import { Button } from "@mui/material";
import React, { useState } from "react";
import serverimage from "../../../../../serverConstant";
import styles from "./Commondetailexshibitiongallery.module.css";
import capitalizeWords from "../../../../../utils/capitalizeContent";
import dateFormat from "../../../../../utils/dateFormat";
import TableForBuyerBid from "../../../../../artist/commonTables/EightcolimgArtist/TableForBuyerBid";

export default function CommondetailAuctionaArtist(props) {
  const [open, setOpen] = useState(false);
  console.log("props data", props.data && props.data.data.data)
  const handlePopup = () => {
    props.setOpen && props.setOpen({ open: true });
  };
  const handleDatepopup = () => {
    props.setOpen({ dateopen: true });
  };
  return (
    <div className={styles.mains}>
      {props.type === "order_detail_gallery" ? (
        ""
      ) : (
        <>
           <div className={styles.more_detail_child} style={{display:'flex',alignItems:'center',gap:"10px",padding:"10px 10px"}}>
                            <h1 style={{height:'20px'}}>Auction Title:</h1>
 <h1 style={{height:'20px',color:'#636365',fontWeight:"300"}}>  { capitalizeWords(props.data && props.data.data.auction?.name)}</h1>
                            {/* <div className={styles.content} style={{height:"0px"}}>{ capitalizeWords(props.data && props.data.data.auction?.name)} </div> */}
                          </div>
          <hr />
        </>
      )}
         <div className={styles.more_detail}>
                    <div>
                 
                    
                       

                    
                        
                       
                          <div className={styles.more_detail_child}>
                            <div className={styles.title}>Description:</div>

                            <div className={styles.content}>{ capitalizeWords(props.data && props.data.data.auction?.description)} </div>
                          </div>
                          <div className={styles.more_detail_child}>
                            <div className={styles.title}>Created Date:</div>

                            <div className={styles.content}>{dateFormat(props.data && props.data.data.auction?.createdAt)} </div>
                          </div>
                          <div className={styles.more_detail_child}>
                            <div className={styles.title}>Start Date:</div>

                            <div className={styles.content}>{dateFormat(props.data && props.data.data.auction?.start_date)} </div>
                          </div>
                          <div className={styles.more_detail_child}>
                            <div className={styles.title}>End Date:</div>

                            <div className={styles.content}>{ dateFormat(props.data && props.data.data.auction?.end_date)} </div>
                          </div>
                          <div className={styles.more_detail_child} style={{alignItems:'center',gap:'15px'}}>
                            <div className={styles.title}>Application End Date:</div>

                            <div className={styles.content}>{ dateFormat(props.data && props.data.data.auction?.application_end_date)} </div>
                          </div>
                         
                        </div>
                  
                   
                  
                
                  </div>

      <div className={styles.detail_id}>
        <h1>No. of Items</h1>
        <p>{props.data === null ? 0 : props.data?.data.data.length === 0 ? "0" : props.data.data.data.length}</p>
      </div>
      {/* <hr /> */}
      {props.data === null ?
        <h2 align="center">No Data Found</h2>
        : props.data.data && props.data.data.data.length === 0 ? (
          <h2 align="center">No Data Found</h2>
        )
          : (
          
            props.data &&
            props.data.data.data &&
            props.data.data.data.map((item, i) => (
           <>
              <div key={i}>
                <hr className={styles.hrs} />
                <div className={styles.card_main_content}>
                  <div className={styles.status}>
                    {props.type === "auction_gallery" ? (
                      item.item_status === "Sold" ? (
                        <Button
                          sx={{ color: "#35BE43", backgroundColor: "#EAEAEA", width: "120px", height: "46px" }}>
                          Sold
                        </Button>
                      ) : item.item_status === "Unsold" ? (
                        <Button
                          sx={{ color: "#EE0909", backgroundColor: "#EAEAEA", width: "120px", height: "46px" }}>
                          Unsold
                        </Button>
                      ) : (
                        <Button
                          sx={{ color: "#FFC705", backgroundColor: "#EAEAEA", width: "120px", height: "46px" }}>
                          Pending
                        </Button>
                      )
                    ) : props.type === "exshibition_gallery" ? (
                      item.item_status === "Sold" ? (
                        <Button
                          sx={{ color: "#35BE43", backgroundColor: "#EAEAEA", width: "120px", height: "46px" }}>
                          Sold
                        </Button>
                      ) : item.item_status === "Unsold" ? (
                        <Button
                          sx={{ color: "#EE0909", backgroundColor: "#EAEAEA", width: "120px", height: "46px" }}>
                          Unsold
                        </Button>
                      ) : (
                        <Button
                          sx={{ color: "#FFC705", backgroundColor: "#EAEAEA", width: "120px", height: "46px" }}>
                          Pending
                        </Button>
                      )
                    ) :
                      props.type === "order_detail_gallery" ? (
                        item.content === "1" ? (
                          <Button
                            sx={{ color: "#EE0909", backgroundColor: "#EAEAEA", width: "120px", height: "46px" }}>
                            Rejected
                          </Button>
                        ) : item.content === "2" ? (
                          <div className={styles.item_acc_dec_div}>
                            <Button variant="contained" sx={{ width: "120px", height: "46px" }}>
                              Accept
                            </Button>
                            <Button
                              onClick={handlePopup}
                              variant="outlined"
                              sx={{ backgroundColor: "#EAEAEA", width: "120px", height: "46px" }}>
                              Decline
                            </Button>
                          </div>
                        ) : item.order_status?.toLowerCase() === "schedule pickup pending" ? (
                          <Button
                            onClick={handleDatepopup}
                            variant="contained"
                            sx={{ width: "226px", height: "46px" }}>
                            Pick A Shedule
                          </Button>
                        ) : item.content === "0" ? (
                          <Button
                            sx={{ color: "#35BE43", backgroundColor: "#EAEAEA", width: "326px", height: "46px" }}>
                            Product Recived at Artist’s Location
                          </Button>
                        ) : (
                          <p className={styles.schedule_time}>{item.order_status}</p>
                        )
                      ) : item.content === "0" ? (
                        <Button
                          sx={{ color: "#35BE43", backgroundColor: "#EAEAEA", width: "120px", height: "46px" }}>
                          Accept
                        </Button>
                      ) : item.content === "1" ? (
                        <Button
                          sx={{ color: "#EE0909", backgroundColor: "#EAEAEA", width: "120px", height: "46px" }}>
                          Rejected
                        </Button>
                      ) : (
                        <Button
                          sx={{ color: "#FFC705", backgroundColor: "#EAEAEA", width: "120px", height: "46px" }}>
                          Pending
                        </Button>
                      )}

                  

                  </div>
                  <div className={styles.more_detail}>
                    <div>
                      {item.item.map((val, i) => (
                        <div>
                          <h1 className={styles.art_name}>{val.name}</h1>
                          <div>
                            <img src={ val.image} height="85px" width="121px" alt='img' />{" "}
                          </div>
                          {localStorage.getItem('usertype') === 'gallery' ?
                            <div className={styles.more_detail_child}>
                              <div className={styles.title}>Artist Name:</div>
                              <div className={styles.content}>{item.artist}</div>
                            </div> : ""}
                       
                          <div className={styles.more_detail_child}>
                            <div className={styles.title}>Materials:</div>
                            <div className={styles.content}>{val.material && val.material.map(c => <>{c.name}, </>)}</div>
                          </div>
                          <div className={styles.more_detail_child}>
                            <div className={styles.title}>Category:</div>

                            <div className={styles.content}>{val?.category} </div>
                          </div>
                          <div className={styles.more_detail_child}>
                            <div className={styles.title}>Dimensions:</div>
                            <div className={styles.content}>  {val?.width} W × {val?.height} H × {val?.depth} D in cm</div>  
                          </div>
                         
                        </div>
                      ))}
                    </div>
                    {/* {item.action ===  } */}
                    <div className={styles.action}>
                      <p className={styles.remark_text}>{item.remark}</p>
                    </div>
                  </div>
                </div>
              </div>
                    <TableForBuyerBid bids={item} />
           </>
            ))
            
          )}
    
    </div>
  );
}
