import React, { useState } from "react";
import Galleryadminofferpopup from "../../../gallery/components/popups/adminOffer/Galleryadminofferpopup";
import Commonadminoffer from "../../../gallery/pages/adminOffer/commonadminoffer/Commonadminoffer";
import styles from "./Adminoffer.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchAdminOffer } from "../../../State/api/artistapi";
import { fetchArtsData } from "../../../State/api/superadminApi";
import { ToastContainer } from "react-toastify";

const approvedhead = [
  {
    id: "name",
    label: "Offer Image",
  },
  {
    id: "name",
    label: "Offer Name",
  },
  {
    id: "mobile",
    label: "Discount Type",
  },
  {
    id: "email",
    label: "Start Date",
  },
  {
    id: "type",
    label: "End Date",
  },
  {
    id: "action",
    label: "Action",
  },
];
const adminhead = [
  {
    id: "name",
    label: "Image",
  },
  {
    id: "name",
    label: "Image",
  },
  {
    id: "mobile",
    label: "Name",
  },
  {
    id: "email",
    label: "Views",
  },
  {
    id: "type",
    label: "Likes",
  },
  {
    id: "action",
    label: "Stock in Qty",
  },
];
const sortdata = [
  { label: "Newest Date ↑", value: "ascending" },
  { label: "Oldest Date ↓", value: "descending" },
]
const filterdata = [
  { label: "By Percentage", value: "start_date" },
  { label: "By Price", value: "end_date" },
]
export default function Adminoffer() {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [search, setSearch] = useState("")
  console.log(search,'search')
  const [condition, setCondition] = useState({ sorting: "", filter: "" })
  console.log(condition,'condition')
  const [filteredId, setFilteredId] = useState(null)
  const artdata = useSelector((state) => state.artsdata.data);
  const [offerId, setOfferId] = useState("")

  const adminofferDt = useSelector((state) => state.adminOfferData.data);
  const offerIdd = adminofferDt?.data?.map((val) => val?._id)

  const [data, setData] = useState({ offset: 1 });
  const [dataArts, setDataArts] = useState({ is_accepted: "Approved", offset: 1 })
  
  const fetchData = () => {
    dispatch(fetchAdminOffer(dataArts));
  };
  useEffect(() => {
    fetchData();
  }, [dataArts]);

  const handleFilter = (event) => {
    const value = event.target.value
    if (value === "") {
      setCondition({ ...condition, filter: "views" })
    } else {
      setCondition({ ...condition, filter: value })
    }
  }
  const handleSearch = (e) => {
    let val = e.target.value
    setSearch(val)
  }

  const handleSorting = (event) => {
    setCondition({ ...condition, sorting: event.target.value })
  }
  const fetchDataArts = () => {
    dispatch(fetchArtsData(dataArts))
  }
  useEffect(() => {
    fetchDataArts()
  }, [dataArts])

  const [FilterOfferDetails, setFilterOfferDetails] = useState({})
 
  
  useEffect(() => {
    const data = adminofferDt?.data?.filter((val) => val?._id.includes(offerId));
     setFilteredId(data?.[0]?._id)
     setFilterOfferDetails(data?.[0])
  },[offerId])
  const filteredData = React.useMemo(() => {
    if (!adminofferDt?.data) return [];
  
    let sortedData = [...adminofferDt.data]; // Create a copy to avoid mutating original data
  
    // Apply sorting based on filter and sorting conditions
    if (condition?.sorting === "ascending") {
      sortedData.sort((a, b) => {
        const dateA = new Date(b.start_date);
        const dateB = new Date(a.start_date);
  
        return condition.sorting === "ascending" ? dateA - dateB : dateB - dateA;
      });
    } else if (condition?.sorting === "descending") {
      sortedData.sort((a, b) => {
        const dateA = new Date(a.start_date);
        const dateB = new Date(b.start_date);
  
        return condition.sorting === "descending" ? dateA - dateB : dateB - dateA;
      });
    }
  
    // Apply search filter if search query is present
    if (search.length > 0) {
      sortedData = sortedData.filter((item) =>
        item.name.toLowerCase().includes(search.toLowerCase())
      );
    }
    if(condition.filter === "start_date"){
      sortedData = sortedData.filter((item) => item.discountType === "Percentage")
    }
    if(condition.filter === "end_date"){
      sortedData = sortedData.filter((item) => item.discountType === "Price")
    }
    return sortedData;
  }, [adminofferDt?.data, condition,search]);
  console.log(filteredData,'filtereddata')


  return (
    <div className={styles.mains}>
      {open ? <Galleryadminofferpopup 
      open={open} 
      setOpen={setOpen} 
      artdata={artdata} 
      offerId={offerId} 
      offerIds={offerIdd} 
      filteredId={filteredId} 
      FilterOfferDetails={FilterOfferDetails}
        /> : null}
      <div className={styles.components}>
        <Commonadminoffer
          setOpen={setOpen}
          activetab="galleryadmin"
          head={approvedhead}
          
          adminhead={adminhead}
          pagination={data}
          setData={setData}
          totalData={adminofferDt && adminofferDt.totalCount}
          data={filteredData}
          setOfferId={setOfferId}
          handleSearch={handleSearch}
          handleFilter={handleFilter}
          handleSorting={handleSorting}
          sortdata={sortdata}
          filterdata={filterdata}
          condition={condition}
          search={search}
          setDataArts={setDataArts}
        />
        
        <ToastContainer />
      </div>
    </div>
  );
}
