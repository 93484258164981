import {
  Box,
  Button,
  FormControlLabel,
  Modal,
  Radio,
  StepButton,
  Stepper,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import homeImage from "../../../constant";
import styles from "./ChooseFrameModal.module.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";

import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { styled } from "@mui/material/styles";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";
import api from "../../../State/api/Api";
import { setLoader } from "../../../State/Features/common/loaderSlice";
import { useDispatch, useSelector } from "react-redux";
import serverimage from "../../../serverConstant";
import { FreeMode } from "swiper";
import {
  handelCustomFrame,
  handelSubmitFrame,
} from "../../../State/api/userApi";
import { Errornotify, Successnotify } from "../../../common/alert/Alert";
import CircularLoader from "../../../common/loader/Loader";

const steps = ["S", "M", "L"];
const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 8.7px)",
    right: "calc(50% + 8px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#1C1C1C",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#1C1C1C",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === "dark" ? "#1C1C1C" : "#D9D9D9",
    borderTopWidth: 4,
    borderRadius: 1,
  },
}));
function QontoStepIcon(props) {
  const { active, completed, className } = props;
  
  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        // <Check className="QontoStepIcon-completedIcon" />
        <div className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}
const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 21,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#1C1C1C",
  }),
  "& .QontoStepIcon-completedIcon": {
    // color: "#1C1C1C",
    // zIndex: 1,
    // fontSize: 18,
    width: 18,
    height: 18,
    borderRadius: "50%",
    backgroundColor: "#1C1C1C",
  },
  "& .QontoStepIcon-circle": {
    width: 18,
    height: 18,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function ChooseFrameModal({
  frameModal,
  setFrameModal,
  setFrameModalsecond,
  frameModalsecond,
  handleClickChooseFrame,
  setFrameData,
  frameData,
  handleBackbutton,
}) {
  const [frame, setFrame] = useState({
    first: false,
    second: false,
    value: "",
  });
  const [framestep, setFramestep] = useState("");
  const [requestInfo, setRequestInfo] = useState({
    email: "",
    description: "",
  });

  const loader = useSelector((state) => state.loader);

  const handleOpenQuote = () => setFrameModal(true);
  const handleCloseQuote = () => setFrameModal(false);
  const handleCloseframeModalsecond = () => {
    setFrameModalsecond(false);
    setFrameData({ thickness_id: "", finishtouch_id: "", framing_id: "" });
  };

  const handleSelectFrameFirst = (e) => {
    const id = e.target.value;
    setFrame({ first: true, second: false, value: id });
    setFrameData({ ...frameData, finishtouch_id: id });
  };
  const handleSelectFrameSecond = (e) => {
    const id = e.target.value;
    setFrame({ first: false, second: true, value: id });
    setFrameData({ frameData, finishtouch_id: id });
  };
  const [activeStep, setActiveStep] = React.useState(null);
  const [completed, setCompleted] = React.useState({});
  const [finishingFrame, setFinishingFrame] = React.useState();
  const [allFrame, setAllFrame] = React.useState();
  const [modalGetdata, setModalgetdata] = useState({
    thickness: null,
    finishingframe: null,
    allFrame: null,
  });
  

  const dispatch = useDispatch();
  const handleStep = (step, id) => () => {
    if(step===activeStep){

    }
    setActiveStep(step);
    setFrameData({ ...frameData, thickness_id: id });
  };
  const handleCustomSize = () => {
    setFramestep("second");
    setFrame({ ...frame, value: "" });
  };
  const handleRedimadeSize = (e) => {
    const value = e.target.id;
    if(value === frame.value){
    setFrame({ ...frame, value: '' });
    setFramestep("");
    setActiveStep(null);
    setFrameData({ ...frameData, framing_id: '' });
    }
    else{
      setFrame({ ...frame, value: value });
      setFramestep("");
      setActiveStep(1);
      setFrameData({ ...frameData, framing_id: value });
    }
  };  
  const handleFetchFinishingTouch = async function () {
    dispatch(setLoader(true));
    try {
      const result = await api.applicationApi(`api/user/getfinishtouch`);
      if (result && result.status === 200) {
        // setModalgetdata({...modalGetdata,finishingframe:result.data.response});
        setFinishingFrame(result.data.response);
        dispatch(setLoader(false));
      } else if (result.status === 401) {
      } else {
      }
    } catch (err) {
    }
  };
  const handleFetchFrames = async function () {
    dispatch(setLoader(true));
    try {
      const result = await api.applicationApi(`api/user/getframing`);
      if (result && result.status === 200) {
        setAllFrame(result.data.response);
        dispatch(setLoader(false));
      } else if (result.status === 401) {
      } else {
      }
    } catch (err) {
    }
  };
  const handleFetchThickness = async function () {
    dispatch(setLoader(true));
    try {
      const result = await api.applicationApi(`api/user/getthickness`);
      if (result && result.status === 200) {
        setModalgetdata({ ...modalGetdata, thickness: result.data.response });
        dispatch(setLoader(false));
      } else if (result.status === 401) {
      } else {
      }
    } catch (err) {
    }
  };
  useEffect(() => {
    handleFetchFinishingTouch();
    handleFetchFrames();
    handleFetchThickness();
  }, []);
  const handleSubmitFrames = () => {
    if (activeStep === null) {
      Errornotify("Please select frame thickness");
    } else {
      dispatch(handelSubmitFrame(frameData, setFrameModalsecond));
    }
  };
  const handleChangeRequest = (e) => {
    const { name, value } = e.target;
    setRequestInfo({ ...requestInfo, [name]: value });
  };
  const handleSubmitRequest = () => {
    dispatch(handelCustomFrame(requestInfo, setFrameModalsecond));
  };
  return (
    <>
      <Successnotify />
      <Modal
        open={frameModal}
        onClose={handleCloseQuote}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={styles.modal_box}>
          <div className={styles.main_div}>
            <div className={styles.left_div}>
              <div>
                <div className={styles.img_div}>
                  <img
                    src="/img/curatorleftimg1.png"
                    width="100%"
                    height="100%" alt=""
                  />
                </div>
                <h5 className={styles.order_summary}>Order Summary</h5>
                <p className={styles.streching}>Streching only</p>
                <h6 className={styles.view_price}>+250.00</h6>
              </div>

              <div className={styles.left_second_div}>
                <h6 className={styles.selected}>
                  Total price for selected options{" "}
                  <span className={styles.view_price}>$250.00</span>
                </h6>
              </div>
            </div>
            <div className={styles.right_div}>
              <div className={styles.close_icon}>
                <img
                  className={styles.modalclosebutton}
                  onClick={handleCloseQuote}
                  src="/img/image 86.png" alt=""
                />
              </div>
              <div className={styles.texcbox}>
              <h1>Any Finishing touches?</h1>
              <p className={styles.para_first}>
                This artwork is not stretched on canvas. You can have the
                artwork stretched without adding a frame - it's up to you! Let
                us know if you have any questions.
              </p>
              <p className={styles.para_second}>
                Don't worry - you can always change your mind before finalizing
                your order.
              </p>
              <div className={styles.select_frame_div}>
                {/* {modalGetdata.finishingframe && modalGetdata.finishingframe */}
                {loader.loader ? (
                  <div className={styles.loader}>
                    <CircularLoader />
                  </div>
                ) : (
                  finishingFrame &&
                  finishingFrame.map((val, i) => (
                    <div className={styles.card_div}>
                      <img
                        src={`/img/frame 1.png`}
                        width="80%"
                        height="100px" alt=""
                      />
                      <br />
                      <FormControlLabel
                        sx={{ mt: -0.5, mb: 0 }}
                        value="strech"
                        control={
                          <Radio
                            checked={i === 0 ? frame.first : frame.second}
                            value={val._id}
                            onClick={
                              i === 0
                                ? handleSelectFrameFirst
                                : handleSelectFrameSecond
                            }
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: 18,
                              },
                            }}
                            checkedIcon={<CheckCircleIcon />}
                          />
                        }
                        className={styles.frame_name}
                        label={
                          <Typography
                            sx={{
                              fontFamily: "DM Sans",
                              fontStyle: "normal",
                              fontWeight: 400,
                              fontSize: "12px",
                              lineHeight: "28px",
                              color: "#636365",
                              marginLeft: "-5px",
                            }}
                          >
                            {val.name}
                          </Typography>
                        }
                      />
                      <p className={styles.price}>+{val.amount}</p>
                    </div>
                  ))
                )}
              </div>

              <button
              className={styles.buttonstyl}
              
                onClick={handleClickChooseFrame}
              >
                Choose Your Frame
              </button>
              </div>
              
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={frameModalsecond}
        onClose={handleCloseframeModalsecond}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={styles.modal_box}>
          <div className={styles.main_div}>
            <div
              className={
                framestep === "second"
                  ? styles.left_div_second
                  : styles.left_div_third
              }
            >
              <h1 align="center" className={styles.framing_second_heading}>
                Framing
              </h1>
              <div className={styles.frame_flex}>
                {loader.loader ? (
                  <div className={styles.loaders}>
                    <CircularLoader />
                  </div>
                ) : (
                  allFrame &&
                  allFrame.map((val) => (
                    <div className={styles.frame_div}>
                      <div
                        className={styles.checked_div}
                        onClick={handleRedimadeSize}
                      >
                        {frame.value === val._id ? (
                          <CheckCircleIcon className={styles.checked_icon} />
                        ) : (
                          ""
                        )}
                        <img
                          src={ val.image}
                          height="100%"
                          width="100%"
                          id={val._id} alt=""
                        />
                      </div>
                      <h6>{val.name}</h6>
                      <h6>+${val.amount}</h6>
                    </div>
                  ))
                )}
                <div className={styles.customframe_div}>
                  <div
                    className={styles.checked_div}
                    onClick={handleCustomSize}
                  >
                    {framestep === "" ? (
                      ""
                    ) : (
                      <CheckCircleIcon className={styles.checked_icon} />
                    )}
                    <img
                      src="/img/customFrraming.png"
                      height="45%"
                      width="45%" alt=""
                    />
                  </div>
                  <h6>Custom</h6>
                  <h6>Upon Request</h6>
                </div>
              </div>
              {framestep === "second" ? (
                ""
              ) : (
                <>
                  <h2 className={styles.thickness_text}>
                    Choose the thickness of the frame
                  </h2>
                  {loader.loader ? (
                    <div className={styles.loader_stepper}>
                      <CircularLoader />
                    </div>
                  ) : (
                    <Stepper
                      alternativeLabel
                      activeStep={activeStep}
                      connector={<QontoConnector />}
                    >
                      {modalGetdata.thickness &&
                        modalGetdata.thickness.map((label, index) => (
                          <Step key={label._id}>
                            <StepLabel
                              color="inherit"
                              sx={{ mt: 0, cursor: "pointer" }}
                              StepIconComponent={QontoStepIcon}
                              onClick={handleStep(index, label._id)}
                            >
                              {label.name}
                            </StepLabel>
                          </Step>
                        ))}
                    </Stepper>
                  )}
                  <div className={styles.action_btns}>
                    <Button
                      variant="text"
                      sx={{
                        display: "flex",
                        borderRadius: "10px",
                        height: "58px",
                        width: "100px",
                      }}
                      onClick={handleBackbutton}
                    >
                      <KeyboardDoubleArrowLeftIcon />
                      Back
                    </Button>
                    <Button
                        variant="contained"
                        size="small"
                      sx={{
                        background: "#1C1C1C",
                        borderRadius: "10px",
                        height: "50px",
                        width: "180px",
                      }}
                      onClick={handleSubmitFrames}
                    >
                      Submit
                    </Button>
                  </div>
                </>
              )}
            </div>
            {framestep === "second" ? (
              <div className={styles.right_div_second}>
                <div className={styles.close_icon}>
                  <img
                    className={styles.modalclosebutton}
                    onClick={handleCloseframeModalsecond}
                    src="/img/image 86.png" alt=""
                  />
                </div>
                <h2 className={styles.framing_second_heading}>
                  Would you like a custom frame for this artwork?
                </h2>
                <p className={styles.para_first}>
                  We'd be happy to help you find the perfect frame for this
                  piece. Provide us with some details of what you'd like and
                  we'll send you a quote as soon as possible.
                </p>
                <div className={styles.textarea_div}>
                  <label className={styles.textarea_label}>Description</label>
                  <br />
                  <TextareaAutosize
                    // onChange={handleChange}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                      "&&:after": {
                        borderBottom: "none",
                      },
                    }}
                    placeholder="Enter description"
                    className={styles.textarea}
                    fullWidth
                    minRows={4}
                    name="description"
                    value={requestInfo.description}
                    onChange={handleChangeRequest}
                  />
                </div>
                <label className={styles.textarea_label}>Email</label> <br />
                <TextField
                  className={styles.textarea_label}
                  placeholder="emilycooper@gmail.com"
                  variant="outlined"
                  sx={{
                    mt: 0.5,
                    backgroundColor: "#f6f6f6",
                    "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                    borderRadius: "6px",
                  }}
                  size="small"
                  fullWidth
                  type="email"
                  name="email"
                  value={requestInfo.email}
                  onChange={handleChangeRequest}
                />
                <Button
                  fullWidth
                  color="primary"
                  sx={{
                    mt: 5,
                    fontFamily: "DM Sans",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "23px",
                    height: "58px",
                    textTransform: "none",
                    color: "white",
                    backgroundColor: "black",
                    "&.MuiButtonBase-root:hover": {
                      bgcolor: "black",
                    },
                    borderRadius: "8px",
                  }}
                  onClick={handleSubmitRequest}
                >
                  Request A Quote
                </Button>
              </div>
            ) : (
              <div className={styles.second_left_div}>
                <div>
                  <div className={styles.close_icon}>
                    <img
                      className={styles.modalclosebutton}
                      onClick={handleCloseframeModalsecond}
                      src="/img/image 86.png" alt=""
                    />
                  </div>
                  <div className={styles.img_div}>
                    <div className={styles.border_image}>
                      {/* <div className={styles.border_img}></div> */}
                      <img
                        className={styles.border_img}
                        src="/img/vintage frame 2.png" alt=""
                      />
                    </div>
                    <div className={styles.img_right_div}>
                      <img
                        src="/img/curatorleftimg1.png" alt=""
                        className={activeStep === null ? styles.img_right_null:activeStep === 0 ? styles.img_right_small:activeStep === 1 ? styles.img_right_mid:styles.img_right_large}
                      />
                    </div>
                  </div>
                  <h5 className={styles.order_summary}>Order Summary</h5>
                  <p className={styles.streching}>Streching only</p>
                  <h6 className={styles.view_price}>+250.00</h6>
                </div>

                <div className={styles.left_second_div}>
                  <hr />
                  <h6 className={styles.selected}>
                    Total price for selected options{" "}
                    <span className={styles.view_price}>$250.00</span>
                  </h6>
                </div>
              </div>
            )}
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default ChooseFrameModal;
