import React, { useEffect, useState } from "react";
import styles from "./Footer.module.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategories } from "../../../State/api/superadminApi";
import {Grid, Container, Link, TextField, Button} from "@mui/material"
import { RiPinterestFill, RiInstagramLine, RiFacebookFill, RiLinkedinFill, RiTwitterXFill, RiYoutubeFill } from "react-icons/ri";
import { AlertsSuccess, Errornotify } from "../../../common/alert/Alert"
import {
  AddMailSubscription
} from "../../../State/api/userApi"
const data = [
  {
    heading: "For Buyers",
    first: "Art Consultancy",
    first_path: "/artworks",
    second: "Buyer FAQ",
    second_path: "/faq",
    third: "Buyer Manual",
    third_path: "/",
    fourth: "Return Policy",
    fourth_path: "/shipping-returns",
    fifth: "Testimonials",
    fifth_path: "/termsandconditions",

    sixth: "Curators Collection",
    sixth_path: "/search?category=art+prints",
    seventh: "Gift Card",
    seventh_path: "/giftcard",
    eighth: "Offers",
    eighth_path: "/offers",
    nineth: "Commisoned Art ",
    nineth_path: "/",
    tenth: "Art Rentals ",
    tenth_path: "/",
    eleventh: "Art Curation Process  ",
    eleventh_path: "/",
    twelfth: "Catalog",
    twelfth_path: "/catelog",
    // seventh: "Curators",
    // seventh_path: "/curator-circle",
    // tenth: "Refer A Friend",
    // tenth_path: "/",
  },
  {
    heading: "For Artist",
    first: "Artist Application",
    first_path: "/artist-application",
    second: "Artist Manual",
    second_path: "/",
    // second: "Artist Handbook",
    // second_path: "/artistlist",
   
   
  },
  {
    heading: "For Gallery",
    first: "Gallery Application",
    first_path: "/gallery-application",
    second: "Gallery Manual",
    second_path: "/",
  },
  {
    heading: "For Curator",
    first: "Curator Application",
    first_path: "/curator-application",
    second: "Curator Manual ",
    second_path: "/",
  },
  {
    heading: "For Affilate",
    first: "Affilate Application ",
    first_path: "/",
    second: " Affilate Manual ",
    second_path: "/",
  },
  {
    heading: "About Us",
    first: "About Us",
    first_path: "/about-us",
    second: "Press",
    second_path: "/pressrelease",
    third: "Careers",
    third_path: "/career",
    fourth: "Contact Us",
    fourth_path: "/contactus",
    fifth: "Blogs",
    fifth_path: "/blogPage",
    sixth: "Art Smiley Biz",
    sixth_path: "https://artsmileybiz.com/",
    // seventh: "FAQ",
    // seventh_path: "/faq",
    eighth: "Art Smiley NFT",
    eight_path: "https://artsmileynft.com/",
    nineth: "Art Prints",
    nineth_path: "/search?category=art+prints",
  },
  {
    heading: "Art Smiley",
    first: "Terms of Service",
    first_path: "/termsconditions",
    second: "Privacy Policy / Copyright Policy",
    second_path: "/privacypolicy",
    // third: "Copyright Policy",
    // third_path: "/privacypolicy",
    // fourth: "Affiliat Program",
    // fourth_path: "/",
    // fifth: "Gift Card Terms",
    // fifth_path: "user/giftcard",
    // sixth: "Sitemap",
    // sixth_path: "/",
  },
  // {
  //   heading: "Top Categories",
  //   first: "Sculptures",
  //   first_path: "/search?category=sculptures",
  //   second: "Artworks",
  //   second_path: "/search?category=artworks",
  //   third: "Art Prints",
  //   third_path: "/search?category=art+prints",
  //   fourth: "Photography",
  //   fourth_path: "/search?category=photography",
  //   fifth: "Painting",
  //   fifth_path: "/search?category=painting",
  //   sixth: "Drawing",
  //   sixth_path: "/search?category=drawing",
  //   seventh: "Collage",
  //   seventh_path: "/search?category=collage",
  //   eighth: "Textile",
  //   eight_path: "/search?category=textile",
  //   nineth: "Digital",
  //   nineth_path: "/search?category=digital",
  //   tenth: "Crafts",
  //   tenth_path: "/search?category=crafts",
  //   eleventh: "Installation",
  //   eleventh_path: "/search?category=installation",
  //   Twelfth: "Prints",
  //   Twelfth_path: "/search",
  // },
];

// {
//     heading: "Top Categories",
//     first: "Paintings",
//     first_path: "/",
//     second: "Phototgraphy",
//     second_path: "/",
//     third: "Sculpture",
//     third_path: "/",
//     fourth: "Drawings",
//     fourth_path: "/",
//     fifth: "collage",
//     fifth_path: "/",
//   },

export default function Footer() {
  const navigate = useNavigate();
const dispatch = useDispatch()
  const handleNavigate = (route) => {
    if (route.includes("https")) {
      window.open(route, "_blank");
    } else {
      console.log(route,'route')
      if(route === "/search") {
        navigate(`/user${route}`, {state: {itemType: "print"}});
        window.scrollTo({ top: 0, behavior: "smooth" });
      }else {
        navigate(`/user${route}`);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
     
    }
  };

  const categorydt = useSelector((state) => state.categoryData.data);
  useEffect(() => {
    dispatch(fetchCategories());
  }, []);

  const [email, setEmail] = useState("")
    const handleChange = (e) => {
      setEmail(e.target.value)
    }
    const handleSubmit = () => {
        const email_regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (email.match(email_regex)) {
          dispatch(AddMailSubscription({ email: email }, setEmail))
        } else {
          Errornotify("Please Enter Valid email")
        }
      }

  return (
    <>
    <div className={styles.socln_sec}>
          <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
          <Grid container spacing={2} sx={{alignItems:"center",justifyContent:"space-between"}}>
              <Grid item xs={12} md={5}>
                <div className={styles.scrvtext}>
                <div>
            <img src={"/img/email.png"} width="80px" height="63px" alt="img" />
          </div>
          <h5 className={styles.subscribe_head}>
          Subscribe to the art smiley newsletter
            {/* Subscribe for ArtSmiley Newsletter to get the trending art updates,
            curated collections, and special offers. */}
          </h5>
                </div>
              </Grid>
              <Grid item xs={12} md={7}>
                <div className={styles.fomsc}>
                  <div className={styles.frsvc}>
                    <div className={styles.mte}>
                    <TextField
                    
                    fullWidth
            variant="outlined"
            placeholder="Please enter your mail address"
            sx={{
              width: { xs: "100%", sm: "400px" },
              backgroundColor: "#3c3c3c",
              borderRadius: "6px",
              "& fieldset": {
                border:
                  email.length === 0
                    ? "none"
                    : email.match(
                   /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
                      )
                    ? "none"
                    : "",
              }, 
              borderRadius: "6px",
              "& .MuiOutlinedInput-notchedOutline": { border: "0" }, 
              fontFamily: "DM Sans",
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "1.5",
              color: "#FFFFFF",
              input: { color: "white",
                padding:"11.5px 15px",
                fontSize:"16px",
                fontWeight:"500",
                borderRadius: "6px",
                lineHeight:"26px",
                border:"0"
               },
            }}
            error="true"
            value={email}
            onChange={handleChange}
          />
                   
                    </div>
                    <div>
                    <Button
            variant="contained"
            sx={{
              background: "#fff",
              color: "#1c1c1c",
              padding:"10px 35px",
              fontSize:"16px",
              fontWeight:"500",
              borderRadius: "6px",
              lineHeight:"26px",
            }}
            onClick={handleSubmit}
          >
            Subscribe
          </Button>
                    </div>
                  </div>
                
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      <div className={styles.footer_main}>
      <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
      <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={9}>
        <Grid container spacing={2}>
        <Grid item  xs={12} sm={2.4} md={2.4}>
        <h4 className={styles.footer_heading}>
        For Buyers
            </h4>
            <ul className={styles.ftlinks}>
  <li>
    <Link onClick={() => handleNavigate('/art-consultancy')}>
      Art Consultancy
    </Link>
  </li>
  <li>
    <Link onClick={() => handleNavigate('/faq?category=for__buyer')}>
      Buyer FAQ
    </Link>
  </li>
  <li>
    <Link onClick={() => handleNavigate('/buyer-manual')}>
      Buyer Manual
    </Link>
  </li>
  <li>
    <Link onClick={() => handleNavigate('/return-policy')}>
      Return Policy
    </Link>
  </li>
  <li>
    <Link onClick={() => handleNavigate('/')}>
      Testimonials
    </Link>
  </li>
  <li>
    <Link onClick={() => handleNavigate('/')}>
      Curators Collection
    </Link>
  </li>
  <li>
    <Link onClick={() => handleNavigate('/giftcard')}>
      Gift Card
    </Link>
  </li>
  <li>
    <Link onClick={() => handleNavigate('/offers')}>
      Offers
    </Link>
  </li>
  <li>
    <Link onClick={() => handleNavigate('/commisioned-art')}>
      Commisioned Art
    </Link>
  </li>
  <li>
    <Link onClick={() => handleNavigate('/art-rentals')}>
      Art Rentals
    </Link>
  </li>
  <li>
    <Link onClick={() => handleNavigate('/')}>
      Art Curation Process
    </Link>
  </li>
  <li>
    <Link onClick={() => handleNavigate('/catelog')}>
      Catalog
    </Link>
  </li>
</ul>
</Grid>
<Grid item  xs={12} sm={2.4} md={2.4}>
        <h4 className={styles.footer_heading}>
        For Artist
              </h4>
              <ul className={styles.ftlinks}>
                <li><Link onClick={() => handleNavigate('/artist-application')}>Artist Application</Link></li>
                <li><Link  onClick={() => handleNavigate('/artist-manual')}>Artist Manual</Link></li>
              </ul>
              <h4 className={styles.footer_heading}>
              For Gallery
              </h4>
              <ul className={styles.ftlinks}>
                <li><Link onClick={() => handleNavigate('/gallery-application')}>Gallery Application</Link></li>
                <li><Link onClick={() => handleNavigate('/gallery-manual')}>Gallery Manual</Link></li>
              </ul>
              <h4 className={styles.footer_heading}>
              For Curator
              </h4>
              <ul className={styles.ftlinks}>
                <li><Link  onClick={() => handleNavigate('/curator-application')}>Curator Application</Link></li>
                <li><Link  onClick={() => handleNavigate('/curator-manual')}>Curator Manual </Link></li>
              </ul>
              <h4 className={styles.footer_heading}>
              For Affilate
              </h4>
              <ul className={styles.ftlinks}>
                <li><Link  onClick={() => handleNavigate('/affiliate-application')}>Affilate Application </Link></li>
                <li><Link  onClick={() => handleNavigate('/affiliate-manual')}> Affilate Manual</Link></li>
              </ul>
        </Grid>
        <Grid item  xs={12} sm={2.4} md={2.4}>
        <h4 className={styles.footer_heading}>
        Art Smiley
              </h4>
              <ul className={styles.ftlinks}>
                <li><Link onClick={() => handleNavigate('/about-us')}> About Us</Link></li>
                <li><Link   onClick={() => handleNavigate('/pressrelease')}> Press</Link></li>
                <li><Link   onClick={() => handleNavigate('/career')}> Careers</Link></li>
                <li><Link   onClick={() => handleNavigate('/contactus')}> Contact Us</Link></li>
                <li><Link   onClick={() => handleNavigate('/blogPage')}> Blogs</Link></li>
                <li><Link   onClick={() => handleNavigate('https://artsmileybiz.com/')}> Art Smiley Biz</Link></li>
                <li><Link   onClick={() => handleNavigate('https://artsmileynft.com/')}> Art Smiley NFT</Link></li>
                <li><Link   onClick={() => handleNavigate('/faq?section=artist')}> FAQ's</Link></li>
              </ul>
        </Grid>
        <Grid item  xs={12} sm={2.4} md={2.4}>
        <h4 className={styles.footer_heading}>
        Terms & Conditions 
              </h4>
              <ul className={styles.ftlinks}>
                <li><Link onClick={() => handleNavigate('/termsconditions')}> Terms of Service</Link></li>
                <li><Link onClick={() => handleNavigate('/privacypolicy')} > Privacy Policy </Link></li>
                <li><Link onClick={() => handleNavigate('/shipping-policy')}> Shipping Policy</Link></li>
                {/* <li><Link onClick={() => handleNavigate('/return-policy')}> Return Policy</Link></li> */}
                <li><Link  onClick={() => handleNavigate('/')}> Terms and Conditions for Artist or Gallery</Link></li>
              </ul>
        </Grid>
        <Grid item  xs={12} sm={2.4} md={2.4}>
        <h4 className={styles.footer_heading}>
        Top Categories
              </h4>
              <ul className={styles.ftlinks}>
                <li><Link  onClick={() => handleNavigate('/search?category=Painting')}> Painting</Link></li>
                <li><Link  onClick={() => handleNavigate('/search?category=Drawing')}> Drawing</Link></li>
                <li><Link  onClick={() => handleNavigate('/search?category=Mixed+Media')}> Mixed Media</Link></li>
                <li><Link  onClick={() => handleNavigate('/search?category=Sculpture')}> Sculpture</Link></li>
                <li><Link  onClick={() => handleNavigate('/search?category=Photography')}> Photography</Link></li>
                <li><Link  onClick={() => handleNavigate('/search?category=Installation')}> Installation</Link></li>
                <li><Link  onClick={() => handleNavigate('/search?category=Digital+Art')}> Digital Art</Link></li>
                <li><Link  onClick={() => handleNavigate('/search?category=art+prints')}> Art Prints</Link></li>
              </ul>
        </Grid>
        
         
        
        {/* // onClick={() => handleNavigate(val.Twelfth_path)} */}
        {/* <Grid item xs={12} md={2.4}>
        <div >
            <h4 className={styles.footer_heading} >
              Top Categories
            </h4>
           {categorydt?.map((val) => (
              <p
              onClick={() =>      navigate(`/user/search?category=${val?.name}`)}
              className={styles.footer_links}
            >
           {val?.name}
            </p>
           ))}
          </div>
        </Grid> */}
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
      <div className={styles.mbtextcenter}>
          <img src={"/img/Copyright symbol.png"} width="30px" height="30px" alt="" />
          <p className={styles.footer_links}>
            Copyright 2024 ArtSmiley Eart and Technology FZCO.
          </p>
          <p className={styles.footer_links}>All Rights Reserved.</p>
          <div className={styles.footer_cards}>
            <img src={"/img/Visa Sign.png"} alt="" />
            <img src={"/img/Master Card.png"} alt="" />
            <img src={"/img/Discover.png"} alt=""/>
            <img src={"/img/American Express.png"} alt=""/>
            <img src={"/img/Paypal.png"} alt=""/>
          </div>
          <div className={styles.footer_socialmedia}>
            <ul>
              <li><Link href="https://www.instagram.com/artsmiley_arts?igsh=cGZpNG9qazRqZWR2" target="_blank"><RiInstagramLine className={styles.soclicn}/></Link></li>
              <li><Link href="https://www.linkedin.com/company/artsmiley/" target="_blank"><RiLinkedinFill className={styles.soclicn}/></Link></li>
              <li><Link href="https://www.facebook.com/artsmileyonline" target="_blank"><RiFacebookFill className={styles.soclicn}/></Link></li>
              <li><Link href="https://x.com/ArtSmileyArts" target="_blank"><RiTwitterXFill className={styles.soclicn}/></Link></li>
              <li><Link href="https://www.youtube.com/channel/UCxppAu6eSwZI0pezf1-7e4A" target="_blank"><RiYoutubeFill className={styles.soclicn}/></Link></li>
              <li><Link href="https://www.pinterest.com/artsmiley/" target="_blank"><RiPinterestFill className={styles.soclicn}/></Link></li>
            </ul>
           
          </div>
        </div>
      </Grid>
      </Grid>
      </Container>
      </div>
    </>
  );
}