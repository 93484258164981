import React, { useState } from "react";
// import Navbar from "../Navbar/Navbar"
// import Header from "../Header/Header"
import styles from "./Home.module.css";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
// import Footer from "../Footer/Footer";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import homeImage from "../../constant";
import { useDispatch } from "react-redux";
import { AddMailSubscription } from "../../State/api/userApi";
import { AlertsSuccess } from "../alert/Alert";
import { useMediaQuery } from "@material-ui/core";

const data = [
  {
    id: 1,
    heading: "Internation visibility",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.",
  },
  {
    id: 2,
    heading: "Internation visibility",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.",
  },
  {
    id: 3,
    heading: "Internation visibility",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.",
  },
];

const FAQs = [
  {
    id: 1,
    question: "What is the process for becoming an affiliate?",
    answer:
      "The Art Smiley affiliate program allows its users to refer friends, family, and customers. The commissions from each sale are paid to the affiliates. Our platform also offers affiliate opportunities for artists, curators, and professionals who wish to sell our artworks and prints."
  },
  {
    id: 2,
    question: "What is the commission process?",
    answer:
          "Commissions are earned on qualified sales (excluding sales returned and sale cancellations). We have a 30-day cookie period. In this period, affiliates will receive commissions on all sales associated with their account."
  },
  {
    id: 3,
    question: "What is the eligibility for Art Smiley's affiliate program?",
    answer:
          "Art Smiley sells products that appeal to a variety of audiences. It is open to all affiliates to apply. You must have traffic of at least 1000 followers, generating consistent traffic on social media platforms. You must have a network of people interested in buying art and sculptures. You must have exposure to implementing multiple branding and promotion ideas that support affiliate programs supporting our website theme."
  },
  {
    id: 4,
    question: "What happens after I register for the ArtSmiley Affiliate Program?",
    answer:
          "After you register, you will receive a confirmation email welcoming you to the program. Our team will review your application within 14 business days. We will inform you about the next steps once the evaluation is complete. If you have any questions or need assistance, feel free to contact our affiliate support team."
  },
  {
    id: 5,
    question: "What should I do if my affiliate application is rejected?",
    answer:
          "If your application is not successful, you will receive a notification email explaining that your profile did not align with our current affiliate needs. This decision is not a reflection of your talent. You are encouraged to enhance your profile and consider reapplying after 6 months. For more details or feedback, you can reach out to our support team."
  },
  {
    id: 6,
    question: "How will I know if my affiliate account has been approved?",
    answer:
          "You will receive an approval email informing you that your affiliate account has been approved. The email will include a link to create your password and access your account. Once you set your password, you will be able to explore all the tools and resources available to you as an affiliate."
  },
  {
    id: 7,
    question: "What should I do if I have questions or need support?",
    answer:
          "If you have any questions or require assistance, you can contact our affiliate support team[give the link to contact us page] at any time. We are here to help ensure your success as an affiliate and address any concerns you may have."
  },
  {
    id: 8,
    question: "How do I access my affiliate tools and resources?",
    answer:
          "After your account is approved, you will receive an email with a link to create your password. Once your password is set, you will have full access to the affiliate tools and resources on our platform."
  },
  {
    id: 9,
    question: " How can I stay updated with ArtSmiley’s news and promotions?",
    answer:
          " You can follow us on our social media platforms and subscribe to our newsletters for the latest updates, news, and promotions related to ArtSmiley. Links to our social media pages will be provided in our emails and on our website."
  },
  {
    id: 10,
    question: "What should I do if I don't receive the confirmation or approval email?",
    answer:
          "If you don't receive the email, please check your spam or junk folder. If it’s not there, contact our affiliate support team to verify your email address and ensure that there were no issues with your registration or approval process."
  },
  {
    id: 11,
    question: " How can I unsubscribe from ArtSmiley’s communications?",
    answer:
          "If you wish to unsubscribe from our communications, you can click the 'Unsubscribe' link provided in our emails or manage your preferences through the provided hyperlink. This will remove you from our mailing list."
  },
  {
    id: 12,
    question: "What are the different account types I can select during registration?",
    answer:
          "You can choose between two account types: Private and Company. Select the one that best describes your affiliate status."
  },
  {
    id: 13,
    question: "What information do I need to provide for a company account?",
    answer:
          "For a company account, you will need to enter your company name along with other company details."
  },
  {
    id: 14,
    question: "What personal details are required for registration?",
    answer:
          "You need to provide your first name, last name, email address, username, phone number, and Whatsapp number."
  },
  {
    id: 15,
    question: "How do I select my country and language?",
    answer:
          "You can select your country and language from the dropdown menus provided in the registration form."
  },
  {
    id: 16,
    question: "What should I do if I encounter issues during the registration process?",
    answer:
          "If you experience any issues or need assistance with the registration process, please contact our affiliate support team for help."
  },
  {
    id: 17,
    question: "How can I update my information after registration?",
    answer:
          "If you need to update any information after registering, please contact our support team to make the necessary changes to your account."
  },
  {
    id: 18,
    question: "What is the purpose of providing details about my promotion strategies and audience interests?",
    answer:
          " By providing this information helps us understand how you plan to promote ArtSmiley services and ensures that your strategies align with our affiliate program's goals."
  },
];

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function HomeAffiliate() {

  const [expanded, setExpanded] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleSignupButton = () => {
    // const applied_profile = localStorage.getItem("applied_profile");
    // const user_type = localStorage.getItem("usertype");
    
    // if (applied_profile === "true" && user_type === "aritst") {
    //   Errornotify("You Already Apply for Artist");
    // }
    // if (applied_profile === "true" && user_type === "affiliate") {
    //   Errornotify("You Already Apply for Artist");
    // } else {
    //   navigate("/artsmiley/applynow");
    // }
    navigate("/artsmiley/affiliate/applynow")
  };
  const handleChangeSubscription = (e) => {
    setEmail(e.target.value);
  };
  const handleSubmit = () => {
    dispatch(AddMailSubscription({ email: email }, "st", setEmail));
  };

  const [subfaqExpanded, setFaqExpanded] = React.useState("excpanel0"); // Default to the first panel open
    const handleFaqChange = (panel) => (event, isExpanded) => {
      setFaqExpanded(isExpanded ? panel : false);
    };

  return (
    <>
      <AlertsSuccess />
      {/* <Header />
            <Navbar /> */}
      <React.Fragment>
        <CssBaseline />
        <section className={styles.hero_main_affiliate}>
       <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
        <div className={`${styles.pageitle} ${styles.max_width}`}>
              <h1>Promote Art, Earn Commissions:
              <br/>
              Start Your Affiliate Journey</h1>
              <button
              onClick={handleSignupButton}
              variant="contained"
              className={styles.apply_btn}
            >
              Apply
            </button>
            </div>
</Container>
       </section>
       <section className={styles.sec_sc}>
            <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
            <div className={`${styles.text_center} ${styles.max_width1}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>Expand Your Income Stream With Art Smiley Affiliate Program          </h3>
          <p className={styles.withor}>Support artists while earning through the Art Smiley Affiliate Program. If you’re passionate about promoting art and connecting with others, you can earn recurring high commissions on sale by sharing our diverse range of artworks and prints.</p>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={4}>
              <div className={styles.stboxh}>
                <div className={styles.stvob}>
                  <div className={styles.sticon}>
                  <img
                className={styles.img_fluid}
                src={"/img/curatorsignup.svg"}
                alt="img"
              />  </div>
                  <h4>Sign Up</h4>
                  <div className={styles.stcont}>
                    <p>Register and await our review within 14 business days. You’ll receive an email update on the next steps.</p>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
            <div className={styles.stboxh}>
                <div className={styles.stvob}>
                <div className={styles.sticon}>
                  <img
                className={styles.img_fluid}
                src={"/img/curator-recommended.svg"}
                alt="img"
              />  </div>
                  <h4>Recommended</h4>
                  <div className={styles.stcont}>
                   <p>Share artworks with your audience. We have customized linking tools and techniques to share the artwork.</p>
                   </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
            <div className={styles.stboxh}>
                <div className={styles.stvob}>
                <div className={styles.sticon}>
                  <img
                className={styles.img_fluid}
                src={"/img/curator-earn.svg"}
                alt="img"
              />  </div>
                  <h4>Earn</h4>
                  <div className={styles.stcont}>
                    <p>You can earn high affiliate commissions depending on the artwork and the purchase.</p>
                  </div>
                </div>
              </div>
            </Grid>
            </Grid>
          </Container>
          </section>
          <section className={styles.hero_main_affiliate_benefits}>
            <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
            <div className={styles.text_center}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={`${styles.sec_title} ${styles.text_white}`}>Benefits of Partnering As Affiliate with ArtSmiley          </h3>
          </div>
          <div>
          <Grid container spacing={2}>
          <Grid item xs={12} sm={3} md={3}>
            <div className={styles.befnhgt}>
              <div className={styles.befnbox}>
                <div className={styles.befnmbr}>
                  <p>01</p>
                </div>
                <h4>High Commissions</h4>
                <div className={styles.befncont}>
                  <p>Earn a commission of [dynamic value updated in superadmin]% on all artworks, sculptures, frames, prints etc. When a customer makes a purchase through your referral link, you earn a commission on the transaction.</p>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <div className={styles.befnhgt}>
              <div className={styles.befnbox}>
                <div className={styles.befnmbr}>
                  <p>02</p>
                </div>
                <h4>Reliable and secure</h4>
                <div className={styles.befncont}>
                  <p>Tracking and payments are managed through our affiliate program. Art Smiley is committed to protecting from unethical marketing practices.</p>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <div className={styles.befnhgt}>
              <div className={styles.befnbox}>
                <div className={styles.befnmbr}>
                  <p>03</p>
                </div>
                <h4>Powerful tracking</h4>
                <div className={styles.befncont}>
                  <p>With our affiliate dashboard, you can view clicks, trials, sales and commissions in one place. You will be paid every month at the same time.</p>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <div className={styles.befnhgt}>
              <div className={styles.befnbox}>
                <div className={styles.befnmbr}>
                  <p>04</p>
                </div>
                <h4>Exclusive Access</h4>
                <div className={styles.befncont}>
                  <p>Gain access to a range of exclusive promotions, art events, and special offers that you can share with your network.</p>
                </div>
              </div>
            </div>
          </Grid>
          </Grid>
          <div className={styles.btnx_div}>
              <button
                variant="contained"
                onClick={handleSignupButton}
                className={styles.apply_btn}
              >
                Apply
              </button>
            </div>
          </div>
            </Container>
          </section>
          <section className={styles.sec_sc}>
            <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
            <div className={`${styles.text_center} ${styles.max_width1}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>Frequently Asked Questions</h3>
          </div>
             <div className={` ${styles.accordsc} ${styles.faqx}`}>
             {FAQs.map((val, index) => {
  const panelId = `excpanel${index}`;
  return ( // Explicit return statement is necessary
    <Accordion
      key={panelId}
      expanded={subfaqExpanded === panelId}
      onChange={handleFaqChange(panelId)}
      sx={{
        "& .MuiAccordionSummary-contentGutters": {
          margin: "10px 0",
        },
        "&.MuiPaper-root": {
          boxShadow: "none",
          borderBottom: "1px solid #D9DBE9",
          marginBottom: "0",
          borderBottomLeftRadius:"0",
          borderBottomRightRadius:"0",
        },
        "&.MuiAccordion-root::before": {
          display: "none",
        },
        "& .Mui-expanded": {
          minHeight: "auto",
          margin: "0 0 10px 0",
        },
        "& .MuiAccordionSummary-expandIconWrapper": {
          background: "#B7B7B7",
          opacity: ".3",
          textAlign: "center",
          borderRadius: "8px",
        },
        "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
          background: "#1C1C1C",
          opacity: "1",
          color: "#fff",
        },
        "& .MuiAccordionDetails-root": {
          paddingTop: "0",
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${panelId}-content`}
        id={`${panelId}-header`}
      >
        <h3 className={styles.faqhdr}>{val.question}</h3>
      </AccordionSummary>
      <AccordionDetails>
        <p>{val.answer}</p>
      </AccordionDetails>
    </Accordion>
  );
})}
             </div>
          </Container>
          <div className={styles.btn_div1}>
                  <button
                  className={styles.apply_btnbl}
                    variant="contained"
                    onClick={handleSignupButton}
                  >
                    Apply
                  </button>
                </div>
          </section>

      </React.Fragment>
      {/* <Footer /> */}
    </>
  );
}

export default HomeAffiliate;
