import {
  Button,
  createTheme,
  Grid,
  ThemeProvider,
  Typography,
  Container
} from "@mui/material";
import moment from 'moment'
import React, { useEffect } from "react";
import styles from "./ExhibitionDetails.module.css";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import homeImage from "../../../constant";
import api from "../../../State/api/Api";
import { useParams } from "react-router-dom";
import {useState} from 'react';
import { FiMapPin, FiCalendar} from "react-icons/fi";
function ExhibitionDetails() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#FFFFFF",
      },
    },
  });
  const ExhibitionDetailsCard = [
    {
      id: 1,
      imgUrl: "/img/Rectangle prd.png",
    },
    {
      id: 2,
      imgUrl: "/img/Rectangle 5267.png",
    },
    {
      id: 3,
      imgUrl: "/img/Rectangle 5268.png",
    },
  ];
  const [exhibitionDetali, setExhibitionDetails] = useState({})
  const {id} = useParams();
const fetchExhibitionsById = async () => {
  try {
   const {data} = await api.applicationApi(`api/exhibition/fetchExhibitionsbyID/${id}`, "POST")
   setExhibitionDetails(data.data)
  }catch(error) {
  }
}
  useEffect(() => {
    fetchExhibitionsById()
  },[])
  return (
    <ThemeProvider theme={theme}>
      <>
        <div className={styles.exhibitiondetails}>
          <div className={styles.header}>
          <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
          <div className={`${styles.pageitle} ${styles.max_width}`}>
            <h2>Art Exhibitions</h2>
            <h1>{exhibitionDetali?.title}</h1>
            <p className={`${styles.withor} ${styles.whitext}`}>
            {exhibitionDetali?.concept}
            </p>
            <ul className={styles.exlist}>
              <li> <FiMapPin />
                    {exhibitionDetali?.city} | {exhibitionDetali?.venue_address}
                  </li>
                  <li><FiCalendar /> {moment(exhibitionDetali?.start_date).format('dddd')}-{moment(exhibitionDetali?.end_date).format('dddd')}</li>
            </ul>
            <button
                 className={styles.apply_btn}
              >Interested
              </button>
          </div>
          </Container>
             
          </div>
          <section className={styles.sec_sc}>
            <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
              <div className={styles.styhf}>
              <h3>Curator at Large: Exhibitions to see in April</h3>
              <p>  The four exhibitions I have written about this month focus on
                  the everyday. Each artist starts with the familiar,
                  transforming subjects that we think we know by showing us
                  their own version of them. In these exhibitions, we find
                  wonder in pavement debris, anxiety in stuffed animals and
                  outright terror in tables and chairs. And outside of them,
                  having been exposed to a new view on our quotidian
                  surroundings, perhaps we will pay a little more attention to
                  them.
              </p>
              </div>
              <Grid container spacing={2} sx={{justifyContent:"center"}}>
              {ExhibitionDetailsCard.map((item) => (
                  <Grid item xs={12} sm={8} md={8}>
                    <div className={styles.exhibition__card}>
                      <img className={styles.card__img} src={item.imgUrl} alt="{item.imgUrl}" />
                      <h4>Can't take my eyes off you | Kavitha Balasingham &
                      Shayna Fonseka, indigo+madder</h4>
                      <div className={styles.exhibitiondate}>
                      <ul className={styles.exlist}>
                        <li><FiCalendar /> 24 Mar - 23 Apr</li>
                      </ul>
                      </div>
                      <p>  The four exhibitions I have written about this month
                          focus on the everyday. Each artist starts with the
                          familiar, transforming subjects that we think we know
                          by showing us their own version of them. In these
                          exhibitions, we find wonder in pavement debris,
                          anxiety in stuffed animals and outright terror in
                          tables and chairs. And outside of them, having been
                          exposed to a new view on our quotidian surroundings,
                          perhaps we will pay a little more attention to them.
                        
                      </p>
                    </div>
                  </Grid>
                ))}
                
              </Grid>
              <Grid container spacing={2} sx={{justifyContent:"center"}}>
              <Grid item xs={12} sm={8} md={8}>
              <div className={styles.discover_con_btns}>
              <button
                 className={styles.apply_btnbl}
              >Discover New Art
              </button>
            </div>
              </Grid>
              
              </Grid>
            </Container>
          </section>
          
        </div>
      </>
    </ThemeProvider>
  );
}

export default ExhibitionDetails;
