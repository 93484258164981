import { createTheme, ThemeProvider, Typography, Link, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./PrivacyPolicy.module.css";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { useNavigate } from "react-router-dom";
import { setLoader } from "../../../State/Features/common/loaderSlice";
import api from "../../../State/api/Api";
import { useDispatch } from "react-redux";

const theme = createTheme({
  typography: {
    h4: {
      fontFamily: "Marcellus",
      fontSize: "2vw",
    },
    h6: {
      fontFamily: "Marcellus",
      fontSize: "1vw",
    },
  },
});
function PrivacyPolicy() {
  const [privacyPolicy, setPrivacyPolicy] = useState();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNavigate = (route) => {
    navigate(route)
  }

  const handleFetchPrivacyPolicy = async function () {
    dispatch(setLoader(true));
    try {
      const result = await api.applicationApi('api/user/get-privacy-policy'
      )
      if (result && result.status === 200) {
        setPrivacyPolicy(result.data);
        dispatch(setLoader(false));
      }
      else if (result.status === 401) {
      }
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    handleFetchPrivacyPolicy()
  }, [])
  return (
    <ThemeProvider theme={theme}>
      <div className={styles.tandc}>
      <div className={styles.headerCategory}>
          <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
            <div className={styles.pageitle}>
              <h1>Privacy Policy</h1>
              <ul>
                <li><Link onClick={() => handleNavigate("/")}>Home /{" "} </Link></li>
                <li>Privacy Policy</li>
              </ul>
            </div>
          </Container>
          </div>
        {privacyPolicy && privacyPolicy.response.map((val) => (
          <div className={styles.tandc__content}>
            <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
              <h3><CalendarMonthOutlinedIcon/> {val.title}</h3>
              <p dangerouslySetInnerHTML={{ __html: val.description }}></p>
            </Container>
            <div className={styles.calendarHeading}>
               
            </div>
            
            {/* <div className={styles.calendarHeading}>
              <Typography mt={0.5} fontWeight="600" fontSize="1rem">
                WHAT INFORMATION DOES ARTSMILEY COLLECT?
              </Typography>
            </div>
            <div className={styles.para__content}>
              <p>
                <Typography fontSize="1.1rem">
                  This Privacy Policy covers our treatment of “personally
                  identifiable” information (meaning it can be used to
                  specifically identify you as a unique person) or “non-personally
                  identifiable” information (meaning it cannot be used to
                  specifically identify you) that we may collect in your use of
                  the Services. We use both types of information, and combinations
                  of both types, as described in this Privacy Policy. This Privacy
                  Policy does not apply to the practices of any other company or
                  individual that we do not own or control.
                </Typography>
              </p>
            </div>
            <div className={styles.italic__content}>
              <p>
                <Typography fontSize="1.1rem">
                  <i>
                    <b>When You Register for the Services:</b>
                  </i>{" "}
                  We receive and store any information you provide to us when
                  registering with the Services such as your name, username,
                  password, country of location, zip code, postal mail address and
                  email address. Some of the Services may collect your photo,
                  “about” information you provide, links to your social media
                  accounts and certain other profile information.
                </Typography>
              </p>
              <p>
                <Typography fontSize="1.1rem">
                  <i>
                    <b>When You Visit Our Websites or Use the Services: </b>
                  </i>
                  Even if you do not register with us, whenever you interact with
                  the Services, we may collect the following: IP address, language
                  setting, operating system type, the page you requested, an
                  Identifier for advertising if you are using an iOS device or an
                  Android ID if you are using an Android device, user agent, and
                  time stamp. We collect information about your search and click
                  activity on the Services, or your user generated content or
                  activity related to your use of certain Services (for example,
                  information that you upload).
                </Typography>
              </p>
              <p>
                <Typography fontSize="1.1rem">
                  <i>
                    <b>When You Provide Third Party Account Information: </b>
                  </i>
                  If you provide or connect your third-party account credentials
                  to your account, some content and/or information in those
                  accounts may be transmitted into your account with us. For
                  example, when you connect with Facebook, we receive and collect
                  your name, email address and profile photo.
                </Typography>
              </p>
              <p>
                <Typography fontSize="1.1rem">
                  <i>
                    <b>When You Enter a Sweepstake, Contest or Giveaway: </b>
                  </i>
                  We collect information you provide to us if you enter a
                  sweepstake, contest or giveaway. We may collect and share such
                  information you provide with an advertiser who sponsors such
                  event or a service provider may collect your information in
                  connection with such promotional event on our behalf.
                </Typography>
              </p>
              <p>
                <Typography fontSize="1.1rem">
                  <i>
                    <b>When Provided By Other Users: </b>
                  </i>
                  In some cases, your email address and other information may be
                  provided to us by other users of the Services
                </Typography>
              </p>
            </div>
            <div className={styles.calendarHeading}>
              <Typography mt={0.5} fontWeight="600" fontSize="1rem">
                HOW DOES ARTSMILEY USE YOUR INFORMATION?
              </Typography>
            </div>
            <div className={styles.information__use}>
              <Typography mt={1} fontSize="0.9rem">
                We may use the information we collect for various purposes,
                inter-alia, those described below:
              </Typography>
              <Typography fontSize="1.1rem">
                <ul>
                  <li>personalize and imporove the Services;</li>
                  <li>
                    customize the Services and provide advertisements, content or
                    features that match your interests;
                  </li>
                  <li>allow our users to set up a user account and profile;</li>
                  <li>
                    communicate with you about or fulfil your requests for certain
                    products, services, offers, promotions and events offered by
                    ArtSmiley;
                  </li>
                  <li>
                    send you notices, updates, and other administrative messages;
                  </li>
                  <li>process transactions and send you related information;</li>
                  <li>operate sweepstakes, contests or giveaways;</li>
                  <li>
                    link or combine with information we get from others to help
                    provide you with better service;
                  </li>
                  <li>
                    detect, investigate and/or prevent fraudulent, unauthorized or
                    illegal activity
                  </li>
                  <li>
                    analyze and monitor how users utilize the websites and
                    Services; and
                  </li>
                  <li>
                    carry out other purposes described in this Privacy Policy
                  </li>
                </ul>
              </Typography>
            </div>
            <div className={styles.calendarHeading}>
              <Typography mt={0.5} fontWeight="600" fontSize="1rem">
                HOW DOES ARTSMILEY SHARE YOUR INFORMATION?
              </Typography>
            </div>
            <div className={styles.italic__content}>
              <p>
                <Typography fontSize="1.1rem">
                  <i>
                    <b>Advertisers: </b>
                  </i>
                  We allow advertisers, advertising agencies, ad networks, and/or
                  merchant partners and their affiliates and partners to collect
                  information on the Services for the purposes of serving you with
                  advertising tailored to your interest or demographics and
                  analyzing the performance of advertising campaigns.
                </Typography>
              </p>
              <p>
                <Typography fontSize="1.1rem">
                  <i>
                    <b>Third Party Websites: </b>
                  </i>
                  Businesses or third party websites may sell items or provide
                  services to you through the Services (either alone or jointly
                  with us) and we will share your information with that business
                  only to the extent that it is related to such transaction or
                  service.
                </Typography>
              </p>
              <p>
                <Typography fontSize="1.1rem">
                  <i>
                    <b>Agents: </b>
                  </i>
                  We employ other third party vendors, consultants and other
                  service providers who need access to your information to perform
                  work on our behalf.
                </Typography>
              </p>
              <p>
                <Typography fontSize="1.1rem">
                  <i>
                    <b>Business Transfers: </b>
                  </i>
                  We may share your information in connection with, or during
                  negotiations of, any merger, sale of company assets, financing
                  or acquisition, or in any other situation where personal
                  information may be disclosed or transferred as one of our
                  business assets.
                </Typography>
              </p>
              <p>
                <Typography fontSize="1.1rem">
                  <i>
                    <b>Enforcement of Terms and Policies: </b>
                  </i>
                  We may share your information to enforce or apply our Privacy
                  Policy, Terms of Use or our other policies or agreements.
                </Typography>
              </p>
              <p>
                <Typography fontSize="1.1rem">
                  <i>
                    <b>Protection of ArtSmiley and Others: </b>
                  </i>
                  We may access, read, preserve, and disclose any information that
                  we reasonably believe is necessary to comply with law or court
                  order; or protect the rights, property, or safety of ArtSmiley,
                  our employees, our users, or others. This includes exchanging
                  information with other companies and organizations for fraud
                  protection and credit risk reduction.
                </Typography>
              </p>
              <p>
                <Typography fontSize="1.1rem">
                  <i>
                    <b>User Profiles and Submissions: </b>
                  </i>
                  Certain user profile information such as a user's name or image
                  content that you have uploaded to the Services may be displayed
                  to other users to facilitate user interaction within the
                  Services or address request for Services.
                </Typography>
              </p>
              <p>
                <Typography fontSize="1.1rem">
                  <i>
                    <b>With Your Consent: </b>
                  </i>
                  We may share your information in other circumstances with your
                  consent, for example when you have chosen to link an account
                  with the Services with a social media network like Facebook.
                </Typography>
              </p>
              <p>
                <Typography fontSize="1.1rem">
                  <i>
                    <b>Compliance: </b>
                  </i>
                  ArtSmiley will comply with data protections laws and regulations
                  of the Emirate of Dubai, United Arab Emirates, that are in force
                  at any given time.
                </Typography>
              </p>
            </div>
            <div className={styles.calendarHeading}>
              <Typography mt={0.5} fontWeight="600" fontSize="1rem">
                HOW MAY ARTSMILEY COMMUNICATE WITH ME?
              </Typography>
            </div>
            <div className={styles.para__content}>
              <p>
                <Typography fontSize="1.1rem">
                  We may contact or communicate with you by email, postal mail,
                  notices on our websites or applications, newsletters or other
                  means available through the Services, including mobile text
                  message and push notifications. Such communications may contain
                  messages of a commercial or non-commercial nature, including but
                  not limited to, providing you the relevant service or response
                  to your inquiry that you requested, marketing and promotions of
                  the Services or the services of our partners and advertisers,
                  administrative purposes, or to carry out other purposes
                  described in this Privacy Policy.
                </Typography>
              </p>
            </div>
            <div className={styles.calendarHeading}>
              <Typography mt={0.5} fontWeight="600" fontSize="1rem">
                HOW DOES ARTSMILEY USE COOKIES AND WEB BEACONS?
              </Typography>
            </div>
            <div className={styles.para__content}>
              <p>
                <Typography fontSize="1.1rem">
                  “Cookies” are small data file identifiers that are transferred
                  to your computer or mobile web browser that allow us to
                  recognize your browser or mobile device and transfer information
                  about you and your use of the Services. For example, cookies
                  that we use tell us how many times you have visited the
                  Services, your search preferences, and if you have clicked on an
                  advertisement that has been displayed to you. We use
                  “persistent” cookies to save your login information for future
                  logins to the Services. We also use “session ID” cookies to
                  enable certain features of the Services, to better understand
                  how you interact with the Services and to monitor aggregate
                  usage by users and web traffic routing on the Services. Unlike
                  persistent cookies, session ID cookies are deleted from your
                  computer when you log off from the Services and close your
                  browser. <br />
                  We, and third parties with whom we partner, may also deliver a
                  file known as a web beacon, pixel or pixel tag (“Web Beacons”)
                  to you through the Services from an ad network. Web Beacons are
                  small, invisible graphic images that may be used on the Services
                  interface or in emails relating to the Service to collect
                  certain information and monitor user activity on the Service
                  interface, such as to count visits, understand usage
                  effectiveness and to determine if an email has been opened and
                  acted upon. Web Beacons allow ad networks and service providers
                  that we use to provide anonymized, aggregated auditing, research
                  and reporting for us, for advertisers and our other partners.
                  Web Beacons also enable ad networks to serve targeted
                  advertisements to you when you visit other websites. Because
                  your web browser must request these advertisements and Web
                  Beacons from the ad network’s servers, these companies can view,
                  edit, or set their own cookies, just as if you had requested a
                  web page from their site.
                </Typography>
              </p>
            </div>
            <div className={styles.calendarHeading}>
              <Typography mt={0.5} fontWeight="600" fontSize="1rem">
                HOW DOES ARTSMILEY USE SOCIAL PLUGINS?
              </Typography>
            </div>
            <div className={styles.para__content}>
              <p>
                <Typography fontSize="1.1rem">
                  We may use social plugins on the Services, for instance plugins
                  of the social networks such as Facebook, Twitter, Google+,
                  Pinterest and Tumblr, and may feature icons, like the “thumbs
                  up” icon or text like “Recommend“, “Like“ or “Comment“. These
                  cookies are activated once you start navigating the Services.
                  Your browser may create a connection to the servers of such
                  social networks and/or transmit data to such servers when you
                  view a page on our website. If you use the plugin functionality
                  (e.g. click the “Like” button or make a comment), this
                  information is also transmitted directly to the social network
                  by your browser and may be stored there. This Privacy Policy
                  does not cover the practices of these third party social
                  networks.
                </Typography>
              </p>
            </div>
            <div className={styles.calendarHeading}>
              <Typography mt={0.5} fontWeight="600" fontSize="1rem">
                HOW DOES ARTSMILEY USE THIRD-PARTY ONLINE ADVERTISING OR
                ANALYTICS?
              </Typography>
            </div>
            <div className={styles.para__content}>
              <p>
                <Typography fontSize="1.1rem">
                  Some of the advertisements you see on the Services or on emails
                  we deliver to you are delivered by advertisers who collect
                  information about your online activities with the emails we send
                  and/or on the Services through the use of Cookies, Web Beacons,
                  and other technologies. The third parties collecting this
                  information may include, but are not limited to, Adaptive Media,
                  BlueKai, Brightroll, Criteo, DoubleClick, Effective Measure,
                  Google, MediaMath, Oracle, Pulse360, Quantcast, Rubicon Project,
                  Turn and Yahoo. The use of cookies, web beacons, and other
                  technologies by these third parties is subject to the privacy
                  policies of those third parties.
                  <br /> Some of these companies are members of the Network
                  Advertising Initiative (“NAI”) and/or the Digital Advertising
                  Alliance (“DAA”), each of which offers a single location to opt
                  out of receiving tailored ads from its member companies. To opt
                  out of information collection by NAI member companies, or to
                  obtain information about the technologies they use or their own
                  privacy policies, please visit the NAI consumer opt out page
                  located here. To opt out of information collection by DAA member
                  companies, or to obtain information about the technologies they
                  use or their own privacy policies, please visit the DAA consumer
                  choices page located here. [To opt out of collection of your
                  information by Signal, or to obtain information about the
                  technologies Signal uses or its privacy policies, please visit
                  here.]
                  <br /> In addition, ArtSmiley works with third parties to
                  provide analytics information regarding your usage of the
                  Services or to help us track the advertisements that are
                  delivered to you to determine the effectiveness of advertising
                  on the Services. Third parties that use cookies, web beacons and
                  other technologies to assist us with our traffic measurement,
                  research and analytics include, but are not limited to, [Adjust,
                  Adobe, Comscore, Effective Measure, LiveRamp, Tapad, Moat, and
                  DoubleClick.
                </Typography>
              </p>
            </div> */}
          </div>
        ))}
      </div>
    </ThemeProvider>
  );
}

export default PrivacyPolicy;
