import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: null,
};

const ArtsSlice = createSlice({
    name: "artsdata",
    initialState,
    reducers: {
        setArtsdata: (state, action) => {
            // console.log("Reducer Payload:", action.payload);
            state.data = action.payload;
            // console.log("Updated State:", state.data);
        },
    },
});

export const { setArtsdata } = ArtsSlice.actions;

export const ArtsData = (state) => state.artsdata;


export default ArtsSlice.reducer;
