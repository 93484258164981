import { Container, Link, Grid, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom"
import styles from "./ArtistManual.module.css";
import homeImage from "../../../constant";
function  AffilateManual() {
const navigate = useNavigate()

  const handleNavigate = (route) => {
    navigate(route)
  }
  const scrollToSection = (id) => {
  const element = document.querySelector(id);
  if (element) {
    const headerOffset = 150; // Adjust for header height
    const elementPosition = element.offsetTop;
    const offsetPosition = elementPosition - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }
};
  return (
    <>
    <section className={styles.headerCategory}>
      <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
      <div className={styles.pageitle}>
              <h1> Affilate Manual</h1>
              <ul>
                <li><Link onClick={() => handleNavigate("/")}>Home /{" "} </Link></li>
                <li> Affilate Manual</li>
              </ul>
            </div>
      </Container>
    </section>
    <section className={styles.secs_tcv}>
      <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
        <Grid container spacing={2}>
          {/* Static Left Section */}
          <Grid item xs={12} md={3} className={styles.staticLinks}>
            <div className={styles.quilinks}>
              <h4>Process</h4>
              <ul>
                <li>
                  <a href="#step1" onClick={(e) => {
                    e.preventDefault();
                    scrollToSection("#step1");
                  }}>
                    Step 1
                  </a>
                </li>
                <li>
                  <a href="#step2" onClick={(e) => {
                    e.preventDefault();
                    scrollToSection("#step2");
                  }}>
                    Step 2
                  </a>
                </li>
                <li>
                  <a href="#step3" onClick={(e) => {
                    e.preventDefault();
                    scrollToSection("#step3");
                  }}>
                    Step 3
                  </a>
                </li>
              </ul>
            </div>
          </Grid>

          {/* Main Content Section */}
          <Grid item xs={12} md={9}>
            <div className={styles.hrndg}>
              <h2>Register a New Account</h2>
              <ul>
                <li>0.6 year ago - Updated</li>
              </ul>
            </div>
            <div className={styles.manulsc}>
              <ul>
                <li id="step1">
                <span>Step 1 –</span> From the Saatchi Art homepage, click
                  Register at the top right of the page. You will be redirected
                  to the Register page.
                  <div className={styles.mnu_img}>
                    <img
                      className="img-fluid"
                      src={"/img/albc-tr.png"}
                      alt="SectionTitleImage"
                    />
                  </div>
                </li>
                <li id="step2">
                <span>Step 2 –</span> From the Saatchi Art homepage, click
                  Register at the top right of the page. You will be redirected
                  to the Register page.
                  <div className={styles.mnu_img}>
                    <img
                      className="img-fluid"
                      src={"/img/albc-tr.png"}
                      alt="SectionTitleImage"
                    />
                  </div>
                  <div>
                    <p>
                      Select <span>Sign</span> and answer the questions
                      regarding your account.
                    </p>
                    <h5>
                      Opening a Saatchi Art profile with your account.
                    </h5>
                    <p>
                      If you have an existing account, you can select the “Sign
                      in with button during account creation.
                    </p>
                  </div>
                </li>
                <li id="step3">
                <span>Step 3 –</span> Complete the registration process as
                described.
                </li>
              </ul>
            </div>
          </Grid>
        </Grid>
      </Container>
    </section>
    </>
  );
}

export default  AffilateManual;
