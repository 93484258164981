import { Grid, Container, Link } from "@mui/material"
import React, { useEffect, useState } from "react"
import styles from "./PressReleasesDetails.module.css"
import api from "../../../State/api/Api"
import { setLoader } from "../../../State/Features/common/loaderSlice"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { useNavigate } from "react-router-dom"

function PressReleasesDetails() {
  const dispatch = useDispatch()
  const { id } = useParams()
  const [pressDetail, setPressDetail] = useState()

  const handleFetchPessDetail = async function () {
    dispatch(setLoader(true))
    try {
      const result = await api.applicationApi(`api/user/pressrelease/${id}`)
      if (result && result.status === 200) {
        setPressDetail(result.data)
        dispatch(setLoader(false))
      } else if (result.status === 401) {
      }
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    handleFetchPessDetail()
    // dispatch(fetchUsertypes());
  }, [])

  const navigate = useNavigate()
  const handleNavigate = (route) => {
    navigate(route)
  }
  return (
    <div className={styles.pressreleasesdetails}>
      <div className={styles.headerCategory}>
      <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
      <div className={styles.pageitle}>
              <h1>Press Releases</h1>
              <ul>
                <li><Link onClick={() => handleNavigate("/")}>Home /{" "} </Link></li>
                <li>Press Releases</li>
              </ul>
            </div>
      </Container>
      </div>
      <div className={styles.pressce}>
      <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
      <Grid container spacing={2} sx={{
    justifyContent: "center",
    alignItems: "center",
  }}>
        {pressDetail &&
          pressDetail.response.map((item) => (
            <Grid item xs={12} sm={12} md={8}>
              <div className={styles.content__main}>
                <div className={styles.predimg}>
                <img
                  className={styles.img_fluid}
                  src={ item.media}
                  alt=""
                />
                </div>
                <div className={styles.predtl_cont}>
                  <h4>{item.title}</h4>
                  <h6>{item.press_type}</h6>
                  <span
                      dangerouslySetInnerHTML={{
                        __html:
                          item &&
                          item.description &&
                          item.description.length > 100
                            ? item.description.substring(0) + " "
                            : item && item.description
                            ? item.description
                            : "",
                      }}
                    ></span>
                </div>
                 
              </div>
            </Grid>
          ))}
      </Grid>
      </Container>
      </div>
      
    </div>
  )
}

export default PressReleasesDetails
