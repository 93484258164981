import axios from "axios";

// const API_BASE_URL_PRODUCTION = "http://3.6.185.87:8000/";
const API_BASE_URL_PRODUCTION = "https://demo.artsmiley.com/artsmileyapi/";

const NEW_API_BASE_URL_PRIMARY = "https://demo.artsmiley.com/artsmileyapi/";
// const NEW_API_BASE_URL_PRIMARY = 'http://3.6.185.87:8000/'
const api = {
  multipartApi: (url, method, data) => {
    const token = localStorage.getItem("login_token");
    try {
      return axios(`${API_BASE_URL_PRODUCTION}${url}`, {
        method: method,
        headers: {
          authorization: token,
          "Content-Type": "multipart/form-data",
        },
        data: data,
      })
        .then((result) => {
          return result;
        })
        .catch((err) => {
          return err.response;
        });
    } catch (error) {
      return false;
    }
  },
  applicationApi: async (url, method, data, userType) => {
    const token = localStorage.getItem("login_token");
    try {
      return axios(`${API_BASE_URL_PRODUCTION}${url}`, {
        method: method,
        headers: {
          authorization: token,
          "Content-Type": "application/json",
        },
        data: data || {},
      })
        .then((result) => {
          return result;
        })
        .catch((err) => {
          return err.response;
        });
    } catch (error) {
      return false;
    }
  },
};

export default api;
