import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import serverimage from "../../../../../serverConstant";
import styles from "./Commondetailexshibitiongallery.module.css";
import api from "../../../../../State/api/Api";
import { useParams } from "react-router-dom";
import { Errornotify, Successnotify } from "../../../../../common/alert/Alert";
import { ToastContainer } from "react-toastify";

export default function ArtistOrderDetails(props) {

  console.log("Order Product details",props);
  
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [orderData, setOrderData] = useState(null);
  const handlePopup = () => {
    props.setOpen && props.setOpen({ open: true });
  };
  const handleDatepopup = () => {
    props.setOpen({ dateopen: true });
  };

  const handleFetchOrders = async function () {
    // dispatch(setLoader(true));
    setLoading(true);
    try {
      const result = await api.applicationApi(
        `api/order/fetchOrder/${id}`,
        "GET"
      );

      if (result && result.status === 200) {
        setOrderData(result.data.data);
        // dispatch(setLoader(false));
        setLoading(false);
      } else if (result.status === 401) {
        Errornotify("Error with code 401");
      }
    } catch (err) {
      Errornotify("Network Error");
      setLoading(false);
    }
  };
  useEffect(() => {
    handleFetchOrders();
  }, []);

  const handleAcceptOrderItem = async (orderItemId) => {
    try {
      const response = await api.applicationApi(
        `api/order/updateOrderItemStatus/${orderItemId}`,
        "PATCH",
        {
          order_status: "Approved", //Approved and Cancelled
          // "cancellation_reason":"This Is testing" // Not Optional
        }
      );
      if (response.status === 200 || response.status === 201) {
        Successnotify(response.data.message);
        handleFetchOrders();
      } else {
        Errornotify(response.data.message);
      }
    } catch (error) {}
  };

  const handleRejectOrderItem = async (orderItemId) => {
    try {
      const response = await api.applicationApi(
        `api/order/updateOrderItemStatus/${orderItemId}`,
        "PATCH",
        {
          order_status: "Rejected", //Approved and Cancelled
          // "cancellation_reason":"This Is testing" // Not Optional
        }
      );
      if (response.status === 200 || response.status === 201) {
        Successnotify("Order Item Declined Successfully!");
      }
    } catch (error) {}
  };
  return (
    <div className={styles.mains}>
      <>
        <h3 className={styles.main_heading}>
          {/* {props.type==="auction_gallery" ? props.data1 && props.data.auction: props.data && props.data.data && props.data.data.exhibition} */}
          {/* {props.data && props.data.data.data && props.data.data.data[0].artist} */}
          Order ID{" "}
          {orderData?.map(
            (val) => val?.items?.map((item) => item?.order_number)[0]
          )}
        </h3>
      </>
      <>
        {" "}
        {orderData?.map((val) =>
          val?.items?.map((item) => (
            <>
              <div>
                <hr className={styles.hrs} />
                <div className={styles.card_main_content}>
                  <div className={styles.status}>                   
                    {item?.order_status === "Pending" ? (
                      <>
                        <div className={styles.item_acc_dec_div}>
                          <Button
                            variant="contained"
                            sx={{ width: "120px", height: "46px" }}
                            onClick={() => handleAcceptOrderItem(item?._id)}
                          >
                            Accept
                          </Button>
                          <Button
                            // onClick={handlePopup}
                            variant="outlined"
                            sx={{
                              backgroundColor: "#EAEAEA",
                              width: "120px",
                              height: "46px",
                            }}
                            onClick={() => handleRejectOrderItem(item?._id)}
                          >
                            Decline
                          </Button>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {item?.order_status === "Approved" ? (
                      <>
                        <Button
                          onClick={handleDatepopup}
                          variant="contained"
                          sx={{ width: "226px", height: "46px" }}
                        >
                          Pick A Schedule
                        </Button>
                      </>
                    ) : null}
                    {item?.order_status === "Rejected" ? (
                      <>
                        <Button
                          onClick={handleDatepopup}
                          variant="contained"
                          sx={{
                            width: "226px",
                            height: "46px",
                            background: "lightgray",
                            color: "red",
                          }}
                        >
                          Rejected
                        </Button>
                      </>
                    ) : null}

                    {/* <Button
      onClick={handleDatepopup}
      variant="contained"
      sx={{ width: "226px", height: "46px" }}>
      Pick A Schedule
    </Button>

    <Button
      sx={{ color: "#35BE43", backgroundColor: "#EAEAEA", width: "326px", height: "46px" }}>
      Product Received at Artist’s Location
    </Button>

    <p className={styles.schedule_time}>Order Status</p>


  <Button
    sx={{ color: "#35BE43", backgroundColor: "#EAEAEA", width: "120px", height: "46px" }}>
    Sold
  </Button>

  <Button
    sx={{ color: "#EE0909", backgroundColor: "#EAEAEA", width: "120px", height: "46px" }}>
    Unsold
  </Button>

  <Button
    sx={{ color: "#FFC705", backgroundColor: "#EAEAEA", width: "120px", height: "46px" }}>
    Pending
  </Button> */}
                  </div>
                  <div className={styles.more_detail}>
                    <div>
                      <div>
                        <h1 className={styles.art_name}>{item?.item.name}</h1>
                        <div style={{ padding: "10px 0px" }}>
                          <img
                            src={` ${item?.item?.image}`}
                            height="85px"
                            width="121px"
                            alt="img"
                          />{" "}
                        </div>

                        <div className={styles.more_detail_child}>
                          <div className={styles.title}>Artist Name:</div>
                          <div className={styles.content}>
                            {item?.artist?.first_name +
                              " " +
                              item?.artist?.last_name}
                          </div>
                        </div>
                        <div className={styles.more_detail_child}>
                          <div className={styles.title}> Price:</div>
                          <div className={styles.content}>${val?.price}</div>
                        </div>

                        <div className={styles.more_detail_child}>
                          <div className={styles.title}>Materials:</div>
                          {item?.item?.material?.map((item2) => {
                            return (
                              <div className={styles.content}>
                                {item2?.name}
                              </div>
                            );
                          })}
                        </div>
                        <div className={styles.more_detail_child}>
                          <div className={styles.title}>Category:</div>
                          <div className={styles.content}>
                            {item?.item?.category[0]?.name}
                          </div>
                        </div>
                        <div className={styles.more_detail_child}>
                          <div className={styles.title}>Dimensions:</div>
                          <div className={styles.content}>
                            <span>
                              <div>
                                {item?.item?.width} W × {item?.item?.height} H ×{" "}
                                {item?.item?.depth} D in cm
                              </div>
                            </span>
                          </div>

                          {/* <div className={styles.content}>20 W x 20 H x 20 D in</div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ))
        )}
      </>
      <ToastContainer />
    </div>
  );
}
