import {
  Box,
  Button,
  Container,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Link
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./MyCart.module.css";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { AccountCircle } from "@mui/icons-material";
import homeImage from "../../../constant";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import serverimage from "../../../serverConstant";
import {
  fetchAllItemData,
  fetchFetchAllcart,
  handelAddWishlist,
  handleUpdataeCart,
} from "../../../State/api/userApi";
import api from "../../../State/api/Api";
import { setLoader } from "../../../State/Features/common/loaderSlice";
import {
  AlertsSuccess,
  Errornotify,
  Successnotify,
} from "../../../common/alert/Alert";
import CircularLoader from "../../../common/loader/Loader";
import { fetchMaterial } from "../../../State/api/artistapi";
import axios from "axios";
import { FiHeart, FiX } from "react-icons/fi";
function MyCart() {
  const navigate = useNavigate();
  const cardDetails = [
    {
      id: 1,
      imgUrl: "/img/Rectangle 5198.png",
    },
    {
      id: 2,
      imgUrl: "/img/Rectangle 5199.png",
    },
    {
      id: 3,
      imgUrl: "/img/Rectangle 5197.png",
    },
    {
      id: 4,
      imgUrl: "/img/Rectangle 5200.png",
    },
  ];
  const user_id = localStorage.getItem("user_id");
  const [counter, setCounter] = useState(1);
  const [data, setData] = useState(1);
  const [taxes, setTaxes] = useState(null);
  const [addTocart, setAddTocart] = useState({
    userId: user_id,
    items: [],
  });
  const allitemdt = useSelector((state) => state.allitemData);
  const mac_Address = localStorage.getItem("device_id");
  const [ipAdd, setIpAdd] = useState("");
  const [currency, setCurrency] = useState("");
  const [inputs, setInputs] = useState({
    buy_percentage: 0,
    print_percentage: 0,
    rent_percentage: 0,
    vat_percentage: 0,
    custom_duties: 0,
    tax_percentage: 0,
  });
  const myCart = useSelector((state) => state.mycartData.data);
  const userty = useSelector((state) => state.user.data);
  const itemprice =
    myCart &&
    myCart.result.map((val) => {
      if (!val.item_id) return 0;

      if (val.item_id.sale.length) {
        return val.item_id.sale
          .map((item) => item.price)
          .reduce((acc, price) => acc + price, 0);
      } else if (val.item_id.print.length) {
        return val.item_id.print
          .map((item) => item.print_price)
          .reduce((acc, price) => acc + price, 0);
      } else if (val.item_id.rent.length) {
        return val.item_id.rent
          .map((item) => item.rent_price)
          .reduce((acc, price) => acc + price, 0);
      } else {
        return 0;
      }
    });

  const itemQuantity = myCart && myCart.result.map((val) => val.quantity);
  const subtotalarray =
    itemprice && itemprice.map((e, index) => e * itemQuantity[index]);
  const subtotal = subtotalarray && subtotalarray.reduce((a, b) => a + b, 0);
  const vat = subtotal * (inputs.vat_percentage / 100);
  const loader = useSelector((state) => state.loader);
  const [material, setMaterial] = useState(null);
  const dispatch = useDispatch();
  const increase = () => {
    setCounter((count) => count + 1);
  };
  const decrease = () => {
    if (counter > 0) {
      setCounter((count) => count - 1);
    }
  };
  const handleClickDecrease = (event) => {
    decrease();
  };
  const handleClickIncrease = (e) => {
    const ids = e.item._id;
    const qty = e.item.qty;
  };
  const handleNavigate = () => {
    navigate(`/user/`);
  };

  const handleFetchTaxes = async function () {
    dispatch(setLoader(true));
    try {
      const result = await api.applicationApi(
        `api/superAdmin/fetchSettingDetails`,
        "GET"
      );
      if (result && result.status === 200) {
        result.data.data.forEach((element) => {
          setInputs(element);
        });
        dispatch(setLoader(false));
      } else if (result.status === 401) {
      }
    } catch (err) {
    }
  };
  // {"offset": 1,user_id:""/IP_address:""}
  // const getData = async () => {
  //   const res = await axios.get('https://geolocation-db.com/json/')
  
  //   dispatch(fetchFetchAllcart({ offset: 1, IP_address: mac_Address }))
  // }
  useEffect(() => {
    userty.signed === true &&
      dispatch(
        fetchFetchAllcart({
          offset: data,
          user_id: localStorage.getItem("user_id"),
        })
      );
    userty.signed === false &&
      dispatch(
        fetchFetchAllcart({
          offset: 1,
          user_id: localStorage.getItem("user_id"),
        })
      );
    dispatch(fetchMaterial(setMaterial));
    dispatch(fetchAllItemData());
    handleFetchTaxes();
    // dispatch(fetchSettingTax(setTaxes));
  }, []);

  const handleWishlist = (id) => {
    if (userty.signed === false) {
      Errornotify("Please login ");
    } else {
      dispatch(handelAddWishlist({ item_id: id }));
    }
  };
  const handleRemoveItemcart = async function (ids) {
    dispatch(setLoader(true));
    try {
      const result = await api.applicationApi(
        `api/user/removeFromCart/${ids}`,
        "DELETE"
      );
      if (result.status === 200 || result.status === 201) {
        dispatch(setLoader(false));
        userty.signed === true &&
          dispatch(
            fetchFetchAllcart({
              offset: data,
              user_id: localStorage.getItem("user_id"),
            })
          );
        dispatch(
          fetchFetchAllcart({
            offset: 1,
            user_id: localStorage.getItem("user_id"),
          })
        );
        Successnotify(result.data.message);
      } else if (result.status === 400) {
        Errornotify(result.data.message);
      } else if (result.status === 401) {
      }
    } catch (err) {
    }
  };
  const handleUpdateCarts = (id, qty) => {
    if (userty.signed) {
      dispatch(
        handleUpdataeCart(
          id,
          { quantity: qty + 1 },
          { offset: data, user_id: localStorage.getItem("user_id") }
        )
      );
    } else {
      dispatch(
        handleUpdataeCart(
          id,
          { quantity: qty + 1 },
          { offset: data, user_id: localStorage.getItem("user_id") }
        )
      );
    }
  };
  const handleUpdateCartRemove = (id, qty) => {
    if (qty === 1) {
      handleRemoveItemcart(id);
      localStorage.removeItem("product_prices");
    } else {
      userty.signed === true &&
        dispatch(
          handleUpdataeCart(
            id,
            { quantity: qty - 1 },
            { offset: data, user_id: localStorage.getItem("user_id") }
          )
        );
      userty.signed === false &&
        dispatch(
          handleUpdataeCart(
            id,
            { quantity: qty - 1 },
            { offset: data, user_id: localStorage.getItem("user_id") }
          )
        );
    }
  };
  const handleClickCartItem = (id) => {
    navigate(`/user/productdetail/${id}`);
  };

  useEffect(() => {
    const getIpAddress = async () => {
      const data = await axios.get("https://api.ipify.org/?format=json");
      const id = data.data.ip;
      setIpAdd(id);
    };
    getIpAddress();
  }, []);
  const currencyRates = {
    USD: 1, // Base currency
    GBP: 0.7967, // Pound Sterling rate against USD
    AED: 3.6725, // Dirham rate against USD (example value, replace with actual rate)
    // Add more currency rates as needed
  };
  function formatNewCurrency(amount) {
    if (currency === "GBP") {
      // Assuming formatCurrency function is defined elsewhere
      return "£" + formatCurrency(amount);
    } else if (currency === "AED") {
      // Assuming formatCurrency function is defined elsewhere
      return formatCurrency(amount);
    } else {
      return "$" + amount;
    }
  }
  const formatCurrency = (amount) => {
    let convertedAmount;
    if (currency === "USD") {
      convertedAmount = amount; // No conversion needed if currency is USD
    } else {
      const rateAgainstUSD = currencyRates[currency]; // Get the exchange rate against USD
      convertedAmount = amount * rateAgainstUSD; // Convert amount to user's currency
    }
    return currency === "AED"
      ? `${currency} ${convertedAmount.toFixed(2)}`
      : `${convertedAmount.toFixed(2)}`;
  };
  useEffect(() => {
    const getCurrency = async () => {
      const currency = await axios.get(`https://ipapi.co/${ipAdd}/currency/`);
      const data = currency.data;
      setCurrency(data);
    };
    getCurrency();
  }, []);

  const formatNewCurrencies = (value) => {
    // Your currency formatting logic here
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(value);
  };

  const processItem = (val) => {
    let totalPrices = 0;

    if (val.item_id) {
      if (val.item_id.sale) {
        totalPrices += val.item_id.sale
          .map((item) => item.price)
          .reduce((acc, price) => acc + price, 0);
      }
      if (val.item_id.print) {
        totalPrices += val.item_id.print
          .map((item) => item.price)
          .reduce((acc, price) => acc + price, 0);
      }
      if (val.item_id.rent) {
        totalPrices += val.item_id.rent
          .map((item) => item.price)
          .reduce((acc, price) => acc + price, 0);
      }
    }

    return formatNewCurrencies(val.quantity * totalPrices);
  };

  const getDiscountedPrice = (price) => {
    // Get the discount percentage from localStorage and parse it as a number
    const totalDiscountPercentage =
      JSON.parse(localStorage.getItem("discount_percentage")) || 0;
    if (totalDiscountPercentage > 0) {
      // Apply the discount to the price
      return price * (1 - totalDiscountPercentage / 100);
    } else {
      return price;
    }
  };
  return (
    <div className={styles.mycart}>
      <AlertsSuccess />
      <div className={styles.headerCategory}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
      <div className={styles.pageitle}>
              <h1>My Cart</h1>
              <ul>
                <li><Link onClick={() => handleNavigate("/")}>Home /{" "} </Link></li>
                <li>My Cart</li>
              </ul>
            </div>
      </Container>
        </div>
      {/* <div className={styles.headerCategory}>
        <br />
        <div>
          <Typography fontFamily="marcellus" fontSize="2vw">
            <span className={styles.headerTitle}></span>
          </Typography>
          <Typography fontFamily="marcellus" fontSize="1.25vw">
            <span className={styles.headerSubTitle}>
              <span style={{ cursor: "pointer" }} onClick={handleNavigate}>
                Home /{" "}
              </span>
              <b>My Cart</b>
            </span>
          </Typography>
        </div>
      </div> */}
      <div className={styles.formsec}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
        <Stepper>
        <Step>
          <StepLabel>Shopping Cart</StepLabel>
        </Step>
        <Step>
          <StepLabel active>Checkout</StepLabel>
        </Step>
        <Step>
          <StepLabel>Completed</StepLabel>
        </Step>
      </Stepper>
      <div className={styles.cart__grid}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={8}>
            <div className={styles.wishlist__content}>
              <p  className={styles.carhndg}>
                Your Selection ({myCart && myCart.result.length} items)
              </p>
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          borderBottom: "1px solid #111111",
                        }}
                        align="center"
                      >PRODUCT
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "1px solid #111111",
                        }}
                     
                      >ART TYPE
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "1px solid #111111",
                        }}
                        align="right"
                      >
                        QUANTITY
                      </TableCell>

                      <TableCell
                        sx={{
                          borderBottom: "1px solid #111111",
                        }}
                        align="right"
                      >
                        PRICE
                      </TableCell>

                      <TableCell
                        sx={{
                          borderBottom: "1px solid #111111",
                        }}
                        align="right"
                      ></TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "1px solid #111111",
                        }}
                        align="right"
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  {loader.loader ? (
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={8}>
                          <Box className={styles.loader_box}>
                            {" "}
                            <CircularLoader size={60} />
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ) : (
                    <TableBody>
                      {myCart ? (
                        myCart.result.length === 0 ? (
                          <TableRow>
                            <TableCell colSpan={8}>
                              <Box className={styles.loader_box}>
                                {" "}
                                No Data Found
                              </Box>
                            </TableCell>
                          </TableRow>
                        ) : (
                          myCart &&
                          myCart.result.map((val) => (
                            //  val.quantity === 0 ? (
                            //   <TableRow>
                            //    <TableCell colSpan={8}>
                            //     <Box className={styles.loader_box}> No Data Found</Box>
                            //    </TableCell>
                            //   </TableRow>
                            //  ) :
                            <TableRow
                              key={val._id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                sx={{ borderBottom: "none", paddingBottom: 0 }}
                                component="th"
                                scope="row"
                              >
                                <Grid container spacing={2}>
                                  <Grid item xs={4} sm={6} md={4}>
                                    {val.item_id === null ? (
                                      <div className={styles.productimg}>
                                        <img
                                        className={styles.cartprodimg}
                                        h
                                        srac=""
                                        src=""
                                        alt="img"
                                        onClick={() =>
                                          handleClickCartItem(val.item_id?._id)
                                        }
                                      />
                                      </div>
                                    ) : (
                                      <div className={styles.productimg}>
                                        <img
                                        className={styles.cartprodimg}
                                        srac=""
                                        src={ val.item_id?.image}
                                        alt="img"
                                        onClick={() =>
                                          handleClickCartItem(val.item_id?._id)
                                        }
                                      />
                                      </div>
                                    )}
                                  </Grid>
                                  <Grid item xs={8} sm={6} md={8}>
                                    <div className={styles.product__info}>
                                      <p className={styles.product_nme}>
                                        {val.item_id === null
                                          ? ""
                                          : val.item_id?.name}
                                      </p>
                                      {material &&
                                        material.map((vl) => {
                                          if (
                                            vl._id === val.item_id?.material_id
                                          ) {
                                            return (
                                              <p className={styles.product_nme}
                                              >
                                                {vl.name}
                                              </p>
                                            );
                                          }
                                        })}
                                      <p className={styles.frdimns}
                                      >
                                        {val.item_id === null
                                          ? ""
                                          : `${val.item_id?.height}"*"${val.item_id?.width}"*"${val.item_id?.depth}`}
                                      </p>
                                    </div>
                                  </Grid>
                                </Grid>
                              </TableCell>
                              <TableCell
                                sx={{
                                  borderBottom: "none",
                                  paddingBottom: 0,
                                  cursor: "pointer",
                                }}
                              >
                          {val?.orderType_id?.name}
                              </TableCell>
                              <TableCell
                                sx={{ borderBottom: "none", paddingBottom: 0 }}
                                align="center"
                              >
                                <div className={styles.counterDiv}>
                                  <div
                                    onClick={() =>
                                      handleUpdateCartRemove(
                                        val._id,
                                        val.quantity
                                      )
                                    }
                                    className={styles.decrease}
                                  >
                                    -
                                  </div>
                                  <div className={styles.counterValue}>
                                    {val.quantity}
                                  </div>
                                  <div
                                    onClick={() =>
                                      handleUpdateCarts(val._id, val.quantity)
                                    }
                                    className={styles.increase}
                                  >
                                    +
                                  </div>
                                </div>
                              </TableCell>

                              <TableCell
                                sx={{ borderBottom: "none", paddingBottom: 0 }}
                                align="right"
                              >
                                <Typography fontSize="1rem">
                                  {formatNewCurrency(
                                  (
                                    val.quantity *
                                    (val.item_id === null
                                      ? 0
                                      : val.item_id?.sale.length
                                      ? getDiscountedPrice(
                                          val.item_id.sale
                                            .map((item) => item.price)
                                            .reduce((acc, price) => acc + price, 0),
                                          "sale"
                                        )
                                      : val.item_id?.print.length
                                      ? getDiscountedPrice(
                                          val.item_id.print
                                            .map((item) => item.print_price)
                                            .reduce((acc, price) => acc + price, 0),
                                          "print"
                                        )
                                      : val.item_id?.rent.length
                                      ? getDiscountedPrice(
                                          val.item_id.rent
                                            .map((item) => item.rent_price)
                                            .reduce((acc, price) => acc + price, 0),
                                          "rent"
                                        )
                                      : JSON.parse(localStorage.getItem("product_prices")))
                                  ).toFixed(2)
                                  
                                  )}
                                </Typography>
                              </TableCell>
                              <TableCell
                                sx={{
                                  borderBottom: "none",
                                  paddingBottom: 0,
                                }}
                                align="center"
                              >
                                <Link  onClick={() =>
                                    handleWishlist(
                                      val.item_id === null
                                        ? ""
                                        : val.item_id?._id
                                    )
                                  }><FiHeart

                                  className={styles.wisliic}
                                />
                                </Link>
                              </TableCell>
                              <TableCell
                              
                                sx={{
                                  borderBottom: "none",
                                  paddingBottom: 0,
                                }}
                                align="center"
                              >
                                <Link  onClick={() => handleRemoveItemcart(val._id)}><FiX className={styles.remic}/></Link>
                              </TableCell>
                            </TableRow>
                          ))
                        )
                      ) : (
                        <TableRow>
                          <TableCell colSpan={8}>
                            <Box className={styles.loader_box}>
                              {" "}
                              No Data Found
                            </Box>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
             
            </div>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <div className={styles.total__div}>
              <p className={styles.carhndg}>Cart Totals</p>
              <div className={styles.cartitdtl}>
              {myCart?.result?.map((val) => (
                <div className={styles.totalvalue__div}>
                  <span className={styles.itemnm}>
                    {val.item_id?.name}
                  </span>
                  <span className={styles.iprisubt}>
                    {/* {subtotalarray.reduce((a,b) => a+b,0)} */}
                    ${
                     
                        val.quantity *
                          (val.item_id === null
                            ? 0
                            : val.item_id?.sale.length
                            ? getDiscountedPrice(
                                val.item_id.sale
                                  .map((item) => item.price)
                                  .reduce((acc, price) => acc + price, 0),
                                "sale"
                              )
                            : val.item_id?.print.length
                            ? getDiscountedPrice(
                                val.item_id.print
                                  .map((item) => item.print_price)
                                  .reduce((acc, price) => acc + price, 0),
                                "print"
                              )
                            : val.item_id?.rent.length
                            ? getDiscountedPrice(
                                val.item_id.rent
                                  .map((item) => item.rent_price)
                                  .reduce((acc, price) => acc + price, 0),
                                "rent"
                              )
                            : JSON.parse(
                                localStorage.getItem("product_prices")
                              ))
                    
                    }
                  </span>
                </div>
              ))}
              </div>
              
              <div className={styles.totalsub__div}>
                <span className={styles.itemnm}>
                  SubTotal
                </span>
                <span className={styles.iprisubt}>
                  {/* {subtotalarray.reduce((a,b) => a+b,0)} */}
                  ${subtotal !== 0
                    ? 
                          getDiscountedPrice(
                            subtotal 
                          )
                     
                    : 
                        JSON.parse(localStorage.getItem("product_prices")) !==
                          null
                          ? 
                              getDiscountedPrice(
                                JSON.parse(
                                  localStorage.getItem("product_prices")
                                )
                              
                            ).toFixed(2)
                          : "0"
                      }
                </span>
              </div>

              {/* <div className={styles.totalvalue__div}>
                <div>
                  <Typography fontSize="0.9rem">Total</Typography>
                  <Typography fontSize="0.75rem" color="#636365">
                    Free Basic Packaging
                  </Typography>
                </div>
                <Typography fontSize="1.05rem" fontWeight="600">
                  {formatNewCurrency(subtotal + vat)}
                </Typography>
              </div> */}
              <button className={styles.fillbtn}
                disabled={
                  subtotal === 0 &&
                  !JSON.parse(localStorage.getItem("product_prices"))
                }
                onClick={() => {
                  navigate("/user/checkout");
                  subtotal !== 0
                    ? localStorage.setItem(
                        "total_amount",
                       
                          subtotal +
                            vat +
                            JSON.parse(localStorage.getItem("product_prices"))
                       
                      )
                    : localStorage.setItem(
                        "total_amount",
                       
                          JSON.parse(localStorage.getItem("product_prices")) +
                            vat
                      
                      );
                }}
              >
                Proceed To Checkout
              </button>
              <button
              className={styles.wotfillbtn}
                onClick={() => navigate("/user/")}
               
              >
                Continue Shopping
              </button>
            </div>
          </Grid>
        </Grid>
      </div>
        </Container>
      </div>
      <div className={styles.newpaintngs}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
        <div className={styles.text_center}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>Newest Paintings</h3>
          </div>
          <div>
          <Grid container spacing={2}>
              {allitemdt.data &&
                allitemdt.data
                  .filter((val) => val.is_accepted === "Approved")
                  .map((item, i) =>
                    i >= 4 ? (
                      ""
                    ) : (
                      <Grid item xs={12} sm={6} md={3}>
                        <div className={styles.card__main}>
                        <div className={styles.card__top}>
                      <img
                        className={styles.card__Image}
                        src={item.image}
                        width="100%"
                        alt="img"
                      />
                    </div>
                          {/* <div className={styles.card__top}>
                            <img
                              className={styles.cardImage}
                              src={ item.image}
                              alt="imgs"
                            />
                          </div> */}
                          <div className={styles.pic_info}>
                      <div className={styles.card__middle}>
                        <span className={styles.titles}>{item.name}</span>
                      </div>
                      <div className={styles.info_like}>
                        <div className={styles.wishlst}>
                          <FiHeart />
                        </div>
                        <span className={styles.like_count}>
                          {item.likesview?.likes.length || 0}
                        </span>
                      </div>
                    </div>
                    <p className={styles.names}>
                    {item.artist_id && item.artist_id.first_name}{" "}
                    {item.artist_id && item.artist_id.last_name}
                        </p>
                        <div className={styles.price_sec}>
                      <div className={styles.card__bottom}>
                        <span className={styles.buys}>Buy</span>
                        <span className={styles.prices}>
                          $ {item?.sale?.price}
                        </span>
                      </div>
                      {item?.rent?.rent_price > 0 ? (
                        <>
                          {" "}
                          <div className={styles.hrs} />
                          <div>
                            <span className={styles.buys}>Rent/Month</span>
                            <span className={styles.prices}>
                              $ {item?.rent?.rent_price}
                            </span>
                          </div>
                        </>
                      ) : null}
                      {item?.print?.[0]?.print_price > 0 ? (
                        <>
                          {" "}
                          <div className={styles.hrs} />
                          <div>
                            <span className={styles.buys}>Print</span>
                            <span className={styles.prices}>
                              $ {item?.print[0]?.print_price}
                            </span>
                          </div>
                        </>
                      ) : null}
                    </div>

                          
                        </div>
                      </Grid>
                    )
                  )}
            </Grid>
          </div>
        </Container>
      </div>
      
    </div>
  );
}

export default MyCart;
