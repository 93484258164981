import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AlertsSuccess } from "../../../../common/alert/Alert"
import {
  fetchHeroSectionData,
  handleAddHeroSection,
  editHero,
  deleteHero,
} from "../../../../State/api/superadminApi"
import { setHerodata } from "../../../../State/Features/superadmin/herosectionSlice"
import Pagination from "../Pagination/Pagination"
import Addimgpopup from "../Popupdelete/addSubscription/addwithImage/Addimgpopup"
import Sixcolimg from "../Table/sixcolumn/Sixcolimg/Sixcolimg"
import Tableheader from "../Table/Tableheader/Tableheader"
import styles from "./Hero.module.css"

const buyhead = [
  {
    id: "image ",
    label: "Image",
  },
  {
    id: "order_id",
    label: "Title",
  },
  {
    id: "date",
    label: "Subtitle",
  },
  {
    id: "item",
    label: "Button",
  },
  {
    id: "type",
    label: "Position",
  },
  {
    id: "Created_Date",
    label: "Created Date",
  },
  {
    id: "amount",
    label: "Link",
  },
]
const sortdata = [
  { label: "Oldest to Newest", value: "low" },
  { label: "Newest to Oldest", value: "high" },
]
const filterdata = [
  { label: "Name (A-Z)", value: "name-asc" },
  { label: "Name (Z-A)", value: "name-desc" },
]
export default function Hero() {
  const [button, setButton] = useState({ type: "add", text: "Add" })
  const [open, setOpen] = useState(false)
  const [edit, setEdit] = useState(false)
  const [editdataHero, setEditdataHero] = useState()
  const [data, setData] = useState({ offset: 1 })
  const [condition, setCondition] = useState({
    search: "",
    filter: "",
    sorting: "",
  })

  const dispatch = useDispatch()
  const herodata = useSelector((state) => state.heroData.data)

  console.log("herodata",herodata);
  
  const fetchData = () => {
    dispatch(fetchHeroSectionData(data))
  }

  const handleDelete = (id) => {
    dispatch(deleteHero(id, fetchData))
  }
  const handleEditPopup = (data) => {
    setEditdataHero(data)
    setEdit(true)
  }
  const handleEdit = (id, data) => {
    dispatch(editHero(id, data, setEdit, fetchData))
  }
  useEffect(() => {
    fetchData()
  }, [data])
  const handleAdd = (dt) => {
    dispatch(handleAddHeroSection(dt, setOpen, fetchData))
  }

  const handleFilter = (event) => {
    const value = event.target.value
    setCondition({ ...condition, filter: value })
  }

  const handleSearch = (e) => {
    let value = e.target.value
    setCondition({ ...condition, search: value })
  }

  const handleSorting = (e) => {
    let value = e.target.value  
      setCondition({ ...condition, sorting: value })   
  }
  return (
    <div className={styles.mains}>
      {open ? (
        <Addimgpopup
          handleAdd={handleAdd}
          poptype="addhero"
          open={open}
          setOpen={setOpen}
        />
      ) : (
        ""
      )}
      {edit ? (
        <Addimgpopup
          open={edit}
          setOpen={setEdit}
          editdataHero={editdataHero}
          poptype="edithero"
          handleEdit={handleEdit}
        ></Addimgpopup>
      ) : (
        ""
      )}
      <div className={styles.components}>
        <AlertsSuccess />
        <Tableheader
          handleFilter={handleFilter}
          handleSorting={handleSorting}
          filterdata={filterdata}
          sortdata={sortdata}
          condition={condition}
          search={condition.search}
          handleSearch={handleSearch}
          setOpen={setOpen}
          button={button}
        />
        <Sixcolimg
          handleEditPopup={handleEditPopup}
          handleDelete={handleDelete}
          activetab="hero"
          head={buyhead}
          data={
            herodata &&
            [...herodata.data]
              .filter((val) => {
                const search = condition.search.toLowerCase().trim()
                const nameMatch =
                  val.title && val.title.toLowerCase().includes(search)

                const sub_title =
                  val.sub_title && val.sub_title.toLowerCase().includes(search)

                const position =
                  val.position && val.position.toLowerCase().includes(search)
                const button_name =
                  val.button_name &&
                  val.button_name.toLowerCase().includes(search)

                return nameMatch || sub_title || position || button_name
              })
              .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
              .sort((a,b)=>{
                if(condition.sorting === "high"){
                  return new Date(b.createdAt) - new Date(a.createdAt);
                }else if (condition.sorting === "low"){
                  return new Date(a.createdAt) - new Date(b.createdAt); // Sort by oldest
                }
                return 0; // Default case: no sorting
              })
              .sort((a,b)=>{
                if(condition.filter === "name-asc"){
                  return a.title.localeCompare(b.title, undefined, {
                    sensitivity: "base",
                  });
                }else if(condition.filter === "name-desc"){
                  return b.title.localeCompare(a.title, undefined, {
                    sensitivity: "base",
                  });
                }
                return 0;
              })
          }
        />
        <Pagination
          totaldata={herodata && herodata.totalCount}
          pagination={data}
          setPagination={setData}
        />
      </div>
    </div>
  )
}
