import React from "react"
import styles from "./Commondahboard.module.css"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormHelperText from "@mui/material/FormHelperText"
import { useTheme } from "@mui/material/styles"
import OutlinedInput from "@mui/material/OutlinedInput"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import { useNavigate } from "react-router-dom"
import { AlertsSuccess } from "../../../../../common/alert/Alert"
import { Grid} from "@mui/material";

export default function Commondashboard(props) {
  console.log(props, "props earning--")
  const [age, setAge] = React.useState("")

  return (
    <Grid  container spacing={2}>
      {props.data &&
        props.data.map((val) => (
          <Grid item xs={12} sm={4} md={3}>
          <div className={styles.card}>
            <div className={styles.img_div}>
              <img src={val.src} alt="img" />
            </div>
            <h3>{val.field}</h3>
            <h4 className={styles.left_align}>
              {val.field === "Total Earnings" ? "$" + val.total : val.total}
            </h4>
          </div>
      </Grid>
        ))}
    </Grid>
  )
}
