import {
 Autocomplete,
 Button,
 Checkbox,
 FormControl,
 FormControlLabel,
 Grid,
 Radio,
 RadioGroup,
 TextField,
 Typography,
} from "@mui/material";
import React from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Commonthirdsecond from "../common/Commonthirdsecond";
import Steppergallery from "../../../../../../gallery/pages/uploadArt/stepper/Steppergallery";
import styles from "./Digitalart.module.css";
import { AlertsSuccess } from "../../../../../../common/alert/Alert";
const data = {
 main_heading: "Price & Details",
 sub_heading: "Availability",
 sale: [],
 sale_pricing: [
  {
   id: 1,
   label: "Price of artwork",
   type: "text",
   placeholder: "Enter Price",
   name: "price",
  },
  {
   id: 2,
   label: "Artist Benefit",
   type: "text",
   placeholder: "------",
   name: "price_atwork",
  },
  {
   id: 3,
   label: "Shipping & Handling paid by Collector",
   type: "text",
   placeholder: "-------",
   name: "price_atwork",
  },
  {
   id: 4,
   label: "Price the Collector will see",
   type: "text",
   placeholder: "------",
   name: "price_atwork",
  },
 ],
 print_size: [
  {
   id: 1,
   label: "Size",
   placeholder: "small",
   name: "size",
  },
 ],
};
const stage = 2;
export default function Digitalart(props) { 
 return (
  <div className={styles.mains}>
    <AlertsSuccess />
   <Grid container spacing={{ sm: 5 }} columns={{ xs: 12, sm: 12, md: 12 }}>
    <Grid item xs={0} sm={4} md={2}></Grid>
    <Grid item xs={12} sm={8} md={10} sx={{ my: 2 }}>
     <Steppergallery stage={stage} />
    </Grid>
   </Grid>
   <Commonthirdsecond
    data={data}
    uploadArt={props.uploadArt}
    setUploadArt={props.setUploadArt}
    handleChangedata={props.handleChangedata}
    category={props.category}
   />
  </div>
 );
}
