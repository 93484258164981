import {Grid, Link, Container } from "@mui/material";
import React from 'react';
import styles from "./TradeProgramApplication.module.css";
import CssBaseline from "@mui/material/CssBaseline";
import { RiCheckFill } from "react-icons/ri";
import PhoneInput from "react-phone-input-2";
function TradeProgramApplication() {
  const scrollToSection = () => {
    const section = document.getElementById('target-section');
    if (section) {
      // Calculate offset to account for the fixed header
      const headerOffset = 100; // Height of your fixed header
      const sectionPosition = section.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = sectionPosition - headerOffset;

      // Scroll to the calculated position
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  const featurebeni = [
    {
      content: <p>Complimentary expert art consultation and curated selections tailored to your project.</p>,
    },
    {
      content: <p>Enjoy exclusive savings starting with 10% off on all purchases above 1500 USD.</p>,
    },
    {
      content: <p>Benefit from dedicated customer support to assist with every aspect of your order.</p>,
    },
    {
      content:<p>Access end-to-end art solutions from design selection to flawless installation.</p>,
    },
    {
      content:<p>Get priority access to our latest art collections.</p>
    },
    {
      content:<p>Fast delivery of the artwork by consistently meeting the project deadlines.</p>
    },
  ];

  
  return (
    <React.Fragment>
      <CssBaseline />
    <div className={styles.aboutus}>
      
      <section className={styles.hero_main}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
        <div className={`${styles.pageitle} ${styles.max_width}`}>
              <h1>Trade Program Application</h1>
              <Link  onClick={(e) => {
          e.preventDefault(); // Prevent default link behavior
          scrollToSection(); // Call scroll function
        }}
              className={styles.apply_btnwht}
            >
              Connect with Us
            </Link>
            </div>
</Container>
        </section>
      <section className={styles.sec_sc}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
        
        <Grid container spacing={2} sx={{justifyContent:"space-between", alignItems:"center"}}>
        <Grid item xs={12} sm={6} md={5}>
        <div className={styles.leftText}>
              <h4 className={styles.abttitle}>Art Smiley Trade Program Application</h4>
              <p className={styles.prtra}>The Art Smiley Trade Program is specifically tailored for interior designers, architects, art consultants, and other industry professionals seeking to elevate their projects with high-quality, original artwork. This exclusive program offers a comprehensive suite of benefits, designed to simplify the art acquisition process and provide customized solutions for both residential and commercial projects. Art Smiley’s Trade Program grants access to expertly curated collections and dedicated support, ensuring the seamless integration of art to elevate the aesthetic and ambiance of any environment.</p>
                
                
              </div>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
         
              <div className={styles.vintageframeDiv}>
                <img
                  className="img-fluid"
                  src={ "/img/vintage-wood-frame.png"} alt=""
                />
              </div>
        </Grid>
        </Grid>
        </Container>
      </section>
      <section className={styles.sec_shgn}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
        <div className={styles.text_center}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={`${styles.sec_title}`}>Art Smiley Trade Program Benefits</h3>
          </div>
      {/* Tab Content */}
      <div className={styles.agtd}>
      <Grid container spacing={5} sx={{justifyContent:"space-between",alignItems:"center"}}>
        <Grid item xs={12} sm={6} md={6}>
        <div>
              <img src={"/img/Mockup.png"} alt="mockup" className="img-fluid" />
              </div>
        </Grid>
        <Grid item xs={12} sm={6} md={5}>
        {featurebeni.map((val) => (
                <div  className={styles.feabox}>
                <div className={styles.feabc}>
                  <div className={styles.iconst}>
                  <RiCheckFill className={styles.usicon} />
                  </div>
                  <div>{val.content}
                  </div>
                </div>
                </div>
                  ))}
                  {/* <Link href="#Programapplication"
                  className={styles.apply_btn}
                > Explore <RiArrowRightLine className={styles.ariconb} />
                </Link> */}
          
        </Grid>
        </Grid>
      </div>
      
        </Container>
      </section>
      <section className={styles.sec_sc} id="target-section">
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
        <div className={`${styles.text_center} ${styles.max_width}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>Kindly fill in the form below with the relevant information</h3>
          </div>
        <Grid container spacing={2} sx={{justifyContent:"center"}}>
        <Grid item xs={12} sm={10} md={8}>
        <div className={styles.stcgbox}>
                  <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group1">
                <label>First Name *</label>
                <input
                  id="outlined-basic"
                  variant="outlined"
                  placeholder=""
                  className="form-control"
                  name="first_name"
                />
                
                </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group1">
                <label>Phone *</label>
                <PhoneInput
                  inputStyle={{ background: "transparent" }}
                  containerStyle={{
                    background: "transparent",
                    width: "100%",
                  }}
                  placeholder="Enter your phone number"
                  country={"in"}
                  name="phone"
                
                />
                </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group1">
                <label>Email *</label>
                <input
                  id="outlined-basic"
                  variant="outlined"
                  placeholder=""
                  className="form-control"

                  name="email"
                />
                </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group1">
                <label>Company Name *</label>
                <input
                  id="outlined-basic"
                  variant="outlined"
                  placeholder=""
                  className="form-control"
                  name="company_name"
                />
                
                </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group1">
                <label>Designation *</label>
                <input
                  id="outlined-basic"
                  variant="outlined"
                  placeholder=""
                  className="form-control"
                  name="designation"
                />
                
                </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group1">
                <label>Company Website Link*</label>
                <input
                  id="outlined-basic"
                  variant="outlined"
                  placeholder=""
                  className="form-control"
                  name="company_website"
                />
                
                </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                                                        <div className="form-group1">
                                                      <label style={{marginBottom:"0",marginTop:"15px"}}>Please provide us the links of your social media platform here</label>
                                                      </div>
                                                      </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group1">
                <label>Company Facebook Link</label>
                <input
                  id="outlined-basic"
                  variant="outlined"
                  placeholder=""
                  className="form-control"
                  name=""
                />
                
                </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group1">
                <label>Company Instagram Link</label>
                <input
                  id="outlined-basic"
                  variant="outlined"
                  placeholder=""
                  className="form-control"
                  name=""
                />
                
                </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group1">
                <label>Company Twitter Link</label>
                <input
                  id="outlined-basic"
                  variant="outlined"
                  placeholder=""
                  className="form-control"
                  name=""
                />
                
                </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group1">
                <label>Company Linkedin Link</label>
                <input
                  id="outlined-basic"
                  variant="outlined"
                  placeholder=""
                  className="form-control"
                  name=""
                />
                
                </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group1">
                <label>Company Youtube Link</label>
                <input
                  id="outlined-basic"
                  variant="outlined"
                  placeholder=""
                  className="form-control"
                  name=""
                />
                
                </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                  <div className="form-group1">
                <label>Company  Address *</label>
                <input
                  id="outlined-basic"
                  variant="outlined"
                  placeholder=""
                  className="form-control"
                  name=""
                />
                
                </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group1">
                <label>City *</label>
                <input
                  id="outlined-basic"
                  variant="outlined"
                  placeholder=""
                  className="form-control"
                  name=""
                />
                
                </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group1">
                <label>State *</label>
                <input
                  id="outlined-basic"
                  variant="outlined"
                  placeholder=""
                  className="form-control"
                  name=""
                />
                
                </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group1">
                <label>Country *</label>
                <input
                  id="outlined-basic"
                  variant="outlined"
                  placeholder=""
                  className="form-control"
                  name=""
                />
                
                </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group1">
                <label>Zip Code *</label>
                <input
                  id="outlined-basic"
                  variant="outlined"
                  placeholder=""
                  className="form-control"
                  name=""
                />
                
                </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                  <div className="form-group1">
                <label>Project Details *</label>
                <textarea
                  maxRows={5}
                  minRows={5}
                 className="form-control"
                  // onChange={props.handleChangedata}
                  // value={props.uploadArt.description}
                  name="message"
                  fullWidth
                  placeholder=""
                />
                </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                  <div className="form-group1">
                <label>
                Are you interested in receiving the email updates? <br/>
                  <p><input
                  type="checkbox" name=""
                /> Yes</p> <p>
                  <input
                type="checkbox"  name=""
              /> No</p>
                 </label>
                
                </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                  <div className={styles.text_right}>
               <button
                className={styles.apply_btn}
              >
                Submit
              </button>
               </div>
               <div>
                <p className={styles.sbychsbu}>By completing this form, you consent to receive promotional emails regarding the Art Smiley Trade Program. You may opt out at any time. <Link href="/user/privacypolicy" target="_blank">Privacy Policy</Link> <Link href="/user/termsconditions" target="_blank">Terms and Conditions</Link>.</p>
               </div>
                  </Grid>
                  </Grid>
</div>
          </Grid>
        </Grid>
        
        </Container>
      </section>
    </div>
    </React.Fragment>
  );
}

export default TradeProgramApplication;
