
import React from "react";
import styles from "./Commoncard.module.css";
import homeImage from "../../../../../constant";
import { useNavigate } from "react-router-dom";
import { RiUserLine } from "react-icons/ri";
export default function Commoncard(props) {
  const navigate = useNavigate();
  const dataBuyer = [
    {
      title: "I Am An Art Lover, A Collector",
      content:
        "Create an account to save your favorites and to receive personal offers.",
      already: "Already have an account ?",
      src: "/img/Avtar.png",
      buttonTitle: "Create An Account",
    },
  
  
  ];
  return (
    <>
      {/* {
            props.type === 'web' ?
                // <Box sx={{ mt: { xs: '-20px', sm: '-64px' } }} className={props.data.length === props.i + 1 ? styles.cancel_imgweb : styles.cancel_img_hide_web} >
                //     {props.data.length === props.i + 1 ?
                //         <img alt='img' onClick={() => props.setOpen(false)} src={ '/img/cancel_cross.png'} height='20px' width='20px' />
                //         : ''}
                // </Box> 
                "" :
                <div className={props.data.length === props.i + 1 ? styles.cancel_img : styles.cancel_img_hide} >
                    {props.data.length === props.i + 1 ?
                        <img alt='img' onClick={() => props.setOpen(false)} src={ '/img/cancel_cross.png'} height='20px' width='20px' />
                        : ''}
                </div>
        } */}
        <div>
          <div>
            <div className={styles.icdvn}><RiUserLine className={styles.icvn}/></div>
          </div>
            <h3 className={styles.sucrhndg}>I Am An Art Lover, A Collector</h3>
            <p className={styles.orpra}>Create an account to save your favorites and to receive personal offers.</p>
            <button className={styles.apply_btn} onClick={() => navigate("/artsmiley/signup", {state: {type: "buyer"}})}>Create An Account</button>
          </div>
        
    </>
  );
}
