import { height } from "dom7"
import { Errornotify, Successnotify } from "../../common/alert/Alert"
import { setbuyerOfferData } from "../Features/Artist/buyerOfferSlice"
import { setQuateData } from "../Features/Artist/quateDataSlice"
import { setRecreateData } from "../Features/Artist/recreateSlice"
import { setLoader } from "../Features/common/loaderSlice"
import { removeUser } from "../Features/common/userSlice"
import api from "./Api"
import { setadminOfferData } from "../Features/Artist/adminOfferSlice"
import axios from "axios"
import { fetchArtsData } from "./superadminApi"

export function editArtistProfile(data, navigate) {
  return async function fetcheditArtistProfile(dispatch) {
    dispatch(setLoader(true))
    try {
      const formData = new FormData()
      let dt = {
        social_links: data.social_links,
      }
      for (let dataKey in dt) {
        if (dataKey === "social_links") {
          for (let previewKey in dt[dataKey]) {
            formData.append(
              `social_links[${previewKey}]`,
              dt[dataKey][previewKey]
            )
          }
        }
      }
      data.category_id.forEach(function (element, index) {
        formData.append(`category_id[${index}][_id]`, element._id)
      })
      data.art_price_range.forEach(function (element, index) {
        formData.append(`art_price_range[${index}]`, element)
      })
      data.promoting_by_reason.forEach(function (element, index) {
        formData.append(`promoting_by_reason[${index}]`, element)
      })
      formData.append("exp", data.exp)
      formData.append("online_art_gallary_URL", data.online_art_gallary_URL)
      formData.append("arts_solds_in_past_12_months", 12)
      formData.append("produce_art_each_month", 1)
      formData.append("artist_email", data.email)
      formData.append("exclusive_work", data.exclusive_work)
      formData.append("completion", data.completion)
      formData.append("language", data.language)
     
      formData.append("is_fulltime_artist", data.is_fulltime_artist)
      formData.append("artist_type_id", data.artist_type_id)
      formData.append("is_featured", false)
      formData.append(
        "is_another_art_galary_representation",
        data.is_another_art_galary_representation
      )

      formData.append("addresses[0][nationality]", data.nationality);
      formData.append("addresses[0][country]", data.country);
      formData.append("first_name", data.first_name)
      formData.set("last_name", data.last_name)
      formData.append("backgroundImage", data.backgroundImage)
      formData.append("image", data.image)
      formData.append("phone", data.phone)
      formData.append("ID_proof", data.ID_proof)
      // formData.append("CV", data.CV);
      formData.append("portfolio", data.portfolio)
      formData.append("art_work", data.art_work);

      // formData.append("bank_name", data.bank_name);
      // formData.append("account_holder_name", data.account_holder_name);
      // formData.append("routing_ABA_number", data.routing_ABA_number);
      // formData.append("account_number", data.account_number);
      // formData.append("bank_location", data.bank_location);
      // formData.append("account_type", data.account_type);
      // formData.append("bank_address", data.bank_address);
      // formData.append("swift_code", data.swift_code);
      // formData.append("exhibition_part_count", data.exhibition_part_count);
      // formData.append("exhibition_id", data.exhibition_id);
      // formData.append("auction_id", data.auction_id);
      // formData.append("about", data.about);

      for (var pair of formData.entries()) {
      }
      // const apiResponse = await axios.post(
      //   "http://192.168.29.154:8000/api/artist/updateArtistProfile",
      //   formData
      // );
      const apiResponse = await api.multipartApi(
        "api/artist/updateArtistProfile",
        "POST",
        formData
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        setTimeout(() => {
          // window.location.replace("/artsmiley/artist/form-submission");
        }, 500)
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          dispatch(setLoader(false))
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
      dispatch(setLoader(false))
      // Errornotify("Network error");
    }
  }
}
export function updateArtistProfile(data, handleFetchArtistProfile) {
  return async function fetcheditArtistProfile(dispatch) {
    dispatch(setLoader(true))
    try {
      const formData = new FormData()
      let dt = {
        social_links: data.social_links,
      }
      for (let dataKey in dt) {
        if (dataKey === "social_links") {
          for (let previewKey in dt[dataKey]) {
            formData.append(
              `social_links[${previewKey}]`,
              dt[dataKey][previewKey]
            )
          }
        }
      }

      // data.artist_type_id.forEach(function (element, index) {
      //   formData.append(`artist_type_id[${index}]`, element._id);
      // });
      formData.append("first_name", data.first_name)
      formData.set("last_name", data.last_name)
      formData.append("backgroundImage", data.backgroundImage)
      formData.append("about", data.about)
      formData.append("professional_details",data.professional_details)
      formData.append("award_prize", data.award_prize)
      formData.append("image", data.image)
      formData.append("phone", data.phone)
      formData.append("artist_type_id", data.artist_type_id)
      formData.append("ID_proof", data.ID_proof)
      formData.append("CV", data.CV)
      formData.append("qualification", data.qualification)
      formData.append("subsciption_plan_id", data.subsciption_plan_id)
      formData.append("portfolio", data.portfolio)
      formData.append("biography", data.biography)
      formData.append("degree_year", data.degree_year)
      formData.append("art_work", data.art_work)
      formData.append(
        "inspiration_behind_artwork",
        data.inspiration_behind_artwork
      )
      formData.append("bank_name", data.bank_name)
      formData.append("account_holder_name", data.account_holder_name)
      formData.append("routing_ABA_number", data.routing_ABA_number)
      formData.append("account_number", data.account_number)
      formData.append("bank_location", data.bank_location)
      formData.append("account_type", data.account_type)
      formData.append("bank_address", data.bank_address)
      formData.append("city", data.city)
      formData.append("state", data.state)
      formData.append("zipcode", data.zipcode)
      formData.append("address1", data.address1)
      formData.append("address2", data.address2)

      const apiResponse = await api.multipartApi(
        "api/artist/myprofileupdateArtist",
        "POST",
        formData
      )
      if (apiResponse.status === 200) {
        localStorage.setItem(
          "user_name",
          data.first_name + " " + data.last_name
        )
        Successnotify(apiResponse.data.message)
        handleFetchArtistProfile()
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
          dispatch(setLoader(false))
        }
      }
    } catch (err) {
      dispatch(setLoader(false))
      // Errornotify("Network error");
    }
  }
}
export function updateArtistProfileShippingAddress(data, handleFetchArtistProfile) {
  return async function fetcheditArtistProfile(dispatch) {
    dispatch(setLoader(true))
    try {
      const formData = new FormData()
      let dt = {
        social_links: data.social_links,
      }
    

    
      formData.append("city", data.city)
      formData.append("state", data.state)
      formData.append("zipcode", data.zipcode)
      formData.append("address1", data.address1)
      formData.append("address2", data.address2)
      formData.append("country", data.country)
      const apiResponse = await api.multipartApi(
        "api/artist/myprofileupdateArtist",
        "POST",
        formData
      )
      if (apiResponse.status === 200) {
     
        Successnotify(apiResponse.data.message)
     
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
          dispatch(setLoader(false))
        }
      }
    } catch (err) {
      dispatch(setLoader(false))
      // Errornotify("Network error");
    }
  }
}


export function updateGalleryProfile(data, handleFetchArtistProfile) {
  return async function fetcheditArtistProfile(dispatch) {
    dispatch(setLoader(true))
    try {
      const formData = new FormData()
      let dt = {
        social_links: data.social_links,
      }
      for (let dataKey in dt) {
        if (dataKey === "social_links") {
          for (let previewKey in dt[dataKey]) {
            formData.append(
              `social_links[${previewKey}]`,
              dt[dataKey][previewKey]
            )
          }
        }
      }

      // data.artist_type_id.forEach(function (element, index) {
      //   formData.append(`artist_type_id[${index}]`, element._id);
      // });
      formData.append("first_name", data.first_name)
      formData.set("last_name", data.last_name)
      formData.append("backgroundImage", data.backgroundImage)
      formData.append("image", data.image)
      formData.append("phone", data.phone)
      formData.append("artist_type_id", data.artist_type_id)
      formData.append("ID_proof", data.ID_proof)
      formData.append("CV", data.CV)
      formData.append("qualification", data.qualification)
      formData.append("subsciption_plan_id", data.subsciption_plan_id)
      formData.append("portfolio", data.portfolio)
      formData.append("biography", data.biography)
      formData.append("degree_year", data.degree_year)
      formData.append("art_work", data.art_work)
      formData.append(
        "inspiration_behind_artwork",
        data.inspiration_behind_artwork
      )
      formData.append("bank_name", data.bank_name)
      formData.append("account_holder_name", data.account_holder_name)
      formData.append("routing_ABA_number", data.routing_ABA_number)
      formData.append("account_number", data.account_number)
      formData.append("bank_location", data.bank_location)
      formData.append("account_type", data.account_type)
      formData.append("bank_address", data.bank_address)
      formData.append("city", data.city)
      formData.append("state", data.state)
      formData.append("zipcode", data.zipcode)
      formData.append("address1", data.address1)
      formData.append("address2", data.address2)

      const apiResponse = await api.multipartApi(
        "api/gallery/updateMyProfileForGallery",
        "PATCH",
        formData
      )
      if (apiResponse.status === 200) {
        localStorage.setItem(
          "user_name",
          data.first_name + " " + data.last_name
        )
        Successnotify(apiResponse.data.message)
        handleFetchArtistProfile()
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
          dispatch(setLoader(false))
        }
      }
    } catch (err) {
      dispatch(setLoader(false))
      // Errornotify("Network error");
    }
  }
}
// FETCH ARTIST PROFILE

// ADD AUCTION ART

export function handleAddArtAuction(data, setOpen, fetchData) {
  return async function addsubscriptionFeatureThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        "api/auction/addAuctionArt",
        "POST",
        [data]
      )
      if (apiResponse.status === 200) {
        Successnotify("Your Art added to the auction successfully.")
        fetchData()
        setOpen(false)
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          removeUser({
            signed: false,
            type: "",
            data: "",
          })
          Errornotify(apiResponse.data.message)
          // return 401;
        } else {
          Errornotify("Art is already exist in this auction.")
          // return false;
        }
      }
    } catch (err) {
    }
  }
}

// ADD EXHIBITION ART

export function handleAddArtExhibition(data, setOpen, fetchData) {
  return async function addsubscriptionFeatureThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        "api/exhibition/addArtInExhibition",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify("Your Art added to the exhibition successfully.")
        fetchData()
        setOpen(false)
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          removeUser({
            signed: false,
            type: "",
            data: "",
          })
          Errornotify(apiResponse.data.message)
          // return 401;
        } else {
          Errornotify("Art is already exist in this exhibition.")
          // return false;
        }
      }
    } catch (err) {
    }
  }
}

// QUATE REQUEST API'S

export function fetchQuateRequestData(data) {
  return async function fetchArtistDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        `api/requestQoute/fetchRequestQoutes`,
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        dispatch(setQuateData(apiResponse.data.data))
        dispatch(setLoader(false))
        // fetchData();
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          removeUser({
            signed: false,
            type: "",
            data: "",
          })
          return 401
        }
        return false
      }
    } catch (err) {
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}

export function acceptDeclineQuateRequest(id, data, fetchData, setOpen) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/requestQoute/acceptRejectRequestQoute/${id}`,
        "PATCH",
        data
      )
      if (apiResponse.status === 200) {
        setOpen(false)
        Successnotify(apiResponse.data.message)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          removeUser({
            signed: false,
            type: "",
            data: "",
          })
          // Errornotify(apiResponse.data.message)
        } else {
          // Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
    }
  }
}
// FETCH MATERIAL

export function fetchMaterial(setMaterial) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        `api/user/fetchItemMaterials`,
        "GET"
      )
      if (apiResponse.status === 200) {
        
        dispatch(setMaterial(apiResponse.data.data.data))
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
        } else {
        }
      }
    } catch (err) {
    }
  }
}
// FETCH SUBJECT

export function fetchSubject(setSubject) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        `api/itemSubject/fetchItemSubjects`,
        "GET"
      )
      if (apiResponse.status === 200) {
        
        dispatch(setSubject(apiResponse.data.data.data))
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
    }
  }
}
// FETCH STYLE

export function fetchStyle(setStyle) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(`api/user/getstyles`, "GET")
      if (apiResponse.status === 200) {
        dispatch(setStyle(apiResponse.data.response))
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
    }
  }
}
// FETCH COUNTRY

export function fetchCountry(setCountry) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(`api/user/getcountry`, "GET")
      if (apiResponse.status === 200) {
        dispatch(setCountry(apiResponse.data.response))
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
    }
  }
}
// ADD ART
export function handleAddArt(data, pricingData, printType, type, navigate) {
  console.log("data===>",data,"pricingData", pricingData,"printType", printType, "type", type, "navigate", navigate);
  
  return async function handleAddArtThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      var bodyFormData = new FormData()

      // let dt = {
      //   sale: data.sale,
      //   rent: data.rent,
      //   print: data.print,
      // };

      // for (let dataKey in dt) {
      //   if (dataKey === "sale") {
      //     for (let previewKey in dt[dataKey]) {
      //       bodyFormData.append(`sale[${previewKey}]`, dt[dataKey][previewKey]);
      //     }
      //   }
      //   if (dataKey === "rent") {
      //     for (let previewKey in dt[dataKey]) {
      //       bodyFormData.append(`rent[${previewKey}]`, dt[dataKey][previewKey]);
      //     }
      //   } else if (dataKey === "print") {
      //     for (let previewKey in dt[dataKey]) {
      //       if (previewKey === "size") {
      //         // for (let pKey in dt[previewKey]) {
      //         //   bodyFormData.append(
      //         //     `print[${previewKey}][${pKey}]`,
      //         //     dt[dataKey][previewKey][pKey]
      //         //   )
      //         // }
      //         let newSize = [data.print.size]
      //         newSize.forEach((data, index) => {
      //           Object.entries(data).forEach(([key, value]) => {
      //             bodyFormData.append(
      //               `print[${previewKey}][${index}][${key}]`,
      //               value
      //             );
      //           });
      //         });
      //         // for (var i = 0; i < data.print.size.length; i++) {
      //         //   bodyFormData.append(`print[${previewKey}]`, data.print.size[i]);
      //         // }
      //         // data.print.size.forEach(function (element, index) {
      //         //   bodyFormData.append(`print[${previewKey}][${index}][height]`, element.height);
      //         //   bodyFormData.append(`print[${previewKey}][${index}][width]`, element.width);
      //         // });
      //       } else {
      //         bodyFormData.append(
      //           `print[${previewKey}]`,
      //           dt[dataKey][previewKey]
      //         );
      //       }
      //     }
      //   }
      // }
      // data.images.forEach(function (element, index) {
      // bodyFormData.append(`images[${index}]`, element);
      // });
      data.medium_id.forEach(function (element, index) {
        bodyFormData.append(`medium_id[${index}][_id]`, element._id)
      })
      data.style_id.forEach(function (element, index) {
        bodyFormData.append(`style_id[${index}][_id]`, element._id)
      })
      data.material_id.forEach(function (element, index) {
        bodyFormData.append(`material_id[${index}][_id]`, element._id)
      })

      data.colors.forEach(function (element, index) {
        bodyFormData.append(`color_id[${index}][_id]`, element)
      })

      if (type.print && printType === "printbyme") {
        data.print.forEach(function (element, index) {
          bodyFormData.append(`print[${index}][edition]`, element.edition)
          bodyFormData.append(`print[${index}][qty]`, element.qty)
          bodyFormData.append(
            `print[${index}][size][height]`,
            element.size?.height
          )
          bodyFormData.append(
            `print[${index}][size][width]`,
            element.size?.width
          )
          bodyFormData.append(
            `print[${index}][print_price]`,
            element.print_price
          )
          bodyFormData.append(`print[${index}][material]`, element.material)
          bodyFormData.append(
            `print[${index}][artsmiley_commission_print]`,
            element.artsmiley_commission_print
          )
          bodyFormData.append(
            `print[${index}][artist_profit_print]`,
            element.artist_profit_print
          )
          bodyFormData.append(
            `print[${index}][price_of_collector_print]`,
            element.price_of_collector_print
          )
          bodyFormData.append(
            `print[${index}][print_weight]`,
            element.print_weight
          )
          bodyFormData.append(
            `print[${index}][package_type]`,
            element.package_type
          )
        })
      } else {
        data.print_by_artsmiley.forEach(function (element, index) {
          bodyFormData.append(
            `print_by_artsmiley[${index}][print_price]`,
            element.print_price
          )
          bodyFormData.append(
            `print_by_artsmiley[${index}][metal_thikness]`,
            element.metal_thikness
          )
        })
        data.print_by_artsmiley_material.forEach(function (element, index) {
          bodyFormData.append(
            `print_by_artsmiley[${index}][material]`,
            element.value
          )
        })
        pricingData.forEach(function (element, index) {
          bodyFormData.append(
            `print_by_artsmiley[${index}][product_cost][product_price]`,
            element.price
          )
          bodyFormData.append(
            `print_by_artsmiley[${index}][product_cost][product]`,
            element.product
          )
          bodyFormData.append(
            `print_by_artsmiley[${index}][product_commission]`,
            element.price
          )
        })
      }
      bodyFormData.append(`sale[sale_weight]`, data.sale.sale_weight)
      bodyFormData.append(`sale[qty]`, data.sale.qty)
      bodyFormData.append(`sale[price]`, data.sale.price)
      bodyFormData.append(
        `sale[price_of_collector]`,
        data.sale.price_of_collector
      )
      bodyFormData.append(`sale[artist_profit]`, data.sale.artist_profit)
      bodyFormData.append(
        `sale[artsmiley_commission]`,
        data.sale.artsmiley_commission
      )
      bodyFormData.append(
        `sale[package_type]`,
        data.sale.package_type
      )
      data.sale.rolled.forEach(function (element, index) {
        bodyFormData.append(
          `sale[rolled][${index}][rolled_price]`,
          element.rolled_price
        )
        //  bodyFormData.append(`sale[rolled][${index}][rolled_size]`, element.rolled_size)
      })
      if (type.rent) {
        bodyFormData.append(`rent[rent_weight]`, data.rent.rent_weight)
        bodyFormData.append(`rent[rent_price]`, data.rent.rent_price)
        bodyFormData.append(`rent[time]`, data.rent.time)
        bodyFormData.append(
          `rent[price_of_collector_rent]`,
          data.rent.price_of_collector_rent
        )
        bodyFormData.append(
          `rent[artist_profit_rent]`,
          data.rent.artist_profit_rent
        )
        bodyFormData.append(
          `rent[package_type]`,
          data.rent.package_type
        )
        bodyFormData.append(
          `rent[artsmiley_commission_rent]`,
          data.rent.artsmiley_commission_rent
        )
      }

      bodyFormData.append("name", data.name)
      bodyFormData.append("frame_color", data.frame_color)
      bodyFormData.append("package_type", data.package_type)
      bodyFormData.append("artist_name", data.artist_name)

      bodyFormData.append("orientation_id", data.orientation)

      bodyFormData.append("tags", "tags")
      bodyFormData.append("weight", "1")

      // bodyFormData.append("medium_id", data.medium_id);
      bodyFormData.append("copyright", data.copyright)
      bodyFormData.append("is_showcased", data.is_showcased)
      bodyFormData.append("category_id", data.category_id)
      bodyFormData.append("subject_id", data.subject_id)
      // bodyFormData.append("style_id", data.style_id);
      bodyFormData.append("width", data.width)
      bodyFormData.append("height", data.height)
      bodyFormData.append("depth", data.depth)
      bodyFormData.append("is_hanged", data.is_hanged)
      bodyFormData.append("is_signed", data.is_signed)
      bodyFormData.append("keywords", data.keywords?.join(","))
      bodyFormData.append("description", data.description)
      bodyFormData.append("tube_size", data.tube_size)
      bodyFormData.append("year", data.year)
      bodyFormData.append("image", data.image)
      data.images.forEach((file) => {
        bodyFormData.append("images", file); // "images" should match the backend field name
      });
     
      bodyFormData.append("item_type", data.item_type)
      // for (const value of bodyFormData.values()) {
      
      // }
      bodyFormData.append("medium", "medium")
      for (var pair of bodyFormData.entries()) {
      }      
      const apiResponse = await api.multipartApi(
        "api/art/addArt",
        "POST",
        bodyFormData
      )
      if (apiResponse.status === 200) {
        dispatch(setLoader(false))
        Successnotify(apiResponse.data.message)
        navigate("/arts")
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
      dispatch(setLoader(false))
    }
  }
}
export function handleUploadUpdateArt(
  artId,
  data,
  pricingData,
  printType,
  type,
  navigate
) {
  return async function handleAddArtThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      var bodyFormData = new FormData()

      // let dt = {
      //   sale: data.sale,
      //   rent: data.rent,
      //   print: data.print,
      // };

      // for (let dataKey in dt) {
      //   if (dataKey === "sale") {
      //     for (let previewKey in dt[dataKey]) {
      //       bodyFormData.append(`sale[${previewKey}]`, dt[dataKey][previewKey]);
      //     }
      //   }
      //   if (dataKey === "rent") {
      //     for (let previewKey in dt[dataKey]) {
      //       bodyFormData.append(`rent[${previewKey}]`, dt[dataKey][previewKey]);
      //     }
      //   } else if (dataKey === "print") {
      //     for (let previewKey in dt[dataKey]) {
      //       if (previewKey === "size") {
      //         // for (let pKey in dt[previewKey]) {
      //         //   bodyFormData.append(
      //         //     `print[${previewKey}][${pKey}]`,
      //         //     dt[dataKey][previewKey][pKey]
      //         //   )
      //         // }
      //         let newSize = [data.print.size]
      //         newSize.forEach((data, index) => {
      //           Object.entries(data).forEach(([key, value]) => {
      //             bodyFormData.append(
      //               `print[${previewKey}][${index}][${key}]`,
      //               value
      //             );
      //           });
      //         });
      //         // for (var i = 0; i < data.print.size.length; i++) {
      //         //   bodyFormData.append(`print[${previewKey}]`, data.print.size[i]);
      //         // }
      //         // data.print.size.forEach(function (element, index) {
      //         //   bodyFormData.append(`print[${previewKey}][${index}][height]`, element.height);
      //         //   bodyFormData.append(`print[${previewKey}][${index}][width]`, element.width);
      //         // });
      //       } else {
      //         bodyFormData.append(
      //           `print[${previewKey}]`,
      //           dt[dataKey][previewKey]
      //         );
      //       }
      //     }
      //   }
      // }
      // data.images.forEach(function (element, index) {
      // bodyFormData.append(`images[${index}]`, element);
      // });
      data.medium_id.forEach(function (element, index) {
        bodyFormData.append(`medium_id[${index}][_id]`, element._id)
      })
      data.style_id.forEach(function (element, index) {
        bodyFormData.append(`style_id[${index}][_id]`, element._id)
      })
      data.material_id.forEach(function (element, index) {
        bodyFormData.append(`material_id[${index}][_id]`, element._id)
      })

      data.colors.forEach(function (element, index) {
        bodyFormData.append(`color_id[${index}][_id]`, element)
      })

      if (printType === "printbyme") {
        data.print.forEach(function (element, index) {
          bodyFormData.append(`print[${index}][edition]`, element.edition)
          bodyFormData.append(`print[${index}][qty]`, element.qty)
          bodyFormData.append(
            `print[${index}][size][height]`,
            element.size?.height
          )
          bodyFormData.append(
            `print[${index}][size][width]`,
            element.size?.width
          )
          bodyFormData.append(
            `print[${index}][print_price]`,
            element.print_price
          )
          bodyFormData.append(`print[${index}][material]`, element.material)
          bodyFormData.append(
            `print[${index}][artsmiley_commission_print]`,
            element.artsmiley_commission_print
          )
          bodyFormData.append(
            `print[${index}][artist_profit_print]`,
            element.artist_profit_print
          )
          bodyFormData.append(
            `print[${index}][price_of_collector_print]`,
            element.price_of_collector_print
          )
          bodyFormData.append(
            `print[${index}][print_weight]`,
            element.print_weight
          )
          bodyFormData.append(
            `print[${index}][package_type]`,
            element.package_type
          )
        })
      } else {
        data.print_by_artsmiley.forEach(function (element, index) {
          bodyFormData.append(
            `print_by_artsmiley[${index}][print_price]`,
            element.print_price
          )
          bodyFormData.append(
            `print_by_artsmiley[${index}][metal_thikness]`,
            element.metal_thikness
          )
        })
        data.print_by_artsmiley_material.forEach(function (element, index) {
          bodyFormData.append(
            `print_by_artsmiley[${index}][material]`,
            element.value
          )
        })
        pricingData.forEach(function (element, index) {
          bodyFormData.append(
            `print_by_artsmiley[${index}][product_cost][product_price]`,
            element.price
          )
          bodyFormData.append(
            `print_by_artsmiley[${index}][product_cost][product]`,
            element.product
          )
          bodyFormData.append(
            `print_by_artsmiley[${index}][product_commission]`,
            element.price
          )
          bodyFormData.append(
            `print_by_artsmiley[${index}][package_type]`,
            element.package_type
          )
        })
      }
      bodyFormData.append(`sale[sale_weight]`, data.sale.sale_weight)
      bodyFormData.append(`sale[qty]`, data.sale.qty)
      bodyFormData.append(`sale[price]`, data.sale.price)
      bodyFormData.append(
        `sale[price_of_collector]`,
        data.sale.price_of_collector
      )
      bodyFormData.append(`sale[artist_profit]`, data.sale.artist_profit)
      bodyFormData.append(
        `sale[artsmiley_commission]`,
        data.sale.artsmiley_commission
      )
      bodyFormData.append(
        `sale[package_type]`,
        data.sale.package_type
      )
      data.sale.rolled.forEach(function (element, index) {
        bodyFormData.append(
          `sale[rolled][${index}][rolled_price]`,
          element.rolled_price
        )
        //  bodyFormData.append(`sale[rolled][${index}][rolled_size]`, element.rolled_size)
      })
      if (data.rent) {
        bodyFormData.append(`rent[rent_weight]`, data.rent.rent_weight)
        bodyFormData.append(`rent[rent_price]`, data.rent.rent_price)
        bodyFormData.append(`rent[time]`, data.rent.time)
        bodyFormData.append(
          `rent[price_of_collector_rent]`,
          data.rent.price_of_collector_rent
        )
        bodyFormData.append(
          `rent[artist_profit_rent]`,
          data.rent.artist_profit_rent
        )
        bodyFormData.append(
          `rent[artsmiley_commission_rent]`,
          data.rent.artsmiley_commission_rent
        )
        bodyFormData.append(
          `rent[package_type]`,
          data.rent.package_type
        )
      }

      bodyFormData.append("name", data.name)

      bodyFormData.append("orientation_id", data.orientation)

      bodyFormData.append("tags", "tags")
      bodyFormData.append("weight", "1")

      // bodyFormData.append("medium_id", data.medium_id);
      bodyFormData.append("copyright", data.copyright)
      bodyFormData.append("is_showcased", data.is_showcased)
      bodyFormData.append("category_id", data.category_id)
      bodyFormData.append("subject_id", data.subject_id)
      // bodyFormData.append("style_id", data.style_id);
      bodyFormData.append("width", data.width)
      bodyFormData.append("height", data.height)
      bodyFormData.append("depth", data.depth)
      bodyFormData.append("is_hanged", data.is_hanged)
      bodyFormData.append("is_signed", data.is_signed)
      bodyFormData.append("keywords", data.keywords?.join(","))
      bodyFormData.append("description", data.description)
      bodyFormData.append("year", data.year)
      bodyFormData.append("image", data.image)
      bodyFormData.append("images", data.images)
      bodyFormData.append("item_type", data.item_type)
      // for (const value of bodyFormData.values()) {
      
      // }
      bodyFormData.append("medium", "medium")
      for (var pair of bodyFormData.entries()) {
      }
      const apiResponse = await api.multipartApi(
        `api/art/updateArtbyId/${artId}`,
        "PATCH",
        bodyFormData
      )
      if (apiResponse.status === 200) {
        dispatch(setLoader(false))
        Successnotify("Artwork Updated.")
        setTimeout(() => {
          navigate("/arts")
        },1000)
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
      dispatch(setLoader(false))
    }
  }
}
// FETCH OFFER

//Fetch Admin offer in Artist and Gallery page

export function fetchAdminOffer(data) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        `api/offer/fetchOffers`,
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        dispatch(setadminOfferData(apiResponse.data.data))
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
      // Errornotify("Enternal Error");
    }
  }
}

// Fetch Recreate data in Artist and Gallery
export function fetchRecreateRequest(data) {
  return async function fetchArtistDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        `api/recreateRequest/fetchRecreateRequests`,
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        dispatch(setRecreateData(apiResponse.data.data))
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          return 401
        }
        return false
      }
    } catch (err) {
    }
  }
}

export function acceptDeclineRecreateRequest(id, data, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/recreateRequest/acceptDeclineRecreateRequest/${id}`,
        "PATCH",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          removeUser({
            signed: false,
            type: "",
            data: "",
          })
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
    }
  }
}

// FETCH BUYER OFFER for artist and gallery

export function fetchBuyerOffer(data) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        `api/makeOffer/fetchMakeOffers`,
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        dispatch(setbuyerOfferData(apiResponse.data.data))
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
      
      Errornotify("Enternal Error")
    }
  }
}

export function acceptDeclineBuyerOffer(id, data, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/makeOffer/acceptDeclineMakeOffer/${id}`,
        "PATCH",
        data
      )
      if (apiResponse.status === 200 || apiResponse.status === 400) {
        Successnotify(apiResponse.data.message)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          removeUser({
            signed: false,
            type: "",
            data: "",
          })
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
    }
  }
}
// affiliate profile update
export function editAffiliateProfile(data, setOpen, fetchData) {
  
  return async function fetcheditArtistProfile(dispatch) {
    try {
      const formData = new FormData()

      data.description.forEach(function (element, index) {
        formData.append(`description[${index}]`, element)
      })
      data.traffic_drivation.forEach(function (element, index) {
        formData.append(`traffic_drivation[${index}]`, element)
      })
      formData.append("image", data.image)
      formData.append("first_name", data.first_name)
      formData.set("last_name", data.last_name)
      formData.append("phone", data.phone)
      formData.append("email", data.email)
      formData.append(
        "affiliate_account_type_id",
        data.affiliate_account_type_id
      )
      formData.append("promote_art_smiley", data.promote_art_smiley)
      formData.append("audience_interest", data.audience_interest)
      formData.append("website_url", data.website_url)
      // formData.append("assigned_to", data.assigned_to);
      formData.append("app_url", data.app_url)
      // formData.append("CV", data.CV);
      formData.append("build_links", data.build_links)
      formData.append("generate_income", data.generate_income)
      formData.append("unique_visitor_count", data.unique_visitor_count)
      formData.append("primary_reason_to_join", data.primary_reason_to_join)
      formData.append("hear_about_us", data.hear_about_us)
      formData.append("website_about", data.website_about)
      formData.append("bank_name", data.bank_name)
      formData.append("account_holder_name", data.account_holder_name)
      // formData.append("routing_ABA_number", data.routing_ABA_number)
      // formData.append("account_number", data.account_number)
      formData.append("bank_location", data.bank_location)
      formData.append("account_type", data.account_type)
      formData.append("swift_code", data.swift_code)
      formData.append("bank_address", data.bank_address)
      formData.append("address_type", data.address_type)
      formData.append("address_line_1", data.address_line_1)
      formData.append("address_type_2", data.address_type_2)
      formData.append("city", data.city)
      formData.append("state", data.state)
      formData.append("country", data.country)
      formData.append("zipcode", data.zipcode)

      const apiResponse = await api.multipartApi(
        "api/affiliate/updateMyProfileForAffiliate",
        "PATCH",
        formData
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        setOpen(true)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
      // Errornotify("Network error");
    }
  }
}
export function handleApplyAffiliates(data, navigate) {
  
  return async function fetcheditArtistProfile(dispatch) {
    try {
      const formData = new FormData()

      data.description.forEach(function (element, index) {
        formData.append(`description[${index}]`, element)
      })
      data.build_links.forEach(function (element, index) {
        formData.append(`build_links[${index}]`, element)
      })


    
      data.traffic_drivation.forEach(function (element, index) {
     
        formData.append(`traffic_drivation[${index}]`, element);
      
      })
      formData.append("traffic_drivation_others", data.traffic_drivation_others)
      formData.append("description_others", data.description_others)


      formData.append("image", data.image)
      formData.append("first_name", data.first_name)
      formData.set("last_name", data.last_name)
      formData.append("email", data.email)
      formData.append("phone", data.phone)
      formData.append("company_name", data.company_name)
      formData.append("language", data.language)

      formData.append("addresses[0][country]", data.country);
      formData.append("addresses[0][is_default]", true)

      formData.append(
        "affiliate_account_type_id",
        data.affiliate_account_type_id
      )
      data.promote_art_smiley.forEach(function (element, index) {
        if (element === "Others") {
          // Append the additional input for "Others"
          formData.append(`promote_art_smiley[${index}]`, data.otherPromotionMethod);
        } else {
          formData.append(`promote_art_smiley[${index}]`, element);
        }
      });
      formData.append("otherSocialMethod", data.otherSocialMethod)
      formData.append("audience_interest", data.audience_interest)
      formData.append("website_url", data.website_url)
      formData.append("app_url", data.app_url)
    
      formData.append("generate_income", data.generate_income)
      formData.append("unique_visitor_count", data.unique_visitor_count)
      formData.append("primary_reason_to_join", data.primary_reason_to_join)
      if (data.hear_about_us === "Others") {
        formData.append(
          "hear_about_us",
          data.hear_about_us_other // Use the value from the "Others" input
        );
      } else {
        formData.append(
          "hear_about_us",
          data.hear_about_us
        );
      }

    
    
      formData.append("completion", data.completion)
      if (data.platform_influence_marketing === "Others") {
        formData.append(
          "platform_influence_marketing",
          data.other_social_influence // Use the value from the "Others" input
        );
      } else {
        formData.append(
          "platform_influence_marketing",
          data.platform_influence_marketing
        );
      }
     
      formData.append("strategy_influence_marketing", data.strategy_influence_marketing)

      const apiResponse = await api.multipartApi(
        "api/affiliate/updateAffiliateProfile",
        "POST",
        formData
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        setTimeout(() => {
          navigate("/artsmiley/affiliate/form-submission")
        }, 500)
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
      // Errornotify("Network error");
    }
  }
}
// Delete Art in Artist
export function deleteArtistArt(id, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/art/deleteArtById/${id}`,
        "DELETE"
      )

      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        console.log("apiResponse",apiResponse);
        
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
    }
  }
}

export function offerAssignToArt(artId, offerId, setOpen, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/offer/offerAssignToArt/${offerId}`,
        "PATCH",
        {
          art_id: [artId],
        }
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        dispatch(fetchArtsData({ is_accepted: "Approved", offset: 1 }))
     
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          removeUser({
            signed: false,
            type: "",
            data: "",
          })
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
    }
  }
}

export function offerAssignToArtAll(artId, offerId, setOpen, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/offer/offerAssignToArt/${offerId}`,
        "PATCH",
        {
          art_id: artId,
        }
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        dispatch(fetchArtsData({ is_accepted: "Approved", offset: 1 }))
     
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          removeUser({
            signed: false,
            type: "",
            data: "",
          })
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
    }
  }
}
export function offerRemoveToArt(artId, offerId, setOpen) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/offer/offerRemoveToArt/${artId}`,
        "DELETE",
        {
          offer_id: [offerId],
        }
      )
      if (apiResponse.status === 200 || apiResponse.status === 201) {
        Successnotify(apiResponse.data.message)
        // fetchData();
        dispatch(fetchArtsData({ is_accepted: "Approved", offset: 1 }))
     
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          removeUser({
            signed: false,
            type: "",
            data: "",
          })
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
    }
  }
}

//art
export function offerRemovedToArtAdmin(artId, offerId, setOpen, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/offer/offerRemoveToArt/${artId}`,
        "DELETE",
        {
          offer_id: [offerId],
        }
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
        setTimeout(() => {
          setOpen(false)
        }, 300)
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
    }
  }
}
