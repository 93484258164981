import React, { useEffect, useState } from "react";
import styles from "./Exshibitionartist.module.css";

import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";

import Tablesfivecol from "../../../components/Pages/List/Table/Fivecolumn/Tablefivecol";
import Commonexshibitiongallery from "../../../gallery/pages/exshibition/commonExshibitiongallery/Commonexshibitiongallery";
import Fillexshibition from "../../../gallery/components/popups/exshibition/Fillexshibition";
import Tables from "../../../components/Pages/List/Table/sixcolumn/Tables";
import Addpaintingexshibition from "./popup/Addpaintingexshibition";
import { useDispatch, useSelector } from "react-redux";
import { setExshibitiondata } from "../../../State/Features/superadmin/exshibitionSlice";
import {
  fetchExhibitionForArtistAndGallery,
  fetchExshibitionAdmin,
  fetchExshibitionData,
  handleAddExshibition,
} from "../../../State/api/superadminApi";
import Fivecoltable from "../../commonTables/FivecolimgArtist/Fivecoltable";
import Sixcoltable from "../../commonTables/SixcolimgArtist/Sixcoltable";
import { handleAddArtExhibition } from "../../../State/api/artistapi";
import { AlertsSuccess } from "../../../common/alert/Alert";
import ExhibitionTablePart from "../../commonTables/FivecolimgArtist/ExhibitionTablePast";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Pagination, PaginationItem } from "@mui/material";
import { validationForExhibitionArts } from "../../../validation/validationForExhibitionArts";
const moreOptions = [
  {
    id: "live_gallery",
    label: "Live",
    value: "live_gallery",
  },
  {
    id: "upcoming_gallery",
    label: "Upcoming",
    value: "upcoming_gallery",
  },
  // {
  //   id: "my_exshibition",
  //   label: "My Exhibition",
  //   value: "my_exshibition",
  // },
  {
    id: "past_gallery",
    label: "Past",
    value: "past_gallery",
  },
];

const requesthead = [
  {
    id: "artist_name ",
    label: "Exshibition Title",
  },
  {
    id: "mobile_number",
    label: "Place",
  },
  // {
  //   id: "email",
  //   label: "Free/Paid",
  // },
  {
    id: "assign_to",
    label: "Start Date",
  },
  {
    id: "type",
    label: "End Date",
  },
  {
    id: "action",
    label: "Status",
  },
];
const pendinghead = [
  {
    id: "artist_name ",
    label: "Exhibition's Title",
  },
  // {
  //   id: "mobile_number",
  //   label: "Place",
  // },
  {
    id: "email",
    label: "Free/Paid",
  },
  {
    id: "assign_to",
    label: "Start Date",
  },
  {
    id: "type",
    label: "End Date",
  },
  {
    id: "type",
    label: "Application End Date",
  },
  // {
  //   id: "action",
  //   label: "View",
  // },
];
const livehead = [
  {
    id: "artist_name ",
    label: "Exhibition's Title",
  },
  // {
  //   id: "mobile_number",
  //   label: "Place",
  // },
  {
    id: "email",
    label: "Free/Paid",
  },
  {
    id: "assign_to",
    label: "Start Date",
  },
  {
    id: "type",
    label: "End Date",
  },
  {
    id: "type",
    label: "Application End Date",
  },
  {
    id: "type",
    label: "Action",
  },
];
const sortdata = [
  { label: "By Start Date ↓", value: "low" },
  { label: "By Start Date ↑", value: "high" },
  { label: "By End Date ↓", value: "endlow" },
  { label: "By End Date ↑", value: "endhigh" },
];
const filterdata = [
  { label: "Free", value: "free" },
  { label: "Paid", value: "paid" },
  //  { label: "Rejected", value: "rejected" },
];
export default function Exshibitionartist() {
  const [open, setOpen] = React.useState(false);
  const [alertopen, setAlertopen] = React.useState(false);
  const [errors, setErrors] = useState({})
  const [button, setButton] = useState({ type: "add", text: "Create" });
  const [activetab, setAcitvetab] = useState("live_gallery");
  const [data, setData] = useState({ is_accepted: "", offset: 1 });
  console.log(data, "data");
  const [condition, setCondition] = useState({
    search: "",
    filter: "",
    sorting: "",
  });

  const [addArtinExhibition, setAddArtinExhibition] = useState({
    exhibition_id: "",
    item_id: "",
    price: "",
    description: "",
  });
  console.log(addArtinExhibition, "addArtinExhibition");

  const dispatch = useDispatch();
  const exhibitiondt = useSelector((state) => state.exhibitionAdminData?.data);
  console.log(exhibitiondt, "exhibitiondt");
  const fetchData = () => {
    dispatch(
      fetchExhibitionForArtistAndGallery({
        event:
          activetab === "live_gallery"
            ? "live"
            : activetab === "upcoming_gallery"
            ? "upcoming"
            : activetab === "past_gallery"
            ? "past"
            : "upcoming_gallery",

      })
    );
  };
  useEffect(() => {
    fetchData();
  }, [activetab]);

  const handleMorecategories = (e) => {
    setAcitvetab(e);
    if (e === "request_gallery") {
      setData({ is_accepted: "", offset: 1 });
    } else {
      setData({ is_accepted: "", offset: 1 });
    }
  };

  const handleFilter = (event) => {
    const value = event.target.value;
    setCondition({ ...condition, filter: value });
  };

  const handleSearch = (e) => {
    let value = e.target.value;
    setCondition({ ...condition, search: value });
  };

  const handleSorting = (e) => {
    let value = e.target.value;
    if (value === "low") {
      setCondition({ ...condition, sorting: value });
      dispatch(
        setExshibitiondata({
          data:
            exhibitiondt.exhibitions &&
            [...exhibitiondt.exhibitions].sort((a, b) => {
              if (a.start_date.toLowerCase() < b.start_date.toLowerCase())
                return -1;
            }),
          totalCount: exhibitiondt.totalCount,
        })
      );
    } else if (value === "high") {
      setCondition({ ...condition, sorting: value });
      dispatch(
        setExshibitiondata({
          data:
            exhibitiondt.exhibitions &&
            [...exhibitiondt.exhibitions].sort((a, b) => {
              if (a.start_date.toLowerCase() > b.start_date.toLowerCase())
                return -1;
            }),
          totalCount: exhibitiondt.totalCount,
        })
      );
    } else if (value === "endlow") {
      setCondition({ ...condition, sorting: value });
      dispatch(
        setExshibitiondata({
          data:
            exhibitiondt.exhibitions &&
            [...exhibitiondt.exhibitions].sort((a, b) => {
              if (a.end_date.toLowerCase() < b.end_date.toLowerCase())
                return -1;
            }),
          totalCount: exhibitiondt.totalCount,
        })
      );
    } else if (value === "endhigh") {
      setCondition({ ...condition, sorting: value });
      dispatch(
        setExshibitiondata({
          data:
            exhibitiondt.exhibitions &&
            [...exhibitiondt.exhibitions].sort((a, b) => {
              if (a.end_date.toLowerCase() > b.end_date.toLowerCase())
                return -1;
            }),
          totalCount: exhibitiondt.totalCount,
        })
      );
    } else {
      dispatch(fetchExshibitionData(data));
      setCondition({ ...condition, sorting: value });
    }
  };

  const handleAdd = () => {
    const {isValid, exhibitionErrors} = validationForExhibitionArts(addArtinExhibition);
    if(isValid) {
      dispatch(handleAddArtExhibition(addArtinExhibition, setOpen, fetchData));
      setOpen(false);
    }
    else {
      setErrors(exhibitionErrors);
    }
  };
  const handleInterested = (id) => {
  
    setAddArtinExhibition({ ...addArtinExhibition, exhibition_id: id });
    setOpen(true);
  };
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Adjust how many items you want per page

  // Function to handle page changes
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    window.scrollTo({
      top: 0, // Scroll to the top of the page
      behavior: "smooth", // Smooth scrolling effect
    });
  };

  // Slice the data based on current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = exhibitiondt?.exhibitions?.slice(startIndex, endIndex);
  console.log(currentItems, "currentItems");
  const safeItemsPerPage = Array.isArray(currentItems) ? currentItems : [];

  return (
    <div className={styles.mains}>
      <AlertsSuccess />
      {open ? (
        <Fillexshibition
          text="Exhibition"
          handleAdd={handleAdd}
          addArtinExhibition={addArtinExhibition}
          setAddArtinExhibition={setAddArtinExhibition}
          open={open}
          setOpen={setOpen}
          errors={errors}
        />
      ) : (
        ""
      )}
      {/* {open ? <Addpaintingexshibition open={open} setOpen={setOpen} /> : '' } */}
      <div className={styles.components}>
        <Commonexshibitiongallery
          handleFilter={handleFilter}
          handleSorting={handleSorting}
          handleSearch={handleSearch}
          filterdata={filterdata}
          sortdata={sortdata}
          condition={condition}
          search={condition.search}
          button={button}
          setAcitvetab={setAcitvetab}
          activetab={activetab}
          moreOptions={moreOptions}
          handleMorecategories={handleMorecategories}
          setOpen={setOpen}
        />

        {activetab === "upcoming_gallery" ? (
          <Sixcoltable
            activetab={activetab}
            head={livehead}
            handleInterested={handleInterested}
            data={safeItemsPerPage
              .filter((val) =>
                val.title.toLowerCase().includes(condition.search.toLowerCase())
              )
              .filter((val) => condition.filter === "free" ? !val.is_paid : condition.filter === "paid" ? val.is_paid : true)
              .sort((a, b) => {
                // Apply sorting based on the selected condition
                const sorting = condition.sorting || "default"; // Default condition if not set
                
                switch (sorting) {
                  case "low":
                    return new Date(a.start_date) - new Date(b.start_date); // Start date ascending
                  case "high":
                    return new Date(b.start_date) - new Date(a.start_date); // Start date descending
                  case "endlow":
                    return new Date(a.end_date) - new Date(b.end_date); // End date ascending
                  case "endhigh":
                    return new Date(b.end_date) - new Date(a.end_date); // End date descending
                  default:
                    return new Date(b.start_date) - new Date(a.start_date); // Default: start_date descending
                }
              })}
          />
        ) : activetab === "my_exshibition" ? (
          <Sixcoltable
            head={pendinghead}
            activetab={activetab}
            data={exhibitiondt?.data
              .filter((val) =>
                val.title.toLowerCase().includes(condition.search.toLowerCase())
              )
              .filter((val) => {
                if (condition.filter === "free") {
                  return val.is_paid === false;
                } else if (condition.filter === "paid") {
                  return val.is_paid === true;
                } else {
                  return val;
                }
              })}
          />
        ) : activetab === "past_gallery" ? (
          <ExhibitionTablePart
            head={pendinghead}
            activetab={activetab}
            data={safeItemsPerPage // Safely spread exhibitions
              .filter((val) =>
                val.title.toLowerCase().includes(condition.search.toLowerCase())
              )
              .filter((val) => condition.filter === "free" ? !val.is_paid : condition.filter === "paid" ? val.is_paid : true)
              .sort((a, b) => {
                // Apply sorting based on the selected condition
                const sorting = condition.sorting || "default"; // Default condition if not set
                switch (sorting) {
                  case "low":
                    return new Date(a.start_date) - new Date(b.start_date); // Start date ascending
                  case "high":
                    return new Date(b.start_date) - new Date(a.start_date); // Start date descending
                  case "endlow":
                    return new Date(a.end_date) - new Date(b.end_date); // End date ascending
                  case "endhigh":
                    return new Date(b.end_date) - new Date(a.end_date); // End date descending
                  default:
                    return new Date(b.start_date) - new Date(a.start_date); // Default: start_date descending
                }
              })}
          />
        ) : (
          <Fivecoltable
            head={livehead}
            activetab={activetab}
            handleInterested={handleInterested}
            data={safeItemsPerPage  // Safely spread exhibitions
              .filter((val) =>
                val.title.toLowerCase().includes(condition.search.toLowerCase())
              )
              .filter((val) => condition.filter === "free" ? !val.is_paid : condition.filter === "paid" ? val.is_paid : true)
              .sort((a, b) => {
                // Apply sorting based on the selected condition
                const sorting = condition.sorting || "default"; // Default condition if not set
                switch (sorting) {
                  case "low":
                    return new Date(a.start_date) - new Date(b.start_date); // Start date ascending
                  case "high":
                    return new Date(b.start_date) - new Date(a.start_date); // Start date descending
                  case "endlow":
                    return new Date(a.end_date) - new Date(b.end_date); // End date ascending
                  case "endhigh":
                    return new Date(b.end_date) - new Date(a.end_date); // End date descending
                  default:
                    return new Date(b.start_date) - new Date(a.start_date); // Default: start_date descending
                }
              })}
          />
        )}
          <Box className={styles.pagination_box} sx={{}}>
          <Pagination   count={Math.ceil(exhibitiondt?.exhibitions?.length / itemsPerPage)} // Total pages
        page={currentPage}
        onChange={handlePageChange}
        variant="outlined"
        shape="rounded"
        renderItem={(item) => (
          <PaginationItem
          sx={{
            "&.Mui-selected": {
              backgroundColor: "black", // Set the selected background to black
              color: "white", // Ensure the text color is visible
              "&:hover": {
                backgroundColor: "black", // Maintain black on hover
              },
            },
          }}
            slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
            {...item}
          />
        )} />
          </Box>
      </div>
    
      {activetab === "live_gallery" ? (
        <Collapse in={alertopen} sx={{ my: 8 }}>
          <Alert
            icon={false}
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setAlertopen(false);
                }}
              >
                <CloseIcon fontSize="inherit" sx={{ color: "#EF401A" }} />
              </IconButton>
            }
            sx={{
              mb: 2,
              color: "#EF401A",
              fontFamily: "DM Sans",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "21px",
              backgroundColor: "rgba(234, 84, 85, 0.12)",
              borderRadius: "4px",
            }}
          >
            You've Already Been a Part of 4 Auction This Year So Far, To Be a
            Part of Further Action Please <u>Upgrade</u> Your Plan for The Same
          </Alert>
        </Collapse>
      ) : (
        ""
      )}
    </div>
  );
}
