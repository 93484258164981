import { Button, FormControl, MenuItem, Select } from "@mui/material";
import styles from "./Tableheader.module.css";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
export default function TableheaderQuote(props) {

 // console.log("Table Header props====>", props);

  const location = useLocation();
  const navigate = useNavigate();

  const handleAddbtn = () => {
    if (window.location.pathname === "/arts") {
      navigate("/upload/stepfirst");
    } else {
      props.setOpen && props.setOpen(true);
    }
  };

  return (
    
    <div>
      <div className={styles.buyer_header}>
        <div className={styles.input_div}>
          <input
            onChange={props.handleSearch}
            name="search"
            value={props.search}
            className={styles.search_input}
            type="text"
            placeholder="Search by..."
          />
          
          <img
            className={styles.search_icon}
            src="/img/Search Symbol.png"
            height="20px"
            width="20px"
            alt="img"
          />
        </div>
        <div className={styles.header_action}>

          {props?.namefilter?.length >0 && 
            <FormControl>
            <Select
              value={props.condition?.name || ""}
              onChange={props.handleNameFilter && props.handleNameFilter}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              sx={{ height: "44px", color: "#636365" }}
              className={styles.selects}
              IconComponent={() => (
                <img
                  src="/img/dropdownicon.png"
                  width="13px"
                  height="10px"
                  style={{ marginRight: "20px" }}
                  alt="img"
                />
              )}
            >
              <MenuItem value="">Sort by Name</MenuItem>
              {props.namefilter &&
                props.namefilter.map((val) => (
                  <MenuItem value={val.value}>{val.label}</MenuItem>
                ))}
            </Select>
          </FormControl>
          }

          {!window.location.href.includes("print-pricing") &&
            !window.location.href.includes("reports") &&
            !window.location.href.includes("policies") &&
            props.activetab !== "artist_art_pending" &&
            props.activetab !== "artist_art_rejected" &&
            props.activetab !== "gallery_art_pending" &&
            props.activetab !== "gallery_art_rejected" && (
              <>
                <FormControl>
                  <Select
                    value={props.condition?.sorting || ""}
                    onChange={props.handleSorting && props.handleSorting}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{ height: "44px", color: "#636365" }}
                    className={styles.selects}
                    IconComponent={() => (
                      <img
                        src="/img/dropdownicon.png"
                        width="13px"
                        height="10px"
                        style={{ marginRight: "20px" }}
                        alt="img"
                      />
                    )}
                  >
                    <MenuItem value="">Sort by</MenuItem>
                    {props.sortdata &&
                      props.sortdata.map((val) => (
                        <MenuItem value={val.value}>{val.label}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
            
                    <FormControl>
                      <Select
                        value={props.condition?.filter || ""}
                        onChange={props.handleFilter && props.handleFilter}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        sx={{
                          height: "44px",
                          maxWidth: "150px",
                          color: "#636365",
                        }}
                        className={styles.selects}
                        IconComponent={() => (
                          <img
                            src="/img/dropdownicon.png"
                            width="13px"
                            height="10px"
                            style={{ marginRight: "20px" }}
                            alt="img"
                          />
                        )}
                      >
                        <MenuItem value="">All</MenuItem>
                        {props.filterdata &&
                          props.filterdata.map((val) => (
                            <MenuItem value={val.value}>{val.label}</MenuItem>
                          ))}
                      </Select>
                    </FormControl>
             
              </>
            )}
  
        </div>
      </div>
    </div>
  );
}
