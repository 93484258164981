import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./Tablefivecol.module.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { addData } from "../../../../../State/Features/Artist/profileSlice";
import CircularLoader from "../../../../../common/loader/Loader";
import moment from "moment";
import dayjs from "dayjs";

export default function Tablesfivecol(props) {
  console.log("props--SuperAdmin TableFivecol ", props);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const loader = useSelector((state) => state.loader);
  const [threedot_id, setThreedot_id] = React.useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const threedots = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setThreedot_id(event.target.id);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handledetailpage = (row, isExhibitionTitle = false) => {
    if (isExhibitionTitle && location.pathname === "/exhibitions") {
    
      navigate(`/exhibitions/${row._id}`);
      return;
    }

    // Default row navigation
    if (location.pathname === "/artist") {
      navigate(`/artist/${row._id}`);
    } else if (location.pathname === "/affiliate") {
      navigate(`/affiliate/${row._id}`);
    } else if (location.pathname === "/exhibitions") {
      navigate(`/exhibitions/${row._id}`);
    } else if (location.pathname === "/career") {
      navigate(`/career-detail/${row._id}`);
    }
    dispatch(addData(row));
  };

  const usertype = useSelector((state) => state.user.data);

  const [selectedArtistTypes, setSelectedArtistTypes] = useState({});
  useEffect(() => {
    if (Array.isArray(props.data)) {
      const initialArtistTypes = {};
      props.data.forEach((artist) => {
        // Extract the first name from the artist_type array or set as an empty string
        initialArtistTypes[artist._id] = artist.artist_type?.[0]?.name || "";
      });
      setSelectedArtistTypes(initialArtistTypes);
    }
  }, []);

  // Handle change of the dropdown value for each artist
  const handleArtistTypeChange = (event, artistId) => {
    const newValue = event.target.value;

    // Update the state with the new value for the specific artist
    setSelectedArtistTypes((prev) => ({
      ...prev,
      [artistId]: newValue,
    }));
  };

  return (
    <div className={styles.mains}>
      <TableContainer
        component={Paper}
        sx={{ backgroundColor: "#F6F6F6", color: "black" }}
      >
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#bfbbc3" }}>
              {props.head.map((column, i) => (
                <TableCell
                  className={styles.table_head}
                  align={props.head.length - 1 === i ? "left" : "left"}
                  sx={{
                    whiteSpace: "nowrap",
                    fontFamily: "DM Sans",
                    fontWeight: "500",
                    fontSize: "16px",
                    height: "21px",
                    color: "#1C1C1C",
                  }}
                  key={i}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {loader.loader ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={8}>
                  <Box className={styles.loader_box}>
                    {" "}
                    <CircularLoader size={60} />
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {props.data && props.data.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={8}>
                    <Box className={styles.loader_box}> No Data Found</Box>
                  </TableCell>
                </TableRow>
              ) : (
                props?.data &&
                props?.data
                  ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                  ?.map((row, i) => (
                    <TableRow
                      style={{ color: "#636365", height: "30px" }}
                      className={styles.table_row}
                      key={row._id}
                    >
                      <TableCell
                        sx={{
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "21px",
                          color: "#636365",
                          whiteSpace: "nowrap",
                        }}
                        style={{ cursor: "pointer" }}
                        className={styles.table_column}
                        align="left"
                      >
                        {/* Name fields */}
                        {props.activetab === "gallery_approved" ||
                        "gallery_decline" ? (
                          <>
                            {row.first_name} {row.last_name}
                          </>
                        ) : (
                          ""
                        )}

                        {/* Gallery name */}
                        {row.gallery_name}

                        {/* Hyperlink for exhibition titles */}
                        {props.activetab === "upcoming" || "past" ? (
                          <a
                            href={`/exhibitions/${row._id}`} // Dynamic href for the title link
                            onClick={(e) => {
                              e.preventDefault(); // Prevent default navigation for controlled behavior
                              handledetailpage(row, true); // Pass the exhibition title flag
                            }}
                            className={styles.hyperlink_title}
                          >
                            {row?.title}
                          </a>
                        ) : (
                          ""
                        )}

                        {/* Auction name */}
                        {props.activetab === "auctionlive" &&
                        usertype.type === "curator"
                          ? i + 1
                          : row.name}

                        {/* Additional fields */}
                        {props.activetab === "pending" ? (
                          row.first_name && row.last_name ? (
                            <Link
                              className={styles.hyperlink_title}
                              to={`/artists/${row._id}`}
                            >
                              {row.first_name + " " + row.last_name}
                            </Link>
                          ) : row.first_name || row.last_name ? (
                            <a href={`/artists/${row._id}`}>
                              {row.first_name || row.last_name}
                            </a>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}

                        {props.activetab === "request"
                          ? row.start_date &&
                            moment(row.start_date).format("DD-MM-YYYY")
                          : ""}
                        {props.activetab === "decline" ? (
                          <Link to={`#`} className={styles.hyperlink_title}>
                            {row.first_name + " " + row.last_name}
                          </Link>
                        ) : (
                          ""
                        )}
                        {props.activetab === "affiliatedecline" ? (
                          <Link
                            to={`/affiliate/${row?._id}`}
                            className={styles.hyperlink_title}
                          >
                            {row.first_name + " " + row.last_name}
                          </Link>
                        ) : (
                          ""
                        )}
                        {props.activetab === "affiliatepending" ? (
                          <Link
                            to={`/affiliate/${row?._id}`}
                            className={styles.hyperlink_title}
                          >
                            {row.first_name + " " + row.last_name}
                          </Link>
                        ) : (
                          ""
                        )}
                        {props.activetab === "affiliateapproved" ? (
                          <Link
                            to={`/affiliate/${row?._id}`}
                            className={styles.hyperlink_title}
                          >
                            {row.first_name + " " + row.last_name}
                          </Link>
                        ) : (
                          ""
                        )}
                        {props.activetab === "contact-us"
                          ? row.first_name + " " + row.last_name
                          : ""}
                        {props.activetab === "printpricing" ? row.material : ""}
                      </TableCell>

                      {props.activetab === "career" ? (
                        <>
                          <TableCell
                            sx={{
                              fontFamily: "DM Sans",
                              fontStyle: "normal",
                              fontWeight: "400",
                              fontSize: "16px",
                              lineHeight: "21px",
                              color: "#636365",
                            }}
                            className={styles.table_column}
                            align="left"
                          >
                            {moment(row?.createdAt)?.format("DD-MM-YYYY")}
                          </TableCell>
                        </>
                      ) : (
                        ""
                      )}

                      <TableCell
                        sx={{
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "21px",
                          color: "#636365",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        {props.activetab === "gallery_approved" ||
                        "gallery_decline"
                          ? row.country
                          : row.phone}

                        {props.activetab === "gallery_approved"
                          ? row.first_name + " " + row.last_name
                          : ""}
                        {props.activetab === "gallery_decline"
                          ? row.first_name + " " + row.last_name
                          : ""}
                        {props.activetab === "decline" ? row.phone : ""}
                        {props.activetab === "auctionlive" &&
                        usertype.type === "curator" ? (
                          <Link
                            className={styles.hyperlink_title}
                            to={`/auction/${row._id}`}
                          >
                            {row.name}
                          </Link>
                        ) : row.time_left ? (
                          row.time_left
                        ) : (
                          ""
                        )}
                        {props.activetab === "affiliatedecline"
                          ? row.phone
                          : ""}
                        {props.activetab === "affiliatepending"
                          ? row.phone
                          : ""}
                        {props.activetab === "affiliateapproved"
                          ? row.phone
                          : ""}
                        {props.activetab === "contact-us" ? row.phone : ""}
                        {props.activetab === "request"
                          ? row.end_date &&
                            moment(row.end_date).format("DD-MM-YYYY")
                          : ""}
                        {props.activetab === "career" && " " + row.location}
                        {props.activetab === "printpricing" ? row.product : ""}
                        {props.activetab === "pending" ? row.phone : ""}
                        {props.activetab === "decline" ? (
                          <div>
                            {row.invite_artists &&
                              row.invite_artists.map((vl) => (
                                <>
                                  {" "}
                                  {vl.first_name} {vl.last_name}
                                </>
                              ))}
                          </div>
                        ) : (
                          ""
                        )}
                        {props.activetab === "exshibitionpast" ? (
                          <Select
                            value=""
                            // onChange={handleChange}
                            displayEmpty
                            // fullWidth
                            sx={{
                              background: "#f6f6f6",
                              ".MuiOutlinedInput-notchedOutline": {
                                border: "none",
                              },
                              "& .MuiInputBase-root": {
                                borderRadius: "6px",
                              },
                              fontFamily: "DM Sans",
                              fontStyle: "normal",
                              fontWeight: "400",
                              fontSize: "16px",
                              lineHeight: "21px",
                              color: "#636365",
                              height: "40px",
                            }}
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            <MenuItem value="">Artists Name</MenuItem>
                            {row.invite_artists &&
                              row.invite_artists.map((val) => (
                                <MenuItem disabled key={val._id}>
                                  {val.first_name} {val.last_name}
                                </MenuItem>
                              ))}
                          </Select>
                        ) : (
                          ""
                        )}
                        {props.activetab === "exshibitiondecline" ? (
                          <Select
                            value=""
                            // onChange={handleChange}
                            displayEmpty
                            // fullWidth
                            sx={{
                              background: "#f6f6f6",
                              ".MuiOutlinedInput-notchedOutline": {
                                border: "none",
                              },
                              "& .MuiInputBase-root": {
                                borderRadius: "6px",
                              },
                              fontFamily: "DM Sans",
                              fontStyle: "normal",
                              fontWeight: "400",
                              fontSize: "16px",
                              lineHeight: "21px",
                              color: "#636365",
                              height: "40px",
                            }}
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            <MenuItem value="">Artists Name</MenuItem>
                            {row.invite_artists &&
                              row.invite_artists.map((val) => (
                                <MenuItem disabled key={val._id}>
                                  {val.first_name} {val.last_name}
                                </MenuItem>
                              ))}
                          </Select>
                        ) : (
                          ""
                        )}
                        {props.activetab === "exshibitionupcoming" ? (
                          <Select
                            value=""
                            // onChange={handleChange}
                            displayEmpty
                            // fullWidth
                            sx={{
                              background: "#f6f6f6",
                              ".MuiOutlinedInput-notchedOutline": {
                                border: "none",
                              },
                              "& .MuiInputBase-root": {
                                borderRadius: "6px",
                              },
                              fontFamily: "DM Sans",
                              fontStyle: "normal",
                              fontWeight: "400",
                              fontSize: "16px",
                              lineHeight: "21px",
                              color: "#636365",
                              height: "40px",
                            }}
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            <MenuItem value="">Artists Name</MenuItem>
                            {row.invite_artists &&
                              row.invite_artists.map((val) => (
                                <MenuItem disabled key={val._id}>
                                  {val.first_name} {val.last_name}
                                </MenuItem>
                              ))}
                          </Select>
                        ) : (
                          ""
                        )}
                        {props.activetab === "exhibitionlive" ? (
                          <Select
                            value=""
                            // onChange={handleChange}
                            displayEmpty
                            // fullWidth
                            sx={{
                              background: "#f6f6f6",
                              ".MuiOutlinedInput-notchedOutline": {
                                border: "none",
                              },
                              "& .MuiInputBase-root": {
                                borderRadius: "6px",
                              },
                              fontFamily: "DM Sans",
                              fontStyle: "normal",
                              fontWeight: "400",
                              fontSize: "16px",
                              lineHeight: "21px",
                              color: "#636365",
                              height: "40px",
                            }}
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            <MenuItem value="">Artists Name</MenuItem>
                            {row.invite_artists &&
                              row.invite_artists.map((val) => (
                                <MenuItem disabled key={val._id}>
                                  {val.first_name} {val.last_name}
                                </MenuItem>
                              ))}
                          </Select>
                        ) : (
                          ""
                        )}
                        {/* this is for curator panel exhibition tab */}
                        {props.activetab === "upcoming" ? (
                          // <Select
                          //   value=""
                          //   // onChange={handleChange}
                          //   displayEmpty
                          //   // fullWidth
                          //   sx={{
                          //     background: "#f6f6f6",
                          //     ".MuiOutlinedInput-notchedOutline": {
                          //       border: "none",
                          //     },
                          //     "& .MuiInputBase-root": {
                          //       borderRadius: "6px",
                          //     },
                          //     fontFamily: "DM Sans",
                          //     fontStyle: "normal",
                          //     fontWeight: "400",
                          //     fontSize: "16px",
                          //     lineHeight: "21px",
                          //     color: "#636365",
                          //     height: "40px",
                          //   }}
                          //   inputProps={{ "aria-label": "Without label" }}
                          // >
                          //   <MenuItem value="">Artists Invited</MenuItem>
                          //   {row.invite_artists &&
                          //     row.invite_artists.map((val) => (
                          //       <MenuItem key={val._id}>
                          //         {val.first_name} {val.last_name}
                          //       </MenuItem>
                          //     ))}
                          // </Select>
                          <>
                            {row.is_paid && row.is_paid === true
                              ? "Paid"
                              : "Free"}
                          </>
                        ) : (
                          ""
                        )}
                        {props.activetab === "past" ? (
                          // <Select
                          //   value=""
                          //   // onChange={handleChange}
                          //   displayEmpty
                          //   // fullWidth
                          //   sx={{
                          //     background: "#f6f6f6",
                          //     ".MuiOutlinedInput-notchedOutline": {
                          //       border: "none",
                          //     },
                          //     "& .MuiInputBase-root": {
                          //       borderRadius: "6px",
                          //     },
                          //     fontFamily: "DM Sans",
                          //     fontStyle: "normal",
                          //     fontWeight: "400",
                          //     fontSize: "16px",
                          //     lineHeight: "21px",
                          //     color: "#636365",
                          //     height: "40px",
                          //   }}
                          //   inputProps={{ "aria-label": "Without label" }}
                          // >
                          //   <MenuItem value="">Artists Invited</MenuItem>
                          //   {row.invite_artists &&
                          //     row.invite_artists.map((val) => (
                          //       <MenuItem key={val._id}>
                          //         {val.first_name} {val.last_name}
                          //       </MenuItem>
                          //     ))}
                          // </Select>
                          <>
                            {row.is_paid && row.is_paid === true
                              ? "Paid"
                              : "Free"}
                          </>
                        ) : (
                          ""
                        )}
                      </TableCell>

                      {props.activetab === "printpricing" ? (
                        <TableCell
                          sx={{
                            width: "32%",
                            fontFamily: "DM Sans",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "21px",
                            color: "#636365",
                          }}
                          className={styles.column}
                          align="center"
                        >
                          <div className={styles.career_lastcol}>
                            {row?.price ? row.price : "---"}
                            <div className={styles.action}>
                              <img
                                src={"/img/Edit.png"}
                                height="15px"
                                width="14px"
                                alt="img"
                                onClick={() => props.handleEditpopup(row)}
                              />
                              <img
                                src={"/img/delete.png"}
                                height="15px"
                                width="14px"
                                alt="img"
                                onClick={() =>
                                  props.handleDeletePrintPricing(row._id)
                                }
                              />
                            </div>
                          </div>
                        </TableCell>
                      ) : (
                        <TableCell
                          sx={{
                            fontFamily: "DM Sans",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "21px",
                            color: "#636365",
                          }}
                          className={styles.table_column}
                          align="left"
                        >
                          {props.activetab === "decline" ? row.email : ""}
                          {props.activetab === "pending" ? row.email : ""}
                          {props.activetab === "affiliatedecline"
                            ? row.email
                            : ""}
                          {props.activetab === "affiliatepending"
                            ? row.email
                            : ""}
                          {props.activetab === "affiliateapproved"
                            ? row.email
                            : ""}
                          {props.activetab === "contact-us" ? row.email : ""}

                          {props.activetab === "gallery_approved" ||
                          "gallery_decline"
                            ? ""
                            : row.email}
                          {row.department}
                          {/* {props.activetab === "auctionlive" ? row.current_bid : row.name} */}
                          {props.activetab === "auctionlive" &&
                          usertype.type === "curator"
                            ? moment(row.createdAt).format("DD-MM-YYYY")
                            : row.current_bid
                            ? row.current_bid
                            : ""}
                          {props.activetab === "live" ? row.current_bid : ""}
                          {props.activetab === "career" ? "" : row.location}
                          {props.activetab === "request" ? row.name : ""}
                          {row.country}
                          {props.activetab === "gallery_approved" ||
                          "gallery_decline"
                            ? ""
                            : ""}

                          {props.activetab === "exshibitionpast"
                            ? row.is_paid
                              ? "Paid"
                              : "Free"
                            : ""}
                          {props.activetab === "exshibitiondecline"
                            ? row.is_paid
                              ? "Paid"
                              : "Free"
                            : ""}
                          {props.activetab === "exhibitionlive"
                            ? row.is_paid
                              ? "Paid"
                              : "Free"
                            : ""}
                          {props.activetab === "exshibitionupcoming"
                            ? row.is_paid
                              ? "Paid"
                              : "Free"
                            : ""}
                          {/* this is for curator panel exhibition tab (upcoming and past) */}

                          {props.activetab === "upcoming"
                            ? row.end_date_registration &&
                              moment(row.end_date_registration).format(
                                "DD-MM-YYYY"
                              )
                            : ""}
                          {props.activetab === "past"
                            ? row.end_date_registration &&
                              moment(row.end_date_registration).format(
                                "DD-MM-YYYY"
                              )
                            : ""}
                        </TableCell>
                      )}
                      {/* {props.activetab === "affiliatepending" || "affiliateapproved" || "affiliatedecline" ? (
                        <TableCell
                          sx={{
                            fontFamily: "DM Sans",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "21px",
                            color: "#636365",
                          }}
                          className={
                            props.activetab === "pending"
                              ? ""
                              : styles.table_columnss
                          }
                          align="left"
                          
                        >
                          {props.activetab === "affiliatepending"
                            ? moment(row?.createdAt).format("DD-MM-YYYY")
                            : ""}
                          {props.activetab === "affiliateapproved"
                            ? moment(row?.createdAt).format("DD-MM-YYYY")
                            : ""}
                          {props.activetab === "affiliatedecline"
                            ? moment(row?.createdAt).format("DD-MM-YYYY")
                            : ""}
                        </TableCell>
                      ) : (
                        ""
                      )} */}
                      {props.activetab === "request" ? (
                        <TableCell
                          sx={{
                            fontFamily: "DM Sans",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "21px",
                            color: "#636365",
                          }}
                          className={styles.table_column}
                          align="left"
                        >
                          <Select
                            value=""
                            // onChange={handleChange}
                            displayEmpty
                            // fullWidth
                            sx={{
                              background: "#f6f6f6",
                              ".MuiOutlinedInput-notchedOutline": {
                                border: "none",
                              },
                              "& .MuiInputBase-root": {
                                borderRadius: "6px",
                              },
                              fontFamily: "DM Sans",
                              fontStyle: "normal",
                              fontWeight: "400",
                              fontSize: "16px",
                              lineHeight: "21px",
                              color: "#636365",
                              height: "40px",
                            }}
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            <MenuItem value="">Artists Name</MenuItem>
                            {row.invite_artists &&
                              row.invite_artists.map((val) => (
                                <MenuItem disabled key={val._id}>
                                  {val.first_name} {val.last_name}
                                </MenuItem>
                              ))}
                          </Select>
                        </TableCell>
                      ) : props.activetab === "decline" ? (
                        <TableCell
                          sx={{
                            fontFamily: "DM Sans",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "21px",
                            color: "#636365",
                          }}
                          className={styles.table_column}
                          align="left"
                        >
                          <p>
                            {row.start_date &&
                              moment(row.start_date).format("DD-MM-YYYY")}
                          </p>
                        </TableCell>
                      ) : props.activetab === "gallery_decline" ? ( // this is for superadmin gallery decline
                        <TableCell
                          sx={{
                            fontFamily: "DM Sans",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "21px",
                            color: "#636365",
                          }}
                          className={styles.table_columns}
                          align="left"
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <span>{row.likes}</span>
                          </Box>
                        </TableCell>
                      ) : props.activetab === "gallery_approved" ? ( // this is for superadmin gallery approved
                        <TableCell
                          sx={{
                            fontFamily: "DM Sans",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "21px",
                            color: "#636365",
                          }}
                          className={styles.table_columns}
                          align="left"
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <span>{row.likes}</span>
                          </Box>
                        </TableCell>
                      ) : (
                        <TableCell
                          sx={{
                            fontFamily: "DM Sans",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "21px",
                            color: "#636365",
                            width:"15%",
                          }}
                          className={
                            props.activetab === "pending"
                              ? ""
                              : styles.table_columnss
                          }
                          align="left"
                          // style={props.activetab === "gallery_enquiry" ? { width: "30%" } : {}}
                        >
                          {props.activetab === "career" && (
                            <>
                              <div>
                                <b>Exp : </b>
                                {row?.min_exp}-{row?.max_exp} yrs
                              </div>
                            </>
                          )}

                          {row.experience}
                          {props.activetab === "gallery_approved" ||
                          "gallery_decline"
                            ? ""
                            : row.followers}
                          {props.activetab === "auctionlive" &&
                          usertype.type === "curator"
                            ? moment(row.start_date).format("DD-MM-YYYY")
                            : props.activetab === "auctionlive" && row.time_left
                            ? row.time_left
                            : ""}
                          {row.type}
                          {props.activetab === "gallery_approved"
                            ? row.likes
                            : ""}
                          {props.activetab === "gallery_approved"
                            ? row.likes
                            : ""}
                          {props.activetab === "pending" ? (
                            <Select
                              value={selectedArtistTypes[row._id] || ""}
                              onChange={(event) =>
                                handleArtistTypeChange(event, row._id)
                              }
                              displayEmpty
                              sx={{
                                background: "#f6f6f6",
                                ".MuiOutlinedInput-notchedOutline": {
                                  border: "none",
                                },
                                "& .MuiInputBase-root": {
                                  borderRadius: "6px",
                                },
                                fontFamily: "DM Sans",
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "16px",
                                lineHeight: "21px",
                                color: "#636365",
                                height: "40px",
                              }}
                              inputProps={{ "aria-label": "Without label" }}
                            >
                              <MenuItem value="">Artist Type</MenuItem>
                              {props.artistTypes &&
                                props.artistTypes.data.map((val) => (
                                  <MenuItem key={val._id} value={val.name}>
                                    {val.name}
                                  </MenuItem>
                                ))}
                            </Select>
                          ) : (
                            ""
                          )}
                          {props.activetab === "exshibitionpast"
                            ? row.start_date &&
                              moment(row.start_date).format("DD-MM-YYYY")
                            : ""}
                          {props.activetab === "exshibitiondecline"
                            ? row.start_date &&
                              moment(row.start_date).format("DD-MM-YYYY")
                            : ""}
                          {props.activetab === "exshibitionupcoming"
                            ? row.start_date &&
                              moment(row.start_date).format("DD-MM-YYYY")
                            : ""}
                          {/* this is for curator panel exhibition tab (upcoming and past) */}
                          {props.activetab === "upcoming"
                            ? row.start_date &&
                              moment(row.start_date).format("DD-MM-YYYY")
                            : ""}
                          {props.activetab === "past"
                            ? row.start_date
                              ? moment(row.start_date).format("DD-MM-YYYY")
                              : ""
                            : ""}
                          {props.activetab === "contact-us"
                            ? row.createdAt &&
                              moment(row.createdAt).format("DD-MM-YYYY")
                            : ""}
                          {props.activetab === "affiliatepending"
                            ? row.affiliates[0]?.accountType[0]?.name
                            : ""}
                          {props.activetab === "affiliateapproved"
                            ? row.affiliates[0]?.accountType[0]?.name
                            : ""}
                          {props.activetab === "affiliatedecline"
                            ? row.affiliates[0]?.accountType[0]?.name
                            : ""}
                        </TableCell>
                      )}

                      {props.activetab === "contact-us" ? (
                        <TableCell
                          sx={{
                            fontFamily: "DM Sans",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "21px",
                            color: "#636365",
                          }}
                          className={styles.table_column}
                          align="left"
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              gap: 4,
                            }}
                          >
                            {row.message}
                            <img
                              src={"/img/delete.png"}
                              height="15px"
                              width="14px"
                              alt="img"
                              onClick={() => props.handleDelete(row._id)}
                            />
                          </Box>
                        </TableCell>
                      ) : props.activetab === "affiliateapproved" ? (
                        <TableCell
                          sx={{
                            fontFamily: "DM Sans",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "21px",
                            color: "#636365",
                          }}
                          className={styles.table_columns}
                          align="center"
                        >
                          <Tooltip title="Account settings">
                            <IconButton
                              onClick={handleClick}
                              size="small"
                              sx={{ ml: 2 }}
                              id={row._id}
                              aria-controls={
                                threedots ? "account-menu" : undefined
                              }
                              aria-haspopup="true"
                              aria-expanded={threedots ? "true" : undefined}
                            >
                              <img
                                src={"/img/Menu Icon.png"}
                                height="21.62px"
                                width="4.63px"
                                alt="img"
                              />
                            </IconButton>
                          </Tooltip>
                          <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={threedots}
                            onClose={handleClose}
                            onClick={handleClose}
                            PaperProps={{
                              elevation: 0,
                              sx: {
                                width: "150px",
                                overflow: "visible",
                                boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
                                filter:
                                  "drop-shadow(0px 0px 0px rgba(0,0,0,0.32))",
                                mt: 2.5,
                                "& .MuiAvatar-root": {
                                  width: "120px",
                                  height: "132px",
                                  ml: 0.5,
                                  mr: 1,
                                  // mt:5
                                },
                                "&:before": {
                                  content: '""',
                                  display: "block",
                                  position: "absolute",
                                  top: 0,
                                  // horizontal: 'center',
                                  right: 62,
                                  width: 10,
                                  height: 10,
                                  bgcolor: "background.paper",
                                  transform: "translateY(-50%) rotate(45deg)",
                                  zIndex: 0,
                                },
                              },
                            }}
                            transformOrigin={{
                              horizontal: "center",
                              vertical: "right",
                            }}
                            anchorOrigin={{
                              horizontal: "left",
                              vertical: "center",
                            }}
                          >
                            <MenuItem
                              onClick={() => props.handleDelete(threedot_id)}
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                textAlign: "center",
                                fontSize: "16px",
                                lineHeight: "12px",
                                fontWeight: "400",
                                fontFamily: "DM Sans",
                              }}
                            >
                              Delete
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      ) : props.activetab === "decline" ? (
                        <TableCell
                          sx={{
                            fontFamily: "DM Sans",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "21px",
                            color: "#636365",
                          }}
                          className={styles.table_column}
                          align="left"
                        >
                          <p>
                            {row.end_date &&
                              moment(row.end_date).format("DD-MM-YYYY")}
                          </p>
                          <img
                            src={"/img/delete.png"}
                            height="15px"
                            width="14px"
                            alt="imgs"
                            onClick={() => props.handleDelete(row._id)}
                          />
                        </TableCell>
                      ) : props.activetab === "affiliatedecline" ? (
                        <TableCell
                          sx={{
                            fontFamily: "DM Sans",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "21px",
                            color: "#636365",
                          }}
                          className={styles.table_column}
                          align="left"
                        >
                          <p>
                            {row.end_date &&
                              moment(row.end_date).format("DD-MM-YYYY")}
                          </p>
                          <img
                            src={"/img/delete.png"}
                            height="15px"
                            width="14px"
                            alt="img"
                            cursor="ponter"
                            onClick={() => props.handleDelete(row._id)}
                          />
                        </TableCell>
                      ) : props.activetab === "affiliatepending" ? (
                        <TableCell
                          sx={{
                            fontFamily: "DM Sans",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "21px",
                            color: "#636365",
                            whiteSpace: "nowrap",
                          }}
                          className={styles.table_columns}
                          align="left"
                        >
                          <Button
                            onClick={() =>
                              props.handleAcceptDecline(row._id, "Approved")
                            }
                            color={row.status ? "primary" : "primary"}
                            variant="contained"
                            sx={{ width: "100px", mr: 2 }}
                          >
                            Accept
                          </Button>
                          <Button
                            onClick={() =>
                              props.handleAcceptDecline(row._id, "Rejected")
                            }
                            color={row.status ? "primary" : "primary"}
                            variant="outlined"
                            sx={{ width: "100px", mr: 2 }}
                          >
                            Decline
                          </Button>
                        </TableCell>
                      ) : props.activetab === "gallery_approved" ? (
                        <TableCell
                          sx={{
                            fontFamily: "DM Sans",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "21px",
                            color: "#636365",
                          }}
                          className={styles.table_columns}
                          align="left"
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <span>{row.followers}</span>
                            <Tooltip title="Account settings">
                              <IconButton
                                onClick={handleClick}
                                size="small"
                                sx={{ ml: 2 }}
                                aria-controls={
                                  threedots ? "account-menu" : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={threedots ? "true" : undefined}
                              >
                                <img
                                  src={"/img/Menu Icon.png"}
                                  height="21.62px"
                                  width="4.63px"
                                  alt="img"
                                />
                              </IconButton>
                            </Tooltip>
                            <Menu
                              anchorEl={anchorEl}
                              id="account-menu"
                              open={threedots}
                              onClose={handleClose}
                              onClick={handleClose}
                              PaperProps={{
                                elevation: 0,
                                sx: {
                                  width: "150px",
                                  overflow: "visible",
                                  boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
                                  filter:
                                    "drop-shadow(0px 0px 0px rgba(0,0,0,0.32))",
                                  mt: 2.5,
                                  "& .MuiAvatar-root": {
                                    width: "120px",
                                    height: "132px",
                                    ml: 0.5,
                                    mr: 1,
                                    // mt:5
                                  },
                                  "&:before": {
                                    content: '""',
                                    display: "block",
                                    position: "absolute",
                                    top: 0,
                                    // horizontal: 'center',
                                    right: 62,
                                    width: 10,
                                    height: 10,
                                    bgcolor: "background.paper",
                                    transform: "translateY(-50%) rotate(45deg)",
                                    zIndex: 0,
                                  },
                                },
                              }}
                              transformOrigin={{
                                horizontal: "center",
                                vertical: "right",
                              }}
                              anchorOrigin={{
                                horizontal: "left",
                                vertical: "center",
                              }}
                            >
                              <MenuItem
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  fontSize: "16px",
                                  lineHeight: "12px",
                                  fontWeight: "400",
                                  fontFamily: "DM Sans",
                                }}
                              >
                                Delete
                              </MenuItem>
                            </Menu>
                          </Box>
                        </TableCell>
                      ) : props.activetab === "gallery_decline" ? (
                        <TableCell
                          sx={{
                            fontFamily: "DM Sans",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "21px",
                            color: "#636365",
                          }}
                          className={styles.table_columns}
                          align="left"
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <span>{row.followers}</span>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Tooltip title="Account settings">
                                <IconButton
                                  onClick={handleClick}
                                  id={row._id}
                                  size="small"
                                  sx={{ ml: 2 }}
                                  aria-controls={
                                    threedots ? "account-menu" : undefined
                                  }
                                  aria-haspopup="true"
                                  aria-expanded={threedots ? "true" : undefined}
                                >
                                  <img
                                    src={"/img/Menu Icon.png"}
                                    id={row._id}
                                    alt="img"
                                    height="21.62px"
                                    width="4.63px"
                                  />
                                </IconButton>
                              </Tooltip>
                              <Menu
                                anchorEl={anchorEl}
                                id="account-menu"
                                open={threedots}
                                onClose={handleClose}
                                onClick={handleClose}
                                // onClick={props.handleDelete}
                                value={row._id}
                                name={row._id}
                                PaperProps={{
                                  elevation: 0,
                                  sx: {
                                    width: "150px",
                                    overflow: "visible",
                                    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
                                    filter:
                                      "drop-shadow(0px 0px 0px rgba(0,0,0,0.32))",
                                    mt: 2.5,
                                    "& .MuiAvatar-root": {
                                      width: "120px",
                                      height: "132px",
                                      ml: 0.5,
                                      mr: 1,
                                      // mt:5
                                    },
                                    "&:before": {
                                      content: '""',
                                      display: "block",
                                      position: "absolute",
                                      top: 0,
                                      // horizontal: 'center',
                                      right: 62,
                                      width: 10,
                                      height: 10,
                                      bgcolor: "background.paper",
                                      transform:
                                        "translateY(-50%) rotate(45deg)",
                                      zIndex: 0,
                                    },
                                  },
                                }}
                                transformOrigin={{
                                  horizontal: "center",
                                  vertical: "right",
                                }}
                                anchorOrigin={{
                                  horizontal: "left",
                                  vertical: "center",
                                }}
                              >
                                <MenuItem
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    fontSize: "16px",
                                    lineHeight: "12px",
                                    fontWeight: "400",
                                    fontFamily: "DM Sans",
                                  }}
                                >
                                  Featured Artist
                                </MenuItem>
                                <Divider />
                                <MenuItem
                                  onClick={() =>
                                    props.handleDelete(threedot_id)
                                  }
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    textAlign: "center",
                                    fontSize: "16px",
                                    lineHeight: "12px",
                                    fontWeight: "400",
                                    fontFamily: "DM Sans",
                                  }}
                                >
                                  Delete
                                  {/* {row._id} */}
                                </MenuItem>
                              </Menu>
                            </Box>
                          </Box>
                        </TableCell>
                      ) : props.activetab === "request" ? (
                        <TableCell
                          sx={{
                            fontFamily: "DM Sans",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "21px",
                            color: "#636365",
                            whiteSpace: "nowrap",
                          }}
                          className={styles.column}
                          align="center"
                        >
                          <Button
                            color={row.status ? "primary" : "primary"}
                            variant="contained"
                            sx={{ width: "80px", mr: 2 }}
                            onClick={() =>
                              props.handleAcceptReject(row._id, "Approved")
                            }
                          >
                            Accept
                          </Button>
                          <Button
                            color={row.status ? "primary" : "primary"}
                            variant="outlined"
                            sx={{ width: "80px", mr: 0 }}
                            onClick={() =>
                              props.handleAcceptReject(row._id, "Rejected")
                            }
                          >
                            Decline
                          </Button>
                          <IconButton>
                            <img
                              src={"/img/delete.png"}
                              height="15px"
                              width="14px"
                              alt="img"
                              onClick={() => props.handleDeleteAuction(row._id)}
                            />
                          </IconButton>
                        </TableCell>
                      ) : props.activetab === "career" ? (
                        <TableCell
                          sx={{
                            width: "32%",
                            fontFamily: "DM Sans",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "21px",
                            color: "#636365",
                          }}
                          className={styles.column}
                          align="center"
                        >
                          <div className={styles.career_lastcol}>
                            <p
                              dangerouslySetInnerHTML={{
                                __html:
                                  row &&
                                  row.description &&
                                  row.description.length > 100
                                    ? row.description.substring(0, 100) + "..."
                                    : row && row.description
                                    ? row.description
                                    : "",
                              }}
                            ></p>
                            <div className={styles.action}>
                              <img
                                src={"/img/Edit.png"}
                                height="15px"
                                width="14px"
                                alt="img"
                                onClick={() => props.handleEditpopup(row)}
                              />
                              <img
                                src={"/img/delete.png"}
                                height="15px"
                                width="14px"
                                alt="img"
                                onClick={() =>
                                  props.handleDeletecareeer(row._id)
                                }
                              />
                            </div>
                          </div>
                        </TableCell>
                      ) : props.activetab === "pending" ? (
                        <>
                        <TableCell
                        sx={{
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "21px",
                          color: "#636365",
                          whiteSpace: "nowrap",
                        }}
                        className={
                          props.activetab === "pending"
                            ? ""
                            : styles.table_columnss
                        }
                        align="left"
                        
                      >
                        {dayjs(row?.createdAt).format("DD-MM-YYYY").toString()}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontFamily: "DM Sans",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "21px",
                            color: "#636365",
                            whiteSpace: "nowrap",
                          }}
                          className={styles.table_column}
                          align="left"
                        >
                          <Button
                            color={row.status ? "primary" : "primary"}
                            variant="contained"
                            disabled={row.is_blocked}
                            sx={{ width: "80px", mr: 2 }}
                            onClick={() =>
                              props.handleAcceptDecline(row._id, "Approved", {
                                name: "Basic Subscription",
                                artist_id: row._id,
                                subscriptionId: "66a24113b46eb6ba162ec273",
                                subscription_tenure: "monthly",
                                payment_mode: "From_admin",
                                transactionId: "TXN123456789",
                                amount: "0",
                              })
                            }
                          >
                            Accept
                          </Button>
                          <Button
                            color={row.status ? "primary" : "primary"}
                            variant="outlined"
                            disabled={row.is_blocked}
                            sx={{ width: "80px", mr: 0 }}
                            onClick={() =>
                              props.handleAcceptDecline(row._id, "Rejected")
                            }
                          >
                            Decline
                          </Button>

                         
                        </TableCell>
                        </>
                      ) : props.activetab === "galleryadmin" ? (
                        <TableCell
                          sx={{
                            fontFamily: "DM Sans",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "21px",
                            color: "#636365",
                            whiteSpace: "nowrap",
                          }}
                          className={styles.table_column}
                          align="left"
                        >
                          <Button
                            onClick={() => props.setOpen(true)}
                            variant="contained"
                            sx={{
                              width: "106px",
                              mr: 0,
                              height: "34px",
                              boderRadius: "4px",
                            }}
                          >
                            Interested
                          </Button>
                        </TableCell>
                      ) : props.activetab === "exshibitionpast" ||
                        "exshibitiondecline" ? (
                        <TableCell
                          sx={{
                            fontFamily: "DM Sans",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "21px",
                            width :"130px"
                          }}
                          align="left"
                        >
                          {props.activetab === "auctionlive"
                            ? usertype.type === "curator"
                              ? moment(row.end_date).format("DD-MM-YYYY")
                              : row.total_bid
                            : row.end_date &&
                              moment(row.end_date).format("DD-MM-YYYY")}
                        </TableCell>
                      ) : props.activetab === "upcoming" || "past" ? (
                        <TableCell
                          sx={{
                            fontFamily: "DM Sans",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "21px",
                            color: "#636365",
                          }}
                          className={styles.table_column}
                          align="left"
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            {row.followers}
                            {props.activetab === "gallery_decline" ? (
                              <img
                                src={"/img/delete.png"}
                                height="15px"
                                width="14px"
                                alt="img"
                                onClick={() => props.handleDelete(row._id)}
                              />
                            ) : (
                              ""
                            )}
                          </Box>
                        </TableCell>
                      ) : (
                        <></>
                      )}

          { props.activetab === "past" &&
                      usertype.type === "curator" ? (
                        <TableCell
                        sx={{
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "21px",
                        }}
                        align="center"
                      >
                        {row.createdAt &&
                          moment(row.createdAt).format(
                            "DD-MM-YYYY"
                          )}
                      </TableCell>
                    ) : (
                      ""
                    )}
                      
                      {props.activetab === "auctionlive" &&
                      usertype.type === "curator" ? (
                        <TableCell
                          sx={{
                            fontFamily: "DM Sans",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "21px",
                          }}
                          align="center"
                        >
                          {row.application_end_date &&
                            moment(row.application_end_date).format(
                              "DD-MM-YYYY"
                            )}
                        </TableCell>
                      ) : (
                        ""
                      )}
                       {props.activetab === "upcoming" &&
                      usertype.type === "curator" ? (
                        <TableCell
                          sx={{
                            fontFamily: "DM Sans",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "21px",
                          }}
                          align="center"
                        >
                          {row.createdAt &&
                            moment(row.createdAt).format(
                              "DD-MM-YYYY"
                            )}
                        </TableCell>
                      ) : (
                        ""
                      )}
                    </TableRow>
                  ))
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </div>
  );
}
