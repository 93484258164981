import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import dayjs from "dayjs";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import styles from "./Tables.module.css";
import { useSelector } from "react-redux";
import CircularLoader from "../../../../../common/loader/Loader";
import homeImage from "../../../../../constant";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
export default function Tablesfour(props) {
  console.log("Curator Table props", props);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [ids, setIds] = useState("");
  const navigate = useNavigate();
  const threedots = Boolean(anchorEl);
  const loader = useSelector((state) => state.loader);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIds(event.target.id);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handlePopup = () => {
    props.setOpen(true);
  };
  const handleProfilepage = (id) => {
    const path = window.location.pathname;
    if (path === "/artist") {
      navigate(`/artist/${id}`, { state: { active: props.activetab } });
    } else if (path === "/curators") {
      navigate(`/curators/${id}`);
    } else if (path === "/exhibitions") {
      navigate(`/exhibitions/${id}`);
    }
  };
  const navigateToArtistDetail = (id) => {
    navigate(`/user/artist/${id}`);
  };

  const handleDelete = (data) => {
    props.handlePopup(data); // Trigger the handlePopup
    if (props.setOpen) {
      props.setOpen(true); // Ensure modal state is updated
    }
  };





  const [expandedRows, setExpandedRows] = useState({}); // Track expanded state for each row

  const toggleReadMore = (rowIndex) => {
    setExpandedRows((prevState) => ({
      ...prevState,
      [rowIndex]: !prevState[rowIndex], // Toggle the state for the specific row
    }));
  };

  return (
    <div className={styles.mains}>
      <TableContainer
        component={Paper}
        sx={{ backgroundColor: "#f6f6f6", color: "black" }}
      >
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#bfbbc3" }}>
              {props.head &&
                props.head.map((column, i) => (
                  <TableCell
                    align={props.head.length - 1 === i ? "left" : "left"}
                    sx={{
                      whiteSpace: "nowrap",
                      fontFamily: "DM Sans",
                      fontWeight: "500",
                      fontSize: "16px",
                      height: "21px",
                      color: "#1C1C1C",
                    }}
                    key={i}
                  >
                    {column.label}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          {loader.loader ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={8}>
                  <Box className={styles.loader_box}>
                    {" "}
                    <CircularLoader size={60} />
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {props.data && props.data.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={8}>
                    <Box className={styles.loader_box}> No Data Found</Box>
                  </TableCell>
                </TableRow>
              ) : (
                props.data &&
                props.data.map((row, index) => (
                  <TableRow
                    style={{ color: "#636365", height: "30px" }}
                    className={styles.table_row}
                    key={row._id}
                  >
                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      style={{ cursor: "pointer" }}
                      className={styles.table_column}
                      align="left"
                      onClick={() => handleProfilepage(row._id)}
                    >
                      {row.name}
                      <span className={styles.hover_text}>
                        {row.first_name} {row.last_name}
                      </span>
                      {props.activetab === "exhibitionlive" ||
                      props.activetab === "live" ? (
                        <Link
                          to={`/exhibitions/${row._id}`}
                          className={styles.hover_text}
                        >
                          {row?.title}
                        </Link>
                      ) : (
                        " "
                      )}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      {props.activetab === "curator" ? row.email : row.location}
                      {row.campaign_code}
                     {" "}
                      {row?.artist?.map((loc, i) => loc?.country)}
                      {props.activetab === "request" ? (
                        <div>
                          {row.artist &&
                            row.artist.map((vl) => (
                              <>
                                {" "}
                                {vl.first_name} {vl.last_name}
                              </>
                            ))}
                        </div>
                      ) : (
                        ""
                      )}
                      {props.activetab === "exhibitionlive" ? (
                        <Select
                          value=""
                          // onChange={handleChange}
                          displayEmpty
                          // fullWidth
                          sx={{
                            background: "#f6f6f6",
                            ".MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                            "& .MuiInputBase-root": {
                              borderRadius: "6px",
                            },
                            fontFamily: "DM Sans",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "21px",
                            color: "#636365",
                            height: "40px",
                          }}
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          <MenuItem value="">Artists Name</MenuItem>
                          {row.artist &&
                            row.artist.map((val) => (
                              <MenuItem disabled key={val._id}>
                                {val.first_name} {val.last_name}
                              </MenuItem>
                            ))}
                        </Select>
                      ) : (
                        ""
                      )}
                      {props.activetab === "live" ? (
                        // <Select
                        //   value=""
                        //   // onChange={handleChange}
                        //   displayEmpty
                        //   // fullWidth
                        //   sx={{
                        //     background: "#f6f6f6",
                        //     ".MuiOutlinedInput-notchedOutline": {
                        //       border: "none",
                        //     },
                        //     "& .MuiInputBase-root": {
                        //       borderRadius: "6px",
                        //     },
                        //     fontFamily: "DM Sans",
                        //     fontStyle: "normal",
                        //     fontWeight: "400",
                        //     fontSize: "16px",
                        //     lineHeight: "21px",
                        //     color: "#636365",
                        //     height: "40px",
                        //   }}
                        //   inputProps={{ "aria-label": "Without label" }}
                        // >
                        //   <MenuItem value="">Artists Invited</MenuItem>
                        //   {row.invite_artists &&
                        //     row.invite_artists.map((val) => (
                        //       <MenuItem key={val._id}>
                        //         {val.first_name} {val.last_name}
                        //       </MenuItem>
                        //     ))}
                        // </Select>
                        <>
                          {row.is_paid && row.is_paid === true
                            ? "Paid"
                            : "Free"}
                        </>
                      ) : (
                        ""
                      )}
                    </TableCell>

                    {props.activetab === "featured" && (
                      <TableCell
                        sx={{
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "21px",
                          color: "#636365",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        {row.artist[0]
                          ? row.artist[0].featured_date
                            ? dayjs(row.artist[0].featured_date)
                                .format("YYYY-MM-DD")
                                .toString()
                            : "----"
                          : ""}
                      </TableCell>
                    )}
                    {props.activetab === "approved" && (
                      <TableCell
                        sx={{
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "21px",
                          color: "#636365",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        <div> {row?.email}</div>
                        <div>{row?.phone}</div>
                      </TableCell>
                    )}
                    {props.activetab === "exclusive" && (
                      <TableCell
                        sx={{
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "21px",
                          color: "#636365",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        <div> {row?.email}</div>
                        <div>{row?.phone}</div>
                      </TableCell>
                    )}
                    {props.activetab === "exclusive" && (
                      <TableCell
                        sx={{
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "21px",
                          color: "#636365",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        {row.artist[0]
                          ? row.artist[0].createdAt
                            ? dayjs(row.artist[0].createdAt)
                                .format("YYYY-MM-DD")
                                .toString()
                            : "----"
                          : ""}
                      </TableCell>
                    )}
                    {props.activetab === "decline" && (
                      <TableCell
                        sx={{
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "21px",
                          color: "#636365",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        <div> {row?.email}</div>
                        <div>{row?.phone}</div>
                      </TableCell>
                    )}
                    {props.activetab === "decline" && (
                      <TableCell
                        sx={{
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "21px",
                          color: "#636365",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        {row.artist[0]
                          ? row.artist[0].approved_date
                            ? dayjs(row.artist[0].approved_date)
                                .format("YYYY-MM-DD")
                                .toString()
                            : "----"
                          : ""}
                      </TableCell>
                    )}
                    {props.activetab === "recommended" && (
                      <TableCell
                        sx={{
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "21px",
                          color: "#636365",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        <div> {row?.email}</div>
                        <div>{row?.phone}</div>
                      </TableCell>
                    )}
                    {props.activetab === "recommended" && (
                      <TableCell
                        sx={{
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "21px",
                          color: "#636365",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        {row.artist[0]
                          ? row.artist[0].recommended_date
                            ? dayjs(row.artist[0].recommended_date)
                                .format("YYYY-MM-DD")
                                .toString()
                            : "----"
                          : ""}
                      </TableCell>
                    )}

                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      {props.activetab === "approved" ? (
                        <>
                          {" "}
                          {row.artist &&
                            row.artist.map((vl) => {
                              const lastSubscription =
                                vl?.subscriptions?.slice(-1)[0];
                              return lastSubscription ? (
                                <>{lastSubscription?.name}</>
                              ) : (
                                <>----</>
                              );
                            })}
                        </>
                      ) : (
                        ""
                      )}
                      {props.activetab === "exclusive" ? (
                        <>
                          {" "}
                          {row.artist &&
                            row.artist.map((vl) => {
                              const lastSubscription =
                                vl?.subscriptions?.slice(-1)[0];
                              return lastSubscription ? (
                                <>{lastSubscription?.name}</>
                              ) : (
                                <>----</>
                              );
                            })}
                        </>
                      ) : (
                        ""
                      )}
                      {props.activetab === "decline" ? (
                        <>
                          {" "}
                          {row.artist &&
                            row.artist.map((vl) => {
                              const lastSubscription =
                                vl?.subscriptions?.slice(-1)[0];
                              return lastSubscription ? (
                                <>{lastSubscription?.name}</>
                              ) : (
                                <>----</>
                              );
                            })}
                        </>
                      ) : (
                        ""
                      )}
                      {props.activetab === "recommended" ? (
                        <>
                          {" "}
                          {row.artist &&
                            row.artist.map((vl) => {
                              const lastSubscription =
                                vl?.subscriptions?.slice(-1)[0];
                              return lastSubscription ? (
                                <>{lastSubscription?.name}</>
                              ) : (
                                <>----</>
                              );
                            })}
                        </>
                      ) : (
                        ""
                      )}
                      {props.activetab === "curator"
                        ? row?.curator[0]?.weekly_earning
                          ? row?.curator[0]?.weekly_earning
                          : "----"
                        : ""}
                      {row?.discount}{props?.activetab === "campaigns" && ( "%")}
                      {props.activetab === "exhibitionlive"
                        ? row.is_paid
                          ? "Paid"
                          : "Free"
                        : ""}
                      {/* this is for curator panel exshibition tab (live) */}
                      {props.activetab === "live"
                        ? row.end_date_registration &&
                          moment(row.end_date_registration).format("DD-MM-YYYY")
                        : ""}
                      {props.activetab === "featured"
                        ? row.artist?.map((lik, i) => (
                            <>{lik.likesview?.likes}</>
                          ))
                        : ""}
                    </TableCell>
                    {props.activetab === "approved" && (
                      <TableCell
                        sx={{
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "21px",
                          color: "#636365",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        {row.artist?.map((lik, i) => (
                          <>{lik.likesview?.likes}</>
                        ))}
                      </TableCell>
                    )}

                    {props.activetab === "exclusive" && (
                      <TableCell
                        sx={{
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "21px",
                          color: "#636365",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        {row.artist?.map((lik, i) => (
                          <>{lik.likesview?.likes}</>
                        ))}
                      </TableCell>
                    )}
                    {props.activetab === "decline" && (
                      <TableCell
                        sx={{
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "21px",
                          color: "#636365",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        {row.artist?.map((lik, i) => (
                          <>{lik.likesview?.likes}</>
                        ))}
                      </TableCell>
                    )}

                    {props.activetab === "recommended" && (
                      <TableCell
                        sx={{
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "21px",
                          color: "#636365",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        {row.artist?.map((lik, i) => (
                          <>{lik.likesview?.likes}</>
                        ))}
                      </TableCell>
                    )}

                    {props.activetab === "curator" ? (
                      <TableCell
                        sx={{
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "21px",
                          color: "#636365",
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                        className={styles.column}
                      >
                        {row.is_accepted === "Approved" ? (
                          <Button
                            color={row.is_blocked ? "secondary" : "primary"}
                            variant="contained"
                            onClick={() =>
                              props.handleBlockUnBlock(row._id, !row.is_blocked)
                            }
                          >
                            {row.is_blocked ? "Unblock" : "Block"}
                          </Button>
                        ) : row.is_accepted === "Rejected" ? (
                          "Rejected"
                        ) : (
                          <>
                            <Button
                              color={row.status ? "primary" : "primary"}
                              variant="contained"
                              sx={{ width: "80px", mr: 2 }}
                              onClick={() =>
                                props.handleAcceptDecline(row._id, {
                                  is_accepted: "Approved",
                                })
                              }
                            >
                              Accept
                            </Button>
                            <Button
                              color={row.status ? "primary" : "primary"}
                              variant="outlined"
                              sx={{ width: "80px", mr: 0 }}
                              onClick={() =>
                                props.handleAcceptDecline(row._id, {
                                  is_accepted: "Rejected",
                                })
                              }
                            >
                              Decline
                            </Button>
                          </>
                        )}
                        {/* <IconButton
                          onClick={() => props.handleDelete(row._id)}
                          sx={{ justifyContent: "flex-end" }}
                        >
                          <img
                            src="/img/delete.png"
                            height="20px"
                            width="18px"
                            alt="img"
                          />
                        </IconButton> */}
                      </TableCell>
                    ) : props.activetab === "campaigns" ? (
                      <TableCell
                        sx={{
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "21px",
                          color: "#636365",
                          // alignItems: "center",
                          
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        <div className={styles.table_column}>
                          {/* {row.likes} */}
                          {row.validity &&
                            moment(row.validity).format("DD-MM-YYYY")}
                          {/* {row.description} */}
                        </div>
                      </TableCell>
                    ) : props.activetab === "exhibitionlive" ||
                      props.activetab === "live" ? (
                        <>
                        <TableCell
                        sx={{
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "21px",
                          color: "#636365",
                        }}
                        className={styles.table_column}
                        // align="center"
                      >
                        {" "}
                      
                        {row.start_date &&
                          moment(row.start_date).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "21px",
                          color: "#636365",
                        }}
                        className={styles.table_column}
                        // align="center"
                      >
                        {" "}
                        {/* this is for curator panel exshibition tab (live) */}
                        {row.end_date &&
                          moment(row.end_date).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "21px",
                          color: "#636365",
                        }}
                        className={styles.table_column}
                        // align="center"
                      >
                        {" "}
                        {/* this is for curator panel exshibition tab (live) */}
                        {row.createdAt &&
                          moment(row.createdAt).format("DD-MM-YYYY")}
                      </TableCell>
                      </>
                    ) : props.activetab === "upcoming_auction_gallery" ? (
                      <TableCell
                        sx={{
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "21px",
                          color: "#636365",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        <div className={styles.flex_div}>
                          {/* {row.followers} */}
                          <Button
                            variant="contained"
                            sx={{
                              width: "106px",
                              height: "34px",
                              borderRadius: "4px",
                            }}
                            onClick={handlePopup}
                          >
                            Interested
                          </Button>
                        </div>
                      </TableCell>
                    ) : props.activetab === "approved" ||
                      props.activetab === "decline" ||
                      props.activetab === "recommended" ||
                      props.activetab === "exclusive" ? (
                      <TableCell
                        sx={{
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "21px",
                          color: "#636365",
                          height: "auto",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                          }}
                        >
                          {row.followers ? row.followers.length : "0"}
                          {props.activetab === "approved" ? (
                            <>
                              <Tooltip title="Account settings">
                                <IconButton
                                  onClick={handleClick}
                                  size="small"
                                  id={row._id}
                                  sx={{ ml: 2 }}
                                  aria-controls={
                                    threedots ? "account-menu" : undefined
                                  }
                                  aria-haspopup="true"
                                  aria-expanded={threedots ? "true" : undefined}
                                >
                                  <img
                                    src={"/img/Menu Icon.png"}
                                    id={row._id}
                                    height="21.62px"
                                    alt="img"
                                    width="4.63px"
                                  />
                                </IconButton>
                              </Tooltip>
                              <Menu
                                anchorEl={anchorEl}
                                id="account-menu"
                                open={threedots}
                                onClose={handleClose}
                                onClick={handleClose}
                                PaperProps={{
                                  elevation: 0,
                                  sx: {
                                    width: "150px",
                                    overflow: "visible",
                                    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
                                    filter:
                                      "drop-shadow(0px 0px 0px rgba(0,0,0,0.32))",
                                    mt: 2.5,
                                    "& .MuiAvatar-root": {
                                      width: "120px",
                                      height: "132px",
                                      ml: 0.5,
                                      mr: 1,
                                      // mt:5
                                    },
                                    "&:before": {
                                      content: '""',
                                      display: "block",
                                      position: "absolute",
                                      top: 0,
                                      // horizontal: 'center',
                                      right: 62,
                                      width: 10,
                                      height: 10,
                                      bgcolor: "background.paper",
                                      transform:
                                        "translateY(-50%) rotate(45deg)",
                                      zIndex: 0,
                                    },
                                  },
                                }}
                                transformOrigin={{
                                  horizontal: "center",
                                  vertical: "right",
                                }}
                                anchorOrigin={{
                                  horizontal: "left",
                                  vertical: "center",
                                }}
                              >
                                <MenuItem
                                  onClick={() =>
                                    props.handleSetArtist(ids, true)
                                  }
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    fontSize: "16px",
                                    lineHeight: "12px",
                                    fontWeight: "400",
                                    fontFamily: "DM Sans",
                                  }}
                                >
                                  Add Featured Artist
                                </MenuItem>
                                <Divider />
                                <MenuItem
                                  onClick={() =>
                                    props.handleRecommended(ids, true)
                                  }
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    fontSize: "16px",
                                    lineHeight: "12px",
                                    fontWeight: "400",
                                    fontFamily: "DM Sans",
                                  }}
                                >
                                  Recommended
                                </MenuItem>
                                <Divider />
                                <MenuItem
                                  onClick={() =>
                                    // props.handleRecommended(ids, true)
                                    navigateToArtistDetail(ids)
                                  }
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    fontSize: "16px",
                                    lineHeight: "12px",
                                    fontWeight: "400",
                                    fontFamily: "DM Sans",
                                  }}
                                >
                                  View Artist Profile
                                </MenuItem>

                                {/* <MenuItem
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    fontSize: "16px",
                                    lineHeight: "12px",
                                    fontWeight: "400",
                                    fontFamily: "DM Sans",
                                  }}
                                  onClick={() => props.handleArtistDelete(ids)}
                                >
                                  Delete
                                </MenuItem> */}
                              </Menu>
                            </>
                          ) : (
                            ""
                          )}
                          {props.activetab === "recommended" ? (
                            <>
                              <Tooltip title="Account settings">
                                <IconButton
                                  onClick={handleClick}
                                  size="small"
                                  id={row._id}
                                  sx={{ ml: 2 }}
                                  aria-controls={
                                    threedots ? "account-menu" : undefined
                                  }
                                  aria-haspopup="true"
                                  aria-expanded={threedots ? "true" : undefined}
                                >
                                  <img
                                    src={"/img/Menu Icon.png"}
                                    id={row._id}
                                    height="21.62px"
                                    alt="img"
                                    width="4.63px"
                                  />
                                </IconButton>
                              </Tooltip>
                              <Menu
                                anchorEl={anchorEl}
                                id="account-menu"
                                open={threedots}
                                onClose={handleClose}
                                onClick={handleClose}
                                PaperProps={{
                                  elevation: 0,
                                  sx: {
                                    width: "150px",
                                    overflow: "visible",
                                    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
                                    filter:
                                      "drop-shadow(0px 0px 0px rgba(0,0,0,0.32))",
                                    mt: 2.5,
                                    "& .MuiAvatar-root": {
                                      width: "120px",
                                      height: "132px",
                                      ml: 0.5,
                                      mr: 1,
                                      // mt:5
                                    },
                                    "&:before": {
                                      content: '""',
                                      display: "block",
                                      position: "absolute",
                                      top: 0,
                                      // horizontal: 'center',
                                      right: 62,
                                      width: 10,
                                      height: 10,
                                      bgcolor: "background.paper",
                                      transform:
                                        "translateY(-50%) rotate(45deg)",
                                      zIndex: 0,
                                    },
                                  },
                                }}
                                transformOrigin={{
                                  horizontal: "center",
                                  vertical: "right",
                                }}
                                anchorOrigin={{
                                  horizontal: "left",
                                  vertical: "center",
                                }}
                              >
                                <MenuItem
                                  onClick={() =>
                                    // props.handleRecommended(ids, true)
                                    navigateToArtistDetail(ids)
                                  }
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    fontSize: "16px",
                                    lineHeight: "12px",
                                    fontWeight: "400",
                                    fontFamily: "DM Sans",
                                  }}
                                >
                                  View Artist Profile
                                </MenuItem>
                                <Divider />
                                <MenuItem
                                  onClick={() =>
                                    props.handleRecommended(ids, false)
                                  }
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    fontSize: "16px",
                                    lineHeight: "12px",
                                    fontWeight: "400",
                                    fontFamily: "DM Sans",
                                  }}
                                >
                                  Remove
                                </MenuItem>
                              </Menu>
                            </>
                          ) : (
                            ""
                          )}
                          {props.activetab === "exclusive" ? (
                            <>
                              <Tooltip title="Account settings">
                                <IconButton
                                  onClick={handleClick}
                                  size="small"
                                  id={row._id}
                                  sx={{ ml: 2 }}
                                  aria-controls={
                                    threedots ? "account-menu" : undefined
                                  }
                                  aria-haspopup="true"
                                  aria-expanded={threedots ? "true" : undefined}
                                >
                                  <img
                                    src={"/img/Menu Icon.png"}
                                    id={row._id}
                                    height="21.62px"
                                    alt="img"
                                    width="4.63px"
                                  />
                                </IconButton>
                              </Tooltip>
                              <Menu
                                anchorEl={anchorEl}
                                id="account-menu"
                                open={threedots}
                                onClose={handleClose}
                                onClick={handleClose}
                                PaperProps={{
                                  elevation: 0,
                                  sx: {
                                    width: "150px",
                                    overflow: "visible",
                                    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
                                    filter:
                                      "drop-shadow(0px 0px 0px rgba(0,0,0,0.32))",
                                    mt: 2.5,
                                    "& .MuiAvatar-root": {
                                      width: "120px",
                                      height: "132px",
                                      ml: 0.5,
                                      mr: 1,
                                      // mt:5
                                    },
                                    "&:before": {
                                      content: '""',
                                      display: "block",
                                      position: "absolute",
                                      top: 0,
                                      // horizontal: 'center',
                                      right: 62,
                                      width: 10,
                                      height: 10,
                                      bgcolor: "background.paper",
                                      transform:
                                        "translateY(-50%) rotate(45deg)",
                                      zIndex: 0,
                                    },
                                  },
                                }}
                                transformOrigin={{
                                  horizontal: "center",
                                  vertical: "right",
                                }}
                                anchorOrigin={{
                                  horizontal: "left",
                                  vertical: "center",
                                }}
                              >
                                <MenuItem
                                  onClick={() =>
                                    // props.handleRecommended(ids, true)
                                    navigateToArtistDetail(ids)
                                  }
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    fontSize: "16px",
                                    lineHeight: "12px",
                                    fontWeight: "400",
                                    fontFamily: "DM Sans",
                                  }}
                                >
                                  View Artist Profile
                                </MenuItem>
                                <Divider />
                                <MenuItem
                                  onClick={() =>
                                    props.handleRecommended(ids, false)
                                  }
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    fontSize: "16px",
                                    lineHeight: "12px",
                                    fontWeight: "400",
                                    fontFamily: "DM Sans",
                                  }}
                                >
                                  Remove
                                </MenuItem>
                              </Menu>
                            </>
                          ) : (
                            ""
                          )}
                        </Box>
                      </TableCell>
                    ) : props.activetab === "featured" ? (
                      <TableCell
                        sx={{
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "21px",
                          color: "#636365",
                          height: "auto",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                          }}
                        >
                          {row.followers ? row.followers.length : "0"}
                          <Tooltip title="Account settings">
                            <IconButton
                              onClick={handleClick}
                              size="small"
                              id={row._id}
                              sx={{ ml: 2 }}
                              aria-controls={
                                threedots ? "account-menu" : undefined
                              }
                              aria-haspopup="true"
                              aria-expanded={threedots ? "true" : undefined}
                            >
                              <img
                                src={"/img/Menu Icon.png"}
                                id={row._id}
                                alt="img"
                                height="21.62px"
                                width="4.63px"
                              />
                            </IconButton>
                          </Tooltip>
                          <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={threedots}
                            onClose={handleClose}
                            onClick={handleClose}
                            PaperProps={{
                              elevation: 0,
                              sx: {
                                width: "200px",
                                overflow: "visible",
                                boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
                                filter:
                                  "drop-shadow(0px 0px 0px rgba(0,0,0,0.32))",
                                mt: 2.5,
                                "& .MuiAvatar-root": {
                                  width: "80px",
                                  height: "132px",
                                  ml: 0.5,
                                  mr: 0.5,
                                  // mt:5
                                },
                                "&:before": {
                                  content: '""',
                                  display: "block",
                                  position: "absolute",
                                  top: 0,
                                  // horizontal: 'center',
                                  right: row.is_featured ? 89 : 62,
                                  width: 6,
                                  height: 10,
                                  bgcolor: "background.paper",
                                  transform: "translateY(-50%) rotate(45deg)",
                                  zIndex: 0,
                                },
                              },
                            }}
                            transformOrigin={{
                              horizontal: "center",
                              vertical: "right",
                            }}
                            anchorOrigin={{
                              horizontal: "left",
                              vertical: "center",
                            }}
                          >
                            <MenuItem
                              onClick={() =>
                                // props.handleRecommended(ids, true)
                                navigateToArtistDetail(ids)
                              }
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "16px",
                                lineHeight: "12px",
                                fontWeight: "400",
                                fontFamily: "DM Sans",
                              }}
                            >
                              View Artist Profile
                            </MenuItem>
                            <Divider />
                            <MenuItem
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "16px",
                                lineHeight: "12px",
                                fontWeight: "400",
                                fontFamily: "DM Sans",
                              }}
                              // onClick={() => props.handleDeleteArtist(ids)}
                              onClick={() => props.handleRemoveArtist(ids)}
                            >
                              Remove Featured Artist
                            </MenuItem>
                            <Divider />
                          </Menu>
                        </Box>
                      </TableCell>
                    ) : (
                      <TableCell
                        sx={{
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "21px",
                          color: "#636365",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        {row.likes}
                      </TableCell>
                    )}
                    {props.activetab === "campaigns" && (
                      <>
                        <TableCell
                          sx={{
                            fontFamily: "DM Sans",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "21px",
                            color: "#636365",
                            alignItems: "center",
                          }}
                          className={styles.table_column}
                          align="left"
                        >
                          <div className={styles.table_campaign_col}>
                            <p
                              className={styles.description_text}
                              dangerouslySetInnerHTML={{
                                __html: expandedRows[index]
                                  ? row?.description
                                  : row?.description?.length > 50
                                  ? `${row?.description?.substring(0, 50)?.trim()}...`
                                  : row?.description,
                              }}
                            >
                             
                            </p>
                          </div>
                          {(row?.description?.split(" ").length > 10 || row?.description?.includes("<img")) && (
                            <a
                              onClick={() => toggleReadMore(index)} // Pass the row index to toggle
                              className={styles.read_more_button}
                              style={{
                                cursor: "pointer",
                                color: "#007BFF",
                                textDecoration: "underline",
                              }}
                            >
                              {expandedRows[index] ? "Read Less" : "Read More"}
                            </a>
                          )}
                        </TableCell>
                      </>
                    )}
                    {/*  */}
                    {props.activetab === "campaigns" && (
                      <TableCell
                        sx={{
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "21px",
                          color: "#636365",
                          whiteSpace: "nowrap",
                        }}
                        className={styles.table_columns}
                        align="left"
                      >
                        <div className={styles.auction_last_col}>
                          <div className={styles.action}>
                            <Tooltip title="Edit">
                              <img
                                alt="img"
                                src={"/img/Edit.png"}
                                height="15px"
                                width="14px"
                                onClick={() => props.handleEditPopup(row)}
                              />
                            </Tooltip>

                            <img
                              alt="img"
                              src={"/img/delete.png"}
                              style={{ marginLeft: "10px" }}
                              height="20px"
                              width="18px"
                              onClick={() => props.handleDelete(row._id)}
                            />

                            <span
                              className={
                                row.is_active === true
                                  ? styles.status_active
                                  : styles.status_inactive
                              }
                            >
                              <img
                                src={
                                  row.is_active === true
                                    ? "/img/greendot.png"
                                    : "/img/reddott.png"
                                }
                                height="6px"
                                width="8px"
                                alt="img"
                              />
                              {row.is_active === true ? "Active" : "Inactive"}{" "}
                            </span>
                          </div>
                        </div>
                      </TableCell>
                    )}
                    {/*  */}
                  </TableRow>
                ))
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </div>
  );
}
