import { Button } from "@mui/material"
import styles from "./Moreoption.module.css"
import React from "react"
import { useState } from "react"

export default function Moreoption(props) {
  console.log("pagination props => ",props);
  
  const handleMorecategories = (e) => {
    let value = e.target.value
    props.handleMorecategories(value)
    if (value === "pending") {
      props.setData({ is_accepted: "Pending", offset: 1 })
    } else if (value === "approved") {
      // props.setButton && props.setButton({ type: '', text: '' })
      props.setData({ is_accepted: "Approved", offset: 1 })
    } else if (value === "exclusive") {
      // props.setButton && props.setButton({ type: '', text: '' })
      props.setData({ exclusive_work: "Yes", offset: 1 })
    } else if (value === "featured") {
      // props.setButton && props.setButton({ type: '', text: '' })
      props.setData({ is_featured: true, offset: 1 })
    } else if (value === "decline") {
      // props.setButton && props.setButton({ type: '', text: '' })
      props.setData({ is_accepted: "Rejected", offset: 1 })
    } else if (value === "affiliatedecline") {
      // props.setButton && props.setButton({ type: '', text: '' })
      props.setData({ is_accepted: "Rejected", offset: 1 })
    } else if (value === "is_best_seller") {
      props.setData({ is_best_seller: true, offset: 1 })
    } else if (value === "most_popular") {
      props.setData({ is_most_popular: true, offset: 1 })
    } else if (value === "recommended") {
      props.setData({ is_recommended: true, offset: 1 })
    } else if (value === "request") {
      props.setData({ is_accepted: "Pending", offset: 1 })
    } else if (value === "exshibitiondecline") {
      props.setData({ is_accepted: "Rejected", offset: 1 })
    } else if (value === "affiliatepending") {
      props.setData({ is_accepted: "Pending", offset: 1 })
    } else if (value === "affiliateapproved") {
      props.setData({ is_accepted: "Approved", offset: 1 })
    } else if (value === "gallery_pending") {
      props.setData({ is_accepted: "Pending", offset: 1 })
    } else if (value === "gallery_decline") {
      props.setData({ is_accepted: "Rejected", offset: 1 })
    } else if(value === "buyer") {
      props?.setData({value: value})
    } else if(value === "gallery") {
      props?.setData({value: value})
    } else if (value === "buy") {
      const name = props.usertype.filter(
        (val) => val.name.toLowerCase() === value
      )     
      //  name && name.forEach((val) => props.setData({ ...props.data, order_type: val._id, offset: 1 }));
      props.setData({ ...props.data, order_type: name[0]._id })
    } else if (value === "rent") {
      const name = props.usertype.filter(
        (val) => val.name.toLowerCase() === value
      )
      props.setData({ ...props.data, order_type: name[0]._id })
      //  name && name.forEach((val) => props.setData({ ...props.data, order_type: val._id, offset: 1 }));
    } else if (value === "print") {
      const name = props.usertype.filter(
        (val) => val.name.toLowerCase() === value
      )
      //  name && name.forEach((val) => props.setData({ ...props.data, order_type: val._id, offset: 1 }));
      props.setData({ ...props.data, order_type: name[0]._id })
    } else if (value === "recreate_request") {
      props.setData && props.setData({ offset: 1 })
    } else if (value === "make_offer") {
      props.setData && props.setData({ offset: 1 })
    } else if (value === "qoute_request") {
      props.setData && props.setData({ offset: 1 })
    } else if (value === "upcoming" || "live" || "past") {
      props.setData && props.setData({ is_accepted: "Approved", offset: 1 })
    } else if (
      value === "exshibitionupcoming" ||
      "exhibitionlive" ||
      "exshibitionpast"
    ) {
      props.setData && props.setData({ is_accepted: "Approved", offset: 1 })
    } else {
    }
  }
  return (
    <div className={styles.mains}>
      {props.moreOptions.map((val, index) => (
        
        <Button
          onClick={handleMorecategories}
          key={val.id}
          className={styles.more_categories_btn}
          sx={
            index === 0
              ? {
                  height: "40px",
                  borderRadius: "5px 0px 0px 0px",
                  mt: 1,
                  // fontFamily: 'DM Sans',
                  // fontStyle: 'normal',
                  // fontWeight: "500",
                  // fontSize: "13px",
                  // lineHeight: '12px',
                }
              : index === props.moreOptions.length - 1
              ? {
                  borderRadius: "0px 5px 0px 0px",
                  mt: 1,
                  height: "40px",
                  minWidth: "150px",
                  width: "fit-content",
                  // fontFamily: 'DM Sans',
                  // fontStyle: 'normal',
                  // fontWeight: "500",
                  // fontSize: "13px",
                  // lineHeight: '12px',
                }
              : {
                  height: "40px",
                  borderRadius: "0px",
                  mt: 1,
                  //  fontFamily: 'DM Sans',
                  // fontStyle: 'normal',
                  // fontWeight: "500",
                  // fontSize: "13px",
                  // lineHeight: '12px',
                }
          }
          value={val.value}
          variant={props.activetab === val.value ? "contained" : ""}
          style={
            props.activetab === val.value
              ? { whiteSpace: "nowrap" }
              : {
                  backgroundColor: val.label === "Sent" ? "black" : "white",
                  color: val.label === "Sent" ? "white" : "black",
                  whiteSpace: "nowrap",
                }
          }
        >
          {val.label}
        </Button>
      ))}
    </div>
  )
}
