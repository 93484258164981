import React, { useState } from "react";
import Fivecoltable from "../../../../artist/commonTables/FivecolimgArtist/Fivecoltable";
import TableheaderArtist from "../../../../artist/commonTables/TableheaderArtist/TableheaderArtist";
import styles from './Commonenquirygallery.module.css'
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Pagination, PaginationItem } from "@mui/material";
const filterdata = [
  { label: "Open", value: "open" },
  { label: "Closed", value: "closed" },
];
export default function Commonenquirygallery(props) {
  const [search, setSearch] = useState("");
  const [data, setData] = useState({ offset: 1 });
  const [condition, setCondition] = useState({
    sorting: "",
    filter: "",
  });
  const handleFilter = (event) => {
    const value = event.target.value;
    setCondition({ ...condition, filter: value });
  };
  const handleSearch = (e) => {
    let val = e.target.value;
    setSearch(val);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Adjust how many items you want per page

  // Function to handle page changes
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    window.scrollTo({
      top: 0, // Scroll to the top of the page
      behavior: "smooth", // Smooth scrolling effect
    });
  };
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = props?.data?.slice(startIndex, endIndex);
  console.log(currentItems, "currentItems");
  return (
    <div>
      <TableheaderArtist
        // setOpen={setOpen}
        handleFilter={handleFilter}
        button=""
        
        search={search}
        condition={condition}
        filterdata={filterdata}
        // sortdata={sortdata}
        handleSearch={handleSearch}
      // handleSorting={handleSorting}
      enquiry="enquiry"
      />
      <Fivecoltable
        activetab="gallery_enquiry"
        head={props.head}
        data={  currentItems?.filter((val) => {
            if (search === "") {
              return val
            } else if (
              val.customer_name.toLowerCase().includes(search.toLowerCase())
            ) {
              return val
            }
          })
      }
      />
     <Box className={styles.pagination_box} sx={{}}>
          <Pagination   count={Math.ceil(props?.data?.length / itemsPerPage)} // Total pages
        page={currentPage}
        onChange={handlePageChange}
        variant="outlined"
        shape="rounded"
        renderItem={(item) => (
          <PaginationItem
          sx={{
            "&.Mui-selected": {
              backgroundColor: "black", // Set the selected background to black
              color: "white", // Ensure the text color is visible
              "&:hover": {
                backgroundColor: "black", // Maintain black on hover
              },
            },
          }}
            slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
            {...item}
          />
        )} />
          </Box>
    </div>
  );
}
