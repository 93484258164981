import { Button } from "@mui/material";
import React, { useState } from "react";
import styles from "./CommonDetailAuctionCurator.module.css";
import dateFormat from "../../../../utils/dateFormat";
import capitalizeWords from "../../../../utils/capitalizeContent";
import TableForBuyerBid from "../../../../artist/commonTables/EightcolimgArtist/TableForBuyerBid";
import { useNavigate } from "react-router-dom";

export default function CommondetailAuctionaCurator(props) {
 

  const navigate = useNavigate()
 

  const handlePages = () => {
   
     navigate("/auction")
   }
  return (
    <div className={styles.mains}>
         <div className={styles.main_artistprofile}>
        <img
                src={"/img/_ Back.png"}
                onClick={handlePages}
                className={styles.backimage}
                height="13px"
                width="46px"
                alt="img"
              />
              </div>
      {props.type === "order_detail_gallery" ? (
        ""
      ) : (
        <>
          <h1 className={styles.main_heading}>
          
          </h1>
          <hr />
        </>
      )}
         <div className={styles.more_detail}>
                    <div>
                 
                    
                       

                    
                        
                          <div className={styles.more_detail_child} style={{display:'flex',alignItems:'center',gap:"10px"}}>
                            <h3>Auction Title :</h3>

                            <div className={styles.content} style={{'fontWeight':'800'}}>{ capitalizeWords(props.data && props.data.data.auction?.name)} </div>
                          </div>
                          <div className={styles.more_detail_child}>
                            <div className={styles.title}>Description :</div>

                            <div className={styles.content}>{ capitalizeWords(props.data && props.data.data.auction?.description)} </div>
                          </div>
                          <div className={styles.more_detail_child}>
                            <div className={styles.title}>Created Date :</div>

                            <div className={styles.content}>{dateFormat(props.data && props.data.data.auction?.createdAt)} </div>
                          </div>
                          <div className={styles.more_detail_child}>
                            <div className={styles.title}>Start Date :</div>

                            <div className={styles.content}>{dateFormat(props.data && props.data.data.auction?.start_date)} </div>
                          </div>
                          <div className={styles.more_detail_child}>
                            <div className={styles.title}>End Date :</div>

                            <div className={styles.content}>{ dateFormat(props.data && props.data.data.auction?.end_date)} </div>
                          </div>
                          <div className={styles.more_detail_child} style={{alignItems:'center',gap:'15px'}}>
                            <div className={styles.title}>Application End Date :</div>

                            <div className={styles.content}>{ dateFormat(props.data && props.data.data.auction?.application_end_date)} </div>
                          </div>

                          <div className={styles.more_detail_child}>
                            <div className={styles.title}>No. of Items :</div>

                            <div className={styles.content}>{props.data === null ? 0 : props.data?.data.data.length === 0 ? "0" : props.data.data.data.length}</div>
                          </div>
                         
                        </div>
                  </div>

    
    
     
    
    </div>
  );
}
