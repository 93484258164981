import React, { useEffect, useState } from "react";
// import Pagination from "../../../components/Pages/List/Pagination/Pagination";
import Tablesfivecol from "../../../components/Pages/List/Table/Fivecolumn/Tablefivecol";
import Commonordergallery from "../../../gallery/pages/orders/common/Commonordergallery";
import styles from "./Orderartist.module.css";
import ArtistOrderTable from "../../../components/Pages/List/Table/artistOrderTable/artistOrderTable";
import PaginationWithLimit from "../../../components/Pages/List/Pagination/PaginationWithLimit";
import api from "../../../State/api/Api";
import { Errornotify } from "../../../common/alert/Alert";
import { Box, Pagination, PaginationItem } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
const moreOptions = [
  {
    id: "buy_order_gallery",
    label: "Buy",
    value: "buy_order_gallery",
  },
  {
    id: "rent_order_gallery",
    label: "Rent",
    value: "rent_order_gallery",
  },
  {
    id: "print_order_gallery",
    label: "Print",
    value: "print_order_gallery",
  },
];

const approvedhead = [
  {
    id: "name",
    label: "Order ID",
  },
  {
    id: "name",
    label: "Order Type",
  },
  {
    id: "mobile",
    label: "Customer",
  },
  {
    id: "email",
    label: "Amount",
  },
  {
    id: "type",
    label: "Date",
  },
  {
    id: "action",
    label: "No. of Items",
  },
];
const approvedData = [
  {
    id: 1,
    artist_name: "Galexy On ",
    location: "Emily cooper",
    likes: "artsmiley@gmai.com",
    followers: "Gabrial Zac",
  },
  {
    id: 2,
    artist_name: "Galexy On ",
    location: "Emily cooper",
    likes: "artsmiley@gmai.com",
    followers: "Gabrial Zac",
  },
  {
    id: 3,
    artist_name: "Galexy On ",
    location: "Emily cooper",
    likes: "artsmiley@gmai.com",
    followers: "Gabrial Zac",
  },
  {
    id: 4,
    artist_name: "Galexy On ",
    location: "Emily cooper",
    likes: "artsmiley@gmai.com",
    followers: "Gabrial Zac",
  },
];
const sortdata = [
  { label: "Ascending Date ⬆", value: "ascending" },
  { label: "Descending Date ⬇", value: "descending" },
];

export default function Orderartist() {
  const [orderData, setOrderData] = useState(null);
  console.log(orderData,'orderData')
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12; // Adjust how many items you want per page

  // Function to handle page changes
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  // Slice the data based on current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = orderData?.data?.slice(startIndex, endIndex);


  const [data, setData] = useState({
    //  limit: 41,
    "offset": 2
})
  const [open, setOpen] = React.useState(false);
  const [activetab, setAcitvetab] = useState("buy_order_gallery");
  const [condition, setCondition] = useState({
    search: "",
    sorting: "",
    filter: "",
  });
  console.log(condition, "condition");

  const handleMorecategories = (e) => {
    setAcitvetab(e);
  };
  const handleSearch = (e) => {
    let val = e.target.value;
    setCondition({ ...condition, search: val });
  };
  const handleSorting = (event) => {
    setCondition({ ...condition, sorting: event.target.value });
  };
  const handleFetchOrders = async function () {
    // dispatch(setLoader(true));
    try {
      const result = await api.applicationApi("api/order/fetchOrders", "POST", data);
      if (result && result.status === 200) {
       setOrderData(result.data.data);
        // dispatch(setLoader(false));
      } else if (result.status === 401) {
        Errornotify("Error with code 401");
      }
    } catch (err) {
      Errornotify("Network Error");
    }
  };
  useEffect(() => {
    handleFetchOrders();
  }, [data]);
  return (
    <div className={styles.mains}>
      <div className={styles.components}>
        <Commonordergallery
          setOpen={setOpen}
          condition={condition}
          setAcitvetab={setAcitvetab}
          activetab={activetab}
          moreOptions={moreOptions}
          handleMorecategories={handleMorecategories}
          setCondition={setCondition}
          handleSearch={handleSearch}
          sortdata={sortdata}
          handleSorting={handleSorting}
        />
        <ArtistOrderTable
          head={approvedhead}
          condition={condition}
          orderData={currentItems}
          setOrderData={setOrderData}
        />
        
          <Box className={styles.pagination_box} sx={{}}>
          <Pagination   count={Math.ceil(orderData?.data?.length / itemsPerPage)} // Total pages
        page={currentPage}
        onChange={handlePageChange}
        variant="outlined"
        shape="rounded"
        renderItem={(item) => (
          <PaginationItem
          sx={{
            "&.Mui-selected": {
              backgroundColor: "black", // Set the selected background to black
              color: "white", // Ensure the text color is visible
              "&:hover": {
                backgroundColor: "black", // Maintain black on hover
              },
            },
          }}
            slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
            {...item}
          />
        )} />
          </Box>
   
 
        {/* <PaginationWithLimit totaldata={orderData?.data?.length} data={data} setData={setData} /> */}
      </div>
    </div>
  );
}
