import React from "react";
import styles from "./ContactusSuperAdminTable.module.css";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import CircularLoader from "../../../../common/loader/Loader";
import { useSelector } from "react-redux";
import moment from "moment";
function ContactusSuperAdminTable(props) {
  console.log("contactus props----->", props);
  const loader = useSelector((state) => state.loader);
  return (
    <div className={styles.mains}>
      <TableContainer
        component={Paper}
        sx={{ backgroundColor: "#F6F6F6", color: "black" }}
      >
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#bfbbc3" }}>
              {props?.head?.map((column, i) => (
                <TableCell
                  className={styles.table_head}
                  align={props.head.length - 1 === i ? "left" : "left"}
                  sx={{
                    whiteSpace: "nowrap",
                    fontFamily: "DM Sans",
                    fontWeight: "500",
                    fontSize: "16px",
                    height: "21px",
                    color: "#1C1C1C",
                  }}
                  key={i}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {loader.loader ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={8}>
                  <Box className={styles.loader_box}>
                    {" "}
                    <CircularLoader size={60} />
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {props?.data && props?.data?.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={8}>
                    <Box className={styles.loader_box}> No Data Found</Box>
                  </TableCell>
                </TableRow>
              ) : (
                props?.data &&
                props?.data?.map((row, index) => (
                  <TableRow
                    style={{ color: "#636365", height: "30px" }}
                    className={styles.table_row}
                    key={row._id}
                  >
                    <TableCell className={styles.table_column} align="left">
                      {row?.first_name} {row?.last_name}
                    </TableCell>
                    <TableCell className={styles.table_column} align="left">
                      {row?.phone}
                    </TableCell>
                    <TableCell className={styles.table_column} align="left">
                      {row?.email}
                    </TableCell>
                    <TableCell className={styles.table_column} align="left">
                      {moment(row?.createdAt)?.format("DD-MM-YYYY")}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: 4,
                        }}
                      >
                        {row.message}
                        <img
                          src={"/img/delete.png"}
                          height="15px"
                          width="14px"
                          alt="img"
                          onClick={() => props.handleDelete(row._id)}
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </div>
  );
}

export default ContactusSuperAdminTable;
