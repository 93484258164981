import {
  Button,
  createTheme,
  Grid,
  TextField,
  ThemeProvider,
  Typography,
  Link,
  Container
} from "@mui/material";
import React, { useState } from "react";
import styles from "./ContactUs.module.css";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import { TextareaAutosize } from "@mui/base";
import homeImage from "../../../constant";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { handelContactDetails } from "../../../State/api/userApi";
import { AlertsSuccess, Errornotify } from "../../../common/alert/Alert";
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput from "react-phone-input-2";
import { RiPhoneLine, RiMailOpenLine, RiHome5Line } from "react-icons/ri";
import { validationForContactUs } from "../../../validation/validationForContactUs";

const theme = createTheme({
  typography: {
    h4: {
      fontFamily: "Marcellus",
      fontSize: "2vw",
    },
    h6: {
      fontFamily: "Marcellus",
      fontSize: "1vw",
    },
  },
});
function ContactUs() {
  const [captcha, setCaptcha] = useState(false);
  const [error, setErrors] = useState({})
  const [contactDetail, setContactDetail] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    message: "",
    captcha : false
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleNavigate = (route) => {
    navigate(route);
  };

  const onChange = (value) => {
    setContactDetail({ ...contactDetail, captcha: value ? true : false });
  };
  
  const handlePhoneInput = (value) => {
    setContactDetail({ ...contactDetail, phone: value });
  };
  const handleChangeDetails = (e) => {
    const { name, value } = e.target;
  
    // Check if the field is 'first_name' or 'last_name'
    if (name === "first_name" || name === "last_name") {
      // Allow only alphabetic characters (and spaces if needed)
      const isValid = /^[a-zA-Z\s]*$/.test(value);
      if (!isValid) {
        return; // Exit early if the input is invalid
      }
    }
  
    // Update state for valid inputs
    setContactDetail({ ...contactDetail, [name]: value });
    //console.log(contactDetail);
  };

  
  
  const handleSubmitDetail = () => {
    
   const {errors, isValidAddress} = validationForContactUs(contactDetail)
   if(isValidAddress) {
    dispatch(handelContactDetails(contactDetail, setContactDetail));
    setErrors({})
   }else {
    setErrors(errors)
   }
   
  
  };
  return (
    <ThemeProvider theme={theme}>
      <AlertsSuccess />
      <div className={styles.contactus}>
        <div className={styles.headerCategory}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
            <div className={styles.pageitle}>
              <h1>Contact Us</h1>
              <ul>
                <li><Link onClick={() => handleNavigate("/")}>Home /{" "} </Link></li>
                <li>Contact Us</li>
              </ul>
            </div>
          </Container> 
            
        </div>
        <div className={styles.formsec}>
          <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={5} md={5}>
              <img
                className={styles.img_fluid}
                src={"/img/contactim.png"}
                alt="img"
              />
             
            </Grid>
            <Grid item xs={12} sm={1} md={1}></Grid>
            <Grid item xs={12} sm={5} md={5}>
              <div className={styles.mainForm}>
                <h3>Let's Get In Touch</h3>
                <div className="form-group">
                <label>First Name *</label>
                <input
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Enter your first Name"
                  className="form-control"
                  name="first_name"
                  value={contactDetail.first_name}
                  onChange={handleChangeDetails}
                />
                  {error?.first_name &&  <div style={{color:"red",fontWeight:'500',fontSize:"14"}}>{error.first_name}</div>}
                
                </div>
                <div className="form-group">
                <label>Last Name *</label>
                <input
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Enter your last name"
                  className="form-control"
                  name="last_name"
                  value={contactDetail.last_name}
                  onChange={handleChangeDetails}
                />
                
               {error?.last_name &&  <div style={{color:"red",fontWeight:'500',fontSize:"14"}}>{error.last_name}</div>}
                </div>
                <div className="form-group">
                <label>Email *</label>
                <input
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Enter your email address"
                  className="form-control"

                  name="email"
                  value={contactDetail.email}
                  onChange={handleChangeDetails}
                />
            {error?.email &&  <div style={{color:"red",fontWeight:'500',fontSize:"14"}}>{error.email}</div>}
                </div>
                <div className="form-group">
                <labe>Phone *</labe>
                <PhoneInput
                  inputStyle={{ background: "transparent" }}
                  containerStyle={{
                    background: "transparent",
                    width: "100%",
                  }}
                  placeholder="Enter your phone number"
                  country={"in"}
                  name="phone"
                  value={contactDetail.phone}
                  onChange={handlePhoneInput}
                  
                />
                  {error?.phone &&  <div style={{color:"red",fontWeight:'500',fontSize:"14"}}>{error.phone}</div>}
                </div>
                <div className="form-group">
                <label>Message *</label>
                <textarea
                  maxRows={5}
                  minRows={5}
                 className="form-control"
               
                  name="message"
                  value={contactDetail.message}
                  onChange={handleChangeDetails}
                  fullWidth
                  placeholder="Write your message here"
                />
                  {error?.message &&  <div style={{color:"red",fontWeight:'500',fontSize:"14"}}>{error.message}</div>}
                </div>
                <div className="form-group">
                <ReCAPTCHA
                  sitekey="6LcbcC0qAAAAAHuVO0pv2LZpOnk6ZRxlYOAgM6z7"
                  onChange={onChange}
                />
                 {error?.captcha && (
    <div style={{ color: "red", fontWeight: "500", fontSize: "14px" }}>
      {error.captcha}
    </div>
  )}
                </div>
               <div className={styles.text_right}>
               <button
                className={styles.apply_btn}
                onClick={handleSubmitDetail}
              >
                Submit
              </button>
               </div>

                
                
            
                
                
                
             
              </div>
            </Grid>
          </Grid>
          </Container>
        </div>
        
        <div className={styles.map__div}>
         <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
          <div className={styles.contmp}>
            <div className={styles.mapdc}>
            <iframe className={styles.frme} src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d115579.26579314738!2d55.15919906514963!3d25.14069211111819!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x3e5f43ae52033d2b%3A0xd884099aa41fa23b!2sF19%20Al%20Asayel%20St%20-%20Al%20Quoz%20-%20Al%20Quoz%20Industrial%20Area%202%20-%20Dubai%20-%20United%20Arab%20Emirates!3m2!1d25.1407149!2d55.2416006!5e0!3m2!1sen!2sin!4v1736499675238!5m2!1sen!2sin" width="100%" height="350" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
          <div className={styles.contactInfo}>
            <div className={styles.contactInfo__details}>
              <div className={styles.contactdetails__Icon}>
              <RiHome5Line />
              </div>
              <p className={styles.prdgf}>
              ArtSmiley Art Gallery LLC, F19 Al Asayel St - Al Quoz - Al Quoz Industrial Area 2 - Dubai - United Arab Emirates.
              </p>
            </div>
            <div className={styles.contactInfo__details}>
              <div className={styles.contactdetails__Icon}>
              <RiMailOpenLine />
              </div>
              <a href="mailto:contactus@artsmiley.com" className={styles.prdgf} >
              contactus@artsmiley.com
              </a>
            </div>
            <div className={styles.contactInfo__details}>
              <div className={styles.contactdetails__Icon}>
              <RiPhoneLine />
              </div>
              <div>
                <a href="tel:+971 56 175 6215" className={styles.prdgf}>
                +971 56 175 6215
                </a> 
              </div>
            </div>
          </div>
          </div>
         </Container>
          
        </div>
      </div>
    </ThemeProvider>
  );
}

export default ContactUs;
