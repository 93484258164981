import {
  Button,
  Checkbox,
  Fade,
  FormControlLabel,
  FormGroup,
  Modal,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./Uploadpopup.module.css";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import homeImage from "../../../../constant";
import { useNavigate } from "react-router-dom";
import { AiFillCloseCircle } from "react-icons/ai";
import { subscriptionPurchase } from "../../../../State/api/userApi";
import { useDispatch } from "react-redux";

export default function Uploadpopupsubscription(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch()
 


  const handleClose = (e) => {
    props.setOpen(false);

    navigate("/arts");
  };

  const sortedSubscriptionData = props.subscriptionData
    ? [...props.subscriptionData].sort(
        (a, b) => new Date(a.plan_price) - new Date(b.plan_price)
      )
    : [];
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Box className={styles.modal_del}>
            <div className={styles.main_div}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <AiFillCloseCircle
                  style={{ fontSize: "22px", cursor: "pointer" }}
                  onClick={() => {
                    props.setOpen(false);
                    navigate("/arts");
                  }}
                />
              </div>
              <div className={styles.mains_common_subs_card}>
                {sortedSubscriptionData.map((val) => (
                  <div
                    key={val.id}
                    className={
                      val.plan_name === "Standard"
                        ? styles.card_dark
                        : styles.card
                    }
                  >
                    <div
                      className={
                        val.plan_name === "Standard"
                          ? styles.card_child_dark
                          : styles.card_child
                      }
                    >
                      <Stack sx={{ display: "flex", flexDirection: "row",padding:"0px" }}>
                        <FormControlLabel
                          required
                          control={
                            <Checkbox
                            checked={props.selectedPlans[val.plan_name]?.monthly || false}
                            onChange={props.handleCheckboxChange(val.plan_name, 'monthly')}
                            sx={{
    color: val.plan_name === "Standard" ? "white" : "black",
    '&.Mui-checked': {
      color: val.plan_name === "Standard" ? "white" : "black",
    },
  }}
                            />
                          }
                          label="monthly"
                          sx={{
    color: val.plan_name === "Standard" ? "white" : "black",
    '&.Mui-checked': {
      color: val.plan_name === "Standard" ? "white" : "black",
    },
  }}
                        />
                        <FormControlLabel
                          required
                          control={
                            <Checkbox
                            checked={props.selectedPlans[val.plan_name]?.yearly || false}
                            onChange={props.handleCheckboxChange(val.plan_name, 'yearly')}
                            sx={{
    color: val.plan_name === "Standard" ? "white" : "black",
    '&.Mui-checked': {
      color: val.plan_name === "Standard" ? "white" : "black",
    },
  }}
                            />
                          }
                          label="yearly"
                          sx={{
    color: val.plan_name === "Standard" ? "white" : "black",
    '&.Mui-checked': {
      color: val.plan_name === "Standard" ? "white" : "black",
    },
  }}
                        />
                      </Stack>
                      <div
                        className={
                          val.plan_name === "Standard"
                            ? styles.pricing_dark
                            : styles.pricing
                        }
                      >
                        <h3>${ props.selectedPlans[val.plan_name]?.yearly ? Math.round((val.plan_price * 12) * (1 - 0.20)).toFixed(0) : val.plan_price}</h3>
                        <h6>/ {props.selectedPlans[val.plan_name]?.yearly ? "yearly" : "monthly"}</h6>
                      </div>
                      <h6 style={{textAlign:'center'}}>{val.plan_name}</h6>
                      <p style={{color: val.plan_name === "Standard" ? "white" : "black"}}>
                        {val?.plan_description?.length > 50
                          ? val?.plan_description?.slice(0, 30) + "..."
                          : val?.plan_description}
                      </p>
                      {val.feature_ids?.map((item) => (
                        <div
                          key={item.id}
                          className={
                            val.plan_name === "Standard"
                              ? styles.content_dark
                              : styles.content
                          }
                        >
                          {val.plan_name === "Standard" ? (
                            <div>
                              <img
                                src={"/img/Correct-2.png"}
                                height="100%"
                                width="100%"
                                alt="img"
                              />
                            </div>
                          ) : (
                            <div>
                              <img
                                src={"/img/Correct.png"}
                                height="100%"
                                width="100%"
                                alt="img"
                              />
                            </div>
                          )}

                          <h2>
                            {item._id.name}: {item?._id?.count}
                          </h2>
                        </div>
                      ))}
                      {val.plan_name === "Standard" ? (
                        <div className={styles.btn_div}>
                          <Button
                            variant="outlined"
                            sx={{
                              borderColor: "white",
                              color: "white",
                              width: "183px",
                              height: "41px",
                              borderRadius: "6px",
                            }}
                            onClick={() =>
                              navigate(`/user/mycart-subscription/${val?._id}`,{state: {planName:props.selectedPlans[val.plan_name]?.yearly ? "yearly" : "monthly"}  })
                            }
                          >
                            Choose Plan
                          </Button>
                        </div>
                      ) : (
                        <div className={styles.btn_div}>
                          <Button
                            variant="outlined"
                            sx={{
                              borderColor: "black",
                              color: "black",
                              width: "183px",
                              height: "41px",
                              borderRadius: "6px",
                            }}
                            
                            onClick={() => {
                              if (val?.plan_name === "Basic") {
                                dispatch(
                                  subscriptionPurchase({
                                    subscriptionName: val?._plan_name,
                                    artistId: localStorage.getItem("user_id"),
                                    subscriptionId: val?._id,
                                    subscriptionTenure: "monthly",
                                    paymentType: "Paypal",
                                    transactionId: "",
                                    amount: 0,
                                  })
                                );
                              } else {
                                navigate(`/user/mycart-subscription/${val?._id}`, {
                                  state: { planName: props.selectedPlans[val.plan_name]?.yearly ? "yearly" : "monthly" },
                                });
                              }
                            }}
                            // onClick={() =>
                            //   navigate(`/user/mycart-subscription/${val?._id}`,{state: {planName:props.selectedPlans[val.plan_name]?.yearly ? "yearly" : "monthly"}  })
                            // }
                          >
                            Choose Plan
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
