import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AlertsSuccess } from "../../../../../common/alert/Alert"
import {
  editTestimonial,
  handleFetchCuratorProfile,
  fetchTestimonialData,
  handleAddTestimonial,
} from "../../../../../State/api/superadminApi"
import { setTestimonialdata } from "../../../../../State/Features/superadmin/testimonialSlice"
import Pagination from "../../Pagination/Pagination"
import Addimgpopup from "../../Popupdelete/addSubscription/addwithImage/Addimgpopup"
import Fourcolimg from "../../Table/Fourcolumn/Fourcolimg"
import Tableheader from "../../Table/Tableheader/Tableheader"
import styles from "./CuratorTestimonials.module.css"
import { TextField, Box, Button } from "@mui/material";
const head = [
  {
    id: 1,
    label: "Image",
  },
  {
    id: 2,
    label: "Customer Name",
  },
  {
    id: 3,
    label: "Position",
  },
  {
    id: 4,
    label: "Description",
  },
]


export default function CuratorTestimonial() {
 
  const [data, setData] = useState({ offset: 0 })
 
 
  const dispatch = useDispatch()
 
  
  const [description, setDescription] = useState("");
  const [mode, setMode] = useState("add"); // "add" or "edit"
  const [isActive, setIsActive] = useState(false);
  const [currentId, setCurrentId] = useState("");
  const [position, setPosition] = useState("")


  const [error, setError] = useState("");

  const [positionerror, setPositionerror] = useState("")

  const validateDescription = (value) => {
    if (!value.trim()) {
      setError("Message is required.");
      return false;
    }
    if (value.length < 10) {
      setError("Message must be at least 10 characters long.");
      return false;
    }
    setError(""); // No errors
    return true;
  };

  const validatePosition = (value) => {
    if (!value.trim()) {
      setPositionerror("Position is required.");
      return false;
    }
    if (value.length < 3) {
      setPositionerror("Position must be at least 3 characters long.");
      return false;
    }
    setPositionerror(""); // No errors
    return true;
  };
  const fetchData = () => {
    dispatch(fetchTestimonialData(data))
  }
  useEffect(() => {
    fetchData()
  }, [data])

  

  useEffect(() => {
      dispatch(handleFetchCuratorProfile())
    }, [dispatch])

  

  const testdata = useSelector((state) => state.testmonialdata.data)
  const curatorDetails = useSelector((state) => state.recreateData.data);

  const handleDescriptionChange = (e) => {
    const value = e.target.value;
    setDescription(value);
    validateDescription(value); // Trigger validation on each change
  };

  const handlePositionChange = (e) => {
    const value = e.target.value;
    setPosition(value);
    validatePosition(value); 
  };

  useEffect(() => {
    if (testdata?.data && testdata.data.length > 0) {
      const firstTestimonial = testdata.data[0];
      setDescription(firstTestimonial.description || "");
      setPosition(firstTestimonial.position || "")
      setIsActive(firstTestimonial.is_active === "Active");
      setMode("edit"); // Switch to edit mode if data exists
      setCurrentId(firstTestimonial._id); // Capture the ID for editing
    } else {
      setMode("add"); // Default to add mode if no data exists
    }
  }, [testdata]);



  const handleSubmit = () => {
    const isDescriptionValid = validateDescription(description);
    const isPositionValid = validatePosition(position);

    if (isDescriptionValid && isPositionValid) {
    const data = {
      customer_name: curatorDetails?.curator_name || "",
      description,
      position,
      ...(mode === "add" && { image: curatorDetails?.profile_image || null}),
      ...(mode === "edit" && { is_active: isActive ? "Active" : "Inactive" }),
    };
  
    if (mode === "add") {
      dispatch(handleAddTestimonial(data, () => {}, fetchData));
      console.log("Added Testimonial:", data);
    } else if (mode === "edit") {
      dispatch(editTestimonial(currentId, data, () => {}, fetchData));
      console.log("Updated Testimonial:", data);
    }
  }
  };

 

 
  return (
    <div className={styles.mains}>
      {" "}
      <div className={styles.components}>
        <AlertsSuccess />
      
      

    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: 2,
        gap: 2,
      }}
    >

<TextField
        label="Position"
        placeholder="Enter your position"
        variant="outlined"
        fullWidth
        value={position}
        onChange={handlePositionChange}
        InputLabelProps={{
          shrink: !!position, // Ensures the label shrinks when there's a default value
        }}
        error={!!positionerror} // Highlights the field in red if there's an error
        helperText={positionerror}
        sx={{
          width: "100%",
          marginTop:'10px',
          backgroundColor: "#f9f9f9",
          borderRadius: 1,
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#ccc", // Default border color
            },
            "&:hover fieldset": {
              borderColor: "#888", // Hover border color
            },
            "&.Mui-focused fieldset": {
              borderColor: "#3f51b5", // Focus border color
              borderWidth: 2, // Thicker border
            },
          },
        }}
      />

     <TextField
  label="Your Message"
  multiline
  rows={8}
  placeholder="Type your message here..."
  variant="outlined"
  fullWidth
  value={description}
  onChange={handleDescriptionChange}
  InputLabelProps={{
    shrink: !!description, // Ensures the label shrinks when there's a default value
  }}
  error={!!error} // Highlights the field in red if there's an error
  helperText={error}
  sx={{
    width: "100%",
    backgroundColor: "#f9f9f9",
    borderRadius: 1,
    marginTop:'20px',
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#ccc", // Default border color
      },
      "&:hover fieldset": {
        borderColor: "#888", // Hover border color
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3f51b5", // Focus border color
        borderWidth: 2, // Thicker border
      },
    },
  }}
/>


     <Button variant="contained" color="primary" onClick={handleSubmit}>
  {mode === "add" ? "Add Testimonial" : "Update Testimonial"}
</Button>
    </Box>
      </div>
    </div>
  )
}
