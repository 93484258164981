import { Grid } from "@mui/material";
import React from "react";
import Commonuploadartsecond from "../../../../gallery/pages/uploadArt/commonUploadartsecond/Commonuploadartsecond";
import Steppergallery from "../../../../gallery/pages/uploadArt/stepper/Steppergallery";
import styles from "./Seconduploadartist.module.css";

const stage = 1;
function Seconduploadartist(props) {  
  return (
    <div className={styles.mains}>
      <Grid container spacing={{ sm: 5 }} columns={{ xs: 12, sm: 12, md: 12 }}>
        <Grid item xs={0} sm={4} md={2}></Grid>
        <Grid item xs={12} sm={8} md={10} sx={{ my: 2 }}>
          <Steppergallery stage={stage} />
        </Grid>
      </Grid>
      <Commonuploadartsecond
        uploadArt={props.uploadArt}
        setUploadArt={props.setUploadArt}
        handleChangedata={props.handleChangedata}
        handleChangedataCategories={props.handleChangedataCategories}
        src={props.src}
        handleChangeOrientation={props.handleChangeOrientation}
        setCategory={props.setCategory}
        category={props.category}
      />
    </div>
  );
}

export default React.memo(Seconduploadartist);
