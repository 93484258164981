// import { ThemeProvider } from "@emotion/react";
// import { Button, Grid, Typography } from "@mui/material";
// import { createTheme } from "@mui/system";
// import React, { useEffect, useState } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import styles from "./ExhibitionUser.module.css";
// import homeImage from "../../../constant";
// import api from "../../../State/api/Api";
// import { setLoader } from "../../../State/Features/common/loaderSlice";
// import { useDispatch, useSelector } from "react-redux";
// import serverimage from "../../../serverConstant";
// import { setActiveStatus } from "../../../State/Features/user/activetabSlice";

// const theme = createTheme({
//   typography: {
//     h4: {
//       fontFamily: "Marcellus",
//       fontSize: "2vw",
//     },
//     h6: {
//       fontFamily: "Marcellus",
//       fontSize: "1vw",
//     },
//   },
// });
// const ExhibitonContent = [
//   {
//     id: 1,
//     imgUrl1: "/img/Rectangle 5259.png",
//     imgUrl2: "/img/Rectangle 5260.png",
//   },
//   {
//     id: 2,
//     imgUrl1: "/img/Rectangle 5261.png",
//     imgUrl2: "/img/Rectangle 5262.png",
//   },
// ];
// const historyCard = [
//   {
//     id: 1,
//     imgUrl: "/img/Rectangle 5195.png",
//   },
//   {
//     id: 2,
//     imgUrl: "/img/Rectangle 5196.png",
//   },
// ];
// function ExhibitionUser() {
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const [exshibitionData, setExshibitionData] = useState();

//   const navigatetoexhibitiondetail = (id) => {
//     navigate(`/user/exhibition/${id}`);
//   };
//   const handleNavigate = (route) => {
//     navigate(route);
//   };
//   const handleFetchExshibition = async function () {
//     dispatch(setLoader(true));
//     try {
//       const result = await api.applicationApi("api/user/getexhibits");
//       if (result && result.status === 200) {
//         setExshibitionData(result.data.responses);
//         dispatch(setLoader(false));
//       } else if (result.status === 401) {
//       }
//     } catch (err) {
//       console.log(err);
//     }
//   };
//   useEffect(() => {
//     handleFetchExshibition();
//   }, []);

//   const auctionstatus = useSelector((state) => state.activetab);
//   const handleAuctionstatus = (vl) => {
//     dispatch(setActiveStatus({ status: vl, maintab: "exhibition" }));
//   };
//   return (
//     <ThemeProvider theme={theme}>
//       <div className={styles.exhibition}>
//         <div className={styles.headerCategory}>
//           <br />
//           <div>
//             <Typography fontWeight="500" variant="h4">
//               <span className={styles.headerTitle}>Exhibitions</span>
//             </Typography>
//             <Typography fontWeight="500" ml={8} variant="h6">
//               <span className={styles.headerSubTitle}>
//                 <span
//                   style={{ cursor: "pointer" }}
//                   onClick={() => handleNavigate("/user")}
//                 >
//                   Home /{" "}
//                 </span>
//                 <span
//                   style={{ cursor: "pointer" }}
//                   onClick={() => handleNavigate("/user/")}
//                 >
//                   Events
//                 </span>{" "}
//                 / <b>Exhibition</b>
//               </span>
//             </Typography>
//           </div>
//         </div>
//         <div className={styles.main__content}>
//           <div className={styles.link__div}>
//             <Link style={{ textDecoration: "none", color: "black" }}>
//               <Typography
//                 color={auctionstatus.status === "current" ? "" : "#636365"}
//                 onClick={() => handleAuctionstatus("current")}
//                 fontSize="1vw"
//                 fontFamily="marcellus"
//               >
//                 CURRENT EXHIBITIONS
//               </Typography>
//             </Link>
//             <div className={styles.verticalLine3}></div>
//             <Link
//               onClick={() => handleAuctionstatus("past")}
//               style={{ textDecoration: "none", color: "black" }}
//             >
//               <Typography
//                 fontSize="1vw"
//                 color={auctionstatus.status === "past" ? "" : "#636365"}
//                 onClick={() => handleAuctionstatus("past")}
//                 fontFamily="marcellus"
//               >
//                 PAST
//               </Typography>
//             </Link>
//             <div className={styles.verticalLine3}></div>
//             <Link style={{ textDecoration: "none", color: "black" }}>
//               <Typography
//                 onClick={() => handleAuctionstatus("upcoming")}
//                 fontSize="1vw"
//                 color={auctionstatus.status === "upcoming" ? "" : "#636365"}
//                 fontFamily="marcellus"
//               >
//                 UPCOMING
//               </Typography>
//             </Link>
//           </div>
//           <hr className={styles.divider} />
//           <div className={styles.exhibitioncards}>
//             <Grid container spacing={0}>
//               {exshibitionData &&
//               exshibitionData.filter((val) => {
//                 return (
//                   (auctionstatus.status === "upcoming"
//                     ? new Date(val.start_date.substring(0, 10)) > new Date() > 0
//                     : auctionstatus.status === "past"
//                     ? new Date(val.end_date.substring(0, 10)) < new Date() > 0
//                     : new Date(val.start_date.substring(0, 10)) <=
//                         new Date() >
//                         0 &&
//                       new Date(val.end_date.substring(0, 10)) >=
//                         new Date() >
//                         0) && val.is_accepted === "Approved"
//                 );
//               }).length === 0 ? (
//                 <label style={{ textAlign: "center", width: "100%" }}>
//                   No Exhibition Found
//                 </label>
//               ) : (
//                 exshibitionData &&
//                 exshibitionData
//                   .filter((val) => {
//                     return (
//                       (auctionstatus.status === "upcoming"
//                         ? new Date(val.start_date.substring(0, 10)) >
//                           new Date() >
//                           0
//                         : auctionstatus.status === "past"
//                         ? new Date(val.end_date.substring(0, 10)) <
//                           new Date() >
//                           0
//                         : new Date(val.start_date.substring(0, 10)) <=
//                             new Date() >
//                             0 &&
//                           new Date(val.end_date.substring(0, 10)) >=
//                             new Date() >
//                             0) && val.is_accepted === "Approved"
//                     );
//                   })
//                   .map((item, i) =>
//                     i === 0 || i % 2 === 0 ? (
//                       <Grid item xs={12} sm={12} md={12}>
//                         <div
//                           className={styles.leftcard}
//                           onClick={() => navigatetoexhibitiondetail(item._id)}
//                         >
//                           <img
//                             alt="img"
//                             className={styles.leftImage}
//                             src={ item.image}
//                           />
//                           <div className={styles.cardInfo__right}>
//                             <img
//                               alt="img"
//                               className={styles.designImage}
//                               src={"/img/Design.png"}
//                             />
//                             <Typography fontFamily="marcellus" fontSize="2vw">
//                               {item.title}
//                               <iframe
//                                 width="100%"
//                                 height="450"
//                                 src="https://d7mntklkfre1v.cloudfront.net/virtual-exhibitions/?i=9090"
//                                 frameborder="0"
//                                 allowfullscreen
//                               ></iframe>
//                             </Typography>
//                             <Typography
//                               fontSize="0.8vw"
//                               ml={0.5}
//                               color="#636365"
//                             >
//                               {item.concept}
//                             </Typography>
//                             <div className={styles.logos_flex}>
//                               <img
//                                 alt="img"
//                                 className={styles.loc__logo}
//                                 src={"/img/location.png"}
//                               />
//                               <Typography ml={1} fontSize="0.8vw">
//                                 {item.venue_address} | {item.city}
//                               </Typography>
//                             </div>
//                             <div className={styles.logos_flex}>
//                               <img
//                                 alt="img"
//                                 className={styles.loc__logo}
//                                 src={"/img/calendar-2.png"}
//                               />
//                               <Typography ml={1} fontSize="0.8vw">
//                                 Monday - Tuesday
//                               </Typography>
//                             </div>
//                             <div className={styles.logos_flex}>
//                               <img
//                                 alt="img"
//                                 className={styles.loc__logo}
//                                 src={"/img/Group 1000007138.png"}
//                               />
//                               <Typography
//                                 ml={1}
//                                 fontSize="0.8vw"
//                                 fontWeight="600"
//                               >
//                                 {item.item_ids?.length}{" "}
//                               </Typography>
//                               <Typography ml={0.5} fontSize="0.8vw">
//                                 Art Showcase
//                               </Typography>
//                             </div>
//                             <div className={styles.logos_flex}>
//                               <img
//                                 alt="img"
//                                 className={styles.loc__logo}
//                                 src={"/img/Group 1000007139.png"}
//                               />
//                               <Typography
//                                 ml={1}
//                                 fontSize="0.8vw"
//                                 fontWeight="600"
//                               >
//                                 {item.artist_ids.length}{" "}
//                               </Typography>{" "}
//                               <Typography ml={0.5} fontSize="0.8vw">
//                                 Artists
//                               </Typography>
//                             </div>
//                             <button className={styles.interested__button}>
//                               <Typography fontSize="0.8vw">
//                                 Interested
//                               </Typography>
//                             </button>
//                             <Typography fontSize="0.8vw" mt={11}>
//                               By {item.chief_guest} | {item.updatedAt}
//                             </Typography>
//                           </div>
//                         </div>
//                       </Grid>
//                     ) : (
//                       <Grid item xs={12} sm={12} md={12}>
//                         <div
//                           onClick={() => navigatetoexhibitiondetail(item._id)}
//                           className={styles.rightcard}
//                         >
//                           <div className={styles.cardInfo__left}>
//                             <img
//                               alt="img"
//                               className={styles.designImage}
//                               src={"/img/Design.png"}
//                             />
//                             <Typography fontFamily="marcellus" fontSize="2vw">
//                               {item.title}
//                             </Typography>
//                             <Typography
//                               fontSize="0.8vw"
//                               ml={0.5}
//                               color="#636365"
//                             >
//                               {item.concept}
//                             </Typography>
//                             <div className={styles.logos_flex}>
//                               <img
//                                 alt="img"
//                                 className={styles.loc__logo}
//                                 src={"/img/location.png"}
//                               />
//                               <Typography ml={1} fontSize="0.8vw">
//                                 {item.venue_address} | {item.city}
//                               </Typography>
//                             </div>
//                             <div className={styles.logos_flex}>
//                               <img
//                                 alt="img"
//                                 className={styles.loc__logo}
//                                 src={"/img/calendar-2.png"}
//                               />
//                               <Typography ml={1} fontSize="0.8vw">
//                                 Monday - Tuesday
//                               </Typography>
//                             </div>
//                             <div className={styles.logos_flex}>
//                               <img
//                                 alt="img"
//                                 className={styles.loc__logo}
//                                 src={"/img/Group 1000007138.png"}
//                               />
//                               <Typography
//                                 ml={1}
//                                 fontSize="0.8vw"
//                                 fontWeight="600"
//                               >
//                                 {item.item_ids.length}{" "}
//                               </Typography>
//                               <Typography ml={0.5} fontSize="0.8vw">
//                                 Art Showcase
//                               </Typography>
//                             </div>
//                             <div className={styles.logos_flex}>
//                               <img
//                                 alt="img"
//                                 className={styles.loc__logo}
//                                 src={"/img/Group 1000007139.png"}
//                               />
//                               <Typography
//                                 ml={1}
//                                 fontSize="0.8vw"
//                                 fontWeight="600"
//                               >
//                                 15{" "}
//                               </Typography>{" "}
//                               <Typography ml={0.5} fontSize="0.8vw">
//                                 Artists
//                               </Typography>
//                             </div>
//                             <button className={styles.interested__button}>
//                               <Typography fontSize="0.8vw">
//                                 Interested
//                               </Typography>
//                             </button>
//                             <Typography fontSize="0.8vw" mt={11}>
//                               By Phin Jennings | 01 Apr 2022
//                             </Typography>
//                           </div>
//                           <img
//                             alt="img"
//                             className={styles.rightImage}
//                             src={ item.image}
//                           />
//                         </div>
//                       </Grid>
//                     )
//                   )
//               )}
//             </Grid>
//           </div>
//         </div>
//         <div className={styles.history__exhibitions}>
//           <Grid container>
//             <Grid item xs={12} sm={3} md={3}>
//               <div className={styles.history__heading}>
//                 <div className={styles.hh__Main}>
//                   <div className={styles.design__imagediv}>
//                     <img
//                       alt="img"
//                       className={styles.design__image}
//                       src={"/img/Design.png"}
//                     />
//                   </div>

//                   <Typography fontSize="1.8vw" fontFamily="marcellus">
//                     View History Of
//                   </Typography>
//                   <Typography fontSize="1.8vw" fontFamily="marcellus">
//                     Joined Exhibitions
//                   </Typography>
//                 </div>
//               </div>
//             </Grid>
//             <Grid item xs={12} sm={9} md={9}>
//               <Grid container>
//                 {historyCard.map((item) => (
//                   <Grid item xs={12} sm={6} md={6}>
//                     <div className={styles.historycard__main}>
//                       <img
//                         alt="img"
//                         className={styles.historycard__image}
//                         src={item.imgUrl}
//                       />
//                       <Typography
//                         fontFamily="marcellus"
//                         fontSize="1.1vw"
//                         mt={0.5}
//                         ml={1}
//                       >
//                         The Life And Death Of Charles
//                       </Typography>
//                       <Typography fontSize="0.875vw" mt={1} ml={1}>
//                         By Phin Jennings | 07 Mar 2022
//                       </Typography>
//                     </div>
//                   </Grid>
//                 ))}
//               </Grid>
//             </Grid>
//           </Grid>
//         </div>
//       </div>
//     </ThemeProvider>
//   );
// }

// export default ExhibitionUser;

import { ThemeProvider } from "@emotion/react";
import {Grid, Typography, Container } from "@mui/material";
import { createTheme } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./ExhibitionUser.module.css";
import api from "../../../State/api/Api";
import { setLoader } from "../../../State/Features/common/loaderSlice";
import { useDispatch, useSelector } from "react-redux";
import serverimage from "../../../serverConstant";
import { setActiveStatus } from "../../../State/Features/user/activetabSlice";
import { fetchExshibitionAdmin } from "../../../State/api/superadminApi";
import { FiMapPin, FiCalendar, FiImage, FiUser } from "react-icons/fi";
import dateFormat from "../../../utils/dateFormat";

const theme = createTheme({
  typography: {
    h4: {
      fontFamily: "Marcellus",
      fontSize: "2vw",
    },
    h6: {
      fontFamily: "Marcellus",
      fontSize: "1vw",
    },
  },
});

const ExhibitonContent = [
  {
    id: 1,
    imgUrl1: "/img/Rectangle 5259.png",
    imgUrl2: "/img/Rectangle 5260.png",
  },
  {
    id: 2,
    imgUrl1: "/img/Rectangle 5261.png",
    imgUrl2: "/img/Rectangle 5262.png",
  },
];

const historyCard = [
  {
    id: 1,
    imgUrl: "/img/Rectangle 5195.png",
  },
  {
    id: 2,
    imgUrl: "/img/Rectangle 5196.png",
  },
];

function ExhibitionUser() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState({is_accepted: ""});
   const exhibitiondt = useSelector(
    (state) => state.exhibitionAdminData?.data
  )
  console.log(exhibitiondt,'exhibitiondt')
  const [exhibitionData, setExhibitionData] = useState([]);

  const navigateToExhibitionDetail = (id) => {
    navigate(`/user/exhibition/${id}`);
  };

  const handleNavigate = (route) => {
    navigate(route);
  };

  const fetchExhibitions = async () => {
    dispatch(setLoader(true));
    try {
      const result = await api.applicationApi("api/user/getexhibits");
      if (result && result.status === 200) {
        setExhibitionData(result.data.responses);
      } else if (result.status === 401) {
        // Handle unauthorized access if necessary
      }
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(setLoader(false));
    }
  };

  useEffect(() => {
    dispatch(fetchExshibitionAdmin(data));
    dispatch(setActiveStatus({ status: "current exhibitions", maintab: "exhibition" }));
  }, []);

  const auctionStatus = useSelector((state) => state.activetab);
 
  const handleAuctionStatus = (status) => {
    dispatch(setActiveStatus({ status, maintab: "exhibition" }));
    
  };

  const filteredExhibitions = exhibitiondt?.data?.filter((val) => {
    const currentDate = new Date();
    const startDate = new Date(val.start_date); 
    const endDate = new Date(val.end_date);
  
    return (
      // Upcoming exhibitions
      (auctionStatus.status === "upcoming" && startDate > currentDate) ||
      // Past exhibitions
      (auctionStatus.status === "past" && endDate < currentDate) ||
      // Current exhibitions
      (auctionStatus.status === "current exhibitions" &&
        startDate <= currentDate &&
        endDate >= currentDate)
    ) 
  });
  
  

  return (
    <ThemeProvider theme={theme}>
      <div className={styles.exhibition}>
        <div className={styles.headerCategory}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
            <div className={styles.pageitle}>
              <h1>Exhibitions</h1>
              <ul>
                <li><Link onClick={() => handleNavigate("/")}>Home /{" "} </Link></li>{" "}
                <li><Link onClick={() => handleNavigate("/user/")}>Events /{" "} </Link></li>
                <li>Exhibition</li>
              </ul>
            </div>
          </Container>
        </div>
        <section className={styles.sec_sc}>
          <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
          <div className={styles.link__div}>
          <ul>
          {["current exhibitions", "past", "upcoming"].map(
              (status, index) => (
                <React.Fragment key={status}>
                  <li>
                    <Link
                      style={{
                        textDecoration: "none",
                        color: auctionStatus.status === status ? "black" : "#636365",
                        fontWeight: auctionStatus.status === status ? "500" : "400",
                      }}
                      onClick={() => handleAuctionStatus(status)}
                    >
                      {status.toUpperCase()}
                    </Link>
                  {/* {index < 2 && <div className={styles.verticalLine3}></div>}{" "} */}
                  {/* Add line only if it's not the last item */}</li>
                  
                  
                </React.Fragment>
              )
            )}
          </ul>
            
          </div>
          <div className={styles.exhibitioncards}>
            {filteredExhibitions === 0 ? (
                <label style={{ textAlign: "center", width: "100%" }}>
                  No Exhibition Found
                </label>
              ) : (
                filteredExhibitions?.map((item, i) => (
                  <div className={styles.lisbgm} key={item._id}>
                    <Grid container spacing={4} >
                     
                     {i % 2 === 0 && (
                       <Grid item xs={12} sm={6} md={6}>
                         <div className={styles.imgrc}>
                         <img
                         alt="img"
                          className={styles.imgalign}
                         src={ "/img/exhibition-image.jpg"}
                       />
                         </div>
                         
                       </Grid>
                       
                     )}
                     <Grid item xs={12} sm={6} md={6}>
                     <div
                       className={
                       
                           styles.cardInfo__right
                       }
                     >
                       <img
                         alt="img"
                         className={styles.designImage}
                         src={"/img/Design.png"}
                       />
                       <h3 className={styles.prthgd} onClick={() => navigateToExhibitionDetail(item._id)}>{item.title}</h3>
                       <p className={styles.prta}>{item.concept}</p> 
                       <ul className={styles.exlist}>
                         <li><FiMapPin className={styles.icns}/> {item.venue_address} | {item.city}</li>
                         <li><FiCalendar className={styles.icns}/> {dateFormat(item.start_date)} - {dateFormat(item.end_date)}</li>
                         <li><FiImage className={styles.icns}/> <span>{item.item_ids?.length}</span> <span>{item?.auction_art_details?.length || 0}</span> Art Showcase</li>
                         <li><FiUser className={styles.icns}/> <span>{item.artist_ids.length}</span> Artists</li>
                       </ul>
                       <button className={styles.apply_btn} onClick={() => navigate(`/user/exhibitionArts/${item?._id}`)}>
                         Interested
                       </button>
                       <p className={styles.byathr}> By {item.chief_guest} | {dateFormat(item.updatedAt)}</p>
                     </div>
                     </Grid>
                     {i % 2 !== 0 && (
                       <Grid item xs={12} sm={6} md={6}>
                         <div className={styles.imgrc}>
                         <img
                       alt="img"
                       className="img-fluid"
                       src={ "/img/exhibition-image.jpg"}
                     />
                         </div>
                       </Grid>
                     )}
                 </Grid>
                  </div>
                ))
              )}
          </div>
          </Container>
        </section>
        <div className={styles.history__exhibitions}>
          <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
          <Grid container spacing={2} sx={{alignItems:"center"}}>
            <Grid item xs={12} sm={3} md={3}>
            <div className={styles.price_explore}>
            <div>
              <img
                src={"/img/Design.png"}
                height="21.49px"
                width="78.3px"
                alt=""
              />
            </div>
            <h3>View History Of Joined Exhibitions</h3>
          </div>
            </Grid>
            <Grid item xs={12} sm={9} md={9}>
              <Grid container spacing={2}>
                {historyCard.map((item) => (
                  <Grid item xs={12} sm={6} md={6}>
                    <div className={styles.historycard__main}>
                      <img
                        alt="img"
                        className={styles.historycard__image}
                        src={item.imgUrl}
                      />
                      <div className={styles.textvh}>
                        <h4>The Life And Death Of Charles</h4>
                        <p>By Phin Jennings | 07 Mar 2022</p>
                      </div> 
                    </div>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
          </Container>
          
        </div>
      </div>
    </ThemeProvider>
  );
}

export default ExhibitionUser;
