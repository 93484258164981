import React, { useCallback, useEffect } from "react";

import AllCategory from "./components/Pages/User/AllCategory";
import TrendingCategory from "./components/Pages/User/TrendingCategory";
import Artworks from "./components/Pages/User/Artworks";
import ProductDetails from "./components/Pages/User/ProductDetails";
import TandC from "./components/Pages/User/TandC";
import PrivacyPolicy from "./components/Pages/User/PrivacyPolicy";
import ShippingReturnDets from "./components/Pages/User/ShippingReturnDets";
import PrintPage from "./components/Pages/User/PrintPage";
import AskAQuestion from "./components/Pages/User/AskAQuestion";
import RentDetails from "./components/Pages/User/RentDetails";
import Search from "./components/Pages/User/Search";
import ArtistList from "./components/Pages/User/ArtistList";
import ArtistDetails from "./components/Pages/User/ArtistDetails";
import ContactUs from "./components/Pages/User/ContactUs";
import FAQ from "./components/Pages/User/FAQ";
import CareerUser from "./components/Pages/User/CareerUser";
import CareerUserDetails from "./components/Pages/User/CareerUserDetails";
import PressReleases from "./components/Pages/User/PressReleases";
import PressReleasesDetails from "./components/Pages/User/PressReleasesDetails";
import ExhibitionDetails from "./components/Pages/User/ExhibitionDetails";
import ExhibitionUser from "./components/Pages/User/ExhibitionUser";
import UserAuction from "./components/Pages/User/UserAuction";
import Offers from "./components/Pages/User/Offers";
import AuctionDetails from "./components/Pages/User/AuctionDetails";
import GalleryDetails from "./components/Pages/User/GalleryDetails";
import Catelog from "./components/Pages/User/Catelog";
import WishList from "./components/Pages/User/WishList";
import MyCart from "./components/Pages/User/MyCart";
import CheckOut from "./components/Pages/User/CheckOut";
import CuratorCircle from "./components/Pages/User/CuratorCircle";
import Aucations from "./components/Pages/User/Aucations";
import AucationsDetails from "./components/Pages/User/AucationsDetails";

import PastAucations from "./components/Pages/User/PastAucation";
import UpcomingAucations from "./components/Pages/User/UpcomingAucations";
import PastUserAuction from "./components/Pages/User/PastUserAuction";
import AboutUs from "./components/Pages/User/AboutUs";
import GiftCardUser from "./components/Pages/User/GiftCardUser";
import GiftCardOrder from "./components/Pages/User/GiftCardOrder";
import SearchResult from "./components/Pages/User/SearchResult";
import AuctionDetailsOther from "./components/Pages/User/AuctionDetailsOther";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  IndexRoute,
} from "react-router-dom";
import Home from "./common/home/Home";
import BlogDetails from "./components/Pages/User/blogDetails";
import BlogDetailsPage from "./components/Pages/User/BlogDetailsPage";
import Login from "./components/Login/Login";
import Signup from "./components/Signup/Signup";
import Homepage from "./components/Pages/User/Homepage";
import { useDispatch, useSelector } from "react-redux";
import { fetchFetchAllcart, handelFetchWishlist } from "./State/api/userApi";
import Varifycode from "./components/Vrification/Varifycode";
// import {redirect} from "react-router-dom";
import MyAccount from "./components/Pages/User/User Profile/userAccount/MyAccount";
import EditUserProfile from "./components/Pages/User/User Profile/userAccount/editProfile/EditUserProfile";
import Artistnews from "./artist/pages/news/Artistnews";
import UserInquiry from "./components/Pages/User/User Profile/Inquiry/UserInquiry";
import FollowedArtist from "./components/Pages/User/User Profile/followed artist/FollowedArtist";
import Giftcard from "./components/Pages/User/User Profile/Giftcard/Giftcard";
import UserExhibition from "./components/Pages/User/User Profile/Exhibition/UserExhibition";
import UserAuctions from "./components/Pages/User/User Profile/Auction/UserAuctions";
import UserDispute from "./components/Pages/User/User Profile/dispute/UserDispute";
import UserAddress from "./components/Pages/User/User Profile/address/UserAddress";
import EditUserAddress from "./components/Pages/User/User Profile/address/editAddress/EditUserAddress";
import UserOrder from "./components/Pages/User/User Profile/order/UserOrder";
import Pagenotfound from "./components/pagenotfound/Pagenotfound";
import RequestMade from "./components/Pages/User/User Profile/requestMade/RequestMade";
import QouteRequests from "./components/Pages/User/User Profile/qouterequest/QouteRequests";
import { useState } from "react";
import api from "./State/api/Api";
import { setBtnLoader } from "./State/Features/common/loaderSlice";
import Emailverify from "./components/verifyPage/Emailverify";

import reactRouterToArray from "react-router-to-array";
import ResendMail from "./components/Pages/User/resendmail/Resendmail";
import Forgot from "./components/Forgot/Forgot";
import Fillquestionaffliate from "./affiliate/page/apply/applyQuestions/questions/Fillquestionsaffliate";
import Fillquestionartist from "./artist/pages/apply/fillQuestion/Fillquestion";
import Applyformartist from "./artist/pages/apply/applyForm/Applyformartist";
import Personaldetail from "./artist/pages/apply/personalDetail/Personaldetailartist";
import Questionhomeartist from "./artist/pages/apply/quationHome/Quationhomeartist";
import Affiliateapplyform from "./affiliate/page/apply/form/Affiliateapplyform";
import Applysecondaffileate from "./affiliate/page/apply/form/applySecond/Applysecondaffileate";
import Questionhomeaffliate from "./affiliate/page/apply/applyQuestions/questionHome/Questionhome";
import Questionhome from "./gallery/pages/apply/fillQuestions/questionHome/Questionhome";
import Fillquestiongallery from "./gallery/pages/apply/fillQuestions/fillQuestions/Fillquestiongallery";
import Galleryapplyform from "./gallery/pages/apply/from/Galleryapplyform";
import Applyform from "./curator/pages/applyForm/Applyform";
import Fillquestion from "./curator/pages/applyForm/questions/Fillquestion";
import Applyhome from "./curator/pages/applyForm/applyHome/Applyhome";
import LoginSuperAdmin from "./components/Login/LoginSuperAdmin";
import HomeCurator from "./common/home/HomeCurator";
import HomeGallery from "./common/home/HomeGallery";
import HomeAffiliate from "./common/home/HomeAffiliate";
import Formsubmission from "./gallery/components/popups/formSubmit/Formsubmission";
import PersonaldetailGallery from "./artist/pages/apply/personalDetail/PersonalDetailGallery";
import UserOrderDetails from "./components/Pages/User/User Profile/order/UserOrderDetails";
import RentPage from "./components/Pages/User/RentPage";
import CompletedOrder from "./components/Pages/User/CompletedOrder";
import BuyerOffers from "./components/Pages/User/User Profile/Giftcard/Offers";
import MyCartSubscription from "./components/Pages/User/MyCartForSubscription";
import CompleteOrderSubscription from "./components/Pages/User/CompleteOrderSubscription";
import BuyerDisputeDetails from "./artist/pages/order/details/BuyerDisputeDetails";
import ExhibitionArts from "./components/Pages/User/ExhibitionArts";
import PastUserExhibition from "./components/Pages/User/PastUserExhibition";
import PastAuctions from "./components/Pages/User/PastAucation";
import Gallerynews from "./gallery/pages/news/Gallerynews";
import ArtistManual from "./components/Pages/User/ArtistManual";
import GalleryManual from "./components/Pages/User/GalleryManual";
import AffilateManual from "./components/Pages/User/AffiliateManual";
import CuratorManual from "./components/Pages/User/CuratorManual";
import { Errornotify } from "./common/alert/Alert";
import CommisionedArt from "./components/Pages/User/CommisionedArt";
import ArtRental from "./components/Pages/User/ArtRental";
import BuyerManual from "./components/Pages/User/BuyerManual";
import TradeProgram from "./components/Pages/User/ArtConsultancy/TradeProgramApplication";
import ArtInteriorDesigners from "./components/Pages/User/ArtConsultancy/ArtInteriorDesigners";
import ArtSmileyBusiness from "./components/Pages/User/ArtConsultancy/ArtSmileyBusiness";
import ArtConsultancy from "./components/Pages/User/ArtConsultancy/ArtConsultancy";
import Questionnaire from "./components/Pages/User/ArtConsultancy/Questionnaire";
import MeetOurCurators from "./components/Pages/User/ArtConsultancy/MeetOurCurators";
import ShippingPolicy from "./components/Pages/User/ShippingPolicy";
//  import ReturnPolicy from "./components/Pages/User/ReturnPolicy";

function UserMain() {
  const usertype = useSelector((state) => state.user.data);
  // const mac_Address = useSelector((state) => state.macAddress.address);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    usertype.signed === true &&
      dispatch(
        fetchFetchAllcart({
          offset: 1,
          user_id: localStorage.getItem("user_id"),
        })
      );
    dispatch(handelFetchWishlist({ offset: 1 }));
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [usertype.signed === true]);

  useEffect(() => {
    dispatch(setBtnLoader(false));
  }, [location.pathname]);

  useEffect(() => {
    // Check if an ID exists in localStorage
    let deviceId = localStorage.getItem("device_id");

    if (!deviceId) {
      // If no ID exists, generate a new one
      deviceId = generateDeviceId();
      dispatch(
        fetchFetchAllcart({
          offset: 1,
          user_id: localStorage.getItem("user_id"),
        })
      );
      localStorage.setItem("device_id", deviceId);
    }
  }, []);

  const generateDeviceId = () => {
    // Generate a random string or use any other logic to create a unique identifier
    const randomId = Math.random().toString(36).substr(2, 10);
    return `user_device_art_cart${randomId}`;
  };

  const [apply, setApply] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    is_featured: false,
    ID_proof: "",
    social_links: {
      instagram: "",
      facebook: "",
      twitter: "",
      linked_in: "",
      youtube: "",
    },
    degree_year: 2022,
    assigned_to: "No",
    portfolio: "",
    country: "",
    nationality: "",
    language: "",
    image: "",
    backgroundImage: "",
    is_fulltime_artist: "",
    art_work: [],
    exp: "",
    art_price_range: [],
    arts_solds_in_past_12_months: "",
    produce_art_each_month: "",
    promoting_by_reason: [],
    online_art_gallary_URL: "",
    // artist_type_id: "",
    CV: "",
    // qualification: "",
    // subsciption_plan_id: "{}",
    // biography: "",
    // exhibition_id: [{ id: "" }],
    // auction_id: [{ _id: "" }],
    award_prize: "",
    about: "",

    professional_details: "",
    is_another_art_galary_representation: "No",
    // exhibition_part_count: 0,

    completion: "",
    exclusive_work: "",
    category_id: [],
    artist_type_id: "",
    bank_name: "",
    account_holder_name: "",
    routing_ABA_number: "",
    account_number: "",
    bank_location: "",
    account_type: "",
    bank_address: "",
    swift_code: "",
  });
  console.log(apply, "apply");
  const handleChangeSocialLinks = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "instagram") {
      setApply({
        ...apply,
        social_links: { ...apply.social_links, instagram: value },
      });
    } else if (name === "twitter") {
      setApply({
        ...apply,
        social_links: { ...apply.social_links, twitter: value },
      });
    } else if (name === "linked_in") {
      setApply({
        ...apply,
        social_links: { ...apply.social_links, linked_in: value },
      });
    } else if (name === "youtube") {
      setApply({
        ...apply,
        social_links: { ...apply.social_links, youtube: value },
      });
    } else {
      setApply({
        ...apply,
        social_links: { ...apply.social_links, facebook: value },
      });
    }
  };
  const handleSelectCheckbox = (e) => {
    const name = e.target.name;
    const vl = e.target.value;
    // if (name === "art_price_range") {
    //  const a = vl.forEach((element) => {
    //   var art_price_range = [...apply.art_price_range];
    //   const dt = apply.art_price_range.map((val) => val._id);
    //   const index = dt.indexOf(element);

    //   if (index === -1) {
    //    art_price_range.push({ _id: element });
    //    setApply({ ...apply, art_price_range: art_price_range });
    //
    //   } else {
    //    art_price_range.slice(index, 1);
    //    setApply({ ...apply, art_price_range: art_price_range });

    //   }
    //  });
    // } else if (name === "category_id") {

    //  const a = vl.forEach((element) => {
    //   var category_id = [...apply.category_id];
    //   const dt = apply.category_id.map((val) => val._id);
    //   const index = dt.indexOf(element);

    //   if (index === -1) {
    //     category_id.push({ _id: element });
    //    setApply({ ...apply, category_id: category_id });
    //
    //   } else {
    //     category_id.slice(index, 1);
    //    setApply({ ...apply, category_id: category_id });

    //   }
    //  });
    // }
    if (name === "category_id") {
      const dt = apply.category_id && apply.category_id.map((val) => val._id);
      const index = dt.indexOf(vl);
      const category_id = [...apply.category_id];

      if (index === -1) {
        // Add the category if it's not already in the list
        category_id.push({ _id: vl });
      } else {
        // Remove the category from the list using splice
        category_id.splice(index, 1);
      }

      // Update the state with the new category_id array
      setApply({ ...apply, category_id });
    } else if (name === "art_price_range") {
      const index = apply.art_price_range.indexOf(vl);
      const art_price_range = [...apply.art_price_range];

      if (index === -1) {
        // Add the value if it's not already in the array
        art_price_range.push(vl);
      } else {
        // Remove the value from the array using splice
        art_price_range.splice(index, 1);
      }

      // Update the state with the new art_price_range array
      setApply({ ...apply, art_price_range });
    } else if (name === "promoting_by_reason") {
      const index = apply.promoting_by_reason.indexOf(vl);
      const promoting_by_reason = [...apply.promoting_by_reason];

      if (index === -1) {
        promoting_by_reason.push(vl);
      } else {
        promoting_by_reason.splice(index, 1); // Use splice instead of slice to remove the item
      }

      setApply({ ...apply, promoting_by_reason: promoting_by_reason });
    }
  };
  const handleChangeApplyData = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    // Fields that should accept only numbers and are limited to 5 characters
    if (
      [
        "arts_solds_in_past_12_months",
        "exp",
        "produce_art_each_month",
      ].includes(name)
    ) {
      if (/^\d*$/.test(value)) {
        // Allow only numeric input
        if (value.length > 5) {
          value = value.slice(0, 5); // Limit to 5 characters
        }
        setApply({ ...apply, [name]: value });
      }
    } else if (name === "first_name" || name === "last_name") {
      // Allow only alphabetic characters
      if (/^[a-zA-Z]*$/.test(value)) {
        setApply({ ...apply, [name]: value });
      }
    } else if (name === "exclusive_work") {
      setApply({ ...apply, exclusive_work: value });
    } else if (name === "completion") {
      setApply({ ...apply, completion: value });
    } else if (name === "is_another_art_galary_representationelse") {
      setApply({
        ...apply,
        is_another_art_galary_representationelse: Number(value),
      });
    } else if (name === "is_fulltime_artist") {
      setApply({ ...apply, is_fulltime_artist: value });
    } else {
      setApply({ ...apply, [name]: value });
    }
  };

  // affiliate urls
  const urls = [
    "/artsmiley/applyform",
    "/artsmiley/applyformsecond",
    "/artsmiley/fillquestion",
    "/artsmiley/applynow",
    "/home",
    "/dashboard",
    "/earnings",
    "/earnings/:id",
    "/myprofile",
  ];

  const [applyAffiliate, setApplyAffiliate] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password: "",
    description: [],
    traffic_drivation: [],
    affiliate_account_type_id: "",
    affiliate_user_name: "",
    app_url: "",
    audience_interest: "",
    build_links: [],
    company_name: "",
    country: "",
    generate_income: "",
    hear_about_us: "",
    language: "",
    phone: "",
    primary_reason_to_join: "",
    completion: "",
    promote_art_smiley: [],
    otherPromotionMethod: "",
    otherSocialMethod: "",
    skype_id: "",
    unique_visitor_count: "",
    website_about: "",
    website_url: "",
    description_others: "",
    traffic_drivation_others: "",
    strategy_influence_marketing: "",
    platform_influence_marketing: "",
    other_social_influence:"",
    
   
  });
  console.log(applyAffiliate,'affiliateapply')
  const handleChangeSocialLinksAffiliate = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "instagram") {
      setApplyAffiliate({
        ...applyAffiliate,
        social_links: { ...applyAffiliate.social_links, instagram: value },
      });
    } else if (name === "twitter") {
      setApplyAffiliate({
        ...applyAffiliate,
        social_links: { ...applyAffiliate.social_links, twitter: value },
      });
    } else if (name === "linked_in") {
      setApplyAffiliate({
        ...applyAffiliate,
        social_links: { ...applyAffiliate.social_links, linked_in: value },
      });
    } else {
      setApplyAffiliate({
        ...applyAffiliate,
        social_links: { ...applyAffiliate.social_links, facebook: value },
      });
    }
  };

  const handleChangeApplyDataAffiliate = (e) => {
    const name = e.target.name;
    let value = e.target.value;
  
    // Conditional checks for specific fields
    if (name === "first_name" || name === "last_name") {
      if (/^[a-zA-Z]*$/.test(value)) {
        setApplyAffiliate({ ...applyAffiliate, [name]: value });
      }
    } else if (name === "unique_visitor_count") {
      // Ensure the value is a number and has at most 5 digits
      value = value.slice(0, 5);
      setApplyAffiliate({ ...applyAffiliate, [name]: value });
    } 
    else if (name === "affiliate_account_type_id") {
    
      setApplyAffiliate((prev) => ({
        ...prev,
        [name]: value,
        company_name: value === "66307a1362041dbbcad0609b" ? "" : prev.company_name,
      }));
    } 
    
    else {
      setApplyAffiliate({ ...applyAffiliate, [name]: value });
    }
  };
  
  const handleSelectCheckboxAffiliate = (e) => {
    const name = e.target.name;
    const vl = e.target.value;
    // if (name === "art_price_range") {
    //  const a = vl.forEach((element) => {
    //   var art_price_range = [...apply.art_price_range];
    //   const dt = apply.art_price_range.map((val) => val._id);
    //   const index = dt.indexOf(element);

    //   if (index === -1) {
    //    art_price_range.push({ _id: element });
    //    setApply({ ...apply, art_price_range: art_price_range });
    //
    //   } else {
    //    art_price_range.slice(index, 1);
    //    setApply({ ...apply, art_price_range: art_price_range });

    //   }
    //  });
    // } else if (name === "category_id") {

    //  const a = vl.forEach((element) => {
    //   var category_id = [...apply.category_id];
    //   const dt = apply.category_id.map((val) => val._id);
    //   const index = dt.indexOf(element);

    //   if (index === -1) {
    //     category_id.push({ _id: element });
    //    setApply({ ...apply, category_id: category_id });
    //
    //   } else {
    //     category_id.slice(index, 1);
    //    setApply({ ...apply, category_id: category_id });

    //   }
    //  });
    // }
    if (name === "category_id") {
      const dt =
        applyAffiliate.category_id &&
        applyAffiliate.category_id.map((val) => val._id);
      const index = dt.indexOf(vl);
      const category_id = [...applyAffiliate.category_id];
      if (index === -1) {
        category_id.push({ _id: vl });
        setApplyAffiliate({ ...applyAffiliate, category_id: category_id });
      } else {
        category_id.slice(index, 1);
        setApplyAffiliate({ ...applyAffiliate, category_id: category_id });
      }
    } else if (name === "art_price_range") {
      // const dt = apply.art_price_range && apply.art_price_range.map((val) => val._id);
      const index = applyAffiliate.art_price_range.indexOf(vl);
      const art_price_range = [...applyAffiliate.art_price_range];
      if (index === -1) {
        art_price_range.push(vl);
        setApplyAffiliate({
          ...applyAffiliate,
          art_price_range: art_price_range,
        });
      } else {
        art_price_range.slice(index, 1);
        setApplyAffiliate({
          ...applyAffiliate,
          art_price_range: art_price_range,
        });
      }
    } else if (name === "promoting_by_reason") {
      // const dt = apply.art_price_range && apply.art_price_range.map((val) => val._id);
      const index = applyAffiliate.promoting_by_reason.indexOf(vl);
      const promoting_by_reason = [...applyAffiliate.promoting_by_reason];
      if (index === -1) {
        promoting_by_reason.push(vl);
        setApplyAffiliate({
          ...applyAffiliate,
          promoting_by_reason: promoting_by_reason,
        });
      } else {
        promoting_by_reason.slice(index, 1);
        setApplyAffiliate({
          ...applyAffiliate,
          promoting_by_reason: promoting_by_reason,
        });
      }
    }
  };

  const [applyGallery, setApplyGallery] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    title: "",
    gallery_name: "",
    country: "",
    gallery_address: "",
    gallery_established: "",
    language: "",
    registration_document: "",
    profile_image: "",
    cover_image: "",
    social_links: {
      instagram: "",
      facebook: "",
      twitter: "",
      linked_in: "",
      youtube: "",
    },
    description: "",
    degree_year: "",
    portfolio_file: "",
    portfolio: "",
    award: "",

    image: "",
    backgroundImage: "",
    best_work: [],
    exp: "",
    art_price_range: [],
    arts_solds_in_past_12_months: "",
    other_URL: "",
    completion: "",
    category_id: [],
    bank_name: "",
    account_holder_name: "",
    routing_ABA_number: "",
    account_number: "",
    bank_location: "",
    account_type: "",
    bank_address: "",
    swift_code: "",
  });
  console.log(applyGallery,'applyGallery')
  const handleSelectCheckboxGallery = (e) => {
    const name = e.target.name;
    const vl = e.target.value;
    // if (name === "art_price_range") {
    //  const a = vl.forEach((element) => {
    //   var art_price_range = [...apply.art_price_range];
    //   const dt = apply.art_price_range.map((val) => val._id);
    //   const index = dt.indexOf(element);

    //   if (index === -1) {
    //    art_price_range.push({ _id: element });
    //    setApply({ ...apply, art_price_range: art_price_range });
    //
    //   } else {
    //    art_price_range.slice(index, 1);
    //    setApply({ ...apply, art_price_range: art_price_range });

    //   }
    //  });
    // } else if (name === "category_id") {

    //  const a = vl.forEach((element) => {
    //   var category_id = [...apply.category_id];
    //   const dt = apply.category_id.map((val) => val._id);
    //   const index = dt.indexOf(element);

    //   if (index === -1) {
    //     category_id.push({ _id: element });
    //    setApply({ ...apply, category_id: category_id });
    //
    //   } else {
    //     category_id.slice(index, 1);
    //    setApply({ ...apply, category_id: category_id });

    //   }
    //  });
    // }
    if (name === "category_id") {
      const dt =
        applyGallery.category_id &&
        applyGallery.category_id.map((val) => val._id);
      const index = dt.indexOf(vl);
      const category_id = [...applyGallery.category_id];

      if (index === -1) {
        // Add the category if it's not already in the list
        category_id.push({ _id: vl });
      } else {
        // Remove the category from the list using splice
        category_id.splice(index, 1);
      }

      // Update the state with the new category_id array
      setApplyGallery({ ...applyGallery, category_id });
    } else if (name === "art_price_range") {
      const index = applyGallery.art_price_range.indexOf(vl);
      const art_price_range = [...applyGallery.art_price_range];

      if (index === -1) {
        // Add the value if it's not already in the array
        art_price_range.push(vl);
      } else {
        // Remove the value from the array using splice
        art_price_range.splice(index, 1);
      }

      // Update the state with the new art_price_range array
      setApplyGallery({ ...applyGallery, art_price_range });
    } else if (name === "promoting_by_reason") {
      const index = applyGallery.promoting_by_reason.indexOf(vl);
      const promoting_by_reason = [...applyGallery.promoting_by_reason];

      if (index === -1) {
        promoting_by_reason.push(vl);
      } else {
        promoting_by_reason.splice(index, 1); // Use splice instead of slice to remove the item
      }

      setApplyGallery({
        ...applyGallery,
        promoting_by_reason: promoting_by_reason,
      });
    }
  };

  const handleChangeApplyDataGallery = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    // Check for `arts_solds_in_past_12_months` and limit input to 5 characters
    if (name === "arts_solds_in_past_12_months" && value.length > 5) {
      value = value.slice(0, 5); // Truncate value to 5 characters if it exceeds the limit
    }

    // Allow only alphabetic characters for `first_name` and `last_name`
    if (name === "first_name" || name === "last_name") {
      value = value.replace(/[^a-zA-Z\s]/g, ""); // Remove non-alphabetic characters
    }
     if (name === "completion") {
      setApplyGallery({ ...applyGallery, completion: value });
    }

    setApplyGallery({ ...applyGallery, [name]: value });
  };

  const handleChangeSocialLinksGallery = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "instagram") {
      setApplyGallery({
        ...applyGallery,
        social_links: { ...applyGallery.social_links, instagram: value },
      });
    } else if (name === "twitter") {
      setApplyGallery({
        ...applyGallery,
        social_links: { ...applyGallery.social_links, twitter: value },
      });
    } else if (name === "linked_in") {
      setApplyGallery({
        ...applyGallery,
        social_links: { ...applyGallery.social_links, linked_in: value },
      });
    } else if (name === "youtube") {
      setApplyGallery({
        ...applyGallery,
        social_links: { ...applyGallery.social_links, youtube: value },
      });
    } else {
      setApplyGallery({
        ...applyGallery,
        social_links: { ...applyGallery.social_links, facebook: value },
      });
    }
  };

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFilesGallery, setSelectedFilesGallery] = useState([]);
  // curator all code here
  const [srcCurator, setSrcCurator] = useState({
    cv: "",
    id_proof: "",
  });
  const [src, setSrc] = useState({
    cv: "",
    id_proof: "",
    art_work: "",
  });

  const [achievement, setAchivement] = useState("");
  const [applyCurator, setApplyCurator] = useState({
    first_name: "",
    last_name: "",
    curator_email: "",
    password: "",
    phone: "",
    ID_proof: "",
    profile_image: "",
    cover_image: "",
    CV: "",
    country: "",
    nationality: "",
    qualification: "",
    achievement: "",
    shows_curated: "",
    completion: ""
  });

  const handleChangeApplyDataCurator = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    // Regular expression to allow only alphabets (uppercase and lowercase)
    const onlyAlphabets = /^[A-Za-z]+$/;

    if (name === "first_name" || name === "last_name") {
      if (onlyAlphabets.test(value) || value === "") {
        setApplyCurator({ ...applyCurator, [name]: value });
      }
    } else {
      setApplyCurator({ ...applyCurator, [name]: value });
    }
  };

  const handleChangeShowsCurated = (fieldName) => (content) => {
    // Check if content is empty or just contains a single empty paragraph
    if (content === "<p><br></p>") {
      return; // Do not update the state if content is empty
    }

    setApplyCurator((prevState) => ({
      ...prevState,
      [fieldName]: content,
    }));
  };

  const handleChangeTextEditor = (fieldName) => (value) => {
    setApply((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const handleChangeTextEditorGallery = (fieldName) => (value) => {
    setApplyGallery((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };
  const handleChangeTextEditorAffiliate = (fieldName) => (value) => {
    setApplyAffiliate((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const handleChangeImageCurator = (e) => {
    const name = e.target.name;
    if (e.target.files && e.target.files[0]) {
      const img = e.target.files[0];
      const imgs = URL.createObjectURL(img);
      const fileSizeInKB = img.size / 1024;
  
      if (name === "CV") {
        if (
          img.type !== "application/pdf" &&
          img.type !== "application/msword" &&
          img.type !== "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ) {
          Errornotify("Please upload a file in PDF or Word document format");
          e.target.value = "";
        } else if (fileSizeInKB > 1024) {
          Errornotify("File size should be less than 1 MB");
          e.target.value = "";
        } else {
          setSrcCurator({ ...srcCurator, cv: imgs });
          setApplyCurator({ ...applyCurator, CV: img });
        }
      } else {
        // Check for PDF, JPEG, or PNG format for ID_proof
        if (
          img.type !== "application/pdf" &&
          img.type !== "image/jpeg" &&
          img.type !== "image/png"
        ) {
          Errornotify("Please upload a file in PDF, JPEG, or PNG format");
          e.target.value = "";
        } else if (fileSizeInKB > 1024) {
          Errornotify("File size should be less than 1mb");
          e.target.value = "";
        } else {
          setSrcCurator({ ...srcCurator, id_proof: imgs });
          setApplyCurator({ ...applyCurator, ID_proof: img });
        }
      }
    }
  };
  

  const handleChangeImage = (e) => {
    const name = e.target.name;

    if (e.target.files && e.target.files[0]) {
      const img = e.target.files[0];
      const imgs = URL.createObjectURL(e.target.files[0]);
      const fileSizeInKB = img.size / 1024;
      if (name === "CV") {
        if (
          ![
            "application/pdf",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          ].includes(img.type)
        ) {
          Errornotify("Please upload a file in pdf or word document doc");
          e.target.value = "";
        } else if (fileSizeInKB > 1024) {
          Errornotify("File size should be less than 1mb");
          e.target.value = "";
        } else {
          setSrc({ ...src, cv: imgs });
          setApply({ ...apply, CV: img });
        }
      } else {
        setSrc({ ...src, art_work: imgs });
        setApply({ ...apply, art_work: imgs });
      }
    }
  };

  const handleImageChangeMultipleFillArtist = useCallback(
    (e) => {
      if (e.target && e.target.files && e.target.files.length > 0) {
        const filesToAdd = e.target.files;
        const totalImages = selectedFiles.length + filesToAdd.length;
  
        // Limit the total images to 10
        if (totalImages > 10) {
          Errornotify("You can't upload more than 10 artworks.");
          return;
        }
  
        // Filter to allow only JPEG and PNG images under 1MB
        const validImageTypes = ["image/jpeg", "image/png"];
        const maxFileSize = 2 * 1024 * 1024; // 2MB in bytes
        const filteredFiles = Array.from(filesToAdd).filter(
          (file) => validImageTypes.includes(file.type) && file.size <= maxFileSize
        );
  
        if (filteredFiles.length < filesToAdd.length) {
          Errornotify("Image size should be less than 2MB");
        }
  
        // Convert files to object URLs for preview
        const filesArray = filteredFiles.map((file) => URL.createObjectURL(file));
        setSelectedFiles((prevImages) => prevImages.concat(filesArray));
  
        // Add files to `art_work` state
        const images = apply.art_work ? [...apply.art_work] : [];
        filteredFiles.forEach((file) => images.push(file));
  
        setApply({
          ...apply,
          art_work: images,
        });
      }
    },
    [apply, selectedFiles]
  );



  const handleImageChangeMultiple = useCallback(
    (e) => {
      if (e.target && e.target.files && e.target.files.length > 0) {
        const filesToAdd = e.target.files;
        const totalImages = selectedFiles.length + filesToAdd.length;
  
        // Limit the total images to 10
        if (totalImages > 10) {
          Errornotify("You can't upload more than 10 artworks.");
          return;
        }
  
        // Filter to allow only JPEG and PNG images under 1MB
        const validImageTypes = ["image/jpeg", "image/png"];
        const maxFileSize = 1 * 1024 * 1024; // 1MB in bytes
        const filteredFiles = Array.from(filesToAdd).filter(
          (file) => validImageTypes.includes(file.type) && file.size <= maxFileSize
        );
  
        if (filteredFiles.length < filesToAdd.length) {
          Errornotify("Image size should be less than 1MB");
        }
  
        // Convert files to object URLs for preview
        const filesArray = filteredFiles.map((file) => URL.createObjectURL(file));
        setSelectedFiles((prevImages) => prevImages.concat(filesArray));
  
        // Add files to `art_work` state
        const images = apply.art_work ? [...apply.art_work] : [];
        filteredFiles.forEach((file) => images.push(file));
  
        setApply({
          ...apply,
          art_work: images,
        });
      }
    },
    [apply, selectedFiles]
  );
  
  
  const handleImageChangeMultipleGallery = useCallback(
    (e) => {
      if (e.target && e.target.files && e.target.files.length > 0) {
        const filesToAdd = e.target.files;
        const totalImages = selectedFilesGallery.length + filesToAdd.length;
  
        // Limit the total images to 10
        if (totalImages > 10) {
          Errornotify("You can't upload more than 10 artworks.");
          return;
        }
  
        // Filter to allow only JPEG and PNG images under 1MB
        const validImageTypes = ["image/jpeg", "image/png"];
        const maxFileSize = 2 * 1024 * 1024; // 1MB in bytes
        const filteredFiles = Array.from(filesToAdd).filter(
          (file) => validImageTypes.includes(file.type) && file.size <= maxFileSize
        );
  
        if (filteredFiles.length < filesToAdd.length) {
          Errornotify("Image size should be less than 2MB.");
        }
  
        // Convert files to object URLs for preview and ensure uniqueness
        const filesArray = filteredFiles.map((file) => URL.createObjectURL(file));
        const uniqueFilesArray = Array.from(new Set([...selectedFilesGallery, ...filesArray]));
  
        // Update the preview state with unique files
        setSelectedFilesGallery(uniqueFilesArray);
  
        // Update the `applyGallery.best_work` state with new files
        setApplyGallery((prevApplyGallery) => ({
          ...prevApplyGallery,
          best_work: prevApplyGallery.best_work
            ? [...prevApplyGallery.best_work, ...filteredFiles]
            : filteredFiles,
        }));
      }
    },
    [applyGallery, selectedFilesGallery]
  );
  
  

  const handleRemoveImage = (index) => {
    setSelectedFiles((prevImages) => prevImages.filter((_, i) => i !== index));

    setApply((prevApply) => ({
      ...prevApply,
      art_work: prevApply.art_work.filter((_, i) => i !== index),
    }));
  };

  const handleRemoveImageGallery = (index) => {
    setSelectedFilesGallery((prevImages) => prevImages.filter((_, i) => i !== index));

    setApplyGallery((prevApply) => ({
      ...prevApply,
      best_work: prevApply.best_work.filter((_, i) => i !== index),
    }));
  };
  return (
    <Routes>
      {/* artist application form routes */}
      <Route
        path="/artsmiley/artist/fillquestion"
        element={
          <Fillquestionartist
            handleChangeSocialLinks={handleChangeSocialLinks}
            handleSelectCheckbox={handleSelectCheckbox}
            apply={apply}
            setApply={setApply}
            src={src}
            handleChangeApplyData={handleChangeApplyData}
            handleChangeImage={handleChangeImage}
            handleImageChangeMultiple={handleImageChangeMultipleFillArtist}
            handleRemoveImage={handleRemoveImage}
            selectedFiles={selectedFiles}
            handleChangeTextEditor={handleChangeTextEditor}
          />
        }
      />
      <Route
        path="/artsmiley/artist/applyform"
        element={
          <Applyformartist
            handleChangeSocialLinks={handleChangeSocialLinks}
            handleSelectCheckbox={handleSelectCheckbox}
            apply={apply}
            setApply={setApply}
            handleChangeApplyData={handleChangeApplyData}
            handleChangeImage={handleChangeImage}
            handleImageChangeMultiple={handleImageChangeMultipleFillArtist}
            handleRemoveImage={handleRemoveImage}
            selectedFiles={selectedFiles}
            handleChangeTextEditor={handleChangeTextEditor}
          />
        }
      />
      <Route
        path="/artsmiley/artist/personaldetail"
        element={
          <Personaldetail
            setApply={setApply}
            apply={apply}
            handleChangeApplyData={handleChangeApplyData}
          />
        }
      />
      <Route
        path="/artsmiley/artist/applynow"
        element={<Questionhomeartist />}
      />
      {/* application routes for artist ends here */}

      {/* application routes for affiliate */}
      <Route
        path="/artsmiley/affiliate/applyform"
        element={
          <Affiliateapplyform
            apply={applyAffiliate}
            setApply={setApplyAffiliate}
            handleChangeApplyData={handleChangeApplyDataAffiliate}
          />
        }
      />
      <Route
        path="/artsmiley/affiliate/applyformsecond"
        element={
          <Applysecondaffileate
            setApply={setApplyAffiliate}
            apply={applyAffiliate}
            handleChangeApplyData={handleChangeApplyDataAffiliate}
            handleChangeTextEditorAffiliate={handleChangeTextEditorAffiliate}
          />
        }
      />
      <Route
        path="/artsmiley/affiliate/fillquestion"
        element={
          <Fillquestionaffliate
            handleChangeSocialLinks={handleChangeSocialLinksAffiliate}
            handleSelectCheckbox={handleSelectCheckboxAffiliate}
            apply={applyAffiliate}
            setApply={setApplyAffiliate}
            handleChangeApplyData={handleChangeApplyDataAffiliate}
          />
        }
      />
      <Route
        path="/artsmiley/affiliate/applynow"
        element={<Questionhomeaffliate />}
      />
      {/* application routes for affiliate end here */}

      {/* gallery routes start from here */}
      <Route path="/artsmiley/gallery/applynow" element={<Questionhome />} />
      <Route
        path="/artsmiley/gallery/personaldetails"
        element={
          <PersonaldetailGallery
            setApply={setApplyGallery}
            apply={applyGallery}
            handleChangeApplyData={handleChangeApplyDataGallery}
          />
        }
      />
      <Route
        path="/artsmiley/gallery/fillquestion"
        element={
          <Fillquestiongallery
            handleChangeSocialLinks={handleChangeSocialLinksGallery}
            handleSelectCheckbox={handleSelectCheckboxGallery}
            apply={applyGallery}
            setApply={setApplyGallery}
            handleChangeApplyData={handleChangeApplyDataGallery}
            handleChangeTextEditor={handleChangeTextEditorGallery}
            handleImageChangeMultipleGallery={handleImageChangeMultipleGallery}
            handleRemoveImageGallery={handleRemoveImageGallery}
            selectedFilesGallery={selectedFilesGallery}
          />
        }
      />
      <Route
        path="/artsmiley/gallery/applyform"
        element={
          <Galleryapplyform
            apply={applyGallery}
            setApply={setApplyGallery}
            handleChangeCheckbox={handleSelectCheckboxGallery}
            handleChangeApplyData={handleChangeApplyDataGallery}
            handleChangeSocialLinksGallery={handleChangeSocialLinksGallery}
            handleChangeTextEditor={handleChangeTextEditorGallery}
            handleImageChangeMultipleGallery={handleImageChangeMultipleGallery}
            handleRemoveImageGallery={handleRemoveImageGallery}
            selectedFilesGallery={selectedFilesGallery}
          />
        }
      />
      {/* gallery routes ends here */}

      {/* curator routes start here */}
      <Route
        path="/artsmiley/curator/applyform"
        element={
          <Applyform
            apply={applyCurator}
            setApply={setApplyCurator}
            handleChangeApplyData={handleChangeApplyDataCurator}
            src={srcCurator}
            setSrc={setSrcCurator}
           
          />
        }
      />
      <Route path="/artsmiley/curator/applynow" element={<Applyhome />} />
      <Route
        path="/artsmiley/curator/fillquestion"
        element={
          <Fillquestion
            apply={applyCurator}
            setApply={setApplyCurator}
            handleChangeApplyData={handleChangeApplyDataCurator}
            handleChangeImage={handleChangeImageCurator}
            setAchivement={setAchivement}
            handleChangeShowsCurated={handleChangeShowsCurated}
          />
        }
      />
      {/* curator routes ends here */}
      <Route
        path="/artsmiley/artist/form-submission"
        element={<Formsubmission />}
      />
      <Route
        path="/artsmiley/curator/form-submission"
        element={<Formsubmission />}
      />
      <Route
        path="/artsmiley/affiliate/form-submission"
        element={<Formsubmission />}
      />
      <Route
        path="/artsmiley/gallery/form-submission"
        element={<Formsubmission />}
      />
      <Route path="/artsmiley/emailverify" element={<Emailverify />} />
      <Route path="/artsmiley/resendmail" element={<ResendMail />} />
      <Route path="/artsmiley/forgot" element={<Forgot />} />
      <Route path="/" element={<Homepage />} />
      <Route path="/user/artist-application" element={<Home />} />
      <Route path="/user/curator-application" element={<HomeCurator />} />
      <Route path="/user/gallery-application" element={<HomeGallery />} />
      <Route path="/user/affiliate-application" element={<HomeAffiliate />} />
      <Route path="/user/" element={<Homepage />} />
      <Route path="user/allcategory" element={<AllCategory />} />
      <Route path="user/trending" element={<TrendingCategory />} />
      {/* <Route path="user/artworks" element={<Artworks />} /> */}
      <Route path="user/print" element={<PrintPage />} />
      <Route path="user/askaquestion/:id" element={<AskAQuestion />} />
      <Route path="user/rent-details" element={<RentDetails />} />
      <Route path="user/search" element={<Search />} />
      {/* <Route path="user/search/filter" element={<Search />} /> */}
      <Route path="user/search/:option" element={<Search />} />
      <Route path="user/search-results" element={<SearchResult />} />
      <Route path="user/artist-list" element={<ArtistList />} />
      <Route path="user/artist-details" element={<ArtistDetails />} />
      <Route path="user/contactus" element={<ContactUs />} />
      <Route path="user/artist-manual" element={<ArtistManual />} /> 
      <Route path="user/gallery-manual" element={<GalleryManual />} />
      <Route path="user/buyer-manual" element={<BuyerManual />} />
      <Route path="user/affiliate-manual" element={<AffilateManual />} />
      <Route path="user/curator-manual" element={<CuratorManual />} />
      <Route path="user/commisioned-art" element={<CommisionedArt />} />
      <Route path="user/art-rentals" element={<ArtRental />} />
      <Route path="user/art-consultancy/trade-program-application" element={<TradeProgram />} />
      <Route path="user/art-consultancy/art-for-interior-designers" element={<ArtInteriorDesigners />} />
      <Route path="user/art-consultancy/art-smiley-for-business" element={<ArtSmileyBusiness />} />
      <Route path="user/art-consultancy/questionnaire" element={<Questionnaire />} />
      <Route path="user/art-consultancy" element={<ArtConsultancy />} />
      <Route path="user/art-consultancy/meet-our-curators" element={<MeetOurCurators />} />
      <Route path="user/faq" element={<FAQ />} />
      <Route path="user/career" element={<CareerUser />} />
      <Route path="user/blogPage" element={<BlogDetails />} />
      <Route path="/user/blog-details/:id" element={<BlogDetailsPage />} />
      {/* <Route path="user/career-user-details" element={<CareerUserDetails />} /> */}
      <Route path="user/pressrelease" element={<PressReleases />} />
      {/* <Route path="press-releases-details" element={<PressReleasesDetails />} /> */}
      <Route path="user/exhibition" element={<ExhibitionUser />} />
      <Route path="user/exhibition-details" element={<ExhibitionDetails />} />
      {/* <Route path="user/auctions" element={<Aucations />} /> */}
      <Route path="user/auctions-details" element={<AucationsDetails />} />

      <Route path="user/auction" element={<PastAuctions />} />
      {/* <Route path="user/upcoming-aucations" element={<UpcomingAucations />} /> */}
      {/* <Route path="user/auction" element={<UserAuction />} /> */}
      <Route path="user/auctionArt/:id" element={<PastUserAuction />} />
      <Route path="user/auction-details/:id" element={<AuctionDetails />} />
      <Route
        path="user/auction-details-other"
        element={<AuctionDetailsOther />}
      />
      <Route path="user/wishlist" element={<WishList />} />
      <Route path="user/mycart" element={<MyCart />} />
      <Route
        path="user/mycart-subscription/:id"
        element={<MyCartSubscription />}
      />
      <Route path="user/checkout" element={<CheckOut />} />
      <Route path="user/order-placed" element={<CompletedOrder />} />
      <Route
        path="user/subscription-placed"
        element={<CompleteOrderSubscription />}
      />
      <Route path="user/offers" element={<Offers />} />
      <Route path="user/exhibitionArts/:id" element={<PastUserExhibition />} />
      <Route path="user/termsconditions" element={<TandC />} />
      <Route path="user/privacypolicy" element={<PrivacyPolicy />} />
      <Route path="user/shipping-returns" element={<ShippingReturnDets />} />
      <Route path="user/shipping-policy" element={<ShippingPolicy />} />
      {/* <Route path="user/return-policy" element={<ReturnPolicy />} /> */}
      <Route path="user/giftcard" element={<GiftCardUser />} />
      <Route path="user/giftcard-order" element={<GiftCardOrder />} />
      <Route path="user/gallery-details" element={<GalleryDetails />} />
      <Route path="user/about-us" element={<AboutUs />} />
      <Route path="user/catelog" element={<Catelog />} />
      <Route path="user/curator-circle" element={<CuratorCircle />} />
      <Route path="/user/artist/:id" element={<ArtistDetails />} />
      <Route path="/user/career/:id" element={<CareerUserDetails />} />
      <Route
        path="/user/press-releases/:id"
        element={<PressReleasesDetails />}
      />
      <Route path="/user/auction/:id" element={<AuctionDetails />} />
      <Route path="/user/allcategory/:id" element={<Artworks />} />
      <Route path="/user/newestpaintings/:id" element={<ProductDetails />} />
      <Route path="/user/allcategory/:id/:id" element={<ProductDetails />} />
      <Route path="/user/recently_view/:id" element={<ProductDetails />} />
      <Route path="/user/mostpopular/:id" element={<ProductDetails />} />
      <Route path="/user/productdetails/:id" element={<ProductDetails />} />
      <Route path="/user/trending/:id" element={<ProductDetails />} />
      <Route path="/user/bestseller/:id" element={<ProductDetails />} />
      <Route path="/user/productdetail/:id" element={<ProductDetails />} />
      <Route path="/user/followed/:id" element={<ProductDetails />} />
      <Route path="/user/print/:id/:id" element={<PrintPage />} />
      <Route path="/user/rent/:id/:id" element={<RentPage />} />
      <Route path="/user/exhibition/:id" element={<ExhibitionDetails />} />
      <Route path="/user/giftcard/:id" element={<GiftCardOrder />} />

      <Route path="/verify" element={<Varifycode />} />
      <Route path="/userorder" element={<UserOrder />} />
      <Route path="/userorder/details/:id" element={<UserOrderDetails />} />
      <Route path="/dashboard" element={<MyAccount />} />
      {/* <Route path="/editprofile" element={<EditUserProfile />} /> */}
      <Route path="/news" element={<Gallerynews />} />
      <Route path="/userinquiry" element={<UserInquiry />} />
      <Route path="/followedartist" element={<FollowedArtist />} />
      <Route path="/giftcard" element={<Giftcard />} />
      <Route path="/campaigns" element={<BuyerOffers />} />
      <Route path="/exhibition" element={<UserExhibition />} />
      <Route path="/auction" element={<UserAuctions />} />
      <Route path="/userdispute" element={<UserDispute />} />
      <Route path="/userdispute/:id" element={<BuyerDisputeDetails />} />
      <Route path="/useraddress" element={<UserAddress />} />
      <Route path="/requestmade" element={<RequestMade />} />
      <Route path="/userquotes" element={<QouteRequests />} />

    
          <Route path="/artsmiley/login" element={<Login />} />
          <Route path="/artsmiley/admin/login" element={<LoginSuperAdmin />} />
          <Route path="/artsmiley/signup" element={<Signup />} />
       
      {urls.includes(location.pathname) ? (
        <Route path="*" element={<Navigate to="/" replace />} />
      ) : location.pathname.includes("/artsmiley/login") ? (
        <Route path="*" element={<Navigate to="/dashboard" replace />} />
      ) : (
        <Route path="*" element={<Pagenotfound />} />
      )}
    </Routes>
  );
}

// let output = reactRouterToArray(
//   <Route>
//     < IndexRoute component={UserMain} />
//   </Route >
// );

export default UserMain;
