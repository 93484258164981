import { Button } from "@mui/material"
import { Box } from "@mui/system"
import React from "react"
import styles from "./AffiliateDetailsPage.module.css"
import { useParams, useNavigate } from "react-router-dom"
// import homeImage from "../../../../../constant"
// import serverimage from "../../../../../serverConstant"

const data = {
  description: [
    {
      title: "Title",
      content: "Art of Space",
    },
    {
      title: "Copyright",
      content: "yes",
    },
    {
      title: "Category",
      content: "Oil Painting",
    },
    {
      title: "Subject",
      content: "Lorem this is Subject",
    },
    {
      title: "Year",
      content: "2022",
    },
    {
      title: "Material",
      content: "Canvas",
    },
    {
      title: "Styles",
      content: "Art",
    },
    {
      title: "Dimenstions",
      content: "Cm H25 X  W215 X D14",
    },
    {
      title: "Keywords",
      content: "Art, Painting, Space",
    },
    {
      title: "Description",
      content:
        "ver since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently wi",
    },
    {
      title: "Exibition 3",
      content: "Art of Space",
    },
    {
      title: "Subscription Plan",
      content: "Premium",
    },
    {
      title: "Exxibition 2",
      content: "Art of space",
    },
    {
      title: "Biography",
      content:
        "k. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and m",
    },
  ],
  detailPrice: {
    heading: "Price &  Detail",
    content: [
      {
        details_subheading: "Sale",
        detailsubcontent: [
          {
            title: "Availablity",
            content: "sale",
          },
          {
            title: "Price",
            content: "$250,25",
          },
        ],
      },
      {
        details_subheading: "Rent",
        detailsubcontent: [
          {
            title: "Availablity",
            content: "Rent",
          },
          {
            title: "Price",
            content: "$250,25",
          },
          {
            title: "Time",
            content: "1 Month",
          },
        ],
      },
    ],
  },
}

export default function AffiliateDetailsPage(props) {

  console.log("Affiliate Props",props);
  
  const navigate = useNavigate()

  const handleBack = () => {
    navigate("/affiliate")
  }
  return (
    <>
      <div className={styles.back_button}>
        <img
          src={"/img/_ Back.png"}
          onClick={handleBack}
          className={styles.backimage}
          height="13px"
          width="46px"
          alt="img"
        />
      </div>
      {props.data &&
        props.data.map((val) => (
          <>
            <div className={styles.previewimg_img_div}>
              <img
                src={
                  val.affiliates && val.affiliates[0].image
                    ? val.affiliates[0].image
                    : "/img/dummy-profile.png"
                }
                className={styles.previewimg}
                alt="img"
              />
            </div>
            <div
              className={
                props.page === "superadmin"
                  ? styles.details_main
                  : styles.details_main_curator
              }
            >
              <hr className={styles.horizontal} />
              <h2>Description</h2>
              <div className={styles.description_details}>
                <div className={styles.description_details_title}>Name :</div>
                <div className={styles.description_details_content}>
                  {val.affiliates && val.affiliates[0].affiliate_name}
                </div>
              </div>
              <div className={styles.description_details}>
                <div className={styles.description_details_title}>
                  Mobile Number :
                </div>
                <div className={styles.description_details_content}>
                  {val.affiliates && val.affiliates[0].phone}
                </div>
              </div>
              <div className={styles.description_details}>
                <div className={styles.description_details_title}>
                  Email Id :
                </div>
                <div className={styles.description_details_content}>
                  {val.affiliates && val.affiliates[0].email}
                </div>
              </div>
              <div className={styles.description_details}>
                <div className={styles.description_details_title}>
                  Account Type :
                </div>
                <div className={styles.description_details_content}>
                  {val.affiliates &&
                    val.affiliates[0].accountType &&
                    val.affiliates[0].accountType[0].name}
                </div>
              </div>
              <hr className={styles.horizontal} />
              <h2>Bank Details</h2>
              <div className={styles.description_details}>
                <div className={styles.description_details_title}>
                  Bank Name:
                </div>
                <div className={styles.description_details_content}>
                  {val.affiliates && val.bankdetails[0]?.bank_name
                    ? val.affiliates && val.bankdetails[0]?.bank_name
                    : "----"}
                </div>
              </div>
              <div className={styles.description_details}>
                <div className={styles.description_details_title}>
                  Bank Location :
                </div>
                <div className={styles.description_details_content}>
                  {val.affiliates && val.bankdetails[0]?.bank_location
                    ? val.affiliates && val.bankdetails[0]?.bank_location
                    : "----"}
                </div>
              </div>
              <div className={styles.description_details}>
                <div className={styles.description_details_title}>
                  Account Holder Name :
                </div>
                <div className={styles.description_details_content}>
                  {val.affiliates && val.bankdetails[0]?.account_holder_name
                    ? val.affiliates && val.bankdetails[0]?.account_holder_name
                    : "----"}
                </div>
              </div>
              <div className={styles.description_details}>
                <div className={styles.description_details_title}>
                  Account Type :
                </div>
                <div className={styles.description_details_content}>
                  {val.affiliates && val.bankdetails[0]?.account_type
                    ? val.affiliates && val.bankdetails[0]?.account_type
                    : "----"}
                </div>
              </div>
              <div className={styles.description_details}>
                <div className={styles.description_details_title}>
                  Account Number :
                </div>
                <div className={styles.description_details_content}>
                  {val.affiliates && val.bankdetails[0]?.account_number
                    ? val.affiliates && val.bankdetails[0]?.account_number
                    : "----"}
                </div>
              </div>

              <div className={styles.description_details}>
                <div className={styles.description_details_title}>
                  Bank Address :
                </div>
                <div className={styles.description_details_content}>
                  {val.affiliates && val.bankdetails[0]?.bank_address
                    ? val.affiliates && val.bankdetails[0]?.bank_address
                    : "----"}
                </div>
              </div>
              <div className={styles.description_details}>
                <div className={styles.description_details_title}>
                  Swift Code :
                </div>
                <div className={styles.description_details_content}>
                  {val.affiliates && val.bankdetails[0]?.swift_code
                    ? val.affiliates && val.bankdetails[0]?.swift_code
                    : "----"}
                </div>
              </div>
              <div className={styles.description_details}>
                <div className={styles.description_details_title}>
                  Routing/ABA Number :
                </div>
                <div className={styles.description_details_content}>
                  {val.affiliates && val.bankdetails[0]?.routing_ABA_number
                    ? val.affiliates && val.bankdetails[0]?.routing_ABA_number
                    : "----"}
                </div>
              </div>
              <hr className={styles.horizontal} />

              <h2>{val.affiliates && val.affiliates[0].affiliate_name}</h2>
              <div className={styles.description_details}>
                <div className={styles.description_details_title}>
                  Account Type:
                </div>
                <div className={styles.description_details_content}>
                  {val.affiliates &&
                    val.affiliates[0].accountType &&
                    val.affiliates[0].accountType[0].name}
                </div>
              </div>
              <div className={styles.description_details}>
                <div className={styles.description_details_title}>
                  Company Name :
                </div>
                <div className={styles.description_details_content}>
                  {val.affiliates && val.affiliates[0].company_name
                    ? val.affiliates && val.affiliates[0].company_name
                    : "----"}
                </div>
              </div>
              <div className={styles.description_details}>
                <div className={styles.description_details_title}>Name:</div>
                <div className={styles.description_details_content}>
                  {val.affiliates && val.affiliates[0].affiliate_name}
                </div>
              </div>
              <div className={styles.description_details}>
                <div className={styles.description_details_title}>Email :</div>
                <div className={styles.description_details_content}>
                  {val.affiliates && val.affiliates[0].email}
                </div>
              </div>
              <div className={styles.description_details}>
                <div className={styles.description_details_title}>
                  Country :
                </div>
                <div className={styles.description_details_content}>
                  {val.affiliates && val.affiliates[0].country
                    ? val.affiliates && val.affiliates[0].country
                    : "----"}
                </div>
              </div>
              <div className={styles.description_details}>
                <div className={styles.description_details_title}>
                  Language :
                </div>
                <div className={styles.description_details_content}>
                  {val.affiliates && val.affiliates[0].language
                    ? val.affiliates && val.affiliates[0].language
                    : "----"}
                </div>
              </div>
              <div className={styles.description_details}>
                <div className={styles.description_details_title}>
                  Phone Number :
                </div>
                <div className={styles.description_details_content}>
                  {val.affiliates && val.affiliates[0].phone
                    ? val.affiliates && val.affiliates[0].phone
                    : "----"}
                </div>
              </div>
              <div className={styles.description_details}>
                <div className={styles.description_details_title}>
                  Promote Art Smiley Services :
                </div>
                <div className={styles.description_details_content}>
                  {val.affiliates && val.affiliates[0].promote_art_smiley
                    ? val.affiliates && val.affiliates[0].promote_art_smiley
                    : "----"}
                </div>
              </div>
              <div className={styles.description_details}>
                <div className={styles.description_details_title}>
                  Audience Interests:
                </div>
                <div className={styles.description_details_content}>
                  {val.affiliates && val.affiliates[0].audience_interest
                    ? val.affiliates && val.affiliates[0].audience_interest
                    : "----"}
                </div>
              </div>
              <div className={styles.description_details}>
                <div className={styles.description_details_title}>
                  Website Url :
                </div>
                <div className={styles.description_details_content}>
                  {val.affiliates && val.affiliates[0].website_url
                    ? val.affiliates && val.affiliates[0].website_url
                    : "----"}
                </div>
              </div>
              <div className={styles.description_details}>
                <div className={styles.description_details_title}>
                  Mobile App Url :
                </div>
                <div className={styles.description_details_content}>
                  {val.affiliates && val.affiliates[0].app_url
                    ? val.affiliates && val.affiliates[0].app_url
                    : "----"}
                </div>
              </div>
              <div className={styles.description_details}>
                <div className={styles.description_details_title}>
                  Describes your website or mobile apps :
                </div>

                <div className={styles.description_details_content}>
                  {val.affiliates && val.affiliates[0].description
                    ? val.affiliates &&
                      val.affiliates[0].description.map((a, i) => a)
                    : "----"}
                </div>
              </div>
              <div className={styles.description_details}>
                <div className={styles.description_details_title}>
                  Drive traffic to your website :
                </div>
                <div className={styles.description_details_content}>
                  {val.affiliates && val.affiliates[0].traffic_drivation
                    ? val.affiliates &&
                      val.affiliates[0].traffic_drivation.map((a, i) => a)
                    : "----"}
                </div>
              </div>
              <div className={styles.description_details}>
                <div className={styles.description_details_title}>
                  Build Links :
                </div>
                <div className={styles.description_details_content}>
                  {val.affiliates && val.affiliates[0].build_links
                    ? val.affiliates &&
                      val.affiliates[0].build_links.map((a, i) => a)
                    : "----"}
                </div>
              </div>
              <div className={styles.description_details}>
                <div className={styles.description_details_title}>
                  Generate Income :
                </div>
                <div className={styles.description_details_content}>
                  {val.affiliates && val.affiliates[0].generate_income
                    ? val.affiliates && val.affiliates[0].generate_income
                    : "----"}
                </div>
              </div>
              <div className={styles.description_details}>
                <div className={styles.description_details_title}>
                  Unique visitors do your websites and apps get per month :
                </div>
                <div className={styles.description_details_content}>
                  {val.affiliates && val.affiliates[0].unique_visitor_count
                    ? val.affiliates && val.affiliates[0].unique_visitor_count
                    : "----"}
                </div>
              </div>
              <div className={styles.description_details}>
                <div className={styles.description_details_title}>
                  Primary reason for joining the artsmiley associates :
                </div>
                <div className={styles.description_details_content}>
                  {val.affiliates && val.affiliates[0].primary_reason_to_join
                    ? val.affiliates && val.affiliates[0].primary_reason_to_join
                    : "----"}
                </div>
              </div>
              <div className={styles.description_details}>
                <div className={styles.description_details_title}>
                  About Us :
                </div>
                <div className={styles.description_details_content}>
                  {val.affiliates && val.affiliates[0].hear_about_us
                    ? val.affiliates && val.affiliates[0].hear_about_us
                    : "----"}
                </div>
              </div>
              <div className={styles.description_details}>
                <div className={styles.description_details_title}>
                  Website or mobile apps about :
                </div>
                <div className={styles.description_details_content}>
                  {val.affiliates && val.affiliates[0].website_about
                    ? val.affiliates && val.affiliates[0].website_about
                    : "----"}
                </div>
              </div>
            </div>
          </>
        ))}
    </>
  )
}
