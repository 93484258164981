import { Checkbox, FormControlLabel } from "@mui/material";
import React, { useEffect, useState } from "react";
import Threedots from "../../Table/Threedots/Threedots";
import styles from "./Notification.module.css";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Avatar,
  Typography,
  Button,
  Grid,
} from "@mui/material";
import homeImage from "../../../../../constant";
import { FaSearch } from "react-icons/fa";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


const data = [
  {
    src: "/img/image 1.png",
    name: "Laurie Fox",
    title: "Reported Order",
    content: "kioiewfew ou judif dfhj sdk uwer ij f ew...",
    time: "2:00 PM",
    check: true,
  },
  {
    src: "/img/image 1.png",
    name: "Laurie Fox",
    title: "Reported Order",
    content: "kioiewfew ou judif dfhj sdk uwer ij f ew...",
    time: "2:00 PM",
    check: true,
    btn: [],
  },
  {
    src: "/img/image 1.png",
    name: "Laurie Fox",
    title: "Reported Order",
    content: "kioiewfew ou judif dfhj sdk uwer ij f ew...",
    time: "2:00 PM",
    check: true,
    btn: [],
  },
  {
    src: "/img/image 1.png",
    name: "Laurie Fox",
    title: "Reported Order",
    content: "kioiewfew ou judif dfhj sdk uwer ij f ew...",
    time: "2:00 PM",
    check: false,
    btn: [],
  },
  {
    src: "/img/image 1.png",
    name: "Laurie Fox",
    title: "Reported Order",
    content: "kioiewfew ou judif dfhj sdk uwer ij f ew...",
    time: "2:00 PM",
    check: false,
    btn: [
      {
        label: "Confirm File...",
      },
      {
        label: "Important ...",
      },
    ],
  },
  {
    src: "/img/image 1.png",
    name: "Laurie Fox",
    title: "Reported Order",
    content: "kioiewfew ou judif dfhj sdk uwer ij f ew...",
    time: "2:00 PM",
    check: false,
    btn: [
      {
        label: "Confirm File...",
      },
      {
        label: "Important ...",
      },
    ],
  },
  {
    src: "/img/image 1.png",
    name: "Laurie Fox",
    title: "Reported Order",
    content: "kioiewfew ou judif dfhj sdk uwer ij f ew...",
    time: "2:00 PM",
    check: false,
    btn: [
      {
        label: "Confirm File...",
      },
      {
        label: "Important ...",
      },
    ],
  },
];
const threedotlinks = [
  {
    link: "opened",
  },
];

export default function Notify(data) {
  console.log("data", data);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    // Filter data based on search text
    const filtered = data?.data?.filter((item) =>
      item?.content?.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredData(filtered || []);
  }, [searchText, data]);

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };



  return (
    <div className={styles.mains}>
      <div>
        {/* <InputGroup className="mb-3">
          <Form.Control
            placeholder="Search notifications..."
            aria-label="Search"
            value={searchText}
            onChange={handleSearchChange}
          />
          
        </InputGroup> */}
      </div>
      <div className={styles.components}>
        {filteredData?.length > 0 ? (
          <>
            {/* <Table className={styles.notification_table} bordered hover>
              <thead>
                <tr>
                  <th>Sl No.</th>
                  <th>Image</th>
                  <th>Notification</th>
                  <th>Time</th>
                </tr>
              </thead>
              <tbody>
                {filteredData?.map((item, i) => {
                  return (
                    <tr>
                      <td>{i + 1}.</td>
                      <td>
                        {" "}
                        <img
                          width="100px"
                          height="50px"
                          src={item?.src}
                          alt=""
                        />
                      </td>
                      <td>
                       <div><b>Name : </b> <span>{item?.name}</span></div> 
                       <div> <b>Title :</b> <span>{item?.title}</span> </div>
                       <div> <b>Content :</b> <span>{item?.content}</span> </div>
                       </td>
                      <td>{item?.time}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table> */}
            <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="notifications table" >
        <TableHead className={styles.notification_table}>
          <TableRow>
            <TableCell>Sl No.</TableCell>
            <TableCell align="center">Image</TableCell>
            <TableCell align="center">Notification</TableCell>
            <TableCell align="center">Time</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredData?.map((item, i) => (
            <TableRow
              key={i}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>{i + 1}.</TableCell>
              <TableCell align="center">
                <img
                  src={item?.src}
                  alt="Notification"
                  style={{ width: "100px", height: "50px", objectFit: "cover" }}
                />
              </TableCell>
              <TableCell align="center">
                <div>
                  <b>Name:</b> <span>{item?.name}</span>
                </div>
                <div>
                  <b>Title:</b> <span>{item?.title}</span>
                </div>
                <div>
                  <b>Content:</b> <span>{item?.content}</span>
                </div>
              </TableCell>
              <TableCell align="center">{item?.time}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
          </>
        ) : (
          <h5 style={{ textAlign: "center" }}>No Notification Found !</h5>
        )}
      </div>
    </div>
  );
}
