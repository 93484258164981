import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  Container,
  Link,
  MenuItem,
  Modal,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./CheckOut.module.css";
import Select from "@mui/material/Select";
import homeImage from "../../../constant";
import { useLocation, useNavigate } from "react-router-dom";
import PaypalCheckoutButton from "../../../payment/PaypalCheckoutButton";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import StripeDetail from "../../../payment/stripe/StripeDetail";
import {
  AlertsSuccess,
  Errornotify,
  Successnotify,
} from "../../../common/alert/Alert";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { validationCheckoutPage } from "../../../validation/validationForCheckout";
import axios from "axios";
import { fetchMaterial } from "../../../State/api/artistapi";
import {
  ApplyGiftCard,
  ApplyOfferCoupon,
  buyerProfileAddressUpdate,
  buyerProfileAddressUpdateCheckout,
  fetchAllItemData,
  fetchFetchAllcart,
  handleFetchBuyerDetail,
  updateAddressBuyer,
} from "../../../State/api/userApi";
import api from "../../../State/api/Api";
import { setLoader } from "../../../State/Features/common/loaderSlice";
import { ToastContainer } from "react-toastify";
import CustomTabby from "../../../payment/stripe/CustomTabby";
import { userCurrency } from "../../../utils/currencyFormat";
import { Elements } from "@stripe/react-stripe-js";
// import { Country, State, City } from 'country-state-city';




// Import Interfaces`
// import { ICountry, IState, ICity } from 'country-state-city'
const currency = "USD";
const style = { layout: "vertical" };
function CheckOut() {
  const location = useLocation();
  const amount = localStorage.getItem("total_amount");
  const [coupon_code, setCouponCode] = useState("");
  const [couponData, setCouponData] = useState([]);
  const [giftCardData, setGiftCardData] = useState({});
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [states, setStates] = useState("");
  const dispatch = useDispatch();
  const userDetail = useSelector((state) => state.buyerdetail.data);
  const [address_verify, setAddress_verify] = useState(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    height: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    bgcolor: "background.paper",
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  // cart items api
  const user_id = localStorage.getItem("user_id");
  const [data, setData] = useState(1);
  const mac_Address = localStorage.getItem("device_id");
  const [updatedAddress, setUpdatedAddress] = useState({})
  const [giftCard, setGiftCard] = useState("");
  const myCart = useSelector((state) => state.mycartData.data);
  const orderTypesId = myCart?.result
    ?.map((val) => {
      const id = val.orderType_id?._id;
      return id ? String(id) : ""; // Convert to string if ID exists, otherwise return an empty string
    })
    .join(","); // Jo
  const orderTypesIdArray = orderTypesId?.split(",");

  const userty = useSelector((state) => state.user.data);
  const cart_item_id = myCart && myCart.result.map((val) => val.item_id);
  const itemQuantities = myCart?.result?.map((val) => val.quantity) || [];
  const [inputs, setInputs] = useState({
    buy_percentage: 0,
    print_percentage: 0,
    rent_percentage: 0,
    vat_percentage: 0,
    custom_duties: 0,
    tax_percentage: 0,
  });
  const itemprice =
    myCart &&
    myCart.result.map((val) => {
      if (!val.item_id) return 0;

      if (val.item_id.sale.length) {
        return val.item_id.sale
          .map((item) => item.price)
          .reduce((acc, price) => acc + price, 0);
      } else if (val.item_id.print.length) {
        return val.item_id.print
          .map((item) => item.print_price)
          .reduce((acc, price) => acc + price, 0);
      } else if (val.item_id.rent.length) {
        return val.item_id.rent
          .map((item) => item.rent_price)
          .reduce((acc, price) => acc + price, 0);
      } else {
        return 0;
      }
    });
  const itemQuantity = myCart && myCart.result.map((val) => val.quantity);
  const subtotalarray =
    itemprice && itemprice.map((e, index) => e * itemQuantity[index]);
  const subtotal = subtotalarray && subtotalarray.reduce((a, b) => a + b, 0);
  console.log(subtotal, "subtotal");
  const vat = subtotal * (inputs.vat_percentage / 100);
  const [address, setAddress] = useState({
    item_ids: [
      {
        item_id: "",
        artist_id: "",
        order_type_id: "",
        //chooseframe_id: "664ef8f09b023011fb61de0e",
        quantity: "",
        item_amount: "",
        frame_amount: "", // optional
        contract_duration: "Life Long", // optional
        is_via_affiliate:  true, // optional
        affiliate_id: ""
      },
    ],
    quantity: "",
    price: subtotal + vat,
    shipping_amount: 200, //Required
    package_amount: 60, //Required
    vat_amount: vat, //Required
    discount_type: "Percentage",
    discount_amount: 30,
    payment_method: "", //Required
    address: {
      address_id: "",
      address_type: "",
      apartment: "",
      address_line1: "",
      address_line2: "",
      fullName: "",
      address: "",
      contact: "",
      city: "",
      state: "",
      country: "",
      country_code: "",
      postal_code: "",
      zipcode: "425508",
    },
  });

  useEffect(() => {
    userty.signed === true &&
      dispatch(
        fetchFetchAllcart({
          offset: data,
          user_id: localStorage.getItem("user_id"),
        })
      );
    userty.signed === false &&
      dispatch(
        fetchFetchAllcart({
          offset: 1,
          user_id: localStorage.getItem("user_id"),
        })
      );
    dispatch(fetchMaterial(setMaterial));
    dispatch(fetchAllItemData());
    handleFetchTaxes();
    // dispatch(fetchSettingTax(setTaxes));
  }, []);

  const handleSelectChange = (event) => {
    setAddress((prevState) => ({
      ...prevState,
      address: {
        ...prevState.address,
        address_type: event.target.value,
      },
    }));
    const selectedAddress = userDetail.buyer.addresses.find((val) => val.address_type === event.target.value);
    setUpdatedAddress(selectedAddress)
  }
  const handleChangeAddress = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setAddress((prevState) => ({
      ...prevState,
      address: {
        ...prevState.address,
        [name]: value,
      },
    }));
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleApplyCoupon = () => {
    if (coupon_code) {
      dispatch(ApplyOfferCoupon(coupon_code, setCouponData));
    } else {
      Errornotify("Please enter coupon code");
    }
  };

  const handleApplyGiftCard = () => {
    if (giftCard) {
      dispatch(ApplyGiftCard(giftCard, setGiftCardData, setOpen));
    } else {
      Errornotify("Please enter coupon code");
    }
  };
  const handleSubmitProfile = async () => {
    try {
      const apiResponse = await api.applicationApi(
        `api/DHL/addressValidate`,
        "POST",
        {
          type: "delivery",
          countryCode: address.address.country_code,
          postalCode: address.address.postal_code,
          cityName: address.address.city,
          countyName: address.address.country,
        }
      );
      if (apiResponse.status === 201 || apiResponse.status === 200) {
        dispatch(updateAddressBuyer(address.address.address_id,address));
        // Successnotify("Address Verified");
      } else {
        Errornotify("Address Not Verified");
      }
    } catch (error) {
    }

    setErrors({});
  };
  const handleSaveAddress = async (event) => {
    const { checkoutErrors, isValidCheckout } = validationCheckoutPage(
      address.address
    );
    if (isValidCheckout) {
      setAddress_verify(true);
      setErrors({});
    } else {
      setErrors(checkoutErrors);
      Errornotify("Please fill all the details!");
    }
  };

  const handleMakePayment = () => {};
  const [month, setMonth] = useState("May");

  const handleChangeMonth = (event) => {
    setMonth(event.target.value);
  };
  const [year, setYear] = useState("2024");
  const [material, setMaterial] = useState(null);
  const handleChangeYear = (event) => {
    setYear(event.target.value);
  };

  const indianStatesAndUnionTerritories = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli and Daman and Diu",
    "Lakshadweep",
    "Delhi",
    "Puducherry",
    "Ladakh",
    "Jammu and Kashmir",
  ];

  const countries = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo, Democratic Republic of the",
    "Congo, Republic of the",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Dubai",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "East Timor (Timor-Leste)",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Ivory Coast",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea, North",
    "Korea, South",
    "Kosovo",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "North Macedonia (Macedonia)",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Togo",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Vatican City",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ];
  // get user details
  useEffect(() => {
    if (
      userDetail &&
      userDetail.data &&
      userDetail.buyer &&
      userDetail.buyer?.addresses?.length > 0
    ) {
      setAddress((prevState) => { // Log previous state // Log user details

        const lastAddress =
        userDetail.buyer.addresses.find((address) => address.is_default);
    const item_ids = (location?.state?.item_id ? [location.state] : cart_item_id)?.map((item, index) => {
      // Retrieve affiliate_id from myCart.result if available
      const myCartAffiliateId = myCart?.result?.[index]?.affiliate_id?._id || undefined;
      const affiliateId = location?.state?.affiliate_id || myCartAffiliateId;
    
      return {
        item_id: location?.state?.item_id || item._id,
        artist_id: location?.state?.artist_id || item?.artist_id,
        quantity: location?.state?.quantity || itemQuantities[index] || 0,
        order_type_id: location?.state?.order_type_id || orderTypesIdArray[index],
        item_amount: item?.sale?.length
            ? item.sale.map((saleItem) => saleItem.price).reduce((acc, price) => acc + price, 0)
            : item?.print?.length
            ? item.print.map((printItem) => printItem.print_price).reduce((acc, price) => acc + price, 0)
            : item?.rent?.length
            ? item.rent.map((rentItem) => rentItem.rent_price).reduce((acc, price) => acc + price, 0)
            : 0,
        frame_amount: "",
        contract_duration: "Life Long",
        ...(affiliateId && { affiliate_id: affiliateId }), // Only add affiliate_id if it's defined
        is_via_affiliate: !!affiliateId, // Set to true if affiliate_id exists
      };
    });
       
  

        return {
          ...prevState,
          item_ids,
          quantity: cart_item_id?.length,
          price: location?.state?.price ||  subtotal + vat,
          payment_method: "Paypal",
          address: {
            ...prevState.address,
            fullName: `${userDetail?.data?.first_name} `,
            contact: userDetail?.data?.phone,
            address_type: updatedAddress.address_type || lastAddress?.address_type || "",
            address_line1: updatedAddress?.address_line1 || lastAddress?.address_line1 || "",
            address_line2: updatedAddress?.address_line2 || lastAddress?.address_line2 || "",
            apartment: updatedAddress?.apartment || lastAddress?.apartment,
            city: updatedAddress?.city || lastAddress?.city || "",
            country: updatedAddress?.country || lastAddress?.country || "",
            state: updatedAddress?.state || lastAddress?.state || "",
            zipcode: updatedAddress?.zipcode || lastAddress?.zipcode || "",
            address_id: updatedAddress?._id || lastAddress?._id || ""
          },
        };
      });
    }
  }, [userDetail,updatedAddress,location.state]);

  useEffect(() => {
    dispatch(handleFetchBuyerDetail());
  }, []);

  //

  const navigate = useNavigate();
  const handleNavigate = (route) => {
    navigate(route);
  };
  const handleFetchTaxes = async function () {
    dispatch(setLoader(true));
    try {
      const result = await api.applicationApi(
        `api/superAdmin/fetchSettingDetails`,
        "GET"
      );
      if (result && result.status === 200) {
        result.data.data.forEach((element) => {
          setInputs(element);
        });
        dispatch(setLoader(false));
      } else if (result.status === 401) {
      }
    } catch (err) {
    }
  };
  useEffect(() => {
    dispatch(fetchMaterial(setMaterial));
    dispatch(fetchAllItemData());
    handleFetchTaxes();
    // dispatch(fetchSettingTax(setTaxes));
  }, []);
  const [ipAdd, setIpAdd] = useState("");
  const [currency, setCurrency] = useState("");
  useEffect(() => {
    const getIpAddress = async () => {
      const data = await axios.get("https://api.ipify.org/?format=json");
      const id = data.data.ip;
      setIpAdd(id);
    };
    getIpAddress();
  }, []);
  const currencyRates = {
    USD: 1, // Base currency
    GBP: 0.7967, // Pound Sterling rate against USD
    AED: 3.6725, // Dirham rate against USD (example value, replace with actual rate)
    // Add more currency rates as needed
  };
  function formatNewCurrency(amount) {
    if (currency === "GBP") {
      // Assuming formatCurrency function is defined elsewhere
      return "£" + formatCurrency(amount);
    } else if (currency === "AED") {
      // Assuming formatCurrency function is defined elsewhere
      return formatCurrency(amount);
    } else {
      return "$" + amount;
    }
  }
  const formatCurrency = (amount) => {
    let convertedAmount;
    if (currency === "USD") {
      convertedAmount = amount; // No conversion needed if currency is USD
    } else {
      const rateAgainstUSD = currencyRates[currency]; // Get the exchange rate against USD
      convertedAmount = amount * rateAgainstUSD; // Convert amount to user's currency
    }
    return currency === "AED"
      ? `${currency}${convertedAmount.toFixed(2)}`
      : `${convertedAmount.toFixed(2)}`;
  };
  useEffect(() => {
    const getCurrency = async () => {
      const currency = await axios.get(`https://ipapi.co/${ipAdd}/currency/`);
      const data = currency.data;
      setCurrency(data === "GBP" ? "GBP" : data === "AED" ? data : "USD");
    };
    getCurrency();
  }, []);

  const years = [];
  for (let year = 1950; year <= 2030; year++) {
    years.push(year.toString());
  }

  const getDiscountedPrice = (price) => {
    const numericPrice = parseFloat(price);
    // Get the discount percentage from localStorage and parse it as a number
    const totalDiscountPercentage =
      JSON.parse(localStorage.getItem("discount_percentage")) || 0;
    if (totalDiscountPercentage > 0) {
      // Apply the discount to the price

      return numericPrice * (1 - totalDiscountPercentage / 100) + vat;
    } else {
      return numericPrice + vat;
    }
  };
  return (
    <>
      <div className={styles.checkout}>
        <AlertsSuccess />
        <div className={styles.headerCategory}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
      <div className={styles.pageitle}>
              <h1>My Cart</h1>
              <ul>
                <li><Link onClick={() => handleNavigate("/")}>Home /{" "} </Link></li>
                <li>My Cart</li>
              </ul>
            </div>
      </Container>
        </div>
        <div className={styles.formsec}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
        <Stepper>
          <Step>
            <StepLabel>Shopping Cart</StepLabel>
          </Step>
          <Step active>
            <StepLabel>Checkout</StepLabel>
          </Step>
          <Step>
            <StepLabel>Completed</StepLabel>
          </Step>
        </Stepper>
        <div className={styles.checkout__grid}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={6}>
              <div className={styles.checkout__left}>
              <Typography mt={1} mb={1}>
                  Address Type
                </Typography>

                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={address.address.address_type}
                    onChange={handleSelectChange}
                    name="address_type"
                  >
                   
                    {userDetail?.buyer?.addresses?.map((val) => (
                      <MenuItem value={val.address_type}>{val.address_type}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Typography mt={1} mb={1}>
                  Full Name*
                </Typography>
                <TextField
                  onChange={handleChangeAddress}
                  fullWidth
                  id="outlined-basic"
                  name="fullName"
                  value={address.address.fullName}
                  variant="outlined"
                />
                {errors.fullname && (
                  <FormHelperText className={styles.validation_error_color}>
                    Full Name is required
                  </FormHelperText>
                )}
                {/* <Typography mt={1} mb={1}>
                Address*
              </Typography>
              <TextField onChange={handleChangeAddress}  fullWidth id="outlined-basic" name='address' variant="outlined" />
            { errors.address &&  <FormHelperText className={styles.validation_error_color}>Address is required</FormHelperText>} */}

                <Typography mt={1} mb={1}>
                  Address line 1*
                </Typography>
                <TextField
                  onChange={handleChangeAddress}
                  fullWidth
                  id="outlined-basic"
                  name="address_line1"
                  variant="outlined"
                  value={address.address.address_line1}
                />
            

<Typography mt={1} mb={1}>
                  Address line 2*
                </Typography>
                <TextField
                  onChange={handleChangeAddress}
                  fullWidth
                  id="outlined-basic"
                  name="address_line2"
                  variant="outlined"
                  value={address.address.address_line2}
                />
             
                     {/* <Typography mt={1} mb={1}>
                  Apartment*
                </Typography>
                <TextField
                  onChange={handleChangeAddress}
                  fullWidth
                  id="outlined-basic"
                  name="apartment"
                  variant="outlined"
                  value={address.address.apartment}
                /> */}
          
                <Typography mt={1} mb={1}>
                  Contact*
                </Typography>
                <TextField
                  value={address.address.contact}
                  onChange={handleChangeAddress}
                  fullWidth
                  id="outlined-basic"
                  name="contact"
                  variant="outlined"
                />
                {errors.contact && (
                  <FormHelperText className={styles.validation_error_color}>
                    Contact is required
                  </FormHelperText>
                )}
                <Typography mt={1} mb={1}>
                  City*
                </Typography>
                <TextField
                  onChange={handleChangeAddress}
                  value={address.address.city}
                  fullWidth
                  id="outlined-basic"
                  name="city"
                  variant="outlined"
                />
                {errors.city && (
                  <FormHelperText className={styles.validation_error_color}>
                    City is required
                  </FormHelperText>
                )}

                <Typography mt={1} mb={1}>
                  State*
                </Typography>
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={address.address.state}
                    onChange={handleChangeAddress}
                    name="state"
                  >
                    {indianStatesAndUnionTerritories.map((val) => (
                      <MenuItem value={val}>{val}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {errors.state && (
                  <FormHelperText className={styles.validation_error_color}>
                    State is required
                  </FormHelperText>
                )}

                <Typography mt={1} mb={1}>
                  Country*
                </Typography>

                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={address.address.country}
                    onChange={handleChangeAddress}
                    name="country"
                  >
                    {countries.map((val) => (
                      <MenuItem value={val}>{val}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {errors.country && (
                  <FormHelperText className={styles.validation_error_color}>
                    Country is required
                  </FormHelperText>
                )}
                <Typography mt={1} mb={1}>
                  Country Code*
                </Typography>
                <TextField
                  onChange={handleChangeAddress}
                  value={address.address.country_code}
                  fullWidth
                  id="outlined-basic"
                  name="country_code"
                  placeholder="AE"
                  variant="outlined"
                />
                {errors.country_code && (
                  <FormHelperText className={styles.validation_error_color}>
                    Country code is required
                  </FormHelperText>
                )}
                <Typography mt={1} mb={1}>
                  Postal Code*
                </Typography>
                <TextField
                  onChange={handleChangeAddress}
                  value={address.address.postal_code}
                  fullWidth
                  id="outlined-basic"
                  name="postal_code"
                  variant="outlined"
                  placeholder="12345"
                />
                {errors.postal_code && (
                  <FormHelperText className={styles.validation_error_color}>
                    Postal code is required
                  </FormHelperText>
                )}
                {/* <Grid item xs={6} sm={6} md={6}>
                      <Typography mt={1} mb={1}>
                        Zip Code
                      </Typography>
                      <TextField
                        onChange={handleChangeAddress}
                        fullWidth
                        name="zipcode"
                        value={address.address.zipcode}
                        id="outlined-basic"
                        variant="outlined"
                      />
                      {errors.zipcode && (
                        <FormHelperText
                          className={styles.validation_error_color}
                        >
                          Zip Code is required
                        </FormHelperText>
                      )}
                    </Grid> */}
                <Grid item xs={12} sm={12} md={12} mt={2} >
                  <button 
                    fullWidth
                    className={styles.fillbtn}
                    onClick={handleSubmitProfile}
                  >
                    Save Address
                  </button>
                </Grid>
              </div>
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              {" "}
              <div className={styles.subttl}>
              <span className={styles.itemnm}>
              SubTotal
                  </span>
                  <span className={styles.iprisubt}>${subtotal
                      }</span>
              </div>
               <div className={styles.total__div}>
                <div className={styles.totalvalue__div}>
                <span className={styles.itemnm}>Shipping
                <p className={styles.frdimns}>Free Shipping</p>
                </span>
                <span className={styles.iprisubt}>{formatNewCurrency("00.00")}</span>
                  
                </div>
                <div className={styles.totalvalue__div}>
                <span className={styles.itemnm}>Packaging
                <p className={styles.frdimns}>Free Basic Packaging</p>
                </span>
                <span className={styles.iprisubt}>{formatNewCurrency("00.00")}</span>
                  
                </div>
                <div className={styles.totalvalue__div}>
                <span className={styles.itemnm}>VAT
                <p className={styles.frdimns}>{formatNewCurrency(inputs.vat_percentage)}</p>
                </span>
                <span className={styles.iprisubt}>{formatNewCurrency(vat.toFixed(2))}</span>
                 
                </div>
                <div
                  className={styles.totalvalue__div}
                  style={{ marginBottom: "15px" }}
                >
                  <span className={styles.itemnm}>Discount
                  <p className={styles.frdimns}>0%</p>
                  </span>
                  <span className={styles.iprisubt}>{formatNewCurrency("00.00")}</span>
                  
                </div>

                <TextField
                  id="input-with-icon-textfield"
                  name="coupon_code"
                  value={coupon_code}
                  onChange={(e) => setCouponCode(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={"/img/Coupon Vector.png"} alt="img" />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          onClick={handleApplyCoupon}
                          sx={{
                            backgroundColor: "black",
                            color: "white",
                            marginTop: "-14%",
                            "&:hover": {
                              backgroundColor: "black",
                              color: "white",
                            },
                          }}
                        >
                          Apply Code
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                  variant="standard"
                  sx={{
                    marginBottom: "3%",
                    width: "100%",
                  }}
                />
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Link
                    onClick={() => setOpen(true)}
                    underline="none"
                    sx={{
                      fontSize: "12px",
                      textDecoration: "underline",
                      color: "gray",
                      cursor: "pointer",
                    }}
                  >
                    {"Apply Gift Coupon"}
                  </Link>
                </div>

                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <TextField
                      id="input-with-icon-textfield"
                      name="coupon_code"
                      value={giftCard}
                      onChange={(e) => setGiftCard(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img src={"/img/Coupon Vector.png"} alt="img" />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <Button
                              onClick={handleApplyGiftCard}
                              sx={{
                                backgroundColor: "black",
                                color: "white",
                                marginTop: "-14%",
                                "&:hover": {
                                  backgroundColor: "black",
                                  color: "white",
                                },
                              }}
                            >
                              Apply Gift Coupon
                            </Button>
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                      sx={{
                        marginBottom: "3%",
                        width: "100%",
                      }}
                    />
                  </Box>
                </Modal>
                {couponData.length ? (
                  <>
                    {couponData.map((val) => (
                      <Typography
                        fontSize="0.8rem"
                        color="green"
                        marginTop={"0.2rem"}
                        fontWeight="600"
                      >
                        {coupon_code} Coupon code applied! You get a{" "}
                        {val?.discount}% discount on purchase.
                      </Typography>
                    ))}
                  </>
                ) : null}
                {giftCardData?.user_card_id ? (
                  <>
                    <Typography
                      fontSize="0.8rem"
                      color="green"
                      marginTop={"0.2rem"}
                      fontWeight="600"
                    >
                      {giftCardData?.user_card_id} Gift card applied! You get a
                      ${giftCardData?.amount} discount on purchase.
                    </Typography>
                  </>
                ) : null}
              
                <hr />
              
              {/* <hr /> */}
                <div className={styles.totalvalue__div}>
                  <div>
                    <Typography fontSize="0.9rem">Total</Typography>
                  </div>
                  <Typography fontSize="1.05rem" fontWeight="600">
                    {subtotal !== 0
                      ? formatNewCurrency(
                          (
                            // getDiscountedPrice(
                            //   subtotal +
                            //     JSON.parse(
                            //       localStorage.getItem("product_prices")
                            //     )
                            // ) *
                            
                                 getDiscountedPrice(
                               subtotal  +     JSON.parse(
                                       localStorage.getItem("product_prices")
                                     )
                             ) *
                              (1 -
                                couponData
                                  ?.map((val) => val?.discount)
                                  ?.reduce(
                                    (acc, discount) => acc + discount,
                                    0
                                  ) /
                                  100) -
                            (giftCardData?.amount || 0)
                          ).toFixed(2) // Apply toFixed(2) after the calculation
                        )
                      : formatNewCurrency(
                          (
                            getDiscountedPrice(
                              JSON.parse(localStorage.getItem("product_prices"))
                            ) *
                              (1 -
                                couponData
                                  ?.map((val) => val?.discount)
                                  ?.reduce(
                                    (acc, discount) => acc + discount,
                                    0
                                  ) /
                                  100) -
                            (giftCardData?.amount || 0)
                          ).toFixed(2) // Apply toFixed(2) after the calculation
                        )}

                    {couponData.length || giftCardData?.user_card_id ? (
                      <span
                        style={{
                          marginLeft: "5px",
                          color: "gray",
                          fontWeight: "500",
                          textDecoration: "line-through",
                        }}
                      >
                        {subtotal !== 0
                          ? formatNewCurrency(
                              getDiscountedPrice(
                                subtotal +
                                  JSON.parse(
                                    localStorage.getItem("product_prices")
                                  )
                              ).toFixed(2)
                            )
                          : formatNewCurrency(
                              getDiscountedPrice(
                                JSON.parse(
                                  localStorage.getItem("product_prices")
                                ) + vat
                              ).toFixed(2)
                            )}
                      </span>
                    ) : null}

                    {/* {giftCardData?.user_card_id ? (
                      <span
                        style={{
                          marginLeft: "5px",
                          color: "gray",
                          fontWeight: "500",
                          textDecoration: "line-through",
                        }}
                      >
                        {subtotal !== 0
                          ? formatNewCurrency(
                            getDiscountedPrice(  subtotal +
                              
                                JSON.parse(
                                  localStorage.getItem("product_prices")
                                )).toFixed(2)
                            )
                          : formatNewCurrency(
                            getDiscountedPrice(  JSON.parse(
                                localStorage.getItem("product_prices")
                              )).toFixed(2)
                            )}
                      </span>
                    ) : null} */}
                  </Typography>
                </div>
                {!address_verify && (
                  <button
                  className={styles.fillbtn}
                    onClick={() => {
                      handleSaveAddress();
                      localStorage.setItem(
                        "payment_amount",
                        JSON.stringify(
                          getDiscountedPrice(
                            subtotal +
                              JSON.parse(localStorage.getItem("product_prices"))
                          ) *
                            (1 -
                              couponData
                                ?.map((val) => val?.discount)
                                ?.reduce((acc, discount) => acc + discount, 0) /
                                100) -
                            (giftCardData?.amount || 0)
                        )
                      );
                    }}
                    // disabled={subtotal === 0}
                    // onClick={() => {
                    //   navigate("/user/checkout");
                    //   localStorage.setItem("total_amount", subtotal + vat);
                    // }}
                   
                  >
                    Pay{" "}
                    {subtotal !== 0
                      ? formatNewCurrency(
                          (
                            getDiscountedPrice(
                              subtotal + JSON.parse(localStorage.getItem("product_prices"))
                            ) *
                              (1 -
                                couponData
                                  ?.map((val) => val?.discount)
                                  ?.reduce(
                                    (acc, discount) => acc + discount,
                                    0
                                  ) /
                                  100) -
                            (giftCardData?.amount || 0)
                          ).toFixed(2) // Apply toFixed(2) after the calculation
                        )
                      : formatNewCurrency(
                          (
                            getDiscountedPrice(
                              JSON.parse(localStorage.getItem("product_prices"))
                            ) *
                              (1 -
                                couponData
                                  ?.map((val) => val?.discount)
                                  ?.reduce(
                                    (acc, discount) => acc + discount,
                                    0
                                  ) /
                                  100) -
                            (giftCardData?.amount || 0)
                          ).toFixed(2) // Apply toFixed(2) after the calculation
                        )}
                  </button>
                )}

                {address_verify && (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexWrap: "wrap",
                      padding: "10px 0px",
                    }}
                  >
                    <div style={{ width: "48%", marginRight: "1%" }}>
                      <PayPalScriptProvider
                        options={{
                          "client-id":
                            "AYWjsJ-Q2mNg_Pvjnslq_eUlu6xCfDP5-GXb-77XFykzgZlJS4TyaSw6Wl4cQj9k4B40twLP8SEcN_1B",
                          components: "buttons",
                          currency: currency,
                        }}
                      >
                        <PaypalCheckoutButton
                          currency={currency}
                          showSpinner={false}
                          address={address}
                          setAddress={setAddress}
                          couponData={couponData}
                          giftCardAmount={giftCardData?.amount}
                        />
                      </PayPalScriptProvider>
                    </div>

                    <div style={{ width: "48%", marginBottom: "5px" }}>
                      <StripeDetail
                        cart_item_id={cart_item_id}
                        address={address}
                        setAddress={setAddress}
                      />
                    </div>

                    <div style={{ width: "48%" }}>
                      <CustomTabby />
                    </div>
                  </div>
                )}

                {/* <div style={{width:"100%",display:"flex",alignItems:"center",padding:"4% 0px"}} className={styles.paymentmode__div}>
                <img src={ "/img/giftcard.png"} alt='img' />
                <Typography ml={1}>Gift Card (0$)</Typography>
              </div> */}
              </div>
            </Grid>
          </Grid>
        </div>
        </Container>
        </div>
        

        
      </div>
      <ToastContainer />
    </>
  );
}

export default CheckOut;
