import { Grid, Link, Container } from "@mui/material";
import React, {useRef} from 'react';
import styles from "./TradeProgramApplication.module.css";
import { useNavigate } from "react-router-dom"
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CssBaseline from "@mui/material/CssBaseline";
import {RiArrowLeftLine, RiArrowRightLine } from "react-icons/ri";
import PhoneInput from "react-phone-input-2";
import capitalizeWords from "../../../../utils/capitalizeContent";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import striptags from "striptags";

function ArtConsultancy() {

  const prevButtonRef = useRef(null);
  const nextButtonRef = useRef(null);

  const navigate = useNavigate()
  const handleNavigate = (route) => {
    navigate(route)
  }
  const exclusive_data = [
    {
      id: 1,
      src: "/img/artcollections",
      heading: "Initial Consultation",
      des:"The journey begins with a conversation. We take the time to understand your unique requirements whether it’s a specific style, color palette, theme, or the purpose the artwork will serve. Our expert curators will listen closely to ensure the final piece aligns with your aesthetic and objectives."
    },
    {
      id: 2,
      src: "/img/artcollections",
      heading: "Concept Development",
      des: "Once we understand your vision, we collaborate with you to develop a concept. You’ll receive an initial design brief or mood board that reflects the direction of the artwork. This is the stage where creativity takes shape, and any adjustments can be made to ensure we are headed in the right direction."
    },
    {
      id: 3,
      src: "/img/artcollections",
      heading: "Proposal and Feedback",
      des: "We provide a detailed proposal that includes artwork options, placement suggestions, and pricing. Your feedback is essential, and we refine the selection based on your input until it meets your expectations."
    },
    {
      id: 4,
      src: "/img/artcollections",
      heading: "Procurement and Production",
      des: "Once approved, we source or produce the selected artworks, ensuring the highest quality and attention to detail. We offer a variety of framing and mounting options to complement your artwork and the space it will occupy."
    },
    {
      id: 5,
      src: "/img/artcollections",
      heading: "Delivery and Installation",
      des: "We handle safe and secure shipping to your location, anywhere in the world. Our experienced team ensures precise and professional installation to achieve the desired aesthetic impact."
    },
    {
      id: 6,
      src: "/img/artcollections",
      heading: "Post-Project Support",
      des: "We remain available for any future adjustments or additional art needs. Additionally, we provide advice on maintaining and preserving your artwork for long-lasting beauty."
    },
  ];

  const testimoniald = [
    {
      id:1,
      src: "/img/event3",
      name: "Aisha Al-Mansoori",
      designation: "Hospitality Design Consultant",
      des: "Art Smiley played a vital role in transforming a luxury hotel project. The curated artworks brought a sense of elegance and personality to the space, perfectly matching the client’s expectations. The team’s dedication to quality and timely delivery was exceptional. I look forward to collaborating with them on future projects.",
    },
    {
      id:2,
      src: "/img/event3",
      name: "Carlos Ortega",
      designation: "Property Developer",
      des: "I approached Art Smiley for a villa project, and the results were phenomenal. Art Smiley team provided art solutions that enhanced the communal areas and added a touch of sophistication to the villa. The residents and potential buyers were thoroughly impressed with the aesthetics. Truly a fantastic partner for developers like me.",
    },
    {
      id:3,
      src: "/img/event3",
      name: "Isabella Conti",
      designation: "Interior Designer",
      des: "Working with Art Smiley has been a fantastic experience. Their wide selection of artwork, paired with their expert guidance, allowed me to bring my client’s vision to life. The custom sculptures and contemporary art perfectly complemented the modern interiors of my project. I’ll definitely be working with them again.",
    },
    {
      id:4,
      src: "/img/event3",
      name: "Mehmet Kaya",
      designation: "Design Specialist",
      des: "Art Smiley’s ability to understand the unique needs of a project is outstanding. For a high-end retail space, they provided bespoke art solutions that elevated the ambiance and left a lasting impression on visitors. Art Smiley’s  professionalism and attention to detail make them an invaluable partner in design.",
    },
    {
      id:5,
      src: "/img/event3",
      name: "Omar El-Sayed",
      designation: "Commercial Design Consultant",
      des: "I’ve collaborated with Art Smiley on several commercial projects, and I can confidently say their art solutions are outstanding. Their selection is diverse and of the highest quality, ensuring every space—from office buildings to retail environments—feels inviting and aligned with the brand. Art Smiley team’s attention to detail and ability to meet deadlines make them an indispensable part of my design process.",
    },
    {
      id:6,
      src: "/img/event3",
      name: "Carlos T",
      designation: "Madrid",
      des: "As an emerging collector, I was looking for affordable yet authentic art. Art Smiley offered a wonderful selection within my budget. I couldn’t be happier with the piece I brought home—it’s added so much character to my space.",
    },
  ];
  const ourArtistCard = [
    {
      id: 1,
      imgUrl: "/img/ourartist1.png",
      artistName: "Maria Cooper",
      position: "Interior Designer",
      des:"Our curators can assist you in uncovering art that you cherish."
    },
    {
      id: 2,
      imgUrl: "/img/ourartist2.png",
      artistName: "Titus Archibald",
      position:"Interior Designer, Lifestylist",
      des:"Our curators can assist you in uncovering art that you cherish."
    },
    {
      id: 3,
      imgUrl: "/img/ourartist3.png",
      artistName: "Winslow Homer",
      position: "Designer, Mississippi Native",
      des:"Our curators can assist you in uncovering art that you cherish."
    },
  ];

    const vrvice_data = [
      {
        id: 1,
        heading: "Inclusive Art Solutions for All",
        des: `<p>At Art Smiley, we believe that art should be accessible, meaningful, and tailored to suit every individual and business. Whether you’re a passionate art collector seeking a standout piece for your personal collection or a corporate buyer or an interior designer looking to elevate your office environment, Art Smiley provides inclusive, curated art solutions for all.</p>`,
      },
      {
        id: 2,
        heading: "Tailored Art Curation for Unique Spaces",
        des: `<p>We take the time to understand your space and style. Whether you’re curating for a modern office, a cozy home, or a luxury hotel, our experts will craft a bespoke art selection that complements your environment.</p>`,
      },
      {
        id: 3,
        heading: "Streamlined End-to-End Service",
        des: `<p>We oversee the entire process from concept to installation. Our team meticulously manages every detail, including framing, logistics, and display, ensuring a seamless experience that allows you to concentrate on transforming your space without any hassle.</p>`,
      },
      {
        id: 4,
        heading: "Focus on Long-Term Art Investment",
        des: `<p>We help you select art for today and offer guidance on building a collection that appreciates over time, making it a valuable investment.</p>`,
      },
      {
        id: 5,
        heading: "Hassle-Free Global Delivery",
        des: `<p>We take care of every detail in the shipping process, from handling customs to ensuring safe international delivery. Wherever you are, your artwork will arrive at your door securely and on time.</p>`,
      },
      {
        id: 6,
        heading: "Complimentary Expert Art Advisory",
        des: `<p>Our free art advisory services connect you with experienced curators who work closely with you to find the perfect art pieces for your space. Our team offers personalized guidance to ensure your selections reflect your style and goals, making the process smooth and stress-free.</p>`,
      },
      {
        id: 7,
        heading: "Budget-Friendly Art Options",
        des: `<p>We believe that art should be accessible to everyone. Whether you’re working with a modest or generous budget, Art Smiley offers affordable yet high-quality options tailored to meet your financial goals, we provide cost-effective solutions from striking prints to captivating sculptures without compromising on quality or aesthetic appeal.</p>`,
      },
      {
        id: 8,
        heading: "Diverse Artwork Selections for Every Need",
        des: `<p>You might seek a minimalist piece for a small office or a large-scale sculpture for a corporate lobby, we’ve got you covered. At Art Smiley, we provide a wide range of options from contemporary artists, including abstract, landscape, figurative paintings, canvas, acrylic, metal prints, and sculptures of various types to suit any setting and purpose. Whatever artwork for your vision, we ensure you’ll find the perfect art to elevate your space.</p>`,
      },
    ];

      const [subscriptionExpanded, setSubscriptionExpanded] = React.useState("excpanel0"); // Default to the first panel open
        const handleSubscriptionChange = (panel) => (event, isExpanded) => {
          setSubscriptionExpanded(isExpanded ? panel : false);
        };
  return (
    <>
      <CssBaseline />
      <section className={styles.hero_main}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
        <div className={`${styles.pageitle} ${styles.max_width}`}>
              <h1>Personalized Art Consultancy</h1>
              <p className={`${styles.withor} ${styles.whitext}`}>Get the help of a curator to select artworks that match your requirements.</p>
              <Link  onClick={() => handleNavigate('/user/art-consultancy/questionnaire')}
              className={styles.apply_btnwht}
            >
              Get in Touch
            </Link>
            </div>
</Container>
        </section>
        <section className={styles.sec_sc}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
        <div className={styles.text_center}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={`${styles.sec_title}`}>Why Partner with Art Smiley for Your Art Consulting Needs</h3>
          </div>
        <Grid container spacing={2} sx={{justifyContent:"space-between"}}>
        <Grid item xs={12} sm={5} md={5}>
         <div className={styles.vintageframeDiv}>
           <img
             className="img-fluid"
             src={ "/img/vintage-wood-frame.png"} alt=""
           />
         </div>
   </Grid>
   <Grid item md={6} xs={12}>
      <div className={` ${styles.accordsc}`}>
      {vrvice_data.map((val, index) => {
      const panelId = `excpanel${index}`; // Dynamic panel ID
      return (
        <Accordion
          key={panelId}
          expanded={subscriptionExpanded === panelId} // Check if the panel is expanded
          onChange={handleSubscriptionChange(panelId)}
          sx={{
            "& .MuiAccordionSummary-contentGutters": {
              margin: "10px 0", // Adjust as needed
            },
            "&.MuiPaper-root": {
              boxShadow: "none",
              borderBottom: "1px solid #D9DBE9",
              marginBottom:"0",
              borderBottomLeftRadius:"0",
              borderBottomRightRadius:"0",
            },
            "&.MuiAccordion-root::before":{
              display:"none",
            },
            "& .Mui-expanded": {
              minHeight:"auto",
              margin:"0 0 10px 0 !important"
            },
            "& .MuiAccordionSummary-expandIconWrapper":{
              background:"#B7B7B7",
              opacity:".3",
              textAlign: "center",
              borderRadius:"8px",
            },
            "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded":{
              background:"#1C1C1C",
              opacity:"1",
              color:"#fff",
            },
            "& .MuiAccordionDetails-root":{
              paddingTop:"0"
            },
          }}// Handle expansion state
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${panelId}-content`}
            id={`${panelId}-header`}
          >
            <h4 className={styles.benfhdgn}>{val.heading}</h4>
          </AccordionSummary>
          <AccordionDetails>
            <div className={styles.textContent} dangerouslySetInnerHTML={{ __html: val.des }}></div>
          </AccordionDetails>
        </Accordion>
      );
    })}
      </div>
          </Grid>
        </Grid>
        </Container>
      </section>
      <section className={styles.sec_shgn}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
        <Grid container spacing={2} sx={{justifyContent:"space-between", alignItems:"center"}}>
        <Grid item xs={12} sm={5} md={5}>
        <div className={styles.leftText}>
              <h4 className={styles.abttitle}>Tailored Art Solutions for Interior Design Projects</h4>
              <p className={styles.prtra}>Art Smiley’s Trade Program offers exceptional benefits for interior designers, architects, space designers, design consultants, and decorators looking to enhance their spaces with unique, original art. Our expansive online collection of artwork, prints, and sculptures etc., are curated to suit a variety of tastes and aspirations, allowing you to discover the perfect pieces for your clients. Art Smiley is here to help you find artwork that elevates the atmosphere and aligns with your client’s vision.</p>
                 <Link className={styles.apply_btn} href="/user/art-consultancy/art-for-interior-designers">Art for interior designers</Link>
              </div>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
              <div className={styles.vintageframeDiv}>
                <img
                  className="img-fluid"
                  src={ "/img/vintage-wood-frame.png"} alt=""
                />
              </div>
        </Grid>
        </Grid>
        </Container>
      </section>
      <section className={styles.sec_sc}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
        <Grid container spacing={2} sx={{justifyContent:"space-between", alignItems:"center"}}>
        
        <Grid item xs={12} sm={6} md={6} order={{ xs: 2, sm: 1 }}>
         
              <div className={styles.vintageframeDiv}>
                <img
                  className="img-fluid"
                  src={ "/img/vintage-wood-frame.png"} alt=""
                />
              </div>
        </Grid>
        <Grid item xs={12} sm={5} md={5} order={{ xs: 1, sm: 2 }}>
        <div className={styles.leftText}>
              <h4 className={styles.abttitle}>Custom Art Solutions for Living, Hospitality, and Healing Environments</h4>
              <p className={styles.prtra}>At Art Smiley, we partner with businesses such as hospitality, commercial, healthcare, and residential projects to deliver exceptional art solutions. Our expertise lies in creating tailored artwork that aligns with each project's unique vision and identity. We craft high-quality art pieces from original paintings and prints to contemporary sculptures that elevate spaces and leave a lasting impression. With end-to-end support from conceptualization to installation we ensure a seamless experience, transforming environments into inspiring, memorable destinations.</p>
                 <Link className={styles.apply_btn} href="/user/art-consultancy/art-smiley-for-business">Art Smiley for Business</Link>
              </div>
        </Grid>
        </Grid>
      

        </Container>
      </section>
      <section className={styles.sec_shgn}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
          
        <Grid container spacing={2} sx={{justifyContent:"space-between", alignItems:"center"}}>
        <Grid item xs={12} sm={5} md={5}>
        <div className={styles.leftText}>
              <h4 className={styles.abttitle}>Collaborate with Expert Art Curators – At No Cost to You</h4>
              <p className={styles.prtra}>Looking to find the perfect artwork for your home, office, or project? With our complimentary art advisory services, our skilled curators will handpick pieces that align seamlessly with your preferences, space, and aesthetic vision. Still searching to furnish a cozy corner or an expensive venue? Art Smiley curator will provide you with a thoughtfully curated online artwork collection, making your selection process effortless and enjoyable.</p>
              <p className={styles.prtra}>This personalized service is designed for both individual art enthusiasts and industry professionals, helping you save time while accessing a diverse range of high-quality art. Unlike other programs, our advisory service comes entirely free of charge.</p>
              <p className={styles.prtra}>If you’d rather explore our curated collections by style, medium, or price range, browse through our wide selection of expertly chosen artworks to discover pieces that resonate with your taste and budget.</p>
               <Link className={styles.apply_btn} onClick={() => handleNavigate('/user/search')}>See Our All Products</Link>
              </div>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
         
              <div className={styles.vintageframeDiv}>
                <img
                  className="img-fluid"
                  src={ "/img/vintage-wood-frame.png"} alt=""
                />
              </div>
        </Grid>
        </Grid>
      

        </Container>
      </section>
      <section className={styles.arconsec}>
          <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
            <Grid container spacing={2} sx={{alignItems:"center"}}>
              <Grid item xs={12} md={8}>
                <h3>Make art selection simple and inspiring-connect with a dedicated curator today!</h3>
              </Grid>
              <Grid item xs={12} md={4}>
                <div className={styles.text_center}>
                <Link  onClick={() => handleNavigate('/user/art-consultancy/questionnaire')} className={styles.apply_btnwht}>Fill the Questionnaire</Link>
                </div>
                
              </Grid>
            </Grid>
          </Container>
        </section>
      <section className={styles.sec_sc}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
        <div className={`${styles.max_width} ${styles.text_center}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={`${styles.sec_title}`}>Art Consulting Process</h3>
          </div>
        <div className={styles.taldartist}>
            <Grid container spacing={2}>
            {exclusive_data.map((val, index) => (
                <Grid item xs={12} sm={4} md={4} key={val.id} >
                  <div  key={index} className={styles.stboxh}>
                <div className={styles.rechhbox}>
                  <div className={styles.sticon}>
                    <p className={styles.hgrd}>{val.id}</p>
                  </div>
                  <h4>{val.heading}</h4>
                  <div className={styles.stcont}>
                     <p>{val.des}</p>
    </div>
                </div>
                </div> 
                </Grid>
          ))}
            
            </Grid>
            <div className={`${styles.text_center} ${styles.max_width1} ${styles.mt_2}`}>
           <p className={styles.withor}>By following this structured process, Art Smiley ensures a seamless and enjoyable experience, transforming your vision into reality through exceptional art curation and consulting services.</p>
          </div>
            </div>
        </Container>
      </section>
      <section className={styles.sec_shgn}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
        <Grid container spacing={2} sx={{justifyContent:"space-between", alignItems:"center"}}>
        
        <Grid item xs={12} sm={6} md={6} order={{ xs: 2, sm: 1 }}>
         
              <div className={styles.vintageframeDiv}>
                <img
                  className="img-fluid"
                  src={ "/img/vintage-wood-frame.png"} alt=""
                />
              </div>
        </Grid>
        <Grid item xs={12} sm={5} md={5} order={{ xs: 1, sm: 2 }}>
        <div className={styles.leftText}>
              <h4 className={styles.abttitle}>Payment Terms for Commissioned Artwork</h4>
              <p className={styles.prtra}>To begin the creation of a commissioned artwork, a 50% upfront payment is required. This deposit secures the artist’s time and resources for crafting your custom piece. The remaining 50% of the payment must be completed prior to the delivery of the finished artwork.</p>
              <p className={styles.prtra}>These payment terms ensure a smooth and efficient process, allowing our team to dedicate the utmost care and attention to creating a masterpiece tailored to your vision.</p>
              </div>
        </Grid>
        </Grid>
        </Container>
      </section>
      <section className={styles.sec_sc}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
        <div className={`${styles.max_width} ${styles.text_center}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={`${styles.sec_title}`}>Cancellation Policy</h3>
          <p className={styles.withor}>We understand that plans can change, and we strive to be flexible. However, due to the personalized nature of commissioned artworks, cancellations can only be made before the production process begins.</p>
            
          </div>
        <div className={styles.taldartist}>
            <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <div className={styles.talhg}>
                <div className={styles.talgbx}>
                
                    <div className={styles.talentcont}>
                      <h4>Before Production</h4>
                      <p>If the cancellation request is submitted before work starts, the initial deposit will be refunded, minus any administrative fees.</p>
                      
                    </div>
                    
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <div className={styles.talhg}>
                <div className={styles.talgbx}>
                
                    <div className={styles.talentcont}>
                      <h4>After Production Begins</h4>
                      <p>Once the artwork is in progress, the 50% deposit becomes non-refundable, as it covers the time, effort, and resources invested in your custom piece.</p>
                      
                    </div>
                    
                </div>
              </div>
            </Grid>
            </Grid>
            <div className={`${styles.text_center} ${styles.max_width1} ${styles.mt_2}`}>
           <p className={styles.withor}>This policy ensures fairness and respect for the creative work of our artists while maintaining a professional and transparent experience for our clients.</p>
          </div>

            </div>
        </Container>
      </section>
      <section className={styles.sec_sc}>
            <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
            <div className={`${styles.text_center} ${styles.max_width}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>Hear from our Clients</h3>
          </div>
          <section className={`testmo-slider ${styles.sections}`}>
          <Swiper
        modules={[Navigation, Autoplay]}
        slidesPerView={1}
        spaceBetween={10}
        loop={true}
        autoplay={{
          delay: 3000, // Set autoplay delay in milliseconds (3 seconds here)
          disableOnInteraction: false, // Allows autoplay to continue after user interactions
        }}
        onInit={(swiper) => {
          swiper.params.navigation.prevEl = prevButtonRef.current;
          swiper.params.navigation.nextEl = nextButtonRef.current;
          swiper.navigation.init();
          swiper.navigation.update();
        }}
        breakpoints={{
          480: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 15,
          },
          1024: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
        }}
      >
{testimoniald.map((val, index) => (
                    <SwiperSlide key={index}>
                      <div className={styles.tehgt}>
                       <div className={styles.testmibox}>
                      <div className={styles.tecont}>
                        <div className={styles.hders}>
                        <div className={styles.testimonial_img_div}>
                          <img
                            src={`${val.src}.png`}
                            width="80px"
                            height="80px"
                            alt=""
                          />
                        </div>
                        <div className={styles.test_metainfo}>
                        <h5 className={styles.test_card_heading}>
                            {capitalizeWords(val.name)}
                          </h5>
                          <h6 className={styles.test_card_subhead}>
                            {capitalizeWords(val.designation)}
                          </h6>
                        </div>
                        </div>
                        <div className={styles.test_cont}>
                          <p className={styles.test_card_des}>{striptags(capitalizeWords(val.des))}</p>
                        </div>
                        </div>
                      </div>
                      </div>
                    </SwiperSlide>
                 ))}
              </Swiper>
              <div className="tesarrow">
              <div ref={prevButtonRef} className="swiper-button-prev">
          <RiArrowLeftLine />
        </div>
        <div ref={nextButtonRef} className="swiper-button-next">
          <RiArrowRightLine />
        </div>
      </div>
        </section>
        </Container>
        </section>
        <section className={styles.arconsec}>
          <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
            <Grid container spacing={2} sx={{alignItems:"center"}}>
              <Grid item xs={12} md={8}>
                <h3>Finding It Hard to Decide?</h3>
                <p  className={`${styles.pytrg}`}>Our curators can assist you in uncovering art that you cherish. Take our art quiz to kickstart your personalized recommendations.</p>
              </Grid>
              <Grid item xs={12} md={4}>
              <div className={styles.text_center}>
              <Link onClick={() => handleNavigate('/user/art-consultancy/questionnaire')} className={styles.apply_btnwht}>Submit the Art Requirements</Link>
              </div>
                
              </Grid>
            </Grid>
          </Container>
        </section>
        <section className={styles.sec_shgn}>
          <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
          <div className={`${styles.text_center} ${styles.max_width}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>Meet Art Smiley Curators</h3>
          </div>
          <div>
          <Swiper
        modules={[Navigation, Autoplay]}
        slidesPerView={1}
        spaceBetween={10}
        loop={true}
        autoplay={{
          delay: 3000, // Set autoplay delay in milliseconds (3 seconds here)
          disableOnInteraction: false, // Allows autoplay to continue after user interactions
        }}
        navigation={{
          prevEl: prevButtonRef.current,
          nextEl: nextButtonRef.current,
        }}
        breakpoints={{
          480: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 15,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
        }}
      >
        {ourArtistCard.map((item) => (
        <SwiperSlide>
          <div className={styles.ourartistcard}>
          <img className={styles.ourartistimg} src={  item.imgUrl} alt=""/>
          <div className={styles.ourartistinfo}>
            <h4>{item.artistName}</h4>
            <p className={styles.tdr}>{item.position}</p>
            <p>{item.des}</p>
          </div>
        </div>
        </SwiperSlide>
        ))}
      </Swiper>
      {/* <div
        ref={prevButtonRef}
        className="swiper-button-prev"
      />
      <div
        ref={nextButtonRef}
        className="swiper-button-next"
      />  */}
          </div>
          </Container>
        </section>
        <section className={styles.sec_sc} id="target-section">
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
        <div className={`${styles.text_center} ${styles.max_width}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>Reach out to us</h3>
          </div>
        <Grid container spacing={2} sx={{justifyContent:"center"}}>
        <Grid item xs={12} sm={8} md={8}>
        <div className={styles.stcgbox}>
                  <Grid container spacing={2} sx={{justifyContent:"center"}}>
                    <Grid item xs={12} sm={12} md={12}>
                                      <div className="form-group1">
                                    <label style={{marginBottom:"0"}}>Fill in the required fields below</label>
                                    </div>
                                    </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group1">
                <label>First Name *</label>
                <input
                  id="outlined-basic"
                  variant="outlined"
                  placeholder=""
                  className="form-control"
                  name="first_name"
                />
                
                </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group1">
                <label>Last Name *</label>
                <input
                  id="outlined-basic"
                  variant="outlined"
                  placeholder=""
                  className="form-control"
                  name="last_name"
                />
                
                </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group1">
                <label>Phone *</label>
                <PhoneInput
                  inputStyle={{ background: "transparent" }}
                  containerStyle={{
                    background: "transparent",
                    width: "100%",
                  }}
                  placeholder="Enter your phone number"
                  country={"in"}
                  name="phone"
                
                />
                </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group1">
                <label>Email *</label>
                <input
                  id="outlined-basic"
                  variant="outlined"
                  placeholder=""
                  className="form-control"

                  name="email"
                />
                </div>
                  </Grid>
                  
                  <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group1">
                <label>Designation *</label>
                <input
                  id="outlined-basic"
                  variant="outlined"
                  placeholder=""
                  className="form-control"
                  name="designation"
                />
                
                </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group1">
                <label>Please specify the project category *</label>
                <select className="form-control">
                  <option>Select the Option</option>
                  <option>Commercial</option>
                  <option>Retail</option>
                  <option>Education</option>
                  <option>Healthcare</option>
                  <option>Hospitality</option>
                  <option>Individual Collector</option>
                  <option>Private Home</option>
                  <option>Residential</option>
                  <option>Other</option>
                </select>
                 
                
                </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                  <div className="form-group1">
                <label>Can you tell us more details about your project requirements ( no. of works needed, style, timeline etc.,) *</label>
                <textarea
                  maxRows={5}
                  minRows={5}
                 className="form-control"
                  // onChange={props.handleChangedata}
                  // value={props.uploadArt.description}
                  name="message"
                  fullWidth
                  placeholder=""
                />
                </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                                      <div className="form-group1">
                                    <label style={{marginBottom:"0",marginTop:"15px"}}>Choose the project location</label>
                                    </div>
                                    </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group1">
                <label>City *</label>
                <input
                  id="outlined-basic"
                  variant="outlined"
                  placeholder=""
                  className="form-control"
                  name=""
                />
                
                </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group1">
                <label>Country *</label>
                <input
                  id="outlined-basic"
                  variant="outlined"
                  placeholder=""
                  className="form-control"
                  name=""
                />
                
                </div>
                  </Grid>
                  
                  
                  <Grid item xs={12} sm={12} md={12}>
                  <div className={styles.text_right}>
               <button
                className={styles.apply_btn}
              >
                Submit
              </button>
               </div>
               <div>
                <p className={styles.sbychsbu}>By completing this form, you consent to receive promotional emails regarding the Art Smiley Trade Program. You may opt out at any time. <Link href="/user/privacypolicy" target="_blank">Privacy Policy</Link> <Link href="/user/termsconditions" target="_blank">Terms and Conditions</Link>.</p>
               </div>
                  </Grid>
                  </Grid>
</div>
          </Grid>
        </Grid>
        
        </Container>
      </section>
    </>
  );
}

export default ArtConsultancy;
