import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
// import Divider from '@mui/material/Divider';
import React from "react";
import styles from "./SixcolimgArtist.module.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import serverimage from "../../../serverConstant";
import homeImage from "../../../constant";
import CircularLoader from "../../../common/loader/Loader";
import dateFormat from "../../../utils/dateFormat";
// import profileSlice, { addData } from '../../../../../State/Features/Artist/profileSlice';

export default function SixcolimgArtist(props) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [threedot_id, setThreedot_id] = React.useState("");
  // const dispatch = useDispatch();
  // const data = useSelector((state)=>state.artistdata);
  

  const loader = useSelector((state) => state.loader);
  const threedots = Boolean(anchorEl);
  const handleClick = (event, id) => {
    props.setArtId(id);
    setAnchorEl(event.currentTarget);
    setThreedot_id(event.target.id);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // const handlePopup = (e) => {
  //   
  //   props.setOpen && props.setOpen(true);
  //   props.setPoptype && props.setPoptype("addfeature");
  // };
  const handleProfilepage = (id, name, row) => {
    const path = window.location.pathname;
    if (path === "/arts") {
      navigate(`/arts/${id}`);
    }
    // props.setPage('profile')
    // props.setPendingProfileData({ artist_name: name });
    // dispatch(addData(row));
  };
  return (
    <div className={styles.mains}>
      <TableContainer
        component={Paper}
        sx={{ backgroundColor: "#F6F6F6", color: "black" }}
      >
        <Table sx={{ width: "100%" }} aria-label="customized table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#bfbbc3" }}>
              {props.head.map((column, i) => (
                <TableCell
                  align="center"
                  sx={{
                    whiteSpace: "nowrap",
                    fontFamily: "DM Sans",
                    fontWeight: "500",
                    fontSize: "16px",
                    height: "21px",
                    color: "#1C1C1C",
                  }}
                  key={i}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {loader.loader ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={8}>
                  <Box className={styles.loader_box}>
                    {" "}
                    <CircularLoader size={60} />
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {props.data && props.data.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={8}>
                    <Box className={styles.loader_box}> No Data Found</Box>
                  </TableCell>
                </TableRow>
              ) : (
                props.data &&
                props.data.map((row, i) => (
                  <TableRow
               
                    value={row.artist_name}
                    style={{ color: "#636365", cursor: "pointer" }}
                    key={i}
                    
                  >
                    {/* <Link to={`/artist/${row.id}`}> */}
                    <TableCell
                       onClick={() =>  navigate(`/user/newestpaintings/${row?.item_id?._id}`)}
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="center"
                      // onClick={() => handleProfilepage(row._id)}
                    >
                      <img
                   
                        src={` ${
                          props.activetab === "gallery_art_pending"
                            ? row?.image
                            : props.activetab === "artist_art_pending"
                            ? row?.image : props.activetab === "artist_art_rejected"
                            ? row?.image
                            : row?.item_id?.image
                        }`}
                        height="42px"
                        width="60px"
                        alt="img"
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="center"
                    >
                      {row.name}
                      {props.activetab === "quoterequest"
                        ? row.first_name + " " + row.last_name
                        : ""}
                    </TableCell>
             
                   {props.activetab !== "artist_art_pending" &&  props.activetab !== "artist_art_rejected" ?     <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="center"
                    >
                        {/* {row?.likes} */}
                      {/* {row.likesViews &&
                        row.likesViews.map((like) =>
                          like.likes.length === 0 ? "0" : like.likes
                        )}
                      {!row?.likesViews?.length ? "" : null} */}
                      {props.activetab === "quoterequest"
                        ? dateFormat(row.updatedAt)
                        : ""}
                    </TableCell>
 : null}
                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="center"
                    >
                      {props.activetab === "quoterequest"
                        ? row.message.slice(0, 20)
                        : row.sale.qty}
                    </TableCell>
                    <TableCell
                        sx={{
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "21px",
                          color: "#35BE43",
                        }}
                        className={styles.column_gallery_artist}
                        align="right"
                      >
                        <div className={styles.column_gallery_artist_true}>
                        {props.activetab === "quoterequest" ? (<>  {row.is_accepted === "Approved" ? (
                            <span className={styles.approved}>Approved</span>
                          ) : row.is_accepted === "Rejected" ? (<><span className={styles.rejected}>Rejected</span></>) : (
                            <>
                            <Button
                              onClick={() => props.handlePopupData(row)}
                              variant="contained"
                              sx={{
                                width: "80px",
                                height: "34px",
                                borderRadius: "4px",
                                marginRight:'5px'
                              }}
                            >
                              Accept
                            </Button>
                            <Button
                              onClick={() => props.handlePopupData(row)}
                              variant="outlined"
                              sx={{
                                width: "80px",
                                height: "34px",
                                borderRadius: "4px",
                              }}
                            >
                              Decline
                            </Button>
                          </>
                          )}</>) : (<>  {row.is_accepted === "Pending" ? (
                            <span className={styles.pending}>Pending</span>
                          ) : (
                            <label className={styles.cancel}>Rejected</label>
                          )}</>)}
                        
                        {props.activetab !== "quoterequest" && <>
                          <Tooltip title="Account settings">
                            <IconButton
                              onClick={(event) => handleClick(event, row._id)}
                              size="small"
                              sx={{ ml: 2 }}
                              aria-controls={
                                threedots ? "account-menu" : undefined
                              }
                              // id={row._id}
                              aria-haspopup="true"
                              aria-expanded={threedots ? "true" : undefined}
                            >
                              <img
                                src={"/img/Menu Icon.png"}
                                height="21.62px"
                                width="4.63px"
                                alt="img"
                                id={row._id}
                              />
                            </IconButton>
                          </Tooltip>
                          <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={threedots}
                            onClose={handleClose}
                            onClick={handleClose}
                            PaperProps={{
                              elevation: 0,
                              sx: {
                                width: "auto",
                                overflow: "visible",
                                boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
                                filter:
                                  "drop-shadow(0px 0px 0px rgba(0,0,0,0.32))",
                                mt: 2.5,
                                "& .MuiAvatar-root": {
                                  width: "120px",
                                  height: "132px",
                                  ml: 0.5,
                                  mr: 1,
                                  // mt:5
                                },
                                "&:before": {
                                  content: '""',
                                  display: "block",
                                  position: "absolute",
                                  top: 0,
                                  // horizontal: 'center',
                                  right: 40,
                                  width: 10,
                                  height: 10,
                                  bgcolor: "background.paper",
                                  transform: "translateY(-50%) rotate(45deg)",
                                  zIndex: 0,
                                },
                              },
                            }}
                            transformOrigin={{
                              horizontal: "center",
                              vertical: "right",
                            }}
                            anchorOrigin={{
                              horizontal: "left",
                              vertical: "center",
                            }}
                          >
                            {/* <MenuItem
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "16px",
                                lineHeight: "12px",
                                fontWeight: "400",
                                fontFamily: "DM Sans",
                              }}
                            >
                              Add in Artsmiley Offer
                            </MenuItem> */}
                            {/* <Divider /> */}
                            {}
                            {props.activetab !== "artist_art_rejected" && (
                              <>
                                <MenuItem
                                  onClick={() => {
                                    props.setOpen(true);
                                  }}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    fontSize: "16px",
                                    lineHeight: "12px",
                                    fontWeight: "400",
                                    fontFamily: "DM Sans",
                                  }}
                                >
                                  Edit Art
                                </MenuItem>

                                <Divider />
                              </>
                            )}

                            <MenuItem
                              onClick={() => props.setOpen(row?._id)}
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "16px",
                                lineHeight: "12px",
                                fontWeight: "400",
                                fontFamily: "DM Sans",
                              }}
                            >
                              Delete Art
                            </MenuItem>
                          </Menu></>}
                        </div>
                      </TableCell>
                    {/* {props.activetab === "quoterequest" ? (
                      <TableCell
                        sx={{
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "21px",
                          color: "#35BE43",
                        }}
                        className={styles.table_column}
                        // align="left"
                      >
                        <div className={styles.quoterequest}>
                          {row.is_accepted && row.is_accepted === "Approved" ? (
                            <span> Accepted</span>
                          ) : row.action === "Rejected" ? (
                            <span className={styles.rejected}> Rejected</span>
                          ) : (
                            <>
                              <Button
                                onClick={() => props.handlePopupData(row)}
                                variant="contained"
                                sx={{
                                  width: "80px",
                                  height: "34px",
                                  borderRadius: "4px",
                                }}
                              >
                                Accept
                              </Button>
                              <Button
                                onClick={() => props.handlePopupData(row)}
                                variant="outlined"
                                sx={{
                                  width: "80px",
                                  height: "34px",
                                  borderRadius: "4px",
                                }}
                              >
                                Decline
                              </Button>
                            </>
                          )}
                        </div>
                      </TableCell>
                    ) : (
                    <>
                    </>
                    )} */}
                  </TableRow>
                ))
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </div>
  );
}
