import React, { useEffect } from "react"
import styles from "./Curatordetails.module.css"
import Tablesfour from "../../Table/Fourcolumn/Tables"
import { IconButton } from "@mui/material"
import TableContainer from "@mui/material/TableContainer"
import Paper from "@mui/material/Paper"
import TableHead from "@mui/material/TableHead"
import Table from "@mui/material/Table"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import TableBody from "@mui/material/TableBody"
import { useNavigate, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { fetchcuratorDetails } from "../../../../../State/api/superadminApi"
import { Box, Link, Grid } from "@mui/material"
import CircularLoader from "../../../../../common/loader/Loader"
import serverimage from "../../../../../serverConstant"
import { IoIosArrowBack } from "react-icons/io";
import { FiTrash2 } from "react-icons/fi";
const approvedhead = [
  {
    id: "artist_name",
    label: "Artist Name",
  },
  {
    id: "number",
    label: "Mobile Number",
  },
  {
    id: "email",
    label: "Email",
  },
  {
    id: "type",
    label: "Type",
  },
]

export default function Curatordetails() {
  const { id } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const loader = useSelector((state) => state.loader)
  const curatorDetailsData = useSelector(
    (state) => state.curatorDetailsData.data
  )
  console.log(curatorDetailsData, "curatorDetailsData--")
  useEffect(() => {
    dispatch(fetchcuratorDetails(id))
  }, [dispatch, id])

  const handleBack = () => {
    navigate("/curators")
  }

  function getFileIcon(fileName) {
    const fileExtension = fileName.split(".").pop().toLowerCase()
    switch (fileExtension) {
      case "pdf":
        return <i className="fa fa-file-pdf-o" style={{ color: "red" }}></i>
      case "doc":
      case "docx":
        return <i className="fa fa-file-word-o" style={{ color: "blue" }}></i>
      default:
        return <i className="fa fa-file-o"></i>
    }
  }

  return (
    <div className={styles.mains}>
     
      <div className={styles.back_button}>
    <Link onClick={handleBack} className={styles.backbn}> <IoIosArrowBack /> <span> Go Back</span></Link>
      </div>
      
      {curatorDetailsData &&
        curatorDetailsData[0]?.curators?.map((cur, i) => (
          <div key={i}>
            <div className={styles.bsrd}>
          <Grid  container spacing={2} sx={{justifyContent:"space-between"}}>
           <Grid item xs={12} sm={5} md={4}>
            <div>
            <h1 className={styles.arndng}>{cur?.curator_name}</h1>
            </div>
           </Grid>
           <Grid item xs={12} sm={5} md={4}>
           <div className={styles.del_img}>
           <IconButton
           sx={{
            "&.MuiIconButton-root":{
              padding:"0",
              fontSize:"initial"
            }
           }}
           > <Link><FiTrash2 className={styles.delicon} /></Link></IconButton>
                {/* <IconButton>
                  <img
                    src={"/img/delete.png"}
                    alt="img"
                    width="20px"
                    height="23px"
                    className={styles.del_ig}
                  />
                </IconButton> */}
              </div>
           </Grid>
           </Grid>
           </div>
             
            <div className={styles.detail1}>
              <div key={i}>
              <p className={styles.odlist}><span>Email :</span> <span>{cur?.email ? cur?.email : "----"}</span></p>
              
              </div>
              
              <p className={styles.odlist}><span>Country :</span> <span>{cur?.country ? cur?.country : "----"}</span></p>
              <p className={styles.odlist}><span>Weekly Earning :</span> <span>{cur.weekly_earning ? cur.weekly_earning : "----"}</span></p>
              <p className={styles.odlist}><span>Phone :</span> <span>{cur.phone ? cur.phone : "----"}</span></p>
              <p className={styles.odlist}><span>Qualification :</span> <span
                  dangerouslySetInnerHTML={{
                    __html:
                      cur && cur.qualification && cur.qualification.length > 100
                        ? cur.qualification.substring(0, 100) + "..."
                        : cur && cur.qualification
                        ? cur.qualification
                        : "",
                  }}
                ></span></p>
              <p className={styles.odlist}><span>Achievement :</span> <span
                  dangerouslySetInnerHTML={{
                    __html:
                      cur && cur.achievement && cur.achievement.length > 100
                        ? cur.achievement.substring(0, 100) + "..."
                        : cur && cur.achievement
                        ? cur.achievement
                        : "",
                  }}
                ></span></p>
              <p className={styles.odlist}><span>ID Proof :</span> <span>{cur.ID_proof ? (
                  <a
                    href={` ${cur.ID_proof}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {getFileIcon(cur.ID_proof)} View ID Proof
                  </a>
                ) : (
                  "----"
                )}</span></p> 
              <p className={styles.odlist}><span>Resume :</span> <span>{cur.CV ? (
                  <a
                    href={` ${cur.CV}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {getFileIcon(cur.CV)} View Resume
                  </a>
                ) : (
                  "----"
                )}</span></p>
             
            </div> 
          </div>
        ))}

      <div className={styles.mainsvb}>
        <TableContainer
          component={Paper}
          sx={{ backgroundColor: "#f6f6f6", color: "black" }}
        >
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow sx={{ backgroundColor: "#bfbbc3" }}>
                {approvedhead.map((column, i) => (
                  <TableCell
                    align={approvedhead.length - 1 === i ? "left" : "left"}
                    sx={{
                      whiteSpace: "nowrap",
                      fontFamily: "DM Sans",
                      fontWeight: "500",
                      fontSize: "16px",
                      height: "21px",
                      color: "#1C1C1C",
                    }}
                    key={i}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {loader.loader ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={8}>
                    <Box className={styles.loader_box}>
                      <CircularLoader size={60} />
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {curatorDetailsData &&
                curatorDetailsData[0]?.curators[0]?.artists.length > 0 ? (
                  curatorDetailsData[0]?.curators?.map(
                    (curator, curatorIndex) =>
                      curator?.artists?.map((artist, artistIndex) => (
                        <TableRow
                          key={`${curatorIndex}-${artistIndex}`}
                          style={{ color: "#636365", height: "30px" }}
                          className={styles.table_row}
                        >
                          <TableCell
                            sx={{
                              fontFamily: "DM Sans",
                              fontStyle: "normal",
                              fontWeight: "400",
                              fontSize: "16px",
                              lineHeight: "21px",
                              color: "#636365",
                            }}
                            className={styles.table_column}
                            align="left"
                          >
                            {artist?.artist_name}
                          </TableCell>
                          <TableCell
                            sx={{
                              fontFamily: "DM Sans",
                              fontStyle: "normal",
                              fontWeight: "400",
                              fontSize: "16px",
                              lineHeight: "21px",
                              color: "#636365",
                            }}
                            className={styles.table_column}
                            align="left"
                          >
                            {artist?.phone}
                          </TableCell>
                          <TableCell
                            sx={{
                              fontFamily: "DM Sans",
                              fontStyle: "normal",
                              fontWeight: "400",
                              fontSize: "16px",
                              lineHeight: "21px",
                              color: "#636365",
                            }}
                            className={styles.table_column}
                            align="left"
                          >
                            {artist?.email}
                          </TableCell>
                          <TableCell
                            sx={{
                              fontFamily: "DM Sans",
                              fontStyle: "normal",
                              fontWeight: "400",
                              fontSize: "16px",
                              lineHeight: "21px",
                              color: "#636365",
                            }}
                            className={styles.table_column}
                            align="left"
                          >
                            {artist?.type}
                          </TableCell>
                        </TableRow>
                      ))
                  )
                ) : (
                  <TableRow>
                    <TableCell colSpan={8}>
                      <Box className={styles.loader_box}>No Data Found</Box>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </div>
    </div>
  )
}