import { FormControl, MenuItem, Select } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchEnquiryData } from "../../../../State/api/superadminApi"
import Tables from "../Table/sixcolumn/Tables"
import styles from "./Enquiries.module.css"
import styled from "styled-components"

const exhibitionhead = [
  {
    id: "artist_name ",
    label: "Inquiry ID",
  },
  {
    id: "enq-date",
    label: "Inquiry Date",
  },
  {
    id: "mobile_number",
    label: "Customer Name",
  },
  {
    id: "email",
    label: "Inquiry Type",
  },
  {
    id: "assign_to",
    label: "Descrtipion",
  },
  {
    id: "type",
    label: "Assign to",
  },
  {
    id: "action",
    label: "Status",
  },
]

export default function Enquiries() {
  const [condition, setcondition] = useState(
   {
    search: "",
    sorting: "",
    filter: "",
   }
  )

  const [data, setData] = useState({ offset: 0, is_open: null })
  const dispatch = useDispatch()
  const enquirydata = useSelector((state) => state.enquirydata)
console.log("enquirydata",enquirydata);

const handleChange = (event) => {
  const value = event.target.value;
  setcondition({...condition , filter:value});
};

const handlesortChange = (event) => {
  const value = event.target.value;
  setcondition({...condition , sorting:value});
};
  
console.log("condition...",condition);

  
  const fetchData = () => {
    dispatch(fetchEnquiryData(data))
  }
  useEffect(() => {
    fetchData()
  }, [data])



  return (
    <div className={styles.mains}>
    <div className={styles.components}>
      <div className={styles.table_header}>
        <FormControl>
          <Select
            value={condition.filter}
            onChange={handleChange}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            sx={{ height: "44px", minWidth: "150px", color: "#636365" }}
            className={styles.selects}
          >
            <MenuItem value="">Sort by name</MenuItem>
            <MenuItem value="accending">"A-Z to a-z" </MenuItem>
            <MenuItem value="decending">"Z-A to z-a" </MenuItem>
          </Select>
        </FormControl>
        <FormControl>
          <Select
            value={condition.sorting}
            onChange={handlesortChange}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            sx={{ height: "44px", minWidth: "150px", color: "#636365" }}
            className={styles.selects}
          >
            <MenuItem value="">Filter</MenuItem>
            <MenuItem value="new">Latest</MenuItem>
            <MenuItem value="old">Oldest</MenuItem>
          </Select>
        </FormControl>
      </div>
   

      <Tables
        activetab="enquiry"
        head={exhibitionhead}
        data={
          enquirydata?.data
            ? enquirydata.data
                .slice() // Create a shallow copy to avoid mutating the original data
                .sort((a, b) => {
                  if (condition.filter === "accending") {
                    return a.customer_name?.localeCompare(
                      b.customer_name || "",
                      undefined,
                      { sensitivity: "base" }
                    );
                  } else if (condition.filter === "decending") {
                    return b.customer_name?.localeCompare(
                      a.customer_name || "",
                      undefined,
                      { sensitivity: "base" }
                    );
                  }
                  return 0; // Default order if no sorting is selected
                })
                .sort((a,b)=>{
                  if(condition.sorting === "new"){
                    return new Date(b.date) - new Date(a.date);
                  }else if (condition.sorting === "old"){
                    return new Date(a.date) - new Date(b.date); // Sort by oldest
                  }
                  return 0; // Default case: no sorting
                })
            : [] // Fallback to an empty array if data is null
        }
      />
    </div>
  </div>
  )
}
