import validator from "validator"
import isEmpty from "./isEmpty"

export const validationForContactUs = ( {   first_name,
    last_name,
    phone,
    email,
    message,
    captcha

}) => {
  let errors = {}

  if (validator.isEmpty(first_name)) {
    errors.first_name = "First name is required"
  }

  if (captcha === false) {
    errors.captcha = "Captcha is required";
  }

  if (validator.isEmpty(last_name)) {
    errors.last_name = "Last name is required"
  }
  if (validator.isEmpty(email)) {
    errors.email = "Email is required"
  } else if(!validator.isEmail(email)){
    errors.email = "Email is invalid"
  }

  if (validator.isEmpty(phone)) {
    errors.phone = "Phone number is required";
  } else if (phone?.slice(0, 2) === "91" && phone?.length !== 12) {
    errors.phone = "Phone number is invalid";
  }

  if(validator.isEmpty(message)){
    errors.message
     = "Message is required"
  }

  return {
    errors,
    isValidAddress: isEmpty(errors),
  }
}
