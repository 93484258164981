import React, { useState } from 'react'
// import './Login.css'
import styles from './forgot.module.css';
import { Button, FormControl, Radio, TextField } from '@mui/material';
import { Box } from '@mui/system';
import homeImage from '../../constant';
import RadioGroup, { useRadioGroup } from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { handleSendOTP } from '../../State/api/superadminApi';
import { useDispatch } from 'react-redux';
import Varifycode from '../Vrification/Varifycode';
import Resetpass from '../Vrification/Resetpass/Resetpass';
import { AlertsSuccess, Errornotify, Successnotify } from '../../common/alert/Alert';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Entermobile from '../../curator/pages/applyForm/mobileNumber/Entermobile';

const StyledFormControlLabel = styled((props) => <FormControlLabel {...props} />)(
    ({ theme, checked }) => ({
        ".MuiFormControlLabel-label": checked && {
            color: theme.palette.primary.main,
        },
    })
);
const usertype = [
    {
        id: 1,
        name: "Superadmin",
        value: "superadmin",
    },
    //  {
    //   id: 2,
    //   name: "Admin",
    //   value: "admin",
    //  },
    {
        id: 3,
        name: "Artist",
        value: "artist",
    },
    {
        id: 4,
        name: "Curator",
        value: "curator",
    },
    {
        id: 5,
        name: "Buyer",
        value: "buyer",
    },
    {
        id: 6,
        name: "Affiliate",
        value: "affiliate",
    },
    {
        id: 6,
        name: "Gallery",
        value: "gallery",
    },
];
export default function Forgot() {
    const [user, setUser] = useState({ role: '', email: "" });
    const [type, setType] = useState('forgot_mail')
    const dispatch = useDispatch();
    const navigate = useNavigate()

    function MyFormControlLabel(props) {
        const radioGroup = useRadioGroup();
        let checked = false;
        if (radioGroup) {
            checked = radioGroup.value === props.value;
        }
        return <StyledFormControlLabel checked={checked} {...props} />;
    }
    MyFormControlLabel.propTypes = {
        value: PropTypes.any,
    };
    const handleChange = (e) => {
        let name = e.target.name;
        let val = e.target.value
        setUser({ ...user, [name]: val });
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(handleSendOTP(user, setType))
    }
    const handleOtpVerify = (value) => {
        const otp = localStorage.getItem("art_mail_otp");
        if (otp === value) {
            Successnotify("Otp Verify Successfully")
            setType("reset_Pass")
            localStorage.setItem("art_mail_dp", "reset_Pass");
        } else {
            Errornotify("Please Enter Right OTP")
        }
    }
    useEffect(() => {
        const mail_dp = localStorage.getItem("art_mail_dp")
        if (mail_dp === "reset_Pass") {
            setType("reset_Pass")
        }
        else if (mail_dp === "otp_verification") {
            setType("otp_verification")
        } else {
            setType("forgot_mail")
        }
        // type = localStorage.getItem("art_mail_dp")
    }, [])


    if (type === 'reset_Pass') {
        return <>
            <AlertsSuccess />
            <Resetpass />
        </>
    } else if (type === 'otp_verification') {
        return <>
            <AlertsSuccess />
            <Varifycode handleOtpVerify={handleOtpVerify} />
        </>
    }
    return (
        <div className={styles.login_main}>
            <AlertsSuccess />
            <div className={styles.left_main}>
                <img alt='img' src={ '/img/Login Image.png'} className={styles.img_login} />
            </div>
            <div className={styles.right_main_f}>
                <div className={styles.forgot_center}>

                    <div className={styles.text_center}>
                        <img alt='img' src={ '/img/updated_logo.png'} className={styles.img_forgot} onClick={() => navigate('/user/')} />
                        <h2 className={styles.heading_forgot}>Forgot Password? </h2>
                    </div>
                    <form className={styles.forgot_form_login} onSubmit={handleSubmit}>
                        {/* <span className={styles.span_input}>Phone</span><br /> */}
                        {/* <input className={styles.input_forgot' value={user.mobile} name='mobile' onChange={handleChange} placeholder='Enter your phone' type='number' /><br /> */}
                        {/* <FormControl sx={{ my: 0, width: { sx: "100%", sm: "120%" } }}>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="role"
                                onChange={handleChange}
                                sx={{
                                    "&, &.Mui-checked": {
                                        color: " #D0D0D0",
                                    },
                                    "& .MuiSvgIcon-root": {
                                        fontSize: 15,
                                    },
                                }}
                                value={user.role}>
                                {usertype &&
                                    usertype.map((val) => (
                                        <MyFormControlLabel
                                            name="role"
                                            value={val.value}
                                            id={val.id}
                                            control={<Radio />}
                                            sx={{ fontSize: "12px" }}
                                            label={<span style={{ fontSize: "12px" }}>{val.name}</span>}
                                        />
                                    ))}
                            </RadioGroup>
                        </FormControl> */}
                        <br />
                        <span className={styles.span_input}>Phone</span>
                        <Entermobile
                // handleChangeNumber={handleChangeNumber}
                type="sign_up"
              />
                        <br />
                        {/* <TextField
                            sx={{
                                color: '#636365',
                                "& .MuiOutlinedInput-notchedOutline": { border: "non" }, "& .MuiInputBase-root": {
                                    height: 35
                                }
                            }} id="outlined-basic" value={user.mobile} name='mobile' onChange={handleChange} placeholder='Enter your phone' type='number' fullWidth variant="outlined" /> */}

                        <Box className={styles.forgot_btn}>
                            <button  className={`${styles.full_width} ${styles.apply_btn} ${styles.opt_btn}`} type='submit' >Send OTP</button>
                        </Box>
                    </form>
                </div>
            </div>
        </div >
    )
}
