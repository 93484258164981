import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Moreoption from "../../../components/Pages/List/Table/Moreoption/Moreoption"
import Tableheader from "../../../components/Pages/List/Table/Tableheader/Tableheader"
import Allartpopup from "../../../gallery/components/popups/allArt/Allartpopup"
import { fetchArtsData, fetchArtsDataForSubscription } from "../../../State/api/superadminApi"
import FivecolimgArtist from "../../commonTables/FivecolimgArtist/FivecolimgArtist"
import SixcolimgArtist from "../../commonTables/SixcolimgArtist/SixcolimgArtist"
import styles from "./Artartist.module.css"
import { deleteArtistArt } from "../../../State/api/artistapi"
import { AlertsSuccess } from "../../../common/alert/Alert"
import { setArtsdata } from "../../../State/Features/superadmin/artsSlice"
import Pagination from "../../../components/Pages/List/Pagination/Pagination"
import PopupForDeleteArt from "../../../gallery/components/popups/allArt/PopupForDeleteArt"
import ArtartistTable from "./ArtartistTable"

const moreOptions = [
  {
    id: "pending",
    label: "Pending",
    value: "artist_art_pending",
  },
  {
    id: "approved",
    label: "Approved",
    value: "artist_art_approved",
  },
  {
    id: "rejected",
    label: "Rejected",
    value: "artist_art_rejected",
  },
]
const headData = [
  {
    id: "customer_id",
    label: "Image",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "buyer_name",
    label: "Name",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "email",
    label: "Views",
    // format: (value) => value.toLocaleString(),
  },
  {
    id: "country",
    label: "Likes",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "item",
    label: "Stock in Qty.",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "newsletter",
    label: "Status",
    format: (value) => value.toLocaleString(),
  },
]
const headDataPending = [
  {
    id: "customer_id",
    label: "Image",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "buyer_name",
    label: "Name",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "buyer_name",
    label: "Buy",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "buyer_name",
    label: "Rent",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "buyer_name",
    label: "Print",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "buyer_name",
    label: "Date",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "item",
    label: "Stock in Qty.",
    format: (value) => value.toLocaleString(),
  },

]
const ratinghead = [
  {
    id: "customer_id",
    label: "Image",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "buyer_name",
    label: "Name",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "email",
    label: "Views",
    // format: (value) => value.toLocaleString(),
  },
  {
    id: "country",
    label: "Likes",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "item",
    label: "Stock in Qty.",
    format: (value) => value.toLocaleString(),
  },
]
const sortdata = [
  { label: "Low To High", value: "low" },
  { label: "High to Low", value: "high" },
]
const filterdata = [
  { label: "Old to new", value: "old" },
  { label: "New to old", value: "new" },
]
export default function Artartist() {
  const [button, setButton] = useState({ type: "add", text: "Add" })
  const [activetab, setAcitvetab] = useState("artist_art_pending")
  const [open, setOpen] = useState(false)
  const [openArtDelete, setOpenArtDelete] = useState(false)
  const [artId, setArtId] = useState("")
  const [data, setData] = useState({ is_accepted: "Pending", offset: 1 })
  const [search, setSearch] = useState("")
  const [condition, setCondition] = useState({ sorting: "", filter: "" })
  const dispatch = useDispatch()
  const artdata = useSelector((state) => state.artsdata.data)

  const handleMorecategories = (e) => {
    setAcitvetab(e)
    if (e === "artist_art_pending") {
      setData({ is_accepted: "Pending", offset: 1 })
    } else if (e === "artist_art_approved") {
      // props.setButton && props.setButton({ type: '', text: '' })
      setData({ is_accepted: "Approved", offset: 1 })
    } else if (e === "artist_art_rejected") {
      // props.setButton && props.setButton({ type: '', text: '' })
      setData({ is_accepted: "Rejected", offset: 1 })
    }
  }
  const handleDelete = (id) => {
    dispatch(deleteArtistArt(id, fetchData))
  }
  const handleFilter = (event) => {
    const value = event.target.value
    if (value === "") {
      setCondition({ ...condition, filter: value })
    } else {
      setCondition({ ...condition, filter: value })
    }
  }
  const handleSearch = (e) => {
    let val = e.target.value
    setSearch(val)
  }
  const fetchData = () => {
    dispatch(fetchArtsData(data))
  }
  useEffect(() => {

    fetchData()
  }, [data])


  const handleSorting = (event) => {
    const value = event.target.value;
  
    if (!artdata?.data || !Array.isArray(artdata.data)) return;
  
    const sortedData =
      value === "low"
        ? [...artdata.data].sort((a, b) => (a.sale?.price || 0) - (b.sale?.price || 0))
        : value === "high"
        ? [...artdata.data].sort((a, b) => (b.sale?.price || 0) - (a.sale?.price || 0))
        : artdata.data;
  
    if (value === "low" || value === "high") {
      setCondition({ ...condition, sorting: value });
      dispatch(
        setArtsdata({
          data: sortedData,
          totalCount: artdata.totalCount,
        })
      );
    }
  };
  

  return (
    <div className={styles.mains}>
      <AlertsSuccess />
    
      {openArtDelete ? <PopupForDeleteArt open={openArtDelete} setOpen={setOpenArtDelete} artId={artId} handleDelete={handleDelete} /> : ""}
      <Tableheader
        setOpen={setOpen}
        handleFilter={handleFilter}
        button={button}
        search={search}
        condition={condition}
        filterdata={filterdata}
        sortdata={sortdata}
        handleSearch={handleSearch}
        handleSorting={handleSorting}
        activetab={activetab}
      />
      <Moreoption
        activetab={activetab}
        handleMorecategories={handleMorecategories}
        moreOptions={moreOptions}
      />
      {activetab && activetab === "artist_art_approved" ? (
        <ArtartistTable
          activetab={activetab}
          head={headDataPending}
          data={ 
          artdata &&
          artdata.data
            .filter((val) => {
              if (search === "") {
                return val;
              } else if (val.name.toLowerCase().includes(search.toLowerCase())) {
                return val;
              }
            })
            .sort((a, b) => {
              if (condition.filter === "new") {
                return new Date(b.createdAt) - new Date(a.createdAt);
              } else if (condition.filter === "old") {
                return new Date(a.createdAt) - new Date(b.createdAt); 
              }
              return 0; // No sorting if no valid condition.filter
            }
      )}
      setArtId={setArtId}
      open={openArtDelete}
      setOpen={setOpenArtDelete}
      handleDelete={handleDelete}
        />
      ) : activetab && activetab === "artist_art_pending" ? (
        <ArtartistTable
          activetab="artist_art_pending"
          head={headDataPending}
          data={
            artdata &&
            artdata.data.filter((val) => {
              if (search === "") {
                return val
              } else if (
                val.name.toLowerCase().includes(search.toLowerCase())
              ) {
                return val
              }
            })
          }
          open={openArtDelete}
          setOpen={setOpenArtDelete}
          setArtId={setArtId}
          handleDelete={handleDelete}
        />
      ) : (
        <ArtartistTable
          activetab="artist_art_rejected"
          head={headDataPending}
          data={
            artdata &&
            artdata.data.filter((val) => {
              if (search === "") {
                return val
              } else if (
                val.name.toLowerCase().includes(search.toLowerCase())
              ) {
                return val
              }
            })
          }
          open={openArtDelete}
          setOpen={setOpenArtDelete}
          setArtId={setArtId}
          handleDelete={handleDelete}
        />
      )}
      <Pagination
        totaldata={artdata && artdata.totalCount}
        pagination={data}
        setPagination={setData}
      />
    </div>
  )
}
