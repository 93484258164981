import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./Tablefivecol.module.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import CircularLoader from "../../../../../common/loader/Loader";
import moment from "moment";
import dayjs from "dayjs";

export default function CuratorGallerycol(props) {
  //console.log("props--curpen", props);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const loader = useSelector((state) => state.loader);
  const [threedot_id, setThreedot_id] = React.useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const threedots = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setThreedot_id(event.target.id);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const usertype = useSelector((state) => state.user.data);




  return (
    <div className={styles.mains}>
      <TableContainer
        component={Paper}
        sx={{ backgroundColor: "#F6F6F6", color: "black" }}
      >
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#bfbbc3" }}>
              {props.head.map((column, i) => (
                <TableCell
                  className={styles.table_head}
                  align={props.head.length - 1 === i ? "center" : "center"}
                  sx={{
                    whiteSpace: "nowrap",
                    fontFamily: "DM Sans",
                    fontWeight: "500",
                    fontSize: "16px",
                    height: "21px",
                    color: "#1C1C1C",

                  }}
                  key={i}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {loader.loader ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={8}>
                  <Box className={styles.loader_box}>
                    {" "}
                    <CircularLoader size={60} />
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {props.data && props.data.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={8}>
                    <Box className={styles.loader_box}> No Data Found</Box>
                  </TableCell>
                </TableRow>
              ) : (
                props.data &&
                props.data
                  .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                  .map((row, i) => (
                    <TableRow
                      style={{ color: "#636365", height: "30px" }}
                      className={styles.table_row}
                      key={row._id}
                    >
                      <TableCell
                        sx={{
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "21px",
                          color: "#636365",
                          whiteSpace: "nowrap",
                        }}
                        style={{ cursor: "pointer" }}
                        className={styles.table_column}
                        align="center"
                      >
                       
                     { row.first_name} {row.last_name}

                        {/* Gallery name */}
                        {row.gallery_name}
                     

    
                      </TableCell>

                      <TableCell
                        sx={{
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "21px",
                          color: "#636365",
                        }}
                        className={styles.table_column}
                        align="center"
                      >
                     
                       
                      {row.phone}
                     
                      </TableCell>

                   
                   
                      <TableCell
                        sx={{
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "21px",
                          color: "#636365",
                        }}
                        className={styles.table_column}
                        align="center"
                      >
                     
                       
                      {row.email}
                     
                      </TableCell>

                      <TableCell
                        sx={{
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "21px",
                          color: "#636365",
                        }}
                        className={styles.table_column}
                        align="center"
                      >
                     
                       
                {   moment(row.createdAt).format("DD-MM-YYYY")}
                     
                      </TableCell>
                  
                      <TableCell
                          sx={{
                            fontFamily: "DM Sans",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "21px",
                            color: "#636365",
                            whiteSpace: "nowrap",
                          }}
                          className={styles.column}
                          align="center"
                        >
                          <Button
                            color={row.status ? "primary" : "primary"}
                            variant="contained"
                            sx={{ width: "80px", mr: 2 }}
                            onClick={() =>
                              props.handleAcceptDecline(row._id, "Approved")
                            }
                          >
                            Accept
                          </Button>
                          <Button
                            color={row.status ? "primary" : "primary"}
                            variant="outlined"
                            sx={{ width: "80px", mr: 0 }}
                            onClick={() =>
                              props.handleAcceptDecline(row._id, "Rejected")
                            }
                          >
                            Decline
                          </Button>
                        
                        </TableCell>
                    
                    </TableRow>
                  ))
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </div>
  );
}
