import React, { useState } from "react";
import styles from "./Artistdetail.module.css";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import homeImage from "../../../../../constant";
import Tables from "../../Table/Table/Tables";
import serverimage from "../../../../../serverConstant";
import dayjs from "dayjs";
import { Button, Link, Grid } from "@mui/material";
import { ToastContainer } from "react-toastify";
import PopupModalSubscription from "../../Popupdelete/PopupModalSubscription";
import TableMarketing from "../../Table/Table/TableMarketing";
import { IoIosArrowBack } from "react-icons/io";
const dt = {
  img: "/img/artist_profile.png",
  name: "Emily Cooper",
  more_details: [
    {
      title: "Views",
      content: "10,456",
    },
    {
      title: "Likes",
      content: "8,456",
    },
    {
      title: "Favorites",
      content: "1,456",
    },
  ],
};
const data = [
  {
    heading: "Description",
    description_details: [
      {
        title: "Mobile Number",
        content: "+91 9998738792",
      },
      {
        title: "Email Adress",
        content: "Artsmiley",
      },
      {
        title: "CV",
        content: "Artsmiley",
      },
      {
        title: "ID Proof ",
        content: "Artsmiley",
      },
      {
        title: "Social Media Link ",
        content: "http:art.com",
      },
      {
        title: "Art Qualification University ",
        content: "20 W * 20 H * 50 D in",
      },
      {
        title: "Degree Year",
        content: "2012",
      },
      {
        title: "Artist Type ",
        content: "Emerging Artist ",
      },
      {
        title: "Assign to",
        content: "Joe Doe",
      },
      {
        title: "Exibition 1",
        content: "Artsmiley",
      },
      {
        title: "Exibition 2 ",
        content: "Art of Space",
      },
      {
        title: "Subscription Plan ",
        content: "Art of Space",
      },
      {
        title: "Porfolio ",
        content:
          "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and mgffdg gfdhfdg",
      },
      {
        title: "Biography ",
        content:
          "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and m",
      },
    ],
  },
  {
    heading: "Bank Details",
    description_details: [
      {
        title: "Bank Name",
        content: "Axis Bank",
      },
      {
        title: "Account Holder Name",
        content: "Emily cooper",
      },
      {
        title: "Account Number",
        content: "238787438493",
      },
      {
        title: "Routing/ABA Number",
        content: "4325324543",
      },
      {
        title: "Bank Location",
        content: "India",
      },
      {
        title: "Account Type",
        content: "Saving Account",
      },
    ],
  },
  // {
  //     heading: "Art Detail",
  // }
];

const headData = [
  {
    id: "image",
    label: "Image",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "user_name",
    label: "User Name",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "sku_id",
    label: "Sku_Id",
    // format: (value) => value.toLocaleString(),
  },
  {
    id: "art_name",
    label: "Art Name",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "buy",
    label: "Buy",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "rent",
    label: "Rent",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "print",
    label: "Print",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "action",
    label: "",
    format: (value) => value.toLocaleString(),
  },
];
export default function Artistdetail(props) {
  console.log(props, "props-artist-");
  const location = useLocation();
  const { active } = location.state || {};
  const navigate = useNavigate();
  const data = useSelector((state) => state.artistdata.data);
  const [openModal, setOpenModal] = useState(false);
  const usertype = useSelector((state) => state.user.data);

  const handlePages = () => {
    const prevpathname = localStorage.getItem("pathname :");
    // navigate(prevpathname);
    // if (location.pathname.includes('/artist/')) {
    //     navigate('/artist');
    // }

    if (location.pathname.includes("/artist/") && usertype.type !== "curator") {
      navigate("/artist");
    } else if (location.pathname.includes("/affiliate/")) {
      navigate("/affiliate");
    } else if (usertype.type === "curator") {
      navigate("/artists");
    }

  };

  const navigateToArtistDetail = (id) => {
    navigate(`/user/artist/${id}`);
  };

  const handlePopup = (data) => {
    setOpenModal(true);
  };
  return (
    <>
      <div className={styles.mains}>
        {openModal ? (
          <PopupModalSubscription open={openModal} setOpen={setOpenModal} />
        ) : (
          ""
        )}
        {props.data &&
          props.data.map((val) => (
            <div className={styles.main_artistprofile}>
             <div className={styles.back_button}>
    <Link onClick={handlePages} className={styles.backbn}> <IoIosArrowBack /> <span> Go Back</span></Link>
      
      </div>
              
              {/* <p> < Back </p> */}
              <div className={styles.profile_detail}>
                <div>
                <div className={styles.bsrd}>
          <Grid  container spacing={2} sx={{justifyContent:"space-between"}}>
           <Grid item xs={12} sm={5} md={4}>
           {dt.img === "" ? (
                    <></>
                  ) : (
                    <>
                      {val?.artists?.map((pic, i) => (
                        <img
                          className="img-fluid"
                          src={pic.image ? pic.image : "/img/profile_alt.jpg"}
                          alt="img"
                        />
                      ))}
                    </>
                  )}
                <div className={styles.arf_in}>
                <h2>
                    {val.first_name} {val.last_name}
                  </h2>
                  <div className={styles.more_details}>
                    {/* {props.dt.more_details &&
                      props.dt.more_details.map((val) => ( */}
                    <div>
                      <h4>Views</h4>
                      <p>{val.views ? val.views : "0"}</p>
                    </div>{" "}
                    <div>
                      <h4>Likes</h4>
                      <p>{val.likes ? val.likes : "0"}</p>
                    </div>{" "}
                    <div>
                      <h4>Favorites</h4>
                      <p>{val.favourites ? val.favourites : "0"}</p>
                    </div>
                    {/* ))} */}
                  </div>
                </div>
           </Grid>
           <Grid item xs={12} sm={5} md={4}>
           <div className={styles.edit_btn}>
                   { usertype.type !== "curator" &&
                  <div>
                   
                    <button
                    className={styles.action_btn}
                      onClick={() => handlePopup()}
                      variant="contained"
                       
                    >
                      Artist Marketing Info
                    </button>
                  </div>
}

                </div>
           </Grid>
           </Grid>
           </div>
                  
                  {/* <img className={styles.profile_img} src={ val.image} /> */}
                  {/* <img className={styles.profile_img} src={ /img/artist_profile.png' /> */}
                  {/* <h2>{data.artist_name}</h2> */}
                  
                </div>
              </div> 
              {props.type === "artist" ? (
                <div className={styles.all_details}>
                   <div className={styles.bdngr}>
                   <Grid  container spacing={2} sx={{justifyContent:"space-between"}}>
                   <Grid item xs={12} sm={5} md={4}><h3 className={styles.sethng}>Description</h3></Grid>
                   <Grid item xs={12} sm={5} md={4}>
                   <div className={styles.edit_btn}>
                   {usertype.type !== "curator" &&
                    <button
                      onClick={() => navigateToArtistDetail(val._id)}
                      variant="contained"
                      className={styles.action_btn}
                    >
                      Artist Details
                    </button>
                    }
                    {/* the below code is for curator panel */}
                    {usertype.type === "curator" && val.is_accepted === "Approved" && 
                    <button
                      onClick={() => navigateToArtistDetail(val._id)}
                      variant="contained"
                      className={styles.action_btn}
                    >
                      Artist Details
                    </button>
                    }
                   </div>
                   
                   </Grid>
                   </Grid>
                   
                   <p className={styles.odlist}><span>Mobile Number :</span> <span>{val.phone ? val.phone : "----"}</span></p>
                   <p className={styles.odlist}><span>Email Address :</span> <span>{val.email ? val.email : "----"}</span></p>
                   <p className={styles.odlist}><span>Country of Residence :</span> <span>{val?.artists?.map((con, i) =>
                          con.country ? con.country : "----"
                        )}</span></p>
                   <p className={styles.odlist}><span>Nationality :</span> <span>{val?.artists?.map((nation, i) =>
                          nation.Nationality ? nation.Nationality : "----"
                        )}</span></p>
                   <p className={styles.odlist}><span>Preffered Language :</span> <span>{val?.artists?.map((lan, i) =>
                          lan.language ? lan.language : "----"
                        )}</span></p>
                   <p className={styles.odlist}><span>Full time professional artist? :</span> <span>{val?.artists?.map((vl, i) =>
                          vl.is_fulltime_artist ? vl.is_fulltime_artist : "----"
                        )}</span></p>
                   <p className={styles.odlist}><span>Year of Experience :</span> <span>{val?.artists?.map((vl, i) =>
                          vl.exp ? vl.exp : "----"
                        )}</span></p>
                   <p className={styles.odlist}><span>Represented by another online art gallery :</span> <span>{val?.artists?.map((vl, i) =>
                          vl.is_another_art_galary_representation
                            ? vl.is_another_art_galary_representation
                            : "----"
                        )}</span></p>
                   <p className={styles.odlist}><span>Please provide your online art gallery url :</span> <span>{val?.artists?.map((vl, i) =>
                          vl.online_art_gallary_URL ? (
                            <a
                              href={vl.online_art_gallary_URL}
                              key={i}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {vl.online_art_gallary_URL}
                            </a>
                          ) : (
                            "----"
                          )
                        )}</span></p>
                   <p className={styles.odlist}><span>Price Range :</span> <span>{val?.artists?.map((vl, i) =>
                          vl.art_price_range[0] ? vl.art_price_range[0] : "----"
                        )}</span></p>
                   <p className={styles.odlist}><span>Sold art past 12 month :</span> <span>{val?.artists?.map((vl, i) =>
                          vl.arts_solds_in_past_12_months
                            ? vl.arts_solds_in_past_12_months
                            : "----"
                        )}</span></p>
                   <p className={styles.odlist}><span>Why you are best? :</span> <span>{val?.artists?.map((vl, i) =>
                          vl.promoting_by_reason
                            ? vl.promoting_by_reason
                            : "----"
                        )}</span></p>
                   <p className={styles.odlist}><span>Work exclusively with art smiley :</span> <span>{val?.artists?.map((vl, i) =>
                          vl.exclusive_work ? vl.exclusive_work : "----"
                        )}</span></p>
                   <p className={styles.odlist}><span>CV :</span> <span>{val?.artists?.map((cv, i) =>
                          cv.CV ? (
                            <a
                              key={i}
                              href={`${cv.CV}`} // Ensure this is the correct backend path to the CV file
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                color: "#636365",
                                textDecoration: "none",
                              }}
                            >
                              Download CV
                            </a>
                          ) : (
                            "----"
                          )
                        )}</span></p>
                   <p className={styles.odlist}><span>ID Proof :</span> <span>{val?.artists?.map((cv, i) =>
                          cv.ID_proof ? (
                            <a
                              key={i}
                              href={` ${cv.ID_proof}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                color: "#636365",
                                textDecoration: "none",
                              }}
                            >
                              Download ID
                            </a>
                          ) : (
                            "----"
                          )
                        )}</span></p>
                   <p className={styles.odlist}><span>Social Instagram Link :</span> <span>{val?.artists?.map((insta, i) =>
                          insta?.social_links?.instagram
                            ? insta?.social_links?.instagram
                            : "----"
                        )}</span></p>
                   <p className={styles.odlist}><span>Art Qualification University :</span> <span>{val.artists[0] ? val.artists[0].qualification : "----"}</span></p>
                   <p className={styles.odlist}><span>Degree Year :</span> <span>
                   {val.artists[0]
                          ? val.artists[0].degree_year === null
                            ? "----"
                            : val.artists[0].degree_year
                          : "----"}</span></p>
                   <p className={styles.odlist}><span>Artist Type :</span> <span>
                   {val.artists[0]
                          ? val.artists[0].artist_type.length > 0
                            ? val.artists[0].artist_type[0].name
                            : "----"
                          : "----"}</span></p>
                   <p className={styles.odlist}><span>Assign to :</span> <span>{val.artists[0]
                          ? val.artists[0].assigned_to.length > 0
                            ? val.artists[0].assigned_to
                            : "----"
                          : "----"}</span></p>
                          {val.exhibition &&
                    val.exhibition.map((item, i) => (
                      <p className={styles.odlist}><span>Exhibition {i + 1} :</span> <span>{val.subscription_plan
                        ? val.subscription_plan
                        : "----"}</span></p>
                      
                    ))}
                   <p className={styles.odlist}><span>Subscription plan :</span> <span>{val.artists[0]
                          ? val.artists[0].subscription_plan.length > 0
                            ? val.artists[0].subscription_plan[0].plan_name
                            : "----"
                          : "----"}</span></p>
                   <p className={styles.odlist}><span>Portfolio :</span> <span>{val.artists[0]
                          ? val.artists[0].portfolio
                            ? val.artists[0].portfolio
                            : "----"
                          : ""}</span></p>
                   <p className={styles.odlist}><span>Biography :</span> <span>{val.artists[0]
                          ? val.artists[0].biography
                            ? val.artists[0].biography
                            : "----"
                          : ""}</span></p>
                   <p className={styles.odlist}><span>About Us :</span> <span>{val.artists[0]
                          ? val.artists[0].about
                            ? val.artists[0].about
                            : "----"
                          : ""}</span></p>
                   <p className={styles.odlist}><span>Professional information :</span> <span>{val.artists[0]
                          ? val.artists[0].art_qualification
                            ? val.artists[0].art_qualification
                            : "----"
                          : ""}</span></p>
                   <p className={styles.odlist}><span>Shipping address :</span> <span>{val.artists[0] ? (
                          val.artists[0].address1 ? (
                            <div>
                              {val.artists[0].address1}
                              {val.artists[0].address2}
                              {val.artists[0].city}
                              {val.artists[0].state}
                              {val.artists[0].zipcode}
                            </div>
                          ) : (
                            "----"
                          )
                        ) : (
                          ""
                        )}</span></p>
                        {active === "approved" && (
                          <p className={styles.odlist}><span>Approved Date :</span> <span>{val.artists[0]
                            ? val.artists[0].approved_date
                              ? dayjs(val.artists[0].approved_date)
                                  .format("YYYY-MM-DD")
                                  .toString()
                              : "----"
                            : ""}</span></p>
                    
                  )}
                  {active === "pending" && (
                    <p className={styles.odlist}><span>Created Date :</span> <span>{val.createdAt
                      ? dayjs(val.createdAt)
                          .format("YYYY-MM-DD")
                          .toString()
                      : "----"}</span></p>
                     
                  )}
                  {active === "featured" && (
                    <p className={styles.odlist}><span>Featured Date :</span> <span>{val.artists[0]
                      ? val.artists[0].featured_date
                        ? dayjs(val.artists[0].featured_date)
                            .format("YYYY-MM-DD")
                            .toString()
                        : "----"
                      : ""}</span></p>
                    
                  )}
                  {active === "decline" && (
                    <p className={styles.odlist}><span>Decline Date :</span> <span>{val.artists[0]
                      ? val.artists[0].approved_date
                        ? dayjs(val.artists[0].approved_date)
                            .format("YYYY-MM-DD")
                            .toString()
                        : "----"
                      : ""}</span></p>
                     
                  )}
                  {active === "recommended" && (
                    <p className={styles.odlist}><span>Recommended Date :</span> <span>
                      {val.artists[0]
                            ? val.artists[0].recommended_date
                              ? dayjs(val.artists[0].recommended_date)
                                  .format("YYYY-MM-DD")
                                  .toString()
                              : "----"
                            : ""}</span></p>
                    
                  )}
                  {active === "exclusive" && (
                    <p className={styles.odlist}><span>Exclusive Date :</span> <span>{val.createdAt
                      ? dayjs(val.createdAt)
                          .format("YYYY-MM-DD")
                          .toString()
                      : "----"}</span></p>
                    
                  )}
                   </div> 
                </div>
              ) : (
                
                <div className={styles.all_details}>
                  <div className={styles.bdngr}>
                   <h3 className={styles.sethng}>Description</h3>
                   <p className={styles.odlist}><span>Name :</span> <span> {val.first_name}
                   {val.last_name}</span></p>
                   <p className={styles.odlist}><span>Mobile Number :</span> <span>{val.phone}</span></p>
                   <p className={styles.odlist}><span>Email Address :</span> <span>{val.email}</span></p>
                   <p className={styles.odlist}><span>Type :</span> <span>{val.type}</span></p>
                   
                   </div> 
                  
                   
                   
                  {/* {val.exhibition && val.exhibition.map((item, i) => (
                                    <div className={styles.description_deatils}>
                                        <div className={styles.des_title}>Exshibition {i + 1} :</div>
                                        <div>
                                            <div className={styles.des_content}>{val.subscription_plan}</div>
                                        </div>
                                    </div>
                                ))}  */}
                 
                </div>
              )}
              {val.bankdetails &&
                val.bankdetails.map((item) => (
                  <div className={styles.all_details}>
                     <div className={styles.bdngr}>
                   <h3 className={styles.sethng}>Bank Details</h3>
                   <p className={styles.odlist}><span>Bank Name :</span> <span>{item.bank_name ? item.bank_name : "N/A"}</span></p>
                   <p className={styles.odlist}><span> Accoun Holder Name :</span> <span>{item.account_holder_name
                            ? item.account_holder_name
                            : "N/A"}</span></p>
                   <p className={styles.odlist}><span>Account Number :</span> <span>{item.account_number ? item.account_number : "N/A"}</span></p>
                   <p className={styles.odlist}><span>Routing/ABA Number :</span> <span>{item.routing_ABA_number
                            ? item.routing_ABA_number
                            : "N/A"}</span></p>
                   <p className={styles.odlist}><span>Bank Location :</span> <span>{item.bank_location ? item.bank_location : "N/A"}</span></p>
                   <p className={styles.odlist}><span>Account Type :</span> <span>{item.account_type ? item.account_type : "N/A"}</span></p>
                   <p className={styles.odlist}><span>Bank :</span> <span>{item.account_type ? item.account_type : "N/A"}</span></p>
                   <p className={styles.odlist}><span>Bank :</span> <span>{item.bank_name ? item.bank_name : "N/A"}</span></p>
                   </div>
                    
                  </div>
                ))}
              {/* {
                            props.data.map((val, i) => (
                                <div className={styles.all_details} key={i}>
                                    <h1>{val.heading}</h1>
                                    {val.description_details.map((item, i) => (
                                        <div key={i} className={styles.description_deatils}>
                                            <div className={styles.des_title}>{item.title} :</div>
                                            {Array.isArray(item.content) ? <div>
                                                {item.content.map((ct, i) => (
                                                    <div className={styles.des_content} key={i}>
                                                        {ct.content}
                                                    </div>
                                                ))}
                                            </div> : <div>
                                                <div className={styles.des_content}>{item.content}</div>
                                            </div>}
                                        </div>
                                    ))}
                                    <hr />
                                </div>
                            ))
                        } */}
              <div className={styles.kh}>
              <h3 className={styles.sethng}>All Arts</h3>
                <Tables headData={headData} data={val.items} />
              </div>
              <br></br>
              <div className={styles.kh}>
              <h3 className={styles.sethng}>Artist Marketing Information</h3>
                <TableMarketing data={val.artist_marketing} />
              </div>
            </div>
          ))}
        {/* <Tables headData={headData} data={rows} /> */}
      </div>
      <ToastContainer />
    </>
  );
}
