import { Button, Typography } from "@mui/material";
import React, {  useState } from "react";

import styles from "./CuratorExhiitionCard.module.css";
import dateFormat from "../../../../../utils/dateFormat";
import { acceptDeclineExshibitionArt, fetchExhibitionArtData } from "../../../../../State/api/superadminApi";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";



export default function CuratorExhibitionDetailsCard(props) {
 
  const dispatch = useDispatch()

  


  const handleAccept = (id) => {
    dispatch(
      acceptDeclineExshibitionArt(id, { is_accepted: "Approved" }, () => dispatch(fetchExhibitionArtData(props.id)))
    );
  };
  
  const handleReject = (id) => {
    dispatch(
      acceptDeclineExshibitionArt(id, { is_accepted: "Rejected" }, () => dispatch(fetchExhibitionArtData(props.id)))
    );
  };
     const navigate = useNavigate()
   
   const handlePages = () => {
  
      navigate("/exhibitions")
    }
  return (
    <div className={styles.mains}>
     <div className={styles.main_artistprofile}>
        <img
                src={"/img/_ Back.png"}
                onClick={handlePages}
                className={styles.backimage}
                height="13px"
                width="46px"
                alt="img"
              />
              </div>
      {props.type === "curator_exhibition" ? (
        ""
      ) : (
        <>
          <h1 className={styles.main_heading}>
        
          </h1>
          <hr />
        </>
      )}
      <div className={styles.detail_id}>
      <div>
                 
                    
                       

                    
      <div className={styles.more_detail_child} style={{display:'flex',alignItems:'center'}}>
      <h3 >Exhibition Title:</h3>

                   <div style={{marginLeft:"10px", 'fontWeight':'800'}} className={styles.content}>
  {props?.data?.data?.exhibition?.title}
</div>

                 </div>
                 <div className={styles.more_detail_child}>
                   <div className={styles.title}>Concept:</div>

                   <div style={{marginLeft:"10px"}} className={styles.content}>
  {props?.data?.data?.exhibition?.concept}
</div>

                 </div>
                 <div className={styles.more_detail_child}>
                   <div className={styles.title}>Created Date:</div>

                   <div className={styles.content}>{dateFormat(props.data?.data?.exhibition?.createdAt)} </div>
                 </div>
                 <div className={styles.more_detail_child}>
                   <div className={styles.title}>Start Date:</div>

                   <div className={styles.content}>{dateFormat(props.data?.data?.exhibition?.start_date)} </div>
                 </div>
                 <div className={styles.more_detail_child}>
                   <div className={styles.title}>End Date:</div>

                   <div className={styles.content}>{ dateFormat(props.data?.data?.exhibition?.end_date || "")} </div>
                 </div>
                 <div className={styles.more_detail_child} >
                   <div className={styles.title}>Application End Date:</div>

                   <div className={styles.content}>{ dateFormat(props.data?.data?.exhibition?.end_date_registration)} </div>
                 </div>
                
               </div>
               </div>
      <div className={styles.detail_id}>
        <h1>No. of Items</h1>
        <p>{props.data === null ? 0 : props.data?.data?.data.length === 0 ? "0" : props.data?.data?.data.length}</p>
      </div>
      {/* <hr /> */}
      {props.data === null ?
        <h2 align="center">No Data Found</h2>
        : props.data.data && props.data.data.data.length === 0 ? (
          <h2 align="center">No Data Found</h2>
        )
          : (
           
            props.data &&
            props.data?.data?.data &&
            props.data?.data?.data?.map((item, i) => (
              <div key={i}>
                <hr className={styles.hrs} />
                <div className={styles.card_main_content}>
                  <div className={styles.quoterequest}>
                   <>
                                           {item?.is_accepted === "Pending" &&
                                           <>
                                                <Button
                                                onClick={() => handleAccept(item._id)}
                                               
                                                  variant="contained"
                                                  sx={{
                                                    width: "80px",
                                                    height: "34px",
                                                    borderRadius: "4px",
                                                  }}
                                                >
                                                  Accept
                                                </Button>
                                                <Button
                                                   onClick={() => handleReject(item._id)}
                                                  variant="outlined"
                                                  sx={{
                                                    width: "80px",
                                                    height: "34px",
                                                    borderRadius: "4px",
                                                  }}
                                                >
                                                  Decline
                                                </Button>
                                                </>
}

{item?.is_accepted === "Approved" && (
  <Typography
    variant="h6"
    sx={{
      color: "green",
      width: "80px",
      height: "34px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "4px",
      fontWeight: 'bold',
      fontSize:'15px'
    }}
  >
    Approved
  </Typography>
)}

{item?.is_accepted === "Rejected" && (
  <Typography
    variant="h6"
    sx={{
      color: "red",
      width: "80px",
      height: "34px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "4px",
      fontWeight: 'bold',
      fontSize:'15px'
    }}
  >
    Rejected
  </Typography>
)}
                                              </>

                 

                  </div>
                  <div className={styles.more_detail}>
                    <div>
                      {item.item.map((val, i) => (
                        <div>
                          <h1 className={styles.art_name}>{val.name}</h1>
                          <div>
                           <Link to={`/user/newestpaintings/${val._id}`}> <img src={ val.image} height="85px" width="121px" alt='img' />{" "} </Link>
                          </div>
                          {localStorage.getItem('usertype') === 'gallery' ?
                            <div className={styles.more_detail_child}>
                              <div className={styles.title}>Artist Name:</div>
                              <div className={styles.content}>{item?.artist}</div>
                            </div> : ""}
                          <div className={styles.more_detail_child}>
                            <div className={styles.title}>Price:</div>
                            <div className={styles.content}>${item.price}</div>
                          </div>
                          <div className={styles.more_detail_child}>
                            <div className={styles.title}>Material:</div>
                            <div className={styles.content}>{val.material && val.material.map(c => <>{c.name}, </>)}</div>
                          </div>
                          <div className={styles.more_detail_child}>
                            <div className={styles.title}>Category:</div>
                            <div className={styles.content}>{val.category}</div>
                          </div>
                          <div className={styles.more_detail_child}>
                            <div className={styles.title}>Dimension:</div>
                            <div className={styles.content}>{val.width} W x {val.height} H x {val.depth} D in cm</div>
                          </div>
                      
                        </div>
                      ))}
                    </div>
                    {/* {item.action ===  } */}
                    <div className={styles.action}>
                      <p className={styles.remark_text}>{item.remark}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
    </div>
  );
}
