import validator from "validator"
import isEmpty from "./isEmpty"



export const validationForAuctionArts = (auction) => {
  let auctionErrors = {}

  if (validator.isEmpty(auction.item_id)) {
    auctionErrors.item_id = "Artwork is required"
  }

  if (validator.isEmpty(auction.item_price)) {
    auctionErrors.price = "Price is required"
  }
  if (validator.isEmpty(auction.description)) {
    auctionErrors.description = "Description is required"
  }
  return {
    auctionErrors,
    isValid: isEmpty(auctionErrors),
  }
}
