import React, { lazy, Suspense, useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Home from "./common/home/Home";
import Artdetails from "./components/Pages/List/Artpage/Artdetails/Artdetails";
import Admindisputegallery from "./gallery/pages/adminDispute/Admindisputegallery";
import Disputedetailgallery from "./gallery/pages/adminDispute/detail/Disputedetailgallery";
import Auctiondetailgallery from "./gallery/pages/auction/detail/Auctiondetailgallery";
import Editprofilesecond from "./gallery/pages/editProfile/stepTwo/Editprofilesecond";
import Exshibitiondetailgallery from "./gallery/pages/exshibition/Exshibitiondetailgallery/Exshibitiondetailgallery";
import Galleryuploadart from "./gallery/pages/uploadArt/Galleryuploadart";
import UserMain from "./UserMain";
import { useDispatch, useSelector } from "react-redux";
import { Errornotify } from "./common/alert/Alert";
import Firstuploadartist from "./artist/pages/uploadArt/firstUpload/Firstuploadartist";
import Seconduploadartist from "./artist/pages/uploadArt/secondUpload/Seconduploadartist";
import Digitalart from "./artist/pages/uploadArt/thirdUpload/thirdSecond/digitalart/Digitalart";
import api from "./State/api/Api";
import { setEditArtId } from "./State/Features/common/editArtIdSlice";
import BuyerArtist from "./artist/pages/buyer/Buyerartist";
import Artistquoterequest from "./artist/pages/quoteRequest/Artistquoterequest"
import Recreateartist from './artist/pages/recreate/Recreateartist'
import Artartist from "./artist/pages/allArts/Artartist";
import Orderartist from "./artist/pages/order/Orderartist";
import Orderartistdetail from "./artist/pages/order/details/Orderartistdetail";
import Adminoffer from "./artist/pages/adminOffer/Adminoffer";
import Totalenquiryartist from "./artist/pages/enquiry/Totalenquiryartist";
import Disputartist from "./artist/pages/disputeManagement/Disputeartist";
import Exshibitionartist from "./artist/pages/exshibition/Exshibitionartist";
import Auctionartist from "./artist/pages/auction/Auctionartist";
import Auctionartistdetail from "./artist/pages/auction/details/Auctionartistdetail";
// import OrderDetailgallery from './gallery/pages/orders/details/OrderDetailgallery';
const OrderDetailgallery = lazy(() =>
  import("./gallery/pages/orders/details/OrderDetailgallery")
);
const OrderGallery = lazy(() => import("./gallery/pages/orders/OrderGallery"));
const Earninggalery = lazy(() =>
  import("./gallery/pages/earnings/Earninggalery")
);
const Exshibitiongallery = lazy(() =>
  import("./gallery/pages/exshibition/Exshibitiongallery")
);
const Auctiongallery = lazy(() =>
  import("./gallery/pages/auction/Auctiongallery")
);
const Enquirygallery = lazy(() =>
  import("./gallery/pages/totalEnquiry/Enquirygallery")
);
const Galleryadminoffer = lazy(() =>
  import("./gallery/pages/adminOffer/Galleryadminoffer")
);
const Allartsgallery = lazy(() =>
  import("./gallery/pages/allArts/Allartsgallery")
);
const Fillquestiongallery = lazy(() =>
  import(
    "./gallery/pages/apply/fillQuestions/fillQuestions/Fillquestiongallery"
  )
);
const Questionhome = lazy(() =>
  import("./gallery/pages/apply/fillQuestions/questionHome/Questionhome")
);
const Galleryapplyform = lazy(() =>
  import("./gallery/pages/apply/from/Galleryapplyform")
);
const Gallerybuyeroffer = lazy(() =>
  import("./gallery/pages/buyer/Gallerybuyeroffer")
);
const Bankdetailgallery = lazy(() =>
  import("./gallery/pages/dashboard/bankDetails/Bankdetailgallery")
);
const Gallerydashboard = lazy(() =>
  import("./gallery/pages/dashboard/Gallerydashboard")
);
const Gallerynews = lazy(() => import("./gallery/pages/news/Gallerynews"));
const Galleryquoterequest = lazy(() =>
  import("./gallery/pages/quoteRequest/Galleryquoterequest")
);
const Galleryrecrete = lazy(() =>
  import("./gallery/pages/recrete/Galleryrecrete")
);
const Gallerysubscription = lazy(() =>
  import("./gallery/pages/subscription/Gallerysubscription")
);
const Notificationgallery = lazy(() =>
  import("./gallery/pages/notification/Notificationgallery")
);
const Editprofilegallery = lazy(() =>
  import("./gallery/pages/editProfile/Editprofilegallery")
);

export default function Gallerymain() {
  const dispatch = useDispatch()
   const [category, setCategory] = useState("")
  const [src, setSrc] = useState({ main: "", multi: [] });
    const [selectedFiles, setSelectedFiles] = useState([]);
  const [artsData, setArtsData] = useState(null)
  console.log(artsData,'artsData')
  const user = useSelector((state) => state.user.data);
  const editArtId = useSelector((state) => state.editArtId?.id);
  const [materialId, setMaterialId] = useState(null);
  const [stylesId, setStylesId] = useState(null);
  const [mediumId, setMediumId] = useState(null)
  const [uploadArt, setUploadArt] = useState({
    name: "",
    artist_name: "",
    sale: { 
    qty: "1", 
    price: "",
    rolled: [{ rolled_size: "", rolled_price: "" }],
    artsmiley_commission:"",
    artist_profit:"",
    price_of_collector:"", 
    sale_weight:""
  },
    rent: { time: "1 Month", rent_price: "",
    artsmiley_commission_rent:"",
    artist_profit_rent:"",
    price_of_collector_rent:"",
    rent_weight:""
   },
    print: [{
      edition: "Limited",
      qty: "1",
      size: {},
      print_price: "",
      material: "",
      artsmiley_commission_print:"",
      artist_profit_print:"",
      price_of_collector_print:"",
      print_weight:""
    }],
    print_by_artsmiley: [{
      edition: "Open",
      qty: "1",
      size: {},
      print_price: "",
      material: "",
     metal_thikness: "",
     product: "",
     product_price: "",
     product_commission: ""
    }],
     print_by_artsmiley_material: [],
    medium_id: [],
    image: "",
    printImage:"",
    copyright: "Yes",
    is_showcased: "No",
    subject_id: "",
    style_id: [],
    orientation: "",
    colors: [],
    category_id: "",
    material_id: [],
    width: "",
    height: "",
    depth: "",
    is_hanged: "No",
    is_signed: "Yes",
    keywords: [],
    tags: "",
    description: "",
    images: [],
    year: null,
    item_type: "",
  });
console.log(uploadArt,'uploadArt.image');
  const handleChangedata = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "width") {
      if (value > 1500) {
        Errornotify("Please Enter width less then 1500")
      } else {
        setUploadArt({ ...uploadArt, [name]: value });
      }
    } else if (name === "height") {
      if (value > 1500) {
        Errornotify("Please Enter height less then 1500")
      } else {
        setUploadArt({ ...uploadArt, [name]: value });
      }
    }
    else {
      setUploadArt({ ...uploadArt, [name]: value });
    }
  };
  const handleChangeOrientation = (selectedValue) => {

    setUploadArt({ ...uploadArt, orientation: selectedValue.value });
  }
  const handleChangedataCategories = (selectedOptions,name) => {
    selectedOptions.forEach((selectedOption) => {
      const { value } = selectedOption;
 
      if (name === "material_id") {
        const material = [...uploadArt.material_id];
        const index = material.findIndex((val) => val._id === value);
        if (index === -1) {
          material.push({ _id: value });
          setUploadArt({ ...uploadArt, material_id: material });
        }
      } else if (name === "style_id") {
        const style_id = [...uploadArt.style_id];
        const index = style_id.findIndex((val) => val._id === value);
        if (index === -1) {
          style_id.push({ _id: value });
          setUploadArt({ ...uploadArt, style_id: style_id });
        }
      } else {
        const medium_id = [...uploadArt.medium_id];
        const index = medium_id.findIndex((val) => val._id === value);
        if (index === -1) {
          medium_id.push({ _id: value });
          setUploadArt({ ...uploadArt, medium_id: medium_id });
        }
      }
    });
  };
  const fetchArtById = async () => {
    try { 
      const response = await api.applicationApi(`api/user/allitems/${editArtId?.id}`,"GET");
     // Extract the first item from the response data
     const artData = response.data.artwork[0];

   setArtsData(artData)
     // Set the state with the response data
    
    }catch(error) {
  
    }
  }
    useEffect(() => {
     
      setUploadArt({
        name: artsData?.name || "",
        artist_name: artsData?.artist_name || "",
        sale: {
          qty: artsData?.sale?.qty || "1",
          price: artsData?.sale?.price || "",
          rolled: artsData?.sale?.rolled?.map(rolledItem => ({
            rolled_size: rolledItem?.rolled_size || "",
            rolled_price: rolledItem?.rolled_price || ""
          })) || [],
          artsmiley_commission: artsData?.sale?.artsmiley_commission || "",
          artist_profit: artsData?.sale?.artist_profit || "",
          price_of_collector: artsData?.sale?.price_of_collector || "",
          sale_weight: artsData?.sale?.sale_weight || ""
        },
        rent: {
          time: artsData?.rent?.time || "1 Month",
          rent_price: artsData?.rent?.rent_price || "",
          artsmiley_commission_rent: artsData?.rent?.artsmiley_commission_rent || "",
          artist_profit_rent: artsData?.rent?.artist_profit_rent || "",
          price_of_collector_rent: artsData?.rent?.price_of_collector_rent || "",
          rent_weight: artsData?.rent?.rent_weight || ""
        },
        print: artsData?.print?.map(printItem => ({
          edition: printItem?.edition || "Limited",
          qty: printItem?.qty || "1",
          size: printItem?.size || {},
          print_price: printItem?.print_price || "",
          material: printItem?.material || "Fine Art Paper",
          artsmiley_commission_print: printItem?.artsmiley_commission_print || "",
          artist_profit_print: printItem?.artist_profit_print || "",
          price_of_collector_print: printItem?.price_of_collector_print || "",
          print_weight: printItem?.print_weight || ""
        })) || [],
        print_by_artsmiley: artsData?.print_by_artsmiley?.map(printItem => ({
          edition: printItem?.edition || "Limited",
          qty: printItem?.qty || "1",
          size: printItem?.size || {},
          print_price: printItem?.print_price || "",
          material: printItem?.material || "",
          metal_thikness: printItem?.metal_thikness || "",
          product: printItem?.product || "",
          product_price: printItem?.product_price || "",
          product_commission: printItem?.product_commission || ""
        })) || [],
        print_by_artsmiley_material: artsData?.print_by_artsmiley_material || [],
      
        image: window.location.pathname !== "/upload/stepfirst" ? artsData?.image : "",
        printImage: artsData?.printImage || "",
        copyright: artsData?.copyright || "Yes",
        is_showcased: artsData?.is_showcased || "No",
        subject_id: artsData?.subject_id?._id || "",
     
        orientation: artsData?.orientation_id?._id || "",
        colors: artsData?.color_id?.map(color => color?._id?._id) || [],
        category_id: artsData?.category_id?._id || "",
        
        medium_id: artsData?.medium_id.map(medium => ({
          _id: medium?._id?._id || ''
      })).filter(item => item._id) || [],
      material_id: artsData?.material_id.map(material => ({
          _id: material?._id?._id || ''
      })).filter(item => item._id) || [],
      style_id: artsData?.style_id.map(style => ({
          _id: style?._id?._id || ''
      })).filter(item => item._id) || [],
  
        width: artsData?.width || "",
        height: artsData?.height || "",
        depth: artsData?.depth || "",
        is_hanged: artsData?.is_hanged || "No",
        is_signed: artsData?.is_signed || "Yes",
     keywords: artsData?.keywords
    ? artsData.keywords.split(',').map(keyword => keyword.trim()).filter(keyword => keyword !== '')
    : [],
        tags: artsData?.tags || "",
        description: artsData?.description || "",
        images: artsData?.images || [],
        year: artsData?.year || null,
        item_type: artsData?.item_type || ""
      });
      const material = artsData?.material_id.map((material) => material?._id?._id).join("");
      console.log(material,'material')
       
    }, [artsData]);
  useEffect(() => {
   if(materialId ||  mediumId || stylesId){
      setUploadArt(prevState => ({
        ...prevState,
        material_id: materialId,
        medium_id: mediumId,
        style_id: stylesId
      }))
   }
  },[materialId,mediumId,stylesId])
  useEffect(() => {
        if(window.location.pathname === "/upload/stepfirst"){ 
          setSrc({...src, main: ''})
          dispatch(setEditArtId({id: null}))
        }
  },[window.location.pathname])
  useEffect(() => {
 
     if(editArtId?.id){
    
        fetchArtById()
       }else {
        setUploadArt({
          name: "",
          artist_name: "",
          sale: { 
            qty: "1", 
            price: "",
            rolled: [{ rolled_size: "", rolled_price: "" }],
            artsmiley_commission:"",
            artist_profit:"",
            price_of_collector:"", 
            sale_weight:""
          },
          rent: { 
            time: "1 Month", 
            rent_price: "",
            artsmiley_commission_rent:"",
            artist_profit_rent:"",
            price_of_collector_rent:"",
            rent_weight:""
          },
          print: [{
            edition: "Limited",
            qty: "1",
            size: {},
            print_price: "",
            material: "Fine Art Paper",
            artsmiley_commission_print:"",
            artist_profit_print:"",
            price_of_collector_print:"",
            print_weight:""
          }],
          print_by_artsmiley: [{
            edition: "Limited",
            qty: "1",
            size: {},
            print_price: "",
            material: "",
            metal_thikness: "",
            product: "",
            product_price: "",
            product_commission: ""
          }],
          print_by_artsmiley_material: [],
          medium_id: [],
          image: "",
          printImage:"",
          copyright: "Yes",
          is_showcased: "No",
          subject_id: "",
          style_id: [],
          orientation: "",
          colors: [],
          category_id: "",
          material_id: [],
          width: "",
          height: "",
          depth: "",
          is_hanged: "No",
          
          is_signed: "Yes",
          keywords: [],
          tags: "",
          description: "",
          images: [],
          year: null,
          item_type: ""
        });
    
     }
  
  },[editArtId?.id])
  return (
    <Routes>
      {user.is_accepted === "Pending" ? (
        <>
        <Route path="*" element={<UserMain />} />
          <Route path="/home" element={<Home user="Gallery" />} />
        </>
      ) : (
        <>
          <Route path="/dashboard" element={<Gallerydashboard />} />
          <Route path="/bankdetail" element={<Bankdetailgallery />} />
          <Route path="/galleryprofile" element={<Allartsgallery />} />
          <Route path="/arts" element={<Artartist />} />
          <Route path="/news" element={<Gallerynews />} />
          <Route path="/subscriptions" element={<Gallerysubscription />} />
          <Route path="/buyeroffer" element={<BuyerArtist />} />
      <Route path="/quoterequest" element={<Artistquoterequest />} />
      <Route path="/recreate" element={<Recreateartist />} />
          <Route path="/adminoffer" element={<Adminoffer />} />
          <Route path="/earnings" element={<Earninggalery />} />
          <Route path="/notification" element={<Notificationgallery />} />
          <Route path="/profile" element={<Editprofilegallery />} />
          <Route path="/inquiry" element={<Totalenquiryartist />} />
          <Route path="/exhibitionsgallery" element={<Exshibitionartist />} />
          <Route path="/auctions" element={<Auctionartist />} />
          <Route path="/orders" element={<Orderartist />} />
          <Route path="/dispute" element={<Disputartist />} />

          <Route path="/orderdetail/:id" element={<Orderartistdetail />} />
          <Route path="/dispute/:id" element={<Disputedetailgallery />} />

          {/* <Route path="/home" element={<Home />} /> */}
          <Route
            path="/upload/stepfirst"
            element={
              <Firstuploadartist
                uploadArt={uploadArt}
                setUploadArt={setUploadArt}
                handleChangedata={handleChangedata}
                src={src}
                setSrc={setSrc}
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
              />
            }
          />
          <Route
            path="/upload/stepsecond"
            element={
              <Seconduploadartist
                uploadArt={uploadArt}
                setUploadArt={setUploadArt}
                handleChangedata={handleChangedata}
                handleChangedataCategories={handleChangedataCategories}
                src={src.main}
                handleChangeOrientation={handleChangeOrientation}
                category={category}
                  setCategory={setCategory}
              />
            }
          />
          <Route
            path="/upload/stepthird"
            element={
              <Digitalart
                uploadArt={uploadArt}
                setUploadArt={setUploadArt}
                handleChangedata={handleChangedata}
                category={category}
              // src={src.main}
              />
            }
          />
           <Route
            path="/edit/stepfirst"
            element={
              <Firstuploadartist
                uploadArt={uploadArt}
                setUploadArt={setUploadArt}
                handleChangedata={handleChangedata}
                src={src}
                setSrc={setSrc}
              />
            }
          />
          <Route
            path="/edit/stepsecond"
            element={
              <Seconduploadartist
                uploadArt={uploadArt}
                setUploadArt={setUploadArt}
                handleChangedata={handleChangedata}
                handleChangedataCategories={handleChangedataCategories}
                src={src.main}
                handleChangeOrientation={handleChangeOrientation}
                  category={category}
                  setCategory={setCategory}
              />
            }
          />
          <Route
            path="/edit/stepthird"
            element={
              <Digitalart
                uploadArt={uploadArt}
                setUploadArt={setUploadArt}
                handleChangedata={handleChangedata}
                category={category}
              // src={src.main}
              />
            }
          />
          <Route path="/uploadarts" element={<Galleryuploadart />} />
          <Route path="/editprofilesecond" element={<Editprofilesecond />} />
          <Route path="*" element={<UserMain />} />

          <Route path="/arts/:id" element={<Artdetails />} />
          <Route
            path="/exhibitionsgallery/:id"
            element={<Exshibitiondetailgallery />}
          />
          <Route path="/auctions/:id" element={<Auctionartistdetail />} />
        </>
      )}

      {/* <Route path='*' element={<Navigate to="/" />} /> */}
      {/* <Route path='/galleryapplyhome' element={<Questionhome />} />
                <Route path='/galleryfillform' element={<Fillquestiongallery />} />
                <Routes path='/galleryapplyform' element={<Galleryapplyform />} /> */}
    </Routes>
  );
}
