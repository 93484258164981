import React, { useState } from "react";
import styles from "./Entermobile.module.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export default function Entermobile(props) {
  const [mobile, setMobile] = useState();

  const handleChangeCode = (e) => {
    setMobile(e);
    console.log(e);
  };
  if (props.type === "sign_up") {
    return (
      <PhoneInput
        className={`${styles.phoneinpu} ${
          props.phone?.length ? styles.disabled : ""
        }`}
        country={"in"}
        value={props.mobile}
        disable={true}
        autoFormat={true}
        disableSearchIcon={true}
        variant="contained"
        countryCodeEditable={false}
        inputProps={{
          name: "phone",
          required: true,
          // autoFocus: true
        }}
        // searchStyle={{ borderColor: 'black', bakgrondColor: 'green' }}
        // {props.border && props.border === 'applyform' ? : }
        buttonStyle={
          // props.border && props.border === 'applyform' ?
          // {}
          // :
          {
            paddingLeft: "0px",
            backgroundColor: "white",
            borderRightHeight: "4px",
            borderRightWidth: "20px",
            color: "#636365",
            border: "1px solid #D0D0D0",
            borderTopLeftRadius: "8px",
            borderBottomLeftRadius: "8px",
          }
        }
        containerStyle={{
          width: "100%",
          // border: '1px solid #ababab',
        }}
        // dropdownStyle={{width:'200px',padding:'30px',borderColor:'red'}}
        inputStyle={
         
          //     props.border && props.border === 'applyform' ? {
          //     // paddingLeft:'78px',
          //     width: '100%',
          //     backgroundColor: '#f6f6f6',
          //     height: '34px',
          //     outlined: 'true',
          //     borderRadius: '0px',
          //     '&:focus': {
          //         border: '1px solid black'
          //     },
          // } :
          { 
            // paddingLeft:'78px',
            height: "auto",
                                fontSize: "15px !important",
                                fontWeight: "DM Sans",
                                lineHeight: "1.5",
                                padding:"8px 15px 8px 48px",
                                outline:"none",
            "&:focus": {
              border: "1px solid black",
            },
          }
        }
        {...props}
        onChange={props.handleChangeNumber}
      />
    );
  } else if (props.type === "subadmin") {
    return (
      <PhoneInput
        className={styles.phoneinpu}
        country={"in"}
        // value={mobile}
        value={props.addSubadmin.phone}
        autoFormat={true}
        disableSearchIcon={true}
        variant="contained"
        countryCodeEditable={false}
        inputProps={{
          name: "phone",
          required: true,
          // autoFocus: true
        }}
        // searchStyle={{ borderColor: 'black', bakgrondColor: 'green' }}
        // {props.border && props.border === 'applyform' ? : }
        buttonStyle={
          // props.border && props.border === 'applyform' ?
          // {}
          // :
          {
            paddingLeft: "0px",
            backgroundColor: "#f6f6f6",
            borderRightHeight: "4px",
            borderRightWidth: "20px",
            border: "none",
            borderRadius: "0px",
          }
        }
        containerStyle={{
          width: "100%",
          height: "36px",
          // border: '1px solid #ababab',
          borderRadius: "0px",
        }}
        // dropdownStyle={{width:'200px',padding:'30px',borderColor:'red'}}
        inputStyle={
          //     props.border && props.border === 'applyform' ? {
          //     // paddingLeft:'78px',
          //     width: '100%',
          //     backgroundColor: '#f6f6f6',
          //     height: '34px',
          //     outlined: 'true',
          //     borderRadius: '0px',
          //     '&:focus': {
          //         border: '1px solid black'
          //     },
          // } :
          {
            // paddingLeft:'78px',
            width: "100%",
            backgroundColor: "#f6f6f6",
            height: "36px",
            outlined: "true",
            border: "none",
            borderRadius: "0px",
            "&:focus": {
              border: "1px solid black",
            },
          }
        }
        placeholder="1234567890"
        {...props}
        onChange={props.handleChangeNumber}
      />
    );
  } else {
    return (
      <PhoneInput
        className={styles.phoneinput}
        country={"in"}
        value={mobile}
        autoFormat={true}
        disableSearchIcon={true}
        variant="contained"
        countryCodeEditable={false}
        inputProps={{
          name: "phone",
          required: true,
          // autoFocus: true
        }}
        // searchStyle={{ borderColor: 'black', bakgrondColor: 'green' }}
        // {props.border && props.border === 'applyform' ? : }
        buttonStyle={
          props.border && props.border === "applyform"
            ? {}
            : {
                paddingLeft: "0px",
                borderRightHeight: "4px",
                borderRightWidth: "20px",
                border: "none",
                borderRight: "1px solid black",
                borderBottom: "1px solid black",
                borderRadius: "0px",
              }
        }
        containerStyle={{
          width: "100%",
          // border: '1px solid #ababab',
          borderRadius: "0px",
        }}
        dropdownStyle={{
          width: "250px",
          textAlign: "left",
          // marginLeft: "-10px", // Adjust this as needed to align correctly
        }}
        inputStyle={
          props.border && props.border === "applyform"
            ? {
                // paddingLeft:'78px',
                width: "100%",
                backgroundColor: "#f6f6f6",
                height: "54px",
                outlined: "true",
                borderRadius: "0px",
                "&:focus": {
                  border: "1px solid black",
                },
              }
            : {
                // paddingLeft:'78px',
                width: "100%",
                backgroundColor: "#f6f6f6",
                height: "54px",
                outlined: "true",
                border: "none",
                borderBottom: "1px solid black",
                borderRadius: "0px",
                "&:focus": {
                  border: "1px solid black",
                },
              }
        }
        {...props}
        onChange={props.handleChangeNumber}
      />
    );
  }
}
