import { createTheme, Grid, ThemeProvider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import homeImage from "../../../constant";
import styles from "./UserAuction.module.css";
import { setActiveStatus } from "../../../State/Features/user/activetabSlice";
import { useDispatch, useSelector } from "react-redux";
import api from "../../../State/api/Api";
import serverimage from "../../../serverConstant";

function UserAuctions() {
  const theme = createTheme({
    typography: {
      h4: {
        fontFamily: "Marcellus",
        fontSize: "2vw",
      },
      h6: {
        fontFamily: "Marcellus",
        fontSize: "1vw",
      },
    },
  });
  const AuctionCardDetails = [
    {
      id: 1,
      imgUrl: "/img/Rectangle auction1.png",
    },
    {
      id: 2,
      imgUrl: "/img/Rectangle auction2.png",
    },
    {
      id: 3,
      imgUrl: "/img/Rectangle auction3.png",
    },
    {
      id: 4,
      imgUrl: "/img/Rectangle auction4.png",
    },
    {
      id: 5,
      imgUrl: "/img/Rectangle auction1.png",
    },
    {
      id: 6,
      imgUrl: "/img/Rectangle auction2.png",
    },
    {
      id: 7,
      imgUrl: "/img/Rectangle auction3.png",
    },
    {
      id: 8,
      imgUrl: "/img/Rectangle auction4.png",
    },
  ];
  const navigate = useNavigate();
  const handleNavigate = (route) => {
    navigate(route);
  };

  const dispatch = useDispatch();
  const { id } = useParams();
  const loader = useSelector((state) => state.loader);
  const offersdt = useSelector((state) => state.offersData);
  const [orderTypeId, setOrderTypeId] = useState("");
  const [exhibitionDetailArt, setExhibitionDetailArt] = useState([]);
  console.log(exhibitionDetailArt,'exhibitiondetailsart')

  const auctionStatus = useSelector((state) => state.activetab);
  const handleAuctionStatus = (status) => {
    dispatch(setActiveStatus({ status, maintab: "exhibition" }));
  };
  const fetchExhibitionArtDetails = async () => {
    try {
      const { data } = await api.applicationApi(
        `api/auction/fetchAuctionArtDetails/${id}`,
        "POST"
      );
      setExhibitionDetailArt(data.data.data);
      setOrderTypeId(data?.data?.orderType?._id);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    // handleFetchOffer();
    fetchExhibitionArtDetails();
    // dispatch(
    //   setActiveStatus({ status: "current auctions", maintab: "exhibition" })
    // );
  }, []);

  const filteredExhibitions = exhibitionDetailArt?.data?.filter((val) => {
    const currentDate = new Date();
    const startDate = new Date(val.start_date); 
    const endDate = new Date(val.end_date);
  
    return (
      // Upcoming exhibitions
      (auctionStatus.status === "upcoming" && startDate > currentDate) ||
      // Past exhibitions
      (auctionStatus.status === "past" && endDate < currentDate) ||
      // Current exhibitions
      (auctionStatus.status === "current auctions" &&
        startDate <= currentDate &&
        endDate >= currentDate)
    ) 
  });
  const handleOfferDetail = (id) => {
    navigate(`/user/productdetail/${id}`);
  };
  return (
    <ThemeProvider theme={theme}>
      <div className={styles.userauction}>
        <div className={styles.headerCategory}>
          <br />
          <div className={styles.flex_align}>
            <Typography variant="h4">
              <span className={styles.headerTitle}>Auctions</span>
            </Typography>
            <Typography variant="h6">
              <span className={styles.headerSubTitle}>
                <b
                  style={{ cursor: "pointer" }}
                  onClick={() => handleNavigate("/")}
                >
                  Home /{" "}
                </b>
                Auctions
              </span>
            </Typography>
          </div>
        </div>
        <div className={styles.main__container}>
          {/* <div className={styles.link__div}>
            <Link
              style={{ textDecoration: "none", color: "black" }}
              onClick={() => handleAuctionStatus("current auctions")}
            >
              <Typography
                fontSize="1vw"
                fontFamily="marcellus"
                fontWeight={
                  auctionStatus.status === "current auctions"
                    ? "bold"
                    : "400"
                }
                color={
                  auctionStatus.status === "current auctions"
                    ? "black"
                    : "#636365"
                }
              >
                CURRENT AUCTIONS
              </Typography>
            </Link>
            <div className={styles.verticalLine3}></div>
            <Link
              style={{ textDecoration: "none", color: "black" }}
              onClick={() => handleAuctionStatus("past")}
            >
              <Typography
                fontSize="1vw"
                fontFamily="marcellus"
                fontWeight={auctionStatus.status === "past" ? "bold" : "400"}
                color={auctionStatus.status === "past" ? "black" : "#636365"}
              >
                PAST
              </Typography>
            </Link>
            <div className={styles.verticalLine3}></div>
            <Link
              style={{ textDecoration: "none", color: "black" }}
              onClick={() => handleAuctionStatus("upcoming")}
            >
              <Typography
                fontSize="1vw"
                fontFamily="marcellus"
                fontWeight={
                  auctionStatus.status === "upcoming" ? "bold" : "400"
                }
                color={
                  auctionStatus.status === "upcoming" ? "black" : "#636365"
                }
              >
                UPCOMING
              </Typography>
            </Link>
          </div> */}
          <hr className={styles.divider} />
          <div className={styles.auctioncards__Main}>
            <Grid container spacing={3}>
              {
              exhibitionDetailArt && exhibitionDetailArt.length === 0 ?

              <>
              <div className={styles.no_result}><h3 >No Result Found </h3></div>
              </>
              :
              exhibitionDetailArt.map((item) => (
                <Grid item xs={12} sm={6} md={3}>
                  <div
                    className={styles.auction__cards}
                    onClick={() => {
                      navigate(`/user/auction-details/${id}`)}}
                  >
                    <img
                      className={styles.auctionImg}
                      src={ item?.item?.[0].image}
                    />
                    <Typography fontWeight="600" fontSize="0.95rem">
                      {item?.item?.[0].name}
                    </Typography>
                    <div className={styles.auction__timing}>
                      <Typography fontSize="0.8rem">
                        {item?.item?.[0].category}{" "}
                      </Typography>
                    </div>
                    {/* <div className={styles.auction__timing}>
                      <Typography fontSize="0.9rem">Price: <span style={{color:"black",fontWeight:"600"}}>${item?.price}</span> </Typography>
                      
                    </div> */}
                    <hr />
                    <div className={styles.auction__price}>
                      <div className={styles.bid__price}>
                        <Typography fontSize="0.8rem" color="#636365">
                          Current Bid
                        </Typography>
                        <Typography fontSize="0.8rem" fontWeight="600">
                          $ {item?.item_price}
                        </Typography>
                      </div>
                      <div className={styles.verticalLine}></div>
                      <div className={styles.bid__price}>
                        <Typography fontSize="0.8rem" color="#636365">
                          Next Valid Bid
                        </Typography>
                        <Typography fontSize="0.8rem" fontWeight="600">
                          $ {item?.bids?.[0]?.amount}
                        </Typography>
                      </div>
                    </div>
                    <div className={styles.auction__price} style={{paddingTop:'10px'}}>
                      <div className={styles.bid__price}>
                        <Typography fontSize="0.6rem" color="#636365">
                          Bid History
                        </Typography>
                      
                      </div>
                      <div className={styles.verticalLine} style={{height:"15px"}}></div>
                      <div className={styles.bid__price}>
                        <Typography fontSize="0.6rem" color="#636365">
                          Next Valid Bid
                        </Typography>
                     
                      </div>
                      <div className={styles.verticalLine} style={{height:"15px"}}></div>
                      <div className={styles.bid__price}>
                        <Typography fontSize="0.6rem" color="#636365">
                          Quick Details
                        </Typography>
                       
                      
                      </div>
                    </div>
                  
                  </div>
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default UserAuctions;
