import { Button } from "@mui/material"
import { CSVLink } from "react-csv"
import serverimage from "../serverConstant"

export default function Csvfile(props) {
 
  const exshibitionData = []
  //  props.exshibitiondata.forEach((item) => {
  //   exshibitionData.push({
  //    _id: item._id,
  //    is_accepted: item.is_accepted,
  //    start_date: item.start_date,
  //    end_date: item.end_date,
  //    is_paid: item.is_paid,
  //    artist_name: Object.assign({}, item.artist), // {0:"a", 1:"b", 2:"c"}
  //    artist: item.artist.reduce((obj, cur) => ({ ...obj, ["name"]: cur.first_name+" " + cur.last_name }), {}),
  //    artists: item.artist.reduce((a, b) => Object.assign(a, b), {}),
  //   });
  //   // item.artist.forEach(val => (val.first_name +" "+ val.last_name))
  //   //     for (let i = 0; i < item.artist.length ===0? 0: item.artist.length; i++) {
  //   //         // const role = item.suggestedRoles[i];
  //   //         // data.push({
  //   //         //     name: '',
  //   //         //     description: '',
  //   //         //     suggestedRoles: role.name
  //   //         // });
  //   //         const index = item.artist[i]
  //   //         exshibitionData.push({
  //   //             _id: item._id,
  //   //             is_accepted: item.is_accepted,
  //   //             start_date:item.start_date,
  //   //             end_date:item.end_date,
  //   //             is_paid:item.is_paid,
  //   //             artist_name: {first_name:index.first_name}+" "+{last_name:index.last_name}
  //   //         });
  //   //     }
  //  });
  // props.exshibitiondata?.pendingData
  // props.exshibitiondata?.approvedData?.
  
  const pushData = (dataArray) =>{
    dataArray.forEach((item) => {
      exshibitionData.push({
        _id: item._id,
        image:  item.image,
        name: item.name,
        buy_price: item.buy_price,
        print_price: item.print_price,
        rent_price: item?.rent?.rent_price,
        is_most_popular: item.is_most_popular,
        is_best_seller: item.is_best_seller,
        artist_name: item.artist_name,
      })
    })
  }



  if (props.exshibitiondata?.approvedData?.length) {
    pushData(props.exshibitiondata.approvedData);
  }  
  if (props.exshibitiondata?.pendingData?.length) {
    pushData(props.exshibitiondata.pendingData);
  }
  if (props.exshibitiondata?.declineData?.length) {
    pushData(props.exshibitiondata.declineData);
  }
  
  const header = [
    { label: "Id", key: "_id" },
    { label: "Is Accepted", key: "is_accepted" },
    { label: "Start Date", key: "start_date" },
    { label: "End Date", key: "end_date" },
    { label: "Is Paid", key: "is_paid" },
    { label: "Artist Name", key: "artist.name" },
  ]
  const head = [
    { label: "Id", key: "_id" },
    { label: "Image", key: "image" },
    { label: "Buy Price", key: "buy_price" },
    { label: "Name", key: "name" },
    { label: "Rent Price", key: "rent_price" },
    { label: "Print Price", key: "print_price" },
    { label: "Most Popular", key: "is_most_popular" },
    { label: "Best Seller", key: "is_best_seller" },
    { label: "Print Price", key: "print_price" },
    { label: "Artist Name", key: "artist_name" },
  ]
  //  artist && artist.map(val => val.first_name)
  const exshibitionDatas = exshibitionData
  const csvLink = {
    filename: "file.csv",
    headers: props.type === "arts" ? head : header,
    data: exshibitionDatas,
  }
  return (
    <>
      <CSVLink {...csvLink} className="download_csv">
        <Button
          variant="contained"
          sx={{
            height: "40px",
            minWidth: "150px",
            width: "fit-content",
          }}
        >
          Download CSV
        </Button>
      </CSVLink>
    </>

    //   <CSVLink data={data} headers={headers}>
    //    Download me
    //   </CSVLink>
  )
}
