import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Button, Divider, IconButton, Menu, MenuItem, Select, Tooltip } from "@mui/material";
import React, { useState } from "react";
import styles from "./Fourcoltable.module.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import homeImage from "../../../constant";
import CircularLoader from "../../../common/loader/Loader";
import dateFormat from "../../../utils/dateFormat";
import TruncatedTooltipText from "../../../utils/truncateTooltip";

export default function Fourcoltable(props) {
 const [anchorEl, setAnchorEl] = React.useState(null);
 const [ids, setIds] = useState("");
 const navigate = useNavigate();
 const threedots = Boolean(anchorEl);
 const loader = useSelector((state) => state.loader);
 const handleClick = (event) => {
  setAnchorEl(event.currentTarget);
  setIds(event.target.id);
 };
 const handleProfilepage = (id) => {
  const path = window.location.pathname;
  if (path === "/auctions") {
   navigate(`/auctions/${id}`);
  } else if (path === "/") {
    navigate(`/exhibitions/${id}`);
  }
 };
 return (
  <div className={styles.mains}>
   <TableContainer component={Paper} sx={{ backgroundColor: "#f6f6f6", color: "black" }}>
    <Table   sx={{ 
    tableLayout: "auto", 
    width: "100%", 
    textAlign:'center',
  }}  aria-label="customized table">
     <TableHead   >
      <TableRow   sx={{ 
    tableLayout: "auto", 
    width: 1000 ,
    backgroundColor: "#bfbbc3"
  }} >
       {props.head &&
        props.head.map((column, i) => (
         <TableCell
          // align={props.head.length - 1 === i ? "left" : "left"}
          sx={{
           whiteSpace: "nowrap",
           fontFamily: "DM Sans",
           fontWeight: "500",
           fontSize: "16px",
           height: "21px",
           textAlign:'center',
           color: "#1C1C1C",
           ...(column.label === "Action" && {
            paddingRight: "20px", // Adjust the padding value as needed
          }),
          }}
          key={i} >
          {column.label}
         </TableCell>
        ))}
      </TableRow>
     </TableHead >
     {loader.loader ? (
      <TableBody>
       <TableRow>
        <TableCell colSpan={8}>
         <Box className={styles.loader_box}>
          {" "}
          <CircularLoader size={60} />
         </Box>
        </TableCell>
       </TableRow>
      </TableBody>
     ) : (
      <TableBody>
       {props.data && props.data.length === 0 ? (
        <TableRow>
         <TableCell colSpan={8}>
          <Box className={styles.loader_box}> No Data Found</Box>
         </TableCell>
        </TableRow>
       ) : (
        props.data &&
        props.data.map((row, i) => (
         <TableRow
          style={{ color: "#636365", height: "30px" }}
          className={styles.table_row}
          key={row._id}>
               <TableCell
           sx={{
            fontFamily: "DM Sans",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "21px",
            color: "#636365",
              }}
              style={{ cursor: "pointer" }}
           className={styles.table_column}
           align="left"
         >
           {i + 1}
          </TableCell>
          <TableCell
  sx={{
    fontFamily: "DM Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "21px",
    color: "#636365",
  }}
  style={{ cursor: "pointer" }}
  className={styles.table_column}
  align="left"
>
<TruncatedTooltipText
      text={row.name}
      maxLength={10} // Maximum number of characters before truncation
      href={`/profile/${row._id}`} // Link URL
      onClick={() => handleProfilepage(row._id)} // Custom navigation function
      style={{ color: "black" }} // Optional custom styles
    />
</TableCell>

<TableCell
           sx={{
            fontFamily: "DM Sans",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "21px",
            color: "#636365",
           }}
           className={styles.table_column}
           align="left">
           {props.activetab === "curator" ? row.email : row.location}
           {dateFormat(row.createdAt.substring(0,10))}
           
          </TableCell>
          <TableCell
           sx={{
            fontFamily: "DM Sans",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "21px",
            color: "#636365",
           }}
           className={styles.table_column}
           align="left">
           {props.activetab === "curator" ? row.email : row.location}
           {dateFormat(row.start_date.substring(0,10))}
           
          </TableCell>
          <TableCell
           sx={{
            fontFamily: "DM Sans",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "21px",
            color: "#636365",
           }}
           className={styles.table_column}
           align="left">
           {dateFormat(row.end_date.substring(0,10))}
          </TableCell>
          <TableCell
           sx={{
            fontFamily: "DM Sans",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "21px",
            color: "#636365",
           }}
           className={styles.table_column}
           align="center">
           {dateFormat(row.application_end_date ? row.application_end_date.substring(0,10) : null)}
          </TableCell>
          <TableCell
            sx={{
             fontFamily: "DM Sans",
             fontStyle: "normal",
             fontWeight: "400",
             fontSize: "16px",
             lineHeight: "21px",
             color: "#636365",
            }}
            className={styles.table_column}
              >
              <div className={styles.interested}>
           
            {props.activetab === "upcoming_auction_gallery" || props.activetab === "live_auction_gallery" ? (
             <Button
             variant="contained"
             onClick={() => {
              props.handleInterested(row._id);
              sessionStorage.setItem("exhibition_name", row.name);
            }}
             sx={{ width: "106px", height: "34px", borderRadius: "4px" }}>
             Interested
            </Button>
            ) : 
            ""}
              </div>
           </TableCell>
         </TableRow>
        ))
       )}
      </TableBody>
     )}
    </Table>
   </TableContainer>
  </div>
 );
}
