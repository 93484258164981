import React, { useState, useEffect, useRef } from "react";
import styles from "./Slider.module.css";
import { AiOutlineRight, AiOutlineLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { Button, Grid } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { setFilterByPriceNav } from "../../../../State/Features/user/filterationSlice";
import { useDispatch } from "react-redux";
import striptags from "striptags";
import capitalizeWords from "../../../../utils/capitalizeContent";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { RiArrowRightLine, RiArrowLeftLine } from "react-icons/ri";
import 'swiper/swiper-bundle.min.css';

const Slider = ({ data, type }) => {
  const [startIndex, setStartIndex] = useState(0);
  const [tabCount, setTabCount] = useState(1);
  const [customTab, setcustomTab] = useState({ testimonial: 1, price: 1 });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNavigateCategory = (id) => {
    navigate(`/user/allcategory/${id}`);
  };
  const handleNavigateNewestPainting = (id) => {
    navigate(`/user/newestpaintings/${id}`);
  };
  const handleRouteArtist = (id) => {
    navigate(`/user/artist/${id}`);
  };
  useEffect(() => {
    const width = window.innerWidth;
    if (width < 768) {
      setTabCount(1);
      setcustomTab({ ...customTab, testimonial: 1, price: 1 });
    } else if (width >= 768 && width < 1024) {
      setTabCount(2);
    } else {
      setTabCount(4);
      setcustomTab({ ...customTab, testimonial: 2, price: 3 });
    }
  }, [window.innerWidth]);

  const indexHandler = (type) => {
    if (type === "-") {
      if (startIndex > 0) {
        if (startIndex >= 0) {
          setStartIndex(startIndex - 1);
          // setStartIndex(startIndex - 1)
        } else {
          setStartIndex(0);
        }
      }
    }
    if (type === "+") {
      if (startIndex + tabCount < data.length) {
        if (startIndex + tabCount <= data.length) {
          setStartIndex(startIndex + 1);
        } else {
          setStartIndex(data.length - 1);
        }
      }
    }
  };
  const indexHandlers = (type) => {
    if (type === "-") {
      if (startIndex > 0) {
        if (startIndex >= 0) {
          setStartIndex(startIndex - 1);
          setStartIndex(startIndex - 1);
        } else {
          setStartIndex(0);
        }
      }
    }
    if (type === "+") {
      if (startIndex + customTab.testimonial < data.length) {
        if (startIndex + customTab.testimonial <= data.length) {
          setStartIndex(startIndex + 1);
        } else {
          setStartIndex(data.length - 1);
        }
      }
      if (startIndex + customTab.price < data.length) {
        if (startIndex + customTab.price <= data.length) {
          setStartIndex(startIndex + 1);
        } else {
          setStartIndex(data.length - 1);
        }
      }
    }
  };
  const handleClickMostPopular = (id) => {
    navigate(`/user/mostpopular/${id}`);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const handleFilterByPrice = (value) => {
    navigate("/user/search");
    dispatch(setFilterByPriceNav(value));
  };

  const prevButtonRef = useRef(null);
  const nextButtonRef = useRef(null);

  return (
    <>
      {type === "rating" ? (
        <section className={styles.sections}>
          <div className={styles.left_actions_rating}>
            <button
              variant="contained"
              type="button"
              onClick={startIndex > 0 ? () => indexHandler("-") : null}
              className={
                startIndex <= 0 ? styles.left_inactive : styles.left_active
              }
            >
              <AiOutlineLeft size={20} />
            </button>
          </div>
          <ul className={styles.rating_plans}>
            {data &&
              data.map((item, index) => {
                if (index < startIndex + tabCount && index >= startIndex) {
                  return (
                    <div className={styles.rating_main}>
                      <div className={styles.ratings}>
                        {/* <div className={styles.rating_div}>
                                                    <Rating
                                                        icon={<StarIcon sx={{ width: '15px', height: '15px', color: 'white', backgroundColor: 'green' }} />}
                                                        size="small"
                                                        max={1}
                                                        name="simple-controlled"
                                                        value={value}
                                                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                                        onChange={(event, newValue) => {
                                                            setValue(newValue);
                                                        }}
                                                    /><Rating
                                                        max={1} size="small" icon={<StarIcon sx={{ width: '15px', height: '15px', color: 'white', backgroundColor: 'green' }} />}
                                                        name="simple-controlled"
                                                        value={value}
                                                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                                        onChange={(event, newValue) => {
                                                            setValue(newValue);
                                                        }}
                                                    /><Rating
                                                        max={1} size="small"
                                                        name="simple-controlled"
                                                        value={value} icon={<StarIcon sx={{ width: '15px', height: '15px', color: 'white', backgroundColor: 'green' }} />}
                                                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                                        onChange={(event, newValue) => {
                                                            setValue(newValue);
                                                        }}
                                                    /><Rating
                                                        max={1} size="small"
                                                        name="simple-controlled"
                                                        value={value} icon={<StarIcon sx={{ width: '15px', height: '15px', color: 'white', backgroundColor: 'green' }} />}
                                                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                                        onChange={(event, newValue) => {
                                                            setValue(newValue);
                                                        }}
                                                    /><Rating
                                                        max={1} size="small"
                                                        name="simple-controlled"
                                                        value={value} icon={<StarIcon sx={{ width: '15px', height: '15px', color: 'white', backgroundColor: 'green' }} />}
                                                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                                        onChange={(event, newValue) => {
                                                            setValue(newValue);
                                                        }}
                                                    />
                                                </div> */}
                        <div className={styles.rating_div}>
                          <StarIcon
                            sx={{
                              width: "15px",
                              height: "15px",
                              color: "white",
                              backgroundColor: "green",
                            }}
                          />
                          <StarIcon
                            sx={{
                              width: "15px",
                              height: "15px",
                              color: "white",
                              backgroundColor: "green",
                            }}
                          />
                          <StarIcon
                            sx={{
                              width: "15px",
                              height: "15px",
                              color: "white",
                              backgroundColor: "green",
                            }}
                          />
                          <StarIcon
                            sx={{
                              width: "15px",
                              height: "15px",
                              color: "white",
                              backgroundColor: "green",
                            }}
                          />
                          <StarIcon
                            sx={{
                              width: "15px",
                              height: "15px",
                              color: "white",
                              backgroundColor: "green",
                            }}
                          />
                        </div>
                        <h5> {item.duration} day ago</h5>
                      </div>
                      <h2>{item.heading}</h2>
                      <p>{item.des}</p>
                      <h6>{item.name}</h6>
                      <p>{item.hint}</p>
                    </div>
                  );
                }
              })}
          </ul>
          <div className={styles.right_actions_rating}>
            <button
              type="button"
              onClick={
                startIndex + tabCount < data.length
                  ? () => indexHandler("+")
                  : null
              }
              className={
                startIndex + tabCount >= data.length
                  ? styles.right_inactive
                  : styles.right_active
              }
            >
              <AiOutlineRight size={20} />
            </button>
          </div>
        </section>
      ) : type === "testimonial" ? (
        <section className={styles.sections}>
          <Swiper
        modules={[Navigation]}
        slidesPerView={1}
        spaceBetween={10}
        loop={true}
        navigation={{
          prevEl: prevButtonRef.current,
          nextEl: nextButtonRef.current,
        }}
        breakpoints={{
          480: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 15,
          },
          1024: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
        }}
      >

      
          {data &&
              data.map((item, index) => {
                if (
                  index < startIndex + customTab.testimonial &&
                  index >= startIndex
                ) {
                  return (
                    <SwiperSlide>
                      <div className={styles.testmibox}>
                      <Grid container spacing={2}  alignItems="center">
                      <Grid item md={3} xs={12}>
                      <div className={styles.testimonial_img_div}>
                          <img
                            src={ item.image}
                            width="120px"
                            height="120px"
                            alt=""
                          />
                        </div>
                      </Grid>
                      <Grid item md={9} xs={12}>
                        <div className={styles.tecont}>
                        <div className={styles.test_metainfo}>
                        <h5 className={styles.test_card_heading}>
                            {capitalizeWords(item.customer_name)}
                          </h5>
                          <h6 className={styles.test_card_subhead}>
                            {capitalizeWords(item.position)}
                          </h6>
                        </div>
                        <div className={styles.test_cont}>
                          <p className={styles.test_card_des}>{striptags(capitalizeWords(item.description))}</p>
                        </div>
                        </div>
                      </Grid>
                      </Grid>
                      </div>
                    </SwiperSlide>
                  );
                }
              })}
              </Swiper>
              <div className="tesarrow">
          <div
        ref={prevButtonRef}
        className="swiper-button-prev"
      ><RiArrowLeftLine/>
      </div>
      <div
        ref={nextButtonRef}
        className="swiper-button-next"
      ><RiArrowRightLine/></div> 
      </div>
        </section>
      ) : type === "price" ? (
        <section className={styles.sections}>
          <div className={styles.left_actions_price}>
            <button
              variant="contained"
              type="button"
              onClick={startIndex > 0 ? () => indexHandlers("-") : null}
              className={
                startIndex <= 0 ? styles.left_inactive : styles.left_active
              }
            >
              <AiOutlineLeft size={20} />
            </button>
          </div>
          <ul className={styles.price_plans}>
            {data &&
              data.map((item, index) => {
                if (
                  index < startIndex + customTab.price &&
                  index >= startIndex
                ) {
                  return (
                    <div
                      className={styles.price_card}
                      onClick={() => handleFilterByPrice(item.buy_price)}
                    >
                      <div className={styles.price_img_div}>
                        {/* <div className={styles.main_img_div}> */}
                        <img
                          className={styles.price_img}
                          src={ item.category_id.image}
                          width="100%"
                          height="100%"
                        />
                        {/* </div> */}
                      </div>
                      <div className={styles.by_price}>
                        <p>UpTo {item.buy_price}</p>
                        <div className={styles.price_action}>
                          {/* <h4 style={{ marginBottom: 0 }}>nitin</h4> */}
                          <PlayArrowIcon
                            variant="contained"
                            className={styles.trianglebtn}
                            sx={{ fontSize: 50 }}
                            //  onClick={() => {
                            //   alert("hello");
                            //  }}
                          ></PlayArrowIcon>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
          </ul>
          <div className={styles.right_actions_price}>
            <button
              type="button"
              onClick={
                startIndex + customTab.price < data.length
                  ? () => indexHandlers("+")
                  : null
              }
              className={
                startIndex + customTab.price >= data.length
                  ? styles.right_inactive
                  : styles.right_active
              }
            >
              <AiOutlineRight size={20} />
            </button>
          </div>
        </section>
      ) : (
        <section className={styles.sections}>
          <div className={styles.left_actions}>
            <button
              variant="contained"
              type="button"
              onClick={startIndex > 0 ? () => indexHandler("-") : null}
              className={
                startIndex <= 0 ? styles.left_inactive : styles.left_active
              }
            >
              <AiOutlineLeft size={20} />
            </button>
          </div>
          <ul className={styles.plans}>
            {data &&
              data.map((item, index) => {
                if (index < startIndex + tabCount && index >= startIndex) {
                  return type === "most_popular" ? (
                    <div
                      className={styles.card__main}
                      onClick={() => handleClickMostPopular(item._id)}
                    >
                      <div className={styles.card__top}>
                        <img
                          className={styles.card__Image}
                          src={ item.image}
                          width="100%"
                        />
                      </div>
                      <div className={styles.pic_info}>
                        <div className={styles.card__middle}>
                          <span className={styles.titles}>{item.name}</span>
                          <br />
                          <span className={styles.names}>
                            {item.artist_id && item.artist_id.first_name}{" "}
                            {item.artist_id && item.artist_id.last_name}
                          </span>
                        </div>
                        <div className={styles.info_like}>
                          <div>
                            <img src={"/img/Vector-heart.png"} height="15px" />
                          </div>
                          <span className={styles.like_count}>522</span>
                        </div>
                      </div>
                      <hr className={styles.hr_line} />
                      <div className={styles.pic_info}>
                        <div className={styles.card__bottom}>
                          <span className={styles.buys}>Buy</span>
                          <br />
                          <span className={styles.prices}>
                            $ {item.buy_price}
                          </span>
                        </div>
                        <div className={styles.hrs} />
                        <div>
                          <span className={styles.buys}>Rent/Month</span>
                          <br />
                          <span className={styles.prices}>
                            $ {item.rent_price}
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : type === "paintings" ? (
                    <div
                      className={styles.card__main}
                      onClick={() => handleNavigateNewestPainting(item._id)}
                    >
                      <div className={styles.card__top}>
                        <img
                          className={styles.card_Image}
                          src={ item.image}
                          width="100%"
                        />
                      </div>
                      <div className={styles.pic_info}>
                        <div className={styles.card__middle}>
                          <span className={styles.titles}>{item.name}</span>
                          <br />
                          <span className={styles.names}>
                            {item.artist_id && item.artist_id.first_name}{" "}
                            {item.artist_id && item.artist_id.last_name}
                          </span>
                        </div>
                        <div className={styles.info_like}>
                          <div>
                            <img src={"/img/Vector-heart.png"} height="12px" />
                          </div>
                          <span className={styles.like_count}>522</span>
                        </div>
                      </div>
                      <hr className={styles.hr_line} />
                      <div className={styles.pic_info}>
                        <div className={styles.card__bottom}>
                          <span className={styles.buys}>Buy</span>
                          <br />
                          <span className={styles.prices}>$ 3326</span>
                        </div>
                        <div className={styles.hrs} />
                        <div>
                          <span className={styles.buys}>Rent/Month</span>
                          <br />
                          <span className={styles.prices}>$ 680</span>
                        </div>
                      </div>
                    </div>
                  ) : type === "curator" ? (
                    <div
                      className={styles.card__main}
                      // onClick={()=>handleNavigateNewestPainting(item._id)}
                    >
                      <div className={styles.card__top}>
                        <img
                          className={styles.card__Image}
                          src={item.url}
                          width="100%"
                        />
                      </div>
                      <div className={styles.pic_info}>
                        <div className={styles.card__middle}>
                          <span className={styles.titles}>{item.name}</span>
                          <br />
                          <span className={styles.names}>
                            {item.artist_id &&
                              item.artist_id &&
                              item.artist_id &&
                              item.artist_id.first_name}{" "}
                            {item.artist_id &&
                              item.artist_id &&
                              item.artist_id &&
                              item.artist_id.last_name}
                          </span>
                        </div>
                        <div className={styles.info_like}>
                          <div>
                            <img src={"/img/Vector-heart.png"} height="15px" />
                          </div>
                          <span className={styles.like_count}>522</span>
                        </div>
                      </div>
                      <hr className={styles.hr_line} />
                      <div className={styles.pic_info}>
                        <div className={styles.card__bottom}>
                          <span className={styles.buys}>Buy</span>
                          <br />
                          <span className={styles.prices}>
                            $ {item.buy_price}
                          </span>
                        </div>
                        <div className={styles.hrs} />
                        <div>
                          <span className={styles.buys}>Rent/Month</span>
                          <br />
                          <span className={styles.prices}>
                            $ {item.rent_price}
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : type === "follow" ? (
                    <div
                      className={styles.card__main}
                      // onClick={()=>handleNavigateNewestPainting(item._id)}
                    >
                      <div className={styles.card__top}>
                        <img
                          className={styles.card__Image}
                          src={item.url}
                          width="100%"
                        />
                      </div>
                      <div className={styles.pic_info}>
                        <div className={styles.card__middle}>
                          <span className={styles.titles}>{item.title}</span>
                          <br />
                          <span className={styles.names}>{item.artist}</span>
                        </div>
                        <div className={styles.info_like}>
                          <div>
                            <img src={"/img/Vector-heart.png"} height="15px" />
                          </div>
                          <span className={styles.like_count}>522</span>
                        </div>
                      </div>
                      <hr className={styles.hr_line} />
                      <div className={styles.pic_info}>
                        <div className={styles.card__bottom}>
                          <span className={styles.buys}>Buy</span>
                          <br />
                          <span className={styles.prices}>{item.price}</span>
                        </div>
                        <div className={styles.hrs} />
                        <div>
                          <span className={styles.buys}>Rent/Month</span>
                          <br />
                          <span className={styles.prices}>
                            {" "}
                            {item.rentpermonth}
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : type === "recently" ? (
                    <div
                      className={styles.card__main}
                      // onClick={()=>handleNavigateNewestPainting(item._id)}
                    >
                      <div className={styles.card__top}>
                        <img
                          className={styles.card__Image}
                          src={item.url}
                          width="100%" alt=""
                        />
                      </div>
                      <div className={styles.pic_info}>
                        <div className={styles.card__middle}>
                          <span className={styles.titles}>{item.name}</span>
                          <br />
                          <span className={styles.names}>
                            {item.artist_id &&
                              item.artist_id &&
                              item.artist_id &&
                              item.artist_id.first_name}{" "}
                            {item.artist_id &&
                              item.artist_id &&
                              item.artist_id &&
                              item.artist_id.last_name}
                          </span>
                        </div>
                        <div className={styles.info_like}>
                          <div>
                            <img src={"/img/Vector-heart.png"} height="15px" />
                          </div>
                          <span className={styles.like_count}>522</span>
                        </div>
                      </div>
                      <hr className={styles.hr_line} />
                      <div className={styles.pic_info}>
                        <div className={styles.card__bottom}>
                          <span className={styles.buys}>Buy</span>
                          <br />
                          <span className={styles.prices}>
                            $ {item.buy_price}
                          </span>
                        </div>
                        <div className={styles.hrs} />
                        <div>
                          <span className={styles.buys}>Rent/Month</span>
                          <br />
                          <span className={styles.prices}>
                            $ {item.rent_price}
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : type === "featured" ? (
                    // <div className={styles.hero_main7}>
                    <div className={styles.feature_card}>
                      <div className={styles.featured_img_div}>
                        <div className={styles.main_img_div}>
                          <img
                            src={ item.backgroundImage}
                            width="100%"
                            height="100%"
                          />
                        </div>
                        <div>
                          <div>
                            <img
                              src={ item.image}
                              width="100%"
                              height="100%"
                            />
                          </div>
                          <div>
                            <img
                              src={ item.image}
                              width="100%"
                              height="100%"
                            />
                          </div>
                          <div>
                            <img
                              src={ item.image}
                              width="100%"
                              height="100%"
                            />
                          </div>
                        </div>
                        <img
                          onClick={() => handleRouteArtist(item.user_id)}
                          className={styles.artist_img}
                          src={ item.image}
                          width="100%"
                          height="100%"
                        />
                      </div>
                      <div className={styles.feature_action}>
                        <h4>{item.artist_name}</h4>
                        <Button
                          variant="contained"
                          sx={{
                            width: "100px",
                            height: "40px",
                            borderRadius: "6px",
                          }}
                        >
                          Follow
                        </Button>
                      </div>
                    </div>
                  ) : type === "rating" ? (
                    <div className={styles.rating_main}>
                      <div className={styles.ratings}>
                        {/* <div className={styles.rating_div}>
                                                    <Rating
                                                        icon={<StarIcon sx={{ width: '15px', height: '15px', color: 'white', backgroundColor: 'green' }} />}
                                                        size="small"
                                                        max={1}
                                                        name="simple-controlled"
                                                        value={value}
                                                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                                        onChange={(event, newValue) => {
                                                            setValue(newValue);
                                                        }}
                                                    /><Rating
                                                        max={1} size="small" icon={<StarIcon sx={{ width: '15px', height: '15px', color: 'white', backgroundColor: 'green' }} />}
                                                        name="simple-controlled"
                                                        value={value}
                                                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                                        onChange={(event, newValue) => {
                                                            setValue(newValue);
                                                        }}
                                                    /><Rating
                                                        max={1} size="small"
                                                        name="simple-controlled"
                                                        value={value} icon={<StarIcon sx={{ width: '15px', height: '15px', color: 'white', backgroundColor: 'green' }} />}
                                                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                                        onChange={(event, newValue) => {
                                                            setValue(newValue);
                                                        }}
                                                    /><Rating
                                                        max={1} size="small"
                                                        name="simple-controlled"
                                                        value={value} icon={<StarIcon sx={{ width: '15px', height: '15px', color: 'white', backgroundColor: 'green' }} />}
                                                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                                        onChange={(event, newValue) => {
                                                            setValue(newValue);
                                                        }}
                                                    /><Rating
                                                        max={1} size="small"
                                                        name="simple-controlled"
                                                        value={value} icon={<StarIcon sx={{ width: '15px', height: '15px', color: 'white', backgroundColor: 'green' }} />}
                                                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                                        onChange={(event, newValue) => {
                                                            setValue(newValue);
                                                        }}
                                                    />
                                                </div> */}
                        <div className={styles.rating_div}>
                          <StarIcon
                            sx={{
                              width: "15px",
                              height: "15px",
                              color: "white",
                              backgroundColor: "green",
                            }}
                          />
                          <StarIcon
                            sx={{
                              width: "15px",
                              height: "15px",
                              color: "white",
                              backgroundColor: "green",
                            }}
                          />
                          <StarIcon
                            sx={{
                              width: "15px",
                              height: "15px",
                              color: "white",
                              backgroundColor: "green",
                            }}
                          />
                          <StarIcon
                            sx={{
                              width: "15px",
                              height: "15px",
                              color: "white",
                              backgroundColor: "green",
                            }}
                          />
                          <StarIcon
                            sx={{
                              width: "15px",
                              height: "15px",
                              color: "white",
                              backgroundColor: "green",
                            }}
                          />
                        </div>
                        <h5> {item.duration} day ago</h5>
                      </div>
                      <h2>{item.heading}</h2>
                      <p>{item.des}</p>
                      <h6>{item.name}</h6>
                      <p>{item.hint}</p>
                    </div>
                  ) : (
                    <div
                      className={styles.category_card}
                      onClick={() => handleNavigateCategory(item._id)}
                    >
                      <div className={styles.card__mai}>
                        <div className={styles.category_img_div}>
                          <img
                            src={ item.image}
                            height="100%"
                            width="100%"
                          />
                        </div>
                        <div className={styles.category_text}>
                          <div className={styles.category_text_child}>
                            <h4>{item.name}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
          </ul>
          <div className={styles.right_actions}>
            <button
              type="button"
              onClick={
                startIndex + tabCount < data.length
                  ? () => indexHandler("+")
                  : null
              }
              className={
                startIndex + tabCount >= data.length
                  ? styles.right_inactive
                  : styles.right_active
              }
            >
              <AiOutlineRight size={20} />
            </button>
          </div>
        </section>
      )}
    </>
  );
};
export default Slider;
