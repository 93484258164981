// Art api's

import { Errornotify, Successnotify } from "../../common/alert/Alert"
import { setLoader } from "../Features/common/loaderSlice"
import { removeUser } from "../Features/common/userSlice"
import api from "./Api"

export function handleUpdateProfile(data, fetchData) {
  return async function addArtistTypeDataThunk(dispatch) {
    try {
      var bodyFormData = new FormData()
      bodyFormData.append("first_name", data.first_name)
      bodyFormData.append("last_name", data.last_name)
      bodyFormData.append("phone", data.phone)
      bodyFormData.append("CV", data.CV)
      bodyFormData.append("ID_proof", data.ID_proof)
      bodyFormData.append("qualification", data.qualification)
    
      bodyFormData.append("email", data.email)

      bodyFormData.append("shows_curated", data.shows_curated)
      bodyFormData.append("achievement", data.achievement)
      bodyFormData.append("image", data.image ? data.image : "")
      bodyFormData.append("addresses[0][country]", data.country)
       bodyFormData.append("addresses[0][address_line1]", data.address)
      bodyFormData.append("addresses[0][state]", data.state)
      bodyFormData.append("addresses[0][city]", data.city)

      bodyFormData.append("addresses[0][zipcode]", data.zipcode)
   
     
    
      const apiResponse = await api.multipartApi(
        "api/curator/myprofileupdateCurator",
        "POST",
        bodyFormData
      )
      if (apiResponse.status === 200) {
        localStorage.setItem(
          "user_name",
          data.first_name + " " + data.last_name
        )
        Successnotify(apiResponse.data.message)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
      Errornotify(" Network error")
    }
  }
}

export function handleApplyProfile(data, navigate) {
  return async function addArtistTypeDataThunk(dispatch) {
    try {
      var bodyFormData = new FormData()
      bodyFormData.append("first_name", data.first_name)
      bodyFormData.append("last_name", data.last_name)
      bodyFormData.append("curator_email", data.curator_email)
      // bodyFormData.append("password",data.password);
      bodyFormData.append("phone", data.phone)
      bodyFormData.append("CV", data.CV)
      bodyFormData.append("ID_proof", data.ID_proof)
      bodyFormData.append("qualification", data.qualification)
      bodyFormData.append("cover_image", data.cover_image)
      bodyFormData.append("profile_image", data.profile_image)
      bodyFormData.append("addresses[0][country]", data.country)
      bodyFormData.append("nationality", data.nationality)

      bodyFormData.append("shows_curated", data.shows_curated)
      bodyFormData.append("achievement", data.achievement)
      bodyFormData.append("completion", data.completion);

      const apiResponse = await api.multipartApi(
        "api/curator/updateCuratorProfile",
        "POST",
        bodyFormData
      )
      if (apiResponse.status === 200) {
        localStorage.setItem(
          "user_name",
          data.first_name + " " + data.last_name
        )

        Successnotify(apiResponse.data.message)
        setTimeout(() => {
          navigate("/artsmiley/curator/form-submission")
        }, 500)
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
      Errornotify(" Network error")
    }
  }
}
//Fetch Curated Arts

export function fetchCuratedArtsData(setData) {
  return async function fetchCuratotDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/art/fetchCuratedArts",
        "POST"
      )
      if (apiResponse.status === 200) {
        setData(apiResponse.data.data)
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          return 401
        }
        return false
      }
    } catch (err) {
    }
  }
}

// Add to curated Collection API

export function addToCuratorCollection(id, fetchData) {
  return async function blockArtistDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/art/addArtToCuratedCollection/${id}`,
        "POST"
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}
// INVITE ARTIST

export function inviteArtist(data, setOpen) {
  return async function inviteArtistThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        "api/artist/inviteArtist",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        setOpen(false)
        Successnotify(apiResponse.data.message)
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}
// Art Reject Reason

export function acceptDeclineCuratorArts(id, data, fetchData) {
  return async function blockArtistDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/art/acceptDeclineArt/${id}`,
        "PATCH",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}

export function handleAddSubscriptionFeature(data, setOpen, fetchData) {
  return async function addsubscriptionFeatureThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        "api/feature/addSubscriptionfeature",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
        setOpen(false)
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          setOpen(false)
          // return 401;
        } else {
          Errornotify(apiResponse.data.message)
          setOpen(false)
          // return false;
        }
      }
    } catch (err) {
      setOpen(false)
    }
  }
}
