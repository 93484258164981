import React, { useEffect, useState } from "react";
import styles from "./Order.module.css";
import Moreoption from "../Table/Moreoption/Moreoption";
import Tableheader from "../Table/Tableheader/Tableheader";
import Orderdetail from "./Orderdetail/Orderdetail";
import Sixcolimg from "../Table/sixcolumn/Sixcolimg/Sixcolimg";
import Pagination from "../Pagination/Pagination";
import { FormControl, MenuItem, Select } from "@mui/material";
import { useParams } from "react-router-dom";
import { setLoader } from "../../../../State/Features/common/loaderSlice";
import { AlertsSuccess, Errornotify } from "../../../../common/alert/Alert";
import { useDispatch } from "react-redux";
import api from "../../../../State/api/Api";
import { useCallback } from "react";
import OrderTable from "../Table/orders/OrderTable";
import SixcolimgOrder from "../Table/sixcolumn/Sixcolimg/SixcolimgOrder";
import SixcolimgAdminBuyOrders from "../Table/sixcolumn/Sixcolimg/SixcolimgAdminBuyOrders";

const moreOptions = [
  {
    id: "buy",
    label: "Buy",
    value: "buy",
  },
  {
    id: "rent",
    label: "Rent",
    value: "rent",
  },
  {
    id: "print",
    label: "Print",
    value: "print",
  },
];

const renthead = [
  // {
  //   id: "image ",
  //   label: "Image",
  // },
  {
    id: "order_id",
    label: "Order ID :",
  },
  {
    id: "date",
    label: "Order Date",
  },
  {
    id: "item",
    label: "No.of items :",
  },
  {
    id: "type",
    label: "Type",
  },
  {
    id: "amount",
    label: "Order Amount",
  },
];
const printhead = [
  // {
  //   id: "image ",
  //   label: "Image",
  // },
  {
    id: "order_id",
    label: "Order ID",
  },
  {
    id: "date",
    label: "Order Date",
  },
  {
    id: "item",
    label: "No.of items",
  },
  {
    id: "type",
    label: "Type",
  },
  {
    id: "amount",
    label: "Order Amount",
  },
];
const buyhead = [
  // {
  //   id: "",
  //   label: "",
  // },
  {
    id: "order_id",
    label: "Order ID",
  },
  {
    id: "date",
    label: "Order Date",
  },
  {
    id: "item",
    label: "No.of items",
  },
  {
    id: "type",
    label: "Type",
  },
  {
    id: "amount",
    label: "Order Amount",
  },
  {
    id: "status",
    label: "Staus",
  },
];
const sortdata = [
  { label: "By Date ↓", value: "low" },
  { label: "By Date ↑", value: "high" },
];
const filterdata = [
  { label: "Approved", value: "approved" },
  { label: "Pending", value: "pending" },
  { label: "Cancel", value: "cancel" },
];
export default function Orders() {
  const [activetab, setAcitvetab] = useState("buy");
  const [age, setAge] = useState("");
  const [orderData, setOrderData] = useState(null);
  console.log("orderData1188--", orderData);
  const [usertype, setUsertype] = useState();
  console.log("usertype", usertype);

  const [data, setData] = useState({
    // order_type: "63aaf1eea33ce89c8af5de5d",
    is_accepted: "buy",
    offset: 1,
  });
  

  const [condition, setCondition] = useState({
    search: "",
    filter: "",
    sorting: "",
  });
  const dispatch = useDispatch();
  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleMorecategories = (e) => {
    setAcitvetab(e);
  };

  const handleFetchOrderType = async function () {
    dispatch(setLoader(true));
    try {
      const result = await api.applicationApi(
        "api/orderType/fetchOrderTypes",
        "GET"
      );
      if (result && result.status === 200) {
        setUsertype(result.data.data.data);
        const name = result.data.data.data.filter(
          (val) => val.name.toLowerCase() === activetab
        );
        setData({ ...data, order_type: name[0]._id });
        dispatch(setLoader(false));
      } else if (result.status === 401) {
        Errornotify("Error with code 401");
      }
    } catch (err) {
      Errornotify("Network Error");
    }
  };
  const handleFetchOrders = async function () {
    dispatch(setLoader(true));
    try {
      const result = await api.applicationApi("api/order/fetchOrders", "POST");
      console.log(result.data.data, "result from orders");
      if (result && result.status === 200) {
        setOrderData(result.data.data);
        dispatch(setLoader(false));
      } else if (result.status === 401) {
        Errornotify("Error with code 401");
      }
    } catch (err) {
      Errornotify("Network Error");
    }
  };
  useEffect(() => {
    handleFetchOrders();
    handleFetchOrderType();
  }, []);

  const handleFilter = (event) => {
    const value = event.target.value;
    setCondition({ ...condition, filter: value });
  };

  const handleSearch = (e) => {
    let value = e.target.value;
    setCondition({ ...condition, search: value });
  };
  const handleSorting = (e) => {
    let value = e.target.value;
    if (value === "low") {
      setCondition({ ...condition, sorting: value });
      setOrderData({
        data:
          orderData.data &&
          [...orderData.data].sort((a, b) => {
            if (a.order_date < b.order_date) return -1;
          }),
        totalCount: orderData.totalCount,
      });
    } else if (value === "high") {
      setCondition({ ...condition, sorting: value });
      setOrderData({
        data:
          orderData.data &&
          [...orderData.data].sort((a, b) => {
            if (a.order_date > b.order_date) return -1;
          }),
        totalCount: orderData.totalCount,
      });
    } else {
      handleFetchOrders();
      setCondition({ ...condition, sorting: value });
    }
  };


 



  return (
    <div className={styles.mains}>
      <AlertsSuccess />
      <div className={styles.component}>
        <Tableheader
          handleFilter={handleFilter}
          handleSorting={handleSorting}
          filterdata={filterdata}
          sortdata={sortdata}
          condition={condition}
          search={condition.search}
          handleSearch={handleSearch}
          button=""
        />

        <div className={styles.moreoption_div}>
          <Moreoption
            activetab={activetab}
            moreOptions={moreOptions}
            data={data}
            setData={setData}
            handleMorecategories={handleMorecategories}
            usertype={usertype}
            setUsertype={setUsertype}
          />
        </div>
        {activetab === "buy" ? (
          <SixcolimgAdminBuyOrders
            activetab={activetab}
            head={buyhead}
            data={orderData?.data
              .filter((val) => {
                if (condition.filter === "") {
                  return val;
                } else if (condition.filter === "pending") {
                  return val.order_status === "Pending";
                } else if (condition.filter === "approved") {
                  return val.order_status === "Approved";
                } else {
                  return val.order_status === "Rejected";
                }
              })
              // First filter by search term
              .filter((val) => val._id.toLowerCase().includes(condition.search))
              // Then filter based on order_type.name inside items array
              .filter((val) =>
                val.items.some(
                  (item) => item.order_type && item.order_type.name === "Buy"
                )
              )
              // Map the filtered data to render each item properly
              .map((val) => ({
                _id: val._id,
                items: val.items.filter(
                  (item) => item.order_type && item.order_type.name === "Buy"
                ),
              }))}
          />
        ) : activetab === "rent" ? (
          <OrderTable
            activetab={activetab}
            head={printhead}
            data={orderData?.data
              .filter((val) => {
                if (condition.filter === "") {
                  return val;
                } else if (condition.filter === "pending") {
                  return val.order_status === "Pending";
                } else if (condition.filter === "approved") {
                  return val.order_status === "Approved";
                } else {
                  return val.order_status === "Rejected";
                }
              })
              // First filter by search term
              .filter((val) => val._id.toLowerCase().includes(condition.search))
              // Then filter based on order_type.name inside items array
              .filter((val) =>
                val.items.some(
                  (item) => item.order_type && item.order_type.name === "Rent"
                )
              )
              // Map the filtered data to render each item properly
              .map((val) => ({
                _id: val._id,
                items: val.items.filter(
                  (item) => item.order_type && item.order_type.name === "Rent"
                ),
              }))}
          />
        ) : (
          <Sixcolimg
            activetab={activetab}
            head={printhead}
            data={orderData?.data
              .filter((val) => {
                if (condition.filter === "") {
                  return val;
                } else if (condition.filter === "pending") {
                  return val.order_status === "Pending";
                } else if (condition.filter === "approved") {
                  return val.order_status === "Approved";
                } else {
                  return val.order_status === "Rejected";
                }
              })
              // First filter by search term
              .filter((val) => val._id.toLowerCase().includes(condition.search))
              // Then filter based on order_type.name inside items array
              .filter((val) =>
                val.items.some(
                  (item) => item.order_type && item.order_type.name === "Print"
                )
              )
              // Map the filtered data to render each item properly
              .map((val) => ({
                _id: val._id,
                items: val.items.filter(
                  (item) => item.order_type && item.order_type.name === "print"
                ),
              }))}
          />
        )}
        {/* </> */}
        {/* ) : activetab === "rent" ? (
          <>
            <Moreoption
              activetab={activetab}
              moreOptions={moreOptions}
              handleMorecategories={handleMorecategories}
            />
            <Sixcolimg head={renthead} data={rentData} />
          </>
        ) : (
          <>
            <Moreoption
              activetab={activetab}
              moreOptions={moreOptions}
              handleMorecategories={handleMorecategories}
            />
            <Sixcolimg activetab={activetab} head={buyhead} data={buyData} />
          </>
        )} */}
        {orderData &&
          Array.isArray(orderData.data) &&
          orderData.data?.length > 0 && (
            <Pagination
              totaldata={orderData && orderData.totalCount}
              pagination={data}
              setPagination={setData}
            />
          )}


        {/* <Orderdetail /> */}
      </div>
    </div>
  );
}
