import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AlertsSuccess, Errornotify } from "../../../../common/alert/Alert"
import {
  fetcCompaingnsData,
  handleAddCompaingns,
  editCampaign,
  deleteCampaign,
} from "../../../../State/api/superadminApi"
import { setCompaingnsdata } from "../../../../State/Features/superadmin/compaingnSlice"
import Pagination from "../Pagination/Pagination"
import Addpopup from "../Popupdelete/addPopup/Addpopup"
import Tablesfour from "../Table/Fourcolumn/Tables"
import Tableheader from "../Table/Tableheader/Tableheader"
import styles from "./Campaigns.module.css"

const campaignhead = [
  {
    id: "artist_name ",
    label: "Campaigns Name",
  },
  {
    id: "campaign_code ",
    label: "Campaigns Code",
  },
  {
    id: "location",
    label: "Discount(%)",
  },
  {
    id: "like",
    label: "Validity",
  },
  {
    id: "followe",
    label: "Description",
  },
  {
    id: "action",
    label: "Action",
  },
]

const sortdata = [
  { label: "By Discount ↓", value: "low" },
  { label: "By Discount ↑", value: "high" },
]
const filterdata = [
  { label: "Oldest to Newest", value: "old" },
  { label: "Newest to Oldest", value: "new" },
]
export default function Campaigns() {
  const [button, setButton] = useState({ type: "add", text: "Add" })
  const [open, setOpen] = useState(false)
  const [edit, setEdit] = useState(false)
  const [editdataCamp, setEditdataCamp] = useState()
  const [data, setData] = useState({ offset: 1 })
  const [condition, setCondition] = useState({
    search: "",
    filter: "",
    sorting: "",
  })
  const dispatch = useDispatch()
  const compaingns = useSelector((state) => state.compaingnsdata.data)
  const fetchData = () => {
    dispatch(fetcCompaingnsData(data))
  }
  useEffect(() => {
    fetchData()
  }, [data])
  const handleAddCompaingn = (data) => {
    if (data.discount > 100) {
      Errornotify("Please select discount below 100")
    } else {
      dispatch(handleAddCompaingns(data, setOpen, fetchData))
    }
  }

  const handleDelete = (id) => {
    dispatch(deleteCampaign(id, fetchData))
  }
  const handleEditPopup = (data) => {
    setEditdataCamp(data)
    setEdit(true)
  }
  const handleEdit = (id, data) => {
    dispatch(editCampaign(id, data, setEdit, fetchData))
  }



  const handleSearch = (e) => {
    let value = e.target.value
    setCondition({ ...condition, search: value })
  }

  const handleSorting = (e) => {
    let value = e.target.value
    if (value === "low") {
      setCondition({ ...condition, sorting: value })
      dispatch(
        setCompaingnsdata({
          data:
            compaingns.data &&
            [...compaingns.data].sort((a, b) => {
              return a.discount - b.discount
            }),
          totalCount: compaingns.totalCount,
        })
      )
    } else if (value === "high") {
      setCondition({ ...condition, sorting: value })
      dispatch(
        setCompaingnsdata({
          data:
            compaingns.data &&
            [...compaingns.data].sort((a, b) => {
              return b.discount - a.discount
            }),
          totalCount: compaingns.totalCount,
        })
      )
    } else {
      fetchData()
      setCondition({ ...condition, sorting: value })
    }
  }

  const handleFilter = (event) => {
    const value = event.target.value
    if (value === "old") {
      setCondition({ ...condition, filter: value })
      dispatch(
        setCompaingnsdata({
          data:
            compaingns.data &&
            [...compaingns.data].sort((a, b) => {
              return new Date(a.createdAt) - new Date(b.createdAt)
            }),
          totalCount: compaingns.totalCount,
        })
      )
    } else if (value === "high") {
      setCondition({ ...condition, filter: value })
      dispatch(
        setCompaingnsdata({
          data:
            compaingns.data &&
            [...compaingns.data].sort((a, b) => {
              return new Date(a.createdAt) - new Date(b.createdAt)
            }),
          totalCount: compaingns.totalCount,
        })
      )
    } else {
      fetchData()
      setCondition({ ...condition, filter: value })
    }
  }

  
  return (
    <div className={styles.mains}>
      <AlertsSuccess />
      {open ? (
        <Addpopup
          handleAddCompaingn={handleAddCompaingn}
          poptype="campaigns"
          open={open}
          setOpen={setOpen}
        />
      ) : (
        ""
      )}
      {edit ? (
        <Addpopup
          open={edit}
          setOpen={setEdit}
          editdataCamp={editdataCamp}
          handleEdit={handleEdit}
          poptype="editCamp"
        />
      ) : (
        ""
      )}
      <div className={styles.components}>
        <Tableheader
          handleFilter={handleFilter}
          handleSorting={handleSorting}
          filterdata={filterdata}
          sortdata={sortdata}
          condition={condition}
          search={condition.search}
          handleSearch={handleSearch}
          setOpen={setOpen}
          button={button}
        />
        <Tablesfour
          handleDelete={handleDelete}
          activetab="campaigns"
          handleEditPopup={handleEditPopup}
          head={campaignhead}
          data={
            compaingns &&
            compaingns.data.filter((val) =>
              val.name.toLowerCase().includes(condition.search.toLowerCase())
            )
          }
        />
        <Pagination
          totaldata={compaingns && compaingns.totalCount}
          pagination={data}
          setPagination={setData}
        />
      </div>
    </div>
  )
}
