import React from "react";
import { Button } from "@mui/material";
import styles from "./Acceptreject.module.css";

export default function CommonAcceptreject({ data, handleAcceptRejejectArt }) {
  const exhibitionData = data?.data;

  if (!exhibitionData) {
    return <h2 align="center">No Data Found</h2>;
  }

  const { exhibition, totalCount, data: items } = exhibitionData;

  return (
    <div className={styles.mains}>
      <h1 className={styles.profiler_name}>{exhibition}</h1>
      <hr />
      <div className={styles.detail_id}>
        <h1>Exhibition Name</h1>
        <p>{exhibition}</p>
      </div>
      <div className={styles.detail_id}>
        <h1>No. of Items</h1>
        <p>{totalCount}</p>
      </div>
      {items && items.length > 0 ? (
        items.map((item) => (
          <div key={item._id}>
            <hr />
            <div className={styles.status}>
              <h1>{item.exhibition}</h1>
              {item.is_accepted === "Pending" ? (
                <div className={styles.action_button}>
                  <Button
                    onClick={() =>
                      handleAcceptRejejectArt(item._id, { is_accepted: "Approved" })
                    }
                    variant="contained"
                    sx={{ height: "46px", width: "120px" }}
                  >
                    Accept
                  </Button>
                  <Button
                    onClick={() =>
                      handleAcceptRejejectArt(item._id, { is_accepted: "Rejected" })
                    }
                    variant="outlined"
                    sx={{ height: "46px", width: "120px" }}
                  >
                    Decline
                  </Button>
                </div>
              ) : (
                item.is_accepted
              )}
            </div>
            <div className={styles.more_detail}>
              {item.item?.map((val) => (
                <div key={val._id}>
                  <div>
                    <img
                      src={val.image}
                      height="85px"
                      width="121px"
                      alt="img"
                    />
                  </div>
                  <div className={styles.more_detail_child}>
                    <div className={styles.title}>Bidding Price:</div>
                    <div className={styles.content}>not in db</div>
                  </div>
                  <div className={styles.more_detail_child}>
                    <div className={styles.title}>Material:</div>
                    <div className={styles.content}>
                      {val.material.map((c, index) => (
                        <span key={index}>{c.name}, </span>
                      ))}
                    </div>
                  </div>
                  <div className={styles.more_detail_child}>
                    <div className={styles.title}>Category:</div>
                    <div className={styles.content}>{val.category}</div>
                  </div>
                  <div className={styles.more_detail_child}>
                    <div className={styles.title}>Dimension:</div>
                    <div className={styles.content}>
                      {val.height} * {val.width} * {val.depth}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))
      ) : (
        <h2 align="center">No Items Found</h2>
      )}
    </div>
  );
}
