import {
  Button,
  Fade,
  Grid,
  Modal,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import homeImage from "../../../../constant";
import React, { useEffect, useState } from "react";
import styles from "./Fillexshibition.module.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Pagination, PaginationItem } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";

import dayjs from "dayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Stack from "@mui/material/Stack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { fetchAllItemData } from "../../../../State/api/userApi";
import { useDispatch, useSelector } from "react-redux";
import { fetchArtsData } from "../../../../State/api/superadminApi";
import { PropaneTwoTone } from "@mui/icons-material";
import serverimage from "../../../../serverConstant";

const datecolor = "#636365";
export default function Fillexshibition(props) {
  const [value, setValue] = useState(dayjs(""));
  const [selectOpen, setSelectOpen] = React.useState(false);
  const [age, setAge] = useState("");
  const [addDta, setAddData] = useState({
    title: "",
    concept: "",
    start_date: "",
    end_date: "",
    chief_guest: "",
    city: "",
    venue_address: "",
    price_per_art: 7000,
    is_paid: false,
    artist_ids: [],
  });

  const dispatch = useDispatch();

  const allitems = useSelector((state) => state.artsdata.data);

  const handleChangeAddData = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    props.setAddArtinExhibition({ ...props.addArtinExhibition, [name]: value });
    setSelectOpen(false);
    console.log(name, value);
  };

  // const handleSelectPainting = (e) => {
  //     const vl = e;
  //     const a = allitems && allitems.data.filter(v => props.addArtinExhibition.item_id.includes(v._id)).map(c => (c.name))
  //     console.log(a, allitems.data)
  //     // const a = vl.forEach((element) => {
  //     var item_id = [...props.addArtinExhibition.item_id];
  //     const dt = props.addArtinExhibition.item_id.map((val) => val);
  //     const index = dt.indexOf(vl);
  //     if (index === -1) {
  //         item_id.push(vl);
  //         props.setAddArtinExhibition({ ...props.addArtinExhibition, item_id: item_id });
  //     } else {
  //         // item_id.slice(index, 1);
  //         item_id.splice(index, 1);
  //         props.setAddArtinExhibition({ ...props.addArtinExhibition, item_id: item_id });
  //     }
  //     // });
  // };

  const handleClose = () => {
    props.setOpen(false);
  };
  useEffect(() => {
    dispatch(fetchArtsData({ is_accepted: "Approved", offset: 1 }));
  }, []);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Adjust how many items you want per page

  // Function to handle page changes
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    setSelectOpen(true);
  };

  // Slice the data based on current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = allitems && allitems.data?.slice(startIndex, endIndex);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Box className={styles.modal_uploaded}>
            <div className={styles.cancel_btn_div}>
              <img
                src={"/img/cancel_cross.png"}
                onClick={handleClose}
                width="21px"
                height="21px"
                alt="img"
              />
            </div>
            <div className={styles.mains}>
              <h2>Fill {props.text} Details</h2>
              <h2 style={{ fontWeight: "500", fontSize: "24px" }}>
                {sessionStorage.getItem("exhibition_name") || ""}
              </h2>
              <div>
                <label className={styles.input_label}>Painting *</label>
            <FormControl fullWidth>
  <Select
    open={selectOpen}
    fullWidth
  
    value={props.addArtinExhibition.item_id} // Ensure value is bound to selected item_id
  onClose={() => setSelectOpen(false)} // Keep this to close the dropdown when the user manually clicks outside
    onOpen={() => setSelectOpen(true)}
    displayEmpty
    inputProps={{ "aria-label": "Without label" }}
    renderValue={
      props.addArtinExhibition.item_id.length !== 0
        ? undefined
        : () => <span>Select Painting</span>
    }
    sx={{
      fontFamily: "DM Sans",
      fontSize: "16px",
      color: "#636365",
      lineHeight: "21px",
      backgroundColor: "#f3f3f3",
      border: "none",
      ".MuiOutlinedInput-notchedOutline": {
        borderColor: "rgba(228, 219, 233, 0.25)",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgba(228, 219, 233, 0.25)",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgba(228, 219, 233, 0.25)",
      },
    }}
  >
    {currentItems?.map((val) => (
      <MenuItem value={val._id} key={val._id} className={styles.menu_item}>
        <div style={{ width: "100%",cursor:"default" }}>
          <div className={styles.details_div}>
            <div className={styles.img_detail_div}>
              <div className={styles.details_img_div}>
                <img src={val.image} width="121px" height="85px" alt="img" />
              </div>
              <div className={styles.detail}>
                <h6 className={styles.title}>
                  {val.name.length > 10 ? val.name.substring(0, 10) + "..." : val.name}
                </h6>
                <p className={styles.content}>{val.artist_name}</p>
              </div>
            </div>
            <hr className={styles.hrverticle} />
            <div className={styles.detail}>
              <h6 className={styles.title}>Views</h6>
              <p className={styles.content}>{val.views}</p>
            </div>
            <hr className={styles.hrverticle} />
            <div className={styles.detail}>
              <h6 className={styles.title}>Likes</h6>
              <p className={styles.content}>{val.likes}</p>
            </div>
            <hr className={styles.hrverticle} />
            <div className={styles.button_detail_div}>
              <div className={styles.detail}>
                <h6 className={styles.title}>Stock in Qty.</h6>
                <p className={styles.content}>{val.sale?.qty}</p>
              </div>
            </div>
            <div className={val.added ? styles.add_btn_div : styles.add_btn_div}>
              {props.addArtinExhibition.item_id === val._id ? (
                ""
              ) : val?.sale?.qty > 0 ? (
                <Button
                  variant="contained"
                  name="item_id"
                  sx={{
                    width: "80px",
                    height: "44px",
                    borderRadius: "6px",
                  }}
                  onClick={() => {
                    props.setAddArtinExhibition({ ...props.addArtinExhibition, item_id: val._id });
                  
                  }}
                >
                  Add
                </Button>
              ) : (
                <Button
                  variant="contained"
                  disabled={
                    !(props.addArtinExhibition.item_id !== val._id && val?.sale?.qty > 0)
                  }
                  name="item_id"
                  sx={{
                    width: "80px",
                    height: "44px",
                    borderRadius: "6px",
                  }}
                >
                  Add
                </Button>
              )}
            </div>
          </div>
        </div>
      </MenuItem>
    ))}
      <Box className={styles.pagination_box} sx={{}}>
                      <Pagination
                        count={Math.ceil(allitems?.data?.length / itemsPerPage)} // Total pages
                        page={currentPage}
                        onChange={handlePageChange}
                        variant="outlined"
                        shape="rounded"
                        renderItem={(item) => (
                          <PaginationItem
                            sx={{
                              "&.Mui-selected": {
                                backgroundColor: "black", // Set the selected background to black
                                color: "white", // Ensure the text color is visible
                                "&:hover": {
                                  backgroundColor: "black", // Maintain black on hover
                                },
                              },
                            }}
                            slots={{
                              previous: ArrowBackIcon,
                              next: ArrowForwardIcon,
                            }}
                            {...item}
                          />
                        )}
                      />
                    </Box>
  </Select>
  
</FormControl>

                {props.errors.item_id && (
                  <div
                    style={{
                      color: "red",
                      fontWeight: "500",
                      fontSize: "14px",
                      marginTop: "5px",
                    }}
                  >
                    {props.errors.item_id}
                  </div>
                )}
              </div>
              <label className={styles.input_label}>Base Price *</label>
              <TextField
                className={styles.input_outline}
                onChange={handleChangeAddData}
                name={props.text === "Auction" ? "item_price" : "price"}
                inputProps={{
                  style: {
                    fontFamily: "DM Sans",
                    fontSize: "16px",
                    lineHeight: "21px",
                  },
                }}
                type="number"
                sx={{
                  backgroundColor: "#f3f3f3",
                  // "& .MuiOutlinedInput-root.Mui-focused": {
                  //     "& > fieldset": {
                  //         border: '1px solid #665D6F'
                  //     },
                  // },
                  // "& .MuiFilledInput-input": {
                  //     opacity: '0.5',
                  //     /* Grey */

                  //     border: '1px solid #665D6F',
                  //     borderRadius: '5px'
                  // },
                  "& .MuiInputLabel-root": { color: "green" }, //styles the label
                  "& .MuiOutlinedInput-root": {
                    "& > fieldset": { border: "none" },
                  },
                  // "& .MuiOutlinedInput-root:hover": {
                  //     "& > fieldset": {
                  //         border: '1px solid #665D6F'
                  //     }
                  // }
                }}
                variant="outlined"
                fullWidth
                placeholder="Enter Price"
              />
              {props.errors.price && (
                <div
                  style={{
                    color: "red",
                    fontWeight: "500",
                    fontSize: "14px",
                    marginTop: "5px",
                  }}
                >
                  {props.errors.price}
                </div>
              )}

              {/* <TextareaAutosize maxRows={5} minRows={5} fullWidth placeholder='Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.' /> */}

              <div>
                <label className={styles.input_label}>Description *</label>
                <br />
                <TextareaAutosize
                  maxRows={5}
                  minRows={5}
                  onChange={handleChangeAddData}
                  name="description"
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                  }}
                  className={styles.textarea}
                  fullWidth
                  placeholder="Enter your description"
                />
                {props.errors.description && (
                  <div
                    style={{
                      color: "red",
                      fontWeight: "500",
                      fontSize: "14px",
                    }}
                  >
                    {props.errors.description}
                  </div>
                )}
              </div>

              <Button
                s={{
                  display: "flex",
                  justiyContent: "center",
                  alignItems: "center",
                  columnGap: "10px",
                }}
              >
                <img
                  src={"img/addicon.png"}
                  width="13px"
                  height="13px"
                  style={{ marginRight: "5px" }}
                  alt="img"
                />
                Add another painting
              </Button>
              <div className={styles.btn_div}>
                <Button
                  variant="contained"
                  sx={{ width: "156px", height: "54px", borderRadius: "10px" }}
                  onClick={props.handleAdd}
                >
                  Save
                </Button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
