import React, { useCallback, useState } from "react";
import styles from "./Commonuploadart.module.css";
import Steppergallery from "../stepper/Steppergallery";
import {
  Autocomplete,
  Button,
  Checkbox,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import homeImage from "../../../../constant";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addArtData } from "../../../../State/Features/Artist/uploadartSlice";
import { AlertsSuccess, Errornotify } from "../../../../common/alert/Alert";
import serverimage from "../../../../serverConstant";
import { firstFormArtValidation } from "../../../../validation/firstFormArtValidation";

const stage = 0;
export default function Commonuploadart(props) {
 
  const [firstFormError, setFirstFormError] = useState({});
  console.log(firstFormError,'firstFormError')

  const MIN_FILE_SIZE = 100; // Minimum file size in KB
  const MAX_FILE_SIZE = 5000; // Maximum file size in KB (5 MB)
  
  const handleImageChange = useCallback(
    (e) => {
      if (e.target && e.target.files && e.target.files.length > 0) {
        const filesToAdd = Array.from(e.target.files); // Convert to array
        const totalImages = props.selectedFiles.length + filesToAdd.length;
  
        // Check the total number of images
        if (totalImages > 5) {
          Errornotify("You can't upload more than 5 images.");
          return; // Exit the function without processing further
        }
  
        // Validate file sizes (100 KB to 5000 KB)
        const validFiles = [];
        const allowedTypes = ["image/jpeg", "image/jpg", "image/png"]; // Allowed MIME types
        for (let file of filesToAdd) {
          const fileSizeInKB = file.size / 1024; // Convert to KB
  
                  // File type validation
        if (!allowedTypes.includes(file.type)) {
          Errornotify("Only .jpg, .jpeg, or .png files are allowed.");
          continue; // Skip invalid file types
        }

          // File size validation
          if (fileSizeInKB < MIN_FILE_SIZE) {
            Errornotify("File size should be greater than 100KB");
          } else if (fileSizeInKB > MAX_FILE_SIZE) {
            Errornotify("File size should be less than 5MB");
          } else {
            validFiles.push(file); // Only valid files are added
          }
        }
  
        // Exit if no valid files
        if (validFiles.length === 0) return;
  
        // Update selectedFiles for preview
        const filesArray = validFiles.map((file) => URL.createObjectURL(file));
        props.setSelectedFiles((prevImages) => prevImages.concat(filesArray));
  
        // Update the props.uploadArt.images
        const images = props.uploadArt.images;
        images.push(...validFiles);
        props.setUploadArt({
          ...props.uploadArt,
          images: images,
        });
      }
    },
    [props.uploadArt, props.selectedFiles]
  );
  const handleImageRemove = useCallback(
    (index) => {
      // Remove the selected image from the preview array
      props.setSelectedFiles((prevImages) => prevImages.filter((_, i) => i !== index));
  
      // Remove the corresponding file from the props.uploadArt.images array
      const updatedImages = props.uploadArt.images.filter((_, i) => i !== index);
  
      // Update the parent component's state
      props.setUploadArt({
        ...props.uploadArt,
        images: updatedImages,
      });
    },
    [props.uploadArt]
  );
  
  const renderPhotos = (source, handleRemoveImage) => {
    return source?.map((photo, index) => (
      <div key={photo} className={styles.imageContainer}>
        <div className={styles.deleteButton}>
          <IconButton onClick={() => handleImageRemove(index)}>
            <img
              src={"/img/delete.png"}
              height="15px"
              width="14px"
              alt="delete"
            />
          </IconButton>
        </div>
        <img className={styles.imgs} src={photo} alt="uploaded" />
      </div>
    ));
  };
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const uploadarts = useSelector((state) => state.uploadArt);

  const handleChangeMainImage = (e) => {
    if (e.target.files[0]) {
      const img = e.target.files[0];
      const imgs = URL.createObjectURL(e.target.files[0]);
      const MIN_FILE_SIZE = 100; // kb
      const MAX_FILE_SIZE = 5000; // kb
      const ALLOWED_TYPES = ["image/jpeg", "image/jpg", "image/png"]; // Allowed file types

      const fileSizeKiloBytes = img.size / 1024;
   
        // File type validation
    if (!ALLOWED_TYPES.includes(img.type)) {
      Errornotify("Only .jpg, .jpeg, or .png files are allowed.");
      return; // Exit if file type is invalid
    }
    
      if (fileSizeKiloBytes < MIN_FILE_SIZE) {
        Errornotify("File size should be greater than 100kb");
      } else if (fileSizeKiloBytes > MAX_FILE_SIZE) {
        Errornotify("File size should be less than 5mb");
      } else {
        props.setUploadArt({ ...props.uploadArt, image: img });
        props.setSrc({ ...props.src, main: imgs });
      }
    }
  };

  const handleRemoveimage = () => {
    props.setSrc({ ...props.src, main: "" });
    props.setUploadArt({ ...props.uploadArt, image: "" });
  };
  const handlePopup = () => {
    console.log(props.uploadArt.name, props.uploadArt.image, props.uploadArt.artist_name);
    const { firstFormErrors, isValidFirstForm } = firstFormArtValidation(
      props.uploadArt.name,
      props.uploadArt.image,
        props.uploadArt.artist_name
    );
    if (isValidFirstForm) {
      props.setOpen && props.setOpen(true);
      setFirstFormError({});
    } else {
      setFirstFormError(firstFormErrors);
      window.scrollTo({
        top: 150,
        behavior:"smooth"
      })
    }
  
  };
 
  return (
    <div className={styles.mains} sx={{ background: "blue" }}>
      <AlertsSuccess />
      <Grid container spacing={{ sm: 5 }} columns={{ xs: 12, sm: 12, md: 12 }}>
        <Grid item xs={12} sm={12} md={12}>
          <div className={styles.atwork_div}>
            <h2 className={styles.main_heading}>ArtWork</h2>
            <label className={styles.labels}>Title*</label>
            <br />
            <TextField
              sx={{
                mt: 1,
                backgroundColor: "#f6f6f6",
                borderColor: "#ccc",
              }}
              fullWidth
              placeholder="Enter your title"
              onChange={props.handleChangedata}
              name="name"
              value={props.uploadArt && props.uploadArt.name ? props.uploadArt.name : ""}
            />{" "}
            {firstFormError?.title ? (
              <span
                style={{ color: "red", fontWeight: "bold", fontSize: "12px" }}
              >
                {firstFormError?.title}
              </span>
            ) : null}
            <br />
            {localStorage.getItem("usertype") === "gallery" ?  <div style={{marginTop:"10px"}}>
            <label className={styles.labels} >Artist Name*</label>
            <br />
            <TextField
              sx={{
                mt: 1,
                backgroundColor: "#f6f6f6",
                borderColor: "#ccc",
              }}
              fullWidth
              placeholder="Enter artist name"
              onChange={props.handleChangedata}
              name="artist_name"
              //    value={uploadarts.data ? uploadarts.data.name : uploadarts.name}
              value={props.uploadArt && props.uploadArt.artist_name ? props.uploadArt.artist_name : ""}
            />{" "}
            </div> : null}
         {firstFormError?.artist_name ? (
              <span
                style={{ color: "red", fontWeight: "bold", fontSize: "12px" }}
              >
                {firstFormError?.artist_name}
              </span>
            ) : null}
            <div style={{ marginTop: "10px" }}>
              <label className={styles.labels}>Image*</label>
              <div className={styles.upload_images}>               
                <div style={{ width: "100%" }}>
                  <div className={styles.img_box_div}>                    
                    <div className={styles.upload_div_main}>
                      <div className={styles.preview_img}>
                        {props.src?.main === "" &&
                        !props.uploadArt?.image?.length ? (
                          <>
                            <div className={styles.img_box}>
                              <div
                                htmlFor="input"
                                name="image"
                                style={{ textAlign: "center" }}
                              >
                                <input
                                  onChange={handleChangeMainImage}
                                  accept="image/*"
                                  type="file"
                                  name="image"
                                  id="input"
                                  hidden
                                />
                                <label htmlFor="input" className={styles.label}>
                                  <div className={styles.image_div}>
                                    <img
                                      src={"/img/uploadimg.png"}
                                      width="100%"
                                      height="100%"
                                      alt="img"
                                    />
                                  </div>
                                  <p> Upload Your Primary Image</p>
                                </label>
                              </div>
                            </div>
                          </>
                        ) : props.src?.main !== "" && props.uploadArt?.image ? (
                          <>                           
                            <input
                              name="image"
                              onChange={handleChangeMainImage}
                              accept="image/*"
                              type="file"
                              id="input"
                              hidden
                            />
                            <label htmlFor="input">
                              <img
                                htmlFor="input"
                                src={
                                  props.src.main
                                    ? props.src.main
                                    :  props.uploadArt.image
                                }
                                alt="fimg"
                                id="img"
                                name="image"
                                className={styles.img_view}
                              />
                            </label>
                          </>
                        ) : (
                          <>                      
                            <input
                              name="image"
                              onChange={handleChangeMainImage}
                              accept="image/*"
                              type="file"
                              id="input"
                              hidden
                            />
                            <label htmlFor="input">
                              <img
                                htmlFor="input"
                                src={
                                  props.src?.main
                                    ? props.src?.main
                                    :  props.uploadArt?.image
                                }
                                alt="ivmg"
                                id="img"
                                name="image"
                                className={styles.img_view}
                              />
                            </label>
                            {/* //   </div> */}
                            {/* // </div> */}
                          </>
                        )}
                        <div className={styles.file_div}></div>
                      </div>
                     
                      {props.src?.main !== "" ? (
                        <div className={styles.del_img_div}>
                          <IconButton onClick={handleRemoveimage}>
                            <img
                              src={"/img/delete.png"}
                              height="15px"
                              width="14px"
                              alt="img"
                            />
                          </IconButton>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className={styles.img_box}>
                      <div
                        htmlFor="file"
                        name="image"
                        style={{ textAlign: "center" }}
                        className={styles.preview_imgs}
                      >
                        <input
                          type="file"
                          id="file"
                          multiple
                          accept="image/*"
                          onChange={handleImageChange}
                          hidden
                        />
                        <label htmlFor="file" className={styles.label}>
                          <div className={styles.image_div}>
                            <img
                              src={"/img/uploadimg.png"}
                              width="100%"
                              height="100%"
                              alt="img"
                            />
                          </div>
                          <p> Upload your secondary image</p>
                        </label>
                      </div>
                    </div>
                    
                  </div>
                  {firstFormError?.image ? (
              <span
                style={{ color: "red", fontWeight: "bold", fontSize: "12px" }}
              >
                {firstFormError?.image}
              </span>
            ) : null}
                  <div className={styles.result}>
                    {renderPhotos(props.selectedFiles)}
                  </div>
                </div>
              </div>
              <p>Please select size in between 100kb to 5000kb</p>
              <p className={styles.des}>
                Upload an image of your artwork.
                PNG file, with a color format of RGB.
              </p>
            </div>
            <div>
              <label className={styles.labels}>Copyright</label>
              <div className={styles.copyright_div}>
                <p>Are you the copyright owner of this artwork?</p>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    onChange={props.handleChangedata}
                    value={props.uploadArt && props.uploadArt.copyright ? props.uploadArt.copyright : ""}
                    name="copyright"
                  >
                    <FormControlLabel
                      className={styles.radio_label}
                      control={<Radio />}
                      label="Yes"
                      value="Yes"
                      name="copyright"
                    />
                    <FormControlLabel
                      className={styles.radio_label}
                      value="No"
                      control={<Radio />}
                      label="No"
                      name="copyright"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            <div className={styles.btn_div}>
              <Button
                variant="contained"
                onClick={handlePopup}
                sx={{ width: "156px", height: "54px", borderRadius: "10px" }}
              >
                Continue
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
