import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AlertsSuccess } from "../../../common/alert/Alert";
// import Pagination from "../../../components/Pages/List/Pagination/Pagination";
import Sixcolimg from "../../../components/Pages/List/Table/sixcolumn/Sixcolimg/Sixcolimg";
import Tableheader from "../../../components/Pages/List/Table/Tableheader/Tableheader";
import Recretepopup from "../../../gallery/components/popups/recretepopup/Recretepopup";
import {
  acceptDeclineQuateRequest,
  fetchQuateRequestData,
} from "../../../State/api/artistapi";
import { setQuateData } from "../../../State/Features/Artist/quateDataSlice";
import SixcolimgArtist from "../../commonTables/SixcolimgArtist/SixcolimgArtist";
import styles from "./Artistquoterequest.module.css";
import Requestquotepopup from "../../../gallery/components/popups/recretepopup/RequestQuote";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Pagination, PaginationItem } from "@mui/material";
import TableheaderQuote from "../../../components/Pages/List/Table/Tableheader/TableheaderForQuote";
const upcominghead = [
  {
    id: "artist_name ",
    label: "Image",
  },
  {
    id: "artist_name ",
    label: "Buyer Name",
  },

  {
    id: "like",
    label: "Date",
  },
  {
    id: "like",
    label: "Message",
  },
  {
    id: "followe",
    label: "Action",
  },
];
const datas = [
  {
    id: 1,
    title: "Name",
    des: "John Deo",
  },
  {
    id: 2,
    title: "Category",
    des: "Painting",
  },
  {
    id: 3,
    title: "Subject",
    des: "Atrial",
  },
  {
    id: 4,
    title: "Request date",
    des: "Jul 21, 2021",
  },
  {
    id: 5,
    title: "Name",
    des: "John Deo",
  },
];
const sortdata = [
  { label: "Oldest Date ↓", value: "low" },
  { label: "Newest Date ↑", value: "high" },
];
const filterdata = [
  { label: "Approved", value: "approved" },
  { label: "Rejected", value: "rejected" },
  { label: "Pending", value: "pending" },
  //  { label: "Rejected", value: "rejected" },
];
export default function Artistquoterequest() {
  const [open, setOpen] = useState(false);
  const [condition, setCondition] = useState({
    search: "",
    filter: "",
    sorting: "",
  });
  const [acceptPopupData, setAcceptPopuupData] = useState(null);

  const dispatch = useDispatch();
  const quatedt = useSelector((state) => state.quateRequestData.data);
  const [data, setData] = useState({ offset: 1 });
  console.log("quatedt", quatedt);

  const fetchData = () => {
    dispatch(fetchQuateRequestData(data));
  };
  useEffect(() => {
    fetchData();
  }, [data]);

  const handleFilter = (event) => {
    const value = event.target.value;
    setCondition({ ...condition, filter: value });
  };

  const handleSearch = (e) => {
    let value = e.target.value;
    setCondition({ ...condition, search: value });
  };

  const handleSorting = (e) => {
    let value = e.target.value;
    if (value === "low") {
      setCondition({ ...condition, sorting: value });
      // dispatch(
      //   setQuateData({
      //     data:
      //       quatedt.data &&
      //       [...quatedt.data].sort((a, b) => {
      //         if (a.updatedAt.toLowerCase() < b.updatedAt.toLowerCase())
      //           return -1;
      //       }),
      //     totalCount: quatedt.totalCount,
      //   })
      // );
    } else if (value === "high") {
      setCondition({ ...condition, sorting: value });
      // dispatch(
      //   setQuateData({
      //     data:
      //       quatedt.data &&
      //       [...quatedt.data].sort((a, b) => {
      //         if (a.updatedAt.toLowerCase() > b.updatedAt.toLowerCase())
      //           return -1;
      //       }),
      //     totalCount: quatedt.totalCount,
      //   })
      // );
    } else {
      fetchData();
      setCondition({ ...condition, sorting: value });
    }
  };
  const handlePopupData = (dt) => {
    setOpen(true);
    setAcceptPopuupData(dt);
  };
  const handleAcceptRejectQuate = (id, dt) => {
    dispatch(acceptDeclineQuateRequest(id, dt, fetchData, setOpen));
  };
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Adjust how many items you want per page
  
    // Function to handle page changes
    const handlePageChange = (event, page) => {
      setCurrentPage(page);
    };
  
    // Slice the data based on current page
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
const sortedData = [...(quatedt?.data || [])]?.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
const currentItems = sortedData?.slice(startIndex, endIndex)?.map(item => ({ ...item }));

console.log(currentItems, 'currentItems');


  return (
    <div className={styles.mains}>
      <AlertsSuccess />
      {open ? (
        <Requestquotepopup
          handleAcceptRejectQuate={handleAcceptRejectQuate}
          acceptPopupData={acceptPopupData}
          data={datas}
          open={open}
          setOpen={setOpen}
          activetab="qouterequest"
        />
      ) : (
        ""
      )}
      <div className={styles.components}>
        <TableheaderQuote
          button=""
          handleFilter={handleFilter}
          handleSorting={handleSorting}
          handleSearch={handleSearch}
          filterdata={filterdata}
          sortdata={sortdata}
          condition={condition}
          search={condition.search}
        />
        <SixcolimgArtist
          setOpen={setOpen}
          activetab="quoterequest"
          head={upcominghead}
          handlePopupData={handlePopupData} 
          data={
            currentItems?.filter((val) =>
                (val.first_name + " " + val.last_name)
                  .toLowerCase()
                  .includes(condition.search.toLowerCase())
              )
              .filter((val) => {
                if (condition.filter === "approved") {
                  return val.is_accepted && val.is_accepted === "Approved";
                } else if (condition.filter === "rejected") {
                  return val.is_accepted && val.is_accepted === "Rejected";
                } else if (condition.filter === "pending") {
                  return val.is_accepted && val.is_accepted === "Pending";
                } else {
                  return val;
                }
              }).sort((a, b) => {
      if (condition.sorting === "low") {
        return new Date(a.updatedAt) - new Date(b.updatedAt); // Sort price low to high
      } else if (condition.sorting === "high") {
        return new Date(b.updatedAt) - new Date(a.updatedAt); // Sort price high to low
      }
      return 0; // No sorting if condition.sorting is not 'low' or 'high'
    })
          }
        />
        <Box className={styles.pagination_box} sx={{}}>
          <Pagination
            count={Math.ceil(quatedt?.data?.length / itemsPerPage)} // Total pages
            page={currentPage}
            onChange={handlePageChange}
            variant="outlined"
            shape="rounded"
            renderItem={(item) => (
              <PaginationItem
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "black", // Set the selected background to black
                    color: "white", // Ensure the text color is visible
                    "&:hover": {
                      backgroundColor: "black", // Maintain black on hover
                    },
                  },
                }}
                slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                {...item}
              />
            )}
          />
        </Box>
      </div>
    </div>
  );
}
