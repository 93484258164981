import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import {
  Autocomplete,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Slider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import styles from "./Commonthirdsecond.module.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import homeImage from "../../../../../../constant";
import { useNavigate } from "react-router-dom";
import {
  handleAddArt,
  handleUploadUpdateArt,
} from "../../../../../../State/api/artistapi";
import { useDispatch, useSelector } from "react-redux";
import { Errornotify } from "../../../../../../common/alert/Alert";
import {
  AccessTimeFilled,
  AttachMoney,
  Delete,
  MonetizationOn,
} from "@mui/icons-material";
import api from "../../../../../../State/api/Api";
import PrintTableArtsmiley from "./TableForPrintCal";
import validationForThirdFormArt from "../../../../../../validation/thirdFormArtValidation";
const materialArray = [
  { id: 1, label: "Canvas", value: "canvas" },
  { id: 1, label: "Fine Art Paper", value: "fine art paper" },
  { id: 1, label: "Photo Paper", value: "photo paper" },
  { id: 1, label: "Poster", value: "poster" },
  { id: 1, label: "Metal", value: "metal" },
  { id: 1, label: "Acrylic Sandwich", value: "acrylic sandwich" },
];
const top100Films = [
  { label: "1 Month", year: 1994 },
  { label: "3 Month", year: 1972 },
  { label: "6 Month", year: 1974 },
  { label: "1 Year", year: 2008 },
  { label: "3 Year", year: 2008 },
  { label: "5 Year", year: 2008 },
  { label: "10 Year", year: 2008 },
];
export default function Commonthirdsecond(props) {
  console.log("third props page", props);

  const [thirdFormError, setThirdFormErrors] = useState({});
  const [printType, setPrintType] = useState("printbyme");
  const editArtId = useSelector((state) => state.editArtId?.id);
  const [materialValues, setMaterialValues] = useState({
    canvas: "",
    fineArtPaper: "",
    photoPaper: "",
    poster: "",
    metal: "",
    acrylicSandwich: "",
  });
  const [dynamicFieldsSale, setDynamicFieldsSale] = useState([{}]);
  const [buy_percentage, setBuyPercentage] = useState(null);
  const [curator_percentage, setCuratorPercentage] = useState(null);
  const [pricingData, setPricingData] = useState([]);
  const [rent_percentage, setRentPercentage] = useState(null);
  const [print_percentage, setPrintPercentage] = useState(null);
  const [printPricing, setPrintPricing] = useState({
    material: "",
    product: "",
    metal_print: "",
  });

  const [printImage, setPrintImage] = useState("");
  const [printPricingResponse, setPrintPricingResponse] = useState({});

  const [type, setType] = useState({ sale: true, rent: false, print: false });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleNavigateRoute = (path) => {
    navigate(path);
  };
  const handleChangeMainImage = (e) => {
    if (e.target.files[0]) {
      const img = e.target.files[0];
      const imgs = URL.createObjectURL(e.target.files[0]);
      const MIN_FILE_SIZE = 2000; // kb
      const MAX_FILE_SIZE = 5000; // kb
      const fileSizeKiloBytes = img.size / 1024;

      if (fileSizeKiloBytes < MIN_FILE_SIZE) {
        Errornotify("File size should be greater than 2mb");
      } else if (fileSizeKiloBytes > MAX_FILE_SIZE) {
        Errornotify("File size should be less than 5mb");
      } else {
        props.setUploadArt({ ...props.uploadArt, printImage: img });
        setPrintImage(imgs);
      }
    }
  };
  const handleChange = (event) => {
    const { name, checked } = event.target;
    setMaterialValues((prevMaterialValues) => {
      const updatedMaterialValues = { ...prevMaterialValues };
      if (checked) {
        // Add the value if it's checked
        updatedMaterialValues[name] = name;
      } else {
        // Remove the value if it's unchecked
        delete updatedMaterialValues[name];
      }
      return updatedMaterialValues;
    });
  };

  // Render the MenuItem components

  useEffect(() => {
    const filtered = materialArray.filter((item) => materialValues[item.value]);
    props.setUploadArt({
      ...props.uploadArt,
      print_by_artsmiley_material: filtered,
    });
  }, [materialValues]);
  const renderedMenuItems = materialArray
    .filter((item) => materialValues[item.value]) // Filter out undefined or false items
    .map((item) => (
      <MenuItem key={item.label} value={item.value}>
        {item.label}
      </MenuItem>
    ));

  useEffect(() => {
    setPrintType("printbyme");
  }, []);
  const getProductOptions = () => {
    switch (printPricing.material) {
      case "canvas":
        return [
          { value: "canvas print", label: "Canvas Print" },
          {
            value: "canvas print and stretch",
            label: "Stretch Print & Stretched",
          },
          {
            value: "canvas print with stretching and framing",
            label: "Canvas Print With Stretching & Framing",
          },
        ];
      case "fine art paper":
        return [
          { value: "fine art print", label: "Fine Art Print" },
          // { value: "mounting", label: "Mounting" },
          {
            value: "fine art print with framing",
            label: "Fine Art Print With Framing",
          },
        ];
      case "photo paper":
        return [
          { value: "photo paper print", label: "Photo Paper Print" },
          // { value: "mounting", label: "Mounting" },
          {
            value: "photo paper print with framing",
            label: "Photo Paper Print With Framing",
          },
        ];
      case "poster":
        return [
          { value: "poster", label: "Poster" },
          // { value: "mounting", label: "Mounting" },
          { value: "poster with framing", label: "Poster With Framing" },
        ];
      case "metal print 1.8 mm":
        return [
          { value: "metal print", label: "Metal Print" },
          {
            value: "metal print with aluminium hanging",
            label: "Metal Print With Aluminium Hanging",
          },
        ];
      case "metal print 2.8 mm":
        return [
          { value: "metal print", label: "Metal Print" },
          {
            value: "metal print with aluminium hanging",
            label: "Metal Print With Aluminium Hanging",
          },
        ];
      case "metal print 3.8 mm":
        return [
          { value: "metal print", label: "Metal Print" },
          {
            value: "metal print with aluminium hanging",
            label: "Metal Print With Aluminium Hanging",
          },
        ];
      case "metal print 2 mm":
        return [
          { value: "metal print", label: "Metal Print" },
          {
            value: "metal print with aluminium hanging",
            label: "Metal Print With Aluminium Hanging",
          },
        ];
      case "metal print 4 mm":
        return [
          { value: "metal print", label: "Metal Print" },
          {
            value: "metal print with aluminium hanging",
            label: "Metal Print With Aluminium Hanging",
          },
        ];
      case "metal print 6 mm":
        return [
          { value: "metal print", label: "Metal Print" },
          {
            value: "metal print with aluminium hanging",
            label: "Metal Print With Aluminium Hanging",
          },
        ];
      case "metal print 8 mm":
        return [
          { value: "metal print", label: "Metal Print" },
          {
            value: "metal print with aluminium hanging",
            label: "Metal Print With Aluminium Hanging",
          },
        ];
      case "metal print 10 mm":
        return [
          { value: "metal print", label: "Metal Print" },
          {
            value: "metal print with aluminium hanging",
            label: "Metal Print With Aluminium Hanging",
          },
        ];

      default:
        return [];
    }
  };

  const getProductOptionsMetalPrint = () => {
    switch (printPricing.metal_print) {
      case "metal print 1.8 mm":
        return [
          { value: "metal print", label: "Metal Print" },
          {
            value: "metal print with aluminium hanging",
            label: "Metal Print With Aluminium Hanging",
          },
        ];
      case "metal print 2.8 mm":
        return [
          { value: "metal print", label: "Metal Print" },
          {
            value: "metal print with aluminium hanging",
            label: "Metal Print With Aluminium Hanging",
          },
        ];
      case "metal print 3.8 mm":
        return [
          { value: "metal print", label: "Metal Print" },
          {
            value: "metal print with aluminium hanging",
            label: "Metal Print With Aluminium Hanging",
          },
        ];
      case "metal print 2 mm":
        return [
          { value: "metal print", label: "Metal Print" },
          {
            value: "metal print with aluminium hanging",
            label: "Metal Print With Aluminium Hanging",
          },
        ];
      case "metal print 4 mm":
        return [
          { value: "metal print", label: "Metal Print" },
          {
            value: "metal print with aluminium hanging",
            label: "Metal Print With Aluminium Hanging",
          },
        ];
      case "metal print 6 mm":
        return [
          { value: "metal print", label: "Metal Print" },
          {
            value: "metal print with aluminium hanging",
            label: "Metal Print With Aluminium Hanging",
          },
        ];
      case "metal print 8 mm":
        return [
          { value: "metal print", label: "Metal Print" },
          {
            value: "metal print with aluminium hanging",
            label: "Metal Print With Aluminium Hanging",
          },
        ];
      case "metal print 10 mm":
        return [
          { value: "metal print", label: "Metal Print" },
          {
            value: "metal print with aluminium hanging",
            label: "Metal Print With Aluminium Hanging",
          },
        ];

      default:
        return [];
    }
  };
  const handleChangePrintpricing = (e) => {
    const { name, value } = e.target;
    setPrintPricing({ ...printPricing, [name]: value });
    if (name === "material") {
      props.setUploadArt((prevUploadArt) => {
        const updatedPrintByArtsmiley = prevUploadArt.print_by_artsmiley.map(
          (item, index) => {
            if (index === 0) {
              // Specifically update the material field
              return { ...item, material: value };
            }
            return item;
          }
        );

        return {
          ...prevUploadArt,
          print_by_artsmiley: updatedPrintByArtsmiley,
        };
      });
    } else if (name === "metal_print") {
      props.setUploadArt((prevUploadArt) => {
        const updatedPrintByArtsmiley = prevUploadArt.print_by_artsmiley.map(
          (item, index) => {
            if (index === 0) {
              // Specifically update the material field
              return { ...item, metal_thikness: value };
            }
            return item;
          }
        );

        return {
          ...prevUploadArt,
          print_by_artsmiley: updatedPrintByArtsmiley,
        };
      });
    }
  };
  const handleChangePrintSize = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === "edition") {
      props.setUploadArt({
        ...props.uploadArt,
        print: {
          size: props.uploadArt.print
            ? props.uploadArt.print.size && props.uploadArt.print.size
            : [],
          print_price: props.uploadArt.print
            ? props.uploadArt.print.print_price
            : "",
          edition: value,
          qty: props.uploadArt.print ? props.uploadArt.print.qty : "",
        },
      });
    } else if (name === "qty") {
      props.setUploadArt({
        ...props.uploadArt,
        print: {
          size: props.uploadArt.print
            ? props.uploadArt.print.size && props.uploadArt.print.size
            : [],
          print_price: props.uploadArt.print
            ? props.uploadArt.print.print_price
            : "",
          edition: props.uploadArt.print ? props.uploadArt.print.edition : "",
          qty: value,
        },
      });
    } else if (name === "print_main_price") {
      props.setUploadArt({
        ...props.uploadArt,
        print_main_price: {
          print_main_price: value,
        },
      });
    } else if (name === "print_price") {
      let print = { ...props.uploadArt.print, [name]: value };
      let artsmiley_commission_print = parseFloat(
        (print.print_price * (print_percentage / 100)).toFixed(2)
      ); // Use curator percentage dynamically
      let artist_profit_print = parseFloat(
        (print.print_price * ((100 - print_percentage) / 100)).toFixed(2)
      ); // Use curator percentage dynamically

      props.setUploadArt({
        ...props.uploadArt,
        print: {
          size: props.uploadArt.print
            ? props.uploadArt.print.size && props.uploadArt.print.size
            : [],
          print_price: value,
          edition: props.uploadArt.print ? props.uploadArt.print.edition : "",
          qty: props.uploadArt.print ? props.uploadArt.print.qty : "",
        },
        artist_profit_print,
        artsmiley_commission_print,
        price_of_collector_print: value,
      });
    } else {
      props.setUploadArt({
        ...props.uploadArt,
        print: {
          size: value,
          print_price: props.uploadArt.print
            ? props.uploadArt.print.print_price
            : "",
          edition: props.uploadArt.print ? props.uploadArt.print.edition : "",
          qty: props.uploadArt.print ? props.uploadArt.print.qty : "",
        },
      });
    }
  };

  const handleChangeSale = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if (!isNaN(value)) {
      if (name === "price") {
        let sale = { ...props.uploadArt.sale, [name]: value };              
        let artsmiley_commission = parseFloat(
          (sale.price * (buy_percentage / 100)).toFixed(2)
        );
        let curatorPercentage = parseFloat(
          (artsmiley_commission * (curator_percentage / 100)).toFixed(2)
        );
        // Reduce curatorPercentage from artsmiley_commission
        artsmiley_commission = parseFloat(
          (artsmiley_commission - curatorPercentage).toFixed(2)
        );
        let artist_profit = parseFloat(
          (sale.price * ((100 - buy_percentage) / 100)).toFixed(2)
        ); // Use curator percentage dynamically
        props.setUploadArt((prevState) => ({
          ...prevState,
          sale: {
            qty: props.uploadArt.sale.qty || "",
            price: value,
            price_of_collector: value,
            artsmiley_commission,
            artist_profit,
            curator_percentage: curatorPercentage,
            rolled: prevState.sale.rolled || [
              { rolled_size: "", rolled_price: "" },
            ],
          },
        }));
      } else if (name === "qty") {
        props.setUploadArt((prevState) => ({
          ...prevState,
          sale: {
            qty: value,
            sale_weight: props.uploadArt.sale.sale_weight || "",
            package_type : props.uploadArt.sale.package_type || "Tube",
            price: props.uploadArt.sale.price || "",
            price_of_collector: props.uploadArt.sale.price_of_collector,
            artsmiley_commission: props.uploadArt.sale.artsmiley_commission,
            artist_profit: props.uploadArt.sale.artist_profit,
            isframe:props.uploadArt.sale.isframe,
            rolled: prevState.sale.rolled || [
              { rolled_size: "", rolled_price: "" },
            ],
          },
        }));
      } else {
        props.setUploadArt((prevState) => ({
          ...prevState,
          sale: {
            qty: props.uploadArt.sale.qty || "",
            sale_weight: value,
            package_type: props.uploadArt.sale.package_type || "Tube",
            price: props.uploadArt.sale.price || "",
            price_of_collector: props.uploadArt.sale.price_of_collector,
            artsmiley_commission: props.uploadArt.sale.artsmiley_commission,
            artist_profit: props.uploadArt.sale.artist_profit,
            isframe:props.uploadArt.sale.isframe,
            rolled: prevState.sale.rolled || [
              { rolled_size: "", rolled_price: "" },
            ],
          },
        }));
      }
    }
  };

  const handleChangeRent = (e) => {
    const { name, value } = e.target;
    let rent = { ...props.uploadArt.rent };
    if (name === "rent_price") {
      const numericValue = value.replace(/[^0-9.]/g, ""); 
      if (isNaN(numericValue)) {
        return;
      }
      rent.rent_price = numericValue;

      // Calculate Artsmiley commission and artist profit
      const  artsmiley_commission_rent = parseFloat(
        (rent.rent_price * (rent_percentage / 100)).toFixed(2)
      );  
      const artist_profit_rent = parseFloat(
        (rent.rent_price * ((100 - rent_percentage) / 100)).toFixed(2)
      );
      const  curatorPercentage = parseFloat(
        (artsmiley_commission_rent * (curator_percentage / 100)).toFixed(2)
      );

       // Update Artsmiley commission after curator percentage
       const adjusted_commission_rent = parseFloat(
        (artsmiley_commission_rent - curatorPercentage).toFixed(2)
      );

      // Store all calculated values in the rent object
        rent.price_of_collector_rent = numericValue;
        rent.artsmiley_commission_rent = adjusted_commission_rent;
        rent.artist_profit_rent = artist_profit_rent;
        rent.curator_percentage_rent = curatorPercentage;
        rent.package_type = props.uploadArt.sale.package_type || "Tube";


      // Update the state with calculated values
      props.setUploadArt((prevState) => ({
        ...prevState,
        rent,
      }));
    } else if (name === "time") {
      rent.time = value;
      // Update the state with the new time value
      props.setUploadArt((prevState) => ({
        ...prevState,
        rent,    
      }));
    } else if (name === "rent_weight") {
      const numericValue = value.replace(/[^0-9]/g, "");
      rent.rent_weight = numericValue;
      props.setUploadArt((prevState) => ({
        ...prevState,
        rent,
      }));
    }
  };

  useEffect(() => {
    if (props.uploadArt.sale?.package_type) {
      props.setUploadArt((prevState) => ({
        ...prevState,
        rent: {
          ...prevState.rent, // Retain other fields in rent
          package_type: props.uploadArt.sale.package_type, // Push sale_weight into rent_weight
        },
      }));
    }
  }, [props.uploadArt.sale?.package_type]);

  useEffect(() => {
    if (props.uploadArt.sale?.sale_weight) {
      props.setUploadArt((prevState) => ({
        ...prevState,
        rent: {
          ...prevState.rent, // Retain other fields in rent
          rent_weight: props.uploadArt.sale.sale_weight, // Push sale_weight into rent_weight
        },
      }));
    }
  }, [props.uploadArt.sale?.sale_weight]);

  // placeholder="Enter Your Rent Weight"
  const sizes = [
    {
      _id: 1,
      height: props.uploadArt.height * 0.5,
      width: props.uploadArt.width * 0.5,
    },
    {
      _id: 2,
      height: props.uploadArt.height * 0.75,
      width: props.uploadArt.width * 0.75,
    },
    {
      _id: 3,
      height: Number(props.uploadArt.height),
      width: Number(props.uploadArt.width),
    },
    {
      _id: 4,
      height: props.uploadArt.height * 2,
      width: props.uploadArt.width * 2,
    },
    {
      _id: 5,
      height: props.uploadArt.height * 3,
      width: props.uploadArt.width * 3,
    },
  ];

  const handleChangeType = (e) => {
    const name = e.target.name;
    if (name === "sale") {
      setType({ ...type, sale: !type.sale });
    } else if (name === "rent") {
      setType({ ...type, rent: !type.rent });
    } else {
      setType({ ...type, print: !type.print });
    }
  };

  useEffect(() => {
    if (props) {
      setType({
        sale: props?.uploadArt.sale?.price > 0 || true,
        rent: props?.uploadArt.rent?.rent_price > 0 || false,
        print: props?.uploadArt.print[0]?.print_price > 0 || false,
      });
    }
  }, [props]);

  const handleUploadArt = () => {
    const { thirdFormErrors, isValidThirdForm } = validationForThirdFormArt(
      printType,
      props.uploadArt,
      type,
      props
    ); 
   
    if (!type.sale) {
      Errornotify("Artworks Sale Price information required.");
    } else {
      if (isValidThirdForm) {    
        dispatch(       
          handleAddArt(props.uploadArt, pricingData, printType, type, navigate)
        );     
      } else {
        setThirdFormErrors(thirdFormErrors);
      }
    }
  };
 

  const handleUpdateArt = () => {
    if (props.uploadArt.sale.price <= 0) {
      Errornotify("Please fill Sale detail properly");
    } else if (props.uploadArt.sale.qty <= 0) {
      Errornotify("Please fill Sale detail properly");
    } else {
      dispatch(
        handleUploadUpdateArt(
          editArtId?.id,
          props.uploadArt,
          pricingData,
          printType,
          type,
          navigate
        )
      );
    }
  };

  const handleAddFields = () => {
    const newPrint = {
      edition: "Limited",
      qty: "1",
      size: {},
      print_price: "",
      material: "Fine Art Paper",
      artsmiley_commission_print: "",
      artist_profit_print: "",
      price_of_collector_print: "",
    };
    props.setUploadArt({
      ...props.uploadArt,
      print: [...props.uploadArt.print, newPrint],
    });
  };

  const handleRemoveFields = (index) => {
    const newPrints = props.uploadArt.print.filter((_, i) => i !== index);
    props.setUploadArt({
      ...props.uploadArt,
      print: newPrints,
    });
  };

  const handleSetting = async function () {
    try {
      const result = await api.applicationApi(
        `api/superAdmin/fetchSettingDetails`,
        "GET"
      );
      if (result && result.status === 200) {
        result.data.data.forEach((element) => {
          setBuyPercentage(element.buy_percentage);
          setRentPercentage(element.rent_percentage);
          setPrintPercentage(element.print_percentage);
          setCuratorPercentage(element.curator_percentage);
        });
        // dispatch(setLoader(false));
      } else if (result.status === 401) {
      }
    } catch (err) {}
  };

  const handleFetchPriceByMaterialAndProduct = async function () {
    try {
      const productOptions = getProductOptions();
      const metalPrintOptions = getProductOptionsMetalPrint();
      // Step 2: Extract only the value properties
      const valuesToSend = productOptions.map((option) => option.value);
      const metalPrintValues = metalPrintOptions.map((option) => option.value);
      const result = await api.applicationApi(
        `api/printprice/getPrintPriceByMaterialAndProduct`,
        "POST",
        {
          material:
            printPricing.material !== "metal"
              ? printPricing.material
              : printPricing.metal_print,
          product:
            printPricing.material === "metal" ? metalPrintValues : valuesToSend,
        }
      );
      if (result && result.status === 200) {
        setPrintPricingResponse(result.data);
        // dispatch(setLoader(false));
      } else if (result.status === 401) {
      }
    } catch (err) {}
  };
  useEffect(() => {
    if (printPricing.material) {
      handleFetchPriceByMaterialAndProduct();
    }
  }, [printPricing]);
  useEffect(() => {
    handleSetting();
  }, []);


  return (
    <div className={styles.mains}>
      <Grid container spacing={{ sm: 2 }} columns={{ xs: 12, sm: 12, md: 12 }}>
        <Grid item xs={12} sm={2} md={3} style={{ textAlign: "center" }}>
          <p
            onClick={() => {
              if (editArtId?.id) {
                handleNavigateRoute("/edit/stepsecond");
              } else {
                handleNavigateRoute("/upload/stepsecond");
              }
            }}
            className={styles.back_img}
          >
            {"<"} Back To Description
          </p>
        </Grid>
        <Grid item xs={12} sm={9} md={9} sx={{ my: 2 }}>
          <div className={styles.discription_div}>
            <h2 className={styles.main_heading}>{props.data.main_heading}</h2>
            <h5 className={styles.status_avail}>{props.data.sub_heading}</h5>
            <FormControlLabel
              className={styles.checkbox_labels}
              control={
                <Checkbox
                  name="sale"
                  onChange={handleChangeType}
                  checked={type.sale}
                  sx={{ color: "#665D6F" }}
                />
              }
              label={
                <Typography
                  sx={{
                    fontFamily: "DM Sans",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "20px",
                    lineHeight: "26px",
                  }}
                  className={styles.checkbox_label}
                >
                  Sale
                </Typography>
              }
            />
            <Grid
              container
              columnSpacing={{ sm: 2 }}
              columns={{ xs: 12, sm: 12, md: 12 }}
            >
              <Grid item xs={12} sm={6} md={6}>
                <div className={styles.search_action}>
                  <lable className={styles.labels}>Price *</lable>
                  <br />
                  <FormControl fullWidth sx={{ m: 1 }}>
                    {/* <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel> */}
                    <OutlinedInput
                      onChange={handleChangeSale}
                      value={props.uploadArt.sale.price || ""}
                      fullWidth
                      disabled={!type.sale}
                      placeholder="Enter Your Sale Price"
                      type="text"
                      name="price"
                      startAdornment={
                        <InputAdornment position="start">$</InputAdornment>
                      }
                    />
                    {thirdFormError?.salePrice ? (
                      <span
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          fontSize: "12px",
                        }}
                      >
                        {thirdFormError?.salePrice}
                      </span>
                    ) : null}
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <div className={styles.search_action}>
                  <lable className={styles.labels}>Quantity *</lable>
                  <br />
                  <TextField
                    type="text"
                    fullWidth
                    disabled={!type.sale}
                    onChange={handleChangeSale}
                    placeholder="Enter your quantity"
                    sx={{ mt: 1 }}
                    value={props.uploadArt.sale?.qty}
                    name="qty"
                  />
                </div>
                {thirdFormError?.salePrice ? (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {thirdFormError?.saleQty}
                  </span>
                ) : null}
              </Grid>

              <>
                {dynamicFieldsSale.map((_, index) => {
                  return (
                    <>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        sx={{ display: "flex", justifyContent: "flex-start" }}
                      >
                        <div className={styles.medium_child}>
                          <label className={styles.labels}>
                            Ready to Hang*
                          </label>
                          <FormControl>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="is_hanged"
                              value={props.uploadArt.is_hanged}
                              onChange={props.handleChangedata}
                            >
                              <FormControlLabel
                                className={styles.radio_label}
                                sx={{ m: 0.5 }}
                                value="Yes"
                                control={
                                  <Radio size="small" sx={{ m: 0, p: 0.3 }} />
                                }
                                label="Yes"
                                disabled={[
                                  "Sculpture",
                                  "Crafts",
                                  "Installation",
                                ].includes(props.category)}
                              />
                              <FormControlLabel
                                className={styles.radio_label}
                                sx={{ m: 0.5 }}
                                value="No"
                                control={
                                  <Radio size="small" sx={{ m: 0, p: 0.3 }} />
                                }
                                label="No"
                                disabled={[
                                  "Sculpture",
                                  "Crafts",
                                  "Installation",
                                ].includes(props.category)}
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <div className={styles.search_action}>
                          <lable className={styles.labels}>Tube Size</lable>
                          <br />

                          <Select
                            labelId="select-mutli-artist"
                            id="demo-select-artist"
                            onChange={(e) => {
                              const { value } = e.target; // Get the new value from the input

                              props.setUploadArt({
                                ...props.uploadArt, // Spread the existing state
                                tube_size: value, // Update the tube_size field
                              });
                            }}
                            name="tube_size"
                            value={props.uploadArt.tube_size}
                            disabled={
                              props.uploadArt.is_hanged === "Yes" ||
                              ["Sculpture", "Crafts", "Installation"].includes(
                                props.category
                              )
                                ? true
                                : false
                            }
                            fullWidth
                            sx={{
                              background: "#f6f6f6",
                              ".MuiOutlinedInput-notchedOutline": {},
                              "& .MuiInputBase-root": {
                                borderRadius: "6px",
                              },
                              mt: 1,
                              ml: 1,
                            }}
                            placeholder="Enter your tube size"
                          >
                            {[
                              { _id: 1, label: "Small" },
                              { _id: 2, label: "Medium" },
                              { _id: 3, label: "Large" },
                              { _id: 2, label: "Extra Large" },
                            ].map((val) => (
                              <MenuItem value={val.label} key={val.label}>
                                {val.label}
                              </MenuItem>
                            ))}
                          </Select>
                          {thirdFormError?.tubeSize ? (
                            <span
                              style={{
                                color: "red",
                                fontWeight: "bold",
                                fontSize: "12px",
                                marginLeft: "5px",
                              }}
                            >
                              {thirdFormError?.tubeSize}
                            </span>
                          ) : null}
                        </div>
                      </Grid>

                      {props.uploadArt.is_hanged === "Yes" ? (
                        <>
                          <Grid item xs={12} sm={6} md={6}>
                            <div className={styles.search_action}>
                              <lable className={styles.labels}>
                                Packaging Type
                              </lable>

                              <Select
                                labelId="select-mutli-artist"
                                id="demo-select-artist"
                                onChange={(e) => {
                                  const { value } = e.target; // Get the new value from the input
                                  props.setUploadArt({
                                    ...props.uploadArt, // Spread the existing state
                                    sale: {
                                      ...props.uploadArt.sale, // Spread the existing sale object to retain other fields
                                      package_type: value, // Update the package_type field inside sale
                                    },
                                  });
                                }}
                                name="package_type"
                                value={
                                  props.uploadArt.sale.package_type || "Tube"
                                }
                                fullWidth
                                sx={{
                                  background: "#f6f6f6",
                                  ".MuiOutlinedInput-notchedOutline": {},
                                  "& .MuiInputBase-root": {
                                    borderRadius: "6px",
                                  },
                                  mt: 1,
                                  ml: 1,
                                }}
                                placeholder="Select your frame color"
                              >
                                {[
                                  { _id: 1, label: "Crate" },
                                  { _id: 2, label: "Box" },
                                ].map((val) => (
                                  <MenuItem value={val.label} key={val.label}>
                                    {val.label}
                                  </MenuItem>
                                ))}
                              </Select>
                              {thirdFormError?.packagetype ? (
                            <span
                              style={{
                                color: "red",
                                fontWeight: "bold",
                                fontSize: "12px",
                                marginLeft: "5px",
                              }}
                            >
                              {thirdFormError?.packagetype}
                            </span>
                          ) : null}
                            </div>
                          </Grid>{" "}
                          <Grid item xs={12} sm={6} md={6}>
                            <div className={styles.search_action}>
                              <lable className={styles.labels}>
                               Available Frame
                              </lable>
                              <br />
                              <Select
                                labelId="select-mutli-artist"
                                id="demo-select-artist"
                                onChange={(e) => {
                                  const { value } = e.target; // Get the new value from the input
                                    // Convert "Yes" to true and "No" to false
                                      const isFrameBoolean = value === "Yes";
                                  props.setUploadArt({
                                    ...props.uploadArt, // Spread the existing state
                                    sale:{
                                      ...props.uploadArt.sale,
                                      isframe: isFrameBoolean,
                                    }
                                  });
                                }}
                                name="isframe"
                                value={props.uploadArt.sale?.isframe ? "Yes" : "No"}
                                fullWidth
                                sx={{
                                  background: "#f6f6f6",
                                  ".MuiOutlinedInput-notchedOutline": {},
                                  "& .MuiInputBase-root": {
                                    borderRadius: "6px",
                                  },
                                  mt: 1,
                                  ml: 1,
                                }}
                                placeholder="available frame"
                              >
                                {[
                                  { _id: 1, label: "Yes" },
                                  { _id: 2, label: "No" },
                                ].map((val) => (
                                  <MenuItem value={val.label} key={val.label}>
                                    {val.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <div className={styles.search_action}>
                              <lable className={styles.labels}>
                                Frame Color
                              </lable>
                              <br />
                              <Select
                                labelId="select-mutli-artist"
                                id="demo-select-artist"
                                onChange={(e) => {
                                  const { value } = e.target; // Get the new value from the input
                                  props.setUploadArt({
                                    ...props.uploadArt, // Spread the existing state
                                    frame_color: value, // Update the tube_size field
                                  });
                                }}
                                disabled={!props.uploadArt.sale.isframe}
                                name="frame_color"
                                value={props.uploadArt.frame_color}
                                fullWidth
                                sx={{
                                  background: "#f6f6f6",
                                  ".MuiOutlinedInput-notchedOutline": {},
                                  "& .MuiInputBase-root": {
                                    borderRadius: "6px",
                                  },
                                  mt: 1,
                                  ml: 1,
                                }}
                                placeholder="Select your frame color"
                              >
                                {[
                                  { _id: 1, label: "Black" },
                                  { _id: 2, label: "Brown" },
                                  { _id: 3, label: "White" },
                                  { _id: 4, label: "Silver" },
                                  { _id: 5, label: "Gold" },
                                  { _id: 6, label: "Patina" },
                                  { _id: 7, label: "Other" },
                                ].map((val) => (
                                  <MenuItem value={val.label} key={val.label}>
                                    {val.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <div className={styles.search_action}>
                              <lable className={styles.labels}>
                                Shipping weight (Artwork +{" "}
                                {props.uploadArt.sale.package_type})KG *
                              </lable>
                              <br />

                              <FormControl fullWidth sx={{ m: 1 }}>
                                <OutlinedInput
                                  onChange={handleChangeSale}
                                  value={props.uploadArt.sale.sale_weight || ""}
                                  fullWidth
                                  disabled={!type.sale}
                                  placeholder="Enter Your Sale Weight"
                                  type="text"
                                  name="sale_weight"
                                />
                                {thirdFormError?.saleWeight ? (
                                  <span
                                    style={{
                                      color: "red",
                                      fontWeight: "bold",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {thirdFormError?.saleWeight}
                                  </span>
                                ) : null}
                              </FormControl>
                            </div>
                          </Grid>
                        </>
                      ) : null}
                      {props.uploadArt.is_hanged === "No" ? (
                        <>
                          <Grid item xs={12} sm={6} md={6}>
                            <div className={styles.search_action}>
                              <lable className={styles.labels}>
                                Shipping weight (Artwork + Tube)KG *
                              </lable>
                              <br />

                              <FormControl fullWidth sx={{ m: 1 }}>
                                <OutlinedInput
                                  onChange={handleChangeSale}
                                  value={props.uploadArt.sale.sale_weight || ""}
                                  fullWidth
                                  disabled={!type.sale}
                                  placeholder="Enter Your Sale Weight"
                                  type="text"
                                  name="sale_weight"
                                />
                                {thirdFormError?.saleWeight ? (
                                  <span
                                    style={{
                                      color: "red",
                                      fontWeight: "bold",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {thirdFormError?.saleWeight}
                                  </span>
                                ) : null}
                              </FormControl>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <div className={styles.search_action}>
                              <lable className={styles.labels}>
                                Packaging Type *
                              </lable>
                              <br />

                              <FormControl fullWidth sx={{ m: 1 }}>
                                <OutlinedInput
                                  value={"Tube"}
                                  fullWidth
                                  disabled
                                />
                              </FormControl>
                            </div>
                          </Grid>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  );
                })}
              </>
            </Grid>

            <h3 className={styles.labels} style={{ fontWeight: "700" }}>
              Sale Pricing
            </h3>
            <Grid
              container
              columnSpacing={{ sm: 2 }}
              columns={{ xs: 12, sm: 12, md: 12 }}
            >
              <Grid item xs={12} sm={6} md={4}>
                <div className={styles.search_action}>
                  <lable className={styles.labels}>Artsmiley Commission </lable>
                  <br />
                  <FormControl fullWidth sx={{ m: 1 }}>
                    <OutlinedInput
                      onChange={handleChangeSale}
                      fullWidth
                      disabled={!type.sale}
                      value={props.uploadArt.sale.artsmiley_commission}
                      type="text"
                      name="artsmiley_commission"
                      startAdornment={
                        <InputAdornment position="start">$</InputAdornment>
                      }
                      readOnly
                    />
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <div className={styles.search_action}>
                  <lable className={styles.labels}>Your Profit</lable>
                  <br />
                  <FormControl fullWidth sx={{ m: 1 }}>
                    <OutlinedInput
                      onChange={handleChangeSale}
                      fullWidth
                      disabled={!type.sale}
                      readOnly
                      type="text"
                      name="artist_profit"
                      value={props.uploadArt.sale.artist_profit}
                      startAdornment={
                        <InputAdornment position="start">$</InputAdornment>
                      }
                    />
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <div className={styles.search_action}>
                  <lable className={styles.labels}>
                    Price the Collector will see
                  </lable>
                  <br />

                  <FormControl fullWidth sx={{ m: 1 }}>
                    <OutlinedInput
                      onChange={handleChangeSale}
                      fullWidth
                      disabled={!type.sale}
                      readOnly
                      type="text"
                      name="price_of_collector"
                      value={props.uploadArt.sale.price_of_collector}
                      startAdornment={
                        <InputAdornment position="start">$</InputAdornment>
                      }
                    />
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <div className={styles.search_action}>
                  <lable className={styles.labels}>Curator Percentage</lable>
                  <br />
                  <FormControl fullWidth sx={{ m: 1 }}>
                    <OutlinedInput
                      onChange={handleChangeSale}
                      fullWidth
                      disabled={!type.sale}
                      readOnly
                      type="text"
                      name="curator_percentage"
                      value={Number(props.uploadArt.sale.artist_profit)-Number(props.uploadArt.sale.artsmiley_commission)}
                      // value={props.uploadArt.sale.curator_percentage}
                      startAdornment={
                        <InputAdornment position="start">$</InputAdornment>
                      }
                    />
                  </FormControl>
                </div>
              </Grid>
            </Grid>

            <FormControlLabel
              className={styles.checkbox_labels}
              control={
                <Checkbox
                  name="rent"
                  checked={type.rent}
                  onChange={handleChangeType}
                  sx={{ color: "#665D6F" }}
                />
              }
              label={
                <Typography
                  sx={{
                    fontFamily: "DM Sans",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "20px",
                    lineHeight: "26px",
                  }}
                  className={styles.checkbox_label}
                >
                  Rent
                </Typography>
              }
            />
            <Grid
              container
              columnSpacing={{ sm: 2 }}
              columns={{ xs: 12, sm: 12, md: 12 }}
            >
              <Grid item xs={12} sm={6} md={6}>
                <lable className={styles.labels}>Price *</lable>
                <br />
                <OutlinedInput
                  fullWidth
                  value={
                    props.uploadArt.rent && props.uploadArt.rent.rent_price
                  }
                  onChange={handleChangeRent}
                  disabled={!type.rent}
                  placeholder="Enter Price"
                  sx={{ mt: 0.5 }}
                  type="text"
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                  name="rent_price"
                />{" "}
                {thirdFormError?.rentPrice ? (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {thirdFormError?.rentPrice}
                  </span>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <lable className={styles.labels}>Time *</lable>
                <br />
                <Select
                  labelId="select-mutli-artist"
                  id="demo-select-artist"
                  disabled={!type.rent}
                  value={props.uploadArt.rent && props.uploadArt.rent.time}
                  fullWidth
                  sx={{
                    background: "#f6f6f6",
                    ".MuiOutlinedInput-notchedOutline": {},
                    "& .MuiInputBase-root": {
                      borderRadius: "6px",
                    },
                    mt: 0.8,
                  }}
                  placeholder="select type"
                  onChange={handleChangeRent}
                  name="time"
                  readOnly
                >
                  <MenuItem value="none" disabled>
                    None
                  </MenuItem>
                  {top100Films &&
                    top100Films.map((val) => (
                      <MenuItem value={val.label} key={val.label}>
                        {val.label}
                      </MenuItem>
                    ))}
                </Select>
              </Grid>
              {props.uploadArt.is_hanged === "Yes" ? (
                <>
                  <Grid item xs={12} sm={6} md={6}>
                    <div className={styles.search_action}>
                      <lable className={styles.labels}>Packaging Type *</lable>

                      <Select
                        labelId="select-mutli-artist"
                        id="demo-select-artist"                        
                        disabled
                        name="package_type"
                        value={props.uploadArt.sale.package_type || "Tube"}
                        fullWidth
                        sx={{
                          background: "#f6f6f6",
                          ".MuiOutlinedInput-notchedOutline": {},
                          "& .MuiInputBase-root": {
                            borderRadius: "6px",
                          },
                          mt: 1,
                          ml: 1,
                        }}
                      >
                        <MenuItem value={props.uploadArt.sale.package_type}>
                          {props.uploadArt.sale.package_type}
                        </MenuItem>
                      </Select>
                    </div>
                  </Grid>{" "}
                  <Grid item xs={12} sm={6} md={6} pt={1}>
                    <div className={styles.search_action}>
                      <lable className={styles.labels}>
                        Shipping weight (Artwork +{" "}
                        {props.uploadArt.sale.package_type})KG *
                      </lable>
                      <br />
                      <FormControl fullWidth sx={{ m: 1 }}>
                        <OutlinedInput                          
                          value={props.uploadArt.sale?.sale_weight || ""}
                          fullWidth
                          disabled
                          placeholder="Enter Your Rent Weight"
                          type="text"
                          name="rent_weight"
                        />
                      </FormControl>
                      {thirdFormError?.rentWeight ? (
                        <span
                          style={{
                            color: "red",
                            fontWeight: "bold",
                            fontSize: "12px",
                          }}
                        >
                          {thirdFormError?.rentWeight}
                        </span>
                      ) : null}
                    </div>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12} sm={6} md={6}>
                    <div className={styles.search_action}>
                      <lable className={styles.labels}>Packaging Type *</lable>

                      <Select
                        labelId="select-mutli-artist"
                        id="demo-select-artist"                       
                        disabled
                        name="package_type"
                        value={"Tube"}
                        fullWidth
                        sx={{
                          background: "#f6f6f6",
                          ".MuiOutlinedInput-notchedOutline": {},
                          "& .MuiInputBase-root": {
                            borderRadius: "6px",
                          },
                          mt: 1,
                          ml: 1,
                        }}
                      >
                        <MenuItem value={"Tube"}>{"Tube"}</MenuItem>
                      </Select>
                    </div>
                  </Grid>{" "}
                  <Grid item xs={12} sm={6} md={6} pt={1}>
                    <div className={styles.search_action}>
                      <lable className={styles.labels}>
                        Shipping weight (Artwork + Tube )KG *
                      </lable>
                      <br />
                      <FormControl fullWidth sx={{ m: 1 }}>
                        <OutlinedInput
                          value={props.uploadArt.sale?.sale_weight || ""} // Display the value from rent.rent_weight
                          fullWidth
                          disabled
                          type="text"
                          name="rent_weight"
                        />
                      </FormControl>
                      {thirdFormError?.rentWeight ? (
                        <span
                          style={{
                            color: "red",
                            fontWeight: "bold",
                            fontSize: "12px",
                          }}
                        >
                          {thirdFormError?.rentWeight}
                        </span>
                      ) : null}
                    </div>
                  </Grid>
                </>
              )}
            </Grid>
            <h3 className={styles.labels} style={{ fontWeight: "700" }}>
              Rent Pricing
            </h3>
            <Grid
              container
              columnSpacing={{ sm: 2 }}
              columns={{ xs: 12, sm: 12, md: 12 }}
            >
              <Grid item xs={12} sm={6} md={4}>
                <div className={styles.search_action}>
                  <lable className={styles.labels}>Artsmiley Commission </lable>
                  <br />

                  <FormControl fullWidth sx={{ m: 1 }}>
                    <OutlinedInput
                      onChange={handleChangeRent}
                      fullWidth
                      disabled={!type.rent}
                      value={props.uploadArt.rent.artsmiley_commission_rent}
                      type="text"
                      name="artsmiley_commission_rent"
                      startAdornment={
                        <InputAdornment position="start">$</InputAdornment>
                      }
                      readOnly
                    />
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <div className={styles.search_action}>
                  <lable className={styles.labels}>Your Profit</lable>
                  <br />
                  <FormControl fullWidth sx={{ m: 1 }}>
                    <OutlinedInput
                      onChange={handleChangeRent}
                      fullWidth
                      disabled={!type.rent}
                      readOnly
                      type="text"
                      name="artist_profit_rent"
                      value={props.uploadArt.rent.artist_profit_rent}
                      startAdornment={
                        <InputAdornment position="start">$</InputAdornment>
                      }
                    />
                  </FormControl>
                </div>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <div className={styles.search_action}>
                  <lable className={styles.labels}>
                    Price the Collector will see
                  </lable>
                  <br />
                  <FormControl fullWidth sx={{ m: 1 }}>
                    <OutlinedInput
                      onChange={handleChangeRent}
                      fullWidth
                      disabled={!type.rent}
                      readOnly
                      type="text"
                      name="price_of_collector_rent"
                      value={props.uploadArt.rent.price_of_collector_rent}
                      startAdornment={
                        <InputAdornment position="start">$</InputAdornment>
                      }
                    />
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <div className={styles.search_action}>
                  <lable className={styles.labels}>Curator Percentage</lable>
                  <br />
                  <FormControl fullWidth sx={{ m: 1 }}>
                    <OutlinedInput
                      onChange={handleChangeRent}
                      fullWidth
                      disabled={!type.rent}
                      readOnly
                      type="text"
                      name="curator_percentage_rent"
                      // value={props.uploadArt.rent.curator_percentage_rent}
                      value={Number(props.uploadArt.rent.artist_profit_rent) - Number(props.uploadArt.rent.artsmiley_commission_rent)}
                      startAdornment={
                        <InputAdornment position="start">$</InputAdornment>
                      }
                    />
                  </FormControl>
                </div>
              </Grid>
            </Grid>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <FormControlLabel
                className={styles.checkbox_labels}
                control={
                  <Checkbox
                    disabled={["Sculpture", "Crafts", "Installation"].includes(
                      props.category
                    )}
                    name="print"
                    checked={type?.print}
                    onChange={handleChangeType}
                    sx={{ color: "#665D6F" }}
                  />
                }
                label={
                  <Typography
                    sx={{
                      fontFamily: "DM Sans",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "20px",
                      lineHeight: "26px",
                    }}
                    className={styles.checkbox_label}
                  >
                    Print
                  </Typography>
                }
              />
              <FormControl>
                {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    disabled={!type.print}
                    value="printbyme"
                    onChange={() => setPrintType("printbyme")}
                    checked={printType === "printbyme"}
                    control={<Radio />}
                    label="Limited Edition"
                  />
                  <FormControlLabel
                    disabled={!type.print}
                    value="printbyartsmiley"
                    onChange={() => setPrintType("printbyartsmiley")}
                    checked={printType === "printbyartsmiley"}
                    control={<Radio />}
                    label="Open Edition"
                  />
                </RadioGroup>
              </FormControl>
            </div>

            {printType === "printbyme" ? (
              <>
                {props.uploadArt?.print?.map((printItem, index) => {
                  return (
                    <>
                      <Grid
                        container
                        columnSpacing={{ sm: 2 }}
                        columns={{ xs: 12, sm: 12, md: 12 }}
                      >
                        <Grid item xs={12} sm={6} md={6}>
                          <div className={styles.search_action}>
                            <lable className={styles.labels}>Material *</lable>
                            <br />

                            <Select
                              labelId="select-mutli-artist"
                              id="demo-select-artist"
                              className={styles.search_select}
                              value={printItem.material}
                              onChange={(e) => {
                                const newPrints = [...props.uploadArt.print];
                                newPrints[index].material = e.target.value;
                                props.setUploadArt({
                                  ...props.uploadArt,
                                  print: newPrints,
                                });
                              }}
                              disabled={!type.print}
                              fullWidth
                              sx={{
                                background: "#f6f6f6",
                                ".MuiOutlinedInput-notchedOutline": {},
                                "& .MuiInputBase-root": {
                                  borderRadius: "6px",
                                },
                                mt: 0.8,
                              }}
                              placeholder="select Edition"
                            >
                              <MenuItem value="none" disabled>
                                None
                              </MenuItem>
                              {[
                                { _id: 1, label: "Fine Art Paper" },
                                { _id: 2, label: "Canvas" },
                                { _id: 3, label: "Metal" },
                                { _id: 2, label: "Acrylic" },
                              ].map((val) => (
                                <MenuItem value={val.label} key={val.label}>
                                  {val.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {thirdFormError?.[`printMaterial${index}`] && (
                              <span
                                style={{
                                  color: "red",
                                  fontWeight: "bold",
                                  fontSize: "12px",
                                }}
                              >
                                {thirdFormError[`printMaterial${index}`]}
                              </span>
                            )}
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={6}
                          className={
                            props.uploadArt.print &&
                            props.uploadArt.print.edition === "Open"
                              ? styles.print_qty
                              : ""
                          }
                        >
                          <div className={styles.search_action}>
                            <lable className={styles.labels}>
                              Add Quantity *
                            </lable>
                            <br />
                            <TextField
                              fullWidth
                              placeholder="---------"
                              sx={{ mt: 1 }}
                              name="qty"
                              type="number"
                              disabled={!type.print}
                              value={printItem.qty}
                            />
                          </div>
                        </Grid>
                        {props.data.print_size &&
                          props.data.print_size.map((val, i) => (
                            <Grid item xs={12} sm={6} md={6}>
                              <div className={styles.search_action}>
                                <lable className={styles.labels}>
                                  {val.label} *
                                </lable>
                                <br />
                                <Select
                                  labelId="select-mutli-artist"
                                  id="demo-select-artist"
                                  fullWidth
                                  disabled={!type.print}
                                  value={
                                    props.uploadArt.print[index]?.size || "" // Dynamically set the value
                                  }
                                  renderValue={(selected) =>
                                    `${selected.width} W x ${selected.height} H`
                                  }
                                  sx={{
                                    background: "#f6f6f6",
                                    ".MuiOutlinedInput-notchedOutline": {},
                                    "& .MuiInputBase-root": {
                                      borderRadius: "6px",
                                    },
                                    mt: 0.8,
                                  }}
                                  placeholder="select size"
                                  onChange={(e) => {
                                    const newPrints = [
                                      ...props.uploadArt.print,
                                    ];
                                    newPrints[index].size = e.target.value;
                                    props.setUploadArt({
                                      ...props.uploadArt,
                                      print: newPrints,
                                    });
                                  }}
                                  name="size"
                                >
                                  <MenuItem disabled value="">
                                    None
                                  </MenuItem>
                                  {sizes &&
                                    sizes.map((val) => (
                                      <MenuItem
                                        value={{
                                          height: val.height,
                                          width: val.width,
                                        }}
                                        key={val._id}
                                      >
                                        {val.width} W x {val.height} H
                                      </MenuItem>
                                    ))}
                                </Select>
                                {thirdFormError?.[`printSize${index}`] && (
                                  <span
                                    style={{
                                      color: "red",
                                      fontWeight: "bold",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {thirdFormError[`printSize${index}`]}
                                  </span>
                                )}
                                {/* <TextField fullWidth placeholder="---------" sx={{ mt: 1 }} name="keyword" /> */}
                              </div>
                            </Grid>
                          ))}
                        <Grid item xs={12} sm={6} md={6}>
                          <div className={styles.search_action}>
                            <lable className={styles.labels}>Price *</lable>
                            <br />
                            <OutlinedInput
                              value={printItem.print_price}
                              onChange={(e) => {
                                let value = e.target.value;

                                // Ensure only numeric values are accepted (including decimal point)
                                const numericValue = value.replace(
                                  /[^0-9.]/g,
                                  ""
                                ); // Removes non-numeric characters except for the decimal point

                                // If value is not a valid number, return early or handle error
                                if (isNaN(numericValue)) {
                                  return; // Optionally, display an error message or prevent further action
                                }

                                // Use numeric value for calculations
                                const newPrints = [...props.uploadArt.print];
                                newPrints[index].print_price = numericValue;

                                let artsmiley_commission_print = parseFloat(
                                  (
                                    numericValue *
                                    (print_percentage / 100)
                                  ).toFixed(2)
                                );
                                let curatorPercentage = parseFloat(
                                  (
                                    artsmiley_commission_print *
                                    (curator_percentage / 100)
                                  ).toFixed(2)
                                );
                                artsmiley_commission_print = parseFloat(
                                  (
                                    artsmiley_commission_print -
                                    curatorPercentage
                                  ).toFixed(2)
                                );
                                const artist_profit_print = parseFloat(
                                  (
                                    numericValue *
                                    ((100 - print_percentage) / 100)
                                  ).toFixed(2)
                                );

                                // Update the new properties
                                newPrints[index].artsmiley_commission_print =
                                  artsmiley_commission_print;
                                newPrints[index].artist_profit_print =
                                  artist_profit_print;
                                newPrints[index].price_of_collector_print =
                                  numericValue;
                                newPrints[index].curator_percentage_print =
                                  curatorPercentage;
                                props.setUploadArt({
                                  ...props.uploadArt,
                                  print: newPrints,
                                });
                              }}
                              fullWidth
                              disabled={!type.print}
                              placeholder="Enter Your Print Price"
                              startAdornment={
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              }
                              sx={{ mt: 1 }}
                              type="text"
                              name="print_price"
                            />
                            {thirdFormError?.[`printPrice${index}`] && (
                              <span
                                style={{
                                  color: "red",
                                  fontWeight: "bold",
                                  fontSize: "12px",
                                }}
                              >
                                {thirdFormError[`printPrice${index}`]}
                              </span>
                            )}
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <div className={styles.search_action}>
                            <lable className={styles.labels}>
                              Packaging Type *
                            </lable>
                            <Select
                              labelId="select-mutli-artist"
                              id="demo-select-artist"
                              onChange={(e) => {
                                const newPrints = [...props.uploadArt.print];
                                newPrints[index].package_type = e.target.value;
                                props.setUploadArt({
                                  ...props.uploadArt,
                                  print: newPrints,
                                });
                              }}
                              name="package_type"
                              value={printItem?.package_type}
                              fullWidth
                              sx={{
                                background: "#f6f6f6",
                                ".MuiOutlinedInput-notchedOutline": {},
                                "& .MuiInputBase-root": {
                                  borderRadius: "6px",
                                },
                                mt: 1,
                                ml: 1,
                              }}
                              placeholder="Select your frame color"
                            >
                              {[
                                { _id: 1, label: "Crate" },
                                { _id: 2, label: "Box" },
                              ].map((val) => (
                                <MenuItem value={val.label} key={val.label}>
                                  {val.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </div>
                        </Grid>{" "}
                        <Grid item xs={12} sm={6} md={6}>
                          <div className={styles.search_action}>
                            <lable className={styles.labels}>
                              Shipping weight (Artwork +{" "}
                              {printItem?.package_type}) *
                            </lable>
                            <br />
                            <FormControl fullWidth sx={{ m: 1 }}>
                              <OutlinedInput
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (isNaN(value)) {
                                    e.target.value = "";
                                  }
                                  const newPrints = [...props.uploadArt.print];
                                  newPrints[index].print_weight =
                                    e.target.value;

                                  props.setUploadArt({
                                    ...props.uploadArt,
                                    print: newPrints,
                                  });
                                }}
                                value={printItem.print_weight}
                                fullWidth
                                disabled={!type.print}
                                placeholder="Enter Your Print Weight"
                                type="text"
                                name="sale_weight"
                              />
                            </FormControl>
                            {thirdFormError?.[`printWeight${index}`] && (
                              <span
                                style={{
                                  color: "red",
                                  fontWeight: "bold",
                                  fontSize: "12px",
                                }}
                              >
                                {thirdFormError[`printWeight${index}`]}
                              </span>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                      <h3
                        className={styles.labels}
                        style={{ fontWeight: "700" }}
                      >
                        Print Pricing
                      </h3>
                      <Grid
                        container
                        columnSpacing={{ sm: 2 }}
                        columns={{ xs: 12, sm: 12, md: 12 }}
                      >
                        <Grid item xs={12} sm={6} md={4}>
                          <div className={styles.search_action}>
                            <lable className={styles.labels}>
                              Artsmiley Commission{" "}
                            </lable>
                            <br />

                            <FormControl fullWidth sx={{ m: 1 }}>
                              <OutlinedInput
                                onChange={handleChangeSale}
                                fullWidth
                                disabled={!type.sale}
                                value={printItem.artsmiley_commission_print}
                                type="text"
                                name="artsmiley_commission_print"
                                startAdornment={
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                }
                                readOnly
                              />
                            </FormControl>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <div className={styles.search_action}>
                            <lable className={styles.labels}>Your Profit</lable>
                            <br />

                            <FormControl fullWidth sx={{ m: 1 }}>
                              <OutlinedInput
                                onChange={handleChangeSale}
                                fullWidth
                                disabled={!type.sale}
                                readOnly
                                type="text"
                                name="artist_profit_print"
                                value={printItem.artist_profit_print}
                                startAdornment={
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                }
                              />
                            </FormControl>
                          </div>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <div className={styles.search_action}>
                            <lable className={styles.labels}>
                              Price the Collector will see
                            </lable>
                            <br />

                            <FormControl fullWidth sx={{ m: 1 }}>
                              <OutlinedInput
                                onChange={handleChangeSale}
                                fullWidth
                                disabled={!type.sale}
                                readOnly
                                type="text"
                                name="price_of_collector_print"
                                value={printItem.price_of_collector_print}
                                startAdornment={
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                }
                              />
                            </FormControl>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <div className={styles.search_action}>
                            <lable className={styles.labels}>
                              Curator Percentage
                            </lable>
                            <br />
                            <FormControl fullWidth sx={{ m: 1 }}>
                              <OutlinedInput
                                onChange={handleChangeSale}
                                fullWidth
                                disabled={!type.sale}
                                readOnly
                                type="text"
                                name="curator_percentage_print"
                                value={Number(printItem.artist_profit_print) -Number(printItem.artsmiley_commission_print)}
                                startAdornment={
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                }
                              />
                            </FormControl>
                          </div>
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        sx={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Stack
                          direction="row"
                          display="flex"
                          justifyContent="flex-end"
                          spacing={2}
                          sx={{ marginTop: "20px" }}
                        >
                          {props.uploadArt.print.length > 1 && (
                            <div>
                              <Button
                                variant="contained"
                                onClick={() => handleRemoveFields(index)}
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  columnGap: "5px",
                                  background: "black",
                                  color: "white",
                                }}
                              >
                                <Delete />
                                Remove
                              </Button>
                            </div>
                          )}
                          <div>
                            <Button
                              variant="contained"
                              onClick={handleAddFields}
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                columnGap: "5px",
                                background: "black",
                                color: "white",
                              }}
                            >
                              <AddIcon />
                              Add
                            </Button>
                          </div>
                        </Stack>
                      </Grid>
                    </>
                  );
                })}
              </>
            ) : (
              <>
                <div className={styles.preview_img}>
                  {props.uploadArt?.printImage === "" ? (
                    <div className={styles.img_box}>
                      <div
                        htmlFor="input"
                        name="image"
                        style={{ textAlign: "center" }}
                      >
                        <input
                          accept="image/*"
                          type="file"
                          name="image"
                          id="input"
                          hidden
                          onChange={handleChangeMainImage}
                        />
                        <label htmlFor="input" className={styles.label}>
                          <div className={styles.image_div}>
                            <img
                              src="/img/uploadimg.png"
                              width="100%"
                              height="100%"
                              alt="img"
                            />
                          </div>
                          <p>Upload your image</p>
                        </label>
                      </div>
                    </div>
                  ) : (
                    <>
                      <input
                        accept="image/*"
                        type="file"
                        name="image"
                        id="input"
                        hidden
                        onChange={handleChangeMainImage}
                      />
                      <label htmlFor="input">
                        <img
                          htmlFor="input"
                          src={printImage}
                          alt="ivmg"
                          id="img"
                          name="image"
                          className={styles.img_view}
                        />
                      </label>
                    </>
                  )}
                  <div className={styles.file_div}></div>
                </div>
                <p style={{ color: "gray", marginTop: "10px" }}>
                  Upload a high-quality image (2MB - 5MB).
                </p>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <FormGroup
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    {materialArray.map((material) => (
                      <FormControlLabel
                        key={material.id}
                        checked={materialValues[material.value]}
                        onChange={handleChange}
                        control={
                          <Checkbox name={material.value} size="small" />
                        }
                        label={material.label}
                      />
                    ))}
                  </FormGroup>
                </div>
                {props.uploadArt.print_by_artsmiley.map((printItem, index) => {
                  return (
                    <>
                      <Grid
                        container
                        columnSpacing={{ sm: 2 }}
                        columns={{ xs: 12, sm: 12, md: 12 }}
                      >
                        <Grid item xs={12} sm={12} md={12}>
                          <div className={styles.search_action}>
                            <lable className={styles.labels}>Price</lable>
                            <p className={styles.para_slider}>
                              Use the slider to adjust the price
                            </p>

                            <div
                              style={{
                                background: "#eeeeee",
                                height: "50px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Container maxWidth="sm">
                                <Slider
                                  size="small"
                                  defaultValue={0}
                                  value={printItem.print_price}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    const newPrints = [
                                      ...props.uploadArt.print_by_artsmiley,
                                    ];
                                    newPrints[index].print_price = value;

                                    const artsmiley_commission_print =
                                      parseFloat(
                                        (
                                          value *
                                          (print_percentage / 100)
                                        ).toFixed(2)
                                      );
                                    const artist_profit_print = parseFloat(
                                      (
                                        value *
                                        ((100 - print_percentage) / 100)
                                      ).toFixed(2)
                                    );

                                    // Update the new properties
                                    newPrints[
                                      index
                                    ].artsmiley_commission_print = artsmiley_commission_print;
                                    newPrints[index].artist_profit_print =
                                      artist_profit_print;
                                    newPrints[index].price_of_collector_print =
                                      value;

                                    // Update the state
                                    props.setUploadArt({
                                      ...props.uploadArt,
                                      print_by_artsmiley: newPrints,
                                    });
                                  }}
                                  aria-label="Small"
                                  valueLabelDisplay="auto"
                                  max={props.uploadArt.sale.price}
                                />
                              </Container>
                            </div>
                          </div>
                        </Grid>

                        <Grid
                          container
                          columnSpacing={{ sm: 2 }}
                          columns={{ xs: 12, sm: 12, md: 12 }}
                          px={3}
                          direction="row"
                          display="flex"
                        >
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            className={styles.inputs_gird}
                          >
                            <label>Material</label>
                            <br />
                            <Select
                              labelId="select-mutli-artist"
                              id="demo-select-artist"
                              value={printPricing.material}
                              label="Material"
                              fullWidth
                              placeholder="select type"
                              onChange={handleChangePrintpricing}
                              name="material"
                            >
                              {renderedMenuItems}
                            </Select>
                          </Grid>
                          {printPricing.material === "metal" && (
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              md={6}
                              className={styles.inputs_gird}
                            >
                              <label>Metal Thickness</label>
                              <br />
                              <Select
                                labelId="select-mutli-artist"
                                id="demo-select-artist"
                                // value={printPricing.material}
                                label="Metal Print"
                                fullWidth
                                placeholder="select type"
                                onChange={handleChangePrintpricing}
                                name="metal_print"
                              >
                                <MenuItem value="metal print 1.8 mm">
                                  1.8 mm
                                </MenuItem>
                                <MenuItem value="metal print 2.8 mm">
                                  2.8 mm
                                </MenuItem>
                                <MenuItem value="metal print 3.8 mm">
                                  3.8 mm
                                </MenuItem>
                                <MenuItem value="metal print 2 mm">
                                  2 mm
                                </MenuItem>
                                <MenuItem value="metal print 4 mm">
                                  4 mm
                                </MenuItem>
                                <MenuItem value="metal print 6 mm">
                                  6 mm
                                </MenuItem>
                                <MenuItem value="metal print 8 mm">
                                  8 mm
                                </MenuItem>
                                <MenuItem value="metal print 10 mm">
                                  10 mm
                                </MenuItem>
                              </Select>
                            </Grid>
                          )}
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                          <PrintTableArtsmiley
                            print_percentage={print_percentage}
                            printPricingResponse={printPricingResponse}
                            width={props.uploadArt.width}
                            height={props.uploadArt.height}
                            getProductOptions={getProductOptions}
                            basePrice={
                              props.uploadArt.print_by_artsmiley?.[0]
                                .print_price
                            }
                            props={props}
                            pricingData={pricingData}
                            setPricingData={setPricingData}
                          />
                        </Grid>
                      </Grid>
                    </>
                  );
                })}
              </>
            )}

            {window.location.pathname === "/edit/stepthird" ? (
              <div className={styles.btn_div}>
                <Button
                  onClick={handleUpdateArt}
                  variant="contained"
                  sx={{ width: "156px", height: "54px", borderRadius: "10px" }}
                >
                  Update
                </Button>
              </div>
            ) : (
              <div className={styles.btn_div}>
                <Button
                  onClick={handleUploadArt}
                  variant="contained"
                  sx={{ width: "156px", height: "54px", borderRadius: "10px" }}
                >
                  Save
                </Button>
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
