import { Container, Link, Grid } from "@mui/material";
import React, {useRef} from 'react';
import { useNavigate } from "react-router-dom"
import styles from "./CommisionedArt.module.css";
import { RiArrowRightLine, RiArrowLeftLine  } from "react-icons/ri";
import CssBaseline from "@mui/material/CssBaseline";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import homeImage from "../../../constant";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import striptags from "striptags";
const service_data = [
  {
    id: 1,
    heading: "Initial Consultation",
    des: "The first step in the process to rent artwork is to get in touch with us. We will set up a consultation to learn more about your needs, helping us understand your style and ensuring we make the best recommendations for your space.",
  },
  {
    id: 2,
    heading: "Explore Rental Products",
    des: "Navigate our website to view a comprehensive list of rental products, including sculptures, framed artworks, and canvas prints. Our collection caters to a variety of tastes, from contemporary to classic styles.",
  },
  {
    id: 3,
    heading: "Curated Selection",
    des: "Once we have a clear understanding of your preferences, our team will handpick a curated selection of affordable art rental pieces that meet your needs. You’ll receive detailed photos and descriptions of each piece, along with background information about the artist and the concept behind the work.",
  },
  {
    id: 4,
    heading: "Finalizing Your Rental",
    des: "Once you’ve chosen the perfect piece or collection, we’ll finalize the rental terms with you. Our flexible rental agreements range from short-term to long-term, depending on your needs. You can rent paintings or sculptures for a few months or even longer, making this an ideal option for businesses or events that require temporary art displays.",
  },
  {
    id: 5,
    heading: "Delivery and Installation",
    des: "We offer full-service delivery and installation to ensure that your rented artwork is displayed to perfection. Our professional team will deliver the pieces to your location within the UAE, carefully install them, and make sure everything is perfectly placed. We do take care of the collection of the artwork from your place after the completion of the rental period.",
  },
  {
    id: 6,
    heading: "Rental Insurance Policy",
    des: "When you choose art rental for homes and businesses, rental insurance is included to cover the artwork throughout the rental period. We’ll inform you of the insurance cost or damage waiver up front, ensuring the artwork is safeguarded in case of any accidental damage.",
  },
  {
    id: 7,
    heading: "Ongoing Support",
    des: "Art for rent comes with ongoing support during your rental period. Whether you want to update the artwork or need help with any concerns, we’re here to ensure a smooth experience. Our team can also assist with artwork maintenance as needed. Our goal is to ensure that you have a smooth and enjoyable experience with our art rental service.",
  },
];
const remarableevents =[
  {
    id:1,
    heading: "Affordability",
    des: "With affordable art rental, you can enjoy high-quality art without the large upfront cost of purchasing it, making it a cost-effective solution for decorating your space.  This gives you the opportunity to experience the luxury of fine art without the financial burden of owning it.",
  },
  {
    id:2,
    heading: "Variety",
    des: "Access a wide range of art styles and genres, allowing you to explore pieces that resonate with your taste. Whether you prefer contemporary, classic, or abstract art, renting lets you diversify your collection with no long-term commitment.",
  },
  {
    id:3,
    heading: "Access to Rare Artworks",
    des: "Art lease opens the door to enjoying works from renowned artists and emerging talents outside your budget. This makes it possible for you to appreciate exclusive pieces that may otherwise be out of reach, enriching your collection.",
  },
  {
    id:4,
    heading: "Perfect for Seasonal or Temporary Spaces",
    des: "Renting art is ideal for events or seasonal displays, offering the flexibility to update pieces regularly. Whether it's for a holiday, special occasion, or a short-term exhibition, renting ensures your space always feels fresh and aligned with the theme.",
  },
  {
    id:5,
    heading: "No Long-Term Commitment",
    des: "Renting art offers the freedom to try new styles without the responsibility of long-term ownership. You can refresh your space at any time with art rental UAE, ensuring that the art you have on display always aligns with your current tastes or needs.",
  },
  {
    id:6,
    heading: "Try Before You Buy",
    des: "You can rent artwork before purchasing. The minimum renting period for an artwork is three months. You can purchase the artwork with a 30% rental amount credited for each month. This gives you the opportunity to experience the artwork in your space, making sure it fits your environment before making a final decision.",
  },
  {
    id:7,
    heading: "Easels, Framing, and Installation",
    des: "Our UAE art rental services offers easels, framing, shipping, and installation of artworks, prints, or sculptures to ensure seamless artwork setup. Our team will assist you every step of the way, from delivering the artwork to professionally mounting it in your space.",
  },
  {
    id:8,
    heading: "Elevate the Ambience",
    des: "Renting an artwork enhances the atmosphere of your space by adding carefully selected pieces that match your style. With the right artwork, you can transform the mood of a room, creating a more inviting and visually captivating environment.",
  },
  {
    id:9,
    heading: "Complementary Art Advisory",
    des: "Our art advisors provide expert guidance to help you choose the perfect artwork for your space and taste. Whether you're looking for something bold or subtle, we will assist in curating the ideal pieces that suit your aesthetic preferences and needs.",
  },
];
 
const whyrental_data = [
  {
    id: 1,
    src: "/img/artcollections",
    heading: "Affordable and Flexible Art Rental",
    des: "Renting art is an affordable way to experience high-quality, original works without breaking the bank. It also offers flexibility to swap out pieces whenever you like to refresh your space, keep up with trends, or change your aesthetic.",
  },
  {
    id: 2,
    src: "/img/artcollections",
    heading: "Curated Collection",
    des: "We understand that art is personal, and we take pride in offering a curated collection of works that fit a wide range of tastes and styles. Our team will help you find the perfect pieces to complement your environment. We offer paintings, prints, sculptures, photography, and more, ensuring you have access to a diverse selection.",
  },
  {
    id: 3,
    src: "/img/artcollections",
    heading: "Perfect for Businesses & Events",
    des: "Art Smiley’s rental service is ideal for businesses, offices, and event spaces. We offer a wide selection of pieces to fit any professional environment whether you're outfitting a corporate office, a hotel, or a restaurant. Our art can help set the tone for your brand, inspire creativity, and create a welcoming atmosphere. ",
  },
  {
    id: 4,
    src: "/img/artcollections",
    heading: "Expert Guidance",
    des: "Choosing the right artwork can be overwhelming, but you don’t have to do it alone. Our team of expert curators is here to guide you through the selection process. We take the time to understand your space, goals, and preferences to ensure that each piece we recommend complements your environment and enhances your vision. ",
  },
];
 

const testimoniald = [
  {
    id:1,
    src: "/img/event3",
    name: "Michael R.",
    designation: "HR Director",
    hnd:"Corporate Office Transformation",
    des: "Art Smiley elevated our corporate office with a stunning collection of sculptures and artwork. The curated selection perfectly matched our brand's identity, impressing both clients and employees. The rental process was smooth and hassle-free, making it an ideal solution for our dynamic workspace.",
  },
  {
    id:2,
    src: "/img/event3",
    name: "Clara S.",
    designation: "Hotel Manager",
    hnd:"Luxury Hotel Interior Design",
    des: "We partnered with Art Smiley to enhance the ambiance of our hotel's lobby and suites. Their rental artworks and sculptures added sophistication and charm, leaving a lasting impression on our guests. The flexibility of their rental agreements made it easy to refresh the decor seasonally.",
  },
  {
    id:3,
    src: "/img/event3",
    name: "David T.",
    designation: "Retail Marketing Manager",
    hnd:"Retail Store Launch Event",
    des: "For our flagship store's grand opening, Art Smiley provided an exquisite selection of contemporary art and sculptures. The rented pieces were perfectly aligned with our brand aesthetic and created a memorable experience for our customers. We couldn't have asked for better service.",
  },
  {
    id:4,
    src: "/img/event3",
    name: "Dr. Emily W.",
    designation: "Clinic Owner",
    hnd:"Healthcare Facility Renovation",
    des: "Art Smiley helped us create a calming and uplifting environment in our clinic with their rental art collection. The team recommended beautiful, soothing pieces that resonated with our patients. Their ongoing support during the rental period was exceptional.",
  },
  {
    id:5,
    src: "/img/event3",
    name: "Alex H.",
    designation: "COO",
    hnd:"Technology Startup Collaboration Space",
    des: "As a fast-growing startup, we wanted our office space to reflect innovation and creativity. Art Smiley's rental artwork and sculptures brought energy and inspiration to our collaboration areas. The service was professional, and the team understood our unique requirements.",
  }, 
];
const tpoics =[
  {
    id:1,
    heading: "Sophisticated Curation",
    des:"We search the globe for exceptional artists, bringing you a curated selection of world-class talent to enrich your collection.",
    link:"",
  },
  {
    id:2,
    heading: "Easy Returns",
    des:"With our 14-day worldwide return policy, you can easily return anything that isn’t perfect for your space.",
    link:"/user/return-policy"
  },
  {
    id:3,
    heading: "Secure Transactions",
    des:"With top-level security measures, your transactions are processed through high-level secured payment gateways.",
    link:""
  },
  {
    id:4,
    heading: "Dedicated Support",
    des:"Our curators are here to guide you in selecting artwork that perfectly complements your theme and style. Art Smiley ensures a seamless experience from artwork selection to delivery,—reach out to us anytime!",
    link:"/user/art-consultancy"
  },
];

function ArtRental() {
  const prevButtonRef = useRef(null);
        const nextButtonRef = useRef(null);
  const navigate = useNavigate()

  const handleNavigate = (route) => {
    navigate(route)
  }
  const scrollToSection = (id) => {
  const element = document.querySelector(id);
  if (element) {
    const headerOffset = 150; // Adjust for header height
    const elementPosition = element.offsetTop;
    const offsetPosition = elementPosition - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }
};

const [exclusiveExpanded, setExclusiveExpanded] = React.useState('excpanel0'); // Default to the first panel

const handleExclusiveChange = (panel) => (event, isExpanded) => {
  setExclusiveExpanded(isExpanded ? panel : false);
};
  return (
    <>
    <React.Fragment>
       <CssBaseline />
    <section className={styles.hero_main}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
        <div className={`${styles.pageitle} ${styles.max_width}`}>
              <h1>Art Smiley Art Rentals</h1>
              <p className={`${styles.withor} ${styles.whitext}`} >Elevate Your Environment With Unique Art Rental Pieces To Bring Fresh Energy.</p>
              <Link 
             href="/user/search"
              className={styles.apply_btnwht}
            >
              Rent Art Now
            </Link>
            </div>
</Container>
        </section>
        <section className={styles.sec_sc}>
            <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
             
          <div className={styles.spcebtnm}>
          <Grid container spacing={2} sx={{justifyContent:"space-between", alignItems:"center"}}>
          <Grid item md={6} sm={6} xs={12} order={{ xs: 2, sm: 1 }}>
          <div>
              <img src={"/img/Mockup.png"} alt="mockup" className="img-fluid" />
              </div>
          </Grid>
          <Grid item md={5} sm={6} xs={12} order={{ xs: 1, sm: 2 }}>
          <p className={styles.prtra}>At Art Smiley, we believe that art should be accessible, dynamic, and ever-changing. Our art rental service offers an easy and flexible way to enjoy beautiful works of art without a long-term commitment. With our curated collection of high-quality pieces, you can refresh your surroundings. With Art Smiley’s art rental services, you can change your space and express your style while enjoying the beauty and inspiration that art brings.</p>
      
          </Grid> 
          
          </Grid>
          </div> 
          </Container>
          </section>
          <section className={styles.sec_scexcl}>
            <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
            <div className={`${styles.text_center} ${styles.max_width1}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>Why Choose Art Smiley Art Rental Services?</h3>
          <p className={styles.withor}>Art is a powerful tool for transformation. It can completely change the feel of a space, spark creativity, and leave a lasting impression on visitors and guests. With our art rental service, you can experience the art world without the pressure of ownership. Here’s why our service is the perfect choice:</p>
          </div>
            <div className={styles.taldartist}>
            <Grid container spacing={2}>
            {whyrental_data.map((val) => (
            <Grid item xs={12} sm={6} md={6} key={val.id}>
              <div className={styles.talhg}>
                <div className={styles.talgbx}>
                <div className={styles.talenimg}>
                    <img
                    alt="img"
                    src={`${val.src}.png`}
                    className="img-fluid"
                  />
                    </div>
                    <div className={styles.talentcont}>
                      <h4>{val.heading}</h4>
                      <p>{val.des}</p>
                    </div>
                </div>
              </div> 
            </Grid>
            ))}
            </Grid>
              
            </div>
             
            
          </Container>
          </section>
          <section className={styles.sec_sc}>
            <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
            <div className={`${styles.text_center} ${styles.max_width}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>Art Smiley Art Rental Process</h3>
          <p className={styles.withor}>Renting art from Art Smiley is a simple, straightforward process designed to make art accessible to everyone. Art Smiley offers art rental services for customers within UAE only.</p>
          </div>
          <div className={styles.spcebtnm}>
          <Grid container spacing={2} sx={{justifyContent:"space-between", alignItems:"center"}}>
          <Grid item md={6} sm={6} xs={12}>
          <div>
              <img src={"/img/Mockup.png"} alt="mockup" className="img-fluid" />
              </div>
          </Grid>
          <Grid item md={5} sm={6} xs={12}>
      <div className={`${styles.accordsc}`}>
      {service_data.map((val, index) => {
      const panelId = `excpanel${index}`; // Dynamic panel ID
      return (
        <Accordion
          key={panelId}
          expanded={exclusiveExpanded === panelId} // Check if the panel is expanded
          onChange={handleExclusiveChange(panelId)}
          sx={{
            "& .MuiAccordionSummary-contentGutters": {
              margin: "10px 0", // Adjust as needed
            },
            "&.MuiPaper-root": {
              boxShadow: "none",
              borderBottom: "1px solid #D9DBE9",
              marginBottom:"0",
              borderBottomLeftRadius:"0",
              borderBottomRightRadius:"0",
            },
            "&.MuiAccordion-root::before":{
              display:"none",
            },
            "& .Mui-expanded": {
              minHeight:"auto",
              margin:"0 0 10px 0"
            },
            "& .MuiAccordionSummary-expandIconWrapper":{
              background:"#B7B7B7",
              opacity:".3",
              textAlign: "center",
              borderRadius:"8px",
            },
            "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded":{
              background:"#1C1C1C",
              opacity:"1",
              color:"#fff",
            },
            "& .MuiAccordionDetails-root":{
              paddingTop:"0"
            },
            
          }}// Handle expansion state
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${panelId}-content`}
            id={`${panelId}-header`}
          >
            <h4 className={styles.benfhdgn}>{val.heading}</h4>
          </AccordionSummary>
          <AccordionDetails>
            <p className={styles.bendesc}>{val.des}</p>
          </AccordionDetails>
        </Accordion>
      );
    })}

      </div>
      <div className={styles.mobcenter}>
      <Link href="/user/search"
                  className={`${styles.mt_2} ${styles.apply_btn}`}
                > Explore Rental Art
                </Link> 
      </div>
          </Grid> 
          
          </Grid>
          </div> 
          </Container>
          </section>
          <section className={styles.sec_scexcl}>
            <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
            <div className={`${styles.text_center} ${styles.max_width}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}> Benefits of Renting Art from Art Smiley</h3>
          <p className={styles.withor}>When you choose Art Smiley’s art rental service UAE, you’re choosing more than just a decoration. Here are a few more reasons why renting art can be the right decision for you:</p>
          </div>
          <Grid container spacing={2}>
          
          {remarableevents.map((val, index) => (
                <Grid item xs={12} sm={6} md={4} key={val.id} >
                  <div  key={index} className={styles.stboxh}>
                <div className={styles.rechhbox}>
                  <div className={styles.sticon}>
                    <p className={styles.hgrd}>{val.id}</p>
                  </div>
                  <h4>{val.heading}</h4>
                  <div className={styles.stcont}>
                     <p>{val.des}</p>
    </div>
                </div>
                </div> 
                </Grid>
          ))}
            </Grid>
          </Container>
          </section>
          <section className={styles.sec_sc}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
        <Grid container spacing={2} sx={{justifyContent:"space-between", alignItems:"center"}}>
        <Grid item xs={12} sm={6} md={5}>
        <div className={styles.leftText}>
              <h4 className={styles.abttitle}>Art Smiley for Business</h4>
              <p className={styles.prtra}>We collaborate with businesses across various sectors including hospitality, commercial, healthcare, and residential to create custom art rental solutions that enhance any space. Whether you’re looking to elevate the atmosphere of a hotel, office, clinic, or residential project, our tailored artwork options meet your needs. Our team ensures a seamless experience from concept to installation, transforming your environment with distinctive, high-quality artwork that reflects your brand’s unique identity. We understand the importance of creating a memorable impression, and with our expert guidance, we help bring your vision to life with art that not only fits your space but also resonates with your target audience.</p>
                 <Link className={styles.apply_btn} href="/user/art-consultancy/art-smiley-for-business">Explore the Details</Link>
              </div>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
              <div className={styles.vintageframeDiv}>
                <img
                  className="img-fluid"
                  src={ "/img/vintage-wood-frame.png"} alt=""
                />
              </div>
        </Grid>
        </Grid>
        </Container>
      </section>
      <section className={styles.sec_scexcl}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
        <Grid container spacing={2} sx={{justifyContent:"space-between", alignItems:"center"}}>
        <Grid item xs={12} sm={6} md={6} order={{ xs: 2, sm: 1 }}>
              <div className={styles.vintageframeDiv}>
                <img
                  className="img-fluid"
                  src={ "/img/vintage-wood-frame.png"} alt=""
                />
              </div>
        </Grid>
        <Grid item xs={12} sm={6} md={5} order={{ xs: 1, sm: 2 }}>
        <div className={styles.leftText}>
              <h4 className={styles.abttitle}>Art Smiley for Interior Designers</h4>
              <p className={styles.prtra}>Enhance your design projects with access to exclusive, curated art collections. Art Smiley offers a wide selection of rental artworks for interior designers, providing temporary solutions for installations, events, or staging. Our rental service allows you to access high-quality pieces from contemporary sculptures to unique paintings and prints, without the long-term commitment. With personalized support and trade rates, we help you find the perfect pieces to complement your vision and bring your spaces to life. Whether you're updating a space seasonally or for specific projects, we offer flexible rental options to meet your needs.</p>
                 <Link className={styles.apply_btn} href="/user/art-consultancy/art-for-interior-designers">Explore the Details</Link>
              </div>
        </Grid>
        </Grid>
      

        </Container>
      </section>
      <section className={styles.sec_sc}>
            <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
            <div className={`${styles.text_center} ${styles.max_width}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>Testimonials</h3>
          </div>
          <section className={`testmo-slider ${styles.sections}`}>
          <Swiper
        modules={[Navigation, Autoplay]}
                slidesPerView={1}
                spaceBetween={10}
                loop={true}
                autoplay={{
                  delay: 3000, // Set autoplay delay in milliseconds (3 seconds here)
                  disableOnInteraction: false, // Allows autoplay to continue after user interactions
                }}
                onInit={(swiper) => {
                  swiper.params.navigation.prevEl = prevButtonRef.current;
                  swiper.params.navigation.nextEl = nextButtonRef.current;
                  swiper.navigation.init();
                  swiper.navigation.update();
                }}
        breakpoints={{
          480: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 15,
          },
          1024: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
        }}
      >
{testimoniald.map((val, index) => (
                    <SwiperSlide key={index}>
                      <div className={styles.tehgt}>
                       <div className={styles.testmibox}>
                      <div className={styles.tecont}>
                        <div className={styles.hders}>
                        <div className={styles.testimonial_img_div}>
                          <img
                            src={`${val.src}.png`}
                            width="80px"
                            height="80px"
                            alt=""
                          />
                        </div>
                        <div className={styles.test_metainfo}>
                        <h5 className={styles.test_card_heading}>
                        {val.name}
                          </h5>
                          <h6 className={styles.test_card_subhead}>
                          {val.designation}
                          </h6>
                        </div>
                        </div>
                        <div className={styles.test_cont}>
                          <h4>{val.hnd}</h4>
                          <p className={styles.test_card_des}>{val.des}</p>
                        </div>
                        </div>
                      </div>
                      </div>
                    </SwiperSlide>
                 ))}
              </Swiper>
              <div className="tesarrow">
          <div ref={prevButtonRef} className="swiper-button-prev">
                    <RiArrowLeftLine />
                  </div>
                  <div ref={nextButtonRef} className="swiper-button-next">
                    <RiArrowRightLine />
                  </div>
      </div>
        </section>
        <div className={`${styles.mt_5} ${styles.text_center}`}>
      <Link href="/user/search"
                  className={`${styles.mt_2} ${styles.apply_btn}`}
                > Browse Art Rentals
                </Link> 
      </div>
        </Container>
        </section>
        <section className={styles.sec_scexcl}>
            <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
            <div className={`${styles.text_center} ${styles.max_width1}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>Art Rental Terms and Conditions</h3>
          <p className={styles.withor}>To ensure a seamless rental experience, Art Smiley has established the following terms and conditions:</p>
          </div>
          <ul className={styles.termlistnum}>
            <li><span>Rental Period:</span> The minimum rental period is three months, with flexible extension options available. You can purchase the artwork with a 30% rental amount credited for each month.</li>
            <li><span>Eligibility:</span> Art rental services are exclusively available to UAE-based customers.</li>
            <li><span>Delivery and Collection:</span> We provide delivery, installation, and collection of the artwork services within UAE by informing the charges at the time of renting.</li>
            <li><span>Care of Artwork:</span> Customers are responsible for maintaining the artwork in its original condition during the rental period.</li>
            <li><span>Insurance:</span> Rental insurance is mandatory and protects the artwork against accidental damage. The cost will be communicated before finalizing the agreement.</li>
            <li><span>Payment Terms:</span> Payment must be made in advance as per the rental agreement. Late payments may incur additional charges.</li>
            <li><span>Termination or Extension:</span> Rental period can be extended or terminated early with prior notice, subject to terms outlined in the agreement.</li>
          </ul>
          </Container>
          </section>
          <section className={styles.sec_sc}>
            <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
            {/* <div className={`${styles.text_center} ${styles.max_width}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}> Benefits of Renting Art from Art Smiley</h3>
          <p className={styles.withor}>When you choose Art Smiley’s art rental service UAE, you’re choosing more than just a decoration. Here are a few more reasons why renting art can be the right decision for you:</p>
          </div> */}
          <Grid container spacing={2}>
          
          {tpoics.map((val, index) => (
                <Grid item xs={12} sm={6} md={3} key={val.id} >
                  <div  key={index} className={styles.stboxh}>
                <div className={styles.rechhbox2}>
                  <div className={styles.sticon}>
                    <p className={styles.hgrd}>{val.id}</p>
                  </div>
                  <div className={styles.hdrng}>
                  <h4>{val.heading}</h4>
                  <div className={styles.stcont}>
                    
                     <p>{val.des}</p>
    </div>
                  </div>
                  <a  href={val.link} className={styles.linkdf}>Read More</a>
                </div>
                </div> 
                </Grid>
          ))}
            </Grid>
          </Container>
          </section>
          </React.Fragment>
    </>
  );
}

export default ArtRental;
