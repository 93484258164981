import { createTheme, ThemeProvider, Link, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./TandC.module.css"; 
import { useNavigate } from "react-router-dom";
import { setLoader } from "../../../State/Features/common/loaderSlice";
import api from "../../../State/api/Api";
import { useDispatch } from "react-redux";

const theme = createTheme({
  typography: {
    h4: {
      fontFamily: "Marcellus",
      fontSize: "2vw",
    },
    h6: {
      fontFamily: "Marcellus",
      fontSize: "1vw",
    },  
  },
});
function ShippingPolicy() {
  const [termsData, setTermsData] = useState();

  const dispatch = useDispatch();

  const handleFetchTermsCondition = async function () {
    dispatch(setLoader(true));
    try {
      const result = await api.applicationApi('api/user/termsCondition'
      )
      if (result && result.status === 200) {
        setTermsData(result.data.response);
        dispatch(setLoader(false));
      }
      else if (result.status === 401) {
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    handleFetchTermsCondition();
  }, [])
  const navigate = useNavigate();
  const handleNavigate = (route) => {
    navigate(route)
  }
  return (
    <ThemeProvider theme={theme}>
      <div className={styles.tandc}>
        <div className={styles.allcategory}>
          <div className={styles.headerCategory}>
          <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
            <div className={styles.pageitle}>
              <h1>Shipping Policy</h1>
              <ul>
                <li><Link onClick={() => handleNavigate("/")}>Home /{" "} </Link></li>
                <li>Shipping Policy</li>
              </ul>
            </div>
          </Container>
          </div>
        </div>
        {termsData && termsData.map((val) => (
          <div className={styles.tandc__content}>
            <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
            <div>
              <h3>
              Shipping Policy For Artists
              </h3>
              {/* <p>{val.description}</p>
              <h4>
                {val.title}
              </h4> */}
              <p>At Art Smiley, we are committed to ensuring the safe and efficient artwork shipping to customers worldwide. This document outlines the comprehensive shipping policies and guidelines that artists must follow to ensure their artworks are protected during transit and remain eligible for insurance coverage.
              </p>
              <h4>Shipping Insurance Policy</h4>
              <p>To ensure shipping artwork safely, Our shipping insurance policy applies only when artwork is packaged precisely as per our guidelines. Any damage caused during delivery will not be covered if the packaging does not meet these standards. Following our guidelines accurately is critical for ensuring protection and insurance eligibility. We request you to share the photographs of the packed artworks and sculptures to our email(contactus@artsmiley.com) before shipment.</p>
              <h4>Packaging Guidelines</h4>
              <p>To guarantee art delivery safely, it is vital to use appropriate packing materials as your parcels move through our advanced global network. To check the detailed general packing instructions and practices for all shipments click on the following link.</p>
              <h5>1. Quick Tips</h5>
              <ul>
                <li><b>Assess:</b>{" "} Consider weight, fragility, value, and regulatory requirements.</li>
                <li><b>Pack:</b>{" "} Use double wall boxes, ensure a 6cm buffer between contents and box walls, and fill voids.</li>
                <li><b>Seal:</b>{" "} Apply pressure-sensitive tape using the H-taping method.</li>
                <li><b>Label:</b>{" "} Place the shipment label on the top surface and ensure it's visible.</li>
              </ul>
              <h5>2. Assess Your Packing Needs</h5>
              <ul>
                <li><b>Weight:</b>{" "} Choose a box that can securely hold the contents.</li>
                <li><b>Size and Shape:</b>{" "} Ensure items don’t touch the outer wall; odd-shaped items may require special packaging.</li>
                <li><b>Value:</b>{" "} Use extra cushioning for high-value goods.</li>
                <li><b>Fragility:</b>{" "} Apply additional protection for fragile items.</li>
                <li><b>Cartons/Boxes:</b>{" "} Used for standard-sized framed artworks, ensuring there is ample internal padding.</li>
                <li><b>Tubes:</b>{" "} Ideal for rolled paintings, prints, and canvases to prevent creasing or bending during transit.</li>
                <li><b>Crates:</b>{" "} Necessary for large artwork shipping, heavy, or highly valuable artworks or sculptures, as they provide maximum protection during long-distance shipping.</li>
              </ul>
              <h5>3. Pack with Care</h5>
              <h6>For Framed Artwork with Glass:</h6>
              <p>For framed paintings with glass, extra care is necessary to ensure safe painting delivery. Follow these steps:</p>
              <ul>
                <li>Place a sheet of acid-free paper or glassine over the artwork to safeguard the surface.</li>
                <li>Use corner protectors on the frame to shield its edges.</li>
                <li>Wrap the entire piece with multiple layers of bubble wrap, ensuring the glass is fully cushioned.</li>
              </ul>
              <h6>For Unframed Artwork:</h6>
              <p>For unframed artwork, proper art handling techniques are essential to prevent damage during shipping. Follow these steps:</p>
              <ul>
                <li>Cover the artwork with acid-free paper or glassine.</li>
                <li>If rolling is applicable, carefully roll the artwork around a sturdy, wide-diameter tube with the image side facing outward to prevent creasing.</li>
                <li>Secure the roll with an additional layer of acid-free paper or bubble wrap.</li>
            </ul>
              <h5>4. Securing the Artwork in the Box</h5>
              <ul>
              <li>Start by placing a layer of bubble wrap or packing peanuts at the bottom of the box or crate.</li>
              <li>Position the wrapped artwork in the center of the box, ensuring it does not come into direct contact with the sides.</li>
              <li>Fill any remaining gaps around the artwork with more bubble wrap or packing peanuts to prevent movement during shipping.</li>
              <li>Add a final layer of bubble wrap or packing material on top of the artwork before sealing the box.</li>
              </ul>
              <h5>5. Seal Securely</h5>
              <ul>
                <li><b>Tape Application:</b>{" "} Use pressure-sensitive tape with a width of at least 48mm. Apply the H-taping method:
                <ol>
                  <li>Apply one strip along the center seam.</li>
                  <li>Apply two strips across edge seams.</li>
                  <li>Add more strips for heavier shipments.</li>
                </ol>
                </li>
                <li><b>Avoid Strapping:</b>{" "} For items under 30 kg, avoid using strapping. Ensure the packaging itself is strong enough.</li>
              </ul>
              <h5>6. Label Clearly</h5>
              <ul>
                <li><b>Shipment Label:</b>{" "} Place the shipment label on the top surface of the box, clearly visible and not covering seams. Consider using a plastic self-adhesive clear window pouch for any loose documents.</li>
                <li><b>Special Handling Labels:</b>{" "} Apply any required special handling labels (e.g., "Fragile," "This Way Up") to indicate necessary care during transit.</li>
              </ul>
              <h4>Order Tracking</h4>
              <p>When an order is placed for an artwork, the artist will receive a notification via email. This email confirms that the order has been placed and provides the relevant details. The artist must accept the order from the buyer and schedule the pickup, ensuring that the artwork is packaged properly by following Art Smiley’s detailed packaging guidelines.</p>
              <h4>Shipping Costs</h4>
              <p>At Art Smiley, buyers are responsible for covering shipping costs, which are calculated based on the origin of the artwork and the delivery destination. These costs are transparently displayed during the purchase process, ensuring clarity before checkout. While buyers handle the fees, artists must ensure secure and adequate packaging to protect their artwork during transit, creating a seamless experience that balances affordability and reliability for all parties.</p>
              <h5>Customs Duties for International Orders</h5>
              <p>Customs duties and taxes may apply depending on the destination country's regulations. These charges are determined by local authorities and are the responsibility of the customer. Art Smiley has no control over these fees, as they vary based on the value of the artwork, local tax policies, and import rules. To ensure a smooth delivery process, we recommend customers familiarize themselves with their country's customs procedures and potential charges before placing an order. Please note that delays caused by customs clearance are beyond our control and may impact the estimated delivery timeline.</p>
              <h5>Address Change Policy</h5>
              <p>If you need to modify your delivery address, you can do so before the artwork is dispatched by updating it directly through your dashboard and scheduling a pick-up. Once the artwork has been dispatched, address changes are no longer possible.</p>
              <h5>Key Points to Remember:</h5>
              <p><b>Before scheduling the pick up:</b>{" "} Update your delivery address through the dashboard and schedule a pick-up. Make sure to make these changes promptly to avoid complications.</p>
              <p><b>After scheduling the pick up:</b>{" "} If you change the address after the artwork is scheduled for pick up or provide incorrect pick up address, then you will be responsible for any additional shipping costs.</p>
              <h4>Damaged Artwork Policy</h4>
              <p>At Art Smiley, we are committed to ensuring your artwork arrives in excellent condition, but in the rare instance that damage occurs during shipping, we want to make the process as smooth as possible. Here’s a breakdown of our policy regarding damaged artwork:</p>
              <h5>1. Proper Packaging and Artist Protection</h5>
              <p>If the artwork is carefully packed in accordance with our Packaging Guidelines[provide the link for packaging guideline], the artist will still receive their full commission, even if the piece is damaged during transit. We will also take full responsibility for working with the carrier to resolve the situation and pursue the necessary claim.</p>
              <h5>2. Artist Responsibility for Inadequate Packaging</h5>
              <p>In cases where the artwork arrives damaged due to insufficient or improper packaging, the shipment will not be eligible for insurance coverage. This means the artist will be held responsible for the damage. To avoid this, we require that artists submit photographs of their packed artwork to our email contactus@artsmiley.com before shipping so we can confirm that it adheres to our guidelines. Artists are encouraged to document each step of the packing process to ensure the packing is up to standard.</p>
              <h5>3. Importance of Proper Documentation</h5>
              <p>We highly recommend that artists take step-by-step photographs of the packing process. This will help verify that the packaging materials and methods are compliant with our guidelines and can prevent shipping issues. Documentation is essential in ensuring the artwork is properly protected and provides evidence in the unlikely event of a dispute.</p>
              <h5>4. Unfortunate Situations of Severe Damage</h5>
              <p>In the rare scenario that artwork is severely damaged due to negligence in packaging, carriers may refuse to transport the damaged goods back. In such cases, Art Smiley reserves the right to discard the damaged artwork. This highlights the importance of following our Packaging Guidelines meticulously to avoid such outcomes.</p>
              <h5>5. Safe Delivery with Third-Party Support</h5>
              <p>We work with trusted third-party delivery services to ensure that your artwork is safely transported to its destination. These third-party carriers help us ensure that your artwork is handled with the utmost care during transit. We rely on their professional expertise to deliver your artwork safely and in perfect condition.</p>
              <p>By carefully following these guidelines, we can work together to ensure that your artwork arrives safely and in perfect condition, safeguarding both the artist's interests and the customer’s experience. Your cooperation is essential for a smooth and reliable shipping process.</p>
              
              
            </div>
            </Container>
            <div className={styles.privacy__buyer}>
          <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
          <div >
            <h3>
            Art Smiley Shipping Policy for Buyer
            </h3>
            <p>At Art Smiley, we strive to deliver your artwork with precision, care, and efficiency. This shipping policy outlines everything you need to know, including order tracking, customs duties, address changes, damaged artwork claims, and our shipping insurance policy. Our buyer shipping policy for artwork ensures that your purchases are handled with the utmost care. It covers the responsibilities regarding shipping times, packaging standards, return shipping costs, and procedures for reporting and resolving any issues with damaged items. We are committed to ensuring a smooth and hassle-free experience for all our customers.</p>
            <h4>1. Local and International Shipping</h4>
            <p>Our shipping timeline is categorized into local and international shipping based on the origin and destination of the artwork.</p>
            <h5>Local Art Delivery Guidelines</h5>
            <p>If the artwork's origin and destination are within the same country, it will fall under local shipping with the following timelines:</p>
            <ul>
              <li><b>Sculptures and Framed Artworks:</b>{" "} Delivered within 7-10 business days from the order date.</li>
              <li><b>Rolled Artworks and Prints:</b>{" "} Shipped faster, arriving within 5-7 business days.</li>
              <li>Shipping fees are based on the size of the artwork and calculated during checkout.</li>
            </ul>
            <h5>International Shipping</h5>
            <p>If the artwork’s origin and destination are in different countries, it will be categorized as international art shipping with the following timelines:</p>
            <ul>
              <li><b>Sculptures and Framed Artworks:</b>{" "} Delivered within 15-20 business days from the order date.</li>
              <li><b>Rolled Artworks and Prints:</b>{" "} Shipped in protective tubes, arriving within 8-12 business days.</li>
              <li>Customs duties and taxes may apply to international orders and are the responsibility of the customer. These charges are determined by the local customs office and are not included in the purchase price or shipping fees.</li>
              <li>Orders are processed and shipped only on business days (Monday to Friday).</li>
              <li>Delays in international shipping services due to customs clearance or other external factors do not entitle customers to refunds for shipping costs. Before placing an international order, it is recommended to consult your local customs office to understand the potential costs and procedures.</li>
            </ul>
            <h5>Shipping Costs</h5>
            <p>Shipping fees are calculated based on several factors, including the artwork's dimensions, weight, origin, and destination. These costs will be clearly outlined during the checkout process. Please note that additional fees such as customs duties, taxes, or import charges may apply for international shipments, depending on your country’s regulations. These charges are not included in the shipping fees and are the buyer's responsibility. If you have any concerns about customs duties or specific requirements for your country, we recommend contacting your local customs office for further details before placing an order.</p>
            <h4>2. Order Tracking</h4>
            <p>Once your order has been dispatched, you will receive a confirmation email that includes a tracking number and a link to monitor your shipment. This allows you to track your artwork’s journey directly through our courier partner’s website or via the Art Smiley platform. If you encounter any issues while tracking your shipment or need further assistance, our dedicated support team is here to help. You can reach us at contactus@artsmiley.com to resolve tracking-related concerns efficiently.</p>
            <h4>3. Customs Duties and Taxes</h4>
            <p>For international orders, it is important to note that buyers are responsible for any applicable customs duties, taxes, or import fees charged by the destination country. These charges are not included in the purchase price and must be paid directly to the local authorities upon delivery. Art Smiley does not cover these additional costs, as customs policies vary widely across different countries. To ensure a smooth delivery process, please double-check that your recipient name and delivery address are accurate during checkout. If you have questions about customs processes in your location, we recommend consulting your local customs office. </p>
            <h4>4. Address Change</h4>
            <p>If you need to modify your delivery address after placing an order, please contact us as soon as possible at contactus@artsmiley.com. We can only accommodate address changes if the artwork has not yet been dispatched. Once an order is shipped, address changes cannot be made, as the courier service will already be in possession of the package. Therefore, it is essential to verify your address information at the time of placing the order to avoid any delays or delivery issues.</p>
            <h4>5. Packaging and Shipping Insurance Policy</h4>
            <p>To ensure that your artwork arrives in perfect condition, all shipments are professionally packed according to Art Smiley’s strict Packaging Guidelines. Each piece of artwork is securely protected with high-quality materials designed to withstand handling during transit. Additionally, all shipments are fully covered by shipping insurance. This policy protects against any damage or loss that may occur while your artwork is in transit. In the rare event of shipping-related damage or loss, Art Smiley will work with you to ensure that you receive a full refund or arrange for a replacement, based on availability. These are part of our comprehensive shipping terms for buyers, designed to provide a safe and seamless experience whether you are purchasing artwork locally or internationally.</p>
            <h4>6. Damaged Artwork Policy</h4>
            <p>While we take every precaution to pack your artwork securely, damage may occur during shipping in rare instances. If your artwork arrives damaged, it is important to report the issue to us within 48 hours of receiving the order. To initiate the claim process, please provide clear photographs of the damaged artwork, both internal and external packaging, and the shipping label. Send this information to contactus@artsmiley.com for prompt resolution. Once we verify the damage, we will arrange for a replacement or issue a full refund as applicable. If inadequate packaging materials were used during shipping or return, claims may not be honored. We emphasize reusing original packaging materials for any returns to minimize damage during transit. </p>
            <h4>7. Return Shipping Responsibility</h4>
            <p>If you wish to return an artwork, you must reuse the original packaging materials to ensure it is securely protected during transit. Proper packaging is critical to avoid damage during the return process. For standard returns, buyers are responsible for the cost of return shipping unless the artwork arrives as damaged. If you are unsure about the return process or need guidance on packaging, our support team is available to assist. At Art Smiley, we prioritize the safe delivery of art to buyers, ensuring that all pieces are carefully packaged and shipped to prevent any damage.</p>
            <h4>8. Shipping Restrictions</h4>
            <p>Art Smiley currently ships artworks across the globe. However, certain regions may have restrictions due to local regulations or limitations set by courier services. If we are unable to ship to your location, we will notify you during checkout or promptly after order processing. For any queries regarding shipping availability to your location, please reach out to our support team for clarification. Our buyer shipping policy for artwork ensures transparency and clear communication, so you are always informed about the shipping options available to your specific location. </p>
            <p>We are committed to providing you with a seamless experience, regardless of where you are located.</p>
            <h4>9. Order Status and Customer Support</h4>
            <p>At Art Smiley, we are committed to keeping you informed about your order status at every step. If you have questions regarding your shipment, address changes, customs duties, damaged artwork claims, or any other shipping-related concerns, please contact us directly. You can reach our team via email at contactus@artsmiley.com.</p>
            
            </div>
            </Container>
            </div>
          </div>
          
        ))}
      </div>
    </ThemeProvider>
  );
}

export default ShippingPolicy;
