import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AlertsSuccess, Errornotify } from "../../../../common/alert/Alert";
import {
  acceptRejectGallery,
  blockUnblockGallery,
  deleteGallery,
  fetchGalleryData,
  handleAddGallery,
  setRecommendedGallery,
} from "../../../../State/api/superadminApi";
import { setGallery } from "../../../../State/Features/superadmin/gallerySlice";
import Pagination from "../Pagination/Pagination";
import Tablesfivecol from "../Table/Fivecolumn/Tablefivecol";
import Moreoption from "../Table/Moreoption/Moreoption";
import Tables from "../Table/sixcolumn/Tables";
import Tableheader from "../Table/Tableheader/Tableheader";
import styles from "./Gallery.module.css";
import Add from "../Popupdelete/addPopup/Addpopup";

const moreOptions = [
  {
    id: "pending",
    label: "Pending",
    value: "gallery_pending",
  },
  {
    id: "approved",
    label: "Approved",
    value: "gallery_approved",
  },
  {
    id: "decline",
    label: "Decline",
    value: "gallery_decline",
  },
];
const exhibitionhead = [
  {
    id: "company_name ",
    label: "Company Name",
  },
  {
    id: "artist",
    label: "Artist",
  },
  {
    id: "email",
    label: "Mobile number",
  },
  {
    id: "assign_to",
    label: "Email",
  },
  {
    id: "type",
    label: "Assign to",
  },
  {
    id: "actiont",
    label: "Type",
  },
  {
    id: "action",
    label: "Action",
  },
];
const approvedhead = [
  {
    id: "company_name ",
    label: "Company Name",
  },
  {
    id: "artist",
    label: "Artist",
  },
  {
    id: "email",
    label: "Location",
  },
  {
    id: "assign_to",
    label: "Likes",
  },
  {
    id: "type",
    label: "Followers",
  },
];
const sortdata = [
  { label: "Oldest to Newest", value: "low" },
  { label: "Newest to Oldest", value: "high" },
];
const filterdata = [
  { label: "Name (A-Z)", value: "name-asc" },
  { label: "Name (Z-A)", value: "name-desc" },
];

export default function Gallery() {
  const [activetab, setAcitvetab] = useState("gallery_pending");
  const [button, setButton] = useState({ type: "add", text: "Add" });
  const [popup, setPopup] = useState(false);
  const [data, setData] = useState({
    offset: 1,
    is_accepted: "Pending",
  });
  const [condition, setCondition] = useState({
    search: "",
    filter: "",
    sorting: "",
  });
  const [newgallery, setNewGallery] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    password: "",
  });

  const dispatch = useDispatch();
  const gallerydata = useSelector((state) => state.galleryData.data);
  const handleMorecategories = (e) => {
    setAcitvetab(e);
  };

  console.log("gallerydata", gallerydata);
  console.log("condition", condition);
  useEffect(() => {}, []);
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setNewGallery({ ...newgallery, [name]: value });
  };
  const handleAddnew = () => {
    if (newgallery.phone.length !== 10) {
      Errornotify("Please Enter Valid Number");
    } else if (newgallery.password.length < 8) {
      Errornotify("Please fill password atleast 8 character");
    } else {
      dispatch(handleAddGallery(newgallery, setPopup, fetchData));
    }
  };
  const fetchData = () => {
    dispatch(fetchGalleryData(data));
  };
  useEffect(() => {
    fetchData();
  }, [data]);

  const handleAcceptDecline = (id, dt) => {
    dispatch(acceptRejectGallery(id, { is_accepted: dt }, fetchData));
  };
  const handleBlockUnblock = (id, dt) => {
    dispatch(blockUnblockGallery(id, { is_blocked: !dt }, fetchData));
  };
  const handleRecommended = (id, dt) => {
    dispatch(setRecommendedGallery(id, { is_recommended: !dt }, fetchData));
  };
  const handleDelete = (id) => {
    dispatch(deleteGallery(id, fetchData));
  };

  const handleFilter = (event) => {
    const value = event.target.value;
    setCondition({ ...condition, filter: value });

    if (value === "name-asc") {
      dispatch(
        setGallery({
          data:
            gallerydata.data &&
            [...gallerydata.data].sort((a, b) =>
              a.first_name.toLowerCase() > b.first_name.toLowerCase() ? 1 : -1
            ),
          totalCount: gallerydata.totalCount,
        })
      );
    } else if (value === "name-desc") {
      dispatch(
        setGallery({
          data:
            gallerydata.data &&
            [...gallerydata.data].sort((a, b) =>
              a.first_name.toLowerCase() < b.first_name.toLowerCase() ? 1 : -1
            ),
          totalCount: gallerydata.totalCount,
        })
      );
    } else {
      fetchData();
    }
  };

  const handleSearch = (e) => {
    let value = e.target.value;
    setCondition({ ...condition, search: value });
  };

  // const handleSorting = (e) => {
  //   let value = e.target.value
  //   if (value === "low") {
  //     setCondition({ ...condition, sorting: value })
  //     dispatch(
  //       setGallery({
  //         data:
  //           gallerydata.data &&
  //           [...gallerydata.data].sort((a, b) => {
  //             if (a.createdAt.toLowerCase() < b.createdAt.toLowerCase())
  //               return -1
  //           }),
  //         totalCount: gallerydata.totalCount,
  //       })
  //     )
  //   } else if (value === "high") {
  //     setCondition({ ...condition, sorting: value })
  //     dispatch(
  //       setGallery({
  //         data:
  //           gallerydata.data &&
  //           [...gallerydata.data].sort((a, b) => {
  //             if (a.createdAt.toLowerCase() > b.createdAt.toLowerCase())
  //               return -1
  //           }),
  //         totalCount: gallerydata.totalCount,
  //       })
  //     )
  //   } else {
  //     fetchData()
  //     setCondition({ ...condition, sorting: value })
  //   }
  // }

  const handleSorting = (e) => {
    const value = e.target.value;
    setCondition({ ...condition, sorting: value });

    if (value === "low") {
      dispatch(
        setGallery({
          data: gallerydata.data
            ? [...gallerydata.data].sort(
                (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
              )
            : [],
          totalCount: gallerydata.totalCount,
        })
      );
    } else if (value === "high") {
      dispatch(
        setGallery({
          data: gallerydata.data
            ? [...gallerydata.data].sort(
                (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
              )
            : [],
          totalCount: gallerydata.totalCount,
        })
      );
    } else {
      fetchData();
    }
  };



  return (
    <div className={styles.mains}>
      <AlertsSuccess />
      {popup ? (
        <Add
          handleChange={handleChange}
          poptype="curator"
          fetchData={fetchData}
          handleAddnew={handleAddnew}
          open={popup}
          setOpen={setPopup}
        />
      ) : (
        ""
      )}
      <div className={styles.components}>
        <Tableheader
          handleFilter={handleFilter}
          handleSorting={handleSorting}
          filterdata={filterdata}
          sortdata={sortdata}
          condition={condition}
          search={condition.search}
          handleSearch={handleSearch}
          // button={button}
          setOpen={setPopup}
        />
        <Moreoption
          activetab={activetab}
          moreOptions={moreOptions}
          handleMorecategories={handleMorecategories}
          setData={setData}
          data={data}
        />
        {activetab === "gallery_pending" ? (
          <Tables
            handleBlockUnblock={handleBlockUnblock}
            handleRecommended={handleRecommended}
            handleAcceptDecline={handleAcceptDecline}
            activetab="gallery_pending"
            head={exhibitionhead}
            data={
              gallerydata &&
              gallerydata.data.filter((val) => {
                const fullName =
                  `${val.first_name} ${val.last_name}`.toLowerCase();
                return (
                  val.is_accepted === "Pending" &&
                  fullName.includes(condition.search.toLowerCase())
                  // val.first_name +
                  //   " " +
                  //   val.last_name
                  //     .toLowerCase()
                  //     .includes(condition.search.toLowerCase())
                );
              })
            }
          />
        ) : activetab === "gallery_decline" ? (
          <Tablesfivecol
            handleDelete={handleDelete}
            activetab={activetab}
            head={approvedhead}
            data={
              gallerydata &&
              gallerydata.data.filter((val) => {
                const fullName =
                  `${val.first_name} ${val.last_name}`.toLowerCase();
                return (
                  val.is_accepted === "Rejected" &&
                  fullName.includes(condition.search.toLowerCase())
                  // val.first_name +
                  //   " " +
                  //   val.last_name
                  //     .toLowerCase()
                  //     .includes(condition.search.toLowerCase())
                );
              })
            }
          />
        ) : (
          <Tablesfivecol
          activetab={activetab}
          head={approvedhead}
          data={ 
            gallerydata?.data // Ensure gallerydata exists
              ?.filter((val) => {
                const fullName = `${val.first_name || ""} ${val.last_name || ""}`.toLowerCase();
                const searchMatch = fullName.includes(condition.search.toLowerCase());
                return val.is_accepted === "Approved" && searchMatch;
              })             
              ?.sort((a, b) => {
                if (condition.filter === "name-asc") {
                  const result = a.first_name.localeCompare(b.first_name, undefined, {
                    sensitivity: "base",
                  });
                  return result;
                } else if (condition.filter === "name-desc") {
                  const result = b.first_name.localeCompare(a.first_name, undefined, {
                    sensitivity: "base",
                  });                  
                  return result;
                }
                return 0; // Default order if no sorting condition
              })
          }
        />
        )}
        <Pagination
          totaldata={gallerydata && gallerydata.totalCount}
          pagination={data}
          setPagination={setData}
        />
      </div>
    </div>
  );
}
