import {Grid, Link, Container } from "@mui/material";
import React, {useRef} from 'react';
import { useNavigate } from "react-router-dom"
import styles from "./TradeProgramApplication.module.css";
import { RiArrowRightLine, RiArrowLeftLine  } from "react-icons/ri";
import CssBaseline from "@mui/material/CssBaseline";
import { RiCheckFill } from "react-icons/ri";
import PhoneInput from "react-phone-input-2";
import capitalizeWords from "../../../../utils/capitalizeContent";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import striptags from "striptags";
function ArtInteriorDesigners() {
  const navigate = useNavigate()
  
    const handleNavigate = (route) => {
      navigate(route)
    }
    const prevButtonRef = useRef(null);
      const nextButtonRef = useRef(null);

    const scrollToSection = () => {
      const section = document.getElementById('target-section');
      if (section) {
        // Calculate offset to account for the fixed header
        const headerOffset = 100; // Height of your fixed header
        const sectionPosition = section.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = sectionPosition - headerOffset;
  
        // Scroll to the calculated position
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      }
    };

  const featurebeni = [
    {
      content: <p>Access to a curated selection of original artworks for residential and commercial projects.</p>,
    },
    {
      content: <p>Member exclusive benefits and special 10% discount on all orders above 1500 USD.</p>,
    },
    {
      content: <p>Personalized service to assist you in selecting the ideal pieces for your design needs.</p>,
    },
    {
      content:<p>Streamlined process for acquiring artwork with dedicated support from our team.</p>,
    },
    {
      content:<p>Priority access to new collections and artwork releases.</p>
    },
  ];
  const exclusive_data = [
    {
      id: 1,
      src: "/img/artcollections",
      heading: "Rental Solutions",
      des: "Art Smiley provides flexible art rental solutions for businesses, event organizers, and property developers. Our extensive portfolio of artworks is available for short-term and long-term rentals, allowing you to refresh and enhance your spaces with dynamic, high-quality art. Our rental service ensures your environment remains visually engaging and aligned with your current needs, whether for corporate offices, real estate staging, or events, with options for regular rotation to keep the artwork fresh and inspiring.",
      link: '/user/art-rentals',
      btname: 'Get Started Now'
    },
    {
      id: 2,
      src: "/img/artcollections",
      heading: "Commissioned Artworks",
      des: "Art Smiley specializes in commissioned pieces tailored to your specific vision and space. We collaborate with a diverse network of talented artists to create bespoke art that perfectly complements your style. Our commissioning process ensures that the final piece aligns with your artistic goals, creating a distinctive addition to any space that reflects your individual or brand identity. ",
      link: '/user/commisioned-art',
      btname: 'Check out Artworks '
    },
    {
      id: 3,
      src: "/img/artcollections",
      heading: "Customized Framing",
      des: "Our customized framing services enhance and protect your artwork, ensuring it is beautifully displayed and preserved. We offer various frame types such as metal, wooden, and plastic with different styles to suit your artwork and interior design preferences. With fast, efficient service across the UAE, we provide expertly crafted frames that meet the highest quality standards. We offer an ideal solution for residential and commercial clients looking to complement their artwork with professional framing within the UAE.",
    },
    {
      id: 4,
      src: "/img/artcollections",
      heading: "Shipping and Installation Services",
      des: "Art Smiley offers professional shipping service worldwide and installation services throughout the UAE. We handle the safe transport of your artwork, ensuring it arrives in perfect condition, and provide expert installation services to guarantee optimal display. Our team manages every detail from packaging to placement, ensuring a hassle-free process for clients seeking seamless art consultation and installation service.",
    },
    {
      id: 5,
      src: "/img/artcollections",
      heading: "On-Time Project Execution",
      des: "At Art Smiley, we understand the importance of meeting deadlines. We are committed to delivering projects within agreed-upon timelines. Our efficient project management process ensures that every aspect of the art consultation, selection, and installation is completed on schedule without compromising the quality or integrity of the artwork. During the progress of the project, we communicate the project development process with our clients every week, which helps them to keep track of their order.  Our team works closely with clients to ensure each project is executed smoothly and on time.",
    },
    {
      id: 6,
      src: "/img/artcollections",
      heading: "Art Smiley Trade Program",
      des: "The Art Smiley Trade Program is designed for interior designers, architects, art consultants, and other industry professionals looking to enhance their projects with high-quality, original artwork. By joining this exclusive program, you gain access to expertly curated collections and enjoy personalized support, ensuring your design projects are elevated with the perfect pieces of art.",
    },
  ];

  const testimoniald = [
    {
      id:1,
      src: "/img/event3",
      name: "Noah T.",
      designation: "Design Consultant",
      des: "Art Smiley helped elevate the common areas and model units in a recent multi-family development project. The contemporary sculptures and custom prints brought a sophisticated yet welcoming vibe that tenants absolutely love. Their team’s dedication to delivering on time and within budget was exceptional.",
    },
    {
      id:2,
      src: "/img/event3",
      name: "Liam R.",
      designation: "Interior Designer",
      des: "Collaborating with Art Smiley has been an absolute pleasure. Their ability to curate artwork that aligns with the ambiance of each hospitality project is remarkable. From luxurious hotel lobbies to cozy guest rooms, they’ve delivered stunning pieces that create an unforgettable experience for guests. The seamless service from design to installation is unmatched.",
    },
    {
      id:3,
      src: "/img/event3",
      name: "Emma W.",
      designation: "Commercial Space Designer",
      des: "Art Smiley provided tailored art solutions that perfectly matched my vision for a high-end office space. Their team ensured that each piece reflected the brand's identity and culture. The attention to detail, quality, and professionalism made them my go-to partner for all future projects.",
    },
    {
      id:4,
      src: "/img/event3",
      name: "Ava K.",
      designation: "Design Specialist",
      des: "Finding art that balances aesthetics and a calming atmosphere for healthcare spaces can be challenging, but Art Smiley made it so easy. They curated artwork that fosters a sense of peace and comfort, perfectly suited for clinics and waiting areas. Their commitment to quality and client satisfaction shines through in every piece.",
    }
  ];
  const ourArtistCard = [
    {
      id: 1,
      imgUrl: "/img/ourartist1.png",
      artistName: "Maria Cooper",
      position: "Interior Designer",
      des:"Our curators can assist you in uncovering art that you cherish."
    },
    {
      id: 2,
      imgUrl: "/img/ourartist2.png",
      artistName: "Titus Archibald",
      position:"Interior Designer, Lifestylist",
      des:"Our curators can assist you in uncovering art that you cherish."
    },
    {
      id: 3,
      imgUrl: "/img/ourartist3.png",
      artistName: "Winslow Homer",
      position: "Designer, Mississippi Native",
      des:"Our curators can assist you in uncovering art that you cherish."
    },
  ];
  
  return (
    <React.Fragment>
      <CssBaseline />
      <section className={styles.hero_main}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
        <div className={`${styles.pageitle} ${styles.max_width}`}>
              <h1>Innovative Art Choices for Interior Designers</h1>
              <Link 
              onClick={(e) => {
                e.preventDefault(); // Prevent default link behavior
                scrollToSection(); // Call scroll function
              }}
              className={styles.apply_btnwht}
            >
              Reach out to us
            </Link>
            </div>
</Container>
        </section>
        <section className={styles.sec_sc}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
        
        <Grid container spacing={2} sx={{justifyContent:"space-between", alignItems:"center"}}>
        <Grid item xs={12} sm={6} md={5}>
        <div className={styles.leftText}>
              <h4 className={styles.abttitle}>Explore the expansive online collection of original art to discover the ideal pieces for your clients.</h4>
              <p className={styles.prtra}>Exceptional interior design has the power to elevate the atmosphere of any environment, and you understand that the right artwork can truly enhance the experience.</p>
              <p className={styles.prtra}>Art Smiley is here to assist you in finding artworks that align with your client’s tastes and aspirations, ensuring they enjoy stunning pieces in their spaces.</p>
                <Link onClick={() => handleNavigate('/user/search')} className={styles.apply_btn}>Explore Art Collections</Link>
              </div>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
         
              <div className={styles.vintageframeDiv}>
                <img
                  className="img-fluid"
                  src={ "/img/vintage-wood-frame.png"} alt=""
                />
              </div>
        </Grid>
        </Grid>
        </Container>
      </section>
      <section className={styles.sec_shgn}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
        <div className={styles.taldartist}>
            <Grid container spacing={2}>
            {exclusive_data.map((val) => (
            <Grid item xs={12} sm={6} md={6} key={val.id}>
              <div className={styles.talhg}>
                <div className={styles.talgbx}>
                <div className={styles.talenimg}>
                    <img
                    alt="img"
                    src={`${val.src}.png`}
                    className="img-fluid"
                  />
                    </div>
                    <div className={styles.talentcont}>
                      <h4>{val.heading}</h4>
                      <p>{val.des}</p>
                      
                    </div>
                    {val.link && (
              <button
                className={`${styles.mt_2} ${styles.apply_btn}`}
                onClick={() => window.location.href = val.link}
              >
                {val.btname}
              </button>
            )}
                </div>
              </div> 
            </Grid>
            ))}
            </Grid>
              
            </div>
        </Container>
      </section>
      <section className={styles.sec_sc}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
        <div className={`${styles.text_center} ${styles.max_width}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={`${styles.sec_title}`}>Benefits of Joining the Art Smiley Trade Program</h3>
          </div>
      {/* Tab Content */}
      <div className={styles.agtd}>
      <Grid container spacing={5} sx={{justifyContent:"space-between",alignItems:"center"}}>
        <Grid item xs={12} sm={6} md={6}>
        <div>
              <img src={"/img/Mockup.png"} alt="mockup" className="img-fluid" />
              </div>
        </Grid>
        <Grid item xs={12} sm={6} md={5}>
        {featurebeni.map((val) => (
                <div  className={styles.feabox}>
                <div className={styles.feabc}>
                  <div className={styles.iconst}>
                  <RiCheckFill className={styles.usicon} />
                  </div>
                  <div>{val.content}
                  </div>
                </div>
                </div>
                  ))}
                   <Link href="/user/art-consultancy/trade-program-application"
                  className={`${styles.mt_2} ${styles.apply_btn}`}
                > Join Now
                </Link> 
          
        </Grid>
        </Grid>
      </div>
      
        </Container>
      </section>
      <section className={styles.sec_sc}>
            <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
            <div className={`${styles.text_center} ${styles.max_width}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>Testimonials</h3>
          </div>
          <section className={`testmo-slider ${styles.sections}`}>
          <Swiper
        modules={[Navigation, Autoplay]}
                slidesPerView={1}
                spaceBetween={10}
                loop={true}
                autoplay={{
                  delay: 3000, // Set autoplay delay in milliseconds (3 seconds here)
                  disableOnInteraction: false, // Allows autoplay to continue after user interactions
                }}
                onInit={(swiper) => {
                  swiper.params.navigation.prevEl = prevButtonRef.current;
                  swiper.params.navigation.nextEl = nextButtonRef.current;
                  swiper.navigation.init();
                  swiper.navigation.update();
                }}
        breakpoints={{
          480: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 15,
          },
          1024: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
        }}
      >
{testimoniald.map((val, index) => (
                    <SwiperSlide key={index}>
                      <div className={styles.tehgt}>
                       <div className={styles.testmibox}>
                      <div className={styles.tecont}>
                        <div className={styles.hders}>
                        <div className={styles.testimonial_img_div}>
                          <img
                            src={`${val.src}.png`}
                            width="80px"
                            height="80px"
                            alt=""
                          />
                        </div>
                        <div className={styles.test_metainfo}>
                        <h5 className={styles.test_card_heading}>
                            {capitalizeWords(val.name)}
                          </h5>
                          <h6 className={styles.test_card_subhead}>
                            {capitalizeWords(val.designation)}
                          </h6>
                        </div>
                        </div>
                        <div className={styles.test_cont}>
                          <p className={styles.test_card_des}>{striptags(capitalizeWords(val.des))}</p>
                        </div>
                        </div>
                      </div>
                      </div>
                    </SwiperSlide>
                 ))}
              </Swiper>
              <div className="tesarrow">
          <div ref={prevButtonRef} className="swiper-button-prev">
                    <RiArrowLeftLine />
                  </div>
                  <div ref={nextButtonRef} className="swiper-button-next">
                    <RiArrowRightLine />
                  </div>
      </div>
        </section>
          
        </Container>
        </section>
        <section className={styles.sec_shgn}>
          <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
          <div className={`${styles.text_center} ${styles.max_width}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>Our Valued Clients</h3>
          </div>
          </Container>
        </section>
        <section className={styles.arconsec}>
          <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
            <Grid container spacing={2} sx={{alignItems:"center"}}>
              <Grid item xs={12} md={8}>
                <h3>Are You Looking for Personalized Art Consultancy services?</h3>
              </Grid>
              <Grid item xs={12} md={4}>
                <div className={styles.text_center}>
                <Link  onClick={() => handleNavigate('/user/art-consultancy/questionnaire')} className={styles.apply_btnwht}>Submit the Art Requirements</Link>
                </div>
                
              </Grid>
            </Grid>
          </Container>
        </section>
        <section className={styles.sec_shgn}>
          <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
          <div className={`${styles.text_center} ${styles.max_width}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>Meet Art Smiley Curators</h3>
          </div>
          <div>
          <Swiper
        modules={[Navigation, Autoplay]}
        slidesPerView={1}
        spaceBetween={10}
        loop={true}
        autoplay={{
          delay: 3000, // Set autoplay delay in milliseconds (3 seconds here)
          disableOnInteraction: false, // Allows autoplay to continue after user interactions
        }}
        navigation={{
          prevEl: prevButtonRef.current,
          nextEl: nextButtonRef.current,
        }}
        breakpoints={{
          480: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 15,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
        }}
      >
        {ourArtistCard.map((item) => (
        <SwiperSlide>
          <div className={styles.ourartistcard}>
          <img className={styles.ourartistimg} src={  item.imgUrl} alt=""/>
          <div className={styles.ourartistinfo}>
            <h4>{item.artistName}</h4>
            <p className={styles.tdr}>{item.position}</p>
            <p>{item.des}</p>
          </div>
        </div>
        </SwiperSlide>
        ))}
      </Swiper>
      {/* <div
        ref={prevButtonRef}
        className="swiper-button-prev"
      />
      <div
        ref={nextButtonRef}
        className="swiper-button-next"
      />  */}
          </div>
          </Container>
        </section>
    <div className={styles.aboutus}>
      
     
      
      <section className={styles.sec_sc} id="target-section">
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
        <div className={`${styles.text_center} ${styles.max_width}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>Reach out to us</h3>
          </div>
        <Grid container spacing={2} sx={{justifyContent:"center"}}>
        <Grid item xs={12} sm={10} md={8}>
        <div className={styles.stcgbox}>
                  <Grid container spacing={2} sx={{justifyContent:"center"}}>
                    <Grid item xs={12} sm={12} md={12}>
                                      <div className="form-group1">
                                    <label style={{marginBottom:"0"}}>Fill in the required fields below</label>
                                    </div>
                                    </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group1">
                <label>First Name *</label>
                <input
                  id="outlined-basic"
                  variant="outlined"
                  placeholder=""
                  className="form-control"
                  name="first_name"
                />
                
                </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group1">
                <label>Last Name *</label>
                <input
                  id="outlined-basic"
                  variant="outlined"
                  placeholder=""
                  className="form-control"
                  name="last_name"
                />
                
                </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group1">
                <label>Phone *</label>
                <PhoneInput
                  inputStyle={{ background: "transparent" }}
                  containerStyle={{
                    background: "transparent",
                    width: "100%",
                  }}
                  placeholder="Enter your phone number"
                  country={"in"}
                  name="phone"
                
                />
                </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group1">
                <label>Email *</label>
                <input
                  id="outlined-basic"
                  variant="outlined"
                  placeholder=""
                  className="form-control"

                  name="email"
                />
                </div>
                  </Grid>
                  
                  <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group1">
                <label>Designation *</label>
                <input
                  id="outlined-basic"
                  variant="outlined"
                  placeholder=""
                  className="form-control"
                  name="designation"
                />
                
                </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group1">
                <label>Please specify the project category *</label>
                <select className="form-control">
                  <option>Select the Option</option>
                  <option>Commercial</option>
                  <option>Retail</option>
                  <option>Education</option>
                  <option>Healthcare</option>
                  <option>Hospitality</option>
                  <option>Individual Collector</option>
                  <option>Private Home</option>
                  <option>Residential</option>
                  <option>Other</option>
                </select>
                 
                
                </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                  <div className="form-group1">
                <label>Can you tell us more details about your project requirements ( no. of works needed, style, timeline etc.,) *</label>
                <textarea
                  maxRows={5}
                  minRows={5}
                 className="form-control"
                  // onChange={props.handleChangedata}
                  // value={props.uploadArt.description}
                  name="message"
                  fullWidth
                  placeholder=""
                />
                </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                                      <div className="form-group1">
                                    <label style={{marginBottom:"0",marginTop:"15px"}}>Choose the project location</label>
                                    </div>
                                    </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group1">
                <label>City *</label>
                <input
                  id="outlined-basic"
                  variant="outlined"
                  placeholder=""
                  className="form-control"
                  name=""
                />
                
                </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group1">
                <label>Country *</label>
                <input
                  id="outlined-basic"
                  variant="outlined"
                  placeholder=""
                  className="form-control"
                  name=""
                />
                
                </div>
                  </Grid>
                  
                  
                  <Grid item xs={12} sm={12} md={12}>
                  <div className={styles.text_right}>
               <button
                className={styles.apply_btn}
              >
                Submit
              </button>
               </div>
               <div>
                <p className={styles.sbychsbu}>By completing this form, you consent to receive promotional emails regarding the Art Smiley Trade Program. You may opt out at any time. <Link href="/user/privacypolicy" target="_blank">Privacy Policy</Link> <Link href="/user/termsconditions" target="_blank">Terms and Conditions</Link>.</p>
               </div>
                  </Grid>
                  </Grid>
</div>
          </Grid>
        </Grid>
        
        </Container>
      </section>
    </div>
    </React.Fragment>
  );
}

export default ArtInteriorDesigners;
