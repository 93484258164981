import React from "react";
import { Modal, Backdrop, Fade, Box, Button } from "@mui/material";
import styles from "./DeleteArt.module.css";
import { useNavigate } from "react-router-dom";
import { setEditArtId } from "../../../../State/Features/common/editArtIdSlice";
import { useDispatch } from "react-redux";

export default function PopupForDeleteArt(props) {
  const navigate = useNavigate();
  const handleClose = () => props.setOpen(false);
const dispatch = useDispatch()
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={props.open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      className={styles.modal}
    >
      <Fade in={props.open}>
        <Box className={styles.modalContent}>
          <div className={styles.cancel_img}>
            <img
              onClick={() => props.setOpen(false)}
              src={"/img/cancel_cross.png"}
              height="20px"
              width="20px"
            />
          </div>
          <h2 className={styles.modalHeader}>Manage Your Artwork</h2>
          <div className={styles.description}>
            <div className={styles.buttonGroup}>
              <Button
                onClick={() =>
               {
                navigate("/edit/stepfirst", { state: { id: props.artId } })
                  
                  dispatch(setEditArtId({id: props.artId}))
               }

 
                }
                variant="contained"
                sx={{
                  background: "black",
                  color: "white",
                  "&:hover": { background: "black", color: "white" },
                }}
             
              >
                EDIT ARTWORK
              </Button>
              <Button
                sx={{
                  border: "none",
                  textDecoration: "underline",
                  color: "black",
                }}
                onClick={handleClose}
              >
                CANCEL
              </Button>
            </div>
            <p className={styles.warningText}>
              Wait! Are you sure you want to delete this artwork?
            </p>
            <p>
              We have noticed that deleting art on Artsmiley Art creates a lot
              of frustration:
            </p>
            <ul>
              <li>
                Collectors are not able to find what they are looking for when
                they click on links found online.
              </li>
              <li>
                Artists miss the opportunity of sales because people don't find
                their art on Artsmiley Art.
              </li>
            </ul>
            <p>By editing your artwork instead you can:</p>
            <ul>
              <li>Upload a different image</li>
              <li>Change the name</li>
              <li>Change the price</li>
              <li>Start/Stop making this original work available for sale</li>
              <li>Start/Stop offering prints of this</li>
            </ul>
          </div>
          <div className={styles.buttonGroup}>
            <Button
              variant="contained"
              sx={{
                background: "black",
                color: "white",
                "&:hover": { background: "black", color: "white" },
              }}
              onClick={() => {
                props.handleDelete(props.artId);
                props.setOpen(false);
              }}
            >
              DELETE MY ARTWORK ANYWAY
            </Button>
            <Button
              sx={{
                border: "none",
                textDecoration: "underline",
                color: "black",
              }}
              onClick={handleClose}
            >
              CANCEL
            </Button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
