import { MenuItem, Select, Link, Grid } from "@mui/material"
import { Box } from "@mui/system"
import React, { useEffect, useState } from "react"
import styles from "./CommonArtdetail.module.css"
import { useParams, useNavigate } from "react-router-dom"
import homeImage from "../../../../../constant"
import serverimage from "../../../../../serverConstant"
import { IoIosArrowBack } from "react-icons/io";

const data = {
  description: [
    {
      title: "Title",
      content: "Art of Space",
    },
    {
      title: "Copyright",
      content: "yes",
    },
    {
      title: "Category",
      content: "Oil Painting",
    },
    {
      title: "Subject",
      content: "Lorem this is Subject",
    },
    {
      title: "Year",
      content: "2022",
    },
    {
      title: "Material",
      content: "Canvas",
    },
    {
      title: "Styles",
      content: "Art",
    },
    {
      title: "Dimenstions",
      content: "Cm H25 X  W215 X D14",
    },
    {
      title: "Keywords",
      content: "Art, Painting, Space",
    },
    {
      title: "Description",
      content:
        "ver since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently wi",
    },
    {
      title: "Exibition 3",
      content: "Art of Space",
    },
    {
      title: "Subscription Plan",
      content: "Premium",
    },
    {
      title: "Exxibition 2",
      content: "Art of space",
    },
    {
      title: "Biography",
      content:
        "k. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and m",
    },
  ],
  detailPrice: {
    heading: "Price &  Detail",
    content: [
      {
        details_subheading: "Sale",
        detailsubcontent: [
          {
            title: "Availablity",
            content: "sale",
          },
          {
            title: "Price",
            content: "$250,25",
          },
        ],
      },
      {
        details_subheading: "Rent",
        detailsubcontent: [
          {
            title: "Availablity",
            content: "Rent",
          },
          {
            title: "Price",
            content: "$250,25",
          },
          {
            title: "Time",
            content: "1 Month",
          },
        ],
      },
    ],
  },
};

export default function CommonArtdetail(props) {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/art")
  }
  const [selectedPrints, setSelectedPrints] = useState({});
  useEffect(() => {
    if (Array.isArray(props?.data) && props?.data.length > 0) {
      const defaultPrints = props.data.reduce((acc, item) => {
        if (item && item._id && Array.isArray(item.print) && item.print.length > 0) {
          acc[item._id] = item.print[0]; // Set the first print as the default
        } else {
          acc[item._id] = {}; // Fallback to an empty object if no prints are available
        }
        return acc;
      }, {});
      
      setSelectedPrints(defaultPrints); // Set the default selected prints
    }
  }, [props?.data]); // Re-run when props.data changes
  const handleChange = (itemId, event) => {
    const selectedSize = props?.data
      .find((item) => item._id === itemId)
      .print.find((print) => print._id === event.target.value);

    setSelectedPrints((prev) => ({
      ...prev,
      [itemId]: selectedSize, // Update the selected print for the item
    }));
  };
  return (
    <>
    <div className={styles.compnat}>
    <div className={styles.back_button}>
    <Link onClick={handleBack} className={styles.backbn}> <IoIosArrowBack /> <span> Go Back</span></Link>
      
      </div>
      {props.data &&
        props.data.map((val) => (
          <>
          <div className={styles.bsrd}>
          <Grid  container spacing={2}>
           <Grid item xs={12} sm={5} md={4}>
           <div className={styles.previewimg_img_div}>
              <img src={val.image} className="img-fluid" alt="img" />
            </div>
           </Grid>
          </Grid>
          </div>
            <div
              className={
                props.page === "superadmin"
                  ? styles.details_main
                  : styles.details_main_curator
              }
            >
              <div className={styles.bdngr}>
              <h3 className={styles.sethng}>Description</h3>
                <p className={styles.odlist}><span>Title :</span> <span>{val.name}</span></p>
                <p className={styles.odlist}><span>Copyright :</span> <span>{val.copyright}</span></p>
                <p className={styles.odlist}><span>Category :</span> <span>{val.category}</span></p>
                <p className={styles.odlist}><span>Subject :</span> <span>{val.subject && val.subject.map((val) => val.name)}</span></p>
                <p className={styles.odlist}><span>Year :</span> <span>{val.year}</span></p>
                <p className={styles.odlist}><span>Material :</span> <span>{val.material.map((v) => v.name)}</span></p>
                <p className={styles.odlist}><span>Style :</span> <span> {val.style && val.style.map((v) => v.name)}</span></p>
                <p className={styles.odlist}><span>Dimensions :</span> <span>{val.height} * {val.width} * {val.depth}</span></p>
                <p className={styles.odlist}><span>Keywords :</span> <span>{val.keywords}</span></p>
                <p className={styles.odlist}><span>Orientation :</span> <span>{val.orientation.map((item, i) => item.name)}</span></p>
                <p className={styles.odlist}><span>Ready to hang :</span> <span> {val.is_hanged}</span></p>
                <p className={styles.odlist}><span>Signed :</span> <span> {val.is_signed}</span></p>
                <p className={styles.odlist}><span>Description :</span> <span>{val.description}</span></p>
                {val.exhibitions &&
                  val.exhibitions.map((v, i) => (
                    <>
                    <p className={styles.odlist}><span>Exhibition {i + 1} :</span> <span> {v.name}</span></p>
                        
                    </>
                  ))}
                
                
              </div>
                 
      
               
              {/* <div className={styles.description_details}>
                <div className={styles.description_details_title}>
                  Subscription :
                </div>
                <div className={styles.description_details_content}>
                  {val.artist_subscription &&
                    val.artist_subscription?.map((val, i) => {
                      return (
                        <div style={{ border: "1px solid #ccc", borderRadius: "8px", padding: "16px", margin: "8px 0", backgroundColor: "#f9f9f9" }}>
                      
                        <div style={{ display: "flex", flexWrap: "wrap", gap: "16px" }}>
                          <span style={{ flex: "1 1 100%", marginBottom: "4px" }}>
                            <b>Package :</b> {val.name}
                          </span>
                          <span style={{ flex: "1 1 100%", marginBottom: "4px" }}>
                            <b>Purchase Date:</b> {moment(val?.purchase_date)?.format("DD-MM-YYYY")}
                          </span>
                          <span style={{ flex: "1 1 100%", marginBottom: "4px" }}>
                            <b>Expired Date:</b> {moment(val?.expiry_date)?.format("DD-MM-YYYY")}
                          </span>
                          <span style={{ flex: "1 1 100%", marginBottom: "4px" }}>
                            <b>Amount:</b> {val?.amount}
                          </span>
                          <span style={{ flex: "1 1 100%" }}>
                            <b>Duration:</b> {val?.subscription_tenure}
                          </span>
                        </div>
                      </div>
                      );
                    })}
                </div>
              </div> */}
              <div className={styles.bdngr}>
              <h3 className={styles.sethng}>{data.detailPrice.heading}</h3>
              <p className={styles.details_subheading}>Sale</p>
              <p className={styles.odlist}><span>Availablity :</span> <span>{val.sale ? "Sale" : "Not Available"}</span></p>
              <p className={styles.odlist}><span>Price :</span> <span>${val.sale ? val.sale.price : "----"}</span></p>
             
              </div>
              <div className={styles.bdngr}>
              <p className={styles.details_subheading}>Rent</p>
              <p className={styles.odlist}><span>Availablity :</span> <span>{val.rent ? "Rent" : "Not Available"}</span></p>
              <p className={styles.odlist}><span>Price :</span> <span>{val?.rent?.rent_price
                        ? `$${val.rent.rent_price}`
                        : "------"}</span></p>
              <p className={styles.odlist}><span>Time :</span> <span>{val?.rent ? val?.rent?.time : "------"}</span></p>
              </div>
              <div className={styles.bdngr}>
              <p className={styles.details_subheading}>Print</p>
              <p className={styles.odlist}><span>Availablity :</span> <span>{val.print.length === 0 ? "Not Available" : "Print"}</span></p>
              <p className={styles.odlist}><span>Size and Price :</span> <span>{val.print.length > 0 ? (
  <>
    <Select
      labelId={`select-size-label-${val._id}`}
      value={selectedPrints[val._id]?._id || ""} // Ensure default value is set
      onChange={(event) => handleChange(val._id, event)}
    >
      {val?.print?.map((vl) => (
        <MenuItem key={vl._id} value={vl._id}>
          {vl.size.height} * {vl.size.width}
        </MenuItem>
      ))}
    </Select>

    <div
      style={{
        display: "flex",
        gap: 6,
        alignItems: "center",
        padding: "1px 0px 0px 0px",
      }}
    >
      <label className={styles.title}>Selected Size: {" "}</label>
      <div className={styles.description_details_content}>
        {" "}
        {selectedPrints[val._id]?.size?.height || "-"} {" "} (w) *{" "}
        {selectedPrints[val._id]?.size?.width || "-"} {" "} (h)
        <br />
      </div>
    </div>

    <div
      style={{
        display: "flex",
        gap: 6,
        alignItems: "center",
        padding: "1px 0px 0px 0px",
      }}
    >
      <label className={styles.title}>Price:</label>
      <div className={styles.description_details_content}>
        ${selectedPrints[val._id]?.print_price || "------"}
        <br />
      </div>
    </div>
  </>
) : (
  <div>----</div>
)}</span></p>
              </div>
            </div>
          </>
        ))}
    </div>
    </>
  );
}
