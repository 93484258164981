import { ThemeProvider } from "@emotion/react"; 
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography,
  Container,
  Link,
  Grid
} from "@mui/material";
import { createTheme } from "@mui/system";
import React, { useEffect, useState, useLocation } from "react";
import styles from "./FAQ.module.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchFaq } from "../../../State/api/userApi";
import CircularLoader from "../../../common/loader/Loader";
import { setLoader } from "../../../State/Features/common/loaderSlice";

const theme = createTheme({
  typography: {
    h4: {
      fontFamily: "Marcellus",
      fontSize: "2vw",
    },
    h6: {
      fontFamily: "Marcellus",
      fontSize: "1vw",
    },
  },
});

const divData = [
  { id: 1, label: "FOR ARTIST", value: "for__artist" },
  { id: 2, label: "FOR CURATOR", value: "for__curator" },
  { id: 3, label: "FOR AFFILIATE", value: "for__affiliate" },
  { id: 4, label: "FOR BUYER", value: "for__buyer" },
  { id: 5, label: "FOR GALLERIES", value: "for__galleries" },
];

function FAQ() {
  const [divLinks, setDivLinks] = useState("for__buyer");
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.loader);
  const userFaqData = useSelector((state) => state.userFaqData);
  const FAQ_data = userFaqData?.data?.responses;

  useEffect(() => {
    dispatch(fetchFaq());
  }, [dispatch]);

  const handleClick = (val) => {
    setDivLinks(val);
  };

  const renderFAQContent = (userTypeId) => {
    return (
      FAQ_data &&
      FAQ_data
        .filter((val) => val.user_type_id === userTypeId)
        .map((item, i) => (
          <Accordion key={i}
          sx={{
            "& .MuiAccordionSummary-contentGutters": {
              margin: "10px 0", // Adjust as needed
            },
            "&.MuiPaper-root": {
              boxShadow: "none",
              borderBottom: "1px solid #D9DBE9",
              marginBottom:"0",
              borderBottomLeftRadius:"0",
              borderBottomRightRadius:"0",
            },
            "&.MuiAccordion-root::before":{
              display:"none",
            },
            "& .Mui-expanded": {
              minHeight:"auto",
              margin:"0 0 10px 0 !important"
            },
            "& .MuiAccordionSummary-expandIconWrapper":{
              background:"#B7B7B7",
              opacity:".3",
              textAlign: "center",
              borderRadius:"8px",
            },
            "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded":{
              background:"#1C1C1C",
              opacity:"1",
              color:"#fff",
            },
            "& .MuiAccordionDetails-root":{
              paddingTop:"0"
            },
            
          }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${i}-content`}
              id={`panel${i}-header`}
            >
              <div className={styles.quesDiv}>
                <div className={styles.idDiv}>{i + 1}</div>
                <h4 className={styles.benfhdgn}>{item.question}</h4>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <p className={styles.bendesc} dangerouslySetInnerHTML={{ __html: item.answer || "" }}></p>
              <Typography
                fontSize="0.8rem"
                
              ></Typography>
            </AccordionDetails>
          </Accordion>
          
          
        ))
    );
  };

  const userTypeMapping = {
    for__artist: "633282ce3777d5d6559bd24e",
    for__curator: "633282d73777d5d6559bd251",
    for__affiliate: "633282eb3777d5d6559bd257",
    for__buyer: "633282e33777d5d6559bd254",
    for__galleries: "663c6e47f02e16a2957a1e46",
  };

  const navigate = useNavigate()
  const handleNavigate = (route) => {
    navigate(route)
  }
  

  return (
    <div className={styles.faq}>
      <div className={styles.headerCategory}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
          <div className={styles.pageitle}>
            <h1>FAQ</h1>
            <ul>
              <li>
                <Link onClick={() => navigate("/")}>Home / </Link>
              </li>
              <li>Your questions answered</li>
            </ul>
          </div>
        </Container>
      </div>
      <div className={styles.faqlist}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
          <Grid container spacing={2}>
            <Grid item md={3} xs={12}>
              <div className={styles.faq_category}>
                {divData.map((val, i) => (
                  <Button
                    key={i}
                    value={divLinks}
                    onClick={() => handleClick(val.value)}
                    variant={divLinks === val.value ? "contained" : ""}
                    fullWidth
                    sx={{
                      ml: 1,
                      display: "flex",
                      justifyContent: "flex-start",
                      mr: 0,
                      pr: 0,
                      borderRadius: " 2.06016px 0px 0px 2.06016px",
                    }}
                  >
                    {val.label}
                  </Button>
                ))}
              </div>
            </Grid>
            <Grid item md={9} xs={12}>
            <div className={styles.astbgc}  >
            {loader.loader ? (
                <Box className={styles.loader_box}>
                  <CircularLoader size={60} />
                </Box>
              ) : (
                
                renderFAQContent(userTypeMapping[divLinks])
              )}
              </div>
             
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
}

export default FAQ;
