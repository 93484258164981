import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
// import Divider from '@mui/material/Divider';
import React from "react";
import styles from "./FivecolimgArtist.module.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import homeImage from "../../../constant";
import serverimage from "../../../serverConstant";
import CircularLoader from "../../../common/loader/Loader";
// import profileSlice, { addData } from '../../../../../State/Features/Artist/profileSlice';

export default function FivecolimgArtist(props) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);

  // const dispatch = useDispatch();
  // const data = useSelector((state)=>state.artistdata);
  
  
  const threedots = Boolean(anchorEl);
  const [threedot_id, setThreedot_id] = React.useState("");
  const loader = useSelector((state) => state.loader);
  const handleClick = (event,id) => {
    props.setArtId(id)
    setAnchorEl(event.currentTarget);
    setThreedot_id(event.target.id);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handlePopup = (e) => {
    props.setOpen(true);
    props.setPoptype("addfeature");
  };
  const handleProfilepage = (id, name, row) => {
    props.setPendingProfileData &&
      props.setPendingProfileData({ artist_name: name });
  };
  return (
    <div className={styles.mains}>
      <TableContainer
        component={Paper}
        sx={{ backgroundColor: "#F6F6F6", color: "black" }}
      >
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#bfbbc3" }}>
              {props.head.map((column, i) => (
                <TableCell
                  align="center"
                  sx={{
                    whiteSpace: "nowrap",
                    fontFamily: "DM Sans",
                    fontWeight: "bold",
                    fontSize: "16px",
                    height: "18px",
                    color: "#1C1C1C",
                  }}
                  key={i}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data &&
              props.data.map((row, i) => (
                <TableRow
                  onClick={() =>
                    handleProfilepage(row.id, row.artist_name, row)
                  }
                  value={row.artist_name}
                  style={{ color: "#636365", cursor: "pointer" }}
                  key={i}
                >
                  {/* <Link to={`/artist/${row.id}`}> */}
                  <TableCell
                    sx={{
                      fontFamily: "DM Sans",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "21px",
                      color: "#636365",
                    }}
                     onClick={() => navigate(`/user/newestpaintings/${row?._id}`)}
                    className={styles.table_column}
                    align="center"
                  >
                    <img
                      src={` ${row.image}`}
                      height="42px"
                      width="60px"
                      alt="img"
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "DM Sans",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "21px",
                      color: "#636365",
                    }}
                    className={styles.table_column}
                    align="center"
                  >
                {row.name}
                  </TableCell>
                  {/* {row.likesViews && row.likesViews.map((like) => (  */}
                  {/* <> */}
                  <TableCell
                    sx={{
                      fontFamily: "DM Sans",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "21px",
                      color: "#636365",
                    }}
                    className={styles.table_column}
                    align="center"
                  >
                    {row.views}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "DM Sans",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "21px",
                      color: "#636365",
                    }}
                    className={styles.table_column}
                    align="center"
                  >
                    {row.likes}
                  </TableCell>
                  {/* </> */}
                  {/* //  ))} */}
                  <TableCell
                    sx={{
                      fontFamily: "DM Sans",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "21px",
                      color: "#636365",
                      whiteSpace: "nowrap",
                    }}
                    className={styles.table_column}
                    // align="center"
                  >
                    <div className={styles.column_gallery_artist_true}>
                      {row.sale.qty}
                      {/* align="left">
           {row.is_accepted === "Pending" ? (
            <span className={styles.pending}>Pending</span>
           ) : row.is_accepted === "Rejected" ? (
            <label className={styles.cancel}>Rejected</label>
           ) : (
            row.qty */}

                      <Tooltip title="Account settings">
                        <IconButton
                          onClick={(event) => handleClick(event, row._id)}
                          size="small"
                          sx={{ ml: 2 }}
                          aria-controls={threedots ? "account-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={threedots ? "true" : undefined}
                        >
                          <img
                            src={ "/img/Menu Icon.png"}
                            height="21.62px"
                            width="4.63px"
                            alt="img"
                            id={row._id}
                          />
                        </IconButton>
                      </Tooltip>
                      <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={threedots}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                          elevation: 0,
                          sx: {
                            width: "175px",
                            overflow: "visible",
                            boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
                            filter: "drop-shadow(0px 0px 0px rgba(0,0,0,0.32))",
                            mt: 2.5,
                            "& .MuiAvatar-root": {
                              width: "120px",
                              height: "132px",
                              ml: 0.5,
                              mr: 1,
                              // mt:5
                            },
                            "&:before": {
                              content: '""',
                              display: "block",
                              position: "absolute",
                              top: 0,
                              // horizontal: 'center',
                              right: 72,
                              width: 10,
                              height: 10,
                              bgcolor: "background.paper",
                              transform: "translateY(-50%) rotate(45deg)",
                              zIndex: 0,
                            },
                          },
                        }}
                        transformOrigin={{
                          horizontal: "center",
                          vertical: "right",
                        }}
                        anchorOrigin={{
                          horizontal: "left",
                          vertical: "center",
                        }}
                      >
                     
                        <MenuItem
                              // onClick={() => props.handleDelete(threedot_id)}
                              onClick={() => props.setOpen(true)}
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "16px",
                                lineHeight: "12px",
                                fontWeight: "400",
                                fontFamily: "DM Sans",
                              }}
                            >
                              Edit Art
                            </MenuItem>
                        <Divider />
                        <MenuItem
                           onClick={() => props.setOpen(row?._id)}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                            fontSize: "16px",
                            lineHeight: "12px",
                            fontWeight: "400",
                            fontFamily: "DM Sans",
                          }}
                       
                        >
                          Delete Art
                        </MenuItem>
                      </Menu>
                    </div>
                  </TableCell>

                  {/* </Link> */}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
