import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CommonArtdetail from "../../../../components/Pages/List/Common/artdetail/CommonArtdetail";
import { fetchArtsProfileData } from "../../../../State/api/superadminApi";
import styles from "./CuratorArtdetail.module.css";

const data = {
 description: [
  {
   title: "Title",
   content: "Art of Space",
  },
  {
   title: "Copyright",
   content: "yes",
  },
  {
   title: "Category",
   content: "Oil Painting",
  },
  {
   title: "Subject",
   content: "Lorem this is Subject",
  },
  {
   title: "Year",
   content: "2022",
  },
  {
   title: "Material",
   content: "Canvas",
  },
  {
   title: "Styles",
   content: "Art",
  },
  {
   title: "Dimenstions",
   content: "Cm H25 X  W215 X D14",
  },
  {
   title: "Keywords",
   content: "Art, Painting, Space",
  },
  {
   title: "Description",
   content:
    "ver since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently wi",
  },
  {
   title: "Exibition 3",
   content: "Art of Space",
  },
  {
   title: "Subscription Plan",
   content: "Premium",
  },
  {
   title: "Exxibition 2",
   content: "Art of space",
  },
  {
   title: "Biography",
   content:
    "k. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and m",
  },
 ],
 detailPrice: {
  heading: "Price &  Detail",
  content: [
   {
    details_subheading: "Sale",
    detailsubcontent: [
     {
      title: "Availablity",
      content: "sale",
     },
     {
      title: "Price",
      content: "$250,25",
     },
    ],
   },
   {
    details_subheading: "Rent",
    detailsubcontent: [
     {
      title: "Availablity",
      content: "Rent",
     },
     {
      title: "Price",
      content: "$250,25",
     },
     {
      title: "Time",
      content: "1 Month",
     },
    ],
   },
  ],
 },
};

export default function CuratorArtdetail() {
 const navigate = useNavigate();
 const { id } = useParams();
 const handleBack = () => {
  navigate("/art");
 };
 const dispatch = useDispatch();
 useEffect(() => {
  dispatch(fetchArtsProfileData(id));
 },[id]);
 const artprofiledata = useSelector((state)=> state.artsprofiledata.data)
 return (
  <div className={styles.mains}>
   {/* <div className={styles.back_button}>
        <img src={ /img/_ Back.png'} onClick={handleBack} className={styles.backimage} height='13px' width='46px' />
      </div>
      <div className={styles.previewimg_img_div}>
        <img src={ /img/Image 1.png'} className={styles.previewimg} />
      </div> */}
   <CommonArtdetail page="curator" data={artprofiledata && artprofiledata} />
  </div>
 );
}
