import { Grid, ThemeProvider, Typography, Link } from "@mui/material";
import React, { useEffect } from "react";
import styles from "./AllCategory.module.css";
// import "fontsource-marcellus";
import { Container } from "@mui/system";
import { createTheme } from "@mui/material/styles";
import serverimage from "../../../serverConstant";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategoryData } from "../../../State/api/userApi";
import axios from "axios";
import CircularLoader from "../../../common/loader/Loader";

function AllCategory() {

  const navigate = useNavigate();
  const categorydt = useSelector((state) => state.allcategoryData);

  const dispatch = useDispatch();
  const fetchData = () => {
    dispatch(fetchCategoryData({}));
  }
  useEffect(() => {
    fetchData();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [])

  const navigateToArtwork = (id) => {
    navigate(`/user/allcategory/${id}`);
  };

  const loader = useSelector((state) => state.loader);
  const handleNavigate = (route) => {
    navigate(route)
  }
  const theme = createTheme({
    typography: {
      h4: {
        fontFamily: "Marcellus",
        fontSize: "2vw",
      },
      h6: {
        fontFamily: "Marcellus",
        fontSize: "1vw",
      },
      imgText: {
        fontFamily: "Marcellus",
        fontWeight: 400,
        align: "center",
        fontSize: "1.15vw",
      },
    },
  });
  return (
    <>
      {loader.loader ?
        <div className={styles.loader}>
          <CircularLoader />
        </div>
        :
        <ThemeProvider theme={theme}>
          <div className={styles.allcategory}>
            <div className={styles.headerCategory}>
            <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
            <div className={styles.pageitle}>
              <h1>All Category</h1>
              <ul>
                <li><Link onClick={() => handleNavigate("/")}>Home /{" "} </Link></li> 
                <li>Category</li>
              </ul>
            </div>
          </Container>
            </div>
            <section className={styles.sec_sc}>
              <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
              <Grid container spacing={2}>
                  {categorydt.data && categorydt.data.allcategories.map((item) => (
                    <Grid style={{ cursor: "default" }} onClick={() => navigateToArtwork(item._id)} item xs={6} sm={4} md={4} lg={4}>
                      <div className={styles.card__main}>
                        <div className={styles.card__top}>
                          <img className={styles.card__Image} src={ item.image} alt='img' />
                          <div className={styles.imgText}>
                          <h4 className={styles.catitle}>
                                  {item.name}
                                </h4>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </Container>
            </section>
             
          </div>
        </ThemeProvider>
      }
    </>
  );
}

export default AllCategory;
