import React from "react";
import styles from "./Uploadpic.module.css";

export default function Uploadpic(props) {
  return (
    <div className={styles.preview_img}>
      <input
        accept="image/*"
        onChange={props.handleChangeImage}
        type="file"
        id={props.name}
        name={props.name}
        hidden
      />
      <label htmlFor={props.name}>
        <img
          src={
            typeof props.src === "string" && props.src.startsWith("blob:")
              ? props.src
              : props.src || "/img/dummy-profile.png"
          }
          alt="Preview"
          className={props.src ? styles.img_view : styles.img_view_hide}
        />
      </label>
    </div>
  );
}
