import { Box, Button } from "@mui/material";
import React from "react";
import styles from "./Commoncard.module.css";
import homeImage from "../../../../../constant";
import { useNavigate } from "react-router-dom";
import { RiUserStarLine } from "react-icons/ri";
export default function CommoncardCurator({setCuratorPop, setOpen}) {
  const navigate = useNavigate();
  return (
    <>
      {/* {
            props.type === 'web' ?
                // <Box sx={{ mt: { xs: '-20px', sm: '-64px' } }} className={props.data.length === props.i + 1 ? styles.cancel_imgweb : styles.cancel_img_hide_web} >
                //     {props.data.length === props.i + 1 ?
                //         <img alt='img' onClick={() => props.setOpen(false)} src={ '/img/cancel_cross.png'} height='20px' width='20px' />
                //         : ''}
                // </Box> 
                "" :
                <div className={props.data.length === props.i + 1 ? styles.cancel_img : styles.cancel_img_hide} >
                    {props.data.length === props.i + 1 ?
                        <img alt='img' onClick={() => props.setOpen(false)} src={ '/img/cancel_cross.png'} height='20px' width='20px' />
                        : ''}
                </div>
        } */}
         <div>
         <div>
            <div className={styles.icdvn}><RiUserStarLine className={styles.icvn}/></div>
          </div>
            <h3 className={styles.sucrhndg}>I Am A Curator / An Affiliate</h3>
            <p className={styles.orpra}>You can apply online, and we will respond to you as quickly as possible.</p>
            <button className={styles.apply_btn} onClick={() => {
            setCuratorPop(true);
            setOpen(false)
          }}>Apply Online</button>
          </div>

      
    </>
  );
}
