import React, { useState } from "react";
import styles from "./DisputeDetailsAdmin.module.css";
import { useNavigate } from "react-router-dom";
import serverimage from "../../../../../serverConstant";
export default function DisputeDetailsAdmin(props) {
  const navigate = useNavigate();
  console.log(props, "props--dispute");
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleProfilepage = (id) => {
    navigate(`/orders/${id}`);
  };
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div className={styles.mains}>
      <div
        className={styles.back_btn}
        onClick={() => navigate(`/dispute`)}
        style={{ cursor: "pointer" }}
      >
        <img src={"/img/_ Back.png"} width="46px" height="15px" />
      </div>
      <hr className={styles.hrs} />
      {props.orderData &&
        props.orderData.map((val, i) => (
          <>
            <h6 className={styles.main_heading}>{val.title}</h6>
            <div className={styles.selector}>
              <div className={styles.gallery_status}>
                <h6>Status:</h6>
                <p>{val.dispute_status}</p>
              </div>
              {/* {props.type === "gallery" ? (
                <div className={styles.gallery_status}>
                  <h6>Status:</h6>
                  <p>Open</p>
                </div>
              ) : (
                <FormControl
                  sx={{
                    my: 1,
                    p: 0,
                    width: "120px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Select
                    value={age}
                    size="small"
                    sx={{
                      color: "#EE0909",
                      backgroundColor: "#f6f6f6",
                      borderRadius: "10px",
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                        borderRadius: "10px",
                      },
                      ".MuiOutlinedInput-notchedOutline": {
                        border: "none",
                        borderRadius: "10px",
                      },
                      height: "46px",
                      display: "flex",
                      justifyContent: "center",
                      // textAlign:'right'
                    }}
                    align="right"
                    onChange={handleChange}
                    displayEmpty
                    IconComponent={() => (
                      <img
                        src={"/img/dropdownicon.png"}
                        width="13px"
                        height="10px"
                        style={{ marginRight: "20px", textAlign: "left" }}
                      />
                    )}
                  >
                    <MenuItem sx={{ color: "green" }} value="">
                      Open
                    </MenuItem>
                    <MenuItem sx={{ color: "green" }} value="">
                      Close
                    </MenuItem>
                  </Select>
                </FormControl>
              )} */}
            </div>

            <>
              <div className={styles.details}>
                <div className={styles.title}>Depute ID:</div>
                <div className={styles.content}>{val._id}</div>
              </div>
              <div className={styles.details}>
                <div className={styles.title}>Order ID:</div>
                <div
                   style={{
                    cursor: "pointer",
                    borderBottom: isHovered ? "2px solid black" : "1px solid gray",
                    color: isHovered ? "blue" : "black",
                  }}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  onClick={() => handleProfilepage(val.order_id)}
                  className={styles.content}
                >
                  {val.order_id}
                </div>
              </div>
              <div className={styles.details}>
                <div className={styles.title}>Description:</div>
                <div className={styles.content}>{val.description}</div>
              </div>
              <div className={styles.details}>
                <div className={styles.title}>Payment:</div>
                <div className={styles.content}>{val.refund_status}</div>
              </div>
            </>

            <div className={styles.image_div}>
              <img src={` ${val.images}`} width="113px" height="115px" />
            </div>
          </>
        ))}
    </div>
  );
}
