import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

export default function PrintTableArtsmiley({
  print_percentage,
  printPricingResponse,
  getProductOptions,
  width,
  height,
  basePrice,
  props,
  pricingData,
  setPricingData
}) {
console.log("check calculation ", print_percentage,
  printPricingResponse,
  getProductOptions,
  width,
  height,
  basePrice,
  props,
  pricingData,
  setPricingData);

  const calculatePrice = (height, width, price, product, basePrice) => {
    let printPrice;
    switch (product) {
      case "canvas print":
        printPrice = (((height / 100) * width) / 100) * price + basePrice;
        localStorage.setItem("canvas_print", JSON.stringify(printPrice));
        break;
      case "canvas print and stretch":
        printPrice =
          Number(JSON.parse(localStorage.getItem("canvas_print")) || 0) +
          (((height / 100) * width) / 100) * price +
          basePrice;
        localStorage.setItem("canvas_stretch", JSON.stringify(printPrice));
        sessionStorage.setItem("canvas_stretch", JSON.stringify(printPrice));
        break;
      case "canvas print with stretching and framing":
        printPrice =
          Number(JSON.parse(localStorage.getItem("canvas_stretch")) || 0) +
          (((height / 100) * width) / 100) * price +
          basePrice;
        localStorage.setItem(
          "canvas_stretch_framing",
          JSON.stringify(printPrice)
        );
        break;
      case "fine art print":
        printPrice = (((height / 100) * width) / 100) * price + basePrice;
        localStorage.setItem("fine_art_print", JSON.stringify(printPrice));
        break;
      case "fine art print with framing":
        printPrice =
          Number(JSON.parse(localStorage.getItem("fine_art_print")) * 2) +
          (((height / 100) * width) / 100) * price +
          basePrice;
        localStorage.setItem(
          "fine_art_print_with_framing",
          JSON.stringify(printPrice)
        );
        break;
      case "photo paper print":
        printPrice = (((height / 100) * width) / 100) * price + basePrice;
        localStorage.setItem("photo_paper_print", JSON.stringify(printPrice));
        break;
      case "photo paper print with framing":
        printPrice =
          Number(JSON.parse(localStorage.getItem("photo_paper_print")) * 2) +
          (((height / 100) * width) / 100) * price +
          basePrice;
        localStorage.setItem(
          "photo_paper_print_with_framing",
          JSON.stringify(printPrice)
        );
        break;
      case "poster":
        printPrice = (((height / 100) * width) / 100) * price + basePrice;
        localStorage.setItem("poster", JSON.stringify(printPrice));
        break;
      case "poster with framing":
        printPrice =
          Number(JSON.parse(localStorage.getItem("poster")) * 2) +
          (((height / 100) * width) / 100) * price +
          basePrice;
        localStorage.setItem(
          "poster_with_framing",
          JSON.stringify(printPrice)
        );
        break;
      case "metal print":
        printPrice = (((height / 100) * width) / 100) * price + basePrice;
        localStorage.setItem("metal_print", JSON.stringify(printPrice));
        break;
      case "aluminium hanging":
        printPrice =
          Number(JSON.parse(localStorage.getItem("metal_print")) * 2) +
          (((height / 100) * width) / 100) * price +
          basePrice;
        localStorage.setItem(
          "aluminium_hangin",
          JSON.stringify(printPrice)
        );
        break;
      default:
        printPrice = (((height / 100) * width) / 100) * price + basePrice;
        break;
    }

    // Ensure printPrice is a number and round to 2 decimal places
    printPrice = Number(printPrice) || 0;
    return parseFloat(printPrice.toFixed(2));
  };

const calculatePriceHalf = (height, width, price, product, basePrice) => {  
  let printPrice;
  const halvedHeight = height / 2;
  const halvedWidth = width / 2;
  switch (product) {    
    case "canvas print":     
      printPrice = (((halvedHeight / 100) * (halvedWidth / 100)) * price + basePrice);      
      localStorage.setItem("canvas_print_half", JSON.stringify(printPrice));
      break;
    case "canvas print and stretch":
     
      printPrice =
        (Number(JSON.parse(localStorage.getItem("canvas_print_half")) || 0) +
          (((halvedHeight / 100) * (halvedWidth / 100))*2* price) +
          basePrice)
       
      localStorage.setItem("canvas_stretch_half", JSON.stringify(printPrice));
      sessionStorage.setItem("canvas_stretch_half", JSON.stringify(printPrice));
      break;
    case "canvas print with stretching and framing":
      printPrice =
        (Number(JSON.parse(localStorage.getItem("canvas_stretch_half")) || 0) +
          (((halvedHeight / 100) * halvedWidth) / 100) * price +
          basePrice) /
        2;
      localStorage.setItem(
        "canvas_stretch_framing_half",
        JSON.stringify(printPrice)
      );
      break;
    case "fine art print":
      printPrice = ((((halvedHeight / 100) * halvedWidth) / 100) * price + basePrice) / 2;
      localStorage.setItem("fine_art_print_half", JSON.stringify(printPrice));
      break;
    case "fine art print with framing":
      printPrice =
        (Number(JSON.parse(localStorage.getItem("fine_art_print_half")) * 2) +
          (((halvedHeight / 100) * halvedWidth) / 100) * price +
          basePrice) /
        2;
      localStorage.setItem(
        "fine_art_print_with_framing_half",
        JSON.stringify(printPrice)
      );
      break;
    case "photo paper print":
      printPrice = ((((halvedHeight / 100) * halvedWidth) / 100) * price + basePrice) / 2;
      localStorage.setItem("photo_paper_print_half", JSON.stringify(printPrice));
      break;
    case "photo paper print with framing":
      printPrice =
        (Number(JSON.parse(localStorage.getItem("photo_paper_print_half")) * 2) +
          (((halvedHeight / 100) * halvedWidth) / 100) * price +
          basePrice) /
        2;
      localStorage.setItem(
        "photo_paper_print_with_framing_half",
        JSON.stringify(printPrice)
      );
      break;
    case "poster":
      printPrice = ((((halvedHeight / 100) * halvedWidth) / 100) * price + basePrice) / 2;
      localStorage.setItem("poster_half", JSON.stringify(printPrice));
      break;
    case "poster with framing":
      printPrice =
        (Number(JSON.parse(localStorage.getItem("poster_half")) * 2) +
          (((halvedHeight / 100) * halvedWidth) / 100) * price +
          basePrice) /
        2;
      localStorage.setItem("poster_with_framing_half", JSON.stringify(printPrice));
      break;
    case "metal print":
      printPrice = ((((halvedHeight / 100) * halvedWidth) / 100) * price + basePrice) / 2;
      localStorage.setItem("metal_print_half", JSON.stringify(printPrice));
      break;
    case "aluminium hanging":
      printPrice =
        (Number(JSON.parse(localStorage.getItem("metal_print_half")) * 2) +
          (((halvedHeight / 100) * halvedWidth) / 100) * price +
          basePrice) /
        2;
      localStorage.setItem("aluminium_hanging_half", JSON.stringify(printPrice));
      break;
    default:
      printPrice = ((((halvedHeight / 100) * halvedWidth) / 100) * price + basePrice) / 2;
      break;
  }

  // Ensure printPrice is a number and round to 2 decimal places
  printPrice = Number(printPrice) || 0;
  return parseFloat(printPrice.toFixed(2));
};

const calculatePriceDouble = (height, width, price, product, basePrice) => {
  let printPrice;
  switch (product) {
    case "canvas print":
      printPrice = ((((height / 100) * width) / 100) * price + basePrice) * 2;
      localStorage.setItem("canvas_print_double", JSON.stringify(printPrice));
      break;
    case "canvas print and stretch":
      printPrice =
        (Number(JSON.parse(localStorage.getItem("canvas_print_double")) || 0) +
          (((height / 100) * width) / 100) * price +
          basePrice) *
        2;
      localStorage.setItem("canvas_stretch_double", JSON.stringify(printPrice));
      sessionStorage.setItem("canvas_stretch_double", JSON.stringify(printPrice));
      break;
    case "canvas print with stretching and framing":
      printPrice =
        (Number(JSON.parse(localStorage.getItem("canvas_stretch_double")) || 0) +
          (((height / 100) * width) / 100) * price +
          basePrice) *
        2;
      localStorage.setItem(
        "canvas_stretch_framing_double",
        JSON.stringify(printPrice)
      );
      break;
    case "fine art print":
      printPrice = ((((height / 100) * width) / 100) * price + basePrice) * 2;
      localStorage.setItem("fine_art_print_double", JSON.stringify(printPrice));
      break;
    case "fine art print with framing":
      printPrice =
        (Number(JSON.parse(localStorage.getItem("fine_art_print_double")) * 2) +
          (((height / 100) * width) / 100) * price +
          basePrice) *
        2;
      localStorage.setItem(
        "fine_art_print_with_framing_double",
        JSON.stringify(printPrice)
      );
      break;
    case "photo paper print":
      printPrice = ((((height / 100) * width) / 100) * price + basePrice) * 2;
      localStorage.setItem("photo_paper_print_double", JSON.stringify(printPrice));
      break;
    case "photo paper print with framing":
      printPrice =
        (Number(JSON.parse(localStorage.getItem("photo_paper_print_double")) * 2) +
          (((height / 100) * width) / 100) * price +
          basePrice) *
        2;
      localStorage.setItem(
        "photo_paper_print_with_framing_double",
        JSON.stringify(printPrice)
      );
      break;
    case "poster":
      printPrice = ((((height / 100) * width) / 100) * price + basePrice) * 2;
      localStorage.setItem("poster_double", JSON.stringify(printPrice));
      break;
    case "poster with framing":
      printPrice =
        (Number(JSON.parse(localStorage.getItem("poster_double")) * 2) +
          (((height / 100) * width) / 100) * price +
          basePrice) *
        2;
      localStorage.setItem("poster_with_framing_double", JSON.stringify(printPrice));
      break;
    case "metal print":
      printPrice = ((((height / 100) * width) / 100) * price + basePrice) * 2;
      localStorage.setItem("metal_print_double", JSON.stringify(printPrice));
      break;
    case "aluminium hanging":
      printPrice =
        (Number(JSON.parse(localStorage.getItem("metal_print_double")) * 2) +
          (((height / 100) * width) / 100) * price +
          basePrice) *
        2;
      localStorage.setItem("aluminium_hanging_double", JSON.stringify(printPrice));
      break;
    default:
      printPrice = ((((height / 100) * width) / 100) * price + basePrice) * 2;
      break;
  }

  // Ensure printPrice is a number and round to 2 decimal places
  printPrice = Number(printPrice) || 0;
  return parseFloat(printPrice.toFixed(2));
};


  const calculationPercentage = (productType) => {
    switch (productType) {
      case "canvas print":
        return Number(JSON.parse(localStorage.getItem("canvas_print")));
      case "canvas print and stretch":
        return Number(JSON.parse(localStorage.getItem("canvas_stretch")));
      case "canvas print with stretching and framing":
        return Number(
          JSON.parse(localStorage.getItem("canvas_stretch_framing"))
        );
      case "fine art print":
        return Number(JSON.parse(localStorage.getItem("fine_art_print")));
      case "fine art print with framing":
        return Number(
          JSON.parse(localStorage.getItem("fine_art_print_with_framing"))
        );
      case "photo paper print":
        return Number(JSON.parse(localStorage.getItem("photo_paper_print")));
      case "photo paper print with framing":
        return Number(
          JSON.parse(localStorage.getItem("photo_paper_print_with_framing"))
        );
      case "poster":
        return Number(JSON.parse(localStorage.getItem("poster")));
      case "poster with framing":
        return Number(JSON.parse(localStorage.getItem("poster_with_framing")));
      case "metal print":
        return Number(JSON.parse(localStorage.getItem("metal_print")));
      case "aluminium hanging":
        return Number(JSON.parse(localStorage.getItem("aluminium_hanging")));
      default:
        return 0;
    }
  };
    const calculationPercentageHalf = (productType) => {
    switch (productType) {
      case "canvas print":
        return Number(JSON.parse(localStorage.getItem("canvas_print_half")));
      case "canvas print and stretch":
        return Number(JSON.parse(localStorage.getItem("canvas_stretch_half")));
      case "canvas print with stretching and framing":
        return Number(
          JSON.parse(localStorage.getItem("canvas_stretch_framing_half"))
        );
      case "fine art print":
        return Number(JSON.parse(localStorage.getItem("fine_art_print_half")));
      case "fine art print with framing":
        return Number(
          JSON.parse(localStorage.getItem("fine_art_print_with_framing_half"))
        );
      case "photo paper print":
        return Number(JSON.parse(localStorage.getItem("photo_paper_print_half")));
      case "photo paper print with framing":
        return Number(
          JSON.parse(localStorage.getItem("photo_paper_print_with_framing_half"))
        );
      case "poster":
        return Number(JSON.parse(localStorage.getItem("poster_half")));
      case "poster with framing":
        return Number(JSON.parse(localStorage.getItem("poster_with_framing_half")));
      case "metal print":
        return Number(JSON.parse(localStorage.getItem("metal_print_half")));
      case "aluminium hanging":
        return Number(JSON.parse(localStorage.getItem("aluminium_hanging_half")));
      default:
        return 0;
    }
  };

    const calculationPercentageDouble = (productType) => {
    switch (productType) {
      case "canvas print":
        return Number(JSON.parse(localStorage.getItem("canvas_print_double")));
      case "canvas print and stretch":
        return Number(JSON.parse(localStorage.getItem("canvas_stretch_double")));
      case "canvas print with stretching and framing":
        return Number(
          JSON.parse(localStorage.getItem("canvas_stretch_framing_double"))
        );
      case "fine art print":
        return Number(JSON.parse(localStorage.getItem("fine_art_print_double")));
      case "fine art print with framing":
        return Number(
          JSON.parse(localStorage.getItem("fine_art_print_with_framing_double"))
        );
      case "photo paper print":
        return Number(JSON.parse(localStorage.getItem("photo_paper_print_double")));
      case "photo paper print with framing":
        return Number(
          JSON.parse(localStorage.getItem("photo_paper_print_with_framing_double"))
        );
      case "poster":
        return Number(JSON.parse(localStorage.getItem("poster_double")));
      case "poster with framing":
        return Number(JSON.parse(localStorage.getItem("poster_with_framing_double")));
      case "metal print":
        return Number(JSON.parse(localStorage.getItem("metal_print_double")));
      case "aluminium hanging":
        return Number(JSON.parse(localStorage.getItem("aluminium_hanging_double")));
      default:
        return 0;
    }
  };
  useEffect(() => {
    if (printPricingResponse?.found) {
      const updatedPricing = printPricingResponse.found.map((row) => {
        const product = row.product;
        const price = calculatePrice(height, width, row.price, row.product, basePrice);
        return { product, price };
      });
      setPricingData(updatedPricing);
    }
  }, [printPricingResponse, height, width, basePrice]);

  const handleStoreProductPricing = (product, price) => {
    props.setUploadArt((prevUploadArt) => {
      const updatedPrintByArtsmiley = prevUploadArt.print_by_artsmiley.map((item, index) => {
        if (index === 0) {
          return { 
            ...item, 
            product, 
            product_price: price 
          };
        }
        return item;
      });

      return {
        ...prevUploadArt,
        print_by_artsmiley: updatedPrintByArtsmiley,
      };
    });
  };

  useEffect(() => {
    pricingData.forEach(({ product, price }) => {
      handleStoreProductPricing(product, price);
    });
  }, [pricingData]);

  return (
    <TableContainer>
<Table>
  <TableHead>
    <TableRow>
      <TableCell>Product</TableCell>
      <TableCell align="right">Size</TableCell>
      <TableCell align="right">Price ($)</TableCell>
      <TableCell align="right">Commission ($)</TableCell>
    </TableRow>
  </TableHead>
  <TableBody>
    {printPricingResponse?.found?.map((row) => (
      <React.Fragment key={row.value}>
        {/* Product Name */}
        <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
          <TableCell
            component="th"
            scope="row"
            sx={{ textTransform: "capitalize" }}
          >
            {row.product}
          </TableCell>
          <TableCell align="right">{width/2} x {height/2}</TableCell>
          <TableCell align="right">
            ${calculatePriceHalf(height, width, row.price, row.product, basePrice)}
          </TableCell>
          <TableCell align="right">
            ${parseFloat(
              (calculationPercentageHalf(row.product) * (print_percentage / 100)).toFixed(2)
            )}
          </TableCell>
        </TableRow>

        {/* Repeated Row 2 */}
        <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
          <TableCell />
          <TableCell align="right">{width} x {height}</TableCell>
          <TableCell align="right">
            ${calculatePrice(height, width, row.price, row.product, basePrice)}
          </TableCell>
          <TableCell align="right">
            ${parseFloat(
              (calculationPercentage(row.product) * (print_percentage / 100)).toFixed(2)
            )}
          </TableCell>
        </TableRow>

        {/* Repeated Row 3 */}
        <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
          <TableCell />
          <TableCell align="right">{width*2} x {height*2}</TableCell>
          <TableCell align="right">
            ${calculatePriceDouble(height, width, row.price, row.product, basePrice)}
          </TableCell>
          <TableCell align="right">
            ${parseFloat(
              (calculationPercentageDouble(row.product) * (print_percentage / 100)).toFixed(2)
            )}
          </TableCell>
        </TableRow>
      </React.Fragment>
    ))}
  </TableBody>
</Table>


    </TableContainer>
  );
}
 