import * as React from "react"
import styles from "./Commonsidebar.module.css"
import { Button } from "@mui/material"
import { Link, useLocation, useNavigate } from "react-router-dom"

export default function Commonsidebar(props) {
  const location = useLocation()
  const navigate = useNavigate()
  const rawPath = location.pathname;
  // Remove any dynamic ID if it exists after the base path
  const path = rawPath.replace(/\/[a-f0-9]{24}$/, ''); // Adjust regex for 24-character MongoDB ObjectIDs
  
  const usertypes = localStorage.getItem("usertype")
  const handleDashboard = () => {
    navigate("/dashboard")
  }
  const handleClick = (val) => {
    navigate(val)
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }
  return (
   <div className={styles.demo}>
        {usertypes === "buyer" ||
        usertypes === "affiliate" ||
        usertypes === "subadmin" ? (
          " "
        ) : (
          <h2 onClick={handleDashboard} style={{cursor :"pointer"}}>Dashboard</h2>
        )}
        {props.sidebarRoutes &&
          props.sidebarRoutes.map((val, i) => (
            <div className={styles.demo_child_div} key={i}>
              <div
                className={
                  path === val.path || path.includes(`${val.path}/`)
                    ? styles.flex_start
                    : ""
                }
              ></div>
              <Button
                onClick={() => handleClick(val.path)}
                variant={
                  path === val.path || path.includes(`${val.path}/`)
                    ? "contained"
                    : ""
                }
                fullWidth
                sx={{
                  ml: 1,
                  pl: 2,
                  mr: 1,
                  textAlign:"left",
                  justifyContent:"start",
                  textTransform:"capitalize !important",
                  fontWeight:"500",
                  borderTopRightRadius:"0",
                  borderBottomRightRadius:"0"
                }}
              >
                {val.name}
              </Button>
            </div>
          ))}
      </div>
  )
}
