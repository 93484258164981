import {Grid, Container } from "@mui/material";
import React from 'react';
import styles from "./TradeProgramApplication.module.css";
import CssBaseline from "@mui/material/CssBaseline";
import PhoneInput from "react-phone-input-2";
function Questionnaire() {
  return (
    <React.Fragment>
      <CssBaseline />
    <div className={styles.aboutus}>
      
      <section className={styles.hero_main}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
        <div className={`${styles.pageitle} ${styles.max_width}`}>
              <h1>Art Consultancy Questionnaire</h1>
              
            </div>
</Container>
        </section>
      
      <section className={styles.sec_sc}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
        <div className={`${styles.text_center} ${styles.max_width}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>Welcome to Personalized Art Advisory</h3>
                  <p className={styles.withor}>Fill out a quick questionnaire, and our skilled curators will handpick a range of artworks suited just for you. After submitting your answers, expect to hear from your personal curator within 3-5 business days.
                  Let’s begin the experience!</p>
          </div>
        <Grid container spacing={2} sx={{justifyContent:"center"}}>
        <Grid item xs={12} sm={10} md={8}>
        <div className={styles.stcgbox}>
                  <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12}>
                                      <div className="form-group1">
                                    <label>Select your preferred art categories </label>
                                    <select className="form-control">
                                      <option>Select the Option</option>
                                      <option></option>
                                      <option></option>
                                      <option></option>
                                    </select>
                                    </div>
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={12}>
                                      <div className="form-group1">
                                    <label>Pick your preferred styles for Category </label>
                                    <select className="form-control">
                                      <option>Select the Option</option>
                                      <option></option>
                                      <option></option>
                                      <option></option>
                                    </select>
                                    </div>
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={12}>
                                      <div className="form-group1">
                                    <label>Choose your preferred Subject </label>
                                    <select className="form-control">
                                      <option>Select the Option</option>
                                      <option></option>
                                      <option></option>
                                      <option></option>
                                    </select>
                                    </div>
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={12}>
                                      <div className="form-group1">
                                    <label>Pick Your Preferred Medium for Category</label>
                                    <select className="form-control">
                                      <option>Select the Option</option>
                                      <option></option>
                                      <option></option>
                                      <option></option>
                                    </select>
                                    </div>
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={12}>
                                      <div className="form-group1">
                                    <label>Choose Your Preferred Colors</label>
                                    <select className="form-control">
                                      <option>Select the Option</option>
                                      <option></option>
                                      <option></option>
                                      <option></option>
                                    </select>
                                    </div>
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={12}>
                                      <div className="form-group1">
                                    <label>Select the orientation(s) that apply to your preference </label>
                                    <select className="form-control">
                                      <option>Select the Option</option>
                                      <option></option>
                                      <option></option>
                                      <option></option>
                                    </select>
                                    </div>
                                      </Grid>
                                      
                                      <Grid item xs={12} sm={12} md={12}>
                                      <div className="form-group1">
                                    <label>What is the preferred artwork size that best suits your needs? Select from the following</label>
                                    <select className="form-control">
                                      <option>Select the Option</option>
                                      <option></option>
                                      <option></option>
                                      <option></option>
                                    </select>
                                    </div>
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={12}>
                  <div className="form-group1">
                <label>Do you have a reference image or inspiration for the artwork you'd like to share? </label>
                <input
                  placeholder=""
                  className="form-control"
                  name=""
                  type="file"
                />
                <small>Upload up to 10 images</small>
                </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                                      <div className="form-group1">
                                    <label>What is Your Preferred Sculpture Height? </label>
                                    <select className="form-control">
                                      <option>Select the Option</option>
                                      <option></option>
                                      <option></option>
                                      <option></option>
                                    </select>
                                    </div>
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={12}>
                                      <div className="form-group1">
                                    <label>What Material Should the Sculpture Be Made Of?</label>
                                    <select className="form-control">
                                      <option>Select the Option</option>
                                      <option></option>
                                      <option></option>
                                      <option></option>
                                    </select>
                                    </div>
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={12}>
                  <div className="form-group1">
                <label>Please specify your budget (Minimum value $1500)</label>
                <input
                  id="outlined-basic"
                  variant="outlined"
                  placeholder=""
                  className="form-control"
                  name=""
                />
                <small>I am willing to spend up to</small>
                </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                                      <div className="form-group1">
                                    <label>What is Your Preferred Price Range (in USD)?</label>
                                    <select className="form-control">
                                      <option>Select the Option</option>
                                      <option></option>
                                      <option></option>
                                      <option></option>
                                    </select>
                                    </div>
                                      </Grid>
                                      
                                      <Grid item xs={12} sm={12} md={12}>
                  <div className="form-group1">
                <label style={{marginBottom:"0",marginTop:"15px"}}>Just a few more steps! Fill in the required fields below</label>
                </div>
                </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group1">
                <label>First Name *</label>
                <input
                  id="outlined-basic"
                  variant="outlined"
                  placeholder=""
                  className="form-control"
                  name="first_name"
                />
                
                </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group1">
                <label>Last Name *</label>
                <input
                  id="outlined-basic"
                  variant="outlined"
                  placeholder=""
                  className="form-control"
                  name=""
                />
                
                </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group1">
                <label>Phone *</label>
                <PhoneInput
                  inputStyle={{ background: "transparent" }}
                  containerStyle={{
                    background: "transparent",
                    width: "100%",
                  }}
                  placeholder="Enter your phone number"
                  country={"in"}
                  name="phone"
                
                />
                </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group1">
                <label>Email *</label>
                <input
                  id="outlined-basic"
                  variant="outlined"
                  placeholder=""
                  className="form-control"

                  name="email"
                />
                </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group1">
                <label>Designation *</label>
                <input
                  id="outlined-basic"
                  variant="outlined"
                  placeholder=""
                  className="form-control"
                  name="designation"
                />
                
                </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                                      <div className="form-group1">
                                    <label>Please specify the project category *</label>
                                    <select className="form-control">
                                      <option>Select the Option</option>
                                      <option></option>
                                      <option></option>
                                      <option></option>
                                    </select>
                                    </div>
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={12}>
                  <div className="form-group1">
                <label style={{marginBottom:"0",marginTop:"15px"}}>Choose the project location</label>
                </div>
                </Grid>
                                      <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group1">
                <label>City *</label>
                <input
                  id="outlined-basic"
                  variant="outlined"
                  placeholder=""
                  className="form-control"
                  name=""
                />
                
                </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group1">
                <label>Country *</label>
                <input
                  id="outlined-basic"
                  variant="outlined"
                  placeholder=""
                  className="form-control"
                  name=""
                />
                
                </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                  <div className="form-group1">
                <label>Do you have additional details to share about the artwork you're envisioning, such as color schemes, for which environment, favorite artists, inspirational styles etc., ? Links to Pinterest boards or similar artworks are welcome! *</label>
                <textarea
                  maxRows={5}
                  minRows={5}
                 className="form-control"
                  // onChange={props.handleChangedata}
                  // value={props.uploadArt.description}
                  name="message"
                  fullWidth
                  placeholder=""
                />
                </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                  <div className="form-group1">
                <label>
                <input
                  type="checkbox" name=""
                /> <br/> Subscribe to our Newsletter
                 </label>
                
                </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                  <div className="form-group1">
                <label> Please upload a photo of the wall or room picture for suggesting art works to suit the environment.(Optional)</label>
                <input
                  type="file"
                  className="form-control"
                  name=""
                />
                <small>Upload up to 10 images</small>
                </div>
                  </Grid>
                  
                    
                  
                  
                  <Grid item xs={12} sm={12} md={12}>
                  <div className={styles.text_right}>
               <button
                className={styles.apply_btn}
              >
                Submit
              </button>
               </div>
               {/* <div>
                <p className={styles.sbychsbu}>By completing this form, you consent to receive promotional emails regarding the Art Smiley Trade Program. You may opt out at any time. <Link href="/user/privacypolicy" target="_blank">Privacy Policy</Link> <Link href="/user/termsconditions" target="_blank">Terms and Conditions</Link>.</p>
               </div> */}
                  </Grid>
                  </Grid>
</div>
          </Grid>
        </Grid>
        
        </Container>
      </section>
    </div>
    </React.Fragment>
  );
}

export default Questionnaire;
