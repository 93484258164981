import {
  Autocomplete,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { default as AnotherSelect, components } from "react-select";
import makeAnimated from "react-select/animated";
import styles from "./Commonuploadartsecond.module.css";
import React, { useEffect, useState } from "react";
import { TextareaAutosize } from "@mui/base";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import homeImage from "../../../../constant";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addArtData,
  addCategory,
} from "../../../../State/Features/Artist/uploadartSlice";
import {
  fetchCategories,
  handleFetchMaterial,
  handleFetchMedium,
  handleFetchStyle,
  handleFetchSubject,
} from "../../../../State/api/superadminApi";
import {
  fetchCountry,
  fetchMaterial,
  fetchStyle,
  fetchSubject,
} from "../../../../State/api/artistapi";

import { AlertsSuccess, Errornotify } from "../../../../common/alert/Alert";
import { fetchProductType } from "../../../../State/api/userApi";

import ClearIcon from "@mui/icons-material/Clear";
import api from "../../../../State/api/Api";
import validationForSecondFormArt from "../../../../validation/secondFormArtValidation";

function Commonuploadartsecond(props) {
  const [material, setMaterial] = useState(null);
  const [secondFormError, setSecondFormErrors] = useState({});
  const [subject, setSubject] = useState(null);
  const [colordt, setColordt] = useState();
  console.log(colordt, "colordt");
  const [orientation, setOrientation] = useState();
  const animatedComponents = makeAnimated();
  const [style, setStyle] = useState(null);
  const [medium, setMedium] = useState(null);
  const [country, setCountry] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const uploadarts = useSelector((state) => state.uploadArt);
  const categorydata = useSelector((state) => state.categoryData.data);
  const itemTypes = useSelector((state) => state.productType.data);

  // const [activeIndex, setActiveIndex] = useState(null);

  // Handler to set the active div index
  const handleClick = (colorId) => {
    // Retrieve the current array of colors
    const currentColors = props.uploadArt.colors || [];

    // Check if the colorId is already in the array
    const colorIndex = currentColors.indexOf(colorId);

    // If colorId is not found, add it to the array
    if (colorIndex === -1) {
      props.setUploadArt({
        ...props.uploadArt,
        colors: [...currentColors, colorId],
      });
    } else {
      // Otherwise, remove it from the array
      props.setUploadArt({
        ...props.uploadArt,
        colors: currentColors.filter((id) => id !== colorId),
      });
    }
  };

  const orientationValues = [
    {
      id: 1,
      label: "Portrait",
      value: "Portrait",
    },
    {
      id: 2,
      label: "Square",
      value: "Square",
    },

    {
      id: 3,
      label: "Landscape",
      value: "Landscape",
    },
  ];

  const [keywordValue, setKeywordValue] = useState("");
  const editArtId = useSelector((state) => state.editArtId?.id);
  const handleChangeKeyword = (e) => {
    const { value } = e.target;
    // Uncomment this line if you want to add the value on every change
    setKeywordValue(value);
  };
  const handleKeywordChange = (event) => {
    if (event.key === "Enter" && keywordValue.trim()) {
      event.preventDefault();
      const newKeyword = keywordValue.trim();
      if (!props.uploadArt.keywords.includes(newKeyword)) {
        props.setUploadArt((prevState) => ({
          ...prevState,
          keywords: [...prevState.keywords, newKeyword],
        }));
      }
      setKeywordValue("");
    }
  };

  const handleRemoveKeyword = (keyword) => {
    // Filter out the keyword from the parent component's state
    const updatedKeywords = props.uploadArt.keywords.filter(
      (kw) => kw !== keyword
    );
    props.setUploadArt((prevState) => ({
      ...prevState,
      keywords: updatedKeywords,
    }));
  };
  const handleNavigateNext = (path) => {
    const { isValidSecondForm, secondFormErrors } = validationForSecondFormArt(
      props.uploadArt,
      props.category
    );
    if (isValidSecondForm) {
      navigate(path);
      setSecondFormErrors({});
    } else {
      setSecondFormErrors(secondFormErrors);
      window.scrollTo({
        top: 150,
        behavior: "smooth",
      });
    }
    // if (
    //   props.uploadArt.category_id === "" ||
    //   props.uploadArt.subject_id === "" ||
    //   props.uploadArt.material_id === "" ||
    //   props.uploadArt.style_id.length === 0 ||
    //   props.uploadArt.height === "" ||
    //   props.uploadArt.width === "" ||
    //   props.uploadArt.depth === "" ||
    //   props.uploadArt.year === null ||
    //   props.uploadArt.medium === "" ||
    //   props.uploadArt.keywords === "" ||
    //   props.uploadArt.description === "" ||
    //   props.uploadArt.colors === "" ||
    //   props.uploadArt.orientation === ""
    // ) {
    //   Errornotify("Please Fill All Detail");
    // } else {
    //   navigate(path);
    // }
  };
  const selected_material =
    props.uploadArt.material_id &&
    props.uploadArt.material_id.map((val) => val._id);
  const selected_style =
    props.uploadArt.style_id && props.uploadArt.style_id.map((val) => val._id);
  const selected_medium =
    props.uploadArt.medium_id &&
    props.uploadArt.medium_id.map((val) => val._id);
  useEffect(() => {
    if (
      props.uploadArt.name === "" ||
      props.uploadArt.image === "" ||
      props.uploadArt.images === ""
    ) {
      // navigate("/upload/stepfirst");
    } else {
    }
    dispatch(fetchCategories());
    dispatch(fetchCountry(setCountry));
    dispatch(fetchProductType());
  }, []);

  useEffect(() => {
    if (props.uploadArt.category_id) {
      dispatch(
        handleFetchSubject(
          { category_id: props.uploadArt.category_id },
          setSubject
        )
      );
      dispatch(
        handleFetchMedium(
          { category_id: props.uploadArt.category_id },
          setMedium
        )
      );
      dispatch(
        handleFetchMaterial(
          { category_id: props.uploadArt.category_id },
          setMaterial
        )
      );
      dispatch(
        handleFetchStyle({ category_id: props.uploadArt.category_id }, setStyle)
      );
    }
  }, [dispatch, props.uploadArt]);

  // data.js or data file
  const colourOptions = [
    { value: "red", label: "Red" },
    { value: "orange", label: "Orange" },
    { value: "yellow", label: "Yellow" },
    { value: "green", label: "Green" },
    { value: "blue", label: "Blue" },
    { value: "indigo", label: "Indigo" },
    { value: "violet", label: "Violet" },
    { value: "black", label: "Black" },
    { value: "white", label: "White" },
    { value: "gray", label: "Gray" },
  ];

  const handleSelectCategory = (selectedOption, name) => {
    if (!selectedOption) return; // If nothing is selected, do nothing

    const value = selectedOption.value;
    if (name === "material_id") {
      props.setUploadArt({ ...props.uploadArt, material_id: value });
    } else if (name === "subject_id") {
      props.setUploadArt({ ...props.uploadArt, subject_id: value });
    } else {
      props.setUploadArt({ ...props.uploadArt, category_id: value });
      props.setCategory(selectedOption.label);
      dispatch(handleFetchSubject({ category_id: value }, setSubject));
      dispatch(handleFetchMedium({ category_id: value }, setMedium));
      dispatch(handleFetchMaterial({ category_id: value }, setMaterial));
      dispatch(handleFetchStyle({ category_id: value }, setStyle));
    }
  };

  const handleChangeYear = (yr) => {
    props.setUploadArt({ ...props.uploadArt, year: Number(yr) });
  };
  const handleRemoveMaterial = (id) => {
    var material = [...props.uploadArt.material_id];
    const indexs = props.uploadArt.material_id.findIndex(
      (val) => val._id === id
    );
    material.splice(indexs, 1);
    props.setUploadArt({ ...props.uploadArt, material_id: material });
  };
  const handleRemoveStyle = (id) => {
    var style = [...props.uploadArt.style_id];
    const indexs = props.uploadArt.style_id.findIndex((val) => val._id === id);
    style.splice(indexs, 1);
    props.setUploadArt({ ...props.uploadArt, style_id: style });
  };
  const handleRemoveMedium = (id) => {
    var medium = [...props.uploadArt.medium_id];
    const indexs = props.uploadArt.medium_id.findIndex((val) => val._id === id);
    medium.splice(indexs, 1);
    props.setUploadArt({ ...props.uploadArt, medium_id: medium });
  };

  const handleFetchOrientation = async function () {
    try {
      const result = await api.applicationApi(`api/user/getorientation`);
      if (result && result.status === 200) {
        setOrientation(result.data.response);
      } else if (result.status === 401) {
      }
    } catch (err) {}
  };
  const handleFetchColor = async function () {
    // dispatch(setLoader(true));
    try {
      const result = await api.applicationApi(`api/user/getcolors`);
      if (result && result.status === 200) {
        setColordt(result.data.response);
        // dispatch(setLoader(false));
      } else if (result.status === 401) {
      }
    } catch (err) {}
  };
  useEffect(() => {
    handleFetchOrientation();
    handleFetchColor();
  }, []);
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#f6f6f6",
      minHeight: "55px",
      marginTop: "0.4rem",
      boxShadow: "none", // Disable the blue border
      borderColor: "#ccc", // Set the desired border color here
      borderWidth: "1px", // Set the border width if needed
      borderStyle: "solid", // Set the border style if needed
      "&:hover": {
        borderColor: "#ccc", // Same border color for hover state
      },
      "&:focus": {
        borderColor: "red", // Border color for focus state
        outline: "none", // Remove outline on focus
        boxShadow: "none", // Remove box-shadow on focus if needed
      },
    }),
    indicatorsContainer: () => ({ display: "none" }),
    option: (provided, state) => ({
      ...provided,
      borderColor: "red", // Border color for focus state
      outline: "none", // Remove outline for options
      background: state.isSelected ? "lightgray" : "#ffffff",
      margin: "0",
      "&:hover": {
        background: !state.isSelected && "#eeeeee",
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#a8a8a8", // Light gray color for the placeholder
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#333", // Regular text color for selected value
    }),
  };

  const categoryOptions = categorydata?.map((val) => ({
    value: val._id,
    label: val.name,
  }));
  const orientationOptions = orientation?.map((val) => ({
    value: val._id,
    label: val.name,
  }));
  const selectedOrientation = orientationOptions?.find(
    (option) => option.value === props.uploadArt.orientation
  );
  const selectedCategory = categoryOptions?.find(
    (option) => option.value === props.uploadArt.category_id
  );

  const subjectOptions = subject?.map((val) => ({
    value: val._id,
    label: val.name,
  }));
  const selectedSubject = subjectOptions?.find(
    (option) => option.value === props.uploadArt.subject_id
  );

  const materialOptions = material?.map((val) => ({
    value: val._id,
    label: val.name,
  }));

  // Inside your component
  const [maxError, setMaxError] = useState({
    style_id: false,
    material_id: false,
    medium_id: false,
  });

  const handleMaterialChange = (selectedOptions) => {
    if (selectedOptions.length > 5) {
      setMaxError({ material_id: true }); // Show error if more than 5 items are selected
    } else {
      setMaxError({ material_id: false }); // Hide error if 5 or fewer items are selected
      props.handleChangedataCategories(selectedOptions, "material_id");
    }
  };

  const mediumOptions = medium?.map((val) => ({
    value: val._id,
    label: val.name,
  }));

  const handleMediumChange = (selectedOptions) => {
    if (selectedOptions.length > 5) {
      setMaxError({medium_id:true}); // Show error if more than 5 items are selected
    } else {
      setMaxError({medium_id:false}); // Hide error if 5 or fewer items are selected
      props.handleChangedataCategories(selectedOptions, "medium_id");
    }
  };

  const styleOptions = style?.map((val) => ({
    value: val._id,
    label: val.name,
  }));

  const handleStylesChange = (selectedOptions) => {
    if (selectedOptions.length > 5) {
      setMaxError({style_id:true}); // Show error if more than 5 items are selected
    } else {
      setMaxError({style_id:false}); // Hide error if 5 or fewer items are selected
      props.handleChangedataCategories(selectedOptions, "style_id");
    }
  };

  const { MultiValueRemove } = components;
  const CustomMultiValueRemove = () => null;
  const CustomMultiValueContainer = ({ children, ...props }) => {
    // Render an empty container to hide the selected values
    return <div {...props} />;
  };

  const yearArray = [];
  for (let year = 1950; year <= 2024; year++) {
    yearArray.push(year);
  }

  const currentYear = new Date().getFullYear();
  const startYear = 1870;
  const years = Array.from(
    { length: currentYear - startYear + 1 },
    (_, index) => startYear + index
  );
  const yearOptions = years
    .slice()
    .reverse()
    .map((year) => ({
      value: year,
      label: year.toString(), // or you can customize the label format if needed
    }));
  return (
    <div className={styles.mains}>
      <AlertsSuccess />
      <Grid container spacing={{ sm: 5 }} columns={{ xs: 12, sm: 12, md: 12 }}>
        <Grid item xs={12} sm={4} md={3} sx={{ textAlign: "center" }}>
          <p
            onClick={() => {
              if (editArtId?.id) {
                navigate("/edit/stepfirst");
              } else {
                navigate("/upload/stepfirst");
              }
            }}
            className={styles.back_img}
          >
            {"<"} Back To Artwork
          </p>
          {/* <div className={styles.upload_pic}></div> */}
          {/* <div
            className={
              props.src.length === 0 ? styles.upload_img : styles.upload_pic
            }
          >
            {props.src.length === 0 ? (
              ""
            ) : (
              <img src={props.src} className={styles.img_main_prev} alt="img" />
            )}
          </div> */}
          {/* <Uploadimage /> */}
        </Grid>
        <Grid item xs={12} sm={8} md={9} sx={{ my: 2 }}>
          <div className={styles.discription_div}>
            <h2 className={styles.main_heading}>Description</h2>

            <Grid
              container
              columnSpacing={{ sm: 2 }}
              columns={{ xs: 12, sm: 12, md: 12 }}
            >
              {/* {autoinput1.map((val) => (
        <Grid item xs={12} sm={4} md={4} key={val.id}>
         <div className={styles.search_action}>
          <lable className={styles.labels}>{val.label}</lable>
          <br />
          <Autocomplete
           id="combo-box-demo"
           popupIcon={<KeyboardArrowDownIcon />}
           options={top100Films}
           sx={{ width: "100%", mt: 0.8, backgroundColor: "#F3F3F3", border: "none" }}
           className={styles.search_select}
           renderInput={(params) => (
            <TextField
             {...params}
             name={val.name}
             sx={{ border: "none", color: "#636365" }}
             placeholder={val.placeholder}
            />
           )}
          />
         </div>
        </Grid>
       ))} */}
              <Grid item xs={12} sm={4} md={4}>
                <div className={styles.search_action}>
                  <lable className={styles.labels}>Category*</lable>
                  <br />
                  {/* <Select
                    labelId="select-mutli-artist"
                    id="demo-select-artist"
                    //   value={uploadarts.category_id === [] ? [] : uploadarts.category_id.map((val) => val._id)}
                    value={props.uploadArt.category_id}
                    fullWidth
                    //  multiple
                    sx={{
                      background: "#f6f6f6",
                      ".MuiOutlinedInput-notchedOutline": {},
                      "& .MuiInputBase-root": {
                        borderRadius: "6px",
                      },
                      mt: 0.8,
                      //    height: "40px",
                    }}
                    placeholder="select type"
                    onChange={handleSelectCategory}
                    //  onChange={props.handleChangedataCategories}
                    name="category_id"
                  // input={<OutlinedInput label="Aritst Name" />}
                  // MenuProps={MenuProps}
                  >
                    {categorydata &&
                      categorydata
                        //   .filter((fl) => {
                        //    return fl.is_accepted === "Approved";
                        //   })
                        .map((val) => (
                          <MenuItem value={val._id} key={val._id}>
                            {val.name}
                          </MenuItem>
                        ))}
                  </Select> */}
                  <AnotherSelect
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    // defaultValue={[colourOptions[4], colourOptions[5]]}
                    // isMulti
                    options={categoryOptions}
                    labelId="select-mutli-artist"
                    id="demo-select-artist"
                    value={selectedCategory}
                    fullWidth
                    styles={customStyles}
                    placeholder="Select category"
                    onChange={(selectedOption) =>
                      handleSelectCategory(selectedOption, "category_id")
                    }
                    name="category_id"
                  />
                  {secondFormError?.category_id ? (
                    <span
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {secondFormError?.category_id}
                    </span>
                  ) : null}
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <div className={styles.search_action}>
                  <lable className={styles.labels}>Subject*</lable>
                  <br />
                  <AnotherSelect
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    // defaultValue={[colourOptions[4], colourOptions[5]]}
                    // isMulti
                    options={subjectOptions}
                    labelId="select-mutli-artist"
                    id="demo-select-artist"
                    value={selectedSubject}
                    fullWidth
                    styles={customStyles}
                    placeholder="Select subject"
                    onChange={(selectedOption) =>
                      handleSelectCategory(selectedOption, "subject_id")
                    }
                    name="subject_id"
                  />
                  {secondFormError?.subject_id ? (
                    <span
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {secondFormError?.subject_id}
                    </span>
                  ) : null}
                  {/* <Select
                    labelId="select-mutli-artist"
                    id="demo-select-artist"
                    //   value={uploadarts.category_id === [] ? [] : uploadarts.category_id.map((val) => val._id)}
                    value={props.uploadArt.subject_id}
                    fullWidth
                    // multiple
                    sx={{
                      background: "#f6f6f6",
                      ".MuiOutlinedInput-notchedOutline": {},
                      "& .MuiInputBase-root": {
                        borderRadius: "6px",
                      },
                      mt: 0.8,
                      //    height: "40px",
                    }}
                    placeholder="select type"
                    //   onChange={handleSelectCategory}
                    onChange={props.handleChangedata}
                    name="subject_id"
                  // input={<OutlinedInput label="Aritst Name" />}
                  // MenuProps={MenuProps}
                  >
                    {subject &&
                      subject.map((val) => (
                        <MenuItem value={val._id} key={val._id}>
                          {val.name}
                        </MenuItem>
                      ))}
                  </Select> */}
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <div className={styles.search_action}>
                  <lable className={styles.labels}>Year*</lable>
                  <br />
                  <AnotherSelect
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    options={yearOptions}
                    labelId="select-mutli-artist"
                    id="demo-select-artist"
                    value={yearOptions.find(
                      (option) => option.value === props.uploadArt.year
                    )}
                    fullWidth
                    styles={customStyles}
                    placeholder="Select year"
                    onChange={(selectOption) =>
                      props.setUploadArt({
                        ...props.uploadArt,
                        year: selectOption.label,
                      })
                    }
                    name="year"
                  />

                  {secondFormError?.year ? (
                    <span
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {secondFormError?.year}
                    </span>
                  ) : null}
                </div>
              </Grid>
            </Grid>
            <Grid
              container
              columnSpacing={{ sm: 2 }}
              columns={{ xs: 12, sm: 12, md: 12 }}
            >
              <Grid item xs={12} sm={6} md={6}>
                <div className={styles.search_action}>
                  <lable className={styles.labels}>Materials*</lable>
                  <br />
                  <AnotherSelect
                    closeMenuOnSelect={true}
                    components={{
                      ...animatedComponents,
                      MultiValueRemove: CustomMultiValueRemove,
                      MultiValueContainer: CustomMultiValueContainer,
                    }}
                    options={materialOptions}
                    labelId="select-mutli-artist"
                    id="demo-select-artist"
                    isMulti
                    value={props.uploadArt.material_id.map(({ _id }) => {
                      const material = materialOptions?.find(
                        (option) => option.value === _id
                      );
                      return {
                        value: _id,
                        label: material ? material.label : "",
                      };
                    })}
                    fullWidth
                    styles={customStyles}
                    placeholder="Select material"
                    onChange={handleMaterialChange}
                    name="material_id"
                  />
                  {secondFormError?.material_id ? (
                    <span
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {secondFormError?.material_id}
                    </span>
                  ) : null}
                  {maxError.material_id && (
                    <span
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      Maximum of 5 materials can be selected
                    </span>
                  )}

                  <div className={styles.material_list_main}>
                    {material &&
                      material
                        .filter(
                          (vl) =>
                            selected_material &&
                            selected_material.includes(vl._id)
                        )
                        .map((val) => (
                          <div className={styles.material_list_div}>
                            <Typography>{val.name}</Typography>
                            <ClearIcon
                              onClick={() => handleRemoveMaterial(val._id)}
                            />
                          </div>
                        ))}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <div className={styles.search_action}>
                  <lable className={styles.labels}>Styles*</lable>
                  <br />
                  <AnotherSelect
                    closeMenuOnSelect={true}
                    components={{
                      ...animatedComponents,
                      MultiValueRemove: CustomMultiValueRemove,
                      MultiValueContainer: CustomMultiValueContainer,
                    }}
                    options={styleOptions}
                    labelId="select-mutli-artist"
                    id="demo-select-artist"
                    isMulti
                    value={props.uploadArt.style_id.map(({ _id }) => {
                      const style = styleOptions?.find(
                        (option) => option.value === _id
                      );
                      return { value: _id, label: style ? style.label : "" };
                    })}
                    fullWidth
                    styles={customStyles}
                    placeholder="Select style"
                    onChange={handleStylesChange}
                    name="style_id"
                  />
                  {secondFormError?.style_id ? (
                    <span
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {secondFormError?.style_id}
                    </span>
                  ) : null}

                  {maxError.style_id && (
                    <span
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      Maximum of 5 styles can be selected
                    </span>
                  )}
                  <div className={styles.material_list_main}>
                    {style &&
                      style
                        .filter(
                          (vl) =>
                            selected_style && selected_style.includes(vl._id)
                        )
                        .map((val) => (
                          <div className={styles.material_list_div}>
                            <Typography>{val.name}</Typography>
                            <ClearIcon
                              onClick={() => handleRemoveStyle(val._id)}
                            />
                          </div>
                        ))}
                  </div>
                </div>
              </Grid>
              {!["Sculpture", "Crafts", "Installation"].includes(
                props.category
              ) ? (
                <Grid item xs={12} sm={6} md={6}>
                  <div className={styles.search_action}>
                    <lable className={styles.labels}>Orientation*</lable>
                    <br />
                    <AnotherSelect
                      closeMenuOnSelect={true}
                      components={animatedComponents}
                      options={orientationOptions}
                      labelId="select-mutli-artist"
                      id="demo-select-artist"
                      value={selectedOrientation}
                      fullWidth
                      styles={customStyles}
                      placeholder="Select orientation"
                      onChange={props.handleChangeOrientation}
                      name="orientation"
                    />
                    {secondFormError?.orientation ? (
                      <span
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          fontSize: "12px",
                        }}
                      >
                        {secondFormError?.orientation}
                      </span>
                    ) : null}
                  </div>
                </Grid>
              ) : null}
              <Grid item xs={12} sm={6} md={6}>
                <div className={styles.search_action}>
                  <lable className={styles.labels}>Colors*</lable>
                  <br />
                  <div className={styles.container}>
                    <div className={styles.palette}>
                      {colordt &&
                        colordt.map((val, index) => (
                          <div
                            key={index}
                            style={{
                              backgroundColor: val?.name ? val?.name : "",
                            }}
                            className={`${styles.a_palette_color} ${
                              props.uploadArt?.colors.includes(val?._id)
                                ? styles.active
                                : ""
                            }`}
                            onClick={() => handleClick(val?._id)}
                          ></div>
                        ))}
                    </div>
                    {secondFormError?.colors ? (
                      <span
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          fontSize: "12px",
                        }}
                      >
                        {secondFormError?.colors}
                      </span>
                    ) : null}
                  </div>
                  {/* <div className={styles.material_list_main}>
                    {style &&
                      style
                        .filter(
                          (vl) =>
                            selected_style && selected_style.includes(vl._id)
                        )
                        .map((val) => (
                          <div className={styles.material_list_div}>
                            <Typography>{val.name}</Typography>
                            <ClearIcon
                              onClick={() => handleRemoveStyle(val._id)}
                            />
                          </div>
                        ))}
                  </div> */}
                  {/* <Select
                    labelId="select-mutli-artist"
                    id="demo-select-artist"
                    //   value={uploadarts.category_id === [] ? [] : uploadarts.category_id.map((val) => val._id)}
                    value={props.uploadArt.style_id.length === 0 ? [] : props.uploadArt.style_id.map((val) => val._id)}
                    fullWidth
                    // multiple
                    sx={{
                      background: "#f6f6f6",
                      ".MuiOutlinedInput-notchedOutline": {},
                      "& .MuiInputBase-root": {
                        borderRadius: "6px",
                      },
                      mt: 0.8,
                      //    height: "40px",
                    }}
                    placeholder="select type"
                    //   onChange={handleSelectCategory}
                    onChange={props.handleChangedata}
                    name="style_id"
                  // input={<OutlinedInput label="Aritst Name" />}
                  // MenuProps={MenuProps}
                  >
                    {style &&
                      style
                        //   .filter((fl) => {
                        //    return fl.is_accepted === "Approved";
                        //   })
                        .map((val) => (
                          <MenuItem value={val._id} key={val._id}>
                            {val.name}
                          </MenuItem>
                        ))}
                  </Select> */}
                </div>
              </Grid>
              {/* <Grid item xs={12} sm={6} md={6}>
                <div className={styles.search_action}>
                  <lable className={styles.labels}>Item Type</lable>
                  <br />
                  <Select
                    labelId="select-mutli-artist"
                    id="demo-select-artist"
                    //   value={uploadarts.category_id === [] ? [] : uploadarts.category_id.map((val) => val._id)}
                    value={props.uploadArt.item_type}
                    fullWidth
                    //  multiple
                    sx={{
                      background: "#f6f6f6",
                      ".MuiOutlinedInput-notchedOutline": {},
                      "& .MuiInputBase-root": {
                        borderRadius: "6px",
                      },
                      mt: 0.8,
                      //    height: "40px",
                    }}
                    placeholder="select type"
                    onChange={props.handleChangedata}
                    //  onChange={props.handleChangedataCategories}
                    name="item_type"
                  // input={<OutlinedInput label="Aritst Name" />}
                  // MenuProps={MenuProps}
                  >
                    {itemTypes &&
                      itemTypes
                        //   .filter((fl) => {
                        //    return fl.is_accepted === "Approved";
                        //   })
                        .map((val) => (
                          <MenuItem value={val._id} key={val._id}>
                            {val.name}
                          </MenuItem>
                        ))}
                  </Select>
                </div>
              </Grid> */}
              {/* ))} */}
            </Grid>
            <div className={styles.dimensions_div}>
              <label className={styles.labels}>Dimensions*</label>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="dimensions"
                  value="cm"
                >
                  <FormControlLabel
                    className={styles.radio_label}
                    value="cm"
                    control={<Radio size="small" />}
                    label="Cm"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <Grid
              container
              columnSpacing={{ sm: 2 }}
              sx={{ mt: -2 }}
              columns={{ xs: 12, sm: 12, md: 12 }}
            >
              <Grid item xs={12} sm={4} md={4}>
                <div className={styles.search_action}>
                  <TextField
                    onChange={props.handleChangedata}
                    type="text"
                    value={props.uploadArt.width}
                    sx={{
                      width: "100%",
                      mt: 0.8,
                      backgroundColor: "#f6f6f6",
                      borderColor: "#ccc",
                    }}
                    placeholder="Width"
                    name="width"
                  />
                  {secondFormError?.width ? (
                    <span
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {secondFormError?.width}
                    </span>
                  ) : null}
                </div>
              </Grid>

              <Grid item xs={12} sm={4} md={4}>
                <div className={styles.search_action}>
                  <TextField
                    onChange={props.handleChangedata}
                    value={props.uploadArt.height}
                    type="text"
                    sx={{
                      width: "100%",
                      mt: 0.8,
                      backgroundColor: "#f6f6f6",
                      borderColor: "#ccc",
                    }}
                    placeholder="Height"
                    name="height"
                  />
                  {secondFormError?.height ? (
                    <span
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {secondFormError?.height}
                    </span>
                  ) : null}
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <div className={styles.search_action}>
                  <TextField
                    onChange={props.handleChangedata}
                    value={props.uploadArt.depth}
                    type="text"
                    sx={{
                      width: "100%",
                      mt: 0.8,
                      backgroundColor: "#f6f6f6",
                      borderColor: "#ccc",
                    }}
                    placeholder="Depth"
                    name="depth"
                  />
                  {secondFormError?.depth ? (
                    <span
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {secondFormError?.depth}
                    </span>
                  ) : null}
                </div>
              </Grid>
            </Grid>
            <div className={styles.medium_div}>
              <div className={styles.labels}>Medium*</div>
              <div className={styles.medium_child}>                
                <label className={styles.labels}>Signed* </label>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    value={props.uploadArt.is_signed}
                    onChange={props.handleChangedata}
                    name="is_signed"
                  >
                    <FormControlLabel
                      className={styles.radio_label}
                      sx={{ m: 0.5 }}
                      value="Yes"
                      control={<Radio size="small" sx={{ m: 0, p: 0.3 }} />}
                      label="Yes"
                    />
                    <FormControlLabel
                      sx={{ m: 0.5 }}
                      className={styles.radio_label}
                      value="No"
                      control={<Radio size="small" sx={{ m: 0, p: 0.3 }} />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            <AnotherSelect
              closeMenuOnSelect={true}
              components={{
                ...animatedComponents,
                MultiValueRemove: CustomMultiValueRemove,
                MultiValueContainer: CustomMultiValueContainer,
              }}
              options={mediumOptions}
              labelId="select-mutli-artist"
              id="demo-select-artist"
              isMulti
              value={props.uploadArt.medium_id.map(({ _id }) => {
                const medium = mediumOptions?.find(
                  (option) => option.value === _id
                );
                return { value: _id, label: medium ? medium.label : "" };
              })}
              fullWidth
              styles={customStyles}
              placeholder="Select medium"
              onChange={handleMediumChange}
              name="medium_id"
            />
            {secondFormError?.medium_id ? (
              <span
                style={{ color: "red", fontWeight: "bold", fontSize: "12px" }}
              >
                {secondFormError?.medium_id}
              </span>
            ) : null}
            {maxError.medium_id && (
                    <span
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      Maximum of 5 medium can be selected
                    </span>
                  )}
            <div className={styles.material_list_main}>
              {medium &&
                medium
                  .filter(
                    (vl) => selected_medium && selected_medium.includes(vl._id)
                  )
                  .map((val) => (
                    <div className={styles.material_list_div}>
                      <Typography>{val.name}</Typography>
                      <ClearIcon onClick={() => handleRemoveMedium(val._id)} />
                    </div>
                  ))}
            </div>
            <div className={styles.keyword_div}>
              <label className={styles.labels}>Keywords*</label>
              <br />
              <TextField
                fullWidth
                sx={{ background: "#f6f6f6", borderColor: "#ccc", mt: 1 }}
                placeholder="Enter your keywords"
                onKeyDown={handleKeywordChange}
                onChange={handleChangeKeyword}
                // value={uploadarts.data ? uploadarts.data.keyword : uploadarts.keyword}
                value={keywordValue}
                name="keywords"
              />
              {secondFormError?.keywords ? (
                <span
                  style={{ color: "red", fontWeight: "bold", fontSize: "12px" }}
                >
                  {secondFormError?.keywords}
                </span>
              ) : null}
              <div className={styles.material_list_main}>
                {props.uploadArt.keywords &&
                  props.uploadArt.keywords.map((val) => (
                    <div className={styles.material_list_div}>
                      <Typography>{val}</Typography>
                      <ClearIcon onClick={() => handleRemoveKeyword(val)} />
                    </div>
                  ))}
              </div>

              <p className={styles.para}>
                Tagging your artwork with keywords allows collectors to find
                your artwork more easily. It’s best to enter simple, descriptive
                words that describe the key visual elements of the work, such as
                color, subject matter, and artistic style. You may enter or
                paste a comma separated list of keywords that are distinct and
                at least 2-character long. We recommend providing keywords in
                English.
              </p>
            </div>
            <div className={styles.textarea_div}>
              <label className={styles.labels} style={{ marginBottom: "10px" }}>
                Description*
              </label>
              <br />
              <TextareaAutosize
                maxRows={5}
                minRows={5}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": { borderColor: "#ccc" },
                  "&::after": {
                    borderBottom: "none",
                  },
                }}
                className={styles.textarea}
                onChange={props.handleChangedata}
                value={props.uploadArt.description}
                name="description"
                fullWidth
                placeholder="Write here"
              />
              {secondFormError?.description ? (
                <span
                  style={{ color: "red", fontWeight: "bold", fontSize: "12px" }}
                >
                  {secondFormError?.description}
                </span>
              ) : null}
              <p className={styles.para}>
                Collectors tend to appreciate works more if they know the
                “story” behind them, so be sure to write informative artwork
                descriptions. Great descriptions not only provide useful
                information (e.g. physical texture, whether hanging hardware is
                included, quality of materials
              </p>
            </div>
            <div className={styles.btn_div}>
              <Button
                onClick={() => {
                  if (window.location.pathname === "/upload/stepsecond") {
                    handleNavigateNext("/upload/stepthird");
                  } else {
                    handleNavigateNext("/edit/stepthird");
                  }
                }}
                variant="contained"
                sx={{ width: "156px", height: "54px", borderRadius: "10px" }}
              >
                Continue
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default React.memo(Commonuploadartsecond);
