import { Avatar, Button, Grid, Typography, Container } from "@mui/material";
import React, { useEffect } from "react";
import styles from "./ProdDetsOther.module.css";
import homeImage from "../../../constant";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUserArtist,
  handelAddFollow,
  handelUnFollow,
} from "../../../State/api/userApi";
import CircularLoader from "../../../common/loader/Loader";
import serverimage from "../../../serverConstant";
import { useNavigate } from "react-router-dom";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import striptags from "striptags";
import { Errornotify, Successnotify } from "../../../common/alert/Alert";
import api from "../../../State/api/Api";
import { RiStarFill} from "react-icons/ri";
import {FiUser, FiThumbsUp, FiMapPin } from "react-icons/fi";

function ProdDetsOther(props) {

  const othercardImage = [
    {
      id: 1,
      imgUrl: "/img/Rectangle 22.png",
      artistImg: "/img/Ellipse 10.png",
    },
    {
      id: 2,
      imgUrl: "/img/Rectangle 26.png",
      artistImg: "/img/Ellipse 12.png",
    },
    {
      id: 3,
      imgUrl: "/img/Rectangle 27.png",
      artistImg: "/img/Ellipse 12.png",
    },
    {
      id: 4,
      imgUrl: "/img/Rectangle 28.png",
      artistImg: "/img/Ellipse 12.png",
    },
  ];
  const tagContent = [
    {
      id: 1,
      text: "#summer",
    },
    {
      id: 2,
      text: "#sunset",
    },
    {
      id: 3,
      text: "#cowparsley ",
    },
    {
      id: 4,
      text: "#evening ",
    },
    {
      id: 5,
      text: "#flowers ",
    },
    {
      id: 6,
      text: "#woods",
    },
    {
      id: 7,
      text: "#meadow",
    },
    {
      id: 8,
      text: "#flowers",
    },
    {
      id: 9,
      text: "#woods",
    },
    {
      id: 10,
      text: "#meadow",
    },
  ];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetail = useSelector((state) => state.buyerdetail.data)
  const userartists = useSelector((state) => state.userartistData);
  const loader = useSelector((state) => state.loader);
  
  useEffect(() => {
    dispatch(fetchUserArtist({ is_accepted: "Approved" }));
  }, []);
  const handleAddFollowArtist = (type, id, artist_name) => {
    const user_id = localStorage.getItem("user_id")
    if (!user_id) {
      Errornotify("Please Login First")
    } else if (type === "follow") {
      dispatch(handelAddFollow({ user_id: id }, artist_name))
    } else {
      dispatch(handelUnFollow({ user_id: id }, artist_name))
    }
  }

  const handleLikeArtist = async (id) => {
    const data = await api.applicationApi(`api/artist/likeArtist/${id}`,"PATCH")
    if(data.status === 201 || data.status === 200){
      Successnotify(data.data.message)
      setTimeout(() => {
        window.location.reload()
      },200)
    }else {
      Errornotify(data.data.message)
    }
  }
  return (
    <div className={styles.proddets__bottom}>
      <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
      {loader.loader ? (
        <div className={styles.loader}>
          <CircularLoader />
        </div>
      ) : (
        userartists.data &&
        userartists.data
          .filter((item) => {
            return props.artist_id && props.artist_id.includes(item.user_id);
          })
          .map((val, i) => (
            <Grid container key={val._id} spacing={2}>
              <Grid item xs={12} sm={4} md={3}>
                <div className={styles.otherartistinfo}>
                  <img
                  onClick={() => navigate(`/user/artist/${val?._id}`)}
                    className={styles.otherartistImg}
                    src={ val.image}
                    alt="img"
                  />
                  <div className={styles.artistinfo}>
                    <a className={styles.artisname} href={`/user/artist/${val?._id}`}>
                        {val.artist_name}
                     </a>
                    <div className={styles.artistRating}>
                    <ul className={styles.stratinglst}>
                              <li><RiStarFill className={styles.sticon} style={{color:"#FFD51A",opacity:"1"}}/></li>
                              <li><RiStarFill className={styles.sticon}  style={{color:"#FFD51A",opacity:"1"}}/></li>
                              <li><RiStarFill className={styles.sticon}  style={{color:"#FFD51A",opacity:"1"}}/></li>
                              <li><RiStarFill className={styles.sticon} /></li>
                              <li><RiStarFill  className={styles.sticon}/></li>
                            </ul>
                    </div>
                    <p>
                        Ask Question
                      </p>
                    <ul className={styles.arstlik}>
                      <li>
                      <span onClick={() => handleLikeArtist(val?._id)}>
                        <FiThumbsUp className={styles.icst}
                        />{" "}
                      </span>
                      {props.productdetails?.artistDetail?.likes.length || 0}
                      </li>
                      <li>
                      <span>
                        <FiUser className={styles.icst}/>{" "}
                      </span>
                      0
                      </li>
                    </ul>
                    
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={8} md={9}>
                <div className={styles.otherartistdescr}>
                  <div className={styles.loc}>
                    <p><FiMapPin/> <span> {val.country}</span></p>
                    {userDetail &&
                      userDetail.data.followings &&
                      userDetail.data.followings
                        .map((val) => val._id?._id)
                        .includes(localStorage.getItem("user_id")) ? (
                      <button
                        className={styles.folbtn}
                        onClick={() =>
                          handleAddFollowArtist(
                            "unfollow",
                           localStorage.getItem("user_id"),
                            val?.artist_name
                          )
                          // dispatch(
                          //   handelUnFollow({
                          //     user_id: localStorage.getItem("user_id"),
                          //   })
                          // )
                        }
                      >
                        Unfollow
                      </button>
                    ) : (
                      <button
                      className={styles.folbtn}
                        onClick={() =>
                          handleAddFollowArtist(
                            "follow",
                           localStorage.getItem("user_id"),
                            val?.artist_name
                          )
                        }
                      >
                        Follow
                      </button>
                    )}
                  </div>
                  <p>
                    {striptags(val?.about)}
                  </p>
                </div>
              </Grid>
            </Grid>
          ))
      )}
      <div className={styles.otherarstwok}>
        <h3>Other Artist</h3>
        {loader.loader ? (
        <div className={styles.loader}>
          <CircularLoader />
        </div>
      ) : (
        <Grid container spacing={2}>
          {userartists.data &&
            userartists.data
              .filter((item) => {
                return (
                  props.artist_id &&
                  !props.artist_id.includes(item.user_id) &&
                  item.is_accepted === "Approved"
                );
              })
              .map((val, i) =>
                i >= 4 ? (
                  ""
                ) : (
                  <Grid key={val._id} item xs={12} sm={6} md={3}>
                    <div className={styles.otherartistcard}>
                      <div className={styles.otheim}>
                      <img
                        className={styles.othercardimage}
                        src={
                          val.backgroundImage
                            ?  val.backgroundImage
                            : "/img/artist_bg_alt.jpeg"
                        }
                        alt="img"
                      />
                      </div>
                      
                      <div className={styles.otherartistimage}>
                        <img
                          className={styles.ellipseten}
                          onClick={() => navigate(`/user/artist/${val._id}`)}
                          src={
                            val.image
                              ?  val.image
                              : "/img/artist_profile.png"
                          }
                          alt="img"
                        />
                      </div>
                      <div className={styles.otherartistcardinfo}>
                        <h4>
                          {val.artist_name}
                        </h4>
                        {/* <div className={styles.otherartistchat}>
                          <img src={"/img/Chat icon.png"} alt="img" />
                          <a>
                            <Typography color="#636365" fontSize="0.85rem">
                              Chat
                            </Typography>
                          </a>
                        </div> */}
                      </div>
                      <div className={styles.otherartistprice}>
                        <h5>
                          {val?.art_price_range[0]}
                        </h5>
                        {/* <Typography color="#636365" fontSize="0.85rem">
                          Delivery Charges: $50
                        </Typography> */}
                      </div>
                    </div>
                  </Grid>
                )
              )}
        </Grid>
      )}
      </div>
      
      <div className={styles.keywordsec}>
        <h3>Keywords</h3>
        <ul className={styles.keywlist}>
        {props.productdetails?.artwork.map((item) => (
            <>
              {item?.keywords.split(",")?.map((val) => ( 
              <li>{val}
                </li>
                ))}
           </>
            ))}
        </ul>
      </div>
      </Container>
      
    </div>
  );
}

export default ProdDetsOther;
