import React, { lazy } from "react"
import { Route, Routes } from "react-router-dom"
import Home from "./common/home/Home"

import CuratorArtdetail from "./curator/pages/artPage/artDetail/CuratorArtdetail"
import UserMain from "./UserMain"
import Artistprofile from "./components/Pages/List/Artist/Artistprofile/Artistprofile"
import { useSelector } from "react-redux"

import CuratorArtistprofile from "./curator/pages/artist/profile/CuratorArtistprofile"
import CuratorExhibitiondetails from "./components/Pages/List/Curator/CuratorExhibitionDetails/CuratorExhibitionDetails"
import CuratorTestimonial from "./components/Pages/List/Curator/Testimonials/CuratorTestimonials"
import CuratorAuctionDetails from "./curator/pages/auction/details/CuratorAuctionDetails"
const CuratorAccount = lazy(() =>
  import("./curator/pages/account/CuratorAccount")
)

const CuratorArtist = lazy(() => import("./curator/pages/artist/CuratorArtist"))
const CuratorGallery = lazy(() => import("./curator/pages/gallery/CuratorGallery"))

const CuratorArt = lazy(() => import("./curator/pages/artPage/CuratorArt"))
const CuratorAuction = lazy(() =>
  import("./curator/pages/auction/CuratorAuction")
)
const Curatordashboard = lazy(() =>
  import("./curator/pages/dashboard/CuratorDashboard")
)
const Earning = lazy(() => import("./curator/pages/earning/Earning"))
const CuratorExhibition = lazy(() =>
  import("./curator/pages/exhibition/CuratorExhibition")
)

const CuratorNotification = lazy(() =>
  import("./curator/pages/notification/CuratorNotification")
)
const CuratorOrder = lazy(() => import("./curator/pages/order/CuratorOrder"))
const CuratorOrderDetail = lazy(() =>
  import("./curator/pages/order/orderDetails/OrderDetail")
)

export default function Curatormain() {
  // const Navigate = useNavigate();
  const user = useSelector((state) => state.user.data)
  return (
    <Routes>
      {user.is_accepted === "Pending" ? (
        <>
          <Route path="*" element={<UserMain />} />

          <Route path="/home" element={<Home user="Curator" />} />
        </>
      ) : (
        <>
          <Route path="/dashboard" element={<Curatordashboard />} />
          <Route path="/myaccount" element={<CuratorAccount />} />
          <Route path="/earnings" element={<Earning />} />
          <Route
            path="/curator-testimonials"
            element={<CuratorTestimonial />}
          />
          <Route path="/notification" element={<CuratorNotification />} />
          <Route path="/orders" element={<CuratorOrder />} />
          <Route path="/art" element={<CuratorArt />} />
          <Route path="/artists" element={<CuratorArtist />} />
          <Route path="/gallery" element={<CuratorGallery />} />

          <Route path="/artist/:id" element={<Artistprofile />} />
          <Route path="/exhibitions" element={<CuratorExhibition />} />
          <Route path="/auction" element={<CuratorAuction />} />

          {/* <Route path='*' element={<Navigate to="/" />} /> */}
          {/* <Route path="/artist/:id" element={<ArtistProfileCurator />} /> */}
          <Route path="/art/:id" element={<CuratorArtdetail />} />
          <Route path="/auction/:id" element={<CuratorAuctionDetails />} />
          <Route path="/orders/:id" element={<CuratorOrderDetail />} />
          {/* <Route path='/orders/details/:id' element={<} */}
          {/* <Route path="/exhibitions/:id" element={<CuratorAcceptreject />} /> */}
          <Route
            path="/exhibitions/:id"
            element={<CuratorExhibitiondetails />}
          />
          <Route path="/artist/:id" element={<Artistprofile />} />
          <Route path="*" element={<UserMain />} />
          <Route
            path="/artists/:id"
            element={<CuratorArtistprofile />}
          />
        </>
      )}
    
    </Routes>
  )
}
