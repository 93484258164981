import React, { useEffect } from "react";
import Header from "../Pages/Header/Header";
import Footer from "../Pages/Footer/Footer";
import Navbar from "../Pages/Navbar/Navbar";
import Sidebar from "../Pages/Sidebar/Sidebar";
import styles from "./Layout.module.css";
import Popupfeatured from "../Pages/List/Artist/Popups/Popupfeatured";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import CuratorSidebar from "../../curator/sideBar/CuratorSidebar";
import Gallerysidebar from "../../gallery/sidebar/Gallerysidebar";
import Sidebarartist from "../../artist/pages/sidebarArtist/Sidebarartist";
import Sidebaraffiliate from "../../affiliate/sidebar/SIdebaraffiliate";
import UserMain from "../../UserMain";
import { useDispatch, useSelector } from "react-redux";
import { removeUser, setUser } from "../../State/Features/common/userSlice";
import UserSidebar from "../Pages/User/User Profile/User sidebar/UserSidebar";
import {
  Container, Grid
} from "@mui/material";
const Layout = ({ children, usertype }) => {
  const location = useLocation();
  const path = location.pathname.includes("/artsmiley");
  const userpath = location.pathname.includes("/user/");
  const userpath_ = location.pathname.includes("");
  const userpathUploadArt = location.pathname.includes("/upload/") || location.pathname.includes('/edit');

  const userOrderDetailsPath = location.pathname.includes("/userorder/details");
  const homepath = location.pathname;

  const usertypes = localStorage.getItem("usertype");
  const is_accepted = localStorage.getItem("is_accepted");
  const userty = useSelector((state) => state.user.data);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location.pathname]);
  return (
    <>
      {path ? (
        <>{children}</>
      ) : (
        <>
          <Header />
          {userty.signed ? (
            userty.signed && (userpath || userpath_) ? (
              <Navbar />
            ) : usertypes === "buyer" ? (
              <Navbar />
            ) : (
              ""
            )
          ) : (
            <Navbar />
          )}
          {userpath || homepath === "/" ? (
            children
          ) : (
            <section className={styles.headrtop}>
<Container  maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
             
             <Grid container spacing={2} className={
                userty.signed
                  ? usertype === "buyer" && !userpath
                    ? styles.curator_children 
                    : is_accepted === "Pending"
                    ? styles.curator_childrens
                    : usertype === ""
                    ? ""
                    : styles.curator_children
                  : ""
              }>
                
                <Grid item xs={12} sm={3} md={2}>
                {userty.signed && usertype === "buyer" ? (
                 
                 <UserSidebar />
               
             ) : is_accepted === "Pending" ? (
               ""
             ) : usertype === "curator" ? (
               <CuratorSidebar />
             ) : usertype === "gallery" ? (
               userpathUploadArt ? (
                 ""
               ): 
              (
               <Gallerysidebar />
              )
             ) : usertype === "superadmin" ? (
               <Sidebar />
             ) : usertype === "artist" ? (
               userpathUploadArt ? (
                 ""
               ) : (
                 <Sidebarartist />
               )
             ) : usertype === "affiliate" ? (
               <Sidebaraffiliate />
             ) : (
               ""
             )}
                </Grid>
                <Grid item xs={12} sm={9} md={9}>
                {children}
                </Grid>
              </Grid>

               
            </Container>
            </section>

          )}
          <Footer />
        </>
      )}
    </>
  );
};
export default Layout;