import { Box, Button } from "@mui/material"
import styles from "./Pagination.module.css"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { nextPage } from "../../../../State/Features/common/paginationSlice"
import homeImage from "../../../../constant"
import isValid from "date-fns/fp/isValid"

export default function Pagination({ totaldata, pagination, setPagination }) {
  const dispatch = useDispatch()
  // const pagination = useSelector((state) => state.pagination)
  const [activebtn, setActivebtn] = useState(1)

  const handlePreviouspage = () => {
    // if ((state.offset - state.limit) >= 0) {
    // setState({
    //   ...state,
    //   offset: state.offset - state.limit
    // })
    // }
  }
  const handleNextpage = (val) => {
    window.scrollTo({ top: 0, behavior: "smooth" })
    if (val === -1) {
      setActivebtn(val)
      setPagination({ ...pagination, offset: pagination.offset + 1 })
    } else {
      setActivebtn(val)
      setPagination({ ...pagination, offset: val })
    }
  
  }
  return (
    <div className={styles.mains}>
      <Box className={styles.pagination_box} sx={{}}>
        <div>
          <Button
            variant={activebtn === 1 ? "contained" : ""}
            onClick={() => handleNextpage(1)}
            // color="primary"
            className={styles.page_btn}
            sx={{
              minWidth: "20px",
              backgroundColor: activebtn === 1 ? "" : "#FFFFFF",
              border: activebtn === 1 ? "" : "0.841897px solid #d5d5d5",
            }}
          >
            1
          </Button>
          <Button
            disabled={totaldata <= 10}
            onClick={() => handleNextpage(2)}
            className={styles.page_btn}
            sx={{
              ml: 1,
              minWidth: "35px",
              height: "29.47px",
              backgroundColor: activebtn === 2 ? "" : "#FFFFFF",
              border: activebtn === 2 ? "" : "0.841897px solid #d5d5d5",
              boxShadow: "none",
            }}
            variant={activebtn === 2 ? "contained" : ""}
          >
            2
          </Button>
          <Button
            disabled={totaldata <= 20}
            onClick={() => handleNextpage(3)}
            className={styles.page_btn}
            sx={{
              ml: 1,
              minWidth: "35px",
              height: "29.47px",
              backgroundColor: activebtn === 3 ? "" : "#FFFFFF",
              border: activebtn === 3 ? "" : "0.841897px solid #d5d5d5",
              boxShadow: "none",
            }}
            variant={activebtn === 3 ? "contained" : ""}
          >
            3
          </Button>
          <Button
            disabled={totaldata <= 30}
            onClick={() => handleNextpage(4)}
            sx={{
              ml: 1,
              minWidth: "35px",
              height: "29.47px",
              backgroundColor: activebtn === 4 ? "" : "#FFFFFF",
              border: activebtn === 4 ? "" : "0.841897px solid #d5d5d5",
              boxShadow: "none",
            }}
            variant={activebtn === 4 ? "contained" : ""}
            className={styles.page_btn}
          >
            4
          </Button>
          <Button
            disabled={totaldata <= 40}
            onClick={() => handleNextpage(5)}
            sx={{
              ml: 1,
              minWidth: "35px",
              height: "29.47px",
              backgroundColor: activebtn === 5 ? "" : "#FFFFFF",
              border: activebtn === 5 ? "" : "0.841897px solid #d5d5d5",
              boxShadow: "none",
            }}
            variant={activebtn === 5 ? "contained" : ""}
            className={styles.page_btn}
          >
            5
          </Button>
          {/* <Button
            disabled={totaldata <= 40}
            onClick={() => handleNextpage(6)}
            sx={{
              ml: 1,
              minWidth: "35px",
              height: "29.47px",
              backgroundColor: activebtn === 6 ? "" : "#FFFFFF",
              border: activebtn === 6 ? "" : "0.841897px solid #d5d5d5",
              boxShadow: "none",
            }}
            variant={activebtn === 6 ? "contained" : ""}
            className={styles.page_btn}
          >
            6
          </Button>
          <Button
            disabled={totaldata <= 40}
            onClick={() => handleNextpage(7)}
            sx={{
              ml: 1,
              minWidth: "35px",
              height: "29.47px",
              backgroundColor: activebtn === 7 ? "" : "#FFFFFF",
              border: activebtn === 7 ? "" : "0.841897px solid #d5d5d5",
              boxShadow: "none",
            }}
            variant={activebtn === 7 ? "contained" : ""}
            className={styles.page_btn}
          >
            7
          </Button>
          <Button
            disabled={totaldata <= 40}
            onClick={() => handleNextpage(8)}
            sx={{
              ml: 1,
              minWidth: "35px",
              height: "29.47px",
              backgroundColor: activebtn === 8 ? "" : "#FFFFFF",
              border: activebtn === 5 ? "" : "0.841897px solid #d5d5d5",
              boxShadow: "none",
            }}
            variant={activebtn === 8 ? "contained" : ""}
            className={styles.page_btn}
          >
            8
          </Button> */}
          <Button
            onClick={() => handleNextpage(-1)}
            className={styles.page_btn}
            disabled={
              totaldata < 50 || (totaldata > 50 && totaldata % 10 !== 0)
            }
            sx={{
              ml: 1,
              minWidth: "35px",
              height: "29.47px",
              backgroundColor: activebtn === -1 ? "" : "#FFFFFF",
              border: activebtn === -1 ? "" : "0.841897px solid #d5d5d5",
              boxShadow: "none",
            }}
            variant={activebtn === -1 ? "contained" : ""}
          >
            <img src={"/img/pagination_right.png"} />
          </Button>
          {/* </Box> */}
        </div>
      </Box>
    </div>
  )
}
