import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Button, Divider, IconButton, Menu, MenuItem, Select, Tooltip } from "@mui/material";
// import Divider from '@mui/material/Divider';
import React, { useState } from "react";
import styles from "./Sixcoltable.module.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import CircularLoader from "../../../common/loader/Loader";
import homeImage from "../../../constant";
import dateFormat from "../../../utils/dateFormat";
import TruncatedTooltipText from "../../../utils/truncateTooltip";

export default function Sixcoltable(props) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [delid, setDelid] = useState();

  const loader = useSelector((state) => state.loader);

  const threedots = Boolean(anchorEl);
  const handleClick = (row, e) => {
    setDelid(row);
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleProfilepage = (id) => {
    const path = window.location.pathname;
    if (path === "/exhibitionartist") {
      navigate(`/exhibitions/${id}`);
    } else if (path === "/exhibitionsgallery") {
      navigate(`/exhibitionsgallery/${id}`);
    }else if (path === "/auction") {
      navigate(`/auction/${id}`);
    }
  };
  return (
    <div className={styles.mains}>
      <TableContainer component={Paper} sx={{ backgroundColor: "#F6F6F6", color: "black" }}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#bfbbc3" }}>
              {props.head.map((column, i) => (
                <TableCell
                  align="left"
                  sx={{
                    whiteSpace: "nowrap",
                    fontFamily: "DM Sans",
                    fontWeight: "500",
                    fontSize: "16px",
                    height: "21px",
                    color: "#1C1C1C",
                  }}
                  key={i}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {loader.loader ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={8}>
                  <Box className={styles.loader_box}>
                    {" "}
                    <CircularLoader size={60} />
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {props.data && props.data.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={8}>
                    <Box className={styles.loader_box}> No Data Found</Box>
                  </TableCell>
                </TableRow>
              ) : (
                props.data &&
                props.data.map((row, i) => (
                  <TableRow value={row.artist_name} style={{ color: "#636365" }} key={i}>
                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      style={{ cursor: "pointer" }}
                      className={styles.table_column}
                      // onClick={() => handleProfilepage(row._id)}
                      align="left">
                    
                        <TruncatedTooltipText
      text={row.title}
      maxLength={20} // Maximum number of characters before truncation
      href={`/${window.location.pathname === "exhibitionartist" ? "exhibitionartist" :  "exhibitionsgallery"}/${row._id}`}// Link URL
       onClick={() => {
      // Replace this with your navigation logic
      window.location.href = `/${window.location.pathname === "/exhibitionartist" ? "exhibitionartist" :  "exhibitionsgallery"}/${row._id}`;
    }}
      style={{ color: "black" }} // Optional custom styles
    /> 
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                      value={row.artist_name}>     {props.activetab === "upcoming_gallery"
                        ? row.is_paid && row.is_paid === true
                          ? "Paid"
                          : "Free"
                        : " "}</TableCell>
                    {/* <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left">
                      {row.is_paid && row.is_paid === true ? "Paid" : "Free"}
                    </TableCell> */}
                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left">
                      {dateFormat(row.start_date && row.start_date.substring(0, 10))}
                    </TableCell>
                   {props.activetab === "request_gallery" &&   <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="right">
                      {row.artist_type}
                      {dateFormat(row.end_date && row.end_date.substring(0, 10))}
                    </TableCell>}
                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="center">
                      {dateFormat(row.end_date && row.end_date.substring(0, 10))}
                    </TableCell>
                    {props.activetab === "request_gallery" ? (
                      <TableCell
                        sx={{
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "21px",
                          color: "#219653",
                        }}
                        align="right">
                        Pending
                      </TableCell>
                    ) : (
                      <TableCell
                        sx={{
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "21px",
                        }}
                        align="right">
                        <div className={styles.interested}>
                          <span>  {dateFormat(row.end_date_registration && row.end_date_registration.substring(0, 10))}</span>
                          {props.activetab === 'my_exshibition' ?
                            <IconButton
                              onClick={() => props.handleDelete(row._id)}
                            >
                              <img
                                src="/img/delete.png"
                                width="20px"
                                height="20px"
                                alt='img'
                              />
                            </IconButton>
                            : ""}
                  

                        </div>
                      </TableCell>
                    )}
                    <TableCell align="left"     sx={{
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "21px",
                        }}>
                 {props.activetab === "upcoming_gallery" && (
  new Date(row.end_date_registration) > new Date() ? (
    <Button
      onClick={() => props.handleInterested(row._id)}
      disabled={row.artist_ids?.includes(localStorage.getItem("user_id"))}
      variant={row.artist_ids?.includes(localStorage.getItem("user_id")) ? "text" : "contained"}
      sx={{ width: "106px", height: "34px", borderRadius: "4px" }}
    >
      Interested
    </Button>
  ) : (
    <Button
      onClick={() => {}}
      disabled
      variant="contained"
      sx={{ width: "106px", height: "34px", borderRadius: "4px" }}
    >
      Interested
    </Button>
  )
)}

                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </div>
  );
}
