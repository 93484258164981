import {Grid, Link, Container, Select, Typography, FormControl, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./MeetOurCurators.module.css";
import CssBaseline from "@mui/material/CssBaseline";
import { useNavigate, useParams } from "react-router-dom"
import { RiStarSFill } from "react-icons/ri";
import ProductSidebar from "../ProductSidebar";
import { useDispatch, useSelector } from "react-redux";
function MeetOurCurators() {
    
const navigate = useNavigate()

  const handleNavigate = (route) => {
    navigate(route)
  }

  const [artistDetail, setArtistDetail] = useState();
    const [arts, setArts] = useState();
    const [style, setStyle] = useState([]);
    const dispatch = useDispatch();
    const [priceRange, setPriceRange] = useState([0, 50000]);
    const [value_height, setValueHeight] = useState([0, 1500]);
    const [value_width, setValueWidth] = useState([0, 1500]);
    const { id } = useParams();
    const [filteredValues, setFilteredValues] = useState({
      categoryName: [],
      styleName: "",
      style: "",
      colorName: "",
      artistTypeName: [],
      artistName: [],
      orientationName: "",
      startPrice: "0",
      endPrice: "50000",
      minHeight: 0,
      maxHeight: 1500,
      minWidth: 0,
      maxWidth: 1500,
      countryName: "",
      searchTitle: "",
    });
    const { option } = useParams();
     const [initialValueSet, setInitialValueSet] = useState(false);
      const loader = useSelector((state) => state.loader);
      const [searchTitle, setSearchTitle] = useState("");
      const filter = useSelector((state) => state.filteration);
      const [selectedSort, setSelectedSort] = useState("low");
      const setAllFilterValues = useSelector(
        (state) => state.filteration.allFilterValues
      );

     const productType = useSelector((state) => state.productType);
      const artistTypes = useSelector((state) => state.artistTypedata);
      const allcategory = useSelector((state) => state.allcategoryData);
      const userartists = useSelector((state) => state.userartistData);
      const userDetail = useSelector((state) => state.buyerdetail.data);
      const handleChangeFilteredValues = (e) => {
        if (e.target.name === "categoryName") {
        }
        setFilteredValues({ ...filteredValues, [e.target.name]: e.target.value });
        window.scrollTo({
          top: 120,
          behavior: "smooth",
        });
      };
  return (
    <>
      <CssBaseline />
      <section className={styles.headerCategory}>
      <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
      <div className={styles.pageitle}>
              <h1>Meet Our Curators</h1>
              <ul>
                <li><Link onClick={() => handleNavigate("/")}>Home /{" "} </Link></li>
                <li>Meet Our Curators</li>
              </ul>
            </div>
      </Container>
    </section>
      <section className={styles.curatorlist}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
        <h3 className={styles.abncr}>Our Curators</h3>
        <div className={styles.searchbarDiv}>
          <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={9}></Grid>
            <Grid item xs={12} sm={8} md={3}>
            <Grid container spacing={2}>
          <Grid item xs={3} sm={3} md={3}>
          <Typography mt={1} align="right">Sort by</Typography>
          </Grid>
          <Grid item xs={9} sm={9} md={9}>
          <FormControl sx={{ width: "100%" }} size="small">
              <Select
               
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                className="select"
                sx={{
                  color: "#636365",
                  border: "0.6px solid #665D6F",
                  borderRadius: "6px",
                
                }}
              >
                {/* <MenuItem value="" sx={{ color: "#636365" }}>
                  Best Seller
                </MenuItem> */}
                <MenuItem value="">Sort by</MenuItem>
                
              </Select>
            </FormControl>
          </Grid>
          </Grid>
            </Grid>
          </Grid>
        </div>
        <div className={styles.abutp}>
        <Grid container spacing={2}>
        <Grid item xs={12} sm={3} md={3}>
                  <ProductSidebar
                  value_height={value_height}
                  value_width={value_width}
                  setValueHeight={setValueHeight}
                  style={style}
                  setStyle={setStyle} 
                  filter={filter}
                  setFilter={filter} 
                  selectedOption={option}
                  setValueWidth={setValueWidth}
                  producttypeData={productType.data}
                  artisttypeData={artistTypes.data}
                  allartistData={userartists.data}
                  allcategoryData={allcategory.data && allcategory.data.allcategories}
                  setFilteredValues={setFilteredValues}
                  handleChangeFilteredValues={handleChangeFilteredValues}
                  filteredValues={filteredValues}
                />
                  </Grid>
        <Grid item xs={12} sm={9} md={9}>
        <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                      <div className={styles.otherartistcards}>
                        <div className={styles.otherartistcardmain}>
                          <div className={styles.Other_otheim}>
                          <img
                            className={styles.otherartimg}
                            src={"/img/Image 3.png"
                            } alt=""
                          />
                          </div>
                          <div className={styles.otherartistimaged}>
                            <img
                              className={styles.otherartistimg}
                              src={"/img/artist_profile.png" 
                              } alt=""
                            />
                          </div>
                          <div className={styles.Other_otherartist}>
                            <div className={styles.otherartistnamerating}>
                            <h4>anelaone</h4>
                            <div><h5><RiStarSFill className={styles.rticon} /> 3.5</h5></div>
                            </div>
                            <div className={styles.locvn}>
                            <p>Japan</p>
                              <p>Photographer</p>
                            </div>
                            <button
                            className={styles.exprlobtn}
                          >
                            Explore
                          </button>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <div className={styles.otherartistcards}>
                        <div className={styles.otherartistcardmain}>
                          <div className={styles.Other_otheim}>
                          <img
                            className={styles.otherartimg}
                            src={"/img/Image 3.png"
                            } alt=""
                          />
                          </div>
                          <div className={styles.otherartistimaged}>
                            <img
                              className={styles.otherartistimg}
                              src={"/img/artist_profile.png" 
                              } alt=""
                            />
                          </div>
                          <div className={styles.Other_otherartist}>
                            <div className={styles.otherartistnamerating}>
                            <h4>anelaone</h4>
                            <div><h5><RiStarSFill className={styles.rticon} /> 3.5</h5></div>
                            </div>
                            <div className={styles.locvn}>
                            <p>Japan</p>
                              <p>Photographer</p>
                            </div>
                            <button
                            className={styles.exprlobtn}
                          >
                            Explore
                          </button>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <div className={styles.otherartistcards}>
                        <div className={styles.otherartistcardmain}>
                          <div className={styles.Other_otheim}>
                          <img
                            className={styles.otherartimg}
                            src={"/img/Image 3.png"
                            } alt=""
                          />
                          </div>
                          <div className={styles.otherartistimaged}>
                            <img
                              className={styles.otherartistimg}
                              src={"/img/artist_profile.png" 
                              } alt=""
                            />
                          </div>
                          <div className={styles.Other_otherartist}>
                            <div className={styles.otherartistnamerating}>
                            <h4>anelaone</h4>
                            <div><h5><RiStarSFill className={styles.rticon} /> 3.5</h5></div>
                            </div>
                            <div className={styles.locvn}>
                            <p>Japan</p>
                              <p>Photographer</p>
                            </div>
                            <button
                            className={styles.exprlobtn}
                          >
                            Explore
                          </button>
                          </div>
                        </div>
                      </div>
                    </Grid>
          </Grid>
        </Grid>
        </Grid>
        </div>
        
        </Container>
      </section>
    </>
  );
}

export default MeetOurCurators;
