import React, { useEffect, useState } from "react"
import Popupfeatured from "../../../components/Pages/List/Artist/Popups/Popupfeatured"
import Enterdetails from "../../../components/Pages/List/Popupdelete/enterDetails/Enterdetails"
import Pagination from "../../../components/Pages/List/Pagination/Pagination"
import Tablesfivecol from "../../../components/Pages/List/Table/Fivecolumn/Tablefivecol"
import Tablesfour from "../../../components/Pages/List/Table/Fourcolumn/Tables"
import Moreoption from "../../../components/Pages/List/Table/Moreoption/Moreoption"
import Tableheader from "../../../components/Pages/List/Table/Tableheader/Tableheader"
import styles from "./Curatorgallery.module.css"

import { useDispatch, useSelector } from "react-redux"
import { addButton } from "../../../State/Features/common/tableheaderbuttonSlice"
import {
  acceptRejectGallery,
  fetchCuratorGalleryData,
  blockUnblockArtist,
  blockUnblockGallery,
} from "../../../State/api/superadminApi"

import { AlertsSuccess } from "../../../common/alert/Alert"
import { setCuratorGalleryData } from "../../../State/Features/superadmin/CuratorGallerySlice"


import dayjs from "dayjs"
import CuratorGallerycol from "../../../components/Pages/List/Table/Fivecolumn/CuratorGallerycol"
import CuratorGalleryFour from "../../../components/Pages/List/Table/Fourcolumn/CuratorGalleryFour"
import CuratorGalleryDecline from "../../../components/Pages/List/Table/Fourcolumn/CuratorGalleryDecline"

const moreOptions = [
  {
    id: "pending",
    label: "Pending",
    value: "pending",
  },
  {
    id: "approved",
    label: "Approved",
    value: "approved",
  },

  {
    id: "decline",
    label: "Decline",
    value: "decline",
  },
]

const pendinghead = [
  {
    id: "gallery_name ",
    label: "Gallery Name",
  },
  {
    id: "mobile_number",
    label: "Mobile Number",
  },
  {
    id: "email",
    label: "Email",
  },
 
  {
    id: "created_at",
    label: "Created At",
  },
  {
    id: "action",
    label: "Action",
  },
]
const approvedhead = [
    {
        id: "gallery_name ",
        label: "Gallery Name",
      },
  {
    id: "location",
    label: "Location",
  },
  {
    id: "contact_details",
    label: "Contact Details",
  },
  {
    id: "created_at",
    label: "Created At",
  },
  {
    id: "like",
    label: "Likes",
  },
  {
    id: "followe",
    label: "Followers",
  },

]
const featuredhead = [
  {
    id: "artist_name ",
    label: "Artist Name",
  },
  {
    id: "location",
    label: "Location",
  },
  {
    id: "like",
    label: "Likes",
  },
  {
    id: "followe",
    label: "Followers",
  },
]
const sortdata = [
  { label: "Latest ↓", value: "latest" },
  { label: "Oldest ↑", value: "oldest" },
];
const filterdata = [
  { label: "A to Z", value: "AZ" },
  { label: "Z to A", value: "ZA" },
 
];
export default function CuratorGallery() {
  const dispatch = useDispatch()
  const [button, setButton] = useState({ type: "", text: "Invite" })
  const [activetab, setAcitvetab] = useState("pending")
  const [poptype, setPoptype] = useState("")
  const [open, setOpen] = useState(false)
  const [inviteopen, setInviteOpen] = useState(false)
  const [data, setData] = useState({ is_accepted: "Pending", offset: 1 })
  const [condition, setCondition] = useState({
    search: "",
    sorting: "",
    filter: "",
  })

  useEffect(() => {
    dispatch(
      addButton({
        type: "add",
        text: "Add",
      })
    )
  }, [activetab])

  const curatorGalleyData = useSelector((state) => state.CuratorGalleryData.data)

   const handelBlockUnblockArtist = (id, dt) => {
      dispatch(blockUnblockGallery(id, { is_blocked: dt }, fetchData))
    }
 
  const fetchData = () => {
    dispatch(fetchCuratorGalleryData(data))
  }
  useEffect(() => {
    fetchData()
  }, [data])

  
  const handleMorecategories = (e) => {
    setAcitvetab(e)
  }
  
 

  const handleSearch = (e) => {
    let value = e.target.value
    setCondition({ ...condition, search: value })
  }


  const handleSorting = (event) => {
      const value = event.target.value;
  
      setCondition({ ...condition, sorting: value });
  
      if (value === "latest") {
        dispatch(
          setCuratorGalleryData({
            data:
            curatorGalleyData.data &&
              [...curatorGalleyData.data].sort(
                (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
              ),
            totalCount: curatorGalleyData.totalCount,
          })
        );
      } else if (value === "oldest") {
        dispatch(
          setCuratorGalleryData({
            data:
            curatorGalleyData.data &&
              [...curatorGalleyData.data].sort(
                (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
              ),
            totalCount: curatorGalleyData.totalCount,
          })
        );
      } else {
        fetchData();
      }
    };
 const handleFilter = (event) => {
     const value = event.target.value;
     setCondition({ ...condition, filter: value });
 
     let filteredData = [...curatorGalleyData.data];
 
     if (value === "AZ" || value === "ZA") {
       // Sort alphabetically without filtering by first letter
       filteredData = filteredData.sort((a, b) => {
         if (value === "AZ") {
           return a.first_name.localeCompare(b.first_name, undefined, {
             sensitivity: "base",
           });
         } else if (value === "ZA") {
           return b.first_name.localeCompare(a.first_name, undefined, {
             sensitivity: "base",
           });
         }
         return 0;
       });
     }
 
     dispatch(
       setCuratorGalleryData({
         data: filteredData,
         totalCount: curatorGalleyData.totalCount,
       })
     );
   };


   const handleAcceptDecline = (id, dt, subscriptionDetails) => {
       dispatch(acceptRejectGallery(id, { is_accepted: dt }, fetchData))
      
     }
 
  return (
    <div className={styles.mains}>
      <AlertsSuccess />
      {open ? (
        <Popupfeatured open={open} setOpen={setOpen} poptype={poptype} />
      ) : (
        ""
      )}
      {inviteopen ? (
        <Enterdetails
        
          poptype="curator_invite_artist"
          open={inviteopen}
          setOpen={setInviteOpen}
        />
      ) : (
        ""
      )}
    
      <div className={styles.components}>
        <Tableheader
          handleFilter={handleFilter}
          handleSorting={handleSorting}
          handleSearch={handleSearch}
          filterdata={filterdata}
          sortdata={sortdata}
          condition={condition}
          search={condition.search}
          poptype={poptype}
          button={button}
          setOpen={setOpen}
        />
        <div className={styles.moreoption_div}>
          <Moreoption
            setButton={setButton}
            setPoptype={setPoptype}
            activetab={activetab}
            handleMorecategories={handleMorecategories}
            moreOptions={moreOptions}
            setData={setData}
          />
        
        </div>
        {activetab === "pending" ? (
          <>
            <CuratorGallerycol
              activetab={activetab}
              setOpen={setOpen}
              setPoptype={setPoptype}
              head={pendinghead}
              handleAcceptDecline={handleAcceptDecline}
              data={
                curatorGalleyData &&
                curatorGalleyData.data
                  .filter((vl) => {
                    if (vl.is_accepted === "Pending") {
                      return vl;
                    }
                  })
                  .filter((val) => {
                    const fullName = `${val.first_name || ""} ${
                      val.last_name || ""
                    }`
                      .toLowerCase()
                      .trim();
                    const search = condition.search.toLowerCase().trim();

                    // Match conditions
                    const nameMatch =
                      val.first_name &&
                      val.first_name.toLowerCase().includes(search);
                    const lastnameMatch =
                      val.last_name &&
                      val.last_name.toLowerCase().includes(search);
                    const emailMatch =
                      val.email && val.email.toLowerCase().includes(search);
                    const fullNameMatch = fullName.includes(search);
                    const phoneMatch =
                      val.phone && val.phone.toLowerCase().includes(search);

                    // Curator Match Logic
                    const curatorMatch =
                      val.artist &&
                      val.artist[0] &&
                      val.artist[0].curator &&
                      Array.isArray(val.artist[0].curator) &&
                      val.artist[0].curator.some(
                        (cur) =>
                          cur.curator_name &&
                          cur.curator_name.toLowerCase().includes(search)
                      );

                    return (
                      nameMatch ||
                      lastnameMatch ||
                      emailMatch ||
                      fullNameMatch ||
                      phoneMatch ||
                      curatorMatch
                    );
                  })
                  .sort((a, b) => {
                    const artistA = a.artist && a.artist[0];
                    const artistB = b.artist && b.artist[0];

                    if (artistA && artistB) {
                      return (
                        new Date(artistB.createdAt) -
                        new Date(artistA.createdAt)
                      );
                    }

                    return 0;
                  })

                  .sort((a,b)=>{
                    if(condition.sorting === "latest"){
                      return new Date(b.createdAt) - new Date(a.createdAt);
                    }else if (condition.sorting === "oldest"){
                      return new Date(a.createdAt) - new Date(b.createdAt); // Sort by oldest
                    }
                    return 0; // Default case: no sorting
                  })

                  .sort((a,b)=>{
                    if(condition.filter === "AZ"){
                      return a.first_name.localeCompare(b.first_name, undefined, {
                        sensitivity: "base",
                      });
                    }else if(condition.filter === "ZA"){
                      return b.first_name.localeCompare(a.first_name, undefined, {
                        sensitivity: "base",
                      });
                    }
                    return 0;
                  })
              }
           
              
            />
          </>
        ) : activetab === "approved" ? (
          <>
            <CuratorGalleryFour
              activetab={activetab}
              head={approvedhead}
                 blockUnblockArtist={handelBlockUnblockArtist}
              data={
                curatorGalleyData &&
                curatorGalleyData.data
                  .filter((vl) => {
                    return vl.is_accepted === "Approved";
                  })
                  .filter((val) => {
                    // Combine first name and last name for full name matching
                    const fullName = `${val.first_name || ""} ${
                      val.last_name || ""
                    }`
                      .toLowerCase()
                      .trim();

                    // Trim and lowercase the search term for case-insensitive comparison
                    const search = condition.search.toLowerCase().trim();

                    // Individual match conditions
                    const fullNameMatch = fullName.includes(search);
                    const nameMatch =
                      val.first_name &&
                      val.first_name.toLowerCase().includes(search);
                    const lastnameMatch =
                      val.last_name &&
                      val.last_name.toLowerCase().includes(search);
                    const emailMatch =
                      val.email && val.email.toLowerCase().includes(search);
                    const phoneMatch =
                      val.phone && val.phone.toLowerCase().includes(search);

               

                    return (
                      nameMatch ||
                      lastnameMatch ||
                      fullNameMatch ||
                      
                      emailMatch ||
                      phoneMatch
                    );
                  })
                
                
            
              }
            />
          </>
        ) : activetab === "decline" ? (
          <>
            <CuratorGalleryDecline
            
             
              activetab={activetab}
              head={approvedhead}
              data={
                curatorGalleyData &&
                curatorGalleyData.data
                  .filter((vl) => {
                    return vl.is_accepted === "Rejected";
                  })

                  .filter((val) => {
                    // Combine first name and last name for full name matching
                    const fullName = `${val.first_name || ""} ${
                      val.last_name || ""
                    }`
                      .toLowerCase()
                      .trim();

                    // Trim and lowercase the search term for case-insensitive comparison
                    const search = condition.search.toLowerCase().trim();

                    // Individual match conditions
                    const fullNameMatch = fullName.includes(search);
                    const nameMatch =
                      val.first_name &&
                      val.first_name.toLowerCase().includes(search);
                    const lastnameMatch =
                      val.last_name &&
                      val.last_name.toLowerCase().includes(search);
                    const emailMatch =
                      val.email && val.email.toLowerCase().includes(search);
                    const phoneMatch =
                      val.phone && val.phone.toLowerCase().includes(search);

                    // Artist match logic, checking for nested values
                    const artistMatch =
                    val.artist &&
                    val.artist[0] &&
                    val.artist[0].addresses[0].country &&
                    val.artist[0].addresses[0].country.toLowerCase().includes(search);
                  
                     const created_date = dayjs(val.createdAt).format("DD-MM-YYYY").includes(search);

                    // Combine all conditions with OR logic
                    return (
                      nameMatch ||
                      lastnameMatch ||
                      fullNameMatch ||
                      artistMatch ||
                      emailMatch ||
                      phoneMatch || created_date
                    );
                  })

                  .sort((a, b) => {
                    const artistA = a.artist && a.artist[0];
                    const artistB = b.artist && b.artist[0];

                    if (artistA && artistB) {
                      return (
                        new Date(artistB.approved_date) -
                        new Date(artistA.approved_date)
                      );
                    }

                    return 0;
                  })

                  .sort((a,b)=>{
                    if(condition.sorting === "latest"){
                      return new Date(b.createdAt) - new Date(a.createdAt);
                    }else if (condition.sorting === "oldest"){
                      return new Date(a.createdAt) - new Date(b.createdAt); // Sort by oldest
                    }
                    return 0; 
                  })

                  .sort((a,b)=>{
                    if(condition.filter === "AZ"){
                      return a.first_name.localeCompare(b.first_name, undefined, {
                        sensitivity: "base",
                      });
                    }else if(condition.filter === "ZA"){
                      return b.first_name.localeCompare(a.first_name, undefined, {
                        sensitivity: "base",
                      });
                    }
                    return 0;
                  })
              }
            />
          </>
        ) :  (
          <>
            <Tablesfour
             
              activetab={activetab}
              head={featuredhead}
              data={
                curatorGalleyData &&
                curatorGalleyData.data
                  .filter((val) =>
                    (
                      val.first_name.toLowerCase() +
                      " " +
                      val.last_name.toLowerCase()
                    ).includes(condition.search.toLowerCase())
                  )
                  .filter((val) => {
                    if (condition.filter === "101") {
                      return val.followers > 100
                    } else if (condition.filter === "99") {
                      return val.followers < 100
                    } else {
                      return val
                    }
                  })
              }
            />
          </>
        )}
        <Pagination
          totaldata={curatorGalleyData && curatorGalleyData.totalCount}
          pagination={data}
          setPagination={setData}
        />
      </div>
    </div>
  )
}
