import {
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./Catelog.module.css";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { Container } from "@mui/system";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import homeImage from "../../../constant";
import api from "../../../State/api/Api";
import serverimage from "../../../serverConstant";
import { Link } from "react-router-dom";
import { FiHeart } from "react-icons/fi";
function Catelog() {
  const [age, setAge] = React.useState("");
  const [artData, setArtdata] = useState();
  console.log(artData);
  const [filterData, setFilterdata] = useState();
  const [query, setQuery] = useState();
  const getArt = async () => {
    const reqData = await api.applicationApi("api/user/allitems", "GET", {});
    setArtdata(reqData);
    setFilterdata(reqData);
  };
  useEffect(() => {
    getArt();
  }, []);
  const handleSearch = (event) => {
    const getSearch = event.target.value;
    if (getSearch.length > 0) {
      const searchdata =
        artData &&
        artData.data.artworks.filter((item) =>
          item.name.toLowerCase().includes(getSearch)
        );

      setArtdata({ data: { artworks: searchdata } });
    } else {
      setArtdata(filterData);
    }
    setQuery(getSearch);
  };
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const cardDetailsArt = [
    {
      id: 1,
      imgUrl: "/img/Rectangle 5373.png",
    },
    {
      id: 2,
      imgUrl: "/img/Rectangle 5376.png",
    },
    {
      id: 3,
      imgUrl: "/img/Rectangle 369.png",
    },
    {
      id: 4,
      imgUrl: "/img/Rectangle 5194.png",
    },
    {
      id: 5,
      imgUrl: "/img/Rectangle 5377.png",
    },
    {
      id: 6,
      imgUrl: "/img/Rectangle 5374.png",
    },
    {
      id: 7,
      imgUrl: "/img/Rectangle 5375.png",
    },
    {
      id: 8,
      imgUrl: "/img/Rectangle 5376.png",
    },
    {
      id: 9,
      imgUrl: "/img/Rectangle 5381.png",
    },
    {
      id: 10,
      imgUrl: "/img/Rectangle 5378.png",
    },
    {
      id: 11,
      imgUrl: "/img/Rectangle 5379.png",
    },
    {
      id: 12,
      imgUrl: "/img/Rectangle 5380.png",
    },
    {
      id: 13,
      imgUrl: "/img/Rectangle 5385.png",
    },
    {
      id: 14,
      imgUrl: "/img/Rectangle 5382.png",
    },
    {
      id: 15,
      imgUrl: "/img/Rectangle 5383.png",
    },
    {
      id: 16,
      imgUrl: "/img/Rectangle 5384.png",
    },
  ];
  return (
    <div className={styles.catelog}>
      <div className={styles.header}>
      <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
        <div className={`${styles.pageitle} ${styles.max_width}`}>
              <h1>Catalog</h1>
              <p>ARTSMILEY 2024 CATALOG: A NEW ERA IN ART DISCOVERY</p>
            </div>
        </Container>
        <div>
        </div>
      </div>
      <div className={styles.pdsec}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={4}>
              <img
                className="img-fluid"
                src={"/img/wood frame catelog.png"}
                alt="img"
              />
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <div className={styles.catcont}>
              <h3 >Discover The Best Artwork With Art Smiley Catalog</h3>
              <p>
                At ArtSmiley, we’re redefining how art connects with collectors. Our 2024 catalogue doesn’t just showcase art—it tells the stories of our artists, highlighting their journeys, creative processes, and the cultural influences behind their work.
Dive into a rich collection that spans diverse styles, mediums, and perspectives. Whether you're looking to make a bold statement with a modern piece or invest in timeless works that promise long-term value, our carefully selected artworks will inspire and elevate your space.
In this edition, we’re excited to introduce exclusive features, including interviews with our artists, behind-the-scenes insights,featured artists, recommended artists, best selling artworks, and a special section on sustainable art practices. Explore the new wave of talent and trends shaping the future of art, all brought to you by ArtSmiley’s curatorial experts.

                </p>
              <p>
                  Whether you’re looking to add a new artist to your collection,
                  find the perfect piece to complement your home’s decor, or
                  invest in an heirloom-quality artwork, Artsmiley is your
                  portal to discovering the most talented artists around the
                  globe. If you’d like assistance finding an artwork you love,
                  or would like to commission something new, please contact us
                  at curator@art smiley.com. We love hearing from you!
              </p>
              </div>
              
            </Grid>
          </Grid>
        </Container>
      </div>
      <div className={styles.main__content}> 
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
  }}>
          <h3 className={styles.phtree}>All Artworks</h3>
          <div className={styles.searchbarDiv}>
          <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={4}></Grid>
          <Grid item xs={12} sm={4} md={4}>
          <TextField
              id="input-with-icon-textfield"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchOutlinedIcon />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              placeholder="Search"
              value={query}
              onChange={handleSearch}
              size="small"
              sx={{
                borderRadius: "5px",
                width: "100%",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
          <Grid container spacing={2}>
          <Grid item xs={3} sm={3} md={3}>
          <Typography mt={1} align="right">Sort by</Typography>
          </Grid>
          <Grid item xs={9} sm={9} md={9}>
          <FormControl sx={{ width: "100%" }}>
              <TextField
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                select
                value={age}
                label="Best Selling"
                onChange={handleChange}
                size="small"
                sx={{
                  "& .MuiFormLabel-root": {
                    marginTop: "-0.15%",
                    
                  },
                }}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </TextField>
            </FormControl>
          </Grid>
          </Grid>
          
            
          </Grid>
          </Grid>
            
            
          </div>
              <Grid container spacing={2}>
              {artData &&
                artData.data.artworks &&
                artData.data.artworks.map((item, i) =>
                  i >= 4 ? (
                    ""
                  ) : (
                    <Grid item xs={12} sm={6} md={3}>
                      <div className={styles.card__main}>
                      <div className={styles.card__top}>
                      <img
                        className={styles.card__Image}
                        src={item.image}
                        width="100%"
                        alt="img"
                      />
                    </div>
                    <div className={styles.pic_info}>
                      <div className={styles.card__middle}>
                        <span className={styles.titles}>{item.name}</span>
                      </div>
                      <div className={styles.info_like}>
                        <div className={styles.wishlst}>
                          <FiHeart />
                        </div>
                        <span className={styles.like_count}>
                          {item.likesview?.likes.length || 0}
                        </span>
                      </div>
                    </div>
                    <p className={styles.names}>
                    {item.artist_id && item.artist_id.first_name}{" "}
                    {item.artist_id && item.artist_id.last_name}
                        </p>
                        <div className={styles.price_sec}>
                      <div className={styles.card__bottom}>
                        <span className={styles.buys}>Buy</span>
                        <span className={styles.prices}>
                          $ {item?.sale?.price}
                        </span>
                      </div>
                      {item?.rent?.rent_price > 0 ? (
                        <>
                          {" "}
                          <div className={styles.hrs} />
                          <div>
                            <span className={styles.buys}>Rent/Month</span>
                            <span className={styles.prices}>
                              $ {item?.rent?.rent_price}
                            </span>
                          </div>
                        </>
                      ) : null}
                      {item?.print?.[0]?.print_price > 0 ? (
                        <>
                          {" "}
                          <div className={styles.hrs} />
                          <div>
                            <span className={styles.buys}>Print</span>
                            <span className={styles.prices}>
                              $ {item?.print[0]?.print_price}
                            </span>
                          </div>
                        </>
                      ) : null}
                    </div>
                        
                      </div>
                    </Grid>
                  )
                )}
            </Grid>
        </Container>
         
      </div>
    </div>
  );
}

export default Catelog;

// import {
//   Button,
//   FormControl,
//   Grid,
//   InputAdornment,
//   InputLabel,
//   MenuItem,
//   Select,
//   TextField,
//   Typography,
// } from "@mui/material";
// import React, { useEffect, useState } from "react";
// import styles from "./Catelog.module.css";
// import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
// import { Container } from "@mui/system";
// import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
// import api from "../../../State/api/Api";
// import serverimage from "../../../serverConstant";
// import { Link } from "react-router-dom";

// function Catelog() {
//   const [age, setAge] = useState("");
//   const [artData, setArtdata] = useState();
//   const [filterData, setFilterdata] = useState();
//   const [query, setQuery] = useState();

//   const getArt = async () => {
//     const reqData = await api.applicationApi("api/user/allitems", "GET", {});
//     setArtdata(reqData);
//     setFilterdata(reqData);
//   };

//   useEffect(() => {
//     getArt();
//   }, []);

//   const handleSearch = (event) => {
//     const getSearch = event.target.value;
//     if (getSearch.length > 0) {
//       const searchdata =
//         artData &&
//         artData.data.artworks.filter((item) =>
//           item.name.toLowerCase().includes(getSearch.toLowerCase())
//         );

//       setArtdata({ data: { artworks: searchdata } });
//     } else {
//       setArtdata(filterData);
//     }
//     setQuery(getSearch);
//   };

//   const handleChange = (event) => {
//     setAge(event.target.value);
//   };

//   return (
//     <div className={styles.catelog}>
//       <div className={styles.header}>
//         <div>
//           <Typography fontFamily="marcellus" color="white" fontSize="2vw">
//             Catelog
//           </Typography>
//           <Typography fontFamily="marcellus" color="white">
//             <Link to="/user/" className={styles.bold}>
//               Home
//             </Link>
//             / Curator's Circle
//           </Typography>
//         </div>
//       </div>
//       <div className={styles.main__content}>
//         <div className={styles.header__grid}>
//           <Grid container>
//             <Grid item xs={12} sm={4} md={4}>
//               <img
//                 className={styles.headergrid_img}
//                 src={"/img/wood frame catelog.png"}
//                 alt="img"
//               />
//             </Grid>
//             <Grid item xs={12} sm={8} md={8}>
//               <Typography mt={0.5} fontFamily="marcellus" fontSize="1.5rem">
//                 Refresh your home this spring with a new original artwork by our
//                 curators’ favorite artists.
//               </Typography>
//               <Typography mt={1.5} fontSize="0.9rem" color="#636365">
//                 For our final catalog of the season, our team of expert curators
//                 share their recommendations for abstracts, landscapes,
//                 sculptures, and more by today’s top emerging artists.
//               </Typography>
//               <Typography mt={1.5} fontSize="0.9rem" color="#636365">
//                 Whether you’re looking to add a new artist to your collection,
//                 find the perfect piece to complement your home’s decor, or
//                 invest in an heirloom-quality artwork, Saatchi Art is your
//                 portal to discovering the most talented artists around the
//                 globe. If you’d like assistance finding an artwork you love, or
//                 would like to commission something new, please contact us at
//                 curator@artsmiley.com. We love hearing from you!
//               </Typography>
//             </Grid>
//           </Grid>
//         </div>
//         <div className={styles.artworks__heading}>
//           <Typography mb={2} fontFamily="marcellus" fontSize="2rem">
//             All Artworks
//           </Typography>
//           <div className={styles.searchbarDiv}>
//             <TextField
//               id="input-with-icon-textfield"
//               InputProps={{
//                 startAdornment: (
//                   <InputAdornment position="start">
//                     <SearchOutlinedIcon />
//                   </InputAdornment>
//                 ),
//               }}
//               variant="outlined"
//               placeholder="Search"
//               value={query}
//               onChange={handleSearch}
//               size="small"
//               sx={{
//                 borderRadius: "5px",
//                 width: "30%",
//               }}
//             />
//             <Typography>Sort by</Typography>
//             <FormControl sx={{ width: "20%" }}>
//               <TextField
//                 labelId="demo-simple-select-label"
//                 id="demo-simple-select"
//                 select
//                 value={age}
//                 label="Best Selling"
//                 onChange={handleChange}
//                 size="small"
//               >
//                 <MenuItem value={10}>Ten</MenuItem>
//                 <MenuItem value={20}>Twenty</MenuItem>
//                 <MenuItem value={30}>Thirty</MenuItem>
//               </TextField>
//             </FormControl>
//           </div>
//         </div>
//         <div className={styles.ProductCards}>
//           <Container>
//             <Grid container spacing={1}>
//               {artData &&
//                 artData.data.artworks &&
//                 artData.data.artworks.map((item) => (
//                   <Grid item xs={12} sm={6} md={3} key={item.id}>
//                     <div className={styles.card__main}>
//                       <div className={styles.card__top}>
//                         <img
//                           className={styles.cardImage}
//                           src={ item.image}
//                           alt="img"
//                         />
//                       </div>
//                       <div className={styles.pic_info}>
//                         <div className={styles.card__middle}>
//                           <div className={styles.card__middleLeft}>
//                             <Typography
//                               fontSize="0.95rem"
//                               fontWeight="700"
//                               mt={1}
//                             >
//                               {item.name}
//                             </Typography>
//                             <Typography
//                               fontSize="0.8rem"
//                               fontWeight="700"
//                               mt={1}
//                               color="#ccc"
//                             >
//                               {item.artist_id && item.artist_id.first_name}{" "}
//                               {item.artist_id && item.artist_id.last_name}
//                             </Typography>
//                           </div>
//                         </div>
//                         <div className={styles.card__middleRight}>
//                           <FavoriteBorderIcon fontSize="small" />
//                           <Typography fontWeight="400" ml={0.5}>
//                             522
//                           </Typography>
//                         </div>
//                       </div>
//                       <hr />
//                       <div className={styles.card__bottom}>
//                         <div className={styles.bottom__text}>
//                           <Typography
//                             fontWeight="600"
//                             color="#ccc"
//                             fontSize="0.65rem"
//                           >
//                             Buy
//                           </Typography>
//                           <Typography
//                             color="black"
//                             fontWeight="500"
//                             fontSize="0.85rem"
//                           >
//                             <b>${item.sale.price}</b>
//                           </Typography>
//                         </div>
//                         <div className={styles.verticalLine}></div>
//                         <div className={styles.bottom__text}>
//                           <Typography
//                             fontWeight="600"
//                             color="#ccc"
//                             fontSize="0.65rem"
//                           >
//                             Rent/Month
//                           </Typography>
//                           <Typography
//                             color="black"
//                             fontWeight="500"
//                             fontSize="0.85rem"
//                           >
//                             <b>
//                               $
//                               {item.rent?.rent_price
//                                 ? item.rent.rent_price
//                                 : "not available"}
//                             </b>
//                           </Typography>
//                         </div>
//                         <div className={styles.verticalLine}></div>
//                         <div className={styles.bottom__text}>
//                           <Typography
//                             fontWeight="600"
//                             color="#ccc"
//                             fontSize="0.65rem"
//                           >
//                             Print
//                           </Typography>
//                           <Typography
//                             color="black"
//                             fontWeight="500"
//                             fontSize="0.85rem"
//                           >
//                             <b>
//                               $
//                               {item.print ? (
//                                 item?.print[0]?.print_price
//                               ) : (
//                                 <>
//                                   not <br /> available
//                                 </>
//                               )}
//                             </b>
//                           </Typography>
//                         </div>
//                       </div>
//                     </div>
//                   </Grid>
//                 ))}
//             </Grid>
//           </Container>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Catelog;
