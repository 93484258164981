import React, { useState } from "react";
import styles from "./Tablethreecol.module.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import dayjs from "dayjs";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import Popup from "../../Popupdelete/Popup";
import Tableheader from "../Tableheader/Tableheader";
import Moreoption from "../Moreoption/Moreoption";
import homeImage from "../../../../../constant";
import { Link, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

export default function Tablethreecol(props) {
  console.log("Tablethreecol props", props);

  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [artists, setArtist] = useState("");
  const [ids, setIds] = useState("");
  const threedots = Boolean(anchorEl);
  const navigate = useNavigate();
  const location = useLocation();
  const handleClick = (event) => {
    setIds(event.target.id);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handlePopup = (e) => {
    props.setOpen(true);
    props.setPoptype("addfeature");
  };
  const handledetailpage = (row) => {
    if (location.pathname === "/auction") {
      navigate(`/auction/${row._id}`);
    }
  };
  const user_type = localStorage.getItem("usertype");

  return (
    <>
      {open ? <Popup open={open} setOpen={setOpen} /> : ""}
      {open ? <Popup open={open} setOpen={setOpen} /> : ""}
      <div className={styles.shadow}>
        {/* <Tableheader /> */}
        {/* <Moreoption  artistOptions={artistOptions} /> */}
        <TableContainer
          component={Paper}
          sx={{ backgroundColor: "#F6F6F6", color: "black", boxShadow: "none" }}
        >
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow sx={{ backgroundColor: "#bfbbc3" }}>
                {props.head.map((column, i) => (
                  <TableCell
                    align="left"
                    sx={{
                      whiteSpace: "nowrap",
                      fontFamily: "DM Sans",
                      fontWeight: "500",
                      fontSize: "16px",
                      height: "21px",
                      color: "#1C1C1C",
                    }}
                    key={i}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.data && props.data.length !== 0 ? (
                props.data.map((row, i) => (
                  <TableRow style={{ color: "#636365" }} key={i}>
                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                        cursor:
                          props.activetab === "upcoming" &&
                          user_type === "curator"
                            ? "default"
                            : "pointer", // Dynamic cursor condition
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      {row.artist_name}
                      {row.first_name}
                      {props.activetab === "upcoming" &&
                      user_type === "curator" ? (
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          style={{
                            cursor: "default",
                          }}
                        >
                          {i + 1}
                        </span>
                      ) : props.activetab === "upcoming" ||
                        props.activetab === "decline" ? (
                        row.name
                      ) : (
                        ""
                      )}{" "}
                      {row.last_name}
                    </TableCell>
                    {(
                      props?.activetab === "buyer" || 
                    props?.activetab === "curator" ||
                      props?.activetab === "affiliate" ||
                      props?.activetab === "artist" ||
                      props?.activetab === "gallery"
                  ) && props?.superadmin === "addcolaccount" ? (
                      <TableCell
                        sx={{
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "21px",
                          color: "#636365",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        {moment(row?.createdAt)?.format("DD-MM-YYYY")}
                      </TableCell>
                    ) : (
                      ""
                    )}
                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      {user_type === "curator" ? (
                        <Link
                          className={styles.hyperlink_title}
                          to={`/auction/${row._id}`}
                        >
                          {row.name}
                        </Link>
                      ) : (
                        row.start_date &&
                        moment(row.start_date).format("DD-MM-YYYY")
                      )}
                      {props.activetab === "subadmin" ? row.email : row.phone}
                    </TableCell>

                    {props.activetab === "subadmin" && (
                      <TableCell
                        sx={{
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "21px",
                          color: "#636365",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        {dayjs(row.createdAt).format("DD-MM-YYYY").toString()}
                      </TableCell>
                    )}
                    {props.activetab === "subadmin" ? (
                      <TableCell
                        sx={{
                          width: "20%",
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "21px",
                          color: "#636365",
                          whiteSpace: "nowrap",
                          paddingLeft: "2px",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        <>
                          <Select
                            value={artists}
                            // onChange={handleChange}
                            displayEmpty
                            // fullWidth
                            sx={{
                              background: "#f6f6f6",
                              ".MuiOutlinedInput-notchedOutline": {
                                border: "none",
                              },
                              "& .MuiInputBase-root": {
                                borderRadius: "6px",
                              },
                              fontFamily: "DM Sans",
                              fontStyle: "normal",
                              fontWeight: "400",
                              fontSize: "16px",
                              lineHeight: "21px",
                              color: "#636365",
                              height: "40px",
                            }}
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            <MenuItem value="">Selected Roles</MenuItem>
                            {row.admin_role &&
                              row.admin_role.split(",").map((role, index) => (
                                <MenuItem key={index} value={role}>
                                  {role}
                                </MenuItem>
                              ))}
                          </Select>
                        </>
                        <IconButton onClick={() => props.handleDelete(row._id)}>
                          <img
                            alt="img"
                            src={"/img/delete.png"}
                            width="20px"
                            height="20px"
                          />
                        </IconButton>
                        <IconButton onClick={() => props.handleEdit(row)}>
                          <img
                            alt="img"
                            src={"/img/Edit.png"}
                            width="20px"
                            height="20px"
                          />
                        </IconButton>
                      </TableCell>
                    ) : props.activetab === "buyer" ? (
                      <TableCell
                        sx={{
                          width: "30%",
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "21px",
                          color: "#636365",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        <div className={styles.buyer_flex}>
                          <span>
                            {row.end_date &&
                              moment(row.end_date).format("DD-MM-YYYY")}
                            {row.email}
                          </span>
                          {/* <Tooltip title="Account settings">
                            <IconButton
                              onClick={handleClick}
                              size="small"
                              id={row._id}
                              sx={{ ml: 2 }}
                              aria-controls={
                                threedots ? "account-menu" : undefined
                              }
                              aria-haspopup="true"
                              aria-expanded={threedots ? "true" : undefined}
                            >
                              <img
                                alt="img"
                                id={row._id}
                                src={"/img/Menu Icon.png"}
                                height="21.62px"
                                width="4.63px"
                              />
                            </IconButton>
                          </Tooltip>
                          <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={threedots}
                            onClose={handleClose}
                            onClick={handleClose}
                            PaperProps={{
                              elevation: 0,
                              sx: {
                                width: "150px",
                                overflow: "visible",
                                boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
                                filter:
                                  "drop-shadow(0px 0px 0px rgba(0,0,0,0.32))",
                                mt: 2.5,
                                "& .MuiAvatar-root": {
                                  width: "120px",
                                  height: "132px",
                                  ml: 0.5,
                                  mr: 1,
                                },
                                "&:before": {
                                  content: '""',
                                  display: "block",
                                  position: "absolute",
                                  top: 0,
                                  right: 62,
                                  width: 10,
                                  height: 10,
                                  bgcolor: "background.paper",
                                  transform: "translateY(-50%) rotate(45deg)",
                                  zIndex: 0,
                                },
                              },
                            }}
                            transformOrigin={{
                              horizontal: "center",
                              vertical: "right",
                            }}
                            anchorOrigin={{
                              horizontal: "left",
                              vertical: "center",
                            }}
                          >
                            <MenuItem
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "16px",
                                lineHeight: "12px",
                                fontWeight: "400",
                                fontFamily: "DM Sans",
                              }}
                              onClick={() => props.handleDeleteAuction(ids)}
                            >
                              Delete
                            </MenuItem>
                          </Menu> */}
                        </div>
                      </TableCell>
                    ) : props.activetab === "artist" ||
                      "affiliate" ||
                      "curator" ? (
                      <TableCell
                        sx={{
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "21px",
                          color: "#636365",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        <div className={styles.buyer_flex}>
                          <span>
                            {user_type === "curator"
                              ? moment(row.createdAt).format("DD-MM-YYYY")
                              : row.end_date &&
                                moment(row.end_date).format("DD-MM-YYYY")}

                            {row.email}
                          </span>
                          {user_type !== "curator" && (
                            <>
                              {/* <Tooltip title="Account settings">
                                <IconButton
                                  onClick={handleClick}
                                  size="small"
                                  id={row._id}
                                  sx={{ ml: 2 }}
                                  aria-controls={
                                    threedots ? "account-menu" : undefined
                                  }
                                  aria-haspopup="true"
                                  aria-expanded={threedots ? "true" : undefined}
                                >
                                  <img
                                    alt="img"
                                    id={row._id}
                                    src={"/img/Menu Icon.png"}
                                    height="21.62px"
                                    width="4.63px"
                                  />
                                </IconButton>
                              </Tooltip>
                              <Menu
                                anchorEl={anchorEl}
                                id="account-menu"
                                open={threedots}
                                onClose={handleClose}
                                onClick={handleClose}
                                PaperProps={{
                                  elevation: 0,
                                  sx: {
                                    width: "150px",
                                    overflow: "visible",
                                    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
                                    filter:
                                      "drop-shadow(0px 0px 0px rgba(0,0,0,0.32))",
                                    mt: 2.5,
                                    "& .MuiAvatar-root": {
                                      width: "120px",
                                      height: "132px",
                                      ml: 0.5,
                                      mr: 1,
                                      // mt:5
                                    },
                                    "&:before": {
                                      content: '""',
                                      display: "block",
                                      position: "absolute",
                                      top: 0,
                                      // horizontal: 'center',
                                      right: 62,
                                      width: 10,
                                      height: 10,
                                      bgcolor: "background.paper",
                                      transform:
                                        "translateY(-50%) rotate(45deg)",
                                      zIndex: 0,
                                    },
                                  },
                                }}
                                transformOrigin={{
                                  horizontal: "center",
                                  vertical: "right",
                                }}
                                anchorOrigin={{
                                  horizontal: "left",
                                  vertical: "center",
                                }}
                              >
                                <MenuItem
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    fontSize: "16px",
                                    lineHeight: "12px",
                                    fontWeight: "400",
                                    fontFamily: "DM Sans",
                                  }}
                                  onClick={() => props.handleDeleteAuction(ids)}
                                >
                                  Delete
                                </MenuItem>
                              </Menu> */}
                            </>
                          )}
                        </div>
                      </TableCell>
                    ) : (
                      <TableCell
                        sx={{
                          width: "33%",
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "21px",
                          color: "#636365",
                          whiteSpace: "nowrap",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        <div className={styles.end_col_auction}>
                          {" "}
                          {row.end_date &&
                            moment(row.end_date).format("DD-MM-YYYY")}
                          {row.email}
                          <IconButton onClick={() => setOpen(true)}>
                            <img
                              src={"/img/delete.png"}
                              width="20px"
                              height="20px"
                            />
                          </IconButton>
                        </div>
                      </TableCell>
                    )}
                    {user_type === "curator" && (
                      <>
                        <TableCell
                          sx={{
                            fontFamily: "DM Sans",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "21px",
                            color: "#636365",
                          }}
                          className={styles.table_column}
                          align="left"
                        >
                          {row.start_date &&
                            moment(row.start_date).format("DD-MM-YYYY")}
                        </TableCell>

                        <TableCell
                          sx={{
                            fontFamily: "DM Sans",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "21px",
                            color: "#636365",
                          }}
                          className={styles.table_column}
                          align="left"
                        >
                          {row.end_date &&
                            moment(row.end_date).format("DD-MM-YYYY")}
                        </TableCell>

                        <TableCell
                          sx={{
                            fontFamily: "DM Sans",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "21px",
                          }}
                          align="left"
                        >
                          {row.application_end_date &&
                            moment(row.application_end_date).format(
                              "DD-MM-YYYY"
                            )}
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={8}>
                    <Box className={styles.loader_box}> No Data Found</Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}
