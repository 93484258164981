import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDisputeData,
  acceptRejectRefundStatus,
} from "../../../../State/api/superadminApi";
import Pagination from "../Pagination/Pagination";
import Tableseven from "../Table/Sevencol/Tableseven";
import Tableheader from "../Table/Tableheader/Tableheader";
import styles from "./Admindispute.module.css";
import {
  disputeData,
  setDisputedata,
} from "../../../../State/Features/superadmin/disputeSlice";

const exhibitionhead = [
  {
    id: "artist_name ",
    label: "Dispute ID",
  },
  {
    id: "mobile_number",
    label: "Order ID",
  },
  {
    id: "date",
    label: "Created Date",
  },
  {
    id: "assign_to",
    label: "Name",
  },
  {
    id: "actiont",
    label: "Refund",
  },
  // {
  //   id: "action",
  //   label: "Status",
  // },
];
const sortdata = [
  { label: "Old to new ↓", value: "low" },
  { label: "New to old ↑", value: "high" },
];
const filterdata = [
  { label: "Approved", value: "Approved" },
  { label: "Rejected", value: "Rejected" },
  { label: "Pending", value: "Pending" },
];
export default function Admindispute() {
  const [data, setData] = useState({
    offset: 0,
  });
  const [condition, setCondition] = useState({
    search: "",
    filter: "",
    sorting: "",
  });
  const disputedata = useSelector((state) => state.disputeData.data);
  const dispatch = useDispatch();
  const fetchData = () => {
    dispatch(fetchDisputeData(data));
  };

  useEffect(() => {
    fetchData();
  }, [data]);

  const handleSearch = (e) => {
    let value = e.target.value;
    setCondition({ ...condition, search: value });
  };

  const handleFilter = (event) => {
    const value = event.target.value;
    setCondition({ ...condition, filter: value });
  };
  const handleSorting = (e) => {
    let value = e.target.value;
    setCondition({ ...condition, sorting: value });
  };
  const ApprovedRefundStatus = (id, dt) => {
    dispatch(acceptRejectRefundStatus(id, dt, fetchData));
  };


  return (
    <div className={styles.mains}>
      <div className={styles.components}>
        <div className={styles.tableheader_enter}>
          <Tableheader
            button=""
            handleFilter={handleFilter}
            handleSorting={handleSorting}
            filterdata={filterdata}
            sortdata={sortdata}
            condition={condition}
            search={condition.search}
            handleSearch={handleSearch}
          />
        </div>
        <Tableseven
          ApprovedRefundStatus={ApprovedRefundStatus}
          activetab="dispute_superadmin"
          head={exhibitionhead}
          data={
            disputedata &&
            disputedata.data
              .filter((val) => {
                if (condition.filter === "") {
                  return val;
                } else if (condition.filter === "Approved") {
                  return val.refund_status === "Approved";
                } else if (condition.filter === "Rejected") {
                  return val.refund_status === "Rejected";
                } else if (condition.filter === "Pending") {
                  return val.refund_status === "Pending";
                }
              })
              .filter((val) => {
                if (condition.search === "") {
                  return val;
                } else if (
                  val.dispute_id.toLowerCase().includes(condition.search.toLowerCase())||
                  val.order_id.toLowerCase().includes(condition.search.toLowerCase())
                ) {
                  return val;
                }
              })
              .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
              .sort((a, b) => {
                if (condition.sorting === "low") {
                  return new Date(b.createdAt) - new Date(a.createdAt);
                } else if (condition.sorting === "high") {
                  return new Date(a.createdAt) - new Date(b.createdAt); // Sort by oldest
                }
                return 0; // Default case: no sorting
              })
          }
        />
        <Pagination
          totaldata={disputedata && disputedata.totalCount}
          pagination={data}
          setPagination={setData}
        />
      </div>
    </div>
  );
}
